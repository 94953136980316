import { Button } from "@progress/kendo-react-buttons";
import CalenderImage from "../../assets/common/calender.png";

const CalenderCustomToggleButton = (props) => {
  return (
    <div className="d-flex align-items-center">
      {props.show && (
        <span
          onClick={() => props.onCancel && props.onCancel()}
          className="k-clear-value"
          role="button"
          tabIndex="-1"
          style={{
            marginBottom: "2px",
          }}
        >
          <span className="k-icon k-svg-icon k-svg-i-x">
            <svg
              aria-hidden="true"
              focusable="false"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path d="M416 141.3 301.3 256 416 370.7 370.7 416 256 301.3 141.3 416 96 370.7 210.7 256 96 141.3 141.3 96 256 210.7 370.7 96l45.3 45.3z"></path>
            </svg>
          </span>
        </span>
      )}
      <Button
        type="button"
        onClick={props.onClick}
        className="px-1 bg-transparent border-white mb-1"
      >
        <img width={16} height={16} src={CalenderImage} alt="Calender Icon" />
      </Button>
    </div>
  );
};

export default CalenderCustomToggleButton;
