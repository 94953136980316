import * as React from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Input, Checkbox, TextArea } from "@progress/kendo-react-inputs";
import { formatDate } from "@progress/kendo-intl";
import { Label } from "@progress/kendo-react-labels";
import { DateInput, DatePicker } from "@progress/kendo-react-dateinputs";
import { Tile } from "components/UIComponents/Tile";
import styles from "./index.module.scss";
import CalenderCustomToggleButton from "components/common/CalenderCustomToogleButton";
import { useState } from "react";
import DataList from "components/MatterDetails/DataList";
import { useSelector } from "react-redux";
import {
  addClientNotes,
  addDirector,
  addMatterNotes,
  updateClientNotes,
  updateMatterNotes,
} from "api";
import { toast } from "react-toastify";
import { FormInput } from "components/Matters";
import { CustomDateInput } from "components/common/CustomDateInput";

const AddDirectors = (props) => {
  const VIEW_NOTE = props.item !== null;
  const [date, setDate] = useState(null);
  const [notes, setNotes] = useState(props.item?.details || "");
  const [inputState, setInputState] = useState({});
  const [isEdited, setIsEdited] = useState(false);
  const [active, setActive] = useState(props.item?.active || false);
  let locale = useSelector((state) => state.company.company.locale);
  const handleInputChange = (value, label) => {
    setInputState({
      ...inputState,
      [label]: value,
    });
  };

  const handleSubmit = async () => {
    try {
      const data = {
        firstName: inputState["First_Name"],
        lastName: inputState["Last_Name"],
        dateOfBirth: date,
        personType: "director",
      };
      const resultData = await addDirector({ data: data }, props.clientId);
      toast.success("Director Added");
      props.onUpdateData(resultData?.data?.data);
      props.cancelEdit();
    } catch (error) {
      toast.error("Unexpected Error Occured");
    }
  };

  return (
    <Dialog
      title={"Add Director"}
      onClose={props.cancelEdit}
      width={500}
      className="p-3"
    >
      <div className={`${styles["director-tile"]} p-2 m-4 ms-5 me-5`}>
        <div className="p-3 ps-4">
          <div className="form-date-picker note-date-picker mt-2">
            <FormInput
              className={"form-date-picker note-date-picker "}
              wf={true}
              isStyled={true}
              width={"260px"}
              label={"First_Name"}
              onChangeInput={handleInputChange}
              state={inputState}
            />
          </div>

          <div className="mt-3">
            <FormInput
              className={"form-date-picker note-date-picker "}
              wf={true}
              isStyled={true}
              width={"260px"}
              label={"Last_Name"}
              onChangeInput={handleInputChange}
              state={inputState}
            />
          </div>

          <div className="form-date-picker note-date-picker mt-3">
            <Label className="label mt-1 mb-1 w-100">Date&nbsp;</Label>
            <DatePicker
              toggleButton={CalenderCustomToggleButton}
              dateInput={CustomDateInput}
              onChange={(e) => {
                setDate(e.value);
              }}
              value={date}
              className="px-1"
              style={{
                height: "32px",
                width: "260px",
              }}
            />
          </div>
        </div>
      </div>
      <div className="dialog-footer">
        <button
          className="bgBtnSecondary common-btn me-2"
          onClick={() => {
            if (isEdited) {
              setIsEdited(false);
            } else {
              props.cancelEdit();
            }
          }}
        >
          Cancel
        </button>
        <button
          className="common-btn me-3"
          onClick={() => {
            handleSubmit();
          }}
        >
          {"Save"}
        </button>
      </div>
    </Dialog>
  );
};

export default AddDirectors;
