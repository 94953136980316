import * as React from "react";
import { Button } from "@progress/kendo-react-buttons";
import styles from "../Settings.module.scss";
import CategoriesList from "./WorkTypeList";
import EditCategory from "./EditWorkType";
import * as api from "../../../api/index";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { getCategory } from "actions/categories.action";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { getDepartmentList } from "api/index";
import { useEffect } from "react";
import { Label } from "@progress/kendo-react-labels";

const Category = (props) => {
  const [addnewform, setaddnewform] = React.useState(false);
  const [departments, setDepartments] = React.useState([]);
  const [departmentID, setDepartmentID] = React.useState("");

  const dispatch = useDispatch();
  const closeform = () => {
    setaddnewform(false);
  };
  React.useEffect(() => {
    if (props.onClose === false) {
      closeform();
    }
  }, [props.onClose]);

  useEffect(() => {
    getActiveDepartments();
  }, []);

  const onChange = (e) => {
    setDepartmentID(e?.value?.departmentID);
  };

  const handleSubmit = async (e) => {
    setaddnewform(false);
    try {
      await api.addCategory({
        ...e,
        departmentId: e.departmentId?.departmentID,
        appCode: e.appCode?.appCode,
        defaultProfitCost: e.defaultProfitCost?.nominalRef,
        rateCategoryId: e.rateCategoryId?.rateCategoryId,
        active: true,
      });
      toast.success("Work Type Added");
      dispatch(getCategory());
    } catch (error) {
      toast.error("Error Occured", error);
    }
  };

  const getActiveDepartments = async () => {
    try {
      const data = await getDepartmentList();
      setDepartments([{ name: "ALL", departmentID: 0 }, ...data.data?.data]);
    } catch (error) {}
  };
  return (
    <React.Fragment>
      <>
        <div className="container my-5 ">
          <div className="row mb-3 ">
            <div className="form-group col-lg-5 col-md-8  d-flex flex-column ms-lg-auto ">
              <Label>Department</Label>
              <ComboBox
                data={departments}
                className={`${styles["work-type-field-style"]} mb-2 `}
                iconClassName="wa"
                required={true}
                textField={"name"}
                placeholder={"Search Department"}
                onChange={onChange}
                value={
                  departments.find((d) => d.departmentID === departmentID) || {
                    name: "ALL",
                    departmentID: 0,
                  }
                }
              />
            </div>

            <div className="col-lg-2 col-md-3 ms-auto">
              <Button
                className={`common-btn  ${styles[`button`]}`}
                onClick={() => {
                  setaddnewform(true);
                  props.open();
                }}
              >
                Add <br></br>
                Work Type
              </Button>
            </div>
          </div>
          <div className="row">
            <div className="mx-auto col-lg-7 col-md-12 ">
              <CategoriesList departmentID={departmentID} />
            </div>
          </div>
        </div>
      </>

      {addnewform && (
        <EditCategory
          cancelEdit={closeform}
          onSubmit={handleSubmit}
          type="addNew"
        />
      )}
    </React.Fragment>
  );
};

export default Category;
