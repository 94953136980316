import * as React from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import AddTime from "pages/Time/AddTime";

const TimeRecordingHelper = (props) => {
  return (
    <Dialog
      title={"Time Recording"}
      onClose={props.cancelEdit}
      className="dialog-xmd"
    >
      <AddTime
        component={"modal"}
        matter={props.matter}
        cancelEdit={props.cancelEdit}
        showModal={true}
      />
    </Dialog>
  );
};

export default TimeRecordingHelper;
