import * as React from "react";
import { useEffect } from "react";
import { SvgIcon } from "@progress/kendo-react-common";
import { reorderIcon } from "@progress/kendo-svg-icons";


const DragHandleCell = (props) => {
  const {
    ariaColumnIndex,
    dataItem,
    selectionChange,
    columnIndex,
    columnsCount,
    rowType,
    expanded,
    dataIndex,
    isSelected,
    ...rest
  } = props;

  return (
    <td {...rest} style={{ touchAction: "none",}}>
      <span style={{cursor: "move"}}
        data-drag-handle="true" >
        <SvgIcon
          style={{
            pointerEvents: "none",
          }}
          icon={reorderIcon}
        />
      </span>
    </td>
  );
};

export default DragHandleCell;
