export const TABS_CONFIG = "TABS_CONFIG";
export const TABS_CONFIG_REMOVE = "TABS_CONFIG_REMOVE";
export const GET_MATTERS_LIST = "GET_MATTERS_LIST";
export const GET_NOMINALS_LIST = "GET_NOMINALS_LIST";
export const GET_Suppliers_LIST = "GET_Suppliers_LIST";
export const GET_CONSULTANT_LIST = "GET_CONSULTANT_LIST";
export const START_LOADING = "START_LOADING";
export const STOP_LOADING = "STOP_LOADING";
export const GET_RECORDED_TIME = " GET_RECORDE_TIME";
export const GET_RECORDED_TIME_FOR_MATTER = "GET_RECORDED_TIME_FOR_MATTER";
export const SUCCESS = "SUCCESS";
export const ERROR = "ERROR";
export const ACCESS_DENIED = "ACCESS_DENIED";
export const GET_USERS_LIST = "GET_USERS_LIST";
export const GET_CURRENT_USER = "GET_CURRENT_USER";
export const GET_COMPANY = "GET_COMPANY";
export const UPDATE_COMPANY = "UPDATE_COMPANY";
export const UPDATE_USER = "UPDATE_USER";
export const GET_BRANCHES_LIST = "GET_BRANCHES_LIST";
export const UPDATE_BRANCH = "UPDATE_BRANCH";
export const UPDATE_USERS_LIST = "UPDATE_USERS_LIST";
export const GET_BRANCHES_NAMES = "GET_BRANCH_NAMES";
export const GET_VATCODES_LIST = "GET_VATCODES_LIST";
export const UPDATE_VATCODE = "UPDATE_VATCODE";
export const ADD_NOMINAL = "ADD_NOMINAL";
export const ADD_SUPPLIER = "ADD_SUPPLIER";
export const ADDEDNEWUSER = "ADDEDNEWUSER";
export const ADDEDNEWBRANCH = "ADDEDNEWBRANCH";
export const GET_CURRENCY_LIST = "GET_CURRENCY_LIST";
export const UPDATE_CURRENCY = "UPDATE_CURRENCY";
export const ADDEDNEWCURRENCY = "ADDEDNEWCURRENCY";
export const GET_VIEW_NOMINALS = "GET_VIEW_NOMINALS";
export const UPDATE_NOMINAL = "UPDATE_NOMINAL";
export const SEARCH_NOMINAL = "SEARCH_NOMINAL";
export const GET_VIEW_SUPPLIER = "GET_VIEW_SUPPLIER";
export const UPDATE_SUPPLIER = "UPDATE_SUPPLIER";
export const GET_POSTING_DETAILS = " GET_POSTING_DETAILS";
export const GET_MATTERSLIST = "GET_MATTERSLIST";
export const GET_FEEEARNER = "GET_FEEEARNER";
export const GET_OFFICELIST = "GET_OFFICELIST";
export const GET_ACTIVEVATS = "GET_ACTIVEVATS";
export const GET_SUPPLIERREFS = "GET_SUPPLIERREFS ";
export const GET_CONSULTANTREFS = "GET_CONSULTANTREFS";
export const RESET = "RESET";
export const RESET_DATA = "RESET_DATA";
export const SEARCH_SUPPLIER = "SEARCH_SUPPLIER";
export const SEARCH_MATTER = "SEARCH_MATTER";
export const CREATED_POSTING = "CREATED_POSTING";
export const GET_ACTIVENOMINALS = "GET_ACTIVENOMINALS";
export const GET_ACTIVE_CURRENCY = "GET_ACTIVE_CURRENCY";
export const GET_NOMINALREF = "GET_NOMINALREF";
export const GET_NOMINALS_BY_REF = "GET_NOMINALS_BY_REF";
export const GET_TRANSACTIONPERIODS = "GET_TRANSACTIONPERIODS";
export const GET_CURRENTTRANSACTIONPERIODS = "GET_CURRENTTRANSACTIONPERIODS";
export const UPDATE_TRANSACTION = " UPDATE_TRANSACTION";
export const GET_SUPPLIERS_BY_REF = "GET_SUPPLIERS_BY_REF";
export const GET_SUPPLIERREF = "GET_SUPPLIERREF";
export const GET_CONSULTANT_REF = "GET_CONSULTANT_REF";
export const OPEN_PROFILE = "OPEN_PROFILE";
export const CLOSE_PROFILE = "CLOSE_PROFILE";
export const LOGGED_IN = "LOGGED_IN";
export const LOGGED_OUT = "LOGGED_OUT";
export const GET_DDALIST = "GET_DDALIST";
export const GET_CLIENTLIST = "GET_CLIENTLIST";
export const GET_NOMINAL_BALANCES = "GET_NOMINAL_BALANCES";
export const GET_SUPPLIER_BALANCES = "GET_SUPPLIER_BALANCES";
export const NO_CONTENT_COMPANY = "NO_CONTENT_COMPANY";
export const COMPLETE_COMPANY = "COMPLETE_COMPANY";
export const NO_CONTENT_USER = "NO_CONTENT_USER";
export const NEWUSER_SUB = "NEWUSER_SUB";
export const CREATENEWUSER_RESULT = "CREATENEWUSER_RESULT";
export const TRANS_POSTING_DETAILS = "TRANS_POSTING_DETAILS";
export const TRANS_POSTING_DETAILS_RESET = "  TRANS_POSTING_DETAILS_RESET";
export const GET_MATTERS_DETAILS = "GET_MATTERS_DETAILS";
export const GET_MATTERS_BALANCES = "GET_MATTERS_BALANCES";
export const GET_MATTERS_POSTINGS = "GET_MATTERS_POSTINGS";
export const CLEAR_MATTERS_POSTINGS = "CLEAR_MATTERS_POSTINGS";
export const GET_NOMINAL_JOURNAL_POSTING = "GET_NOMINAL_JOURNAL_POSTING";
export const CLEAR_NOMINAL_JOURNAL_POSTING = "CLEAR_NOMINAL_JOURNAL_POSTING";
export const ADD_FILES = "ADD_FILES";
export const REMOVE_FILES = "REMOVE_FILES";
export const CLEAR_FILES = "CLEAR_FILES";
export const UPLOAD_PHOTOS = "UPLOAD_PHOTOS";
export const REMOVE_UPLOAD_PHOTOS = "REMOVE_UPLOAD_PHOTOS";
export const CUURENTPROF_PHOTOS = "CUURENTPROF_PHOTOS";
export const RESPONSE_ERROR_LOGIN = "RESPONSE_ERROR_LOGIN";
export const GET_PROFILE_PHOTO = "GET_PROFILE_PHOTO";
export const CLEAR_PROFILE_PHOTO = "CLEAR_PROFILE_PHOTO";
export const EDIT_PROFILE_PHOTO = "EDIT_PROFILE_PHOTO";
export const SET_HEADER_PHOTO = "SET_HEADER_PHOTO";
export const GET_CONTACTS_LIST = "GET_CONTACTS_LIST";
export const GET_CONTACTS_INFO = "GET_CONTACTS_INFO";
export const CLEAR_CONTACT_INFO = "CLEAR_CONTACT_INFO";
export const CONTACT_CREATED = "CONTACT_CREATED";
export const CONTACT_ERROR = "CONTACT_ERROR";
export const RESET_ERROR = "RESET_ERROR";
export const CREATE_TIME = "CREATE_TIME";
export const TIME_ERROR = "TIME_ERROR";
export const RESET_TIME_ERROR = "RESET_TIME_ERROR";
export const ADD_FILES_DISBURSEMENT_POSTING = "ADD_FILES_DISBURSEMENT_POSTING";
export const REMOVE_FILES_DISBURSEMENT_POSTING =
  "REMOVE_FILES_DISBURSEMENT_POSTING";
export const CLEAR_FILES_DISBURSEMENT_POSTING =
  "CLEAR_FILES_DISBURSEMENT_POSTING";
export const ADD_DISBURSEMENT_MATTER_LIST = "ADD_DISBURSEMENT_MATTER_LIST";
export const REMOVE_DISBURSEMENT_MATTER_LIST = "ADD_DISBURSEMENT_MATTER_LIST";
export const ADD_OFFICE_CREDIT_MATTER_LIST = "ADD_OFFICE_CREDIT_MATTER_LIST";
export const REMOVE_OFFICE_CREDIT_MATTER_LIST = "ADD_OFFICE_CREDIT_MATTER_LIST";
export const ADD_FILES_BILL = "ADD_FILES_BILL";
export const REMOVE_FILES_BILL = "REMOVE_FILES_BILL";
export const CLEAR_FILES_BILL = "CLEAR_FILES_BILL";
export const ADD_MATTER_TIME_BILL = "CLEAR_FILES_BILL";
export const CLEAR_MATTER_TIME = "CLEAR_FILES_BILL";
export const POST_BILL = "POST_BILL";
export const BILL_ERROR = "BILL_ERROR";
export const CREATE_BILL = "CREATE_BILL";
export const CLEAR_BILL_ERROR = "CLEAR_BILL_ERROR";
export const ADD_MATTER_DISB_BILL = "ADD_MATTER_DISB_BILL";
export const CLEAR_MATTER_DISB_TIME = "CLEAR_MATTER_DISB_TIME";
export const ADD_FILES_OFFICE_AND_ALLOCATIONS =
  "ADD_FILES_OFFICE_AND_ALLOCATIONS";
export const CLEAR_FILES_OFFICE_AND_ALLOCATIONS =
  "CLEAR_FILES_OFFICE_AND_ALLOCATIONS";
export const REMOVE_FILES_OFFICE_AND_ALLOCATIONS =
  "REMOVE_FILES_OFFICE_AND_ALLOCATIONS";
export const ADD_FILES_CHIT_REQUEST = "ADD_FILES_CHIT_REQUEST";
export const CLEAR_FILES_CHIT_REQUEST = "CLEAR_FILES_CHIT_REQUEST";
export const REMOVE_FILES_CHIT_REQUEST = "REMOVE_FILES_CHIT_REQUEST";
export const ADD_OFFICE_POSTINGS = "ADD_OFFICE_POSTINGS";
export const CLEAR_OFFICE_POSTINGS = "CLEAR_OFFICE_POSTINGS";
export const REMOVE_OFFICE_POSTINGS = "REMOVE_OFFICE_POSTINGS";
export const REMOVE_CLIENT_POSTINGS = "REMOVE_CLIENT_POSTINGS";
export const ADD_CLIENT_POSTINGS = "ADD_CLIENT_POSTINGS";
export const CLEAR_CLIENT_POSTINGS = "CLEAR_CLIENT_POSTINGS";
export const GET_OFFICE_POSTING_DETAILS = "GET_OFFICE_POSTING_DETAILS";
export const GET_CLIENT_POSTING_DETAILS = "GET_CLIENT_POSTING_DETAILS";
export const CHANGE_HEADER_TAB_NAME = "CHANGE_HEADER_TAB_NAME";
export const CLEAR_MATTERS_BALANCES = "CLEAR_MATTERS_BALANCES";
export const GET_CHIT_REQUEST_TYPES = "GET_CHIT_REQUEST_TYPES";
export const CHANGE_REF = "CHANGE_REF";
export const CLEAR_REF = "CLEAR_REF";
export const GET_CHIT_POSTING_LISTS = "GET_CHIT_POSTING_LISTS";
export const OPEN_MATTER_VIEW = "OPEN_MATTER_VIEW";
export const CLOSE_MATTER_VIEW = "CLOSE_MATTER_VIEW";
export const GET_MATTERS_VIEW_DETAILS = "GET_MATTERS_VIEW_DETAILS";
export const ADD_MATTER_VIEW_TAB = "ADD_MATTER_VIEW_TAB";
export const REMOVE_MATTER_VIEW_TAB = "REMOVE_MATTER_VIEW_TAB";
export const ADD_PROFIT_COST_TEMPLATE = "ADD_PROFIT_COST_TEMPLATE";
export const GET_PROFIT_COST_TEMPLATE = "GET_PROFIT_COST_TEMPLATE";
export const ADD_NEW_APPLICATION = "ADD_NEW_APPLICATION";
export const GET_APPLICATIONS = "GET_APPLICATIONS";
export const EDIT_APPLICATIONS = "EDIT_APPLICATIONS";
export const GET_ACTIVE_APPLICATIONS = "GET_ACTIVE_APPLICATIONS";
export const EDIT_FEE_HEADER = "EDIT_FEE_HEADER";
export const GET_FEE_HEADER_ITEMS = "GET_FEE_HEADER_ITEMS";
export const CLEAR_FEE_HEADER_ITEM = "CLEAR_FEE_HEADER_ITEM";
export const GET_ACTIVE_FEE_HEADERS = "GET_ACTIVE_FEE_HEADERS";
export const GET_ACTIVE_FEE_ITEMS = "GET_ACTIVE_FEE_ITEMS";
export const EMPTY_ACTIVE_FEE_ITEMS = "EMPTY_ACTIVE_FEE_ITEMS";
export const SET_MAIN_LOADING = "SET_MAIN_LOADING";
export const GET_CLIENTS_LIST = "GET_CLIENTS_LIST";
export const ADD_CLIENTS_VIEW_TAB = "ADD_CLIENTS_VIEW_TAB";
export const REMOVE_CLIENT_VIEW_TAB = "REMOVE_CLIENT_VIEW_TAB";
export const SET_NOMINALS_PROFIT_AND_LOSS = "SET_NOMINALS_PROFIT_AND_LOSS";
export const SET_NOMINALS_DEFAULT_PROFIT_AND_LOSS =
  "SET_NOMINALS_DEFAULT_PROFIT_AND_LOSS";
export const ADD_CATEGORY = "ADD_CATEGORY";
export const EDIT_CATEGORY_LIST = "EDIT_CATEGORY_LIST";
export const GET_CATEGORY_LIST = "GET_CATEGORY_LIST";
export const ADD_DEPARTMENT = "ADD_DEPARTMENT";
export const EDIT_DEPARTMENT_LIST = "EDIT_DEPARTMENT_LIST";
export const GET_DEPARTMENT_LIST = "GET_DEPARTMENT_LIST";
export const ADD_ACTIVITY = "ADD_ACTIVITY";
export const EDIT_ACTIVITY_LIST = "EDIT_ACTIVITY_LIST";
export const GET_ACTIVITY_LIST = "GET_ACTIVITY_LIST";
export const ADD_TIME_TYPE = "ADD_TIME_TYPE";
export const EDIT_TIME_TYPE_LIST = "EDIT_TIME_TYPE_LIST";
export const GET_TIME_TYPE_LIST = "GET_TIME_TYPE_LIST";
export const EDIT_DOC_TYPE_LIST = "EDIT_DOC_TYPE_LIST";
export const GET_DOC_TYPE_LIST = "GET_DOC_TYPE_LIST";
export const CHNAGE_CLINET_EIDT_PERMISSION = "CHNAGE_CLINET_EIDT_PERMISSION ";
export const SET_CLIENT_DROPDONW_DATA = "SET_CLIENT_DROPDONW_DATA";
export const UPDATE_CLIENT_VIEW_TAB = "UPDATE_CLIENT_VIEW_TAB";
export const GET_MY_MATTERS_LIST = "GET_MY_MATTERS_LIST";
export const ADD_DASHBOARD_MATTER_FILTER = "ADD_DASHBOARD_MATTER_FILTER";
export const CURRENCT_NOMINAL_REF = "CURRENCT_NOMINAL_REF";
export const GET_BATCH_POSTING_LISTS = "GET_BATCH_POSTING_LISTS";
export const ADD_PAY_SUPPLIER_LIST = "ADD_PAY_SUPPLIER_LIST";
export const REMOVE_SUPPLIER_LIST = "REMOVE_SUPPLIER_LIST";
export const INITIAL_LOADING = "INITIAL_LOADING";
export const GET_REFERRER = "GET_REFERRER";
export const EDIT_REFERRER = "EDIT_REFERRER";
export const GET_SOURCE = "GET_SOURCE";
export const EDIT_SOURCE = "EDIT_SOURCE";
export const GET_ACTIVE_SOURCE = "GET_ACTIVE_SOURCE";
export const GET_ACTIVE_REFERRERS = "GET_ACTIVE_REFERRERS";
export const HANDLE_CASE_FILE_TABS = "HANDLE_CASE_FILE_TABS";
export const ADD_CASE_FILE_TAB = "ADD_CASE_FILE_TAB";
export const REMOVE_CASE_FILE_TAB = "REMOVE_CASE_FILE_TAB";
export const GET_CONTACTS_BY_CATEGORY = "GET_CONTACTS_BY_CATEGORY";
export const GET_CONTACTS_ON_MATTER = "GET_CONTACTS_ON_MATTER";
export const GET_ALL_CONTACTS = "GET_ALL_CONTACTS";
export const GET_WORKTYPE_DEPARTMENT = "GET_WORKTYPE_DEPARTMENT";
export const FETCH_MATTER_ACTIVITIES = "FETCH_MATTER_ACTIVITIES";
export const FETCH_MATTER_TASKS = "FETCH_MATTER_TASKS";
export const ADD_CONTACTS_VIEW_TAB = "ADD_CONTACTS_VIEW_TAB";
export const GET_DASHBOARD_TIME_TARGET = "GET_DASHBOARD_TIME_TARGET";
export const GET_DASHBOARD_BILLING_TARGET = "GET_DASHBOARD_BILLING_TARGET";
export const GET_DASHBOARD_MATTER_DATA = "GET_DASHBOARD_MATTER_DATA";
