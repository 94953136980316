import * as React from "react";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { CurrencyLoader } from "./currency-loader";
import { Button } from "@progress/kendo-react-buttons";
import { editCurrencyinfo } from "actions/currency.action";
import { useDispatch } from "react-redux";
import EditCurrency from "./Editcurrency.jsx";
import styles from "../Settings.module.scss"
import { ActiveCell, EditCommandCell, SignatureCell } from "../GridComponents";


const Currencylist = () => {
    const [openForm, setOpenForm] = React.useState(false);
    const dispatch = useDispatch();
    const [editItem, setEditItem] = React.useState({
        ProductID: 1,
    });
    const enterEdit = (item) => {
        setOpenForm(true);
        setEditItem(item);
    };
    const handleSubmit = (event) => {
        let data = {
            currencyCode: event.currencyCode,
            name: event.name,
            symbol: event.symbol,
            active: event.active,
        };
        dispatch(editCurrencyinfo(event.currencyCode, data));
        setOpenForm(false);
    };
    const [products, setProducts] = React.useState({
        data: [],
        total: 20,
    });
    const [dataState, setDataState] = React.useState({
        take: 10,
        skip: 0,
    });

    const handleCancelEdit = () => {
        setOpenForm(false);
    };
    const MyEditCommandCell = (props) => (
        <EditCommandCell {...props} enterEdit={enterEdit} />
    );
    const dataStateChange = (e) => {
        setDataState(e.dataState);
    };

    const dataReceived = (products) => {
        setProducts(products);
    };

    return (
        <div className='settings-grid'>
            <Grid
                {...dataState}
                data={products}
                onDataStateChange={dataStateChange}
            >
                <Column field="symbol" title=" " cell={SignatureCell} width="80" />
                <Column field="currencyCode" title="Code" width="100" />
                <Column field="name" title="Name" />
                <Column cell={ActiveCell} title="Active" field='active' width="80" />
                <Column title=" " cell={MyEditCommandCell} width="60" />
            </Grid>
            {openForm && (
                <EditCurrency
                    cancelEdit={handleCancelEdit}
                    onSubmit={handleSubmit}
                    item={editItem}
                />
            )}

            <CurrencyLoader dataState={dataState} onDataReceived={dataReceived} />
        </div>
    );
};

export default Currencylist;
