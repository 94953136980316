import {
    START_LOADING,
    STOP_LOADING,
    ERROR,
    SUCCESS,
    RESET,
    GET_ACTIVITY_LIST,
    EDIT_ACTIVITY_LIST
} from "../types/index.js";

const ActivityReducer = (
    state = {
        IsError: false,
        isSuccess: false,
        isLoading: undefined,
    },
    action
) => {
    switch (action.type) {
        case START_LOADING:
            return { ...state, isLoading: true };
        case STOP_LOADING:
            return { ...state, isLoading: false };
        case GET_ACTIVITY_LIST:
            return {
                ...state,
                activitiesList: action.payload,
            };
        case EDIT_ACTIVITY_LIST:
            return {
                ...state,
                activitiesList: action.payload,
            };
        case RESET:
            const data = state;
            data.activitiesList = undefined;
            return data;
        case ERROR:
            return { ...state, IsError: true, isSuccess: false };
        case SUCCESS:
            return { ...state, IsError: false, isSuccess: true };
        default:
            return state;
    }
};
export default ActivityReducer;
