import {
  START_LOADING,
  STOP_LOADING,
  ERROR,
  SUCCESS,
  GET_Suppliers_LIST,
  GET_CONSULTANT_LIST,
  ADD_SUPPLIER,
  GET_VIEW_SUPPLIER,
  UPDATE_SUPPLIER,
  SEARCH_SUPPLIER,
  GET_SUPPLIERREFS,
  GET_SUPPLIERS_BY_REF,
  GET_SUPPLIER_BALANCES,
  GET_CONSULTANTREFS,
} from "../types/index.js";

const suppliersReducer = (
  state = {
    IsError: false,
    isSuccess: false,
    isLoading: undefined,
    supplierrefs: { data: [] },
    consultantrefs: { data: [] },
    supplierRefBalances: "",
  },
  action
) => {
  switch (action.type) {
    case START_LOADING:
      return { ...state, isLoading: true };
    case STOP_LOADING:
      return { ...state, isLoading: false };
    case GET_Suppliers_LIST:
      return {
        ...state,
        suppliersList: action.payload,
        isUpdated: false,
      };
    case GET_CONSULTANT_LIST:
      return {
        ...state,
        consultantsList: action.payload,
      };
    case ADD_SUPPLIER:
      return {
        ...state,
        suppliersList: action.payload,
        isUpdated: true,
      };
    case GET_VIEW_SUPPLIER:
      return {
        ...state,
        viewSupplier: action.payload,
      };
    case GET_SUPPLIERREFS:
      return {
        ...state,
        supplierrefs: action.payload,
      };
    case GET_CONSULTANTREFS:
      return {
        ...state,
        consultantrefs: action.payload,
      };
    case UPDATE_SUPPLIER:
      return {
        ...state,
        suppliersList: action.payload,
        isUpdated: true,
      };
    case SEARCH_SUPPLIER:
      return {
        ...state,
        suppliersList: action.payload,
      };
    case GET_SUPPLIERS_BY_REF:
      return {
        ...state,
        getSuppliersByRefs: action.payload,
      };
    case GET_SUPPLIER_BALANCES:
      return {
        ...state,
        supplierRefBalances: action.payload,
      };
    case ERROR:
      return { ...state, IsError: true, isSuccess: false };
    case SUCCESS:
      return { ...state, IsError: false, isSuccess: true };
    default:
      return state;
  }
};
export default suppliersReducer;
