import React from "react";
import { Field } from "@progress/kendo-react-form";
import { NumericTextBox } from "@progress/kendo-react-inputs";
import CustomInput from "components/common/Input";
import { FormComboBox } from "components/Matters";
import CustomDatePicker from "components/common/CustomDatePicker";
import { Checkbox, Input, MaskedTextBox } from "@progress/kendo-react-inputs";
import {
  useFetchCurrency,
  useFetchFeeEarner,
  useFetchVatCode,
} from "./FetchData";
import { Tile } from "components/UIComponents/Tile";
import { ComboBox } from "@progress/kendo-react-dropdowns";

const CreditsDefaults = ({
  readOnly,
  comboBoxState,
  setComboBoxState,
  initialValues,
}) => {
  const currencyData = useFetchCurrency();
  const feeEarnerData = useFetchFeeEarner();
  const vatData = useFetchVatCode();

  return (
    <div className="mt-3">
      <div className="row g-2">
        <div className="col-12 col-xl-6">
          <Tile
            heading="Credit Limit"
            height="280px"
            className="px-4  py-2  overflow-auto"
          >
            <div className="row">
              <div className="col-6 d-flex flex-column right-align">
                <label className="label">Credit Limit</label>
                <Field
                  name="creditLimit"
                  style={{ width: "240px", height: "32px" }}
                  placeholder="0.00"
                  spinners={false}
                  format="n2"
                  component={NumericTextBox}
                />
              </div>
              <div className="col-6 d-flex flex-column right-align">
                <label className="label">Balance</label>
                <Field
                  name="balance"
                  style={{ width: "240px", height: "32px" }}
                  placeholder="0.00"
                  spinners={false}
                  format="n2"
                  component={NumericTextBox}
                />
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-6 me-2">
                <label className="label">Credit Control Stage</label>
                <Field
                  name="creditControlStage"
                  style={{ width: "240px", height: "32px" }}
                  placeholder="Enter Stage"
                  component={CustomInput}
                />
              </div>
              <div className="col-4 mt-4">
                <Checkbox label={"On Hold"} />
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-6 form-date-picker me-2">
                <label className="label">Date Stage Set</label>
                <CustomDatePicker
                  name="dateStageSet"
                  width="240px"
                  style={{ width: "240px", height: "32px" }}
                  placeholder="Select Date"
                  defaultValue={new Date()}
                />
              </div>
              <div className="col-4 mt-4">
                <Checkbox label={"Suspended"} />
              </div>
            </div>
          </Tile>
        </div>
        <div className="col-12 col-xl-6">
          <Tile
            heading="Default Codes"
            height="280px"
            className="px-4 py-2 overflow-auto"
          >
            <div className={`container-fluid p-0`}>
              <div className="row mb-2">
                <div className="col-6 d-flex flex-column">
                  <FormComboBox
                    name="vatCodeId"
                    data={vatData || []}
                    label={"Vat Code"}
                    textField={`representation`}
                    width={"240px"}
                    style={{ width: "240px", height: "32px" }}
                    state={comboBoxState}
                    isStyled={true}
                    wf={true}
                    onChangeComboBox={(e) => {
                      setComboBoxState({
                        ...comboBoxState,
                        vatCodeId: e?.value,
                      });
                    }}
                    placeholder={"Select Vat Code"}
                    iconClassName="wa"
                  />
                </div>
                <div className={`col-6 d-flex right-align flex-column mb-2`}>
                  <label className="label">Rate</label>
                  <Field
                    name="rate"
                    style={{ width: "220px", height: "32px" }}
                    placeholder="0.00"
                    spinners={false}
                    isStyled={true}
                    format="n2"
                    component={NumericTextBox}
                  />
                </div>
              </div>
              <div className="row d-flex flex-column mb-2">
                <div className="col-6">
                  <FormComboBox
                    name="feeEarner"
                    data={feeEarnerData || []}
                    label={"Fee Earner"}
                    textField={`representation`}
                    placeholder={"Select Fee Earner"}
                    iconClassName="wa"
                    wf={true}
                    isStyled={true}
                    width={"240px"}
                    style={{ width: "240px", height: "32px" }}
                    state={comboBoxState}
                    onChangeComboBox={(e) => {
                      setComboBoxState({
                        ...comboBoxState,
                        feeEarner: e?.value,
                      });
                    }}
                  />
                </div>
              </div>
              <div className="row d-flex flex-column">
                <div className="col-6">
                  <FormComboBox
                    name="currency"
                    data={currencyData || []}
                    textField={`representation`}
                    label={"Currency"}
                    isStyled={true}
                    wf={true}
                    width={"240px"}
                    placeholder={"Select Currency Code"}
                    iconClassName="wa"
                    style={{ width: "240px", height: "32px" }}
                    onChangeComboBox={(e) => {
                      setComboBoxState({
                        ...comboBoxState,
                        currency: e?.value,
                      });
                    }}
                    component={ComboBox}
                    state={comboBoxState}
                  />
                </div>
              </div>
            </div>
          </Tile>
        </div>
      </div>
      <div className="row mt-2 g-2">
        <div className="col-12 col-xl-6">
          <Tile
            heading="Payment Bank"
            height="280px"
            className="px-4 py-2 overflow-auto"
          >
            <div className="row">
              <div className="col-6 ">
                <Field
                  name="accountTitle"
                  label="Account Title"
                  ph="Enter Account Title"
                  width="240px"
                  component={CustomInput}
                />
              </div>
              <div className="col-6">
                <Field
                  name="accountType"
                  label="Account Type"
                  ph="Enter Account Type"
                  width="240px"
                  component={CustomInput}
                />
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-6">
                <Field
                  name="sortCode"
                  label="Sort Code"
                  style={{ width: "240px", height: "32px" }}
                  mask="00-00-00"
                  placeholder="Enter Sort Code"
                  component={MaskedTextBox}
                />
              </div>
              <div className="col-6">
                <Checkbox label={"BACS Payment"} className="mt-4" />
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-6">
                <Field
                  name="accountNo"
                  label="Account Number"
                  spinners={false}
                  style={{ width: "240px", height: "32px" }}
                  placeholder="Enter Account Number"
                  component={NumericTextBox}
                />
              </div>
              <div className="col-6">
                <Field
                  name="bacsCode"
                  label="BACS Code"
                  spinners={false}
                  style={{ width: "240px", height: "32px" }}
                  placeholder="Enter BACS Code"
                  component={NumericTextBox}
                />
              </div>
            </div>
          </Tile>
        </div>
      </div>
    </div>
  );
};

export default CreditsDefaults;
