import React from "react"; // Add the missing import statement for React
import styles from "./Badges.module.scss";

export const PrimaryBadgeComponent = (props) => {
  return (
    <p
      className={`badge rounded-pill ${styles["primary-pill"]} ${props.className} p-2 py-1`}
      style={props.style}
    >
      {props.text}
    </p>
  );
};

export const SecondaryBadgeComponent = (props) => {
  return (
    <p
      className={`badge rounded-pill ${styles["secondary-pill"]} ${props.className} py-1`}
    >
      {props.text}
    </p>
  );
};

export const ImageBadgeComponent = (props) => {
  return (
    <div
      className={`rounded-pill ${styles["image-pill"]} ps-3 pe-3 ms-2 me-2 py-1`}
      style={
        props.className === "selected" ? { border: "1px solid #162EFF", color:"#000000" } : null}
    >
      {props.src &&
      <div className={`${styles[props.className]}`}>
        <img src={props.src} alt={props.alt} />
      </div>
      }
      {props.text}
    </div>
  );
};