import React, { useState } from "react";
import ReportViewer from "../Reports/ReportViewerComponent";
import { RadioGroup, Checkbox } from "@progress/kendo-react-inputs";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { Button } from "@progress/kendo-react-buttons";
import { Label } from "@progress/kendo-react-labels";
import CalenderCustomToggleButton from "components/common/CalenderCustomToogleButton";
import { CustomDateInput } from "components/common/CustomDateInput";
import { Dialog } from "@progress/kendo-react-dialogs";

const BillingModal = (props) => {
  const DateFormat = (value) => {
    let date = new Date(value);
    return date.toISOString().split("T")[0];
  };
  const typesData = [
    {
      label: "All Time",
      value: "All",
    },
    {
      label: "Billed Time",
      value: "Billed",
    },
    {
      label: "Unbilled Time",
      value: "Unbilled",
    },
  ];
  const sortData = [
    {
      label: "Date",
      value: "Date",
    },
    {
      label: "Fee Earner",
      value: "FeeEarner",
    },
  ];
  let date = new Date();
  const [fromDate, setFromDate] = React.useState(date);
  const analyzeParameters = () => {
    setParameters({
      TimeTime: type,
      IncludeDisb: includeDisb,
      ToDate: includeEndDate ? DateFormat(endDate) : undefined,
      fromDate: DateFormat(fromDate),
      SortBy: sortbyType,
      matterID: props?.matterId,
    });
    setShouldLoad(true);
    setShowReport(true);
  };
  const [type, setType] = useState(typesData[0].value);
  const [sortbyType, setSortByType] = useState(sortData[0].value);
  const [loading, setLoading] = useState(false);
  const [includeFromDate, setIncludeFromDate] = useState(false);
  const [includeEndDate, setIncludeEndDate] = useState(false);
  const [includeDisb, setIncludeDisb] = useState(true);
  const [shouldLoad, setShouldLoad] = useState(false);
  const [endDate, setEndDate] = React.useState(date);
  const [parameters, setParameters] = useState({
    TimeTime: type,
    IncludeDisb: includeDisb,
    ToDate: includeEndDate ? DateFormat(endDate) : undefined,
    SortBy: sortbyType,
    matterID: props?.matterId,
  });
  const [showReport, setShowReport] = useState(false);
  const [exportFunction, setExportFunction] = useState(false);

  const handleExport = () => {
    setLoading(true);
    setExportFunction(true);
  };

  const onChangeFromDateSelect = (event) => {
    if (includeFromDate) {
      setFromDate(event.value);
    }
  };
  const onChangeEndSelect = (event) => {
    if (includeEndDate) {
      setEndDate(event.value);
    }
  };
  const UpdateButton = (
    <Button className="common-btn" onClick={analyzeParameters}>
      OK
    </Button>
  );
  const fromDatePicker = (
    <div className="d-flex align-items-center justify-content-center">
      <Checkbox
        // defaultChecked={false}
        value={includeFromDate}
        className="ms-2 me-1 mt-auto mb-auto"
        onChange={(e) => {
          setIncludeFromDate(e.value);
        }}
        label={""}
      />
      <DatePicker
        id="dp"
        className="date-picker"
        value={fromDate || null}
        onChange={onChangeFromDateSelect}
        dateInput={CustomDateInput}
        toggleButton={CalenderCustomToggleButton}
        defaultValue={fromDate || null}
        placeholder="Select from date"
      />
    </div>
  );
  const toDatePicker = (
    <div className="d-flex align-items-center justify-content-center">
      <Checkbox
        value={includeEndDate}
        className="ms-2 me-1 mt-auto mb-auto"
        onChange={(e) => {
          setIncludeEndDate(e.value);
        }}
        label={""}
      />
      <DatePicker
        id="to"
        className="date-picker"
        value={endDate || null}
        dateInput={CustomDateInput}
        onChange={onChangeEndSelect}
        toggleButton={CalenderCustomToggleButton}
        defaultValue={endDate || null}
        placeholder="Select to date"
      />
    </div>
  );
  const radioGroup = (
    <RadioGroup
      className=""
      data={typesData}
      value={type}
      onChange={(e) => {
        setType(e.value);
      }}
    />
  );
  const IncludeCheckbox = (
    <Checkbox
      // defaultChecked={false}
      value={includeDisb}
      className="checkbox-label"
      onChange={(e) => {
        setIncludeDisb(e.value);
      }}
      label={"Include Disbursement"}
    />
  );
  const sortByRadioGroup = (
    <RadioGroup
      className=""
      data={sortData}
      value={sortbyType}
      onChange={(e) => {
        setSortByType(e.value);
      }}
    />
  );

  const reportViewer = (
    <ReportViewer
      modalHeight={"65vh"}
      name="BillingGuide.trdp"
      matterID={props?.matterId}
      saveActivity={true}
      parameters={parameters}
      shouldLoad={shouldLoad}
      exportCallback={() => {
        setExportFunction(false);
        setLoading(false);
        setShowReport(false)
        props?.onClose()
      }}
      exportFunction={exportFunction}
      setShouldLoad={setShouldLoad}
    />
  );

  return (
    <>
      <Dialog
        title={"Billing Guide"}
        onClose={props?.onClose}
        width={"36vw"}
        minWidth={"600px"}
      >
        <div className="px-5 mx-3 py-4 ">
          <div className={`row py-2 `}>
            <Label className="label fw-bold  ">Date </Label>
            <div className=" my-2  border-bottom border-opacity-50"></div>

            <div className="col ">
              <Label className="label mb-1">From</Label>
              <div className="no-borders">{fromDatePicker}</div>
            </div>

            <div className="col ">
              <Label className="label mb-1 ">To</Label>
              <div className="no-borders">{toDatePicker}</div>
            </div>
          </div>
          <div>
            <div className=" mt-3  ">
              <Label className="label fw-bold  ">Show Time</Label>
              <div className=" my-2  border-bottom border-opacity-50"></div>

              <div className="py-2 d-flex radio flex-row radio-horizontal">
                {radioGroup}
              </div>
            </div>

            <div className=" mt-3  ">
              <Label className="label  fw-bold">Sort By</Label>
              <div className=" my-2  border-bottom border-opacity-50"></div>

              <div className=" d-flex py-2 radio flex-row radio-horizontal">
                {sortByRadioGroup}
              </div>
              <div className=" my-2  border-bottom border-opacity-50"></div>
            </div>

            <div className="py-3">{IncludeCheckbox}</div>
          </div>
        </div>
        <div className="dialog-footer">
          <div className="me-3">{UpdateButton}</div>
        </div>
      </Dialog>

      {showReport && (
        <Dialog
          title={"Billing Guide Report"}
          onClose={props?.onClose}
          width={"50vw"}
        >
          <div
            className="mb-2 px-4 w-100"
            style={{ height: "60vh", overflowY: "hidden" }}
          >
            {reportViewer}
          </div>
          <div className="dialog-footer">
            <Button className="common-btn bgBtnSecondary me-2" onClick={() => setShowReport(false)}>Cancel</Button>
            <Button
              onClick={handleExport}
              disabled={loading}
              className="common-btn me-3"
            >
              {exportFunction ? "Saving" : "Save"}
            </Button>
          </div>
        </Dialog>
      )}
    </>
  );
};

export default BillingModal;
