import { LOGGED_IN, LOGGED_OUT, RESPONSE_ERROR_LOGIN } from "../types/index.js";

const AutoLoginReducer = (
  state = {
    login: false,
    responseError: false,
  },
  action
) => {
  switch (action.type) {
    case LOGGED_IN:
      return { login: true };
    case LOGGED_OUT:
      return { login: false };
    case RESPONSE_ERROR_LOGIN:
      state.responseError = true;
      return {...state};
    default:
      return state;
  }
};
export default AutoLoginReducer;
