import React from "react";
import DataList from "./DataList";
import styles from "./MatterDetails.module.scss";
import { Avatar } from "@progress/kendo-react-layout";
import EmailICon from "../../assets/email.png";
import PersonIcon from "../../assets/person.png";
import CompanyIcon from "../../assets/company.png";
import LocationICon from "../../assets/location.png";

import PhoneIcon from "../../assets/phone.png";

export const NameAlias = (props) => {
  return (
    <Avatar
      type={"image"}
      shape={"circle"}
      className={`${styles["contact-avatar"]}`}
      style={{ cursor: "pointer" }}
    >
      <div className="d-flex align-items-center justify-content-center">
        {props.data}
      </div>
    </Avatar>
  );
};

const TypeLogo = ({ type }) => {
  return (
    <img
      className="me-3"
      src={type === "Person" ? PersonIcon : CompanyIcon}
    ></img>
  );
};

const ContactCard = ({
  index,
  isMultiple,
  title,
  name,
  type,
  email,
  phone,
  address,
}) => (
  <div
    className={`pt-2 pb-2 px-4 pe-0 mt-2 me-1 ${styles["col-2-sec"]}`}
    style={{
      boxShadow: index === 0 && "none",
    }}
  >
    <div className="mt-1 d-flex align-items-center justify-content-between">
      <div>
        <NameAlias data={name?.charAt(0)} />
        <span className="ps-1"> {name}</span>
      </div>
      {/* <div className={`${styles["detail-key"]}`}>
        {type}
      </div> */}
    </div>
    <div className="d-flex align-items-center justiy-content-between gap-5">
      <div className="d-flex justify-content-center align-items-center">
        <div
          className={`${styles["white-circle"]} mb-1 me-2 d-flex justify-content-center align-items-center`}
        >
          <img src={EmailICon}></img>
        </div>
        <div
          className={`${styles["detail-value"]}`}
          style={{ width: "150px", overflow: "hidden" }}
        >
          {email}
        </div>
      </div>
      <div className="d-flex justify-content-center align-items-center mt-2">
        <div
          className={`${styles["white-circle"]} mb-2 me-2 d-flex justify-content-center align-items-center`}
        >
          <img src={PhoneIcon}></img>
        </div>
        <div
          className={`${styles["detail-value"]}`}
          style={{ maxWidth: "150px" }}
        >
          {phone}
        </div>
      </div>
    </div>

    {/* <div className="d-flex align-items-center">
      <div
        className={`${styles["white-circle"]} mb-2 me-2 d-flex justify-content-center align-items-center`}
      >
        <img src={PhoneIcon}></img>
      </div>
      <DataList text="Phone" value={phone} type="ContactCard" />
    </div>

    <div className="d-flex align-items-center">
      <div
        className={`${styles["white-circle"]} mb-2 me-2 d-flex justify-content-center align-items-center`}
      >
        <img src={LocationICon}></img>
      </div>
      <DataList text="Address" value={address} type="ContactCard" />
    </div> */}
  </div>
);

export default ContactCard;

export const NoContacts = () => {
  return (
    <div>
      <div className="d-flex flex-column justify-content-center align-items-center mt-5">
        <div
          className={`${styles["white-circle"]} mb-2 me-2 d-flex justify-content-center align-items-center`}
        >
          <img src={PhoneIcon}></img>
        </div>
        <div
          className={`${styles["detail-value"]} mb-1`}
          style={{ maxWidth: "150px" }}
        >
          No Contacts
        </div>
      </div>
    </div>
  );
};

export const ContactsListItem = ({ type, value }) => {
  switch (type) {
    case "email":
      return (
        <div className="d-flex align-items-center mb-2 mt-2">
          <div
            className={`${styles["white-circle"]} mb-1 me-4 d-flex justify-content-center align-items-center`}
          >
            <img src={EmailICon} alt="emailIcon"></img>
          </div>
          <div
            className={`${styles["detail-value"]}`}
            style={{ width: "max-content", overflow: "hidden" }}
          >
            {value}
          </div>
        </div>
      );
    case "phone":
      return (
        <div className="d-flex align-items-center mb-2 mt-2">
          <div
            className={`${styles["white-circle"]} mb-1 me-4 d-flex justify-content-center align-items-center`}
          >
            <img src={PhoneIcon} alt="phoneIcon"></img>
          </div>
          <div
            className={`${styles["detail-value"]}`}
            style={{ width: "max-content", overflow: "hidden" }}
          >
            {value}
          </div>
        </div>
      );
    case "location":
      return (
        <div className="d-flex align-items-center mb-2 mt-2">
          <div
            className={`${styles["white-circle"]} mb-1 me-4 d-flex justify-content-center align-items-center`}
          >
            <img src={LocationICon} alt="locationIcon"></img>
          </div>
          <div
            className={`${styles["detail-value"]}`}
            style={{ width: "max-content", overflow: "hidden" }}
          >
            {value}
          </div>
        </div>
          );
      case "person":
          return (
              <div className="d-flex align-items-center mb-1 mt-2">
                  <div
                      className={`${styles["white-circle"]} mb-1 me-4 d-flex justify-content-center align-items-center`}
                  >
                      <img src={PersonIcon} alt="personIcon"></img>
                  </div>
                  <div
                      className={`${styles["detail-value"]}`}
                      style={{ width: "max-content", overflow: "hidden" }}
                  >
                      {value}
                  </div>
              </div>
          );
    default:
      break;
  }
  return {};
};


/**
 * @deprecated Use Tile from components/UIComponent/Tile.jsx
 */
export const ContainerCard = (props) => {
  return (
    <div
      className={`${styles["container-shadow"]} ${props.className}`}
      style={{
        ...props.style,
        height: props.height,
        width: props.width,
      }}
    >
      {props.children}
    </div>
  );
};

/**
 * @deprecated Use PrimaryBadgeComponent from components/UIComponent/Badges.jsx
 */
export const PrimaryBadgeComponent = (props) => {
  return (
    <p
      className={`badge rounded-pill ${styles["primary-pill"]} ${props.className} p-2 py-1`}
    >
      {props.text}
    </p>
  );
};


/**
 * @deprecated Use SecondaryBadgeComponent from components/UIComponent/Badges.jsx
 */
export const CategoryBadgeComponent = (props) => {
  return (
    <p
      className={`badge rounded-pill ${styles["category-pill"]} ${props.className} py-1`}
    >
      {props.text}
    </p>
  );
};
