import {
  START_LOADING,
  STOP_LOADING,
  ERROR,
  SUCCESS,
  GET_MATTERS_LIST,
  GET_MATTERS_DETAILS,
  SEARCH_MATTER,
  GET_MATTERS_BALANCES,
  GET_MATTERS_POSTINGS,
  CLEAR_MATTERS_POSTINGS,
  CLEAR_MATTERS_BALANCES,
  GET_MATTERS_VIEW_DETAILS,
  ADD_MATTER_VIEW_TAB,
  REMOVE_MATTER_VIEW_TAB,
  GET_MY_MATTERS_LIST,
  FETCH_MATTER_ACTIVITIES,
  FETCH_MATTER_TASKS,
  ADD_CLIENTS_VIEW_TAB,
  ADD_CONTACTS_VIEW_TAB,
} from "../types/index";
import * as api from "../api/index.js";
import { handleApiError } from "Helper";

export const getMattersList =
  (page, limit, sort, filterParameters, status) => async (dispatch) => {
    try {
      dispatch({ type: START_LOADING });
      let parameter;
      if (sort === undefined) {
        parameter = "matterRef";
      } else {
        if (sort.dir === "asc") {
          parameter = sort.field;
        } else if (sort.dir === "desc") {
          parameter = sort.field + " desc";
        }
      }
      const datas = await api.getMattersList(
        page,
        limit,
        parameter,
        filterParameters,
        status
      );
      dispatch({ type: GET_MATTERS_LIST, payload: datas.data });
      dispatch({ type: STOP_LOADING });
      dispatch({ type: SUCCESS, payload: datas.message });
    } catch (error) {
      handleApiError(error);
    }
  };

export const searchMatter = (page, limit, search) => async (dispatch) => {
  try {
    const res = await api.searchMatter(page, limit, search);
    dispatch({ type: SEARCH_MATTER, payload: res.data });
  } catch (error) {
    handleApiError(error);
  }
};

export const getMattersInfo = (id) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const datas = await api.getMattersdetails(id);
    dispatch({ type: GET_MATTERS_DETAILS, payload: datas.data });
    dispatch({ type: STOP_LOADING });
    dispatch({ type: SUCCESS, payload: datas.message });
  } catch (error) {
    handleApiError(error);
  }
};

export const getMattersBalances = (id, component) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const datas = await api.getMattersBalances(id);
    datas.data.component = component;
    dispatch({ type: GET_MATTERS_BALANCES, payload: datas.data });
    dispatch({ type: STOP_LOADING });
    dispatch({ type: SUCCESS, payload: datas.message });
  } catch (error) {
    handleApiError(error);
  }
};

export const clearMattersBalances = (component) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    dispatch({ type: CLEAR_MATTERS_BALANCES, payload: component });
    dispatch({ type: STOP_LOADING });
  } catch (error) {
    handleApiError(error);
  }
};

export const getMattersPostings = (id, page, limit) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const datas = await api.getMattersPostings(id, page, limit);
    dispatch({ type: GET_MATTERS_POSTINGS, payload: datas.data });
    dispatch({ type: STOP_LOADING });
    dispatch({ type: SUCCESS, payload: datas.message });
  } catch (error) {
    handleApiError(error);
  }
};

export const clearMattersPostings = () => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    dispatch({ type: CLEAR_MATTERS_POSTINGS });
    dispatch({ type: STOP_LOADING });
    dispatch({ type: SUCCESS });
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const getMattersAllInfo = (id) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const datas = await api.getMatterAllInfo(id);
    let data = {
      matterId: datas.data.data.matterId,
      matterRef: datas.data.data.matterRef,
    };
    dispatch({ type: ADD_MATTER_VIEW_TAB, payload: data });
    dispatch({ type: GET_MATTERS_VIEW_DETAILS, payload: datas.data });
    dispatch({ type: STOP_LOADING });
    dispatch({ type: SUCCESS, payload: datas.message });
  } catch (error) {
    handleApiError(error);
  }
};

export const addClientContactList = (type) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    switch (type) {
      case "Clients List":
        dispatch({ type: ADD_CLIENTS_VIEW_TAB });
        break;
      case "Contacts List":
        dispatch({ type: ADD_CONTACTS_VIEW_TAB });
        break;
    }
  } catch (error) {
    handleApiError(error);
  }
};
export const getMattersAllURLInfo =
  (datas, addTab = true) =>
  async (dispatch) => {
    const dataRecieved = datas?.data?.data || datas;
    try {
      dispatch({ type: START_LOADING });
      let data = {
        matterId: dataRecieved.matterId,
        matterRef: dataRecieved.matterRef,
        matterDetails: dataRecieved.details,
      };
      if (addTab) dispatch({ type: ADD_MATTER_VIEW_TAB, payload: data });
      dispatch({
        type: GET_MATTERS_VIEW_DETAILS,
        payload: datas?.data || { data: datas },
      });
      dispatch({ type: STOP_LOADING });
      dispatch({ type: SUCCESS, payload: datas.message });
    } catch (error) {
      handleApiError(error);
    }
  };

export const removeMattersViewTab = (data) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    dispatch({ type: REMOVE_MATTER_VIEW_TAB, payload: data });
    dispatch({ type: STOP_LOADING });
  } catch (error) {
    handleApiError(error);
  }
};

export const getMyMattersPageList =
  (page, limit, sort, filterParameters) => async (dispatch) => {
    try {
      dispatch({ type: START_LOADING });
      if (page === undefined) {
        dispatch({ type: GET_MY_MATTERS_LIST, payload: undefined });
        return;
      }
      let parameter;
      if (sort === undefined) {
        parameter = "matterRef";
      } else {
        if (sort.dir === "asc") {
          parameter = sort.field;
        } else if (sort.dir === "desc") {
          parameter = sort.field + " desc";
        }
      }
      const datas = await api.getMyMattersList(
        page,
        limit,
        parameter,
        filterParameters
      );
      dispatch({ type: GET_MY_MATTERS_LIST, payload: datas.data });
      dispatch({ type: STOP_LOADING });
      dispatch({ type: SUCCESS, payload: datas.message });
    } catch (error) {
      handleApiError(error);
    }
  };

export const fetchMatterActivities = (
  id,
  feeEarner,
  FromDate,
  ToDate,
  typeId,
  page,
  limit,
  sort
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: START_LOADING });
      let parameter;
      if (sort === undefined) {
        parameter = "typeCode";
      } else {
        if (sort.dir === "asc") {
          parameter = sort.field;
        } else if (sort.dir === "desc") {
          parameter = sort.field + " desc";
        }
      }
      const response = await api.getMatterActivities(
        id,
        feeEarner,
        FromDate,
        ToDate,
        typeId,
        page,
        limit,
        parameter
      );
      dispatch({
        type: FETCH_MATTER_ACTIVITIES,
        id: id,
        payload: response.data,
      });
      dispatch({ type: STOP_LOADING });
    } catch (error) {
      handleApiError(error);
    }
  };
};

export const fetchMatterTasks = (
  id,
  completed,
  FromDate,
  ToDate,
  page,
  limit,
  sort
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: START_LOADING });
      let parameter;
      if (sort === undefined) {
        parameter = "date";
      } else {
        if (sort.dir === "asc") {
          parameter = sort.field;
        } else if (sort.dir === "desc") {
          parameter = sort.field + " desc";
        }
      }
      const response = await api.getMatterTasks(
        id,
        completed,
        FromDate,
        ToDate,
        page,
        limit,
        parameter
      );

      dispatch({ type: FETCH_MATTER_TASKS, id: id, payload: response.data });
      dispatch({ type: STOP_LOADING });
      dispatch({ type: SUCCESS, payload: response.message });
    } catch (error) {
      handleApiError(error);
    }
  };
};
