import * as React from "react";
import ActivitiesList from "./ActivitiesList";
import EditActivity from "./EditActivity";
import { getActivity } from "actions/activity.action";
import EventType from "components/Setting/EventType";

const ActivityTypes = (props) => {
  return (
    <EventType
      open={props.open}
      close={props.close}
      apiAddFunc="addActivity"
      actionFunc={getActivity}
      successMessage="Activity Added"
      errorMessage="Error Occured"
      buttonLabel="Activity Types"
      listComponent={ActivitiesList}
      editFormComponent={EditActivity}
    />
  );
};

export default ActivityTypes;
