import React, { useState, useEffect } from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import { Label } from "@progress/kendo-react-labels";
import { Input } from "@progress/kendo-react-inputs";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { CustomDateInput } from "components/common/CustomDateInput";
import CalenderCustomToggleButton from "components/common/CalenderCustomToogleButton";
import { Editor, EditorTools } from "@progress/kendo-react-editor";
import {
  sendInterviewtoClient,
  runClientInterview,
  getSuggestedEmails,
} from "api";
import { handleApiError } from "Helper";
import Loader from "components/common/Loading";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import EmailListRenderer from "components/common/EmailComposition/EmailListRenderer";
import { ComboBox } from "@progress/kendo-react-dropdowns";

const {
  Bold,
  Italic,
  Underline,
  AlignLeft,
  AlignRight,
  AlignCenter,
  Indent,
  Outdent,
} = EditorTools;

const ClientInterview = (props) => {
  const [email, setEmail] = useState({
    toAddress: "",
    htmlBody: "",
    subject: "",
    details: "",
    interviewURL: "",
    dueDate: new Date(),
  });
  const [isLoading, setIsLoading] = useState(false);
  const [dialogVisible, setDialogVisible] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [Error, setError] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [suggestedEmails, setSuggestedEmails] = useState([]);

  const fetchClientInterviewData = async () => {
    setIsLoading(true);
    try {
      const response = await runClientInterview(props.matterId, props.wfID);
      const data = response.data;
      setEmail({
        toAddress: data.data.toAddress,
        htmlBody: data.data.htmlBody,
        subject: data.data.subject,
        details: data.data.details,
        interviewURL: data.data.interviewURL,
        clientInterviewId: data.data.clientInterviewId,
        dueDate: new Date(data.data.dueDate),
      });
      setIsLoading(false);
    } catch (error) {
      setError("Failed to load client interview data.");
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchClientInterviewData();
  }, [dialogVisible]);

  useEffect(() => {
    const fetchSuggestedEmails = async () => {
      try {
        const emails = await getSuggestedEmails(props.matterId);
        setSuggestedEmails(emails?.data?.data);
      } catch (error) {
        console.error("Failed to fetch suggested emails", error);
      } finally {
      }
    };

    fetchSuggestedEmails();
  }, []);

  const handleSendInterview = async () => {
    let isError = false;
    Object.keys(email).forEach((field) => {
      if (field === "details") return;
      if (
        email[field] === undefined ||
        email[field] === "" ||
        email[field] === null
      ) {
        isError = true;
      }
    });
    if (isError) {
      setError("All Fields are required");
      return;
    }
    setIsLoading(true);
    setError(undefined);

    const data = {
      ...email,
      toAddress: email.toAddress?.email,
      feeEarnerRef: props.feeEarnerRef,
    };

    try {
      await sendInterviewtoClient(props.matterId, props?.wfID, {
        data,
      });
      setDialogMessage("Interview sent successfully!");
      setIsSuccess(true);
      // props.onClose();
    } catch (error) {
      handleApiError(error);
      setDialogMessage("An error occurred while sending Interview.");
      setIsSuccess(false);
    }
    setIsLoading(false);
    setDialogVisible(true);
  };

  const closeDialog = () => {
    setDialogVisible(false);
    setDialogMessage("");
    if (isSuccess) {
      props.onClose();
    }
  };

  const handleEmailChange = (event) => {
    setEmail({ ...email, toAddress: event.value });
  };

  const itemRender = (li, itemProps) => (
    <EmailListRenderer li={li} itemProps={itemProps} />
  );

  return (
    <>
      {!dialogVisible && (
        <Dialog
          title={"Client Interview"}
          onClose={props.onClose}
          width={"50vw"}
        >
          <div className={`px-5 pb-4`}>
            <div className="d-flex justify-content-between flex-wrap mb-2">
              {isLoading && <Loader />}
            </div>
            <div className="d-flex align-items-center border-less-inputs">
              <Label
                style={{
                  width: "80px",
                }}
                className="fs-sm"
              >
                To:
              </Label>
              <ComboBox
                placeholder="Enter Email"
                style={{
                  width: "100%",
                  maxWidth: "100%",
                }}
                data={suggestedEmails}
                textField="email"
                value={email["toAddress"]}
                onChange={handleEmailChange}
                itemRender={itemRender}
                allowCustom
              />
            </div>

            <hr className="mb-1 mt-1" />
            <div className="d-flex align-items-center border-less-inputs">
              <Label
                style={{
                  width: "80px",
                }}
                className="fs-sm"
              >
                Subject:
              </Label>
              <Input
                placeholder={`Enter Subject`}
                style={{
                  width: "100%",
                  maxWidth: "100%",
                }}
                value={email["subject"]}
                required={true}
                onChange={(e) =>
                  setEmail({ ...email, subject: e.target.value })
                }
              />
            </div>

            <hr className="mb-1 mt-1" />
            <div className="d-flex align-items-center border-less-inputs">
              <Label
                style={{
                  width: "80px",
                }}
                className="fs-sm"
              >
                Details:
              </Label>
              <Input
                placeholder={`Enter Details`}
                style={{
                  width: "100%",
                  maxWidth: "100%",
                }}
                value={email["details"]}
                onChange={(e) =>
                  setEmail({ ...email, details: e.target.value })
                }
              />
            </div>
            <hr className="mb-1 mt-1" />
            <div className="d-flex align-items-center border-less-inputs">
              <Label
                style={{
                  width: "80px",
                }}
                className="fs-sm"
              >
                Interview URL:
              </Label>
              <Input
                placeholder={`Enter Interview URL`}
                style={{
                  width: "100%",
                  maxWidth: "100%",
                }}
                value={email["interviewURL"]}
                onChange={(e) =>
                  setEmail({ ...email, interviewURL: e.target.value })
                }
              />
            </div>

            <hr className="mb-1 mt-1" />
            <div className="d-flex align-items-center border-less-inputs">
              <Label
                style={{
                  width: "72px",
                }}
                className="fs-sm"
              >
                Due Date
              </Label>
              <div
                style={{
                  width: "40%",
                  maxWidth: "50%",
                }}
              >
                <DatePicker
                  className="px-1"
                  toggleButton={CalenderCustomToggleButton}
                  dateInput={CustomDateInput}
                  value={email["dueDate"]}
                  onChange={(e) => setEmail({ ...email, dueDate: e.value })}
                />
              </div>
            </div>

            <hr className="mb-3 mt-1" />
            <span className="text-danger fs-sm my-2">{Error}</span>

            <Editor
              tools={[
                [Bold, Italic, Underline],
                [AlignLeft, AlignCenter, AlignRight],
                [Indent, Outdent],
              ]}
              contentStyle={{
                height: 260,
              }}
              defaultContent={""}
              value={email["htmlBody"] || ""}
              onChange={(e) => setEmail({ ...email, htmlBody: e.html })}
            />
          </div>

          <div className="dialog-footer">
            <Button
              className="common-btn me-4"
              type="submit"
              onClick={handleSendInterview}
            >
              Send
            </Button>
          </div>
        </Dialog>
      )}
      {dialogVisible && (
        <Dialog title={"Message"} onClose={closeDialog} width={"25vw"}>
          <div className="d-flex flex-column align-items-center p-4">
            {isSuccess ? (
              <FaCheckCircle size={32} color="green" />
            ) : (
              <FaTimesCircle size={32} color="red" />
            )}
            <p className="my-4">{dialogMessage}</p>
          </div>
          <div className="pe-4 dialog-footer w-100">
            <Button className="common-btn ms-auto" onClick={closeDialog}>
              Close
            </Button>
          </div>
        </Dialog>
      )}
    </>
  );
};

export default ClientInterview;
