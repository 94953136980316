import { useDispatch } from "react-redux";
import {
  getClientPostingtypes,
  getfeeearner,
  getOfficePostingtypes,
  getPostingtypes,
} from "../../actions/postings.action";
import { getactiveVats } from "../../actions/vat.action.js";
import { getSupplierrefs } from "../../actions/suppliers.action.js";
import {
  getactiveNominals,
  getClientBankNominals,
  getDdaList,
  getNominalsJournalList,
  getOfficeBankNominals,
} from "../../actions/nominals.action.js";
import { getBranchesnames } from "../../actions/branchnames.action.js";
import {
  getCurrentTransactionperiods,
  getTransactionperiods,
} from "../../actions/transactionperiod.action.js";
import { getChitPostingtypes } from "../../actions/chitRequest.action";
import { getActiveFixedFeeHeaders } from "../../actions/fixedFeeHeader.action";
import { getActiveCurrency } from "../../actions/currency.action";
import { getActiveApplications } from "actions/application.action";

export const usePreComponentCalls = () => {
  const dispatch = useDispatch()

  const handleCalls = (data) => {
    if (data === "All Postings" || data === "Batch Posting") {
      dispatch(getPostingtypes());
      dispatch(getfeeearner());
      dispatch(getactiveVats());
      dispatch(getBranchesnames());
      dispatch(getSupplierrefs());
      dispatch(getOfficeBankNominals());
      dispatch(getactiveNominals());
      dispatch(getClientBankNominals());
      dispatch(getDdaList());
    } else if (data === "Office Postings") {
      dispatch(getOfficePostingtypes());
      dispatch(getfeeearner());
      dispatch(getactiveVats());
      dispatch(getBranchesnames());
      dispatch(getSupplierrefs());
      dispatch(getOfficeBankNominals());
      dispatch(getactiveNominals());
      dispatch(getClientBankNominals());
      dispatch(getDdaList());
    } else if (data === "Client Postings") {
      dispatch(getClientPostingtypes());
      dispatch(getfeeearner());
      dispatch(getactiveVats());
      dispatch(getBranchesnames());
      dispatch(getSupplierrefs());
      dispatch(getOfficeBankNominals());
      dispatch(getactiveNominals());
      dispatch(getClientBankNominals());
      dispatch(getDdaList());
    } else if (data === "Billing") {
      dispatch(getfeeearner());
      dispatch(getActiveFixedFeeHeaders());
      dispatch(getactiveVats());
      dispatch(getactiveNominals());
    } else if (data === "Office Allocations") {
      dispatch(getfeeearner());
      dispatch(getOfficeBankNominals());
    } else if (data === "Nominal Ledger") {
      dispatch(getactiveNominals());
    } else if (data === "Journal Posting") {
      dispatch(getNominalsJournalList());
    }
    else if (data === "Pay Disbursement") {
      dispatch(getfeeearner());
      dispatch(getOfficeBankNominals());
    } else if (data === "Supplier Ledger") {
      dispatch(getSupplierrefs());
    } else if (data === "Chit Posting") {
      dispatch(getfeeearner());
      dispatch(getChitPostingtypes());
    }
    else if (data === "Transaction Period") {
      dispatch(getCurrentTransactionperiods());
      dispatch(getTransactionperiods());
    }
    else if (data === "Chit Request") {
      dispatch(getfeeearner());
      dispatch(getactiveVats());
      dispatch(getBranchesnames());
      dispatch(getSupplierrefs());
      dispatch(getOfficeBankNominals());
      dispatch(getactiveNominals());
      dispatch(getClientBankNominals());
      dispatch(getDdaList());
      dispatch(getChitPostingtypes());
    } else if (data === "Time Recording") {
      dispatch(getfeeearner());
    }
    else if (data === 'Clients') {
      dispatch(getBranchesnames())
      dispatch(getfeeearner());
      dispatch(getactiveVats());
      dispatch(getActiveCurrency())
    }
    else if (data === 'Filters') {
      dispatch(getBranchesnames())
      dispatch(getfeeearner());
      dispatch(getActiveApplications())
    }
  };
  return { handleCalls };
};
