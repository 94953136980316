import React, { useEffect } from "react";
import { PDFViewer } from "@progress/kendo-react-pdf-viewer";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import { toast } from "react-toastify";
import { Loader } from "@progress/kendo-react-indicators";
import { Editor } from "@progress/kendo-react-editor";
import ShareDocumentModal from "pages/CaseFile/ShareDocumentModal";
import { TbVersionsFilled } from "react-icons/tb";
import { SplitButton } from "@progress/kendo-react-buttons";
import styles from "./DocumentViewer.module.scss";
/*ICONS ***/
import { LiaEditSolid } from "react-icons/lia";
import { FaRegCalendarAlt } from "react-icons/fa";
import { IoCaretBack, IoCaretForward } from "react-icons/io5";
import { BiCollapse, BiExpand } from "react-icons/bi";
import { clipboardHtmlIcon } from "@progress/kendo-svg-icons";
import excelIcon from "../../../assets/fileIcons/Excel.svg";
import emailIcon from "../../../assets/fileIcons/Email.svg";
import wordIcon from "../../../assets/fileIcons/Word.svg";
import pptIcon from "../../../assets/fileIcons/PowerPoint.svg";
import otherFormat from "../../../assets/fileIcons/Document.svg";
import closeSideBar from "../../../assets/collapse.png";
import openSideBar from "../../../assets/expand.png";
import { FaRegShareFromSquare } from "react-icons/fa6";
import EmailViewer from "../EmailViewer";
import { getDocument, getDocumentVerions, getEmailBody } from "api";
import { RxCross2 } from "react-icons/rx";
import { FiRefreshCcw } from "react-icons/fi";
import { AiOutlineDownload } from "react-icons/ai";
import { Tile } from "components/UIComponents/Tile";
import { MdCheckCircle } from "react-icons/md";

const powerPointExt = ["ppt", "pptx"];
const wordExt = ["doc", "docx"];
const excelExt = ["xls", "xlsx"];
const emailExt = ["msg", "eml"];
const imageExt = ["png", "jpeg", "jpg", "gif"];

const truncateFilename = (filename = "", maxLength = 10) => {
  if (filename.length <= maxLength) return filename;
  const parts = filename.split(".");
  if (parts.length < 2) return filename; // No extension found
  const extension = parts.pop();
  const truncatedPart = filename.slice(0, maxLength - extension.length - 1);
  return `${truncatedPart}...${extension}`;
};

export default function DocumentViewer({
  onScreenComponent,
  data,
  onClose,
  upload = false,
  onExpand,
  iconLess,
  component,
  onRefresh,
  onDownload,
  matterId,
  saveDocuemntEdit,
  loader,
  onEditDoc,
  OnlyRefreshEditIcon,
  handleRefresh,
  showTools = true,
  hideToolbar,
}) {
  const [base64, setBase64] = useState();
  const [loadingScreen, setLoadingScreen] = useState(false);
  const [fileNo, setFileNo] = useState(0);
  const [fileName, setFileName] = useState([]);
  const [fileUrl, setFileUrl] = useState([]);
  const [versions, setVersions] = useState([]);
  const [currentVersion, setCurrentVersion] = useState(0);
  const [size, setSize] = useState([]);
  const [collapsedVersion, setCollapsedVersion] = useState(false);
  const [fullScreen, setfullScreen] = useState(false);

  const [dialogSize, setDialogSize] = useState({
    height: onScreenComponent ? "100%" : 600,
    width: 1000,
  });
  const [showShareDocument, setShowShareDocument] = useState(false);
  const [currentDoc, setCurrentDoc] = useState({});

  const dispatch = useDispatch();

  useEffect(() => {
    handleFileData(data);
  }, [data]);

  const showDocumentFile = async (id, specificVersion = false) => {
    const { data } = await getDocument(id, specificVersion);
    handleFileData([data.data]);
  };

  const handleFileData = (dataToProcess) => {
    let fileNameArray = [];
    let fileSizeArray = [];
    let fileUrlArray = [];
    let base64Array = [];
    // dispatch(setMainLoading(true));
    setBase64(null);

    dataToProcess?.forEach((file) => {
      if (file?.documentFile?.downloadURL || file?.downloadURL) {
        let ext = "";
        if (file.documentName) {
          ext = file.documentName?.split(".").pop();
        } else {
          ext = file.filename?.split(".").pop();
        }
        if (ext === "pdf" || ext === "txt") {
          createBase64Format(
            file.documentFile?.downloadURL || file?.downloadURL,
            ext
          );
        }
        if (ext === "msg" || ext === "eml") {
          getEmail(file?.documentId || file?.documentID);
        }
        base64Array.push("N/A");
        let fn = file.documentName || file.filename;
        let size = file.documentFile?.size || file.size;
        fileNameArray.push(fn);
        fileSizeArray.push(size);
        if (
          wordExt.includes(ext) ||
          excelExt.includes(ext) ||
          imageExt.includes(ext)
        ) {
          fileUrlArray.push(
            file.documentFile?.downloadURL || file?.downloadURL
          );
        }
      } else toast.error("Invlaid data.");
    });
    if (
      !dataToProcess.filter((file) => file.filename?.split(".").pop() === "pdf")
        ?.length
    ) {
    }
    setFileUrl([...fileUrlArray]);
    setSize([...fileSizeArray]);
    setFileName([...fileNameArray]);
    if (loadingScreen) setLoadingScreen(false);
  };

  const splitButtonItems = [
    {
      text: "Edit in browser",
      svgIcon: clipboardHtmlIcon,
      id: 0,
    },
    {
      text: "Edit in Word",
      svgIcon: clipboardHtmlIcon,
      id: 1,
    },
    {
      text: "Finish Editing",
      svgIcon: clipboardHtmlIcon,
      id: 2,
    },
  ];

  const downlaodButtonItems = [
    {
      text: "Download file",
      svgIcon: clipboardHtmlIcon,
      id: 0,
    },
    {
      text: "Download as PDF",
      svgIcon: clipboardHtmlIcon,
      id: 1,
    },
  ];

  const getFileVersions = async (file) => {
    try {
      const data = await getDocumentVerions(file.documentId);

      // Sort versions by dateModified in descending order
      const sortedVersions = data?.data?.data?.sort(
        (a, b) => new Date(b.dateModified) - new Date(a.dateModified)
      );

      setVersions(sortedVersions);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (data?.length && data[fileNo]) {
      getFileVersions(data[fileNo]);
    }
  }, [data, fileNo]);

  const createBase64Format = async (downloadURL, extension) => {
    // setLoadingScreen(true);
    try {
      const response = await fetch(downloadURL);
      const blob = await response.blob();
      const base64data = await new Promise((resolve, reject) => {
        const reader = new FileReader();
        if (extension === "txt") {
          reader.readAsText(blob);
        } else {
          reader.readAsDataURL(blob);
        }
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });

      const cleanBase64 =
        extension === "txt" ? base64data : base64data.split(",")[1];
      const fileCopy = [];
      fileCopy.push(cleanBase64);
      setFileUrl([...fileCopy]);
      return cleanBase64;
    } catch (error) {
      console.error("Error converting to Base64", error);
      throw error;
    } finally {
    }
  };

  const getEmail = async (documentId) => {
    // setLoadingScreen(true);
    if (!documentId) return;
    try {
      const response = await getEmailBody(documentId);
      setFileUrl([response?.data.data]);
    } catch (error) {
      console.error("Error converting to Base64", error);
      throw error;
    } finally {
    }
  };

  useEffect(() => {
    if (base64 && fileName[fileNo]?.split(".").pop() === "pdf") {
      const fileCopy = [...fileUrl];
      fileCopy.push(base64);
      setFileUrl([...fileCopy]);
    }
  }, [base64]);

  const handleFileNo = (action) => {
    if (action === "NEXT") {
      if (fileName.length > fileNo + 1) {
        setFileNo((prev) => prev + 1);
      }
    }
    if (action === "PREV") {
      if (fileNo !== 0) {
        setFileNo((prev) => prev - 1);
      }
    }
  };

  const buttonStyle = {
    width: "35px",
    height: "32px",
    background: "#FFFFFF",
    border: "0.3px solid #BDBDBD",
    borderRadius: "5px",
  };

  function formatBytes(bytes, decimals = 2) {
    if (!+bytes) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  }

  const getIcon = (fileName) => {
    const ext = fileName?.split(".").pop();
    if (powerPointExt.includes(ext)) return pptIcon;
    else if (excelExt.includes(ext)) return excelIcon;
    else if (wordExt.includes(ext)) return wordIcon;
    else if (emailExt.includes(ext)) return emailIcon;
    else return otherFormat;
  };

  const getEditButtonItems = () => {
    if (data[0] && data[0]?.documentFile?.inEditMode) {
      return splitButtonItems;
    } else {
      return splitButtonItems.filter((item) => item.id !== 2);
    }
  };

  const renderFile = () => {
    const ext = fileName[fileNo]?.split(".").pop();

    switch (ext) {
      case "pdf":
        return (
          <div
            className={`docviewer h-100 w-100 ${
              (!showTools || onScreenComponent) && "pdf-toolbar-hidden"
            }`}
          >
            <PDFViewer
              zoom={onScreenComponent ? 0.65 : 1}
              tools={
                showTools && !onScreenComponent
                  ? [
                      "pager",
                      "spacer",
                      "zoomInOut",
                      "zoom",
                      "selection",
                      "spacer",
                      "search",
                      "download",
                      "print",
                    ]
                  : []
              }
              onLoad={() => setLoadingScreen(false)}
              onError={() => setLoadingScreen(false)}
              data={fileUrl[fileNo]}
              style={{
                height: onScreenComponent ? "calc(100vh - 350px)" : "100%",
              }}
            />
          </div>
        );
      case "txt":
        return (
          <Editor
            onLoad={() => setLoadingScreen(false)}
            value={fileUrl[fileNo]}
            defaultEditMode="div"
            className="w-100"
          />
        );
      case "docx":
      case "xlsx":
        return (
          <div className="position-relative w-100 h-100">
            <iframe
              title="Viewer"
              src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(
                fileUrl[fileNo]
              )}`}
              width="100%"
              height="100%"
              className="border-0"
            >
              This is an embedded{" "}
              <a target="_blank" rel="noreferrer" href="http://office.com">
                Microsoft Office
              </a>{" "}
              document, powered by{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href="http://office.com/webapps"
              >
                Office Online
              </a>
              .
            </iframe>

            {/*Hide fullscreen option*/}
            <div
              className="position-absolute bottom-0 end-0"
              style={{
                width: "40px",
                height: "21px",
                backgroundColor: "white",
                zIndex: "1000",
              }}
            ></div>
          </div>
        );
      case "jpeg":
      case "png":
      case "gif":
      case "jpg":
        return (
          <div
            className="d-flex justify-content-center w-100 align-items-center"
            style={{
              //background: "#FCFCFC",
              //width: !onScreenComponent && dialogSize.width,
              //height: !onScreenComponent && dialogSize.height - 120,

              background: "#FCFCFC",
              width: "100%",
              height: "100%",
            }}
          >
            <img
              height={onScreenComponent ? "340" : "440"}
              width={onScreenComponent && "340"}
              style={{ objectFit: "contain" }}
              src={fileUrl[fileNo]}
              alt="preview"
            />
          </div>
        );
      case "eml":
      case "msg":
        if (!fileUrl[fileNo]) {
          return (
            <div
              className="d-flex flex-column w-100 justify-content-center align-items-center"
              style={{ height: "479px" }}
            >
              <img
                width={150}
                height={150}
                src={getIcon(fileName[fileNo])}
                alt="Document Icon"
              />
              <span
                className="mt-2 text-center"
                style={{ fontSize: "18px", fontWeight: 600 }}
              >
                {fileName[fileNo]}
              </span>
              <span
                className="mt-2 text-center"
                style={{ color: "#818181", fontSize: "14px", fontWeight: 600 }}
              >
                {formatBytes(size[fileNo])}
              </span>
            </div>
          );
        } else {
          return <EmailViewer emailInfo={fileUrl[fileNo]} />;
        }

      default:
        return (
          <div
            className="d-flex flex-column w-100 justify-content-center align-items-center"
            style={{ height: "479px" }}
          >
            <img
              width={150}
              height={150}
              src={getIcon(fileName[fileNo])}
              alt="Document Icon"
            />
            <span
              className="mt-2 text-center"
              style={{ fontSize: "18px", fontWeight: 600 }}
            >
              {fileName[fileNo]}
            </span>
            <span
              className="mt-2 text-center"
              style={{ color: "#818181", fontSize: "14px", fontWeight: 600 }}
            >
              {formatBytes(size[fileNo])}
            </span>
          </div>
        );
    }
  };

  const handleExpand = () => {
    if (dialogSize.width === 1000) {
      setDialogSize({
        height: "100%",
        width: "100%",
      });
    } else {
      setDialogSize({
        height: 600,
        width: 1000,
      });
    }
  };

  const FileToolBar = () => {
    return showTools ? (
      <div className={`${styles["file-toolbar"]} d-flex align-items-center`}>
        {getFileToolBarData()}
      </div>
    ) : (
      <></>
    );
  };

  console.log("data", data);

  const getFileToolBarData = () => {
    return (
      <>
        <SplitButton
          fillMode={"flat"}
          className={`me-2 ${styles["spliter-class"]}`}
          text={
            <div className="d-flex gap-2 align-items-center">
              <AiOutlineDownload size={24} />
              {!onScreenComponent && <div>Download</div>}
            </div>
          }
          items={downlaodButtonItems}
          onItemClick={(e) => {
            if (e?.item?.id === 0) {
              onDownload(data[0]?.documentId, false, true);
            } else if (e?.item?.id === 1) {
              onDownload(data[0]?.documentId, true, true);
            }
          }}
          onButtonClick={(e) => onDownload(data[0]?.documentId, false, true)}
        />
        {(wordExt.includes(fileName[fileNo]?.split(".").pop()) ||
          excelExt.includes(fileName[fileNo]?.split(".").pop())) && (
          <SplitButton
            fillMode={"flat"}
            className={`me-2 ${styles["spliter-class"]}`}
            text={
              <div className="d-flex gap-2 align-items-center">
                <LiaEditSolid size={22} />
                {!onScreenComponent && <div>Edit</div>}
              </div>
            }
            items={getEditButtonItems()}
            onItemClick={(e) => {
              if (e?.item?.id === 0) {
                onEditDoc(data[0]?.documentId, "Browser");
              } else if (e?.item?.id === 1) {
                onEditDoc(data[0]?.documentId, "Local");
              } else if (e?.item?.id === 2) {
                saveDocuemntEdit(data[0]?.documentId);
              }
            }}
            onButtonClick={(e) => onEditDoc(data[0]?.documentId, "Local")}
          />
        )}
        <SplitButton
          fillMode={"flat"}
          className={`me-3 ${styles["spliter-class"]}`}
          text={
            <div className="d-flex gap-2 align-items-center">
              <FaRegShareFromSquare size={20} />
              {!onScreenComponent && <div>Share</div>}
            </div>
          }
          // items={[]}
          onItemClick={(e) => {
            if (e?.item?.id === 0) {
            } else if (e?.item?.id === 1) {
              onEditDoc(data[0]?.documentId, "Local");
            }
          }}
          onButtonClick={(e) => {
            setShowShareDocument(true);
            setCurrentDoc({
              id: data[0]?.documentId,
              name: data[0]?.documentName,
            });
          }}
        />
      </>
    );
  };

  const DialogTitleBar = (titleText) => {
    return (
      <div className="w-100 d-flex align-items-center justify-content-between">
        <div>{titleText}</div>
        <div className="d-flex align-items-center">
          <IconBackground
            onClick={() => {
              setLoadingScreen(true);
              onRefresh(data[0]?.documentId);
            }}
          >
            <FiRefreshCcw size={18} />
          </IconBackground>
          <hr className={`${styles["vertical-border"]} ms-3 me-3`} />
          <IconBackground onClick={() => setfullScreen(!fullScreen)}>
            {fullScreen ? (
              <BiCollapse size={20} role="b2utton" />
            ) : (
              <BiExpand size={20} role="b2utton" />
            )}
          </IconBackground>
          <hr className={`${styles["vertical-border"]} ms-3 me-3`} />
          <IconBackground onClick={onClose} className="me-2">
            <RxCross2 size={20} color="red" role="b2utton" />
          </IconBackground>
        </div>
      </div>
    );
  };

  return onScreenComponent || showShareDocument ? (
    <div
      style={{
        position: "relative",
      }}
    >
      {loadingScreen && (
        <Loader
          themeColor={"warning"}
          size={"large"}
          type={"pulsing"}
          style={{
            position: "absolute",
            top: "45%",
            left: "40%",
            zIndex: 100,
          }}
        />
      )}
      <div
        className="d-flex justify-content-between align-items-center"
        style={{
          height: "48px",
          background: "#F8F8F8",
          border: "1px solid #E6E6E6",
        }}
      >
        {OnlyRefreshEditIcon ? (
          <span className="ms-2 ">{""}</span>
        ) : (
          <span className="ms-2 ">{getFileToolBarData()}</span>
        )}
        <div>
          <div className="d-flex align-items-center">
            {OnlyRefreshEditIcon ? (
              <>
                <IconBackground
                  onClick={() => {
                    setLoadingScreen(true);
                    // onRefresh(data[0]?.documentId);
                    handleRefresh();
                  }}
                >
                  <FiRefreshCcw size={18} />
                </IconBackground>
                {(wordExt.includes(fileName[fileNo]?.split(".").pop()) ||
                  excelExt.includes(fileName[fileNo]?.split(".").pop())) && (
                  <SplitButton
                    fillMode={"flat"}
                    className={`me-2 ${styles["spliter-class"]}`}
                    text={
                      <div className="d-flex gap-2 align-items-center">
                        <LiaEditSolid size={22} />
                        {!onScreenComponent && <div>Edit</div>}
                      </div>
                    }
                    items={getEditButtonItems()}
                    // onItemClick={(e) => {
                    //   if (e?.item?.id === 0) {
                    //     onEditDoc(data[0]?.documentId, "Browser");
                    //   } else if (e?.item?.id === 1) {
                    //     onEditDoc(data[0]?.documentId, "Local");
                    //   } else if (e?.item?.id === 2) {
                    //     saveDocuemntEdit(data[0]?.documentId);
                    //   }
                    // }}
                    // onButtonClick={(e) =>
                    //   onEditDoc(
                    //     data[0]?.documentId || data[0]?.documentTemplateId,
                    //     "Local"
                    //   )
                    // }
                  />
                )}
              </>
            ) : (
              !iconLess && (
                <>
                  <IconBackground
                    onClick={() => {
                      setLoadingScreen(true);
                      onRefresh(data[0]?.documentId);
                    }}
                  >
                    <FiRefreshCcw size={18} />
                  </IconBackground>
                  <hr className={`${styles["vertical-border"]} ms-3 me-3`} />
                  <IconBackground onClick={onExpand}>
                    <BiExpand size={20} role="button" />
                  </IconBackground>
                  <hr className={`${styles["vertical-border"]} ms-3 me-3`} />
                </>
              )
            )}
            {!OnlyRefreshEditIcon && (
              <IconBackground onClick={onClose} className="me-2">
                <RxCross2 size={20} color="red" role="button" />
              </IconBackground>
            )}
          </div>
        </div>
      </div>
      <div style={{ height: "calc(100vh - 335px)", overflow: "hidden" }}>
        {renderFile()}
      </div>

      {showShareDocument && (
        <ShareDocumentModal
          onClose={() => {
            setShowShareDocument(false);
            onClose();
          }}
          matterId={matterId}
          currentDoc={currentDoc}
        />
      )}
    </div>
  ) : (
    <>
      <div className="pdfwindow">
        <Dialog
          className={` dialog pdfwindow fs-md  ${
            fullScreen ? " dialog-fullscreen " : " dialog-xxl "
          }`}
          title={DialogTitleBar(fileName[fileNo])}
          onClose={() => {
            setBase64();
            onClose();
          }}
        >
          {loadingScreen && (
            <Loader
              themeColor={"warning"}
              size={"large"}
              type={"pulsing"}
              style={{
                position: "absolute",
                top: "40%",
                left: "45%",
                zIndex: 100,
              }}
            />
          )}

          <div
            style={{ height: "calc(100% - 65px)" }}
            className="d-flex gap-3 px-3"
          >
            <img
              className={
                "leftside-collapse-button " +
                (collapsedVersion
                  ? "collapsed-button-location"
                  : "expanded-button-location")
              }
              src={collapsedVersion ? openSideBar : closeSideBar}
              style={{
                left: collapsedVersion ? "80px" : "170px",
                top: "0.6rem",
              }}
              onClick={() => {
                setCollapsedVersion(!collapsedVersion);
              }}
              alt="Collapse Icon"
            ></img>
            <Tile
              heading={
                collapsedVersion ? (
                  <TbVersionsFilled className="ms-3" size={24} />
                ) : (
                  "Versions"
                )
              }
              className="px-3 py-2 mt-2 overflow-hidden"
              height="97%"
              width={collapsedVersion ? "100px" : "220px"}
            >
              {versions.map((version, index) => (
                <VersionCard
                  key={version.fileID}
                  version={version}
                  index={index}
                  collapsedVersion={collapsedVersion}
                  changeVersion={(version) => {
                    setCurrentVersion(index);
                    showDocumentFile(version?.fileID, true);
                  }}
                  currentVersion={currentVersion}
                  isLatest={index === 0}
                />
              ))}
            </Tile>
            {renderFile()}
          </div>

          <div id="footer" className="row dialog-footer m-0 float-none">
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center">
                <div
                  className="ms-3 me-2"
                  style={{
                    fontWeight: "600",
                    fontSize: "13px",
                    lineHeight: "18px",
                  }}
                >
                  Previewing {fileNo + 1} of {fileName.length}
                </div>
                {!hideToolbar && (
                  <div className="d-flex align-items-center">
                    <div
                      className={`ms-4 me-3 ${styles["vertical-border"]}`}
                    ></div>
                    {getFileToolBarData()}
                  </div>
                )}
              </div>
              <div>
                <div className="d-flex align-items-center">
                  {/*{component === "CASE-FILES" &&*/}
                  {/*    wordExt.includes(fileName[fileNo]?.split(".")?.pop()) && (*/}
                  {/*        <button*/}
                  {/*            className="border-0 me-2"*/}
                  {/*            onClick={() => onEditDoc(data[0]?.documentId)}*/}
                  {/*            style={{*/}
                  {/*                width: "50",*/}
                  {/*                height: "50",*/}
                  {/*                borderRadius: "50%",*/}
                  {/*                background: "white",*/}
                  {/*                paddingTop: "7px",*/}
                  {/*                paddingLeft: "9px",*/}
                  {/*                padding: "10px",*/}
                  {/*            }}*/}
                  {/*        >*/}
                  {/*            <LiaEditSolid size={24} role="button" />*/}
                  {/*        </button>*/}
                  {/*    )}*/}

                  {/*<button*/}
                  {/*    className="border-0 me-3"*/}
                  {/*    onClick={() => {*/}
                  {/*        setShowShareDocument(true);*/}
                  {/*        setCurrentDoc({*/}
                  {/*            id: data[0]?.documentId,*/}
                  {/*            name: data[0]?.documentName,*/}
                  {/*        });*/}
                  {/*    }}*/}
                  {/*    style={{*/}
                  {/*        width: "50",*/}
                  {/*        height: "50",*/}
                  {/*        borderRadius: "50%",*/}
                  {/*        background: "white",*/}
                  {/*        padding: "10px",*/}
                  {/*    }}*/}
                  {/*>*/}
                  {/*    <CiShare1 size={22} role="button" />*/}
                  {/*</button>*/}

                  <button
                    style={buttonStyle}
                    className=" me-2"
                    disabled={fileNo === 0}
                    onClick={() => handleFileNo("PREV")}
                  >
                    <IoCaretBack fontSize={20} />
                  </button>
                  <button
                    style={buttonStyle}
                    className=" me-4"
                    disabled={fileName.length === fileNo + 1}
                    onClick={() => handleFileNo("NEXT")}
                  >
                    <IoCaretForward fontSize={20} />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Dialog>
      </div>
    </>
  );
}

const IconBackground = (props) => {
  return (
    <div
      className={`${props.className} ${styles["icon-background"]} cursor-pointer`}
      onClick={() => {
        if (props.onClick) props.onClick();
      }}
    >
      {props.children}
    </div>
  );
};

const VersionCard = ({
  version,
  isLatest,
  changeVersion,
  collapsedVersion,
  currentVersion,
  index,
}) => {
  const formattedDate = new Date(version.dateModified).toLocaleDateString(
    "en-GB",
    {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    }
  );
  return (
    <div
      onClick={() => changeVersion(version)}
      className={`${styles.cardContainer} ${
        !isLatest && "mt-2"
      } d-flex gap-2 cursor-pointer justify-content-between align-items-center`}
      style={{
        backgroundColor:
          currentVersion === index ? "rgba(0, 0, 0, 0.03)" : "transparent", // Apply gray background to latest version
      }}
    >
      {/* Left section: Version title and date */}
      {collapsedVersion ? (
        <div className={`${styles.versionInfo} mt-2`}>
          <div className={styles.versionTitle}>V - {version.versionNo}</div>
        </div>
      ) : (
        <div className={styles.versionInfo}>
          <div className="d-flex align-items-center justify-content-between w-100 gap-4">
            <div className={styles.versionTitle}>
              Version {version.versionNo}
            </div>
            {isLatest && (
              <div className={`${styles.currentLabel} ms-2 px-2 mb-2`}>
                <MdCheckCircle className={styles.currentIcon} />
              </div>
            )}
          </div>
          <div className={styles.dateContainer}>
            <FaRegCalendarAlt color="gray" />
            <span className={styles.dateText}>{formattedDate}</span>
          </div>
        </div>
      )}
    </div>
  );
};
