import React from "react";

export const CompanyItemRender = (handleSelection) => (li, itemProps) => {
  const item = itemProps.dataItem;

  const postalCodeRegex = /[A-Z]{1,2}[0-9R][0-9A-Z]? [0-9][ABD-HJLNP-UW-Z]{2}/g;
  const postalCode = item?.address ? item.address.match(postalCodeRegex) : "";
  
  const addressParts = item?.address ? item.address.split(",") : "";
  const country = addressParts ? addressParts[addressParts.length - 2]?.trim() : "";

  const itemChildren = (
    <div
      className="d-flex flex-column flex-wrap w-100 my-1 px-3 pt-2 cursor-pointer "
      onClick={() => handleSelection(item.companyNumber)}
      style={{
        backgroundColor: "#fff",
        border: "1px solid #e0e0e0",
        borderRadius: "8px",
        transition: "border-color 0.2s",
        height: "10%",
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.05)",
      }}
      onMouseEnter={(e) => (e.currentTarget.style.borderColor = "#7d7ab4")}
      onMouseLeave={(e) => (e.currentTarget.style.borderColor = "#e0e0e0")}
    >
      <div className="d-flex align-items-center">
        <p
          className="fw-semibold fs-sm me-3 px-2"
          style={{
            backgroundColor: "#7d7ab4",
            color: "#fff",
            borderRadius: "5px",
          }}
        >
          {item.companyNumber}
        </p>
        <p className="fw-semibold fs-sm " >
          {item.title}
        </p>
      </div>
        <p className="fs-sm text-muted">
          {postalCode ? `${postalCode}, ${country}` : country}
        </p>
    </div>
  );

  return React.cloneElement(li, li.props, itemChildren);
};
