import * as React from "react";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Input, Checkbox, NumericTextBox } from "@progress/kendo-react-inputs";
import { Label } from "@progress/kendo-react-labels";

const AddEditProfile = (props) => {
  const IS_EDIT = props.type === "edit";
  return (
    <div className="p-3 ps-4">
      <Form
        onSubmit={props.onSubmit}
        initialValues={props.type === "edit" && props.item}
        readOnly={true}
        render={(formRenderProps) => (
          <FormElement
            style={{
              maxWidth: 1000,
            }}
          >
            <fieldset className={"k-form-fieldset"}>
              <div className="mb-3 d-flex flex-column">
                <Label className="label mb-2">Profile Name</Label>
                <Field name={"profileName"} component={Input} />
              </div>
              {props.type === "edit" && (
                <div className="d-flex flex-column">
                  <Field
                    defaultChecked={true}
                    name={"active"}
                    component={Checkbox}
                    label={"Active"}
                  />
                </div>
              )}
            </fieldset>
            <div className="d-flex float-end pb-4">
              <button
                className="common-btn bgBtnSecondary me-1"
                onClick={props.cancelEdit}
              >
                Cancel
              </button>
              <button
                className="common-btn me-2"
                disabled={!formRenderProps.allowSubmit}
                type={"submit"}
              >
                {IS_EDIT ? "Update" : "Save"}
              </button>
            </div>
          </FormElement>
        )}
      />
    </div>
  );
};

export default AddEditProfile;
