import { AmountFormat, } from "components/common/formatter";
import { useTableKeyboardNavigation } from "@progress/kendo-react-data-tools";
import { GridColumn as Column, GRID_COL_INDEX_ATTRIBUTE } from "@progress/kendo-react-grid";

export const FileCell = (props) => {
    const field = props.field || "";
    const value = props.dataItem[field];
    const navigationAttributes = useTableKeyboardNavigation(props.id);
    return (
        <td
            colSpan={props.colSpan}
            role={"gridcell"}
            aria-colindex={props.ariaColumnIndex}
            aria-selected={props.isSelected}
            {...{
                [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
            }}
            {...navigationAttributes}
        >
            {value?.map(((data, index) => {
                return (
                    <div
                        key={index}
                        style={{
                            overflow: "hidden",
                        }}
                    >
                        <p>{data.filename}</p>
                    </div>
                );
            }))}
        </td>
    );
};

export const PayCell = (props) => {
    const navigationAttributes = useTableKeyboardNavigation(props.id);
    return (
        <td
            colSpan={props.colSpan}
            role={"gridcell"}
            aria-colindex={props.ariaColumnIndex}
            aria-selected={props.isSelected}
            {...{
                [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
            }}
            {...navigationAttributes}
        >
            {props.dataItem.payee || props.dataItem.payer}
        </td>
    );
};

