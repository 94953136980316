import * as React from "react";
import { useEffect, useState, useRef, useCallback } from "react";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { useSelector } from "react-redux";
import { Label } from "@progress/kendo-react-labels";
import { usePreComponentCalls } from "components/layout/PreComponentLoad";
import { prepareFeeearnerData } from "components/common/ComboRepresentationFunctions";
import { getAccessRightsData, getTeams } from "api";
import { ComboBoxLabel } from "components/Dashboard";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { DropDownButton } from "@progress/kendo-react-buttons";
import { caretAltDownIcon } from "@progress/kendo-svg-icons";
import styles from "../../components/Dashboard/index.module.scss";
import { Calendar } from "@progress/kendo-react-dateinputs";

export const FilterComponent = (props) => {
  const { userlist: user } = useSelector((state) => state.currentuser);
  const INITIAL_STATE = {
    branchId: {
      branchName: "All",
      branchId: props?.allowAccessData?.accessAllBranches
        ? -2
        : props?.allowAccessData?.accessAllTeams
        ? props?.allowAccessData?.branches[0]?.branchId
        : -1,
    },
    teamId: { teamName: "All", teamID: -2 },
    feeEarnerRef: {
      representation: `${user?.userRef} - ${user?.displayname}` || "All",
      userRef: user?.feeEarnerRef || "All",
      active: null,
    },
  };
  const { handleCalls } = usePreComponentCalls();
  useEffect(() => {
    handleCalls("Filters");
  }, []);

  let today = new Date();
  let startOfWeek, endOfWeek;
  const dayOfWeek = today.getDay();
  startOfWeek = new Date(today);
  startOfWeek.setDate(today.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1));
  endOfWeek = new Date(startOfWeek);
  endOfWeek.setDate(startOfWeek.getDate() + 6);

  const [feeearner, setfeeearnerData] = useState();
  const [branchesdata, setbranchesdata] = useState([]);
  const [allowedAppsData, setAllowedAppsData] = useState();
  const [teamsData, setTeamsData] = useState([]);
  const [comboBoxState, setComboBoxState] = useState(INITIAL_STATE);
  const [initialLock, setInitialLock] = useState(true);
  const [duration, setDuration] = useState("This Week");
  const [toDate, setToDate] = useState(endOfWeek);

  const [fromDate, setFromDate] = useState(startOfWeek);

  const blurTimeoutRef = useRef(null);

  const [showCalender1, setShowCalender1] = useState(false);
  const [showCalender2, setShowCalender2] = useState(false);

  const brancheslist = useSelector((state) => {
    return state?.branchesnames?.branchesList;
  });
  const allowedAppsDataReducer = useSelector(
    (state) => state.applications?.activeApplicationsList?.data
  );
  const feeearnerdata = useSelector((state) => {
    return state.postingsdocs.feeearner.data;
  });

  const CustomCalendar = (props) => {
    return <Calendar {...props} />;
  };

  const onFocus = useCallback(() => {
    clearTimeout(blurTimeoutRef.current);
  }, []);

  const onBlurTimeout = useCallback(() => {
    setShowCalender1(false);
    setShowCalender2(false);
  }, []);

  const onBlur = useCallback(() => {
    clearTimeout(blurTimeoutRef.current);
    blurTimeoutRef.current = setTimeout(onBlurTimeout, 200);
  }, [onBlurTimeout]);

  const onClickFromDate = useCallback((e) => {
    setShowCalender2(false);
    setShowCalender1((prevShow) => !prevShow);
  }, []);

  const onClickToDate = useCallback((e) => {
    setShowCalender1(false);
    setShowCalender2((prevShow) => !prevShow);
  }, []);

  const handleCalendarClick = useCallback((e) => {
    e.stopPropagation(); // Prevent the click event from propagating to the outer div
  }, []);

  useEffect(() => {
    if (!initialLock) {
      props.updateFilters({
        feeearnerRef: comboBoxState["feeEarnerRef"]?.userRef,
        teamId: comboBoxState["teamId"]?.teamID,
        branch: comboBoxState["branchId"]?.branchId,
        ToDate: formatDate(toDate),
        FromDate: formatDate(fromDate),
        duration: duration,
      });
    }

    if (initialLock) setInitialLock(false);
  }, [comboBoxState, toDate, fromDate]);

  // useEffect(() => {
  //   setComboBoxState();
  // }, [props.isRefreshed]);

  useEffect(() => {
    if (user) {
      setComboBoxState({
        ...comboBoxState,
        feeEarnerRef: {
          representation: `${user?.userRef} - ${user?.displayname}` || "All",
          userRef: user?.userRef || "All",
          active: null,
        },
      });
    }
  }, [user]);

  const dataFetcher = async (e) => {
    try {
      const data = await getTeams();
      let dataToPass = data?.data?.data;
      setTeamsData([{ teamName: "All" }, ...dataToPass]);
    } catch (error) {}
  };
  useEffect(() => {
    dataFetcher();
  }, []);

  useEffect(() => {
    if (brancheslist?.length > 0) {
      setbranchesdata([{ branchName: "All", branchId: null }, ...brancheslist]);
      setfeeearnerData([
        { representation: "All", userRef: null },
        ...prepareFeeearnerData(feeearnerdata),
      ]);
      setAllowedAppsData([{ appCode: "All" }, ...allowedAppsDataReducer]);
    }
  }, [brancheslist, allowedAppsDataReducer, feeearnerdata]);
  const DASHBOARD_FILTERS = props.component === "Dashboard";

  function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  const onChangeDuration = (e) => {
    let fromDate, toDate;
    if (e.item === "Today") {
      fromDate = toDate = today;
    } else if (e.item === "This Week") {
      fromDate = startOfWeek;
      toDate = endOfWeek;
    } else if (e.item === "Past Week") {
      const dayOfWeek = today.getDay();
      const startOfCurrentWeek = new Date(today);
      startOfCurrentWeek.setDate(
        today.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1)
      );
      const startOfPastWeek = new Date(startOfCurrentWeek);
      startOfPastWeek.setDate(startOfCurrentWeek.getDate() - 7);
      const endOfPastWeek = new Date(startOfPastWeek);
      endOfPastWeek.setDate(startOfPastWeek.getDate() + 6);
      fromDate = startOfPastWeek;
      toDate = endOfPastWeek;
    } else if (e.item === "This Month") {
      fromDate = new Date(today.getFullYear(), today.getMonth(), 1);
      toDate = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    } else if (e.item === "Quarter") {
      const currentMonth = today.getMonth();
      const startMonth = currentMonth - (currentMonth % 3);
      fromDate = new Date(today.getFullYear(), startMonth, 1);
      toDate = new Date(today.getFullYear(), startMonth + 3, 0);
    } else if (e.item === "Year") {
      fromDate = new Date(today.getFullYear(), 0, 1);
      toDate = new Date(today.getFullYear(), 11, 31);
    }
    setFromDate(fromDate);
    setToDate(toDate);
    setDuration(e.item);
  };

  return (
    <div
      className={`d-flex flex-wrap ${
        props.component === "Graphs" && "flex-wrap"
      }`}
    >
      {props?.allowAccessData?.accessAllBranches && (
        <div
          className={`d-flex flex-column pe-3 ${
            DASHBOARD_FILTERS ? "col" : "col"
          } `}
        >
          {DASHBOARD_FILTERS ? (
            <ComboBoxLabel name="Branch" />
          ) : (
            <Label className="filter-font mb-1">Branch</Label>
          )}
          <ComboBox
            style={{
              width: "100%",
              height: "40px",
            }}
            data={props?.allowAccessData?.branches}
            textField={`branchName`}
            className="ps-2"
            iconClassName="wa"
            filterable={true}
            placeholder={"Search Branch"}
            value={comboBoxState["branchId"]}
            onChange={(e) => {
              if (e.value === null) {
                setComboBoxState({
                  ...comboBoxState,
                  branchId: { branchName: "All", branchId: -2 },
                });
                return;
              }
              setComboBoxState({ ...comboBoxState, branchId: e.value });
            }}
          />
        </div>
      )}

      {(!props?.allowAccessData?.accessAllTeams &&
        props?.allowAccessData?.branches[0]?.teams?.length > 1) ||
        (props?.allowAccessData?.accessAllTeams && (
          <div
            className={`d-flex flex-column pe-3 ${
              DASHBOARD_FILTERS ? "col" : "col"
            } `}
          >
            {DASHBOARD_FILTERS ? (
              <ComboBoxLabel name="Team" />
            ) : (
              <Label className="filter-font mb-1">Team</Label>
            )}
            <ComboBox
              style={{
                width: "100%", //DASHBOARD_FILTERS ? "250px" : "200px",
                height: "40px",
              }}
              data={
                props.allowAccessData?.branches?.filter(
                  (branch) =>
                    branch.branchId === comboBoxState["branchId"]?.branchId
                )[0]?.teams
              }
              iconClassName="wa"
              textField={"teamName"}
              value={comboBoxState["teamId"]}
              onChange={(e) => {
                if (e.value === null) {
                  setComboBoxState({
                    ...comboBoxState,
                    teamId: { teamName: "All", teamID: -2 },
                  });
                  return;
                }
                setComboBoxState({
                  ...comboBoxState,
                  teamId: e.value,
                });
              }}
              placeholder={"Search Team"}
            />
          </div>
        ))}

      {props?.allowAccessData?.accessAllFeeEarnersInTeam && (
        <div
          className={`d-flex flex-column pe-3 ${
            DASHBOARD_FILTERS ? "col" : "col"
          } `}
        >
          {DASHBOARD_FILTERS ? (
            <ComboBoxLabel name="Feeearner" />
          ) : (
            <Label className="filter-font mb-1">Fee Earner</Label>
          )}
          <ComboBox
            style={{
              width: "100%", //DASHBOARD_FILTERS ? "260px" : "200px",
              height: "40px",
            }}
            data={props.allowAccessData?.branches
              ?.filter(
                (branch) =>
                  branch.branchId === comboBoxState["branchId"]?.branchId
              )[0]
              ?.teams.filter(
                (teams) => teams.teamID === comboBoxState["teamId"]?.teamID
              )[0]
              ?.feeEarners.map((item) => ({
                ...item,
                representation: `${item?.userRef} - ${item?.displayname}`,
              }))}
            textField={`representation`}
            filterable={true}
            className="ps-2"
            iconClassName="wa"
            placeholder={"Search Fee Earner"}
            value={comboBoxState["feeEarnerRef"]}
            onChange={(e) => {
              if (e.value === null) {
                setComboBoxState({
                  ...comboBoxState,
                  feeEarnerRef: { representation: "All", userRef: "ALL" },
                });
                return;
              }
              setComboBoxState({
                ...comboBoxState,
                feeEarnerRef: e.value,
              });
            }}
          />
        </div>
      )}

      {props.component === "Dashboard" && (
        <div className="d-flex flex-column col">
          <ComboBoxLabel name="Date Range" />

          <div className="d-flex">
            <div onClick={onClickFromDate}>
              <DatePicker
                onFocus={onFocus}
                onBlur={onBlur}
                calendar={(props) => (
                  <div onClick={handleCalendarClick}>
                    <CustomCalendar {...props} />
                  </div>
                )}
                show={showCalender1}
                className={styles["date-range-picker"]}
                width={"122px"}
                value={fromDate}
                onChange={(e) => {
                  setDuration("Custom Date");
                  setFromDate(new Date(e.target.value));
                  setShowCalender1(false);
                }}
                toggleButton={() => {
                  <></>;
                }}
              />
            </div>

            <div onClick={onClickToDate}>
              <DatePicker
                onFocus={onFocus}
                onBlur={onBlur}
                calendar={(props) => (
                  <div onClick={handleCalendarClick}>
                    <CustomCalendar {...props} />
                  </div>
                )}
                show={showCalender2}
                className={styles["date-range-picker2"]}
                width={"122px"}
                value={toDate}
                onChange={(e) => {
                  setDuration("Custom Date");
                  setToDate(new Date(e.target.value));
                  setShowCalender2(false);
                }}
                toggleButton={() => {
                  <></>;
                }}
              />
            </div>
            <DropDownButton
              className="buttons-container-button"
              items={[
                "Today",
                "This Week",
                "Past Week",
                "This Month",
                "Quarter",
                "Year",
              ]}
              onItemClick={onChangeDuration}
              svgIcon={caretAltDownIcon}
              size="large"
            />
          </div>
        </div>
      )}
    </div>
  );
};
