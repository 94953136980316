import React, { useEffect, useState } from "react";
import styles from "./HeaderProfile.module.scss";
import { Popup } from "@progress/kendo-react-popup";
import { formatDate } from "@progress/kendo-intl";
import { Icon } from "@progress/kendo-react-common";
import BellImage from "../../assets/bell-not.svg";
import CrossStyles from "components/tabs/tabs.module.scss";
import { Badge } from "@progress/kendo-react-indicators";
import { AiTwotoneCheckCircle } from "react-icons/ai";
import { getNotifications, updateNotifications } from "api";
import { shortMonths } from "components/MyMatters";
import { Overlay } from "components/Overlay";
import { weekData } from "pages/Settings/Company/CompanyDetails";

const Notifications = () => {
  const notificationRef = React.useRef();
  const [notifications, setNotifications] = useState([]);
  const [showPortal, setShowPortal] = useState(false);
  const anchor = React.useRef();
  const [show, setShow] = React.useState(false);

  useEffect(() => {
    getApiNotifications();
  }, []);

  const getApiNotifications = async () => {
    try {
      const notifications = await getNotifications();
      setNotifications(notifications?.data?.data);
    } catch (error) {
      console.error(error);
    }
  };

  const toggle = () => {
    setShow((prev) => !prev);
    if (!show) setShowPortal(true);
  };

  const updateNotificationData = async (item) => {
    try {
      await updateNotifications([item.id]);
      await getApiNotifications();
    } catch (error) {}
  };

  const getNotificationsCount = () => {
    const count = notifications.filter((item) => item.seen === false);
    if (count.length >= 10) return "9+";
    return count.length;
  };

  useEffect(() => {
    window.onclick = (event) => {
      if (event.target.id === "overlay") setShow(false);
      if (
        event.target.contains(notificationRef.current) &&
        event.target !== notificationRef.current
      ) {
      } else {
      }
    };
  }, []);

  return (
    <>
      <div onClick={toggle}>
        <div
          className="position-relative cursor-pointer d-flex align-items-center justify-content-center"
          ref={anchor}
          style={{ width: "40px", height: "40px", zIndex: 20 }}
        >
          <img src={BellImage}></img>
          {!!getNotificationsCount() && (
            <Badge className={`${styles["notification-badge"]} ms-2`}>
              <span>{getNotificationsCount()}</span>
            </Badge>
          )}
        </div>
      </div>
      {show && (
        <Overlay hideOverlay={() => setShowPortal(false)}>
          {/*Pass any data to the modal */}
        </Overlay>
      )}
      <div className={`${styles["notification-popup"]}`} ref={notificationRef}>
        <Popup
          show={show}
          popupClass={styles["notification-content"]}
          animate={false}
          anchor={anchor.current}
        >
          <div className="popup-items mb-2">
            <div className="w-100">
              <div
                className={`p-4 d-flex align-items-center justify-space-between w-100`}
              >
                <p className="mb-0">Notifications</p>
                <div className="pt-1">
                  <span
                    className={`${CrossStyles["tabs-remove-icon-wrapper"]} ms-3 d-flex justify-content-center align-items-center cursor-pointer mb-1`}
                    onClick={() => setShow(false)}
                  >
                    <span
                      className={`${CrossStyles["r-icon"]}
                      k-icon k-i-x`}
                    ></span>
                  </span>
                </div>
              </div>
              <div className="w-100 border-bottom border-opacity-50"></div>
            </div>
            <div
              className={`container-fluid ${styles["not-container"]}`}
              role="button"
            >
              {notifications.map((item, index) => (
                <NotificationsItem
                  item={item}
                  key={index}
                  seen={item?.seen}
                  details={item?.details}
                  date={item?.date}
                  updateNotification={updateNotificationData}
                />
              ))}
            </div>
          </div>
        </Popup>
      </div>
    </>
  );
};

export default Notifications;

const NotificationsItem = ({
  item,
  seen,
  details,
  date,
  updateNotification,
}) => {
  const dateFormatter = () => {
    const dateFormat = new Date(date);
    const now = new Date();
    const sevenDaysInMs = 7 * 24 * 60 * 60 * 1000;
    const timeDiffInMs = now.getTime() - dateFormat.getTime();
    if (timeDiffInMs <= sevenDaysInMs) {
      return {
        day: weekData[dateFormat.getDay()].key,
        format: `${dateFormat.getHours()} : ${dateFormat.getMinutes()}`,
      };
    } else if (now.getFullYear === dateFormat.getFullYear) {
      return {
        year: true,
        format: `${
          shortMonths[dateFormat.getMonth()]
        } ${dateFormat.getDate()} `,
      };
    } else
      return {
        year: false,
        format: `${
          shortMonths[dateFormat.getMonth()]
        } ${dateFormat.getDate()}, ${dateFormat.getFullYear} `,
      };
  };
  return (
    <>
      <div
        className={`p-2 pt-3 pb-3 row ${
          !seen && styles["unSeen-background"]
        } align-items-center ${!seen && "cursor-pointer"}`}
        onClick={() => {
          if (!seen) updateNotification(item);
        }}
      >
        <div className="col-1">
          {!seen && (
            <AiTwotoneCheckCircle className={`${styles["filled-circle"]}`} />
          )}
        </div>
        <div className="col-8">
          <span className={`${styles.notificationText}`}>{details}</span>
        </div>
        <div className="col-3 ps-3">
          {dateFormatter()?.day !== undefined ? (
            <>
              <div className="">
                <span
                  className={`${styles.notificationText} ${styles.dateText} text-center`}
                >
                  {dateFormatter().day}
                </span>
              </div>
              <div className="ms-2">
                <span
                  className={`${styles.notificationText} ${styles.dateText}`}
                >
                  {dateFormatter().format}
                </span>
              </div>
            </>
          ) : dateFormatter().year ? (
            <div className="ps-2">
              <span
                className={`${styles.notificationText} ${styles.dateText} text-center`}
              >
                {dateFormatter().format}
              </span>
            </div>
          ) : (
            <div className="">
              <span
                className={`${styles.notificationText} ${styles.dateText} text-center`}
              >
                {dateFormatter().format}
              </span>
            </div>
          )}
        </div>
      </div>
      <div className="w-80 border-bottom border-opacity-50"></div>
    </>
  );
};
