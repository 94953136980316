import React from "react";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { Button } from "@progress/kendo-react-buttons";
import { Checkbox } from "@progress/kendo-react-inputs";
import { Label } from "@progress/kendo-react-labels";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { CustomDateInput } from "components/common/CustomDateInput";
import CalenderCustomToggleButton from "components/common/CalenderCustomToogleButton";

const FilterComponent = ({
  showPopup,
  valueFrom,
  setValueFrom,
  fromDateSelector,
  onChangeFromDateSelect,
  valueTo,
  setValueTo,
  toDateSelector,
  onChangeToDateSelect,
  statusData,
  status,
  typeData,
  typeID,
  handleChange,
  handleRefresh,
  handleReset,
  updateFilterValues,
  completed,
  showCompleted,
}) => {
  return showPopup ? (
    <div className="d-flex align-items-start flex-column p-2 mx-2">
      <Label className="mt-2">Set Filters</Label>
      <hr className="w-100 mt-2 " />
      <div style={{ width: "200px" }}>
        <div>
          <Label editorId={"from"} className="me-5 pe-5 mb-1">
            From
          </Label>
        </div>
        <div className="date-form-to-wrapper">
          <Checkbox
            className="ms-2 mt-auto mb-auto"
            value={valueFrom}
            onChange={(e) => setValueFrom(e.value)}
          />
          <div className="from">
            <DatePicker
              id="from"
              className="date-picker pe-2"
              value={fromDateSelector}
              onChange={onChangeFromDateSelect}
              dateInput={CustomDateInput}
              toggleButton={CalenderCustomToggleButton}
              defaultValue={fromDateSelector}
              placeholder="Select from date"
            />
          </div>
        </div>
        <div className="mt-2">
          <Label editorId={"to"} className=" mb-1">
            To
          </Label>
          <div className="date-form-to-wrapper">
            <Checkbox
              className="ms-2 mt-auto mb-auto"
              value={valueTo}
              onChange={(e) => setValueTo(e.value)}
            />
            <div className="to">
              <DatePicker
                id="to"
                className="date-picker pe-2"
                value={toDateSelector}
                onChange={onChangeToDateSelect}
                dateInput={CustomDateInput}
                toggleButton={CalenderCustomToggleButton}
                defaultValue={toDateSelector}
                placeholder="Select to date"
              />
            </div>
          </div>
        </div>
      </div>
      {statusData && (
        <div className="my-2 d-flex flex-column ">
          <Label className="label mb-1">Status</Label>
          <DropDownList
            className="dropdown me-2"
            style={{ width: "200px" }}
            textField="key"
            iconClassName="wa"
            data={statusData}
            name="status"
            value={status}
            onChange={(e) => handleChange(e)}
          />
        </div>
      )}
      {typeData && (
        <div className="my-2 d-flex flex-column ">
          <Label className="label mb-1">Type</Label>
          <DropDownList
            className="dropdown me-2"
            style={{ width: "200px" }}
            iconClassName="wa"
            textField="name"
            name={"name"}
            data={typeData}
            value={typeID}
            onChange={handleChange}
          />
        </div>
      )}
      {showCompleted && (
        <div className="pt-3 pb-2 ">
          <Checkbox value={completed} onChange={handleChange} />
          <Label className="label">Completed</Label>
        </div>
      )}

      <div className="d-flex my-2">
        <Button className="common-btn me-2" onClick={handleReset}>
          Reset
        </Button>
        <Button className="common-btn " onClick={updateFilterValues}>
          Update
        </Button>
      </div>
    </div>
  ) : (
    <div className="d-flex align-items-center">
      <div className="me-2">
        <div>
          <Label editorId={"from"} className="me-5 pe-5 mb-1">
            From
          </Label>
          <Label editorId={"to"} className="ms-4 ps-1 mb-1">
            To
          </Label>
        </div>
        <div className="date-form-to-wrapper">
          <Checkbox
            className="ms-2 mt-auto mb-auto"
            value={valueFrom}
            onChange={(e) => setValueFrom(e.value)}
          />
          <div className="from">
            <DatePicker
              id="from"
              className="date-picker pe-2"
              value={fromDateSelector}
              onChange={onChangeFromDateSelect}
              dateInput={CustomDateInput}
              toggleButton={CalenderCustomToggleButton}
              defaultValue={fromDateSelector}
              placeholder="Select from date"
            />
          </div>
          <div className="divider m-1"></div>
          <Checkbox
            className="ms-2 mt-auto mb-auto"
            value={valueTo}
            onChange={(e) => setValueTo(e.value)}
          />
          <div className="to">
            <DatePicker
              id="to"
              className="date-picker pe-2"
              value={toDateSelector}
              onChange={onChangeToDateSelect}
              dateInput={CustomDateInput}
              toggleButton={CalenderCustomToggleButton}
              defaultValue={toDateSelector}
              placeholder="Select to date"
            />
          </div>
        </div>
      </div>
      <div className="ms-2 mt-auto align-items-center d-flex">
        <Label className="label me-2">Status</Label>
        <DropDownList
          className="dropdown me-2"
          style={{ width: "150px" }}
          textField="key"
          iconClassName="wa"
          data={statusData}
          name="status"
          value={status}
          onChange={(e) => handleChange(e)}
        />
      </div>
      <div className="ms-2 mt-auto">
        <Button className="common-btn" onClick={handleRefresh}>
          <span className="d-flex align-items-center">
            <span className="k-icon k-i-reload me-2"></span>
            Refresh
          </span>
        </Button>
      </div>
      <div className=""></div>
    </div>
  );
};

export default FilterComponent;
