import { formatDate, formatNumber } from "@progress/kendo-intl";
import { LoadLocaleData } from "./localeData";
import { AiOutlineFile } from "react-icons/ai";
import { useSelector } from "react-redux";
import { Checkbox } from "@progress/kendo-react-inputs";

export const BooleanCell = (props) => {
  const dateField = props.dataItem[props.field];
  if (dateField) {
    return (
      <td className="text-center">
        <Checkbox value={dateField} />
      </td>
    );
  } else {
    return <td></td>;
  }
};

export const DateFormatCell = (props) => {
  const dateField = props.dataItem[props.field];
  if (dateField) {
    return (
      <td
        onClick={(e) => props.onClick && props.onClick(e)}
        className={props.className}
      >
        {DateFormat(dateField)}{" "}
      </td>
    );
  } else {
    return <td></td>;
  }
};

export const DateFormat = (dateField) => {
  let locale = useSelector((state) => state.company.company.locale);
  if (dateField) {
    return formatDate(new Date(dateField), "d", locale);
  } else {
    return "";
  }
};

export const DateFormatFull = (dateField) => {
  if (dateField === "0001-01-01T00:00:00") return "-";

  if (dateField) {
    return formatDate(new Date(dateField), "d MMMM yyyy");
  } else {
    return "";
  }
};

export const DateFormat1 = (dateField, locale) => {
  if (dateField) {
    return formatDate(new Date(dateField), "d", locale);
  } else {
    return "";
  }
};

export const CreditAmountFormatCell = (props) => {
  const amount = props.dataItem[props.field];
  if (!isNaN(amount) && amount < 0) {
    return (
      <td style={{ textAlign: "right", background: "none" }}>
        {AmountFormat(Math.abs(amount))}
      </td>
    );
  } else {
    return <td style={{ background: "none" }}> </td>;
  }
};

export const DebitAmountFormatCell = (props) => {
  const amount = props.dataItem[props.field];
  if (!isNaN(amount) && amount >= 0) {
    return (
      <td style={{ textAlign: "right", background: "none" }}>
        <span style={{ background: "none" }}>
          {AmountFormat(Math.abs(amount))}
        </span>
      </td>
    );
  } else {
    return <td style={{ background: "none" }}> </td>;
  }
};

export const AmountFormatCell = (props) => {
  const amount = props.dataItem[props.field];
  return (
    <td className="text-end">
      <span>{AmountFormat(amount)}</span>
    </td>
  );
};

export const AmountFormatCellEmptyZero = (props) => {
  const amount = props.dataItem[props.field];

  if (amount === 0) {
    return <td> </td>;
  } else {
    return <td style={{ textAlign: "right" }}> {AmountFormat(amount)} </td>;
  }
};

export const AmountFormat = (amount) => {
  // let locale = useSelector((state) => state.company.company.locale);
  let locale = "";
  if (locale === "") {
    locale = "en-GB";
  }
  if (amount !== null && !isNaN(amount)) {
    return formatNumber(amount, "n2", locale);
  } else {
    return "";
  }
};

export const ActiveCell = (props) => {
  return (
    <td>
      <input
        style={{ width: "25px", height: "20px", marginLeft: "12px" }}
        type={"checkbox"}
        checked={props.dataItem.active === true ? true : false}
        readOnly={true}
      />
    </td>
  );
};

export const DocumentCounterCell = (e) => {
  return (
    <td>
      <div style={{ display: "flex", alignItems: "center", gap: "2px" }}>
        {e.dataItem.documentCount !== null && (
          <button className="border-0">
            <AiOutlineFile color="blue" size={20} />
          </button>
        )}
        <span>
          {e.dataItem.documentCount === null ? "" : e.dataItem.documentCount}
        </span>
      </div>
    </td>
  );
};

export const nameFunction = (data) => {
  return data?.replace(" ", "")?.toLowerCase();
};
