import {
  START_LOADING,
  STOP_LOADING,
  SUCCESS,
  CREATE_TIME,
  CHANGE_REF,
  CLEAR_REF,
  OPEN_MATTER_VIEW,
} from "../types/index";
import { handleApiError } from "Helper";

export const changeLedgerLink = (component, ref) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const data = {
      type: component,
      ref: ref,
    };
    dispatch({ type: CHANGE_REF, payload: data });
    dispatch({ type: STOP_LOADING });
  } catch (error) {handleApiError(error)}
};

export const openMatterView = (component, ref) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const data = {
      type: component,
      ref: ref,
    };
    dispatch({ type: OPEN_MATTER_VIEW, payload: data });
    dispatch({ type: STOP_LOADING });
  } catch (error) {handleApiError(error)}
};

export const clearLedgerLink = (component, ref) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const data = {
      type: component,
      ref: ref,
    };
    dispatch({ type: CLEAR_REF, payload: data });
    dispatch({ type: STOP_LOADING });
  } catch (error) {handleApiError(error) }
};
