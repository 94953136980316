import React, { useEffect, useLayoutEffect } from "react";
import { useDispatch } from "react-redux";
import "@progress/kendo-react-intl";
import "@progress/kendo-react-dropdowns";
import "react-router-dom";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { Button } from "@progress/kendo-react-buttons";
import { HandleGridBody } from "components/common/HandleGridBody.js";
import AddNewContact from "./AddViewContact.jsx";
import { useState } from "react";
import styles from "./Contact.module.scss";
import SearchTextBox from "components/SearchComponent.jsx";
import { RedirectCell } from "components/common/GridComponents.jsx";

const Contacts = (props) => {
  const [list, setLists] = React.useState({
    data: [],
    total: 20,
  });

  const [formView, setFormView] = React.useState(false);
  const [showAddNewForm, setshowAddNewForm] = React.useState(false);
  const [type, setType] = useState("");

  const [dataState, setDataState] = React.useState({
    take: 50,
    skip: 0,
    sort: [
      {
        field: "name",
        dir: "asc",
      },
    ],
  });

  const [isSearched, setIsSearched] = React.useState(false);
  const [eventDataState, setEventDataState] = React.useState();
  const [searchValue, setSearchValue] = React.useState();
  const [contactId, setContactaid] = React.useState(null);
  const searchRef = React.useRef();

  const handleContactData = (dataItem) => {
    setFormView(true);
    setContactaid(dataItem.contactId);
  };

  const NameCell = (e) => {
    return (
      <td className="gridBoldField">
        <u
          style={{ cursor: "pointer", color: "black" }}
          onClick={() => {
            handleContactData(e.dataItem);
          }}
        >
          {e.dataItem.name}
        </u>
      </td>
    );
  };

  const ContactCommandCell = (props) => {
    return (
      <td>
        {props?.dataItem?.website ? (
          <a target="_blank" href={`https://${props.dataItem.website}`}>
            {props.dataItem.website}
          </a>
        ) : (
          <span>N/A</span>
        )}
      </td>
    );
  };

  const Redirect = (props) => (
    <RedirectCell handleClick={() => handleContactData(props.dataItem)} />
  );

  const createState = (skip, take) => {
    let pagerSettings = {
      pageSizes: [10, 25, 50, 100],
    };
    return {
      pageSize: take,
      pageable: pagerSettings,
    };
  };
  const [state, setState] = React.useState(createState(0, 50));

  const dataStateChange = (e) => {
    const eventObject = { event: e?.dataState };
    setEventDataState(eventObject);
    if (
      e.dataState?.sort !== undefined &&
      e.dataState?.skip !== 0 &&
      dataState?.sort !== e.dataState.sort
    ) {
      setDataState({ ...e.dataState, skip: 0 });
    } else if (e.dataState?.sort?.[0] === undefined) {
      setDataState({
        ...e.dataState,
        sort: [
          {
            field: dataState?.sort?.[0].field,
            dir: "asc",
          },
        ],
      });
    } else {
      setDataState(e.dataState);
    }
  };

  const dataReceived = (list) => {
    if (list.data) {
      setLists(list);
    }
  };

  const handleRefresh = () => {
    setIsSearched(false);
    setSearchValue("");
    searchRef.current.clearSearch();
    if (eventDataState) {
      setDataState({ ...eventDataState?.event, skip: 0 });
    } else {
      setDataState({
        ...dataState,
        take: 50,
        skip: 0,
      });
    }
  };

  return (
    <div className="page">
      <div className="d-flex align-items-center">
        <SearchTextBox ref={searchRef} changeFilterValue={setSearchValue} />
        <Button
          className="ms-3 border-0 common-btn me-1"
          icon="refresh"
          onClick={handleRefresh}
        >
          Refresh
        </Button>
        <Button
          className="common-btn ms-auto"
          onClick={() => setshowAddNewForm(true)}
        >
          Add Contact
        </Button>
      </div>

      <Grid
        {...dataState}
        data={list}
        onDataStateChange={dataStateChange}
        sortable={true}
        className={`fs-md mt-3 ${styles.contact_gridheight}`}
        pageable={state.pageable}
        pageSize={state.pageSize}
        resizable={true}
      >
        <GridColumn field="name" title="Name" cell={NameCell} />
        <GridColumn field="type" title="Type" width="100px" />
        <GridColumn field="contactCategory" title="Category" width="120px" />
        <GridColumn field="phone" title="Phone" width="150px" />
        <GridColumn field="email" title="Email" width={"220px"} />
        <GridColumn field="fullAddress" title="Address" />
        <GridColumn width="60px" cell={Redirect} />
      </Grid>

      <HandleGridBody
        dataState={dataState}
        onDataReceived={dataReceived}
        currentComponent="contacts"
        searchValue={searchValue}
        isSearched={isSearched}
        // searchValue={filterValue}
      />

      {(showAddNewForm || formView) && (
        <AddNewContact
          type={type}
          viewMode={formView}
          contactId={contactId}
          close={() => {
            setshowAddNewForm(false);
            setFormView(false);
          }}
        />
      )}
    </div>
  );
};
export default Contacts;
