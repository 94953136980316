import { Button } from "@progress/kendo-react-buttons";
import { Checkbox } from "@progress/kendo-react-inputs";
import SearchTextBox from "components/SearchComponent";
import React, { useState, useRef, useEffect } from "react";
import styles from "./CaseFiles.module.scss";
import EditIcon from "../../assets/common/editBoxIcon.png";
import { Dialog } from "@progress/kendo-react-dialogs";
import { getWorkFlowTemplates } from "api";
import { Loader } from "@progress/kendo-react-indicators";
import AddEditDocTemplate from "./AddEditDocTemplate";

export const DocumentTemplates = (props) => {
  const searchRef = useRef();
  const [userScreens, setUserScreens] = useState([]);
  const [filteredScreens, setFilteredScreens] = useState([]);
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [editDocument, setEditDocument] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showTemplatesDialog, setShowTemplatesDialog] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const getUserTemplates = async () => {
    try {
      setIsLoading(true);
      const data = await getWorkFlowTemplates();
      const allowedScreens = allowedScreenIds(data?.data?.data);
      setUserScreens(allowedScreens);
      setFilteredScreens(allowedScreens); // Initialize with all templates
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const allowedScreenIds = (screens) => {
    const ALREADY_ADDED_SCREENS = [];
    props.addedTemplates?.forEach((screenItem) => {
      if (!ALREADY_ADDED_SCREENS.includes(screenItem.documentTemplateId)) {
        ALREADY_ADDED_SCREENS.push(screenItem.documentTemplateId);
      }
    });
    return screens?.filter(
      (item) => !ALREADY_ADDED_SCREENS.includes(item.documentTemplateId)
    );
  };

  const selectQuestion = (type, question) => {
    if (type) {
      setSelectedQuestions([...selectedQuestions, question]);
    } else {
      setSelectedQuestions(
        selectedQuestions.filter(
          (prevQuestion) =>
            prevQuestion.documentTemplateId !== question?.documentTemplateId
        )
      );
    }
  };

  const addQuestions = () => {
    props.addScreens(selectedQuestions);
  };

  const handleSearchChange = (searchText) => {
    setSearchTerm(searchText);

    if (searchText?.trim()) {
      const filtered = userScreens.filter(
        (question) =>
          question.name.toLowerCase().includes(searchText.toLowerCase()) ||
          question.details.toLowerCase().includes(searchText.toLowerCase())
      );
      setFilteredScreens(filtered);
    } else {
      setFilteredScreens(userScreens); // Reset if search is cleared
    }
  };

  useEffect(() => {
    getUserTemplates();
  }, []);

  return (
    <>
      <Dialog
        onClose={() => props.cancelEdit()}
        width={700}
        title={"Select Document Templates"}
      >
        <div className="p-3 px-4 ms-3 me-3">
          <div className="d-flex align-items-center justify-content-between">
            <SearchTextBox
              width={"400px"}
              ref={searchRef}
              changeFilterValue={handleSearchChange} // Attach search handler
            />
            <Button
              className="common-btn"
              onClick={() => setShowTemplatesDialog(true)}
            >
              Add New Template
            </Button>
          </div>
          <div
            className={`container-fluid mt-4 ${
              isLoading && "d-flex align-items-center justify-content-center"
            }`}
            style={{
              height: "400px",
              overflow: "auto",
            }}
          >
            {isLoading ? (
              <Loader themeColor={"warning"} size={"large"} type={"pulsing"} />
            ) : (
              filteredScreens?.map((question, index) =>
                props.questionIds?.includes(question?.documentTemplateId) ? null : (
                  <DocTemplate
                    key={index}
                    question={question}
                    selectedQuestions={selectedQuestions}
                    selectQuestion={selectQuestion}
                    onSelectEdit={() => {
                      setEditDocument(question);
                      setShowTemplatesDialog(true);
                    }}
                  />
                )
              )
            )}
          </div>
        </div>
        <div className="dialog-footer">
          <button
            className="common-btn me-5"
            type={"submit"}
            onClick={addQuestions}
          >
            Select
          </button>
        </div>
        {showTemplatesDialog && (
          <AddEditDocTemplate
            matterId={props.matterId}
            cancelEdit={() => {
              setShowTemplatesDialog(false);
              setEditDocument(null);
            }}
            updateTemplates={() => {
              getUserTemplates();
              setShowTemplatesDialog(false);
            }}
            isEdit={editDocument}
          />
        )}
      </Dialog>
    </>
  );
};

const DocTemplate = ({
  question,
  onSelectEdit,
  selectQuestion,
  selectedQuestions,
}) => {
  return (
    <div
      className={`row ${styles["box-styles"]} p-2 d-flex align-items-center mt-2`}
    >
      <div className="col-1" style={{ maxWidth: "40px" }}>
        <Checkbox
          defaultValue={false}
          value={
            selectedQuestions.some(
              (item) => item.documentTemplateId === question?.documentTemplateId
            )
          }
          onChange={(e) => {
            selectQuestion(e.value, { ...question, itemType: "Document" });
          }}
        />
      </div>
      <div className={`col ${styles["question-text"]}`}>{question?.name}</div>

      <div className={`col ${styles["question-text"]}`}>
        {question?.details}
      </div>

      <div className="col-1" style={{ maxWidth: "40px" }}>
        <img
          className="cursor-pointer"
          onClick={() => onSelectEdit()}
          src={EditIcon}
          alt="Edit Icon"
          style={{ width: "20px", height: "20px" }}
        />
      </div>
    </div>
  );
};
