import * as React from "react";
import { NumericTextBox } from "@progress/kendo-react-inputs";
export const NumericCell = (props) => {
  return (
    <td className="right-align-full numeric-input-padding">
      {true ? (
        <NumericTextBox
          format={"n2"}
          className = 'mt-auto'
          value={
            props.dataItem.amount !== undefined
              ? props.dataItem.amount
              : props.dataItem.value
          }
          onChange={(e) => {
            if (props.onChange) {
              props.onChange({
                dataIndex: 0,
                dataItem: props.dataItem,
                field: props.field,
                syntheticEvent: e.syntheticEvent,
                value: e.value,
                counter: props.dataItem.counter
              });
            }
          }}
          spinners={false}
        />
      ) : (
        ""
      )}
    </td>
  );
};
