import React, { useEffect, useState } from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import { ContainerCard } from "components/MatterDetails/ContactCard";
import { saveConflictCheckResults, searchConflictCheck } from "api";
import { Label } from "@progress/kendo-react-labels";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { CONSTANT } from "CONSTANT/Matter/MatterActivityForm";
import { Tile } from "components/UIComponents/Tile";
import { Loader } from "@progress/kendo-react-indicators"; // Import your loader component
import { toast } from "react-toastify";
import CalenderCustomToggleButton from "components/common/CalenderCustomToogleButton";
import { CustomDateInput } from "components/common/CustomDateInput";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { Input } from "@progress/kendo-react-inputs";
import { FaSearch } from "react-icons/fa";
import { RedirectCell } from "components/common/GridComponents";
import styles from "./Casefile.module.scss";

const ConflictCheck = ({
  fromCaseFile,
  onClose,
  moveNext,
  formData,
  matterId,
  currentMatterDetails,
}) => {
  const DEFAULT_STATE = {
    firstName: "",
    lastName: "",
    dateOfBirth: "",
    postcode: "",
    companyName: "",
    companyRegNo: "",
    matterDetails: "",
  };
  const [suggestedEmails, setSuggestedEmails] = useState([]);
  const [loading, setLoading] = useState(false); // State for loading
  const [data, setData] = useState([]);
  const [inputState, setInputState] = useState(DEFAULT_STATE);

  const matter_Id = fromCaseFile ? matterId : formData?.matterDetails?.matterId;

  const matter_details = fromCaseFile
    ? currentMatterDetails
    : formData?.matterDetails;

  const handleInputChange = (value, label) => {
    setInputState((prevState) => ({
      ...prevState,
      [label]: value,
    }));
  };

  const handleSearch = async () => {
    try {
      setLoading(true);
      const response = await searchConflictCheck(matter_Id, inputState);
      setData(response?.data?.data);
      console.log(data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const saveAndSearch = async (startNew) => {
    try {
      setLoading(true);
      await saveConflictCheckResults(matter_Id, inputState);
      if (startNew) {
        setData([]);
        setInputState(DEFAULT_STATE);
      } else {
        onClose();
      }
      toast.success("Search Saved");
    } catch (error) {
      toast.error("Error saving search");
    } finally {
      setLoading(false);
    }
  };

  const handleNext = async () => {
    try {
      // await saveConflictCheckResults(matter_Id, inputState); commented temporarily; api giving error
      moveNext({}, { conflictCheckData: data });
    } catch (error) {
      toast.error("Error saving search");
    }
  };

  const content = (
    <div>
      {loading && (
        <div
          className="loader-container d-flex justify-content-center align-items-center"
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Loader themeColor={"warning"} size={"large"} type={"pulsing"} />
        </div>
      )}
      <div
        className={`${styles["sub-text"]} m-3 ms-2 mt-1 p-3 ps-4 pe-4 pt-2 pb-0 overflow-auto`}
      >
        <i>
          {matter_details?.client?.clientName} - {matter_details?.matterRef} -{" "}
          {matter_details?.details}{" "}
          {matter_details?.appCode && (
            <span> - [{matter_details?.appCode}]</span>
          )}{" "}
        </i>
      </div>
      <Tile
        height={"280px"}
        heading="Search"
        headingClass=""
        className="m-3 mt-1 p-3 ps-4 pe-4 pt-2 pb-0 overflow-auto"
      >
        <div className="row">
          <div className="col-4">
            <Label className="label d-block mb-1">First Name</Label>
            <Input
              id="payer"
              style={{
                width: "260px",
                height: "32px",
              }}
              min={3}
              placeholder="First Name"
              onChange={(e) => handleInputChange(e.value, "firstName")}
              value={inputState["firstName"]}
            ></Input>
          </div>
          <div className="col-4">
            <Label className="label d-block mb-1">Last Name</Label>
            <Input
              style={{
                width: "260px",
                height: "32px",
              }}
              id="payer"
              placeholder="Last Name"
              onChange={(e) => handleInputChange(e.value, "lastName")}
              value={inputState["lastName"]}
            ></Input>
          </div>
          <div className="col-4 form-date-picker note-date-picker check-date-picker">
            <Label className="label d-block mb-1">Date Of Birth</Label>
            <DatePicker
              style={{
                width: "260px",
              }}
              id="date"
              dateInput={CustomDateInput}
              toggleButton={CalenderCustomToggleButton}
              onChange={(e) => handleInputChange(e.value, "dateOfBirth")}
              value={inputState["dateOfBirth"]}
            />
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-4">
            <Label className="label d-block mb-1">Company Name</Label>
            <Input
              id="payer"
              style={{
                width: "260px",
                height: "32px",
              }}
              placeholder="Company Name"
              onChange={(e) => handleInputChange(e.value, "companyName")}
              value={inputState["companyName"]}
            ></Input>
          </div>
          <div className="col-4">
            <Label className="label d-block mb-1">
              Company Registration No
            </Label>
            <Input
              style={{
                width: "260px",
                height: "32px",
              }}
              id="payer"
              onChange={(e) => handleInputChange(e.value, "companyRegNo")}
              value={inputState["companyRegNo"]}
              placeholder="Reg No"
            ></Input>
          </div>
          <div className="col-4">
            <Label className="label d-block mb-1">Post Code</Label>
            <Input
              style={{
                width: "260px",
                height: "32px",
              }}
              id="payer"
              onChange={(e) => handleInputChange(e.value, "postCode")}
              value={inputState["postCode"]}
              placeholder="Post Code"
            ></Input>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-8">
            <Label className="label d-block mb-1">Matter Details</Label>
            <Input
              id="payer"
              style={{
                maxWidth: "100%",
                height: "32px",
              }}
              placeholder="Matter Details"
              onChange={(e) => handleInputChange(e.value, "matterDetails")}
              value={inputState["matterDetails"]}
            ></Input>
          </div>
          <div className="col-4 d-flex justify-content-end mt-3">
            <Button
              className="common-btn"
              onClick={handleSearch}
              disabled={loading}
            >
              <div className="d-flex gap-2 align-items-center">
                <FaSearch color="white" />
                <div>Search</div>
              </div>
            </Button>
          </div>
        </div>
      </Tile>
      <Tile
        heading="Search Results"
        headingClass=""
        className="m-3 mt-1 p-3 ps-4 pe-4 pt-2 pb-0 overflow-auto"
      >
        <Grid data={data} style={{ height: "200px" }} className="pb-1">
          <GridColumn
            field="criteria"
            editable={false}
            title="Criteria"
            className="fs-sm fw-bold"
            headerClassName="ps-3"
            width="120px"
          />
          <GridColumn
            field="matchedTable"
            title="Match in"
            className="fs-sm"
            width="120px"
            editor="text"
          />
          <GridColumn
            field="details"
            title="Details"
            className="fs-sm"
            editable={false}
          />
          <GridColumn width="60px" cell={RedirectCell} />
        </Grid>
      </Tile>
      {fromCaseFile && (
        <div className="dialog-footer">
          <Button className="common-btn bgBtnSecondary me-2" onClick={onClose}>
            Close
          </Button>

          <Button
            className="common-btn me-2"
            onClick={() => saveAndSearch(true)}
          >
            Save & New Search
          </Button>

          <Button
            className="common-btn  me-3"
            onClick={() => saveAndSearch(false)}
          >
            Save & Close
          </Button>
        </div>
      )}
    </div>
  );

  return formData ? (
    <Tile heading="Conflict Check" className="px-4 h-100 overflow-auto">
      <div style={{ height: "calc(100% - 70px)" }}>
        <div className="overflow-auto" style={{ height: "calc(100% - 55px)" }}>
          <div className={`${styles["onboarding_container"]}`}>{content}</div>
        </div>
        <div
          className={`${styles["onboarding_container"]} d-flex flex-column`}
          style={{ height: "55px" }}
        >
          <hr className="m-0 w-100" />
          <div className="d-flex justify-content-end mt-2">
            <Button
              className="common-btn me-2"
              onClick={() => saveAndSearch(true)}
            >
              Save & New Search
            </Button>
            <Button className="common-btn" onClick={handleNext}>
              Next
            </Button>
          </div>
        </div>
      </div>
    </Tile>
  ) : (
    <div>{content}</div>
  );
};

export default ConflictCheck;
