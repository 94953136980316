import * as React from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import varaibles from "../../styles/_theme.scss";

const ModalLoading = ({ data, closeModal, reset }) => {

    const images = require.context("../../assets/Logos", true);

    return (
        <div
            style={{
                backdropFilter: "blur(1px)",
            }}
        >
            <Dialog height={220} width={400}>
                <div className={`d-flex mt-3`}>
                    <div className="justifiy-content-center d-flex flex-column align-items-center vw-100">
                        <img className="menuLogo"
                            src={images(varaibles.collapsedLogo?.replace(/['"]+/g, ""))}
                            alt="linq logo"

                        ></img>
                        <div className="mt-3">
                            <div className={`mx-auto mb-2 fs-sm mt-1`}>
                                <b>Loading User Data...</b>
                            </div>
                            <div
                                className={`mx-auto mb-2 fs-sm text-center mt-1`}
                                style={{ color: "gray" }}
                            >
                                <b>Please Wait</b>
                            </div>
                        </div>
                        <div className="spinner-border spinner mt-1" role="status"></div>
                    </div>
                </div>
            </Dialog>
        </div>
    );
};

export default ModalLoading;
