export const enum MenuItemId {
  Dashboard = 1,

  Clients = 12,

  Matters = 2,

  Contacts = 3,

  Accounts = 4,
  Billing = 41,
  MatterLedger = 42,
  OfficePostings = 48,
  ClientPostings = 49,
  OfficeCreditAndAlloc = 46,
  Transfers = 47,
  BatchPosting = 43,

  Nominal = 5,
  Nominals = 51,
  NominalLedger = 52,
  BankPostings = 53,
  JournalPostings = 54,
  VATReturn = 55,
  BankRec = 56,

  Purchase = 6,
  Suppliers = 61,
  SupplierLedger = 62,
  PurchaseInvoicePostings = 63,
  DisbursementPostings = 64,
  DisbursementPayments = 65,
  SupplierPayments = 66,

  Consultant = 11,
  Consultants = 97,
  ConsultantLedger = 98,
  PayConsultant = 99,

  Admin = 7,
  TransactionPeriods = 71,
  YearEnd = 72,
  ChitPosting = 73,
  PaymentProcessing = 74,

  FeeEarner = 8,
  ChitRequest = 81,
  TimeRecording = 82,
  TimeRecorded = 83,
  MyMatters = 84,

  Reports = 9,
  TrialBalanceReport = 91,
  MatterBalanceReport = 92,
  SupplierBalanceReport = 93,
  ClientListingReport = 94,
  BillingReport = 95,

  Settings = 10,
}

export default MenuItemId;
