import {
  START_LOADING,
  STOP_LOADING,
  ERROR,
  SUCCESS,
  GET_CHIT_REQUEST_TYPES,
} from "../types/index.js";

const ChitRequestReducer = (
  state = {
    IsError: false,
    isSuccess: false,
    isLoading: undefined,
    postingstypes: { data: [{ displayFields: [] }] },
    data: { data: [{ transactionNo: "Loading" }] },
  },
  action
) => {
  switch (action.type) {
    case START_LOADING:
      return { ...state, isLoading: true };
    case STOP_LOADING:
      return { ...state, isLoading: false };
    case GET_CHIT_REQUEST_TYPES:
      return {
        ...state,
        postingstypes: action.payload,
      };

    case SUCCESS:
      return { ...state, IsError: false, isSuccess: true };
    default:
      return state;
  }
};
export default ChitRequestReducer;
