import * as React from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Input } from "@progress/kendo-react-inputs";
import { Label } from "@progress/kendo-react-labels";
import { useState } from "react";
import { toast } from "react-toastify";
import {
  addClientInterview,
  editClientInterview,
  getWorkFlowClientInterviewsById,
} from "api";
import { Loader } from "@progress/kendo-react-indicators";

const AddEditClientInterview = (props) => {
  const [loading, setLoading] = useState(false);
  const [processingFileName, setProcessingFileName] = useState(
    props?.isEdit ? props?.isEdit.processingFileName : ""
  );
  const [fileName, setFileName] = useState(
    props?.isEdit ? props?.isEdit.resultFileName : ""
  );

  const [type, setType] = useState(props?.isEdit ? props?.isEdit?.type : "");
  const [name, setName] = useState(props?.isEdit ? props?.isEdit.name : "");
  const [details, setDetails] = useState(
    props?.isEdit ? props?.isEdit.details : ""
  );

  const getWorkFlowData = async (id) => {
    setLoading(true); // Set loading to true when the fetch starts
    try {
      const data = await getWorkFlowClientInterviewsById(id);
      setName(data?.data?.data?.name);
      setDetails(data?.data?.data?.details);
      setProcessingFileName(data?.data?.data?.processingFileName);
      setFileName(data?.data?.data?.resultFileName);
      setType(data?.data?.data?.type);
    } catch (error) {
      toast.error("Failed to fetch interview data.");
    } finally {
      setLoading(false); // Set loading to false when the fetch is done
    }
  };

  React.useEffect(() => {
    if (props.component === "WORK_FLOW") {
      getWorkFlowData(props.isEdit);
    }
  }, []);

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const folderData = {
        id: props.isEdit && props.isEdit?.clientInterviewId,
        processingFileName,
        resultFileName: fileName,
        active: true,
        name,
        details,
        itemType: "Interview",
        clientInterviewId: props.isEdit
          ? props.isEdit?.clientInterviewId || props.isEdit
          : undefined,
      };

      if (props.isEdit) {
        await editClientInterview(
          props.isEdit?.clientInterviewId || props.isEdit,
          {
            ...props.isEdit,
            ...folderData,
          }
        );
        // props.updateTemplate();
      } else {
        await addClientInterview(folderData);
      }
      props.updateTemplates(folderData);
    } catch (error) {
      console.log(error);
      toast.error("Unexpected Error Occurred");
    }
  };

  return (
    <Dialog
      title={`${props?.isEdit ? "Edit" : "Add"} Client Interview`}
      onClose={props.cancelEdit}
      width={550}
      className="p-3"
    >
      <form onSubmit={handleSubmit}>
        <div
          className={`p-2 m-4 mt-0 ms-5 me-5`}
          style={{
            height: 350,
          }}
        >
          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%", // Ensure loader takes the full height of the dialog
              }}
            >
              <Loader size="large" type="infinite-spinner" />{" "}
              {/* Centered loader */}
            </div>
          ) : (
            <div>
              <div className="mt-3">
                <Label className="label mb-1 w-100">Name&nbsp;</Label>
                <Input
                  className="px-3 pt-1"
                  style={{
                    width: "320px",
                    height: "32px",
                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.05)",
                  }}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                  value={name}
                  required
                  placeholder={"Name"}
                  name="name"
                  type="text"
                />
              </div>
              <div className="mt-3">
                <Label className="label mb-1 w-100">Details&nbsp;</Label>
                <Input
                  className="px-3 pt-1"
                  style={{
                    width: "320px",
                    height: "32px",
                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.05)",
                  }}
                  onChange={(e) => {
                    setDetails(e.target.value);
                  }}
                  value={details}
                  required
                  placeholder={"Details"}
                  name="details"
                  type="text"
                />
              </div>
              <div className="mt-3">
                <Label className="label mb-1 w-100">
                  Interview Processor&nbsp;
                </Label>
                <Input
                  className="px-3 pt-1"
                  style={{
                    width: "320px",
                    height: "32px",
                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.05)",
                  }}
                  onChange={(e) => {
                    setProcessingFileName(e.target.value);
                  }}
                  value={processingFileName}
                  required
                  placeholder={"Processing File Name"}
                  name="processingFileName"
                  type="text"
                />
              </div>

              <div className="mt-3">
                <Label className="label mb-1 w-100"> File Name&nbsp;</Label>
                <Input
                  className="px-3 pt-1"
                  style={{
                    width: "320px",
                    height: "32px",
                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.05)",
                  }}
                  placeholder={"File Name"}
                  name="fileName"
                  required
                  onChange={(e) => {
                    setFileName(e.target.value);
                  }}
                  value={fileName}
                  type="text"
                />
              </div>

              <div className="mt-3">
                <Label className="label mb-1 w-100"> Type&nbsp;</Label>
                <Input
                  className="px-3 pt-1"
                  style={{
                    width: "320px",
                    height: "32px",
                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.05)",
                  }}
                  placeholder={"Type"}
                  name="type"
                  required
                  onChange={(e) => {
                    setType(e.target.value);
                  }}
                  value={type}
                  type="text"
                />
              </div>
            </div>
          )}
        </div>
        <div className="dialog-footer">
          <button className="common-btn me-4">{`${
            props?.isEdit ? "Update" : "Add"
          }`}</button>
        </div>
      </form>
    </Dialog>
  );
};

export default AddEditClientInterview;
