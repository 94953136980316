import * as React from "react";
import {
  Chart,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartSeries,
  ChartSeriesItem,
} from "@progress/kendo-react-charts";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import {
  addMattersDashboardFilter,
  getDashboardMatterData,
} from "actions/dashboard.action";
import { useDispatch } from "react-redux";
import { ChartFooter, ChartHeader } from "components/Dashboard";
import mattersOpened from "../../assets/DashboardIcons/mattersOpened.svg";
import {
  useDashboardData,
  graphUpdater,
} from "components/Dashboard/useDashboardData";
import styles from "./DashboardPage.module.scss";
import { useSelector } from "react-redux";

export const MattersChart = (props) => {
  const [type, setType] = useState("Month");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [filters, setFilters] = useState({});
  const [series, setSeries] = useState([]);
  const [categories, setCategories] = useState([]);
  const initialSeries = [
    { name: "Matters Opened", data: [] },
    { name: "Matters Closed", data: [] },
  ];

  useDashboardData(filters, getDashboardMatterData);
  const { mattersOpenedData } = useSelector((state) => state.dashboard);

  const onSeriesClick = (e) => {
    const data = {
      groupedBy: type,
      groupingByParameter: e.category,
      filters: filters,
    };
    dispatch(addMattersDashboardFilter(data));
    navigate("/feeearner/mymatters");
  };

  useEffect(() => {
    if (mattersOpenedData?.data) {
      graphUpdater(
        mattersOpenedData.data,
        initialSeries,
        setCategories,
        setSeries
      );
    }
  }, [mattersOpenedData.data]);

  const typesUpdater = () => {
    let types = [];
    if (filters?.feeearnerRef === null) {
      types.push({
        label: "By Fee Earner",
        value: "FeeEarner",
      });
    }
    if (filters?.appCode === null) {
      types.push({
        label: "By App",
        value: "Application",
      });
    }
    if (filters?.branch === null) {
      types.push({
        label: "By Branch",
        value: "Branch",
      });
    }
    if (filters?.month === null) {
      types.push({
        label: "By Month",
        value: "Month",
      });
    }
    setTypesData(types);
    let check = false;
    types.map((item) => {
      if (item.value === type) {
        check = true;
      }
    });
    if (!check) {
      setType("Month");
    }
  };

  const [typesData, setTypesData] = useState([]);
  useEffect(() => {
    setFilters(props.filters);
  }, [props.filters]);

  useEffect(() => {
    typesUpdater();
  }, [filters, type]);

  return (
    <div className="h-100 p-2">
      <ChartHeader
        name={"Matter Opened"}
        type={"(Opened vs Closed)"}
        icon={mattersOpened}
        duration={filters?.duration}
        color={"#F1F4FF"}
      />
      <div className="d-flex p-2 ">
        <Chart
          style={{
            height: "200px",
            width: "100%",
            overflow: "hidden",
          }}
          transitions={false}
          seriesColors={["#262626", "#8079FE"]}
          // transitions={true}
        >
          <ChartCategoryAxis>
            <ChartCategoryAxisItem categories={categories} />
          </ChartCategoryAxis>

          <ChartSeries>
            <ChartSeriesItem type="line" data={series[0]?.data} />
            <ChartSeriesItem type="line" data={series[1]?.data} />
          </ChartSeries>
        </Chart>
      </div>
      <ChartFooter param1={"Opened"} param2={"Closed"} data={series} />
    </div>
  );
};

export const MattersStatsCard = ({ filters }) => {
  const { mattersOpenedData } = useSelector((state) => state.dashboard);
  const totalActual =
    mattersOpenedData?.data?.reduce(
      (sum, item) => sum + item.mattersOpened,
      0
    ) || 0;

  return (
    <div className="d-flex flex-column align-items-start mt-4 py-3 px-4">
      <div
        className={`${styles.iconContainer}`}
        style={{
          backgroundColor: "#F1F4FF",
        }}
      >
        <img src={mattersOpened} alt="matters icon" />
      </div>
      <p className="fs-md fw-semibold mt-1 mb-1" style={{ color: "#5379FF" }}>
        Matters Opened
      </p>
      <p className="fs-xl fw-bold">{totalActual}</p>
    </div>
  );
};
