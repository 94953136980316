import {
  START_LOADING,
  STOP_LOADING,
  ERROR,
  SUCCESS,
  GET_NOMINALS_LIST,
  ADD_NOMINAL,
  GET_VIEW_NOMINALS,
  UPDATE_NOMINAL,
  SEARCH_NOMINAL,
  GET_ACTIVENOMINALS,
  GET_NOMINALS_BY_REF,
  GET_OFFICELIST,
  GET_NOMINAL_BALANCES,
  GET_CLIENTLIST,
  GET_DDALIST,
  GET_NOMINAL_JOURNAL_POSTING,
  CURRENCT_NOMINAL_REF,
  SET_NOMINALS_PROFIT_AND_LOSS,
  SET_NOMINALS_DEFAULT_PROFIT_AND_LOSS
} from "../types/index.js";

const nominalReducer = (
  state = {
    IsError: false,
    isSuccess: false,
    isLoading: undefined,
    activenominals: { data: [] },
    nominalJournal: { data: [] },
    officeList: { data: [] },
    clientList: { data: [] },
    ddaList: { data: [] },
    nominalPofitAndLoss: { data: [] },
    nominalDefaultDDABank: { data: [] },
    nominalRefBalances: "",

  },
  action
) => {
  switch (action.type) {
    case START_LOADING:
      return { ...state, isLoading: true };
    case STOP_LOADING:
      return { ...state, isLoading: false };
    case GET_NOMINALS_LIST:
      return {
        ...state,
        nominalList: action.payload,
        isNominalUpdated: false,
      };
    case ADD_NOMINAL:
      return {
        ...state,
        nominalList: action.payload,
        isNominalUpdated: true,
      };
    case CURRENCT_NOMINAL_REF:
      if (action.payload) {
        return {
          ...state,
          currentNominalRef: action.payload
        };
      } else {
        return {
          ...state,
          currentNominalRef: action.payload
        };
      }
    case GET_VIEW_NOMINALS:
      if (action.payload) {
        return {
          ...state,
          viewNominals: action.payload,
        };
      } else {
        return {
          ...state,
          viewNominals: undefined,
        };
      }
    case UPDATE_NOMINAL:
      return {
        ...state,
        nominalList: action.payload,
        isNominalUpdated: true,
      };
    case SEARCH_NOMINAL:
      return {
        ...state,
        nominalList: action.payload,
      };
    case SET_NOMINALS_PROFIT_AND_LOSS:
      return {
        ...state,
        nominalPofitAndLoss: action.payload
      }
    case SET_NOMINALS_DEFAULT_PROFIT_AND_LOSS:
      return {
        ...state,
        nominalDefaultDDABank: action.payload
      }
    case GET_OFFICELIST:
      return {
        ...state,
        officeList: action.payload,
      };
    case GET_CLIENTLIST:
      return {
        ...state,
        clientList: action.payload,
      };
    case GET_NOMINAL_JOURNAL_POSTING:
      return {
        ...state,
        nominalJournal: action.payload.data,
      };
    case GET_DDALIST:
      return {
        ...state,
        ddaList: action.payload,
      };
    case GET_ACTIVENOMINALS:
      return {
        ...state,
        activenominals: action.payload,
      };
    case GET_NOMINALS_BY_REF:
      return {
        ...state,
        getNominalsByRefs: action.payload,
      };
    case GET_NOMINAL_BALANCES:
      return {
        ...state,
        nominalRefBalances: action.payload,
      };
    case ERROR:
      return { ...state, IsError: true, isSuccess: false };
    case SUCCESS:
      return { ...state, IsError: false, isSuccess: true };
    default:
      return state;
  }
};
export default nominalReducer;
