import React, { useEffect, useState } from "react";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import Title from "./TabTitle";
import { getMatterAllInfo } from "api";
import { useMatch, useLocation, useNavigate } from "react-router-dom";
import LoadingScreen from "components/common/Loading";
import MatterView from "../../pages/Matters/MatterViewPage";
import MyMattersPageComponent from "components/Matters/MattersGridComponent";
import CaseFileView from "pages/CaseFile";
import Clients from "pages/Clients/ClientsList";
import { useAllowedLiscence } from "hooks/useAllowedLiscence";
import { Tabs } from "components/Enums/TabEnums.ts";
import Contacts from "pages/Contacts/Contact";
import Onboarding from "pages/Onboarding/Onboarding";

const getMatterTabName = (item) => {
  if (
    item?.matterDetails !== null &&
    item?.matterDetails !== "" &&
    item?.matterDetails !== undefined
  )
    return item?.matterDetails;
  return item.matterRef;
};

const FIXED_TABS = [
  { id: "0", name: "Clients List" },
  { id: "1", name: "Contacts List" },
  { id: "2", name: "Matter List" },
  { id: "3", name: "Client Onboarding" },
];

const reorderTabs = (tabs) => {
  const orderedTabs = [
    tabs.find((tab) => tab.id === FIXED_TABS[0].id), // Clients List
    tabs.find((tab) => tab.id === FIXED_TABS[1].id), // Contacts List
    tabs.find((tab) => tab.id === FIXED_TABS[2].id), // Matters List
    tabs.find((tab) => tab.id === FIXED_TABS[3].id), // Client Onboarding
    ...tabs.filter(
      (tab) => !FIXED_TABS.some((fixedTab) => fixedTab.id === tab.id)
    ), // Other tabs
  ];
  return orderedTabs.filter(Boolean); // Remove undefined entries
};

const MatterTabs = React.memo(() => {
  const navigate = useNavigate();
  const location = useLocation();
  const { allowed } = useAllowedLiscence(Tabs.MATTERS);
  const [selected, setSelected] = useState(0);
  const [tabs, setTabs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const params = useMatch("/matters/:id");
  const onboardingParams = useMatch("/clients/onboarding");

  const clientParams = useMatch("/clients");
  const contactParams = useMatch("/contacts");

  useEffect(() => {
    if (params?.params?.id) {
      const mattersDataGetter = async () => {
        try {
          const data = await getMatterAllInfo(params.params.id);
          const matterTabName = getMatterTabName(data.data.data);
          const existingTab = tabs.find((tab) => tab.name === matterTabName);

          if (existingTab) {
            setSelected(tabs.indexOf(existingTab));
          } else {
            setTabs((prevTabs) =>
              reorderTabs([
                ...prevTabs,
                {
                  name: matterTabName,
                  id: `matter-${params.params.id}`,
                  matterId: params.params.id,
                },
              ])
            );
            setSelected(tabs.length);
          }
        } catch (error) {
          alert(
            "No matter exists against this Id. Try again with different Id"
          );
          navigate("/matters");
        }
      };
      mattersDataGetter();
    }
  }, [params, navigate]);

  useEffect(() => {
    if (clientParams) {
      const existingTab = tabs.find((tab) => tab.id === FIXED_TABS[0].id);
      if (existingTab) {
        setSelected(tabs.indexOf(existingTab));
      } else {
        let existingNewTab = 0;
        setTabs((prevTabs) => {
          const tabsReturendData = reorderTabs([FIXED_TABS[0], ...prevTabs]);
          existingNewTab = tabsReturendData.indexOf(
            tabsReturendData.find((tab) => tab.id === FIXED_TABS[0].id)
          );
          return tabsReturendData;
        });
        setTimeout(() => {
          setSelected(existingNewTab);
        }, 50);
      }
    }
  }, [clientParams]);

  useEffect(() => {
    if (contactParams) {
      const existingTab = tabs.find((tab) => tab.id === FIXED_TABS[1].id);
      if (existingTab) {
        setSelected(tabs.indexOf(existingTab));
      } else {
        let existingNewTab = 0;
        setTabs((prevTabs) => {
          const tabsReturendData = reorderTabs([FIXED_TABS[1], ...prevTabs]);
          existingNewTab = tabsReturendData.indexOf(
            tabsReturendData.find((tab) => tab.id === FIXED_TABS[1].id)
          );
          return tabsReturendData;
        });
        setTimeout(() => {
          setSelected(existingNewTab);
        }, 50);
      }
    }
  }, [contactParams]);

  useEffect(() => {
    if (location.pathname === "/matters") {
      const existingTab = tabs.find((tab) => tab.id === FIXED_TABS[2].id);
      if (existingTab) {
        setSelected(tabs.indexOf(existingTab));
      } else {
        let existingNewTab = 0;
        setTabs((prevTabs) => {
          const tabsReturendData = reorderTabs([FIXED_TABS[2], ...prevTabs]);
          existingNewTab = tabsReturendData.indexOf(
            tabsReturendData.find((tab) => tab.id === FIXED_TABS[2].id)
          );
          return tabsReturendData;
        });
        setTimeout(() => {
          setSelected(existingNewTab);
        }, 50);
      }
    }
  }, [location.pathname]);

  useEffect(() => {
    if (onboardingParams) {
      const existingTab = tabs.find((tab) => tab.id === FIXED_TABS[3].id);
      if (existingTab) {
        setSelected(tabs.indexOf(existingTab));
      } else {
        let existingNewTab = 0;
        setTabs((prevTabs) => {
          const tabsReturendData = reorderTabs([FIXED_TABS[3], ...prevTabs]);
          existingNewTab = tabsReturendData.indexOf(
            tabsReturendData.find((tab) => tab.id === FIXED_TABS[3].id)
          );
          return tabsReturendData;
        });
        setTimeout(() => {
          setSelected(existingNewTab);
        }, 50);
      }
    }
  }, [onboardingParams]);

  const handleSelect = (e) => {
    setSelected(e.selected);
    const selectedTab = tabs[e.selected];
    if (selectedTab.matterId) {
      navigate(`/matters/${selectedTab.matterId}`);
    } else if (selectedTab.name === "Clients List") {
      navigate("/clients");
    } else if (selectedTab.name === "Client Onboarding") {
      navigate("/clients/onboarding");
    } else if (selectedTab.name === "Contacts List") {
      navigate("/contacts");
    } else {
      navigate("/matters");
    }
  };

  const removeTab = (tabName) => {
    let tabs = [];
    setTabs((prevTabs) => {
      tabs = reorderTabs(prevTabs.filter((t) => t.name !== tabName));
      return tabs;
    });
    if (!tabs.length) {
      navigate("/");
    } else {
      handleSelect({
        selected: 0,
      });
    }
    if (
      ![
        "Clients List",
        "Contacts List",
        "Matter List",
        "Client Onboarding",
      ].includes(tabName)
    ) {
      try {
        window.chrome.webview.postMessage("CasefileClosed:" + tabName);
      } catch (error) {}
    }
  };
  return allowed ? (
    <TabStrip
      selected={selected}
      onSelect={handleSelect}
      keepTabsMounted={true}
      className="navigationTab"
    >
      {tabs?.map((item, index) => (
        <TabStripTab
          title={
            <Title
              content={item.name}
              onTabRemove={() => removeTab(item.name)}
              selected={tabs[selected]?.name}
              count={tabs.length}
              style={{ fontSize: "14px" }}
              maxWidth={"220px"}
            />
          }
          key={index}
        >
          <div>
            {item.id === "0" && <Clients />}
            {item.id === "1" && <Contacts />}
            {item.id === "2" && (
              <MyMattersPageComponent component={"matters"} />
            )}
            {item.matterId && (
              <CaseFileView
                selTab={selected}
                reference={item.name}
                id={item.matterId}
                selectedid={selected}
              />
            )}
            {item.id === "3" && <Onboarding />}
          </div>
          {isLoading && <LoadingScreen />}
        </TabStripTab>
      ))}
    </TabStrip>
  ) : (
    <></>
  );
});

export default MatterTabs;
