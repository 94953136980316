import React from "react";
import {
  CategoryBadgeComponent,
  PrimaryBadgeComponent,
} from "components/MatterDetails/ContactCard";

const EmailListRenderer = ({ li, itemProps }) => {
  const itemChildren = (
    <div className="d-flex flex-column w-100">
      <span className="fs-sm">{itemProps.dataItem.name}</span>
      <div className="d-flex justify-content-between align-items-center w-100">
        <div>{li.props.children}</div>
        <div className="fs-sm ms-auto">
          <CategoryBadgeComponent text={itemProps.dataItem.contactCategory} />
        </div>
      </div>
    </div>
  );
  return React.cloneElement(li, li.props, itemChildren);
};

export default EmailListRenderer;
