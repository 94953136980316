import * as React from "react";
// import Modal from "../Modal";
import { Button } from "@progress/kendo-react-buttons";
import { TextArea } from "@progress/kendo-react-inputs";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addTimeFunctionality } from "actions/time.action";
import { Label } from "@progress/kendo-react-labels";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { Input } from "@progress/kendo-react-inputs";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { NumericTextBox } from "@progress/kendo-react-inputs";
import MattersCombobox from "../Postings/MattersComboBox";
import TimeModal from "./TimeModal";
import { useEffect } from "react";
import { usePreComponentCalls } from "components/layout/PreComponentLoad";
import MatterDetails from "pages/MatterLedger/MatterDetails";
import MatterAccount from "pages/MatterLedger/MatterAccount";
import { getMattersBalances } from "actions/matters.action";
import styles from "./time.module.scss";
import { prepareFeeearnerData } from "components/common/ComboRepresentationFunctions";
import CalenderCustomToggleButton from "components/common/CalenderCustomToogleButton";
import { CustomDateInput } from "components/common/CustomDateInput";
import { getMatterRates, getActiveTimeTypes } from "api";
import { Tooltip } from "@progress/kendo-react-tooltip";
import infoIcon from "../../assets/CaseFileIcons/info-icon.png";
import { DialogActionsBar } from "@progress/kendo-react-dialogs";
import { toast } from "react-toastify";

const AddTime = ({ fromFeeEarner, fromCaseFile, ...props }) => {
  const IS_MODAL = props.component === "modal";
  const dispatch = useDispatch();
  const date = new Date();
  const [details, setDetails] = useState("");
  const [units, setUnits] = useState(1);
  const [rateInfoText, setRateInfoText] = useState(0);
  const [mpu, setMpu] = useState(6);
  const [rate, setRate] = useState(0);
  const [value, setValue] = useState(0);
  const [dateselector, setdateselector] = useState(date);
  const [matterBalanceClear, setMatterBalanceClear] = useState(false);
  const [matterRef, setmatterref] = React.useState(
    IS_MODAL ? props.matter : null
  );
  const [feeearner, setfeeearnerData] = React.useState([]);
  const [feeearnerref, setfeeearnerref] = useState(
    props.matter &&
      props?.matter?.feeEarnerRef && {
        userRef: props?.matter?.feeEarnerRef,
        representation: `${props?.matter?.feeEarnerRef} - ${props?.matter?.feeEarnerName}`,
      }
  );
  const [modalvalue, setmodalvalue] = useState(false);
  const [error, setError] = useState(undefined);
  const [timetypes, setTimetypes] = useState();
  const [type, settype] = useState();

  const { handleCalls } = usePreComponentCalls();
  useEffect(() => {
    handleCalls("Time Recording");
  }, []);
  useEffect(() => {
    if (IS_MODAL) {
      dispatch(getMattersBalances(props.matter?.matterId, "Time Recording"));
    }
  }, []);
  const feeearnerdata = useSelector((state) => {
    return state.postingsdocs.feeearner.data;
  });
  useEffect(() => {
    setTimeout(() => {
      setfeeearnerData(prepareFeeearnerData(feeearnerdata));
    }, 1000);
  }, [feeearnerdata]);

  const onChangematter = (event) => {
    if (IS_MODAL) return;
    setMatterBalanceClear(false);
    if (event.value !== null && event.value !== undefined) {
      setmatterref(event.value);
      if (event?.value?.feeEarnerRef) {
        setfeeearnerref({
          userRef: event?.value?.feeEarnerRef,
          representation: `${event?.value?.feeEarnerRef} - ${event?.value?.feeEarnerName}`,
        });
      } else {
        setfeeearnerref(null);
      }
      dispatch(getMattersBalances(event?.value?.matterId, "Time Recording"));
    } else {
      setMatterBalanceClear(true);
      setfeeearnerref();
    }
  };
  useEffect(() => {
    getTimetypes();
  }, []);
  const onChangedateselect = (event) => {
    setdateselector(event.value);
  };
  const onChangefeeearner = (event) => {
    setfeeearnerref(event.value);
  };

  const onChangetimetype = (event) => {
    settype(event.value);
  };
  const calculateValue = () => {
    if (
      rate !== null &&
      rate !== undefined &&
      units !== null &&
      units !== undefined &&
      mpu !== null &&
      mpu !== undefined &&
      units !== 0 &&
      mpu !== 0
    ) {
      const value = (rate / 60) * (units * mpu);
      setValue(value);
    }
  };
  useEffect(() => {
    calculateValue();
  }, [units, mpu, rate]);

  const getMatterRateValue = async () => {
    try {
      const data = await getMatterRates(
        matterRef?.matterId,
        feeearnerref.userRef
      );
      setRate(data?.data?.data?.rateValue);
      setRateInfoText(data?.data?.data?.rateInfo);
    } catch (error) {}
  };

  useEffect(() => {
    if (matterRef && feeearnerref) getMatterRateValue();
  }, [matterRef, feeearnerref]);

  function Submithandler(e) {
    e.preventDefault();
    const data = [
      {
        timeId: 0,
        matterId: matterRef?.matterId,
        feeEarnerRef: feeearnerref?.userRef,
        date: dateselector,
        details: details,
        units: units,
        mpu: mpu,
        rate: rate,
        value: value,
        timeTypeId: type?.timeTypeID,
      },
    ];

    if (IS_MODAL) {
      data[0].matterId = props.matter?.matterId;
    }

    if (
      data[0].matterId === undefined ||
      data[0].details === "" ||
      data[0].units === 0 ||
      data[0].rate === 0 ||
      data[0].value === "" ||
      data[0].timeTypeId === undefined
    ) {
      setError(true);
      return;
    }

    const onSuccess = () => {
      if (fromCaseFile) {
        props?.fetchTime();
        props.showModal();
      }
      if (IS_MODAL && !fromCaseFile) {
        props.cancelEdit();
      }
      reset();
    };

    dispatch(addTimeFunctionality(data, onSuccess));
  }
  const reset = () => {
    setError(undefined);
    setDetails("");
    setValue(0);
    setMpu(6);
    setUnits(1);
    setRate(0);
  };

  const handleCancel = () => {
    if (fromCaseFile) {
      props.showModal();
    } else reset();
  };

  const getTimetypes = async () => {
    try {
      const data = await getActiveTimeTypes();
      setTimetypes(data?.data?.data);
    } catch (error) {}
  };

  return (
    <form
      onSubmit={Submithandler}
      style={{
        height: fromFeeEarner ? "100vh" : "calc(100% - 65px)",
      }}
    >
      <div
        className={`${
          fromFeeEarner ? "vh-100" : "mx-lg-5 mx-1 h-100"
        } d-flex justify-content-center`}
      >
        <div className="bgForm overflow-auto">
          <div
            className="px-lg-5 px-3 "
            style={{
              maxHeight: fromFeeEarner ? "calc(100vh - 130px)" : "auto",
              overflow: "auto",
            }}
          >
            <div className="d-flex mt-3">
              <div
                className={`d-flex flex-column me-5
                ${fromFeeEarner ? "mt-5" : ""}
                `}
              >
                <div>
                  <Label className="label mb-1 d-block">Matter</Label>
                  <MattersCombobox
                    width={280}
                    onChangematter={onChangematter}
                    error={error === true && matterRef === undefined}
                    value={IS_MODAL ? props.matter : undefined}
                    disabled={IS_MODAL}
                  />
                </div>
                <div className="mt-2">
                  <Label className="label mb-1 d-block">Date</Label>
                  <DatePicker
                    className="datepicker px-2"
                    dateInput={CustomDateInput}
                    toggleButton={CalenderCustomToggleButton}
                    value={dateselector}
                    onChange={onChangedateselect}
                    defaultValue={dateselector}
                  />
                </div>
                <div className="mt-2">
                  <Label className="label mb-1 d-block">Time Type</Label>
                  <ComboBox
                    style={{
                      textAlign: "end",
                      border: error === true && !type && "1px solid red",
                      width: "280px",
                    }}
                    data={timetypes}
                    textField={`name`}
                    iconClassName="wa"
                    filterable={true}
                    placeholder={"Add Time Type"}
                    value={type}
                    onChange={onChangetimetype}
                  />
                </div>
                <Label className="label mb-1 d-block mt-2">Fee Earner</Label>
                <ComboBox
                  style={{
                    width: "280px",
                    border:
                      error === true &&
                      (feeearnerref === null || feeearnerref === undefined) &&
                      "1px solid red",
                  }}
                  data={feeearner}
                  textField={`representation`}
                  iconClassName="wa"
                  filterable={true}
                  placeholder={"Search Fee Earner"}
                  value={feeearnerref}
                  onChange={onChangefeeearner}
                />
              </div>

              <div>
                <div>
                  <MatterDetails
                    matter={matterRef}
                    clear={matterBalanceClear}
                  />
                </div>
                <div className="mt-3">
                  <MatterAccount
                    component={"Time Recording"}
                    matterId={matterRef?.matterId}
                    clear={matterBalanceClear}
                    width={"480px"}
                  />
                </div>
              </div>
            </div>
            <div className="d-flex  mt-2">
              <div className="d-flex  align-items-end me-5">
                <div className="d-flex flex-column me-3 ">
                  <Label className="label mb-1 d-block">Units</Label>
                  <NumericTextBox
                    type={"number"}
                    value={units}
                    onChange={(e) => {
                      setUnits(e.target.value);
                    }}
                    style={{
                      width: "130px",
                    }}
                    onKeyDown={(e) => {
                      if (e.key === ".") {
                        return e.preventDefault();
                      }
                    }}
                  />
                </div>

                <div className="d-flex">
                  <div className="d-flex flex-column right-align">
                    <div className="d-flex align-items-baseline ">
                      <Label className="label mb-1 d-block">Rate</Label>
                      <Tooltip anchorElement="target" position="top">
                        <img
                          src={infoIcon}
                          title={rateInfoText}
                          className="ms-1  cursor-pointer"
                          style={{
                            height: "14px",
                            width: "14px",
                          }}
                        />
                      </Tooltip>
                    </div>
                    <NumericTextBox
                      style={{
                        textAlign: "end",
                        border: error === true && rate === 0 && "1px solid red",
                        width: "130px",
                      }}
                      width={90}
                      step={0}
                      spinners={false}
                      format={"n2"}
                      type={"number"}
                      value={rate}
                      onKeyDown={(e) => {
                        if (e.key === ".") {
                          return e.preventDefault();
                        }
                      }}
                      onChange={(e) => {
                        setRate(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="d-flex flex-column right-align  ">
                <div className="d-flex align-items-baseline ">
                  <Label className="label mb-1 d-block">Value</Label>
                  <Tooltip anchorElement="target" position="top">
                    <img
                      className="ms-1 cursor-pointer"
                      src={infoIcon}
                      title={"MPU is equal to 6"}
                      style={{
                        height: "14px",
                        width: "14px",
                      }}
                    />
                  </Tooltip>
                </div>
                <NumericTextBox
                  width={280}
                  style={{ textAlign: "right" }}
                  value={value}
                  readOnly={true}
                  placeholder="0.00"
                  spinners={false}
                  format="n2"
                />
              </div>
            </div>

            <div className="d-flex my-2 flex-column">
              <Label className="label mb-1 ">Details</Label>
              <TextArea
                value={details}
                style={{
                  height: 50,
                  textAlign: "start",
                  maxWidth: "100%",
                  width: "100%",
                  border: error === true && details === "" && "1px solid red",
                }}
                rows={5}
                multiple={true}
                placeholder="Enter Details"
                onChange={(e) => {
                  setDetails(e.target.value);
                }}
              />
            </div>
            {fromFeeEarner && (
              <div>
                <div className={`d-flex float-end mt-5`}>
                  <Button
                    className="common-btn bgBtnSecondary me-3"
                    type="button"
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="common-btn "
                    type="submit"
                    id="time-recording-submit"
                  >
                    Post
                  </Button>
                </div>
              </div>
            )}
          </div>

          {/* {modalvalue && (
            <TimeModal
              close={() => {
                setmodalvalue(false);
                if (fromCaseFile) props.showModal();
                if (IS_MODAL && !fromCaseFile) props.cancelEdit();
                reset();
              }}
              errorClose={() => {
                setmodalvalue(false);
              }}
            />
          )} */}
        </div>
      </div>
      <div
        style={{
          height: "65px",
        }}
      >
        {!fromFeeEarner && (
          <DialogActionsBar>
            <div className={`d-flex float-end dialog-footer w-100`}>
              <Button
                className="common-btn bgBtnSecondary me-3"
                type="button"
                onClick={props.cancelEdit}
              >
                Cancel
              </Button>
              <Button
                className="common-btn me-4"
                type="submit"
                id="time-recording-submit"
              >
                Post
              </Button>
            </div>
          </DialogActionsBar>
        )}
      </div>
    </form>
  );
};
export default AddTime;
