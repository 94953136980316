import {
  START_LOADING,
  STOP_LOADING,
  ERROR,
  SUCCESS,
  GET_Suppliers_LIST,
  GET_CONSULTANT_LIST,
  ADD_SUPPLIER,
  GET_VIEW_SUPPLIER,
  UPDATE_SUPPLIER,
  SEARCH_SUPPLIER,
  GET_SUPPLIERREFS,
  GET_CONSULTANTREFS,
  GET_SUPPLIERS_BY_REF,
  GET_SUPPLIER_BALANCES,
} from "../types/index";
import * as api from "../api/index.js";
import { handleApiError } from "Helper";
import { toast } from "react-toastify";

export const getSuppliersList = (page, limit, sort) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    let parameter;
    if (sort === undefined) {
      parameter = "supplierRef";
    } else {
      if (sort.dir === "asc") {
        parameter = sort.field;
      } else if (sort.dir === "desc") {
        parameter = sort.field + " desc";
      }
    }
    const datas = await api.getSuppliersList(page, limit, parameter);
    dispatch({ type: GET_Suppliers_LIST, payload: datas.data });
    dispatch({ type: STOP_LOADING });
    dispatch({ type: SUCCESS, payload: datas.message });
  } catch (error) {
    handleApiError(error);
  }
};

export const addSupplier = (data) => async (dispatch) => {
  try {
    const res = await api.addSupplier(data);
    toast.success("Supplier Added!");
    dispatch({ type: ADD_SUPPLIER, payload: res });
  } catch (error) {
    handleApiError(error);
  }
};

export const getViewSupplier = (id) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const datas = await api.getViewSupplier(id);
    dispatch({ type: GET_VIEW_SUPPLIER, payload: datas.data });
    dispatch({ type: STOP_LOADING });
    dispatch({ type: SUCCESS, payload: datas.message });
  } catch (error) {
    handleApiError(error);
  }
};

export const getSupplierrefs = () => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const datas = await api.getSupplierrefs();
    dispatch({ type: GET_SUPPLIERREFS, payload: datas.data });
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};
export const updateSupplier = (id, data) => async (dispatch) => {
  try {
    const res = await api.updateSupplier(id, data);
    toast.success("Supplier Updated");
    dispatch({ type: UPDATE_SUPPLIER, payload: res });
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const searchSupplier = (page, limit, search) => async (dispatch) => {
  try {
    const res = await api.searchSupplier(page, limit, search);
    dispatch({ type: SEARCH_SUPPLIER, payload: res.data });
  } catch (error) {
    handleApiError(error);
  }
};

export const getSuppliersByRef = (searchedVal) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const datas = await api.getSuppliersByRef(searchedVal);
    dispatch({ type: GET_SUPPLIERS_BY_REF, payload: datas.data });
  } catch (error) {
    handleApiError(error);
  }
};

export const getSupplierBalances =
  (searchedVal, FromDate, ToDate) => async (dispatch) => {
    try {
      dispatch({ type: START_LOADING });
      const { data } = await api.getSupplierBalances(
        searchedVal,
        FromDate,
        ToDate
      );
      dispatch({ type: GET_SUPPLIER_BALANCES, payload: data });
    } catch (error) {
      dispatch({ type: ERROR, payload: error });
    }
  };

export const getConsultantsList = (page, limit, sort) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    let parameter;
    if (sort === undefined) {
      parameter = "supplierRef";
    } else {
      if (sort.dir === "asc") {
        parameter = sort.field;
      } else if (sort.dir === "desc") {
        parameter = sort.field + " desc";
      }
    }
    const datas = await api.getConsultantsList(page, limit, parameter);
    dispatch({ type: GET_CONSULTANT_LIST, payload: datas.data });
    dispatch({ type: STOP_LOADING });
    dispatch({ type: SUCCESS, payload: datas.message });
  } catch (error) {
    handleApiError(error);
  }
};

export const getConsultantRefs = () => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const datas = await api.getConsultantRefs();
    dispatch({ type: GET_CONSULTANTREFS, payload: datas.data });
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};
