import * as React from "react";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Popup } from "@progress/kendo-react-popup";
import { useOidc } from "@axa-fr/react-oidc";
import { useNavigate } from "react-router-dom";
import { BiCog } from "react-icons/bi";
import { loggedOut } from "../../actions/autologin.action";
import DefaultProfile from "../../assets/Default-Profile.png";
import { Loader } from "@progress/kendo-react-indicators";
import styles from "./HeaderProfile.module.scss";
import helpIcon from "../../assets/Menu/help.png";
import profileIcon from "../../assets/Menu/profile.png";
import logoutIcon from "../../assets/Menu/logout.png";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Overlay } from "components/Overlay";

export const ProfileBox = ({ collapsed }) => {
  const [show, setShow] = React.useState(false);
  const [showImage, setShowImage] = useState(false);
  const [headerName, setHeaderName] = useState("");
  const anchorMenu = React.useRef(null);
  const [showMenu, setShowMenu] = React.useState(false);
  const anchor = React.useRef();
  const menuRef = React.useRef();

  const toogle = () => setShow((prev) => !prev);

  const image = useSelector((state) => state.currentuser.image);

  useEffect(() => {
    if (image !== null) {
      setShowImage(image);
    }
  }, [image]);

  const { logout } = useOidc();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userdata = useSelector((state) => {
    return state.currentuser.userlist;
  });

  const companydata = useSelector((state) => {
    return state.company.company.displayName;
  });

  const logoutApp = () => {
    localStorage.removeItem("oidc.server:https://auth.linq.legal/");
    navigate("/");
    sessionStorage.clear();
    setShow(false);
    setShowMenu(false);
    dispatch(loggedOut());
    logout("/");
  };

  const headerData = useSelector((state) => state.headerName.headerName);

  useEffect(() => setHeaderName(headerData), [headerData]);

  useEffect(() => {
    const close = (e) => {
      if (e?.target?.alt === "user-avatar") return;
      if (e?.target?.role !== "button") setShow(false);
    };

    document.addEventListener("mousedown", close);
    return () => {
      document.removeEventListener("mousedown", close);
    };
  }, []);

  useEffect(() => {
    const close = (e) => {
      if (e?.target?.role === "menu") return;
      if (e?.target?.role !== "button") setShowMenu(false);
    };

    document.addEventListener("mousedown", close);
    return () => {
      document.removeEventListener("mousedown", close);
    };
  }, []);

  return (
    <>
      <div
        className="d-flex align-items-center m-3 ms-4 mt-2 me-3 "
        style={{ width: "260px" }}
      >
        {show && <Overlay />}
        {showMenu && <Overlay />}
        {collapsed ? (
          <div
            onClick={toogle}
            className="d-flex align-items-center justify-content-center gap-2 cursor-pointer"
          >
            <div className={`me-1 ${styles["image-container"]}`}>
              <img
                className="h-100 w-100"
                style={{ cursor: "pointer" }}
                src={
                  userdata.imageInfo.documentFile.downloadURL
                    ? userdata.imageInfo.documentFile.downloadURL
                    : DefaultProfile
                }
                alt={DefaultProfile}
                ref={anchor}
              />
            </div>
          </div>
        ) : (
          <div
            onClick={toogle}
            className="d-flex align-items-center justify-content-center cursor-pointer"
          >
            <ImageContainer>
              <img
                className="h-100 w-100 me-1"
                style={{ cursor: "pointer" }}
                src={
                  userdata.imageInfo.documentFile.downloadURL
                    ? userdata.imageInfo.documentFile.downloadURL
                    : DefaultProfile
                }
                alt={DefaultProfile}
                ref={anchor}
              />
            </ImageContainer>

            <div className="ms-2  mt-2 me-1">
              <div style={{ minWidth: "165px" }}>
                <div style={{ lineHeight: "10px" }} className="text-start">
                  <span className={`mb-0 ${styles["user-name"]}`}>
                    {userdata?.displayname}
                  </span>
                </div>
                <div
                  className="text-start"
                  style={{
                    overflow: "clip",
                    textOverflow: "ellipsis",
                    width: "180px",
                  }}
                >
                  <span className={`mb-0 ${styles["gray-text"]}`}>
                    {userdata?.emailAddress}
                  </span>
                </div>
              </div>
            </div>

            <div
              role={"menu"}
              className={`cursor-pointer p-1 m-1a ${styles["menu-hover"]}`}
            >
              <BsThreeDotsVertical size={25} color="black" role="menu" />
            </div>
          </div>
        )}
      </div>

      <div className={styles["popup-conatiner"]} ref={menuRef}>
        <Popup
          show={show}
          popupClass={styles["popup-content"]}
          animate={false}
          anchor={anchor.current}
        >
          <div className="popup-items mb-3">
            <p className={styles["company-name"]}>{companydata}</p>
            <div className={styles["border-bottom"]}></div>
            {userdata.displayname === "User" ? (
              <div className="m-auto mt-2">
                <Loader />
              </div>
            ) : (
              <>
                <div className="d-flex pt-4 mx-auto">
                  {/* <Avatar type="image" className={styles["profile-image"]}>
                    <img
                      className="h-100 w-100"
                      src={userdata.imageInfo.documentFile.downloadURL}
                      alt="P"
                    />
                  </Avatar> */}

                  <div className="d-flex flex-column">
                    <span className={styles["profile-name"]}>
                      {userdata.displayname}
                    </span>
                    <span className={styles["profile-email"]}>
                      {userdata.emailAddress}
                    </span>
                    <span className={styles["job-title"]}>
                      {userdata.jobTitle || "Senior Solicitor"}
                    </span>
                  </div>
                </div>
                <div className="flex flex-column  mt-4">
                  <List
                    imageSrc={profileIcon}
                    name="Profile"
                    border={true}
                    onClick={() => {
                      navigate("/settings/profile");
                      setShow(false);
                    }}
                  />
                  <List
                    border={true}
                    icon={<BiCog size={18} color="gray" />}
                    name="Settings"
                    onClick={() => {
                      navigate("/settings");
                      setShow(false);
                    }}
                  />
                  <List border={true} imageSrc={helpIcon} name="Help & Guide" />
                  <List
                    border={false}
                    imageSrc={logoutIcon}
                    name="Log Out"
                    onClick={logoutApp}
                  />
                </div>
              </>
            )}
          </div>
        </Popup>
      </div>
      <div className={styles["popup-conatiner"]}>
        <Popup
          show={showMenu}
          anchor={anchorMenu.current}
          popupClass={`${styles["popup-content"]} ${styles["menu-popup"]}`}
          anchorAlign={{ horizontal: "left", vertical: "top" }}
          popupAlign={{ horizontal: "right", vertical: "bottom" }}
          animate={false}
        >
          {/* <div
          style={{
            width: 200,
            textAlign: "center",
            borderRadius: "15px",
          }}
          role="button"
          className="p-2 text-center cursor-pointer d-flex align-items-center justify-center gap-2"
          onClick={() => {
            navigate("/settings");
            setShowMenu(false);
          }}
        >
          <div className="ms-2" role="button">
            <BiCog size={20} />
          </div>
          <div role="button">Settings</div>
        </div> */}
          <div className="popup-items mb-3">
            <p className={styles["company-name"]}>{companydata}</p>
            <div className={styles["border-bottom"]}></div>
            <div className="flex flex-column mt-4 justify-start">
              <List
                imageSrc={profileIcon}
                name="Profile"
                border={true}
                onClick={() => {
                  // dispatch(openProfile());
                  navigate("/settings/profile");
                  setShowMenu(false);
                }}
              />
              <List border={true} imageSrc={helpIcon} name="Help & Guide" />

              <List
                border={false}
                imageSrc={logoutIcon}
                name="Log Out"
                onClick={logoutApp}
              />
            </div>
          </div>
        </Popup>
      </div>
    </>
  );
};

export const List = ({ imageSrc, name, onClick, border, icon }) => (
  <span className={styles["list-item"]} role="button" onClick={onClick}>
    <span
      role="button"
      className="flex align-items-center ms-4 pe-0 ps-4"
      style={{ padding: "15px 0px 15px 0px" }}
    >
      {icon ? (
        <span style={{ marginRight: "13px" }}>{icon}</span>
      ) : (
        <img src={imageSrc} alt="Image" style={{ marginRight: "15px" }} />
      )}
      <span role="button" className={`${styles.list}`}>
        {name}
      </span>
    </span>
    {border && <div className={`${styles["border-bottom"]}`}></div>}
  </span>
);

export const ImageContainer = (props) => (
  <div className={`${styles["image-container"]} ${props.class}`}>
    {props.children}
  </div>
);
