import React, {
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import CustomInput from "components/common/Input";
import AddressInputComponent from "components/common/AddressInputComponent";
import { emailValidator } from "components/common/validator";

const CONSTANT = {
  PERSON: "Person",
  COMPANY: "Company",
};

const ContactInformationComponent = ({ readOnly, type, tab = "", addType }) => {
  return (
    <>
      <div className="row mt-3">
        <Field
          disabled={readOnly}
          label="Email"
          name={`${addType}email`}
          ph="Add Email"
          width={"260px"}
          component={CustomInput}
          validator={emailValidator}
        />
      </div>
      <div className="row mt-3">
        <Field
          disabled={readOnly}
          mr="20px"
          name={`${addType}phone1`}
          label="Phone Number"
          type="number"
          ph="Add Phone Number"
          width={"260px"}
          component={CustomInput}
        />
      </div>
      <div className="d-flex mt-3">
        <div
          className="me-4"
          style={{
            width: "260px",
          }}
        >
          <Field
            disabled={readOnly}
            name={`${addType}phone2`}
            label="Secondary Phone"
            type="number"
            ph="Add Phone Number"
            width={"260px"}
            component={CustomInput}
          />
        </div>
        <Field
          disabled={readOnly}
          label="Mobile Number"
          type="number"
          name={`${addType}mobileNo`}
          ph="Add Mobile Number"
          width={"260px"}
          component={CustomInput}
        />
      </div>
      {/* <div className="row mt-2 max-100">
        <Field
          className="col-8"
          disabled={readOnly}
          label="Email"
          name={`${addType}email`}
          ph="e.g john@gmail.com"
          width="100%"
          component={CustomInput}
        />
        <Field
          disabled={readOnly}
          label="Website"
          name={`${addType}website`}
          ph="e.g www.google.com"
          width="206px"
          className="col-4"
          component={CustomInput}
        />
      </div> */}
      <div className="mt-3">
        <AddressInputComponent
          name="Address"
          readOnly={readOnly}
          contactComponent={`col-6`}
          width={"260px"}
          type={addType}
          style={{ width: "570px" }}
        />
      </div>
    </>
  );
};

export default ContactInformationComponent;
