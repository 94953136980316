const emailRegex = new RegExp(/\S+@\S+\.\S+/);
export const emailValidator = (value) =>
  !value
    ? ""
    : emailRegex.test(value)
    ? ""
    : "Email is not in a valid format.";

    export const phoneValidator = (value) =>
      !value
        ? ""
        : value.length <= 15
        ? ""
        : "Phone number should be at max 15 digits.";