import {
  START_LOADING,
  GET_BRANCHES_NAMES,
} from "../types/index";
import * as api from "../api/index.js";
import { handleApiError } from "Helper";

export const getBranchesnames = () => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const  datas  = await api.getBranchnames();
    dispatch({ type: GET_BRANCHES_NAMES, payload: datas.data });
  } catch (error) {handleApiError(error)}
};
