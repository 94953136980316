import * as React from "react";
import TransactionPeriodList from "./TransactionGrid";
import CurrentTransactions from "./CurrentTransactions";
import { useEffect } from "react";
import { usePreComponentCalls } from "components/layout/PreComponentLoad";

const TransactionPeriod = () => {
  const { handleCalls } = usePreComponentCalls();
  useEffect(() => {
    handleCalls("Transaction Period");
  }, []);
  return (
    <div className="page pt-0">
      <div className="pt-4">
        <CurrentTransactions />
      </div>
      <div className="container">
        <TransactionPeriodList />
      </div>
    </div>
  );
};

export default TransactionPeriod;
