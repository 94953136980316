import * as React from "react";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { Button } from "@progress/kendo-react-buttons";
import { editCurrencyinfo } from "actions/currency.action";
import { useDispatch } from "react-redux";
import EditApplication from "./EditApplication";
import { ActiveCell } from "../GridComponents";
import { EditCommandCell, SignatureCell } from "../GridComponents";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { editApplication, getApplications } from "actions/application.action";

const ApplicationsList = () => {
    const [openForm, setOpenForm] = React.useState(false);
    const [data, setData] = React.useState([]);
    const dispatch = useDispatch();
    const [editItem, setEditItem] = React.useState({
        ProductID: 1,
    });
    const enterEdit = (item) => {
        setOpenForm(true);
        setEditItem(item);
    };
    const handleSubmit = (event) => {
        let data = {
            appCode: event.appCode,
            appName: event.appName,
            active: event.active,
        };
        dispatch(editApplication(event.appCode, data));
        setOpenForm(false);
        // setTimeout(() => {
        //   dispatch(getApplications());
        // }, 1000);
    };
    const applicationsList = useSelector(
        (state) => state.applications.applicationsList
    );
    useEffect(() => {
        if (applicationsList !== null && applicationsList !== undefined) {
            applicationsList.data.map((item) => {
                return (item.symbol = item.appCode[0]);
            });
            setData(applicationsList);
        }
    }, [applicationsList]);

    useEffect(() => {
        dispatch(getApplications());
    }, []);
    const [products, setProducts] = React.useState({
        data: [],
        total: 20,
    });
    const [dataState, setDataState] = React.useState({
        take: 10,
        skip: 0,
    });

    const handleCancelEdit = () => {
        setOpenForm(false);
    };
    const MyEditCommandCell = (props) => (
        <EditCommandCell {...props} enterEdit={enterEdit} />
    );
    const dataStateChange = (e) => {
        setDataState(e.dataState);
    };

    const dataReceived = (products) => {
        setProducts(products);
    };

    return (
        <div className="settings-grid">
            <Grid {...dataState} data={data} onDataStateChange={dataStateChange}>
                <Column title=" " field="symbol" cell={SignatureCell} width="80" />
                <Column field="appCode" title="App Code" width="100px" />
                <Column field="appName" title="App Name" />
                <Column cell={ActiveCell} title="Active" width="80" />
                <Column title=" " cell={MyEditCommandCell} width="80" />
            </Grid>
            {openForm && (
                <EditApplication
                    cancelEdit={handleCancelEdit}
                    onSubmit={handleSubmit}
                    item={editItem}
                />
            )}
        </div>
    );
};

export default ApplicationsList;
