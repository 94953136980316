import React from "react";
import styles from "./MatterLedger.module.scss";

const MatterDetails = ({ width, matter, clear }) => {
  return (
    <>
      <table
        className="balance-table"
        style={{
          maxWidth: width ? width : "540px",
          width: width ? width : "440px",
          // height: "100px",
          // minWidth: "450px",
          paddingTop: "10px",
        }}
      >
        <thead>
          <tr>
            <td>
              <u
                className="fs-sm"
                style={{
                  fontWeight: "600",
                  fontSize: "12px",
                }}
              >
                {clear || !matter?.matterRef ? "Matter" : matter?.matterRef}
              </u>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr className="position-relative">
            <td className={`${styles["td-header"]} fw-semibold`} colSpan={1}>
              {"Details:"}
            </td>
            <DetailTD
              value={clear ? "" : matter?.details}
              rowWidth={"80%"}
              colSpan={9}
            />
          </tr>
          <tr className="position-relative">
            <td className={`${styles["td-header"]} fw-semibold`} colSpan={1}>
              {"Client Name:"}
            </td>
            <DetailTD
              value={clear ? "" : matter?.clientName || ""}
              rowWidth={"30%"}
              colSpan={4}
            />
            <td className={`${styles["td-header"]} fw-semibold`} colSpan={2}>
              {"Branch:"}
            </td>
            <DetailTD
              value={clear ? "" : matter?.branch}
              rowWidth={"30%"}
              colSpan={3}
            />
          </tr>
          <tr className="position-relative">
            <td className={`${styles["td-header"]} fw-semibold`} colSpan={1}>
              {"Fee Earner:"}
            </td>
            <DetailTD
              colSpan={4}
              value={clear ? "" : matter?.feeEarnerName}
              rowWidth={"30%"}
            />
            <td className={`${styles["td-header"]} fw-semibold`} colSpan={1}>
              {"Supervisor:"}
            </td>
            <DetailTD
              value={clear ? "" : matter?.supervisorName}
              rowWidth={"30%"}
              colSpan={4}
            />
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default MatterDetails;

const DetailTD = ({ value, colSpan }) => {
  return (
    <td
      className="pe-1 text-truncate"
      style={{
        color: "gray",
        whiteSpace: "nowrap",
        overflow: "hidden",
        maxWidth: "20px",
      }}
      colSpan={colSpan}
    >
      {value}
    </td>
  );
};
