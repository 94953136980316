import React, { useState } from "react";
import { useSelector } from "react-redux";
import { getActivePermissions, editAccessibleUsers } from "api/index";
import { prepareFeeearnerData } from "components/common/ComboRepresentationFunctions";
import { useEffect } from "react";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { ContainerCard } from "components/MatterDetails/ContactCard";
import { FormComboBox } from "components/Matters";
import { Button } from "@progress/kendo-react-buttons";
import styles from "./Matter.module.scss";
import { CrossIcon } from "components/tabs/TabTitle";

export const MatterPermissions = ({
    matterId,
    accessibleUsers = [],
    setAccessibleUsers,
    isView,
}) => {
    const { feeearner } = useSelector((state) => state.postingsdocs);
    const [comboBoxState, setComboBoxState] = useState({
        accessibleUser: "",
    });

    const handleAccesibleUsers = async (feeEarner, type) => {
        try {
            if (type === "add") {
                setAccessibleUsers((prevItem) => [
                    ...prevItem,
                    {
                        feeEarnerRef: feeEarner?.userRef,
                        displayName: feeEarner?.displayname,
                        active: true,
                    },
                ]);
            } else {
                const updatedUsers = accessibleUsers.map((user) => {
                    if (
                        user?.feeEarnerRef === feeEarner.feeEarnerRef ||
                        user?.userRef === feeEarner.feeEarnerRef
                    ) {
                        user.active = false;
                    }
                    return user;
                });

                setAccessibleUsers(updatedUsers);
            }
        } catch (error) { }
    };

    const FeeEarnerCell = (props) => {
        return (
            <td className="d-flex justify-content-between">
                <span>{props.dataItem?.displayName}</span>
                {!isView && (
                    <CrossIcon
                        onClick={() => {
                            handleAccesibleUsers(props.dataItem, "remove");
                        }}
                    />
                )}
            </td>
        );
    };

    return (
        <>
            <div
                className="d-flex align-items-center justify-content-between"
                style={{ height: "50px" }}
            >
                <div className={`${styles["section-title"]}`}>Permissions</div>
            </div>
            <hr className="mt-0" />
            <div>
                {!isView && (
                    <div className="d-flex gap-3 align-items-end">
                        <FormComboBox
                            wf={true}
                            isStyled={true}
                            width={"260px"}
                            onChangeComboBox={(e) => {
                                setComboBoxState({
                                    ...comboBoxState,
                                    accessibleUser: e.value,
                                });
                            }}
                            data={prepareFeeearnerData(feeearner?.data)}
                            textField="representation"
                            state={comboBoxState}
                            name={"accessibleUser"}
                            placeholder={"Set Accessible User"}
                            label={"User"}
                        />
                        <Button
                            className="button-small mb-1 bgBtnSecondary"
                            onClick={() => {
                                handleAccesibleUsers(comboBoxState["accessibleUser"], "add");
                            }}
                            type="button"
                        >
                            {" "}
                            Add User{" "}
                        </Button>
                    </div>
                )}
                <Grid
                    // {...dataState}
                    data={accessibleUsers.filter((user) => user.active)}
                    className="mt-2"
                    style={{
                        height: isView ? "220px" : "150px",
                    }}
                >
                    <GridColumn
                        field="displayName"
                        title="Users"
                        headerClassName="ps-3"
                        // width="140px"
                        cell={FeeEarnerCell}
                    />
                </Grid>
            </div>
        </>
    );
};
