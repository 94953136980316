import * as React from "react";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { useDispatch } from "react-redux";
import { ActiveCell, EditCommandCell, SignatureCell } from "../GridComponents";
import { ReferrerLoader } from "./ReferrerLoader";
import { editReferrer } from "actions/referrers.action";
import styles from "../Settings.module.scss";
import AddEditReferrer from "./AddEditReferrer";
import { Checkbox } from "@progress/kendo-react-inputs";

export const ClientCell = (props) => {
  return (
    <td className={`${styles[`checkbox`] + " " + styles[`marginLeft`]}`}>
      <Checkbox
        type={"checkbox"}
        checked={props.dataItem.forClients === true ? true : false}
        readOnly={true}
      />
    </td>
  );
};

export const MatterCell = (props) => {
  return (
    <td className={`${styles[`checkbox`] + " " + styles[`marginLeft`]}`}>
      <Checkbox
        type={"checkbox"}
        checked={props.dataItem.forMatters === true ? true : false}
        readOnly={true}
      />
    </td>
  );
};

const ReferrerList = () => {
  const [openForm, setOpenForm] = React.useState(false);
  const dispatch = useDispatch();
  const [editItem, setEditItem] = React.useState({
    ProductID: 1,
  });
  const enterEdit = (item) => {
    setOpenForm(true);
    setEditItem(item);
  };
  const handleSubmit = (event) => {
    let type = "";
    if (event.forMatters && event.forClients) {
      type = "Both";
    } else if (event.forMatters) {
      type = "Matter";
    } else if (event.forClients) {
      type = "Client";
    } else {
      type = "Both";
    }
    let dataToPost = {
      data: {
        ...event,
        referrerType: type,
      },
    };
    dispatch(editReferrer(event.referrerID, dataToPost));
    setOpenForm(false);
  };
  const [products, setProducts] = React.useState({
    data: [],
    total: 20,
  });
  const [dataState, setDataState] = React.useState({
    take: 10,
    skip: 0,
  });

  const handleCancelEdit = () => {
    setOpenForm(false);
  };
  const MyEditCommandCell = (props) => (
    <EditCommandCell {...props} enterEdit={enterEdit} />
  );
  const dataStateChange = (e) => {
    setDataState(e.dataState);
  };

  const dataReceived = (products) => {
    products?.data?.map((item) => {
      item.symbol = item?.referrerName[0];
      switch (item.referrerType) {
        case "Matter":
          item.forMatters = true;
          item.forClients = false;
          break;
        case "Client":
          item.forMatters = false;
          item.forClients = true;
          break;
        case "Both":
          item.forMatters = true;
          item.forClients = true;
          break;
        default:
          item.forMatters = false;
          item.forClients = false;
          break;
      }
    });
    setProducts(products);
  };

  return (
    <div className="settings-grid container">
      <Grid {...dataState} data={products} onDataStateChange={dataStateChange}>
        <Column field="symbol" title=" " cell={SignatureCell} width="80" />
        <Column field="referrerName" title="Name" />
        <Column field="referrerDetails" title="Details" />
        <Column cell={ActiveCell} title="Active" field="active" width="80" />
        <Column
          cell={ClientCell}
          title="For Clients"
          field="active"
          width="100"
        />
        <Column
          cell={MatterCell}
          title="For Matters"
          field="For Matters"
          width="100"
        />
        <Column title=" " cell={MyEditCommandCell} width="60" />
      </Grid>
      {openForm && (
        <AddEditReferrer
          cancelEdit={handleCancelEdit}
          onSubmit={handleSubmit}
          item={editItem}
        />
      )}

      <ReferrerLoader
        ReferrerLoader
        dataState={dataState}
        onDataReceived={dataReceived}
      />
    </div>
  );
};

export default ReferrerList;
