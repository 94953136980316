import React from "react";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { useDispatch, useSelector } from "react-redux";
import { ACCESS_DENIED } from "types";
import { useEffect } from "react";
import ErrorImage from '../../../assets/maskgroup.png'


export default function AccessDenied() {
    const dispatch = useDispatch()
    const commonState = useSelector((state) => state.common);
    return (
        <Dialog>
            <div className="d-flex flex-column justify-content-around px-5 py-4 align-items-center" style={{ width: "400px", height: "250px" }}>
                <img src={ErrorImage} />
                <p className="text-center">{`Access Denied, ${commonState.accessDenied.message}`}</p>
                <button style={{ width: "103px" }} className="common-btn border-0" onClick={() => dispatch({ type: ACCESS_DENIED, payLoad: { status: false, message: "" } })}>Close</button>
            </div>
        </Dialog>

    );
}
