import {
  ERROR,
  GET_CURRENT_USER,
  UPDATE_USER,
  NO_CONTENT_USER,
  NEWUSER_SUB,
  RESPONSE_ERROR_LOGIN,
  SET_HEADER_PHOTO,
  GET_PROFILE_PHOTO,
  CLEAR_PROFILE_PHOTO,
  INITIAL_LOADING,
} from "../types/index";
import * as api from "../api/index.js";
import { handleApiError } from "Helper";

export const getCurrentUser = () => async (dispatch) => {
  try {
    const datas = await api.getCurrentUser();
    if (datas.status === 204) {
      dispatch({ type: NO_CONTENT_USER });
    } else {
      const datagot = async () => {
        if (datas.data.data.userImageDocumentId !== null) {
          const profilePhoto = await api.getDocument(
            datas.data.data.userImageDocumentId
          );
          datas.data.data.imageInfo = profilePhoto.data.data;
          dispatch({ type: GET_CURRENT_USER, payload: datas.data.data });
        } else {
          datas.data.data.imageInfo = {
            documentFile: {
              downloadURL: "",
            },
          };
          dispatch({ type: GET_CURRENT_USER, payload: datas.data.data });
        }
      };
      datagot();
    }
    dispatch({ type: INITIAL_LOADING, payload: true });
  } catch (error) {
    if (error?.response?.status === 401) {
      dispatch({ type: RESPONSE_ERROR_LOGIN });
    }
    dispatch({ type: ERROR, payload: error });
  }
};

export const getProfilePhoto = (docId) => async (dispatch) => {
  try {
    const profilePhoto = await api.getDocument(docId);
    dispatch({ type: GET_PROFILE_PHOTO, payload: profilePhoto.data.data });
  } catch (error) {
    if (error.response.status === 401) {
      dispatch({ type: RESPONSE_ERROR_LOGIN });
    }
    dispatch({ type: ERROR, payload: error });
  }
};

export const clearProfilePhoto = (docId) => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_PROFILE_PHOTO });
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const setHeaderPhoto = (image) => async (dispatch) => {
  try {
    dispatch({ type: SET_HEADER_PHOTO, payload: image });
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const getUserClaims = () => async (dispatch) => {
  try {
    const datas = await api.getUserClaims();
    localStorage.setItem("url", datas.data.APIURL);
    dispatch({ type: NEWUSER_SUB, payload: datas.data });
  } catch (error) {
    handleApiError(error);
  }
};

export const updateUser = (id, data) => async (dispatch) => {
  try {
    const datas = await api.editUserinfo(id, data);
    dispatch({ type: UPDATE_USER, payload: data });
  } catch (error) {
    handleApiError(error);
  }
};

export const createnewUser = (data, userId) => async (dispatch) => {
  try {
    const datas = await api.createNewUser(data, userId);
  } catch (error) {
    handleApiError(error);
  }
};
