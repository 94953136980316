import {
  BarComponent,
  CardContainer,
  GridComponent,
  Heading,
  VatContainer,
} from "components/VatReturn";
import React from "react";
import styles from "../../components/VatReturn/VatReturn.module.scss";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { PageTabItemTitle } from "components/tabs/TabItem/PageTabItemTitle";
import { useState } from "react";
import VatReturnTab from "./VatReturnTab";
import * as api from "../../api/index";
import { Button } from "@progress/kendo-react-buttons";
import { useEffect } from "react";

const VatDetails = (props) => {
  const [tabs, setTabs] = useState([
    "VAT Return",
    "Transactions by VAT box",
    "Transactions by tax rate",
  ]);
  const [selected, setSelected] = React.useState(0);
  const [calculationData, setCalculationData] = useState({});
  const handleSelect = (e) => {
    setSelected(e.selected);
  };
  const getVatReturnData = async () => {
    try {
      const data = await api.getVatReturnData(
        props.item.fromDate,
        props.item.toDate
      );
      setCalculationData(data?.data?.data?.calculations);
    } catch (error) {}
  };
  useEffect(() => {
    getVatReturnData();
  }, [props.item]);
  return (
    <>
      <CardContainer>
        <div className="container-fluid companytab">
          <TabStrip
            keepTabsMounted={true}
            selected={selected}
            onSelect={handleSelect}
            className="mt-2 pagetab companytab"
          >
            {tabs.map((item, index) => {
              return (
                <TabStripTab
                  title={
                    <PageTabItemTitle
                      className={`mb-sm-4 mb-xl-0`}
                      title={item}
                      index={tabs.indexOf(item)}
                      selected={selected}
                      key={index}
                    />
                  }
                >
                  <div className={`${styles[`company-tabs`]}`}>
                    {item === "VAT Return" && (
                      <VatReturnTab data={calculationData} />
                    )}
                  </div>
                </TabStripTab>
              );
            })}
          </TabStrip>
        </div>
        <div className="row mt-1">
          <div className="col-12">
            <BarComponent />
          </div>
        </div>
        <div className="d-flex mt-1 justify-content-between">
          <div className=" ms-3 mt-2">
            <p>
              <u>Apply Postponed VAT Accounting (PVA) Adjustments</u>
            </p>
          </div>
          <div className="align-items-end">
            <Button
              onClick={() => {
                props.onBack();
              }}
              className="common-btn me-2"
            >
              Back
            </Button>
            <Button className="common-btn me-3">Submit to HMRC</Button>
          </div>
        </div>
      </CardContainer>
    </>
  );
};

export default VatDetails;
