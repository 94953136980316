import * as React from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Input, Checkbox } from "@progress/kendo-react-inputs";
import { Label } from "@progress/kendo-react-labels";

const EditDocType = (props) => {
  const ADD_NEW = props.type === "addNew";
  return (
    <Dialog
      title={ADD_NEW ? "Add Document Type" : `Edit ${props?.item?.typeName}`}
      onClose={props.cancelEdit}
      width={600}
    >
      <div className="p-3 ps-4">
        <Form
          onSubmit={props.onSubmit}
          initialValues={props.item}
          render={(formRenderProps) => (
            <FormElement
              style={{
                maxWidth: 1200,
              }}
            >
              <fieldset className={"k-form-fieldset"}>
                <div className="mb-2">
                  <Label className="label mt-1 mb-2 w-100">Code&nbsp;</Label>
                  <Field
                    name={"code"}
                    component={Input}
                    placeHolder="Enter Code"
                    maxLength={2}
                  />
                </div>
                <div className="mb-3">
                  <Label className="label mt-1 mb-2 w-100">
                    Type Name&nbsp;
                  </Label>
                  <Field
                    name={"typeName"}
                    placeHolder="Enter Name"
                    component={Input}
                  />
                </div>
                <Field
                  defaultChecked={true}
                  name={"active"}
                  component={Checkbox}
                  label={"Active"}
                />
                <button
                  style={{ visibility: "hidden" }}
                  id="app-form"
                  type={"submit"}
                ></button>
              </fieldset>
            </FormElement>
          )}
        />
      </div>
      <div className="dialog-footer">
        <button
          className="bgBtnSecondary common-btn me-2"
          onClick={props.cancelEdit}
        >
          Cancel
        </button>
        <button
          className="common-btn me-3"
          // disabled={!formRenderProps.allowSubmit}
          onClick={() => document.getElementById("app-form").click()}
          type={"submit"}
        >
          {ADD_NEW ? "Save" : "Update"}
        </button>
      </div>
    </Dialog>
  );
};

export default EditDocType;
