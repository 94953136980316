import * as React from "react";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { Button } from "@progress/kendo-react-buttons";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Label } from "@progress/kendo-react-labels";
import { useState } from "react";
import { toast } from "react-toastify";
import SuccessModal from "components/common/Modal/success";

import * as api from "api/index";
import { useDispatch, useSelector } from "react-redux";
import styles from "../Settings.module.scss";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { clearProfilePhoto } from "actions/currentuser.action";
import { Checkbox } from "@progress/kendo-react-inputs";
import { handleApiError } from "Helper";
import EditForm from "./AddEditForm";

const Teams = (props) => {
  const [openForm, setOpenForm] = React.useState(false);
  const [teamsData, setTeamsData] = useState([]);
  const [actualFeeEarners, setActualFeeEarners] = useState([]);
  const [teamMembers, setTeamMemners] = useState([]);
  const [teamsValue, setTeamsValue] = useState();
  const dispatch = useDispatch();
  const [editItem, setEditItem] = React.useState({
    ProductID: 1,
  });

  const [addnewform, setaddnewform] = React.useState(false);
  const [isInActive, setIsInactive] = useState(false);
  const [type, setType] = React.useState("");
  const [modalvalue, setmodalvalue] = React.useState(false);

  const addToTeam = async () => {
    const data = [];
    products.map((item) => {
      if (item.select === true) {
        item.active = true;
        data.push(item);
      }
    });
    try {
      if (data.length === 0) {
        toast.error("Select Fee Earners");
        return;
      }
      await api.addRemoveTeamMembers(data, teamsValue.teamID);
      toast.success("Team Updated ");
      fetchTeamData(teamsValue);
    } catch (error) {
      handleApiError(error);
    }
  };

  const removeFromTeam = async () => {
    const data = [];
    teamMembers.map((item) => {
      if (item.select === true) {
        item.active = false;
        data.push(item);
      }
    });
    try {
      if (data.length === 0) {
        toast.error("Select Fee Earners");
        return;
      }
      await api.addRemoveTeamMembers(data, teamsValue.teamID);
      toast.success("Team Updated ");
      fetchTeamData(teamsValue);
    } catch (error) {
      handleApiError(error);
    }
  };
  const checkInactive = () => {
    if (teamsValue?.active === false) {
      setIsInactive(true);
    } else {
      setIsInactive(false);
    }
  };
  React.useEffect(() => {
    checkInactive();
  }, [teamsValue]);

  const handleSubmit = async (event) => {
    try {
      if (type === "add") {
        if (!event.teamName || !event.branchId ) {
          return;
        }
        await api.createNewTeam(event);
        setmodalvalue(true);
      } else if (type === "edit") {
        if (!event.branchId || !event.teamName) {
          return;
        }
        await api.editTeam(event, event.teamID);
        setmodalvalue(true);
        // setTeamsValue({ teamName: event.teamName, teamID: event.teamID })
        setTeamsValue(event);
      }
    } catch (error) {
      handleApiError(error);
    }
    dispatch(clearProfilePhoto());
    setOpenForm(false);
  };
  const [products, setProducts] = React.useState({
    data: [],
    total: 20,
  });
  const [dataState, setDataState] = React.useState({
    take: 50,
    skip: 0,
  });
  const ActiveCell = (props) => {
    const changeSelection = (e) => {
      let data = products.map((item) => {
        if (item.userID === props.dataItem.userID) {
          item.select = e.value;
        }
        return item;
      });
      setProducts(data);
    };
    return (
      <td className={`${styles[`checkbox`]}`}>
        <Checkbox
          className="ms-2"
          checked={props.dataItem.select === true ? true : false}
          onChange={changeSelection}
        />
      </td>
    );
  };
  const SelectionCell = (props) => {
    const changeSelection = (e) => {
      let data = teamMembers.map((item) => {
        if (item.userRef === props.dataItem.userRef) {
          item.select = e.value;
        }
        return item;
      });
      setTeamMemners(data);
    };
    return (
      <td className={`${styles[`checkbox`]}`}>
        <Checkbox
          className="ms-2"
          checked={props.dataItem.select === true ? true : false}
          onChange={changeSelection}
        />
      </td>
    );
  };
  const fetchTeamData = async (e) => {
    try {
      const data = await api.getTeamsById(e.teamID);
      data?.data?.data.map((item) => {
        return (item.select = false);
      });
      setTeamMemners(data?.data?.data);
      setTimeout(() => {
        let product = [...actualFeeEarners];
        for (var i = data?.data?.data.length - 1; i >= 0; --i) {
          for (var j = product.length - 1; j >= 0; --j) {
            if (data?.data?.data[i].userRef === product[j].userRef) {
              product.splice(j, 1);
            }
          }
        }
        product.map((item) => {
          return (item.select = false);
        });
        setProducts([...product]);
      }, [10]);
    } catch (error) {
      handleApiError(error);
    }
  };
  const onChangeTeam = async (e) => {
    try {
      if (e.value !== undefined && e.value !== null) {
        fetchTeamData(e.value);
        setTeamsValue(e.value);
      } else {
        setTeamsValue(null);
        setTeamMemners([]);
        actualFeeEarners.map((item) => {
          return (item.select = false);
        });
        setProducts(actualFeeEarners);
      }
    } catch (error) {
      handleApiError(error);
    }
  };
  const handleCancelEdit = () => {
    dispatch(clearProfilePhoto());
    setOpenForm(false);
  };
  const feeEarnersData = async () => {
    try {
      const data = await api.getUserandFeeearners();
      data?.data?.data.map((item) => {
        return (item.select = false);
      });
      setProducts(data?.data?.data);
      setActualFeeEarners(data?.data?.data);
    } catch (error) {
      handleApiError(error);
    }
  };
  const dataFetcher = async (e) => {
    try {
      const data = await api.getTeams();
      let dataToPass = data?.data?.data;
      setTeamsData(dataToPass);
    } catch (error) {
      handleApiError(error);
    }
  };
  React.useEffect(() => {
    dataFetcher();
    feeEarnersData();
  }, []);
  const dataStateChange = (e) => {
    setDataState(e.dataState);
  };

  return (
    <>
      {!addnewform ? (
        <div className="w-75 mx-auto">
          <div className="d-flex justify-content-between align-items-start mt-4">
            <div className="d-flex align-items-center">
              <div className="d-flex flex-column">
                <Label className="label mb-2">Select Team</Label>
                <ComboBox
                  style={{
                    width: "350px",
                  }}
                  data={teamsData}
                  iconClassName="wa"
                  textField={"teamName"}
                  value={teamsValue}
                  onChange={(e) => {
                    onChangeTeam(e);
                  }}
                  placeholder={"Search Team"}
                />
                {teamsValue && teamsValue.branchName && (
                  <div
                    className="p-1 my-1 px-3"
                    style={{
                      backgroundColor: "#dcdaff",
                      color: "#262626",
                      fontSize: "12px",
                      borderRadius: "12px",
                    }}
                  >
                    <span className="fw-semibold" style={{ color: "#4843d3" }}>
                      Branch:{" "}
                    </span>
                    <span className="fw-semibold">{teamsValue.branchName}</span>
                  </div>
                )}
              </div>

              <div>
                <Label className="label ms-3 mt-4 pt-1 text-danger">
                  {isInActive && "Inactive"}
                </Label>
              </div>
            </div>
            <div className="pe-4 mt-4">
              <Button
                className={`common-btn me-3`}
                disabled={!teamsValue}
                onClick={(e) => {
                  setEditItem(teamsValue);
                  setType("edit");
                  setOpenForm(true);
                }}
              >
                Edit Team
              </Button>
              <Button
                className={`common-btn`}
                onClick={() => {
                  setType("add");
                  setOpenForm(true);
                }}
              >
                Add Team
              </Button>
            </div>
          </div>
          <div className={`row mt-5 w-100`}>
            <div className="settings-grid users-grid col-5">
              <Grid
                {...dataState}
                data={products}
                style={{ height: "65vh" }}
                sortable={true}
                onDataStateChange={dataStateChange}
              >
                <Column field="userRef" title="User Ref" width={100} />
                <Column field="displayname" title="User Name" />
                <Column
                  field="select"
                  title="Select"
                  cell={ActiveCell}
                  width={80}
                />
              </Grid>
            </div>
            <div className="col-2 d-flex flex-column align-items-center justify-content-center">
              <Button
                className={`common-btn p-2 ms-3 ${styles[`button-1`]}`}
                onClick={addToTeam}
              >
                {">"}
              </Button>
              <Button
                className={`common-btn p-2 ms-3 mt-2 ${styles[`button-1`]}`}
                onClick={removeFromTeam}
              >
                {"<"}
              </Button>
            </div>
            <div className="settings-grid users-grid col-5">
              <Grid
                {...dataState}
                data={teamMembers}
                style={{ height: "65vh" }}
                sortable={true}
                onDataStateChange={dataStateChange}
              >
                <Column field="userRef" title="User Ref" width={100} />
                <Column field="displayname" title="User Name" />
                <Column
                  field="select"
                  title="Select"
                  cell={SelectionCell}
                  width={80}
                />
              </Grid>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
      {modalvalue && (
        <SuccessModal
          onClose={() => {
            dataFetcher();
            setmodalvalue(false);
          }}
          message={type !== "add" ? "Team Updated" : "Team Added"}
        />
      )}
      {openForm && (
        <Dialog
          onClose={handleCancelEdit}
          width={600}
          title={type === "add" ? "Add Team " : "Edit Team"}
        >
          <div className="company">
            <EditForm
              data={teamsData}
              cancelEdit={handleCancelEdit}
              onSubmit={handleSubmit}
              item={editItem}
              type={type}
            />
          </div>
        </Dialog>
      )}
    </>
  );
};

export default Teams;
