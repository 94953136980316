import * as React from "react";
import { Button } from "@progress/kendo-react-buttons";
import styles from "../Settings.module.scss";
import * as api from "../../../api/index";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import ReferrerList from "./ReferrerList";
import AddEditReferrer from "./AddEditReferrer";
import { getReferrers } from "actions/referrers.action";

const Referrers = (props) => {
  const [addnewform, setaddnewform] = React.useState(false);
  const dispatch = useDispatch();
  const closeform = () => {
    setaddnewform(false);
    // props.onCancel();
  };
  React.useEffect(() => {
    if (props.onClose === false) {
      closeform();
    }
  }, [props.onClose]);

  const handleSubmit = async (event) => {
    setaddnewform(false);
    let type = "";
    if (event.forMatters && event.forClients) {
      type = "Both";
    } else if (event.forMatters) {
      type = "Matter";
    } else if (event.forClients) {
      type = "Client";
    } else {
      type = "Both";
    }
    const postingData = {
      data: {
        ...event,
        active: true,
        referrerType: type,
      },
    };
    try {
      await api.addReferrer(postingData);
      toast.success("Referrer Added");
      dispatch(getReferrers());
    } catch (error) {
      toast.error("Error Occured");
    }
  };

  return (
    <React.Fragment>
      <>
        <div className="row">
          <div className="col-lg-10 col-md-8"></div>
          <Button
            className={`common-btn col-lg-1 col-md-4 p-2 mt-5 ${
              styles[`button`]
            }`}
            onClick={() => {
              setaddnewform(true);
            }}
          >
            Add <br></br>
            Referrer
          </Button>
        </div>
        <div className={`row mx-auto mt-3 w-50`}>
          <ReferrerList />
        </div>
      </>
      {addnewform && (
        <AddEditReferrer
          cancelEdit={closeform}
          onSubmit={handleSubmit}
          type="addNew"
        />
      )}
    </React.Fragment>
  );
};

export default Referrers;
