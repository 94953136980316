import * as React from "react";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { Button } from "@progress/kendo-react-buttons";
import { editCurrencyinfo } from "actions/currency.action";
import { useDispatch, useSelector } from "react-redux";

import EditCategory from "./EditWorkType";
import styles from "../Settings.module.scss";
import { ActiveCell, EditCommandCell, SignatureCell } from "../GridComponents";
import { CategoryLoader } from "./WorkTypeLoader";
import { editCategory } from "actions/categories.action";
import { getCategoryByDepartment } from "actions/categories.action";
import { getCategory } from "actions/categories.action";

const CategoriesList = (props) => {
  const [openForm, setOpenForm] = React.useState(false);
  const dispatch = useDispatch();
  const { workTypeList } = useSelector((state) => state.categories);

  const [editItem, setEditItem] = React.useState({
    ProductID: 1,
  });
  const [products, setProducts] = React.useState({
    data: [],
    total: 20,
  });
  const [dataState, setDataState] = React.useState({
    take: 10,
    skip: 0,
  });


  React.useEffect(() => {
    if (props?.departmentID > 0) {
      dispatch(getCategoryByDepartment(props?.departmentID));
    } else if (props?.departmentID === 0 || props?.departmentID === undefined) {
      dispatch(
        getCategory(dataState.skip / dataState.take + 1, dataState.take)
      );
    }
  }, [props?.departmentID]);

  React.useEffect(() => {
    const updatedData =
      workTypeList?.data?.map((item) => {
        return { ...item, symbol: item.name[0] };
      }) || [];

    setProducts({
      data: updatedData,
      total: workTypeList?.total || 0,
    });
  }, [workTypeList]);

  const enterEdit = (item) => {
    setOpenForm(true);
    setEditItem(item);
  };
  const handleSubmit = (event) => {
    const updatedEvent = {
      ...event,
      departmentId: event.departmentId?.departmentID || "",
      appCode: event.appCode?.appCode || "",
      rateCategoryId: event.rateCategoryId?.rateCategoryId || "",
      defaultProfitCost: event.defaultProfitCost?.nominalRef || "",
    };

    dispatch(editCategory(updatedEvent.workTypeID, updatedEvent));
    setOpenForm(false);
  };

  const handleCancelEdit = () => {
    setOpenForm(false);
  };
  const MyEditCommandCell = (props) => (
    <EditCommandCell {...props} enterEdit={enterEdit} />
  );
  const dataStateChange = (e) => {
    setDataState(e.dataState);
  };

  const dataReceived = (products) => {
    products.data.map((item) => {
      return (item.symbol = item.name[0]);
    });
    setProducts(products);
  };

  return (
    <div className="settings-grid">
      <Grid {...dataState} data={products} onDataStateChange={dataStateChange}>
        <Column field="symbol" title=" " cell={SignatureCell} width="80" />
        <Column field="code" title="Code" width="120px" />
        <Column field="name" title="Name" />
        <Column field="department" title="Department" />
        <Column cell={ActiveCell} title="Active" field="active" width="80" />
        <Column title=" " cell={MyEditCommandCell} width="60" />
      </Grid>
      {openForm && (
        <EditCategory
          cancelEdit={handleCancelEdit}
          onSubmit={handleSubmit}
          item={editItem}
        />
      )}
      <CategoryLoader dataState={dataState} onDataReceived={dataReceived} />
    </div>
  );
};

export default CategoriesList;
