import {
  START_LOADING,
  ERROR,
  ADD_FILES,
  REMOVE_FILES,
  CLEAR_FILES,
  UPLOAD_PHOTOS,
  CUURENTPROF_PHOTOS,
  RESPONSE_ERROR_LOGIN,
  REMOVE_UPLOAD_PHOTOS,
  EDIT_PROFILE_PHOTO,
  ADD_FILES_DISBURSEMENT_POSTING,
  REMOVE_FILES_DISBURSEMENT_POSTING,
  CLEAR_FILES_DISBURSEMENT_POSTING,
  ADD_FILES_BILL,
  CLEAR_FILES_BILL,
  REMOVE_FILES_BILL,
  ADD_OFFICE_POSTINGS,
  ADD_CLIENT_POSTINGS,
  REMOVE_FILES_OFFICE_AND_ALLOCATIONS,
  REMOVE_OFFICE_POSTINGS,
  REMOVE_CLIENT_POSTINGS,
  CLEAR_FILES_OFFICE_AND_ALLOCATIONS,
  CLEAR_OFFICE_POSTINGS,
  CLEAR_CLIENT_POSTINGS,
  ADD_FILES_OFFICE_AND_ALLOCATIONS,
  ADD_FILES_CHIT_REQUEST,
  REMOVE_FILES_CHIT_REQUEST,
  CLEAR_FILES_CHIT_REQUEST,
} from "../types/index";

export const addFiles = (component, datacomplete) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    if (component === "All Postings") {
      dispatch({ type: ADD_FILES, payload: datacomplete });
    } else if (component === "Profile") {
      dispatch({
        type: UPLOAD_PHOTOS,
        payload: datacomplete.data.data,
      });
    } else if (component === "Disbursement-Payment") {
      dispatch({
        type: ADD_FILES_DISBURSEMENT_POSTING,
        payload: datacomplete.data.data,
      });
    } else if (component === "Bill") {
      dispatch({
        type: ADD_FILES_BILL,
        payload: datacomplete.data.data,
      });
    } else if (component === "CurrentUser") {
      dispatch({
        type: CUURENTPROF_PHOTOS,
        payload: datacomplete.data.data,
      });
    } else if (component === "EditProfile") {
      dispatch({
        type: EDIT_PROFILE_PHOTO,
        payload: datacomplete.data.data,
      });
    } else if (component === "OfficeCreditandAllocation") {
      dispatch({
        type: ADD_FILES_OFFICE_AND_ALLOCATIONS,
        payload: datacomplete.data.data,
      });
    } else if (component === "OfficePosting") {
      dispatch({
        type: ADD_OFFICE_POSTINGS,
        payload: datacomplete,
      });
    } else if (component === "ClientPosting") {
      dispatch({
        type: ADD_CLIENT_POSTINGS,
        payload: datacomplete,
      });
    } else if (component === "Chit Request") {
      dispatch({
        type: ADD_FILES_CHIT_REQUEST,
        payload: datacomplete.data.data,
      });
    }
  } catch (error) {
    if (error.response.status === 401) {
      dispatch({ type: RESPONSE_ERROR_LOGIN });
    }
    dispatch({ type: ERROR, payload: error });
  }
};

export const reomveFile = (filename) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    dispatch({ type: REMOVE_FILES, payload: filename });
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const clearFiles = () => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_FILES });
    dispatch({ type: REMOVE_UPLOAD_PHOTOS });
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const reomveFileDispPayment = (filename) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    dispatch({ type: REMOVE_FILES_DISBURSEMENT_POSTING, payload: filename });
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const clearFilesDispPayment = () => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_FILES_DISBURSEMENT_POSTING });
    dispatch({ type: REMOVE_UPLOAD_PHOTOS });
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const reomveBillFile = (filename) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    dispatch({ type: REMOVE_FILES_BILL, payload: filename });
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const clearBillFile = () => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_FILES_BILL });
    dispatch({ type: REMOVE_UPLOAD_PHOTOS });
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const removeFile = (filename, component) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    if (component === "OfficeCreditandAllocation") {
      dispatch({
        type: REMOVE_FILES_OFFICE_AND_ALLOCATIONS,
        payload: filename,
      });
    } else if (component === "OfficePosting") {
      dispatch({ type: REMOVE_OFFICE_POSTINGS, payload: filename });
    } else if (component === "ClientPosting") {
      dispatch({ type: REMOVE_CLIENT_POSTINGS, payload: filename });
    } else if (component === "Chit Request") {
      dispatch({ type: REMOVE_FILES_CHIT_REQUEST, payload: filename });
    }
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const clearFile = (component) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    if (component === "OfficeCreditandAllocation") {
      dispatch({
        type: CLEAR_FILES_OFFICE_AND_ALLOCATIONS,
      });
    } else if (component === "OfficePosting") {
      dispatch({ type: CLEAR_OFFICE_POSTINGS });
    } else if (component === "ClientPosting") {
      dispatch({ type: CLEAR_CLIENT_POSTINGS });
    } else if (component === "Chit Request") {
      dispatch({ type: CLEAR_FILES_CHIT_REQUEST });
    }
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};
