import * as React from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Checkbox, Input } from "@progress/kendo-react-inputs";
import { createContactLinks, updateContactLinks } from "api/settingApi";
import { Label } from "@progress/kendo-react-labels";
import { toast } from "react-toastify";

const ContactLinkTypeModal = (props) => {
  const ADD_NEW = props.data.type === 1;
  const add = (formValues) => {
    createContactLinks(formValues)
      .then(({ status, data }) => {
        toast.success(`Contact Category added `);
        if (status === 200) {
          props.onClose();
          props.refreshData();
        } else {
          toast.error(`Error occured`);
        }
      })
      .catch((err) => {
        alert("Failed to add");
      })
      .finally((da) => {});
  };

  const handleSubmit = (formValues) => {
    if (props.data.type === 1) {
      add({
        active: true,
        showRef1: true,
        showRef2: true,
        showRef3: true,
        ...formValues,
      });
    }
    if (props.data.type === 2) {
      const updatedItem = {
        contactCategoryId: props.data.contactCategoryId,
        ...formValues,
      };

      const { contactCategoryId } = updatedItem;
      delete updatedItem.contactCategoryId;
      updateContactLinks(contactCategoryId, updatedItem)
        .then(({ status }) => {
          toast.success(
            `Contact type '${props?.data?.data?.categoryType}' has been updated `
          );
          if (status !== 200) return alert("Failed to update");
          props.onClose();
        })
        .catch((err) => {
          toast.error(`Error occured while updating`);
        });
    }
  };
  return (
    <Dialog
      title={
        ADD_NEW
          ? "Add Contact Category"
          : `Edit ${props?.data?.data?.categoryName}`
      }
      onClose={props.cancelEdit}
      width={600}
    >
      <div className="p-3 ps-5">
        <Form
          initialValues={props?.data?.data}
          onSubmit={handleSubmit}
          render={(formRenderProps) => {
            return (
              <FormElement>
                <fieldset className="k-form-fieldset">
                  <div className="mb-3">
                    <Label className="label mt-1 mb-2 w-100">
                      Category Name&nbsp;
                    </Label>
                    <Field
                      name="categoryName"
                      component={Input}
                      style={{ width: "268px" }}
                      required
                      maxLength={50}
                    />
                  </div>
                  <div className="mb-3">
                    <div>
                      <Label className="label mt-1 mb-2 w-100">
                        Category Details&nbsp;
                      </Label>
                      <Field
                        name="categoryDetails"
                        component={Input}
                        style={{ width: "268px" }}
                        maxLength={50}
                      />
                    </div>
                  </div>
                  <div className="mb-3 d-flex align-items-center gap-5">
                    <div>
                      <Label className="label mt-1 mb-2 w-100">
                        Label for Reference 1&nbsp;
                      </Label>
                      <Field
                        name="labelForRef1"
                        style={{ width: "268px" }}
                        component={Input}
                        maxLength={50}
                      />
                    </div>
                    <div className="mt-4">
                      <Field
                        defaultChecked={true}
                        name="showRef1"
                        component={Checkbox}
                        label="Show Reference 1"
                      />
                    </div>
                  </div>
                  <div className="mb-3 d-flex align-items-center gap-5">
                    <div>
                      <Label className="label mt-1 mb-2 w-100">
                        Label for Reference 2&nbsp;
                      </Label>
                      <Field
                        name="labelForRef2"
                        style={{ width: "268px" }}
                        component={Input}
                        maxLength={50}
                      />
                    </div>
                    <div className="mt-4">
                      <Field
                        defaultChecked={true}
                        name="showRef2"
                        component={Checkbox}
                        label="Show Reference 2"
                      />
                    </div>
                  </div>
                  <div className="mb-3 d-flex align-items-center gap-5">
                    <div>
                      <Label className="label mt-1 mb-2 w-100">
                        Label for Reference 3&nbsp;
                      </Label>
                      <Field
                        name="labelForRef3"
                        style={{ width: "268px" }}
                        component={Input}
                        maxLength={50}
                      />
                    </div>
                    <div className="mt-4">
                      <Field
                        defaultChecked={true}
                        name="showRef3"
                        component={Checkbox}
                        label="Show Reference 3"
                      />
                    </div>
                  </div>
                  <Field
                    defaultChecked={true}
                    name="active"
                    component={Checkbox}
                    label="Active"
                  />
                </fieldset>
                <button
                  style={{ visibility: "hidden" }}
                  id="contact-link-form"
                  type={"submit"}
                ></button>
              </FormElement>
            );
          }}
        />
      </div>
      <div className="dialog-footer">
        <button
          className="bgBtnSecondary common-btn me-2"
          onClick={() => props.onClose()}
        >
          Cancel
        </button>
        <button
          className="common-btn me-3"
          //   disabled={!formRenderProps.allowSubmit}
          onClick={() => document.getElementById("contact-link-form").click()}
          type="submit"
        >
          {props.data.type === 2 ? "Update" : "Add"}
        </button>
      </div>
    </Dialog>
  );
};

export default ContactLinkTypeModal;
