import React, { useState, useCallback, useEffect, useRef } from "react";
import { Button } from "@progress/kendo-react-buttons";
import { Dialog } from "@progress/kendo-react-dialogs";
import DraggableButton from "./DraggableButton";
import QuestionField from "components/UserScreen/QuestionField";
import { DragAndDrop, useDroppable } from "@progress/kendo-react-common";
import { addUserScreen, getWorkFlowInterviews, updateScreen } from "api";
import PropertiesInputField from "./PropertiesInputField";
import { MdCancel } from "react-icons/md";
import { Input } from "@progress/kendo-react-inputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { MdOutlineMenu } from "react-icons/md";
import { CiEdit } from "react-icons/ci";
import { Tile } from "components/UIComponents/Tile";
import { Loader } from "@progress/kendo-react-indicators";

const fieldTypes = [
    "Text",
    "Combobox",
    "Date",
    "Dropdown",
    "MultilineText",
    "Integer",
    "Decimal",
    "Time",
    "DateTime",
    "CheckBox",
    "Radio",
];

const DroppableBox = ({
    onDrop,
    dragData,
    question,
    setQuestion,
    fieldIndex,
    onClick,
    selectedFieldIndex,
}) => {
    const element = React.useRef(null);
    const [isInside, setIsInside] = useState(false);
    const [currentFieldType, setCurrentFieldType] = useState(null);

    useEffect(() => {
        setCurrentFieldType(dragData);
    }, [dragData, question]);

    const handleDragEnter = useCallback(() => {
        setIsInside(true);
    }, []);

    const handleDragLeave = useCallback(() => {
        setIsInside(false);
    }, []);

    useDroppable(element, {
        onDragEnter: handleDragEnter,
        onDragLeave: handleDragLeave,
        onDrop: onDrop,
    });

    const handleRemove = () => {
        const newQuestion = [...question];
        newQuestion[0]?.pages[0]?.userScreenFields.splice(fieldIndex, 1);
        setQuestion(newQuestion);
    };

    return (
        <div
            style={{
                borderBottom: "1px solid lightgray",
                borderLeft: selectedFieldIndex === fieldIndex && "4px solid #162EFF",
                backgroundColor:
                    selectedFieldIndex === fieldIndex ? "#F0F1FB" : "white",
                opacity: selectedFieldIndex === fieldIndex ? "0.8" : "1",
            }}
            className={`d-flex justify-content-center align-items-center p-2`}
            onClick={onClick}
        >
            <div style={{ width: "40px" }}>
                {selectedFieldIndex === fieldIndex && <MdOutlineMenu size={25} />}
            </div>

            <div className="px-1 w-100">
                {currentFieldType
                    ? question[0]?.pages[0]?.userScreenFields[fieldIndex] && (
                        <QuestionField
                            key={fieldIndex}
                            question={question[0]}
                            field={{
                                ...question[0]?.pages[0]?.userScreenFields[fieldIndex],
                                dataType: currentFieldType,
                            }}
                            userScreenFieldId={
                                question[0]?.pages[0]?.userScreenFields[fieldIndex]
                                    ?.userScreenFieldId
                            }
                            isSubmitted={false}
                            fieldIndex={fieldIndex}
                            fromScreenDesigner={true}
                        />
                    )
                    : " "}
            </div>

            <div className="p-2 cursor-pointer">
                <MdCancel color="red" size={25} onClick={handleRemove} />
            </div>
        </div>
    );
};

const DroppableContainer = ({ children, onDrop }) => {
    const element = useRef(null);

    useDroppable(element, {
        onDrop,
    });

    return (
        <div
            className="h-100"
            ref={element}
            style={{
                borderRadius: "10px",
                boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.15)",
            }}
        >
            {children}
        </div>
    );
};

const DraggableListItem = ({ data, onDragStart, onDrop, children }) => {
    const handleDragStart = (e) => {
        e.dataTransfer.effectAllowed = "move";
        onDragStart();
    };

    const handleDrop = (e) => {
        e.preventDefault();
        onDrop();
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        e.dataTransfer.dropEffect = "move";
    };

    return (
        <div
            className="cursor-pointer"
            draggable
            onDragStart={handleDragStart}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
        >
            {children}
        </div>
    );
};

const ScreenDesigner = (props) => {
    const DEFAULT_FIELD_PROPERTIES = {
        userScreenFieldId: 0,
        label: "",
        variable: "",
        hint: "",
        length: 0,
        required: false,
        defaultValue: "",
        dataType: "",
        labelAboveField: true,
        gridWidth: 0,
        gridOffset: 0,
        active: true,
        minValue: "",
        maxValue: "",
        minLength: "",
        maxLength: "",
        symbol: "",
        helpText: "",
        choices: "",
        showLogic: "",
        choicesType: "",
    };

    const [question, setQuestion] = useState([
        {
            name: "",
            details: "",
            active: true,
            pages: [
                {
                    heading: "",
                    subQuestion: "",
                    // labelAboveField: true,
                    order: 0,
                    active: true,
                    userScreenFields: [],
                },
            ],
        },
    ]);
    const [isLoading, setIsLoading] = useState(props.screenId ? true : false);
    const [dragData, setDragData] = useState(null);
    const [workFlowView, setWorFlowView] = useState({});
    const [selectedFieldIndex, setSelectedFieldIndex] = useState(null);
    const currentField =
        question[0]?.pages[0]?.userScreenFields[selectedFieldIndex];

    const properties = [
        { label: "Label", property: "label" },
        { label: "Hint", property: "hint" },
        { label: "Variable", property: "variable", type: "number" },
        { label: "Default Value", property: "defaultValue" },
        { label: "Grid Width", property: "gridWidth", type: "number" },
        { label: "Grid Offset", property: "gridOffset", type: "number" },
        { label: "Length (px)", property: "length", type: "number" },
        { label: "Help Text", property: "helpText" },
    ];

    const booleanComboBox = [
        { label: "Label Above Field", property: "labelAboveField" },
        { label: "Required", property: "required" },
    ];

    const [dragged, setDragged] = useState(false);
    const [drag, setDrag] = useState(null);

    const dragInstance = useRef({});
    const handleDragStart = useCallback(
        (index) => {
            setDragged(true);
            setDrag({ ...dragInstance.current, index });
            setSelectedFieldIndex(index);
        },
        [setDrag]
    );

    const handleFieldDrop = (droppedIndex) => {
        if (!drag) {
            return;
        }
        const newFields = [...question[0].pages[0].userScreenFields];
        const draggedField = newFields.splice(drag?.index, 1)[0];
        newFields.splice(droppedIndex, 0, draggedField);

        // Update the order property of each field
        newFields.forEach((field, index) => {
            field.order = index + 1;
        });

        setQuestion((prevQuestion) => {
            const updatedQuestion = [...prevQuestion];
            updatedQuestion[0].pages[0].userScreenFields = newFields;
            return updatedQuestion;
        });
        setSelectedFieldIndex(droppedIndex);
    };

    useEffect(() => {
        if (
            question[0]?.pages[0]?.userScreenFields?.length - 1 <
            selectedFieldIndex
        ) {
            setSelectedFieldIndex(0);
        }
    }, [question]);

    useEffect(() => {
        const getViewWorkFlows = async () => {
            try {
                const response = await getWorkFlowInterviews(props.screenId);
                if (response?.data?.data) {
                    if (!response?.data?.data?.pages?.length) {
                        response.data?.data?.pages?.push({
                            heading: "",
                            subQuestion: "",
                            order: 0,
                            active: true,
                            userScreenFields: [],
                        });
                    }
                    setQuestion([response?.data?.data]);
                }
            } catch (error) {
            } finally {
                setIsLoading(false);
            }
        };
        if (props.screenId) getViewWorkFlows();
    }, []);

    const handleDrop = useCallback(
        (e) => {
            if (dragData) {
                setQuestion((prevQuestion) => {
                    const newQuestion = JSON.parse(JSON.stringify(prevQuestion));
                    if (dragData) {
                        newQuestion[0]?.pages[0]?.userScreenFields?.push({
                            ...DEFAULT_FIELD_PROPERTIES,
                            dataType: dragData,
                        });
                    }
                    setSelectedFieldIndex(
                        newQuestion[0]?.pages[0]?.userScreenFields.length - 1
                    );
                    return newQuestion;
                });
            }
        },
        [dragData]
    );

    const handleInputChange = (property) => (e) => {
        let value = e.target.value;
        if (selectedFieldIndex === null) return;
        setQuestion((prevQuestion) => {
            const newQuestion = JSON.parse(JSON.stringify(prevQuestion));
            newQuestion[0].pages[0].userScreenFields[selectedFieldIndex][property] =
                value;
            return newQuestion;
        });
    };

    const handleSave = async () => {
        try {
            if (props.screenId) {
                await updateScreen(question[0], props.screenId);
            } else {
                await addUserScreen({
                    ...question[0],
                    type: props.screenType,
                });
            }

            props.onClose && props.onClose(true, question[0]);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <Dialog
            className="dialog-xxl"
            title="Screen Designer"
            onClose={props.onClose}
        >
            {" "}
            {isLoading ? (
                <div className="d-flex align-items-center justify-content-center mt-5">
                    <Loader themeColor={"warning"} size={"large"} type={"pulsing"} />
                </div>
            ) : (
                <div id="main" className="h-100">
                    <div
                        id="designer"
                        className="row m-0 p-0 overflow-auto mh-100"
                        style={{ height: "calc(100% - 65px)" }}
                    >
                        <DragAndDrop>
                            <div
                                id="fields"
                                className="col-12 col-xl-2 p-0 mh-100 overflow-hidden"
                            >
                                <div className="h-100 p-3 pe-1">
                                    <Tile className="px-3 overflow-auto  h-100" heading="Fields">
                                        {fieldTypes.map((fieldType, index) => (
                                            <div key={index} className="mb-2">
                                                <DraggableButton
                                                    fieldType={fieldType}
                                                    setDragData={setDragData}
                                                />
                                            </div>
                                        ))}
                                    </Tile>
                                </div>
                            </div>

                            <div id="screen" className="col-8 col-xl-7 p-0 mh-100">
                                <div className="h-100 px-1 py-3 mh-100">
                                    <DroppableContainer
                                        onDrop={(e) => handleDrop(e)}
                                        className="h-100"
                                    >
                                        <div className="h-100 p-2 overflow-auto mh-100 pb-5">
                                            <div id="heading" className="px-5">
                                                <CiEdit size={25} />
                                                <Input
                                                    className="ps-3 fw-bold fs-md border-0"
                                                    style={{ minWidth: "calc(100% - 25px)" }}
                                                    onChange={(e) => {
                                                        setQuestion((prevQuestion) => {
                                                            const newQuestion = JSON.parse(
                                                                JSON.stringify(prevQuestion)
                                                            );
                                                            newQuestion[0].name = e.target.value;
                                                            return newQuestion;
                                                        });
                                                    }}
                                                    placeholder="Module Name"
                                                    value={question[0].name}
                                                />
                                                <div
                                                    style={{
                                                        borderBottom: "1px solid #d3d3d3",
                                                        marginBottom: "10px",
                                                    }}
                                                />
                                            </div>

                                            <div id="subheading" className="px-5">
                                                <CiEdit size={20} />
                                                <Input
                                                    className="ps-3 fw-bold border-0"
                                                    style={{ minWidth: "calc(100% - 25px)" }}
                                                    onChange={(e) => {
                                                        setQuestion((prevQuestion) => {
                                                            const newQuestion = JSON.parse(
                                                                JSON.stringify(prevQuestion)
                                                            );
                                                            if (newQuestion[0]?.pages[0])
                                                                newQuestion[0].pages[0].heading =
                                                                    e.target.value;
                                                            return newQuestion;
                                                        });
                                                    }}
                                                    value={question[0]?.pages[0]?.heading}
                                                    placeholder="Question Text"
                                                />

                                                <div
                                                    className="mb-3"
                                                    style={{
                                                        borderBottom: "1px solid #d3d3d3",
                                                        marginBottom: "10px",
                                                    }}
                                                />
                                            </div>

                                            {question[0]?.pages[0]?.userScreenFields.map(
                                                (dropZone, index) => (
                                                    <DraggableListItem
                                                        key={index}
                                                        data={{ id: dropZone.id, type: "field" }}
                                                        onDragStart={() => handleDragStart(index)}
                                                        onDrop={() => handleFieldDrop(index)}
                                                    >
                                                        <DroppableBox
                                                            fieldIndex={index}
                                                            dragData={dropZone.dataType ?? ""}
                                                            question={question}
                                                            setQuestion={setQuestion}
                                                            onClick={() => setSelectedFieldIndex(index)}
                                                            selectedFieldIndex={selectedFieldIndex}
                                                        />
                                                    </DraggableListItem>
                                                )
                                            )}
                                        </div>
                                    </DroppableContainer>
                                </div>
                            </div>
                        </DragAndDrop>

                        <div
                            id="properties"
                            className="col-4 col-xl-3 p-0 mh-100"
                            style={{ zIndex: 3 }}
                        >
                            <div className="h-100 p-3 ps-1">
                                <Tile
                                    heading="Text Box Properties"
                                    className="overflow-auto h-100"
                                    headingClass="px-4"
                                >
                                    {currentField && (
                                        <div className="px-3">
                                            {properties.map(({ label, property, type }) => (
                                                <PropertiesInputField
                                                    key={property}
                                                    label={label}
                                                    type={type}
                                                    onChange={handleInputChange(property)}
                                                    value={currentField?.[property] ?? ""}
                                                />
                                            ))}

                                            {booleanComboBox.map(({ label, property }) => (
                                                <div
                                                    className="d-flex w-100 align-items-center pb-2"
                                                    key={property}
                                                >
                                                    <label className="w-50 fw-semibold fs-sm pe-1">
                                                        {label}
                                                    </label>
                                                    <DropDownList
                                                        data={[true, false]}
                                                        onChange={handleInputChange(property)}
                                                        value={
                                                            currentField?.[property] === true
                                                                ? "True"
                                                                : currentField?.[property] === false
                                                                    ? "False"
                                                                    : ""
                                                        }
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </Tile>
                            </div>
                        </div>
                    </div>

                    <div id="footer" className="row dialog-footer m-0">
                        <div className="d-flex justify-content-end">
                            <Button
                                className="common-btn p-3 bgBtnPrimary align-self-end border-0 me-3"
                                onClick={handleSave}
                            >
                                Save
                            </Button>
                        </div>
                    </div>
                </div>
            )}
        </Dialog>
    );
};
export default ScreenDesigner;
