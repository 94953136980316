import React from "react";
import { Label } from "@progress/kendo-react-labels";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Form, Field, FormElement } from "@progress/kendo-react-form";

import * as api from "../../api/index";
import { useEffect } from "react";
import { useState } from "react";
import { handleApiError } from "Helper";

export default function Branch({
  component,
  disabled = false,
  width = "255px",
  height = "44px",
  valueSetter,
  controlled = false,
  name,
  className,
  onChange,
  value,
  style,
  labelClass,
  bacgroundColor,
}) {
  const [branchList, setBranchList] = useState([]);

  const fetchBranchesNames = async () => {
    try {
      const { data } = await api.getBranchesnames();
      const result = data.data.map((branch) => {
        branch.representation = `${branch.branchCode} - ${branch.branchName}`;
        return branch;
      });
      if (component === "AddClients" && data.data.length === 1) {
        valueSetter(result[0]);
      }
      setBranchList(result);
    } catch (error) {
      handleApiError(error);
    }
  };
  useEffect(() => {
    fetchBranchesNames();
  }, []);

  if (component === "AddClients" && branchList.length === 1) return <></>;

  return (
    <div className={`${className} d-flex flex-column`} style={style}>
      <Label className={`label  ${labelClass}`}>Branch</Label>
      {controlled ? (
        <ComboBox
          style={{
            width: width,
            height: height,
            backgroundColor: bacgroundColor,
          }}
          className="ps-2"
          data={branchList}
          textField="representation"
          placeholder="Select Branch"
          dataItemKey="branchId"
          iconClassName="wa"
          value={value}
          onChange={(e) => onChange(e.value)}
          component={ComboBox}
        />
      ) : (
        <Field
          disabled={disabled}
          style={{ width: width, height: "44px" }}
          data={branchList}
          name={name}
          textField="representation"
          placeholder="Select Branch"
          dataItemKey="branchId"
          iconClassName="wa"
          component={ComboBox}
        />
      )}
    </div>
  );
}
