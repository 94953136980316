import React from "react";
import styles from "./EmailViewer.module.scss";
import { getIcon } from "pages/Postings/DcoumentsUpload";
import fileIcon from "../fileIcon";

const EmailViewer = ({ emailInfo = {} }) => {
  const {
    subject = {},
    fromRecipient,
    toRecipients = [],
    ccRecipients = [],
    bccRecipients = [],
    date,
    attachments = [],
    isHtmlBody,
    body,
    htmlBody,
  } = emailInfo;

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleString();
  };

  const getInitial = (name) => {
    return name ? name.charAt(0).toUpperCase() : "?";
  };

  return (
    <div className={`card shadow-sm w-100 ${styles.emailViewerCard}`}>
      <div className={`fs-md card-header ${styles.emailViewerHeader} ${styles.stickyHeader}`}>
        <strong>{subject}</strong>
      </div>

      <div className={`card-header ${styles.emailViewerHeader} ${styles.stickyHeader}`}>
        <div className={styles.avatar}>{getInitial(fromRecipient?.name)}</div>

        <div className={`flex-grow-1 ${styles.emailViewerMeta}`}>
          <div className="mb-2">
            {fromRecipient?.name} &lt;{fromRecipient?.emailAddress}&gt;
          </div>

          <div className="text-muted">
            To:{" "}
            {toRecipients?.map((recipient, index) => (
              <span key={index}>
                {recipient.name} &lt;{recipient.emailAddress}&gt;
                {index < toRecipients.length - 1 && ", "}
              </span>
            ))}
          </div>

          {ccRecipients?.length > 0 && (
            <div className="text-muted">
              CC:{" "}
              {ccRecipients?.map((recipient, index) => (
                <span key={index}>
                  {recipient.name} &lt;{recipient.emailAddress}&gt;
                  {index < ccRecipients.length - 1 && ", "}
                </span>
              ))}
            </div>
          )}

          {bccRecipients?.length > 0 && (
            <div className="text-muted">
              BCC:{" "}
              {bccRecipients?.map((recipient, index) => (
                <span key={index}>
                  {recipient.name} &lt;{recipient.emailAddress}&gt;
                  {index < bccRecipients.length - 1 && ", "}
                </span>
              ))}
            </div>
          )}
        </div>

        <div className={`ml-auto text-muted ${styles.emailViewerDate}`}>
          {formatDate(date)}
        </div>
      </div>

      {!!attachments.length && (
        <div className={`px-3 mt-3 ${styles.stickyAttachments}`}>
          <div className={`ps-3 ${styles.emailViewerAttachments}`}>
            {attachments?.map((attachment, index) => (
              <div className={`d-flex align-items-center ${styles.attachmentItem}`} key={index}>
                {fileIcon(attachment?.name)}
                <span className="ps-1 text-truncate">{attachment.name}</span>
              </div>
            ))}
          </div>
        </div>
      )}

      <div className={`px-3 card-body ${styles.emailViewerBody}`}>
        {isHtmlBody ? (
          <div id="htmlbody" className="h-100">
            <iframe
              title="Email Content"
              sandbox="allow-same-origin allow-popups"
              pointerEvents="none"
              srcDoc={htmlBody}
              className="w-100 h-100 border-0 p-1"
            />
          </div>
        ) : (
          <p>{body}</p>
        )}
      </div>
    </div>
  );
};

export default EmailViewer;
