import * as React from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Input, Checkbox } from "@progress/kendo-react-inputs";
import { useState } from "react";
import { useSelector } from "react-redux";
import { MultiSelect } from "@progress/kendo-react-dropdowns";
import { Label } from "@progress/kendo-react-labels";

const MyCustomMultiCheck = (fieldRenderProps) => {
  const {
    // The meta props of the Field.
    validationMessage,
    touched,
    visited,
    modified,
    valid,
    // The input props of the Field.
    value,
    onChange,
    onFocus,
    onBlur,
    // The custom props that you passed to the Field.
    ...others
  } = fieldRenderProps;
  const [appsValue, setAppsValue] = useState(value);
  const allowedAppsList = useSelector((state) => {
    let array = [];
    let list = state.applications?.activeApplicationsList?.data.map((item) => {
      array.push(item.appCode);
    });
    return array;
  });
  const onValueChange = React.useCallback(
    (e) => {
      setAppsValue(e.value);
      onChange({
        value: e.value,
      });
    },
    [onChange, value]
  );
  return (
    <div onFocus={onFocus} onBlur={onBlur} className="d-flex flex-column">
      <Label className="label mt-1 mb-2 w-100">Allowed Apps&nbsp;</Label>
      <MultiSelect
        onChange={onValueChange}
        data={allowedAppsList}
        value={value}
        id={others.id}
        style={{ padding: "0px", height: "50px" }}
      />
      {/* <label className={"k-checkbox-label"} htmlFor={others.id}>
        {others.label}
      </label> */}
    </div>
  );
};

const EditFixedFeeHeader = (props) => {
  const ADD_NEW = props.type === "addNew";
  return (
    <Dialog
      title={ADD_NEW ? "Add Template" : `Edit ${props?.item?.name}`}
      onClose={props.cancelEdit}
      width={600}
    >
      <div className="p-3 ps-4">
        <Form
          onSubmit={props.onSubmit}
          initialValues={props.item}
          render={(formRenderProps) => (
            <FormElement
              style={{
                maxWidth: 1200,
              }}
            >
              <fieldset className={"k-form-fieldset"}>
                <div className="mb-2">
                  <Label className="label mt-1 mb-2 w-100">Name&nbsp;</Label>
                  <Field name={"name"} component={Input} />
                </div>
                <div className="mb-2">
                  <Label className="label mt-1 mb-2 w-100">Details&nbsp;</Label>
                  <Field name={"details"} component={Input} />
                </div>
                <div className="mb-3">
                  <Field name={"allowedApps"} component={MyCustomMultiCheck} />
                </div>
                <Field
                  defaultChecked={true}
                  name={"active"}
                  component={Checkbox}
                  label={"Active"}
                />
              </fieldset>
              <button
                style={{ visibility: "hidden" }}
                id="fee-header-form"
                type={"submit"}
              ></button>
            </FormElement>
          )}
        />
      </div>
      <div className="dialog-footer">
        <button
          className="bgBtnSecondary common-btn me-2"
          onClick={props.cancelEdit}
        >
          Cancel
        </button>
        <button
          className="common-btn me-3"
          onClick={() => document.getElementById("fee-header-form").click()}
          type={"submit"}
        >
          {ADD_NEW ? "Save" : "Update"}
        </button>
      </div>
    </Dialog>
  );
};

export default EditFixedFeeHeader;
