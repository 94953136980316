import React, { useEffect, useState } from "react";
import styles from "./TransactionPeriod.module.scss";
import { getCurrentSessionTransactionPeriod } from "api";
import { useSelector } from "react-redux";
import { formatDate } from "@progress/kendo-intl";

export const TransactionPeriod = () => {
  let locale = useSelector((state) => state?.company?.company?.locale);
  const [currentTransactionPeriod, setCurrentTransactionPeriod] = useState();

  const getTransactionPeriodText = (currentTransactionPeriod) => {
    if (!currentTransactionPeriod) {
      return {
        color: "red",
        text: "Invalid Transaction Period",
      };
    }
    if (!currentTransactionPeriod?.closed) {
      return {
        color: "blue",
        text: dateReturner(),
      };
    } else if (currentTransactionPeriod?.closed) {
      return {
        color: "red",
        text: `${dateReturner()} Closed`,
      };
    }
  };
  const getCurrentTransactionPeriod = async () => {
    try {
      const data = await getCurrentSessionTransactionPeriod(
        JSON.parse(sessionStorage.getItem("sessionPeriod"))?.period
      );
      setCurrentTransactionPeriod(data.data?.data);
    } catch (error) {}
  };

  const dateReturner = () => {
    return `${currentTransactionPeriod?.period} ( ${formatDate(
      new Date(currentTransactionPeriod?.startDate),
      "d",
      locale
    )} - ${formatDate(
      new Date(currentTransactionPeriod?.endDate),
      "d",
      locale
    )} )`;
  };

  useEffect(() => {
    getCurrentTransactionPeriod();
  }, []);

  return (
    <div
      className={`${styles["container"]} d-flex align-items-center justify-content-end pe-4`}
      style={{
        color: getTransactionPeriodText(currentTransactionPeriod).color,
      }}
    >
      <div>
        {" "}
        Transaction Period:{" "}
        {getTransactionPeriodText(currentTransactionPeriod).text}
      </div>
    </div>
  );
};
