import { load } from "@progress/kendo-react-intl";
import { useSelector } from "react-redux";
import enDEcaGregorian from "cldr-dates-full/main/en-DE/ca-gregorian.json";
import enDEDateFields from "cldr-dates-full/main/en-DE/dateFields.json";
import enDENumbers from "cldr-numbers-full/main/en-DE/numbers.json";

import enGBcaGregorian from "cldr-dates-full/main/en-GB/ca-gregorian.json";
import enGBDateFields from "cldr-dates-full/main/en-GB/dateFields.json";
import enGBNumbers from "cldr-numbers-full/main/en-GB/numbers.json";
import weekData from "cldr-core/supplemental/weekData.json";


export const LoadLocaleData = (locale) => {
  switch (locale) {
    case "en-DE":
      load(enDEcaGregorian, enDEDateFields, enDENumbers);
      break;
    case "en-GB":
      load(enGBcaGregorian, enGBDateFields, enGBNumbers, weekData);
  }
};
