import React from "react";
import styles from "./index.module.scss";
import {
  ContainerCard,
  PrimaryBadgeComponent,
} from "components/MatterDetails/ContactCard";
import { Tile } from "components/UIComponents/Tile";
import { formatDate } from "@progress/kendo-intl";
import DataList from "components/MatterDetails/DataList";
import { AmountFormat, DateFormatCell } from "components/common/formatter";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { Checkbox } from "@progress/kendo-react-inputs";

export const ContactInformationView = ({ data = {}, locale = "en-GB" }) => {
  return (
    <>
      <div className="container pt-4">
        <div className="row g-2 ">
          <div className="col-xl-6 col-12 ">
            <Tile
              heading="Client"
              headingItems={
                <PrimaryBadgeComponent
                  text={"Person"}
                  className="mt-3 ps-3 pe-3"
                />
              }
              height={"300px"}
              className="px-4 py-2  overflow-auto"
            >
              <div className="">
                <div>
                  <DataList
                    text={"Reference:"}
                    value={data?.clientRef}
                    className={"w-50 p-0"}
                    keyClass={"mb-2"}
                    valueClass={"mb-2"}
                    keyStyles={{ minWidth: "80px" }}
                  />
                </div>
                <div className=" d-flex  justify-content-start justify-content-xl-between ">
                  <div className="w-50 ">
                    <DataList
                      text={"Name:"}
                      value={data?.clientName}
                      valueClass={"mb-2"}
                      keyClass={"mb-2"}
                      width={"w-100"}
                      className={"w-100 mt-1"}
                      keyStyles={{ minWidth: "80px" }}
                    />
                    <DataList
                      text={"Email:"}
                      value={data.email1}
                      keyClass={"mb-2"}
                      valueClass={"mb-2"}
                      width={"w-100"}
                      className={"mt-4"}
                      keyStyles={{ minWidth: "80px" }}
                    />
                    <DataList
                      text={"Address:"}
                      className={"mt-1"}
                      width={"w-100"}
                      keyStyles={{ minWidth: "80px" }}
                      value={
                        <>
                          {data?.line1 && (
                            <div>
                              {data?.line1} <br className="mt-1" />
                            </div>
                          )}
                          {data?.town && (
                            <div className="mt-1">
                              {data?.town} <br className="mt-1" />
                            </div>
                          )}{" "}
                          {data?.state && (
                            <div className="mt-1">
                              {data?.state} <br className="mt-1" />
                            </div>
                          )}{" "}
                          {data?.country && (
                            <div className="mt-1">
                              {data?.country} <br className="mt-1" />
                            </div>
                          )}
                        </>
                      }
                      keyClass={"mb-2"}
                      valueClass={"mb-2"}
                    />
                  </div>
                  <div className="ms-4">
                    <DataList
                      text={"Branch:"}
                      valueClass={"mb-2"}
                      keyStyles={{ minWidth: "90px" }}
                      keyClass={"mb-2"}
                      value={data?.branch?.branchName}
                      className={"w-100 mt-1"}
                    />
                    <DataList
                      text={"Phone 1:"}
                      value={data.phone1}
                      keyClass={"mb-2"}
                      valueClass={"mb-2"}
                      className={"mt-4"}
                      keyStyles={{ minWidth: "90px" }}
                    />
                    <DataList
                      text={"Phone 2:"}
                      value={data.phone2}
                      keyClass={"mb-2"}
                      className={"mt-1"}
                      valueClass={"mb-2"}
                      keyStyles={{ minWidth: "90px" }}
                    />
                    <DataList
                      text={"Mobile:"}
                      value={data.mobileNo}
                      keyClass={"mb-2"}
                      className={"mt-1"}
                      keyStyles={{ minWidth: "90px" }}
                      valueClass={"mb-2"}
                    />
                    <DataList
                      text={"Website:"}
                      value={data.website}
                      keyClass={"mb-2"}
                      className={"mt-1"}
                      keyStyles={{ minWidth: "90px" }}
                      valueClass={"mb-2"}
                    />
                  </div>
                </div>
              </div>
            </Tile>
          </div>
          <div className="col-xl-6 col-12 ">
            <Tile
              height={"300px"}
              heading="Personal Details"
              className="px-4 py-2 overflow-auto"
            >
              <div className=" d-flex  justify-content-start justify-content-xl-between ">
                <div className="w-50">
                  <DataList
                    text={"Gender:"}
                    value={data.gender}
                    keyStyles={{ minWidth: "120px" }}
                  />
                  <DataList
                    text={"Date Of Birth:"}
                    value={
                      data.dateOfBirth &&
                      formatDate(new Date(data.dateOfBirth), "d", locale)
                    }
                    keyStyles={{ minWidth: "120px" }}
                  />
                  <DataList
                    text={"NI Number:"}
                    value={data.niNumber}
                    keyStyles={{ minWidth: "120px" }}
                  />
                  <DataList
                    text={"Name at Birth:"}
                    value={data.nameAtBirth}
                    keyStyles={{ minWidth: "120px" }}
                  />
                  <DataList
                    text={"Place of Birth:"}
                    value={data.placeOfBirth}
                    keyStyles={{ minWidth: "120px" }}
                  />
                </div>
                <div className="ms-4">
                  <DataList
                    text={"Ethnicity:"}
                    value={data.ethnicity}
                    keyStyles={{ minWidth: "100px" }}
                  />
                  <DataList
                    text={"Disablity:"}
                    value={data.disablility}
                    keyStyles={{ minWidth: "100px" }}
                  />
                  <DataList
                    text={"Date of Death:"}
                    keyStyles={{ minWidth: "100px" }}
                    value={
                      data.dateOfDeath &&
                      formatDate(new Date(data.dateOfDeath), "d", locale)
                    }
                  />
                </div>
              </div>
            </Tile>
          </div>
        </div>

        <div className="row mt-1 g-2 pb-3 ">
          <div className="col">
            <PersonMattersList data={data?.matters} />
          </div>
        </div>
      </div>
    </>
  );
};

export const StatementInformationView = ({ data = {} }) => {
  return (
    <div className="container pt-4 pb-3">
      <div className="row g-2">
        <div className="col-12 col-xl-6">
          <Tile
            height={"300px"}
            heading="Statement Contact"
            className="px-4 py-2 overflow-auto"
          >
            <div>
              <div className="d-flex  justify-content-start justify-content-xl-between ">
                <div className="w-50">
                  <DataList
                    text={"Name:"}
                    value={data.statementName}
                    keyClass={"mb-2"}
                    keyStyles={{ minWidth: "80px" }}
                    valueClass={"mb-2"}
                  />
                  <DataList
                    text={"Email:"}
                    value={data.statementEmail}
                    keyClass={"mb-2"}
                    valueClass={"mb-2"}
                    className={"mt-4"}
                    keyStyles={{ minWidth: "80px" }}
                  />
                  <DataList
                    text={"Address:"}
                    className={"mt-1"}
                    alignItems="align-items-start"
                    keyStyles={{ minWidth: "80px" }}
                    value={
                      <>
                        {data?.siline1 && (
                          <div>
                            {data?.siline1} <br className="mt-1" />
                          </div>
                        )}
                        {data?.sitown && (
                          <div className="mt-1">
                            {data?.sitown} <br className="mt-1" />
                          </div>
                        )}{" "}
                        {data?.sistate && (
                          <div className="mt-1">
                            {data?.sistate} <br className="mt-1" />
                          </div>
                        )}{" "}
                        {data?.sicountry && (
                          <div className="mt-1">
                            {data?.sicountry} <br className="mt-1" />
                          </div>
                        )}
                      </>
                    }
                    keyClass={"mb-2"}
                    valueClass={"mb-2"}
                  />
                </div>
                <div className="ms-4">
                  <DataList
                    text={"Phone:"}
                    keyStyles={{ minWidth: "80px" }}
                    value={data.statementPhone}
                    keyClass={"mb-2"}
                    valueClass={"mb-2"}
                    className={"mt-5"}
                  />
                </div>
              </div>
            </div>
          </Tile>
        </div>
        <div className="col-12 col-xl-6">
          <Tile
            heading="Billing Contact"
            height={"300px"}
            className="p-3 ps-4 pe-4 pt-2 pb-0 overflow-auto"
          >
            <div>
              <div className="d-flex  justify-content-start justify-content-xl-between ">
                <div className="w-50">
                  <DataList
                    text={"Name:"}
                    value={data.billingName}
                    keyClass={"mb-2"}
                    keyStyles={{ minWidth: "80px" }}
                    valueClass={"mb-2"}
                  />
                  <DataList
                    text={"Email:"}
                    value={data.billingEmail}
                    keyClass={"mb-2"}
                    valueClass={"mb-2"}
                    className={"mt-4"}
                    keyStyles={{ minWidth: "80px" }}
                  />
                  <DataList
                    text={"Address:"}
                    className={"mt-1"}
                    alignItems="align-items-start"
                    keyStyles={{ minWidth: "80px" }}
                    value={
                      <>
                        {data?.biline1 && (
                          <div>
                            {data?.biline1} <br className="mt-1" />
                          </div>
                        )}
                        {data?.bitown && (
                          <div className="mt-1">
                            {data?.bitown} <br className="mt-1" />
                          </div>
                        )}{" "}
                        {data?.bistate && (
                          <div className="mt-1">
                            {data?.bistate} <br className="mt-1" />
                          </div>
                        )}{" "}
                        {data?.bicountry && (
                          <div className="mt-1">
                            {data?.bicountry} <br className="mt-1" />
                          </div>
                        )}
                      </>
                    }
                    keyClass={"mb-2"}
                    valueClass={"mb-2"}
                  />
                </div>
                <div className="ms-4">
                  <DataList
                    text={"Phone:"}
                    keyStyles={{ minWidth: "100px" }}
                    value={data.billingPhone}
                    keyClass={"mb-2"}
                    valueClass={"mb-2"}
                    className={"mt-5"}
                  />
                </div>
              </div>
            </div>
          </Tile>
        </div>
      </div>
    </div>
  );
};

export const CreditsInformationView = ({ data = {}, locale = "en-GB" }) => {
  return (
    <div className="container pt-4">
      <div className="row g-2 pt-0 pb-2">
        <div className="col-12 col-xl-6">
          <Tile
            heading="Credit Limit"
            height={"280px"}
            className="px-4 py-2 overflow-auto"
          >
            <div>
              <DataList
                text={"Credit Limit:"}
                value={AmountFormat(data?.creditLimit)}
                className={"w-100"}
                keyClass={"mb-3"}
                alignItems="align-items-start"
                valueClass={"mb-3"}
              />
              <DataList
                text={"Balance:"}
                value={AmountFormat(data?.balance)}
                className={"w-100"}
                keyClass={"mb-3"}
                alignItems="align-items-start"
                valueClass={"mb-3 "}
              />
              <DataList
                text={"On Hold:"}
                value={<Checkbox checked />}
                className={"w-100"}
                keyClass={"mb-3"}
                alignItems="align-items-start"
                valueClass={"mb-3 "}
              />
              <DataList
                text={"Credit Control Stage:"}
                value={data?.creditControlStage}
                className={"w-100"}
                keyClass={"mb-3"}
                alignItems="align-items-start"
                valueClass={"mb-3 "}
              />
              <DataList
                text={"Date Stage Set:"}
                value={
                  data?.creditControlStage &&
                  formatDate(new Date(data?.creditControlStage), "d", locale)
                }
                className={"w-100"}
                keyClass={"mb-3"}
                valueClass={"mb-3 "}
              />
              <DataList
                text={"Suspended:"}
                value={<Checkbox checked />}
                className={"w-100"}
                keyClass={"mb-3"}
                valueClass={"mb-3 "}
              />
            </div>
          </Tile>
        </div>
        <div className="col-xl-6 col-12">
          <Tile
            heading="Default Codes"
            height={"280px"}
            className="px-4 py-2 overflow-auto"
          >
            <div className=" d-flex  justify-content-start justify-content-xl-between ">
              <div className="w-50 me-4">
                <DataList
                  text={"VAT Code:"}
                  value={data?.vatCodeId?.representation}
                  className={"w-100"}
                  keyClass={"mb-3"}
                  alignItems="align-items-start"
                  valueClass={"mb-3"}
                  keyStyles={{ minWidth: "100px" }}
                />
                <DataList
                  text={"Currency:"}
                  value={data?.currency?.representation}
                  className={"w-100"}
                  keyClass={"mb-3"}
                  valueClass={"mb-3"}
                  keyStyles={{ minWidth: "100px" }}
                />
                <DataList
                  text={"Fee Earner:"}
                  value={data?.feeEarner?.representation}
                  className={"w-100 "}
                  keyClass={"mb-3"}
                  valueClass={"mb-3"}
                  keyStyles={{ minWidth: "100px" }}
                />
              </div>
              <div>
                <DataList
                  text={"Rate Category:"}
                  value={data?.rateId}
                  className={"w-100"}
                  keyClass={"mb-3"}
                  alignItems="align-items-start "
                  keyStyles={{ minWidth: "120px" }}
                  valueClass={"mb-3"}
                />
              </div>
            </div>
          </Tile>
        </div>
      </div>
      <div className="row mt-1 pt-0 pb-3 g-2">
        <div className="col-12 col-xl-6">
          <Tile
            heading="Payment Bank"
            height={"280px"}
            className="px-4 py-2 overflow-auto"
          >
            <div className=" d-flex  justify-content-start justify-content-xl-between ">
              <div className="w-50 me-4">
                <DataList
                  text={"Account Type:"}
                  value={data?.accountType}
                  className={"w-100"}
                  keyClass={"mb-3"}
                  alignItems="align-items-start "
                  keyStyles={{ minWidth: "130px" }}
                  valueClass={"mb-3"}
                />
                <DataList
                  text={"Account Title:"}
                  value={data?.accountTitle}
                  className={"w-100 "}
                  keyClass={"mb-3"}
                  keyStyles={{ minWidth: "120px" }}
                  valueClass={"mb-3"}
                />
                <DataList
                  text={"Account Number:"}
                  value={data?.accountNo}
                  className={"w-100"}
                  keyClass={"mb-3"}
                  keyStyles={{ minWidth: "120px" }}
                  valueClass={"mb-3"}
                />
                <DataList
                  text={"Sort Code:"}
                  value={data?.sortCode}
                  className={"w-100"}
                  keyClass={"mb-3"}
                  keyStyles={{ minWidth: "120px" }}
                  valueClass={"mb-3"}
                />
              </div>
              <div className="">
                <DataList
                  text={"BACS Payment:"}
                  value={<Checkbox checked />}
                  className={"w-100"}
                  keyClass={"mb-3"}
                  keyStyles={{ minWidth: "120px" }}
                  valueClass={"mb-3"}
                />
                <DataList
                  text={"BACS Code:"}
                  value={data?.bacsCode}
                  className={"w-100"}
                  keyClass={"mb-3"}
                  keyStyles={{ minWidth: "120px" }}
                  valueClass={"mb-3"}
                />
              </div>
            </div>
          </Tile>
        </div>
      </div>
    </div>
  );
};

export const CompanyInformationView = ({ data = {}, locale = "en-GB" }) => {
  return (
    <div className="container pt-4">
      <div className="row g-2">
        <div className="col-12 col-xl-6">
          <Tile
            heading="Client"
            headingItems={
              <PrimaryBadgeComponent
                text={"Company"}
                className="mt-3 ps-3 pe-3"
              />
            }
            height={"280px"}
            className="p-3 ps-4 pe-4 pt-2 pb-0 overflow-auto"
          >
            <div>
              <DataList
                text={"Reference:"}
                value={data?.clientRef}
                className={"w-50 p-0"}
                keyClass={"mb-2"}
                valueClass={"mb-2"}
                keyStyles={{ minWidth: "80px" }}
              />
            </div>
            <div className="d-flex align-items-start justify-content-start">
              <div className="w-50">
                <DataList
                  text={"Name:"}
                  value={data?.clientName}
                  valueClass={"mb-2"}
                  keyClass={"mb-2"}
                  className={"w-100 mt-1"}
                  keyStyles={{ minWidth: "80px" }}
                />
                <DataList
                  text={"Email:"}
                  value={data.email1}
                  keyClass={"mb-2"}
                  valueClass={"mb-2"}
                  className={"mt-4"}
                  keyStyles={{ minWidth: "80px" }}
                />
                <DataList
                  text={"Address:"}
                  className={"mt-1"}
                  alignItems="align-items-start"
                  keyStyles={{ minWidth: "80px" }}
                  value={
                    <>
                      {data?.line1 && (
                        <div>
                          {data?.line1} <br className="mt-1" />
                        </div>
                      )}
                      {data?.town && (
                        <div className="mt-1">
                          {data?.town} <br className="mt-1" />
                        </div>
                      )}{" "}
                      {data?.state && (
                        <div className="mt-1">
                          {data?.state} <br className="mt-1" />
                        </div>
                      )}{" "}
                      {data?.country && (
                        <div className="mt-1">
                          {data?.country} <br className="mt-1" />
                        </div>
                      )}
                    </>
                  }
                  keyClass={"mb-2"}
                  valueClass={"mb-2"}
                />
              </div>
              <div className="ps-4">
                <DataList
                  text={"Branch:"}
                  valueClass={"mb-2"}
                  keyStyles={{ minWidth: "80px" }}
                  keyClass={"mb-2"}
                  value={data?.branch?.branchName || ""}
                  className={"w-100 mt-1"}
                />
                <DataList
                  text={"Phone 1:"}
                  value={data.phone1}
                  keyClass={"mb-2"}
                  valueClass={"mb-2"}
                  className={"mt-4"}
                  keyStyles={{ minWidth: "80px" }}
                />
                <DataList
                  text={"Phone 2:"}
                  value={data.phone2}
                  keyClass={"mb-2"}
                  className={"mt-1"}
                  valueClass={"mb-2"}
                  keyStyles={{ minWidth: "80px" }}
                />
                <DataList
                  text={"Mobile:"}
                  value={data.mobileNo}
                  keyClass={"mb-2"}
                  className={"mt-1"}
                  keyStyles={{ minWidth: "80px" }}
                  valueClass={"mb-2"}
                />
                <DataList
                  text={"Website:"}
                  value={data.website}
                  keyClass={"mb-2"}
                  className={"mt-1"}
                  keyStyles={{ minWidth: "80px" }}
                  valueClass={"mb-2"}
                />
              </div>
            </div>
          </Tile>
        </div>
        <div className="col-12 col-xl-6">
          <Tile
            heading="Correspondence"
            height={"280px"}
            className="px-4 py-2  overflow-auto"
          >
            <div className="d-flex align-items-start justify-content-start">
              <div className="w-50">
                <DataList
                  text={"Name:"}
                  value={data?.corrFirstName}
                  valueClass={"mb-2"}
                  keyClass={"mb-2"}
                  className={"w-100"}
                />
                <DataList
                  text={"Phone Number:"}
                  value={data?.corrPhone}
                  valueClass={"tmb-2"}
                  keyClass={"mb-2"}
                  className={"w-100 mt-1"}
                />
                <DataList
                  text={"Email:"}
                  value={data?.corrEmail}
                  keyClass={"mb-2"}
                  valueClass={"mb-2"}
                  className={"w-100 mt-1"}
                />
                <DataList
                  text={"Job Title:"}
                  value={data?.corrJobTitle}
                  keyClass={"mb-2"}
                  valueClass={"mb-2"}
                  className={"w-100 mt-1"}
                />
              </div>
              {/* <div className="ps-5">
                  <DataList
                    text={"Mobile Number:"}
                    value={data.mobileNo}
                    keyClass={"mb-2"}
                    valueClass={"mb-2"}
                  />
                  <DataList
                    text={"Website:"}
                    value={data.website}
                    keyClass={"mb-2"}
                    valueClass={"mb-2"}
                  />
                </div> */}
            </div>
          </Tile>
        </div>
      </div>

      <div className="row mt-1 g-2 pb-3">
        <div className="col-12 col-xl-6">
          <Tile
            heading="Company Details"
            height={"280px"}
            className="px-4 py-2  overflow-auto"
          >
            <div className="d-flex align-items-start justify-content-start">
              <div className="w-50">
                <DataList
                  text={"Registration No:"}
                  value={data.companyRegNo}
                  keyClass={"mb-2"}
                  valueClass={"mb-2"}
                  keyStyles={{ minWidth: "140px" }}
                />
                <DataList
                  text={"Register Address:"}
                  className={"mt-4"}
                  alignItems="align-items-start"
                  keyStyles={{ minWidth: "140px" }}
                  value={
                    <>
                      {data?.registeredAddressline1 && (
                        <div>
                          {data?.registeredAddressline1} <br className="mt-1" />
                        </div>
                      )}
                      {data?.registeredAddresstown && (
                        <div className="mt-1">
                          {data?.registeredAddresstown} <br className="mt-1" />
                        </div>
                      )}{" "}
                      {data?.registeredAddressstate && (
                        <div className="mt-1">
                          {data?.registeredAddressstate} <br className="mt-1" />
                        </div>
                      )}{" "}
                      {data?.registeredAddresscountry && (
                        <div className="mt-1">
                          {data?.registeredAddresscountry}{" "}
                          <br className="mt-1" />
                        </div>
                      )}
                    </>
                  }
                  keyClass={"mb-2"}
                  valueClass={"mb-2"}
                />
              </div>
              <div className="w-50 ps-5">
                <DataList
                  text={"Vat Reg No:"}
                  value={data.vatNo}
                  keyClass={"mb-2"}
                  valueClass={"mb-2"}
                  keyStyles={{ minWidth: "110px" }}
                />
                <DataList
                  text={"SIC Code:"}
                  value={data.sicCode}
                  keyClass={"mb-2"}
                  valueClass={"mb-2"}
                  className={"mt-1"}
                  keyStyles={{ minWidth: "110px" }}
                />
              </div>
            </div>
          </Tile>
        </div>
        <div className="col-12 col-xl-6">
          <MattersList data={data?.matters} />
        </div>
      </div>
    </div>
  );
};

export const MattersList = ({ data = [], locale = "en-GB" }) => {
  return (
    <Tile
      heading="Matters"
      height={"280px"}
      className="px-4 pt-2 pb-0 overflow-auto"
    >
      <div style={{ overflow: "auto" }}>
        <Grid data={data} style={{ maxHeight: "190px" }} className="pb-1">
          <GridColumn
            field="matterRef"
            headerClassName="ps-3"
            title="Matter Ref"
            width="130px"
            className="gridBoldField ps-4 fs-sm"
          />
          <GridColumn
            field="details"
            title="Details"
            className="fs-sm"
            width="180px"
          />
          <GridColumn
            field="appCode"
            title="Application"
            className="fs-sm"
            width="80px"
          />
          <GridColumn
            field="startDate"
            title="Date Opened"
            className="fs-sm"
            cell={DateFormatCell}
            width="130px"
          />
        </Grid>
      </div>
    </Tile>
  );
};

export const PersonMattersList = ({ data = [], locale = "en-GB" }) => {
  return (
    <Tile
      heading="Matters"
      height={"260px"}
      // height={"calc(50vh - 175px"}
      className="px-4 py-2 overflow-auto"
    >
      <div style={{ maxHeight: "220px", overflow: "auto" }}>
        <Grid data={data}>
          <GridColumn
            field="matterRef"
            headerClassName="ps-3"
            title="Matter Ref"
            width="130px"
            className="gridBoldField ps-4"
          />
          <GridColumn field="details" title="Details" width="200px" />
          <GridColumn field="feeEarnerRef" title="Fee Earner" width="120px" />
          <GridColumn field="branch" title="Branch" width="150px" />
          <GridColumn field="appCode" title="Application" width="80px" />
          <GridColumn
            field="startDate"
            title="Date Opened"
            cell={DateFormatCell}
            width="130px"
          />
        </Grid>
      </div>
    </Tile>
  );
};

const MatterItem = ({ matterRef, application, opened, details, index }) => {
  return (
    <div
      className={`${styles["matter-item"]} p-2 ${
        index !== 0 && "mt-2"
      } px-4 pt-3`}
    >
      <div className={`row`}>
        <div className="col-5">
          <DataList
            text={"Matter Ref:"}
            value={matterRef}
            keyClass={"mb-2"}
            valueClass={"mb-2"}
            keyStyles={{ minWidth: 90 }}
            valueStyles={{ overflow: "hidden" }}
          />
        </div>
        <div className="col-3">
          <DataList
            text={"Application:"}
            value={application}
            keyClass={"mb-2"}
            valueClass={"mb-2"}
            keyStyles={{ minWidth: 90 }}
            valueStyles={{ overflow: "hidden" }}
          />
        </div>{" "}
        <div className="col-4">
          <DataList
            text={"Opened:"}
            value={opened}
            keyClass={"mb-2"}
            valueClass={"mb-2"}
            keyStyles={{ minWidth: 80 }}
            valueStyles={{ overflow: "hidden" }}
          />
        </div>
      </div>
      <div className="row">
        <DataList
          //   text={""}
          value={details}
          keyClass={"mb-2"}
          keyStyles={{ minWidth: 0 }}
          valueStyles={{ width: "100%" }}
          valueClass={"mb-2"}
        />
      </div>
    </div>
  );
};
