import {
  START_LOADING,
  STOP_LOADING,
  ERROR,
  SUCCESS,
  GET_NOMINALS_LIST,
  ADD_NOMINAL,
  UPDATE_NOMINAL,
  GET_VIEW_NOMINALS,
  GET_OFFICELIST,
  SEARCH_NOMINAL,
  GET_ACTIVENOMINALS,
  GET_NOMINALS_BY_REF,
  GET_NOMINAL_BALANCES,
  GET_CLIENTLIST,
  GET_DDALIST,
  GET_NOMINAL_JOURNAL_POSTING,
  SET_NOMINALS_PROFIT_AND_LOSS,
  SET_NOMINALS_DEFAULT_PROFIT_AND_LOSS,
  CURRENCT_NOMINAL_REF,
} from "../types/index";
import * as api from "../api/accountsAPI";
import { handleApiError } from "Helper";
import { toast } from "react-toastify";
import { NominalSubtypes } from "components/Enums/Nominals.ts";

export const getIncomeNominals = () => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const datas = await api.getNominalsBySubtype(NominalSubtypes.PL_INCOME);
    dispatch({ type: SET_NOMINALS_PROFIT_AND_LOSS, payload: datas.data });
    dispatch({ type: STOP_LOADING });
    dispatch({ type: SUCCESS, payload: datas.message });
  } catch (error) { handleApiError(error) }
};


export const getDDABankNominals = () => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const datas = await api.getNominalsBySubtype(NominalSubtypes.DDA_BANK);
    dispatch({ type: SET_NOMINALS_DEFAULT_PROFIT_AND_LOSS, payload: datas.data });
    dispatch({ type: STOP_LOADING });
    dispatch({ type: SUCCESS, payload: datas.message });
  } catch (error) { handleApiError(error) }
};

export const getNominalsList = () => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const datas = await api.getNominalsList();
    dispatch({ type: GET_NOMINALS_LIST, payload: datas.data });
    dispatch({ type: STOP_LOADING });
    dispatch({ type: SUCCESS, payload: datas.message });
  } catch (error) { handleApiError(error) }
};

export const getNominalsJournalList = () => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const datas = await api.getNominalJournalPosting();
    dispatch({ type: GET_NOMINAL_JOURNAL_POSTING, payload: datas });
    dispatch({ type: STOP_LOADING });
    dispatch({ type: SUCCESS, payload: datas.message });
  } catch (error) { handleApiError(error) }
};

export const addNominal = (data) => async (dispatch) => {
  try {
    const res = await api.addNominal(data);
    toast.success('Nominal Added')
    dispatch({ type: ADD_NOMINAL, payload: res });
  } catch (error) { 
    toast.error('Error in adding Nominal')
    handleApiError(error) }
};

export const changeNominalToLedgerRef = (data) => async (dispatch) => {
  try {
    dispatch({ type: CURRENCT_NOMINAL_REF, payload: data });
  } catch (error) { handleApiError(error) }
};


export const getViewNominals = (id) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const datas = await api.getViewNominals(id);
    dispatch({ type: GET_VIEW_NOMINALS, payload: datas.data });
    dispatch({ type: STOP_LOADING });
    dispatch({ type: SUCCESS, payload: datas.message });
  } catch (error) { handleApiError(error) }
};

export const clearViewNominals = (id) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    dispatch({ type: GET_VIEW_NOMINALS, payload: undefined });
    dispatch({ type: STOP_LOADING });
  } catch (error) { handleApiError(error) }
};


export const updateNominal = (id, data) => async (dispatch) => {
  try {
    const res = await api.updateNominal(id, data);
    dispatch({ type: UPDATE_NOMINAL, payload: res });
    toast.success('Nominal Updated')
  } catch (error) { handleApiError(error) }
};

export const searchNominal = (page, limit, search) => async (dispatch) => {
  try {
    const res = await api.searchNominal(page, limit, search);
    dispatch({ type: SEARCH_NOMINAL, payload: res.data });
  } catch (error) { handleApiError(error) }
};

export const getactiveNominals = () => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const datas = await api.getactivenominals();
    dispatch({ type: GET_ACTIVENOMINALS, payload: datas.data });
  } catch (error) { handleApiError(error) }
};

export const getOfficeBankNominals = () => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const datas = await api.getNominalsBySubtype(NominalSubtypes.OFFICE_BANK);
    dispatch({ type: GET_OFFICELIST, payload: datas.data });
  } catch (error) { handleApiError(error) }
};

export const getClientBankNominals = () => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const { data } = await api.getclientList();
    dispatch({ type: GET_CLIENTLIST, payload: data });
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const getDdaList = () => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const { data } = await api.getddaList();
    dispatch({ type: GET_DDALIST, payload: data });
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const getNominalsByRef = (searchedVal) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const { data } = await api.getNominalsByRef(searchedVal);
    dispatch({ type: GET_NOMINALS_BY_REF, payload: data });
  } catch (error) {
    handleApiError(error)
  }
};

export const getNominalBalances =
  (searchedVal, FromDate, ToDate) => async (dispatch) => {
    try {
      dispatch({ type: START_LOADING });
      const { data } = await api.getNominalBalances(
        searchedVal,
        FromDate,
        ToDate
      );
      dispatch({ type: GET_NOMINAL_BALANCES, payload: data });
    } catch (error) {
      dispatch({ type: ERROR, payload: error });
    }
  };
