import React, { useEffect } from "react";
import SectionDivider from "components/Contact/sectionDivider";
import styles from "../../Settings.module.scss";
import { Input } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import { DateFormat } from "components/common/formatter";
import { useState } from "react";
import HMRCImage from "../../../../assets/Settings/Integrations/HMRC.png";
import { clioStatus, clioSync, hmrcSync } from "api";
import { toast } from "react-toastify";
import HMRCAuthentication from "pages/Settings/Company/HMRCAuthentication";

export default function HmrcPage({ connectionStatus, onClose }) {
  const [hmrcData, sethmrcData] = useState({});
  const [syncMessage, setSyncMessage] = useState(false);

  const syncHMRCStatus = async () => {
    setSyncMessage(true);
    try {
      const res = await hmrcSync();
      onClose();
      toast.success("Synchronizing started...");
    } catch (error) {
      onClose();
      toast.error("Error occured...");
    }
  };

  return (
    <>
      {!connectionStatus ? (
        <div
          className={`d-flex align-items-start ${styles["clio-container"]} p-4`}
        >
          <div>
            <img src={HMRCImage}></img>
          </div>
          <div className="mt-2">
            <div>
              <SectionDivider sectionName="HMRC MTD VAT Submission" />
            </div>
            <div className={`${styles["clio-text"]} mt-5 `}>
              <p>
                Making Tax Digital is a key part of the government’s Tax
                Administration Strategy. It will help reduce the tax gap by
                requiring businesses and individuals to;<br/> keep digital records,
                use software that works with Making Tax Digital, submit updates
                every quarter, bringing the tax system closer to real-time
              </p>
            </div>
            <div className="mt-4">
              <p className={`${styles["blue-text"]}`}>
                <u>More Details...</u>
              </p>
            </div>
            <div className="d-flex align-items-center mt-5">
              <p className={`${styles["label"]}`}>New HMRC Users :</p>
              <p className={`${styles["blue-text"]}`}>
                <u>Get Started</u>
              </p>
            </div>
            <div className="d-flex align-items-center ">
              <p className={`${styles["label"]} mt-2`}>Existing HMRC Users :</p>
              <HMRCAuthentication onClose={onClose} />
            </div>
          </div>
        </div>
      ) : (
        <div
          className={`d-flex align-items-start ${styles["clio-container"]} p-4`}
        >
          <div>
            <img src={HMRCImage}></img>
          </div>
          <div className="mt-2 w-100">
            <div>
              <SectionDivider
                sectionName="Clio Case Management System"
                component={"Clio"}
              />
            </div>
            <div className="d-flex align-items-center mt-5">
              <p className={`${styles["label"]} mt-2`}>Connected By:</p>
              <Input
                placeholder="Enter Details"
                readOnly
                value={hmrcData?.connectedBy}
              />
            </div>
            <div className="d-flex align-items-center mt-2">
              <p className={`${styles["label"]} mt-2`}>Connected On:</p>
              <Input
                placeholder="Enter Details"
                readOnly
                value={DateFormat(hmrcData?.connectedDate)}
              />
            </div>
            <div className="d-flex align-items-center mt-2">
              <p className={`${styles["label"]} mt-2`}>Full Synced On:</p>
              <Input
                placeholder="Enter Details"
                readOnly
                value={DateFormat(hmrcData?.fullSyncDate)}
              />
            </div>
            <div className="d-flex align-items-center mt-2">
              <p className={`${styles["label"]} mt-2`}>Last Updated On:</p>
              <Input
                placeholder="Enter Details"
                readOnly
                value={DateFormat(hmrcData?.lastSyncDate)}
              />
            </div>
            <div className="d-flex align-items-center mt-2">
              <p className={`${styles["label"]} mt-2`}>Reconnect:</p>
              <HMRCAuthentication
                onClose={onClose}
                text={"Reconnect to Clio"}
              />
            </div>
            <div className="d-flex align-items-center mt-2">
              <p className={`${styles["label"]} mt-2`}></p>
              <Button
                className="common-btn"
                onClick={syncHMRCStatus}
                style={{ width: "165px" }}
                disabled={syncMessage}
              >
                Sync HMRC
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
