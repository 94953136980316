import * as React from "react";
import Chart from "components/Dashboard/Chart";
import { useState } from "react";
import { useEffect } from "react";
import { getDashboardBillingTargetData } from "../../actions/dashboard.action";
import { ChartHeader, ChartFooter } from "components/Dashboard";
import billing from "../../assets/DashboardIcons/billing.svg";
import Unbilled from "../../assets/DashboardIcons/unbilled.svg";
import {
  useDashboardData,
  graphUpdater,
} from "../../components/Dashboard/useDashboardData";
import { AmountFormat } from "components/common/formatter";
import styles from "./DashboardPage.module.scss";
import { useSelector } from "react-redux";

export const BillingTargetChart = (props) => {
  const [type, setType] = useState("Month");
  const [filters, setFilters] = useState({});
  const initialSeries = [
    { name: "Bill Recorded", data: [] },
    { name: "Target", data: [] },
  ];

  useDashboardData(filters, getDashboardBillingTargetData);

  const [series, setSeries] = useState([]);
  const [categories, setCategories] = useState([]);

  const { billingTarget } = useSelector((state) => state.dashboard);

  useEffect(() => {
    if (billingTarget?.data) {
      graphUpdater(billingTarget.data, initialSeries, setCategories, setSeries);
    }
  }, [billingTarget.data]);

  const typesUpdater = () => {
    let types = [];
    if (filters?.feeearnerRef === null) {
      types.push({
        label: "By Fee Earner",
        value: "FeeEarner",
      });
    }
    if (filters?.appCode === null) {
      types.push({
        label: "By App",
        value: "Application",
      });
    }
    if (filters?.branch === null) {
      types.push({
        label: "By Branch",
        value: "Branch",
      });
    }
    if (filters?.month === null) {
      types.push({
        label: "By Month",
        value: "Month",
      });
    }
    setTypesData(types);
    let check = false;
    types.map((item) => {
      if (item.value === type) {
        check = true;
      }
    });
    if (!check) {
      setType("Month");
    }
  };
  const [typesData, setTypesData] = useState([]);
  useEffect(() => {
    setFilters(props.filters);
  }, [props.filters]);
  useEffect(() => {
    typesUpdater();
  }, [filters, type]);
  return (
    <div className="h-100 p-2">
      <ChartHeader
        name={"Billing"}
        type={"(Actual vs Targets)"}
        param1={"Billed"}
        param2={"Target"}
        icon={billing}
        color={"#E2FFF4"}
        duration={filters?.duration}
      />
      <div className="d-flex p-2">
        <Chart
          series={series}
          onSeriesClick={() => {}}
          categories={categories}
        ></Chart>
      </div>
      <ChartFooter param1={"Billed"} param2={"Target"} data={series} />
    </div>
  );
};

export const BillingTargetStatsCard = ({ filters }) => {
  const { billingTarget } = useSelector((state) => state.dashboard);
  const totalBilling =
    billingTarget.data?.reduce((sum, item) => sum + item.actual, 0) || 0;

  return (
    <div className="d-flex flex-column  align-items-start mt-4 py-3 px-4">
      <div
        className={`${styles.iconContainer}`}
        style={{
          backgroundColor: "#E2FFF4",
        }}
      >
        <img src={billing} alt="billingIcon" />
      </div>

      <p className="fs-md fw-semibold mt-1 mb-1" style={{ color: "#0ACF83" }}>
        Billing
      </p>
      <p className="fs-xl fw-bold">&#163; {AmountFormat(totalBilling)}</p>
    </div>
  );
};

export const UnBilledTargetStatsCard = ({ filters }) => {
  const { timeTarget } = useSelector((state) => state.time);

  const { billingTarget } = useSelector((state) => state.dashboard);

  const totalTime =
    timeTarget?.data?.reduce((sum, item) => sum + item.actual, 0) || 0;
  const totalBilling =
    billingTarget.data?.reduce((sum, item) => sum + item.actual, 0) || 0;

  return (
    <div className="d-flex flex-column  align-items-start mt-4 py-3 px-4">
      <div
        className={`${styles.iconContainer}`}
        style={{
          backgroundColor: "#FFF4E5",
        }}
      >
        <img src={Unbilled} alt="billing icon" />
      </div>
      <p className="fs-md fw-semibold mt-1 mb-1" style={{ color: "#FF9E16" }}>
        Unbilled
      </p>
      <p className="fs-xl fw-bold">
        &#163; {AmountFormat(totalTime - totalBilling)}
      </p>
    </div>
  );
};
