import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "@progress/kendo-react-intl";
import "@progress/kendo-react-dropdowns";
import "react-router-dom";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { Button } from "@progress/kendo-react-buttons";
import { HandleGridBody } from "components/common/HandleGridBody.js";
import { DateFormatCell } from "components/common/formatter";
import { Checkbox } from "@progress/kendo-react-inputs";
import { Label } from "@progress/kendo-react-labels";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { Skeleton } from "@progress/kendo-react-indicators";
import SearchTextBox from "components/SearchComponent.jsx";
import { analyzeDashboardFilters, DateReturner } from "components/MyMatters";
import { useMatch, useNavigate } from "react-router-dom";
import styles from "../../pages/Matters/Matter.module.scss";
import LoadingScreen from "components/common/Loading";
import { addMattersDashboardFilter } from "actions/dashboard.action";
import {
  clearMattersBalances,
  getMattersAllURLInfo,
  getMyMattersPageList,
} from "actions/matters.action";
import ChitRequest from "pages/ChitRequest/ChitRequest";
import TimeRecordingHelper from "components/MyMatters/TimeRecordingHelper";
import { changeLedgerLink } from "actions/ledgerLinks.action";
import MatterForm from "pages/Matters/MatterForm";
import { CustomDateInput } from "components/common/CustomDateInput";
import CalenderCustomToggleButton from "components/common/CalenderCustomToogleButton";
import { useAllowedLiscence } from "hooks/useAllowedLiscence";
import { FilterComponent } from "components/common/FiltersComponent";
import { getAccessRightsData, getMatterAllInfo, getTeams } from "api";
import MatterLedger from "pages/MatterLedger/MatterLedger";
import { Dialog } from "@progress/kendo-react-dialogs";
import {
  MatterOptionsList,
  MatterOptions,
} from "components/Matters/MatterOptions";
import { Popup } from "@progress/kendo-react-popup";
import { FaRegCheckCircle } from "react-icons/fa";
import { FaFilter } from "react-icons/fa";
import filtersAppliedIcon from "assets/CaseFileIcons/FilterApplied.svg";

const MyMattersPageComponent = ({ component, fromClient, Clientdata }) => {
  const menuWrapperRef = useRef(null);
  const date = new Date();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const searchRef = useRef();
  const [isRefreshed, setIsRefreshed] = useState(false);
  const [chitRequestModal, setChitRequestModal] = useState(false);
  const [timeRecordingModal, setTimeRecordingModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [matterFilters, setMatterFilters] = useState();
  const [searchValue, setSearchValue] = useState();
  const [filterParameters, setFilterParameters] = useState({});
  const [showMatterForm, setshowMatterForm] = useState(false);
  const [loadedContent, setLoadedContent] = useState(true);
  const [selectedMatter, setSelectedMatter] = useState(null);
  const [includeEndDate, setIncludeEndDate] = useState(false);
  const [allowAccessData, setAllowAccessData] = useState({});
  const { subDataCheck } = useAllowedLiscence();
  const [includeStartDate, setIncludeStartDate] = useState(false);
  const [reference, setReference] = useState();
  const [toDateSelector, setToDateSelector] = useState(date);
  const [fromDateSelector, setFromDateSelector] = useState(date);
  const [matterLedger, selectMatterLedger] = useState(null);
  const [filters, setFilters] = useState({});
  const [list, setLists] = useState({ data: [], total: 50 });
  const [status, setStatus] = useState("Open");
  const [filtersPopup, setFiltersPopup] = useState(false);
  const filterAnchorRef = useRef(null);

  const [selectedCell, setSelectedCell] = useState(null);
  const [show, setShow] = useState(false);
  const offSet = useRef({
    left: 0,
    top: 0,
  });
  const [dataState, setDataState] = useState({
    take: 100,
    skip: 0,
    sort: [
      {
        field: "matterRef",
        dir: "asc",
      },
    ],
  });

  const brancheslist = useSelector((state) => {
    return state?.branchesnames?.branchesList;
  });

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        menuWrapperRef.current &&
        menuWrapperRef.current.contains(event.target)
      )
        return;

      if (
        event.button !== 2 &&
        event.target?.role !== "menu-grid" &&
        event.target?.role !== "button"
      ) {
        setShow(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleOptionClick = (action, props) => {
    switch (action) {
      case "viewMatter":
        onMatterRefClickActions(props);
        break;
      case "casefile":
        navigate(`/matters/${props?.dataItem?.matterId || props?.matterId}`);
        break;
      case "addTime":
        setSelectedMatter(props?.dataItem || props);
        setTimeRecordingModal(true);
        break;
      case "addChitRequest":
        setSelectedMatter(props?.dataItem || props);
        setChitRequestModal(true);
        break;
      case "viewLedger":
        dispatch(changeLedgerLink("MatterLedger", props?.dataItem || props));
        selectMatterLedger(props?.dataItem || props);
        break;
      default:
        break;
    }
    setShow(false);
  };

  const mattersDataGetter = async (id) => {
    try {
      const datas = await getMatterAllInfo(id);
      dispatch(getMattersAllURLInfo(datas, false));
    } catch (error) {
      alert("No matter exists against this Id. Try again with different Id");
      navigate("/matters");
    }
  };

  // useEffect(() => {
  // // if (params?.params?.id !== null && params?.params?.id !== undefined) {
  // mattersDataGetter();
  // } else {
  // }
  // }, [params]);

  const dashboardFilters = useSelector((state) => state.dashboard.filters);

  const analyzeFilterParamters = (params) => {
    if (!fromClient) {
      const paramsToFilter = {
        branch: params?.branch,
        appCode: params?.appCode,
        feeEarnerRef: params?.feeearnerRef,
        teamId: params?.teamId,
        search: searchValue,
      };
      setFilterParameters(paramsToFilter);
    }
  };

  const onChangeFromDateSelect = (event) => {
    if (!includeStartDate) {
      setIncludeStartDate(true);
    }
    setFromDateSelector(event.value);
  };
  const onChangeToDateSelect = (event) => {
    if (!includeEndDate) {
      setIncludeEndDate(true);
    }
    setToDateSelector(event.value);
  };

  useEffect(() => {
    if (dashboardFilters !== undefined) {
      const filters = analyzeDashboardFilters(dashboardFilters, brancheslist);
      setMatterFilters(filters?.matterFilters);
      setIncludeEndDate(true);
      setIncludeStartDate(true);
      setFromDateSelector(filters?.fromDate);
      setToDateSelector(filters?.toDate);
      setTimeout(() => {
        setFilterParameters(filters?.parameters);
        setIsLoading(false);
      }, 1000);
      dispatch(addMattersDashboardFilter());
    } else {
      setIsLoading(false);
    }
  }, [dashboardFilters]);

  useEffect(() => {
    if (dashboardFilters === undefined) {
      analyzeFilterParamters();
    }
  }, [
    filters,
    includeEndDate,
    includeStartDate,
    fromDateSelector,
    toDateSelector,
    searchValue,
  ]);

  const getAccessRights = async () => {
    try {
      const data = await getAccessRightsData();
      setAllowAccessData(data?.data?.data);
      setLoadedContent(false);
    } catch (error) {}
  };

  useEffect(() => {
    if (!fromClient) {
      getAccessRights();
    }
  }, []);

  const onMatterRefClickActions = (props) => {
    // navigate(`/matters/${props.dataItem.matterId}`);
    setReference(props.dataItem?.matterRef || props.matterRef);
    mattersDataGetter(props.dataItem?.matterId || props.matterId);
    setshowMatterForm(true);
  };

  const MatterRefCell = (props) => {
    return (
      <td role="button">
        <span
          style={{ cursor: "pointer" }}
          onClick={() => {
            // onMatterRefClickActions(props);
            setShow(false);
            handleCellClick(null);

            try {
              window.chrome.webview.postMessage(
                "CasefileOpened:" + props.dataItem.matterId
              );
            } catch (error) {}

            navigate(`/matters/${props.dataItem.matterId}`);
          }}
        >
          <u role="button">{props.dataItem.matterRef}</u>
        </span>
      </td>
    );
  };

  const MatterDetailCell = (props) => {
    return (
      <td>
        <div className="d-flex justify-content-between">
          <span className="text-start">
            {props.dataItem.details}

            {props.dataItem.closed && (
              <FaRegCheckCircle className="ms-2" color="red" />
            )}
          </span>

          <span className="text-end fw-bold fs-sm">
            {" "}
            {!fromClient && " " + props.dataItem.clientName}
          </span>
        </div>
      </td>
    );
  };

  const createState = (skip, take) => {
    let pagerSettings = {
      pageSizes: [10, 25, 50, 100],
    };
    return {
      pageSize: take,
      pageable: pagerSettings,
    };
  };
  const [state, setState] = React.useState(createState(0, 10));

  const dataStateChange = (e) => {
    if (
      e.dataState.sort !== undefined &&
      e.dataState.skip !== 0 &&
      dataState.sort !== e.dataState.sort
    ) {
      setDataState({ ...e.dataState, skip: 0 });
    } else if (e.dataState?.sort?.[0] === undefined) {
      setDataState({
        ...e.dataState,
        sort: [
          {
            field: dataState?.sort?.[0].field,
            dir: "asc",
          },
        ],
      });
    } else {
      setDataState(e.dataState);
    }
  };
  const dataReceived = (list) => {
    if (list.data) {
      setLists(list);
    }
  };

  const handleRefresh = () => {
    // setSearchValue("");
    // searchRef.current.clearSearch();
    setIsRefreshed(!isRefreshed);
  };

  useLayoutEffect(() => {
    return () => {
      dispatch(getMyMattersPageList());
    };
  }, []);

  const handleCheckboxChange = (newStatus) => {
    setStatus(newStatus);
  };

  const handleReset = () => {
    setStatus("Open");
  };

  const getHeaderItems = () => {
    switch (component) {
      case "matters":
        return loadedContent ? (
          <Skeleton
            shape="rectangle"
            className="mb-3"
            style={{
              width: "100%",
              height: "70px",
            }}
          />
        ) : (
          <div className="d-flex align-items-end mb-3 flex-wrap mx-auto">
            <div className="">
              <FilterComponent
                allowAccessData={allowAccessData}
                updateFilters={(data) => analyzeFilterParamters(data)}
                isRefreshed={isRefreshed}
              />
            </div>
            <SearchTextBox ref={searchRef} changeFilterValue={setSearchValue} />

            <Button
              className="ms-3 border-0 common-btn me-1"
              icon="refresh"
              onClick={handleRefresh}
            >
              Refresh
            </Button>
            <div
              className="ms-auto me-2"
              ref={filterAnchorRef}
              onClick={() => {
                setFiltersPopup(!filtersPopup);
              }}
            >
              {status == "Open" ? (
                <Button
                  className="common-btn"
                  style={{
                    width: "40px",
                    minWidth: "35px",
                    height: "40px",
                    zIndex: "100",
                  }}
                >
                  <FaFilter color="white" size={16} />
                </Button>
              ) : (
                <Button
                  className="common-btn px-2 "
                  style={{
                    width: "40px",
                    minWidth: "35px",
                    height: "40px",
                    zIndex: "100",
                  }}
                >
                  <img
                    src={filtersAppliedIcon}
                    alt="Filters Applied"
                    width={25}
                    height={25}
                  />
                </Button>
              )}
            </div>

            <Button
              className="common-btn "
              onClick={() => setshowMatterForm(true)}
            >
              Add Matter
            </Button>
          </div>
        );

      case "myMattersPage":
        return (
          <div
            className={`d-flex align-items-center ${
              styles[`marginTop`]
            } flex-wrap`}
          >
            <SearchTextBox
              ref={searchRef}
              changeFilterValue={setSearchValue}
              isRefreshed={isRefreshed}
            />
            <div className="ms-3 mb-4">
              {/* <Filters
updateFilters={(e) => {
setFilters(e);
}}
component={"MyMatters"}
isRefreshed={isRefreshed}
filters={matterFilters}
/> */}
            </div>
            <div className="me-2 filter-font">Open</div>
            <div className="mb-4 mt-2">
              <div className="nominal-form-to-parent-row">
                <div className="labels-containers">
                  <Label editorId={"dp"}>From</Label>
                  <div className="label-to">
                    <Label editorId="to">To</Label>
                  </div>
                </div>
              </div>
              <div className="nominal-form-to-row">
                <div className="date-form-to-wrapper">
                  <div className="from">
                    <div className="d-flex align-items-center justify-content-center">
                      <Checkbox
                        value={includeStartDate}
                        className="ms-3 me-1"
                        onChange={(e) => {
                          setIncludeStartDate(e.value);
                        }}
                        label={""}
                      />
                      <DatePicker
                        id="from"
                        className="date-picker"
                        value={fromDateSelector || null}
                        onChange={onChangeFromDateSelect}
                        dateInput={CustomDateInput}
                        toggleButton={CalenderCustomToggleButton}
                        defaultValue={fromDateSelector || null}
                        placeholder="Select to date"
                      />
                    </div>
                  </div>
                  <div className="divider"></div>
                  <div className="to">
                    <div className="d-flex align-items-center justify-content-center">
                      <Checkbox
                        value={includeEndDate}
                        className="ms-3 me-1"
                        onChange={(e) => {
                          setIncludeEndDate(e.value);
                        }}
                        label={""}
                      />
                      <DatePicker
                        id="to"
                        className="date-picker"
                        value={toDateSelector || null}
                        onChange={onChangeToDateSelect}
                        dateInput={CustomDateInput}
                        toggleButton={CalenderCustomToggleButton}
                        defaultValue={toDateSelector || null}
                        placeholder="Select to date"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Button
              className="ms-3 border-0 common-btn"
              icon="refresh"
              onClick={handleRefresh}
            >
              Refresh
            </Button>
          </div>
        );

      default:
        break;
    }
  };

  const handleCellClick = (cellProps) => {
    setSelectedCell(cellProps);
  };

  const handleContextMenu = (e, dataItem) => {
    if (dataItem.dataItem) dataItem = dataItem.dataItem;
    setSelectedCell(dataItem);
    const { status } = dataItem;
    offSet.current = {
      left: e.clientX,
      top: e.clientY,
    };
    setShow(true);
    e.preventDefault();
  };
  const rowRender = (trElement, dataItem) => {
    const trProps = {
      ...trElement.props,
      onContextMenu: (e) => {
        e.preventDefault();
        handleContextMenu(e, dataItem.dataItem);
      },
    };

    return React.cloneElement(
      trElement,
      { ...trProps },
      trElement.props.children
    );
  };

  return (
    <div
      className={`${!fromClient ? "page" : "p-2"} ${
        component === "myMattersPage" && "mt-3"
      } `}
    >
      {getHeaderItems()}
      <Grid
        {...dataState}
        data={fromClient ? Clientdata : list}
        onDataStateChange={dataStateChange}
        className="fs-md matterlist_gridheight"
        sortable={true}
        pageable={!fromClient ? state.pageable : ""}
        pageSize={!fromClient ? state.pageSize : ""}
        resizable={true}
        rowRender={rowRender}
      >
        <GridColumn
          field="matterRef"
          title="Matter Ref"
          width="140px"
          cell={MatterRefCell}
        />

        <GridColumn field="details" title="Details" cell={MatterDetailCell} />

        {/*{!fromClient && (*/}
        {/* <GridColumn*/}
        {/* field="clientRef"*/}
        {/* title="Client Ref"*/}
        {/* className=""*/}
        {/* width="100px"*/}
        {/* />*/}
        {/*)}*/}

        {/*{!fromClient && (*/}
        {/* <GridColumn*/}
        {/* field="clientName"*/}
        {/* title="Client Name"*/}
        {/* className="gridBoldField"*/}
        {/* />*/}
        {/*)}*/}

        <GridColumn field="feeEarnerRef" title="FE" width="50px" />
        <GridColumn field="appCode" title="App" width="50px" />
        <GridColumn
          field="nextReviewDate"
          title="Review Date"
          cell={DateFormatCell}
          width="110px"
        />
        <GridColumn
          field="lastActionDate"
          title="Last Used"
          cell={DateFormatCell}
          width="110px"
        />
        <GridColumn
          title=" "
          width="50px"
          cell={(props) => (
            <MatterOptions
              {...props}
              handleOptionClick={handleOptionClick}
              handleCellClick={handleCellClick}
              selectedCell={selectedCell}
              setShow={setShow}
              show={show}
            />
          )}
        />

        <GridColumn field="" title="" width="10px" />
      </Grid>
      <Popup show={show} offset={offSet.current}>
        <div tabIndex={-1} ref={menuWrapperRef}>
          <MatterOptionsList
            handleOptionClick={handleOptionClick}
            handleCellClick={handleCellClick}
            selectedCell={selectedCell}
          />
        </div>
      </Popup>

      <Popup
        show={filtersPopup}
        anchor={filterAnchorRef.current}
        popupClass={`rounded-3 p-2`}
        anchorAlign={{
          horizontal: "right",
          vertical: "bottom",
        }}
        popupAlign={{
          horizontal: "right",
          vertical: "top",
        }}
      >
        <div className="d-flex align-items-start flex-column p-2 mx-2">
          <Label className="mt-2">Set Filters</Label>
          <hr className="w-100 mt-2 " />
          <div className="mb-3">
            <div className="mb-2">
              <Checkbox
                label={"All"}
                checked={status === ""}
                onChange={() => handleCheckboxChange("")}
              />
            </div>
            <div className="mb-2">
              <Checkbox
                label={"Open"}
                checked={status === "Open"}
                onChange={() => handleCheckboxChange("Open")}
              />
            </div>
            <div className="mb-2">
              <Checkbox
                label={"Closed"}
                checked={status === "Closed"}
                onChange={() => handleCheckboxChange("Closed")}
              />
            </div>
            <div className="mb-2">
              <Checkbox
                label={"Onboarding"}
                checked={status === "Onboarding"}
                onChange={() => handleCheckboxChange("Onboarding")}
              />
            </div>
          </div>
          <div className="d-flex justify-content-between w-100">
            <Button className="common-btn me-2" onClick={handleReset}>
              Reset
            </Button>
            <Button
              className="common-btn bgBtnSecondary"
              onClick={() => setFiltersPopup(false)}
            >
              Close
            </Button>
          </div>
        </div>
      </Popup>

      <HandleGridBody
        dataState={dataState}
        refresh={isRefreshed}
        onDataReceived={dataReceived}
        searchValue={searchValue}
        filterParameters={filterParameters}
        currentComponent={component}
        status={status}
      />
      {isLoading && !fromClient && <LoadingScreen />}

      {chitRequestModal && (
        <ChitRequest
          cancelEdit={() => {
            setChitRequestModal(false);
            dispatch(clearMattersBalances("Chit Request"));
          }}
          matter={selectedMatter}
          component={"modal"}
        />
      )}

      {timeRecordingModal && (
        <TimeRecordingHelper
          cancelEdit={() => {
            setTimeRecordingModal(false);
            dispatch(clearMattersBalances("Time Recording"));
          }}
          matter={selectedMatter}
        />
      )}

      {showMatterForm && (
        <MatterForm
          isView={reference !== undefined}
          reference={reference}
          onMatterRefClickActions={onMatterRefClickActions}
          setshowMatterForm={() => {
            setshowMatterForm(false);
            setReference(undefined);
          }}
        />
      )}
      {matterLedger && (
        <Dialog
          title={`Matter Ledger - ${matterLedger?.matterRef}`}
          onClose={() => selectMatterLedger()}
          width={"65vw"}
        >
          <MatterLedger component={"Matter"} />
        </Dialog>
      )}
    </div>
  );
};
export default MyMattersPageComponent;
