import React from "react";
import { Label } from "@progress/kendo-react-labels";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import CalenderCustomToggleButton from "components/common/CalenderCustomToogleButton";
import { Field } from "@progress/kendo-react-form";
import { CustomDateInput } from "./CustomDateInput";

const CustomDatePicker = (props) => {
  const style = {
    width: props.width,
    maxWidth: "400px",
    height: "44px",
    marginRight: props.mr || "14px",
  };
  return (
    <div
      className={`${props.className} d-flex flex-column `}
      style={{
        marginRight: props.mr || "14px",
        width: props.width || "255px",
        height: "32px",
      }}
    >
      {/* <Label className={`label`}>{props.label}</Label> */}
      {props.controlled ? (
        <DatePicker
          {...props}
          toggleButton={CalenderCustomToggleButton}
          dateInput={CustomDateInput}
          disabled={props.disabled}
          style={{ ...style }}
          value={props.value || new Date()}
          onChange={(e) => props.onChange(e)}
        />
      ) : (
        <Field
          {...props}
          name={props.name}
          toggleButton={CalenderCustomToggleButton}
          disabled={props.disabled}
          component={DatePicker}
          style={{ ...style }}
        />
      )}
    </div>
  );
};
export default CustomDatePicker;
