import * as React from "react";
import {
  ActiveCell,
  AllowedDocumentCell,
  AllowedTypeCell,
  SignatureCell,
} from "../GridComponents";
import { ActivityLoader } from "./ActvivtyLoader";
import EditActivity from "./EditActivity";
import { editActivity } from "actions/activity.action";
import List from "components/Setting/List";

const ActivitiesList = () => {
  return (
    <List
      editAction={editActivity}
      editFormComponent={EditActivity}
      loaderComponent={ActivityLoader}
      columns={[
        { field: "symbol", title: " ", cell: SignatureCell, width: "80" },
        { field: "code", title: "Code" },
        { field: "name", title: "Name" },
        {
          cell: AllowedTypeCell,
          title: "Time",
          field: "allowTime",
          width: "80",
        },
        {
          cell: AllowedDocumentCell,
          title: "Documents",
          field: "allowedDocument",
          width: "80",
        },
        { cell: ActiveCell, title: "Active", field: "active", width: "80" },
      ]}
    />
  );
};

export default ActivitiesList;
