import * as React from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Input } from "@progress/kendo-react-inputs";
import { Label } from "@progress/kendo-react-labels";
import { useState } from "react";
import { toast } from "react-toastify";
import {
  addDocumentTemplate,
  editDocumentTemplate,
  getWorkFlowTemplatesById,
} from "api";
import { Loader } from "@progress/kendo-react-indicators";
import { FileDocumentTypes } from "components/Enums/FileDocumentTypes.ts";
import { useUploadForm } from "components/fileUploadHook";
import { onFileUpload } from "Services/fileDataService";
import { BiLoaderCircle } from "react-icons/bi";
import { CrossIcon } from "components/tabs/TabTitle";
import fileIcon from "components/common/fileIcon";
import { FaUpload } from "react-icons/fa";

const AddEditDocTemplate = (props) => {
  const [loading, setLoading] = useState(false);
  const [fileLoading, setFileLoading] = useState(false);
  const [templateName, setTemplateName] = useState(
    props?.isEdit && props.component !== "WORK_FLOW"
      ? { filename: props?.isEdit?.templateName }
      : ""
  );
  const fileInputRef = React.useRef(null); // Ref for the hidden file input
  const { uploadForm } = useUploadForm();
  const handleFilePick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click(); // Trigger click on hidden file input
    }
  };

  const handleFileChange = async (e) => {
    try {
      setFileLoading(true);
      let data = onFileUpload(e);
      console.log(props?.matterId);
      if (data) {
        const parameters = {
          documentType: FileDocumentTypes.CaseFile,
          matterId: props?.matterId,
        };
        const dataChoosen = await uploadForm(
          data,
          e.target.files[0],
          parameters
        );
        setTemplateName(dataChoosen?.data?.data);
        setFileLoading(false);
      }
    } catch (error) {
    } finally {
      setFileLoading(false);
    }
  };
  const [name, setName] = useState(props?.isEdit ? props?.isEdit.name : "");
  const [details, setDetails] = useState(
    props?.isEdit ? props?.isEdit.details : ""
  );
  const [processingFileName, setProcessingFileName] = useState(
    props?.isEdit ? props?.isEdit.processingFileName : ""
  );
  const [resultFileName, setFileName] = useState(
    props?.isEdit ? props?.isEdit.resultFileName : ""
  );

  const getWorkFlowData = async (id) => {
    setLoading(true); // Set loading to true when the fetch starts
    try {
      const data = await getWorkFlowTemplatesById(id);
      setName(data?.data?.data?.name);
      setDetails(data?.data?.data?.details);
      setProcessingFileName(data?.data?.data?.processingFileName);
      setFileName(data?.data?.data?.resultFileName);
      if (data?.data?.data?.templateName) {
        setTemplateName({
          filename: data?.data?.data?.templateName,
        });
      }
    } catch (error) {
      toast.error("Failed to fetch Doc Template data.");
    } finally {
      setLoading(false); // Set loading to false when the fetch is done
    }
  };

  React.useEffect(() => {
    if (props.component === "WORK_FLOW") {
      getWorkFlowData(props.isEdit);
    }
  }, []);

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const folderData = {
        fileId: templateName?.fileID,
        processingFileName,
        resultFileName,
        active: true,
        name,
        details,
        documentTemplateId: props.isEdit
          ? props.isEdit.documentTemplateId || props.isEdit
          : undefined,
      };

      if (props.isEdit) {
        await editDocumentTemplate(
          props.isEdit.documentTemplateId || props.isEdit,
          {
            ...props.isEdit,
            ...folderData,
          }
        );
        props.updateTemplates(folderData);
      } else {
        await addDocumentTemplate(folderData);
        props.updateTemplates();
      }
    } catch (error) {
      console.log(error);
      toast.error("Unexpected Error Occurred");
    }
  };
  const DocumentItem = ({ doc, setFiles }) => (
    <div className={`d-flex align-items-center justify-content-between w-100 `}>
      <div className="d-flex align-items-center">
        <div>{fileIcon(doc?.filename)}</div>
        <div>
          <span className="document-name-container ps-2 fs-sm ">
            {doc?.filename && doc?.filename?.length <= 20
              ? doc?.filename
              : doc?.filename.substring(
                  0,
                  20 -
                    (doc?.filename.includes(".")
                      ? doc?.filename.split(".").pop()?.length + 1
                      : 0)
                ) +
                (doc?.filename.includes(".")
                  ? "." + doc?.filename.split(".").pop()
                  : "")}
          </span>
        </div>
      </div>
      <div>
        <CrossIcon onClick={(e) => setFiles(e)} />
      </div>
    </div>
  );

  return (
    <Dialog
      title={`${props?.isEdit ? "Edit" : "Add"} Document Template`}
      onClose={props.cancelEdit}
      width={550}
      className="p-3"
    >
      <form onSubmit={handleSubmit}>
        <div
          className={`p-2 m-4 mt-0 ms-5 me-5`}
          style={{
            height: 350,
          }}
        >
          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%", // Ensure loader takes the full height of the dialog
              }}
            >
              <Loader size="large" type="infinite-spinner" />{" "}
              {/* Centered loader */}
            </div>
          ) : (
            <div>
              <div className="mt-3">
                <Label className="label mb-1 w-100">Name&nbsp;</Label>
                <Input
                  className="px-3 pt-1"
                  style={{
                    width: "320px",
                    height: "32px",
                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.05)",
                  }}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                  value={name}
                  required
                  placeholder={"Enter Name"}
                  name="name"
                  type="text"
                />
              </div>{" "}
              <div className="mt-3">
                <Label className="label mb-1 w-100">Details&nbsp;</Label>
                <Input
                  className="px-3 pt-1"
                  style={{
                    width: "320px",
                    height: "32px",
                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.05)",
                  }}
                  onChange={(e) => {
                    setDetails(e.target.value);
                  }}
                  value={details}
                  required
                  placeholder={"Enter Details"}
                  name="details"
                  type="text"
                />
              </div>
              <div className="mt-3">
                <Label className="label mb-1 w-100">
                  Document Processor&nbsp;
                </Label>
                <Input
                  className="px-3 pt-1"
                  style={{
                    width: "320px",
                    height: "32px",
                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.05)",
                  }}
                  onChange={(e) => {
                    setProcessingFileName(e.target.value);
                  }}
                  value={processingFileName}
                  required
                  placeholder={"Processing File Name"}
                  name="processingFileName"
                  type="text"
                />
              </div>
              <div className="mt-3">
                <Label className="label mb-1 w-100">Template File&nbsp;</Label>
                <div
                  className="px-3 text-center d-flex align-items-center justify-content-center"
                  style={{
                    width: "320px",
                    height: "32px",
                    border: "1px solid #ccc",
                    borderRadius: "5px",
                    backgroundColor: "#F8F8F8",
                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.05)",
                    cursor: "pointer",
                  }}
                  onClick={handleFilePick} // On click, trigger the file picker
                >
                  {templateName ? (
                    <DocumentItem
                      doc={templateName}
                      setFiles={(e) => {
                        e.stopPropagation();
                        setTemplateName();
                      }}
                    />
                  ) : fileLoading ? (
                    <BiLoaderCircle />
                  ) : (
                    <div className="d-flex gap-2 align-items-center justify-content-center">
                      <FaUpload color={"gray"} />
                      <div
                        style={{
                          fontSize: "13px",
                        }}
                      >
                        {" "}
                        Upload File
                      </div>
                    </div>
                  )}
                </div>
                <input
                  type="file"
                  ref={fileInputRef} // Reference for the hidden input
                  style={{ display: "none" }} // Hide the input
                  onChange={handleFileChange} // Handle file selection
                  accept=".doc,.docx,.pdf" // Customize allowed file types if needed
                />
              </div>
              <div className="mt-3">
                <Label className="label mb-1 w-100">
                  Output File Name&nbsp;
                </Label>
                <Input
                  className="px-3 pt-1"
                  style={{
                    width: "320px",
                    height: "32px",
                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.05)",
                  }}
                  placeholder={"File Name"}
                  name="resultFileName"
                  required
                  onChange={(e) => {
                    setFileName(e.target.value);
                  }}
                  value={resultFileName}
                  type="text"
                />
              </div>
            </div>
          )}
        </div>
        <div className="dialog-footer">
          <button className="common-btn me-4">{`${
            props?.isEdit ? "Update" : "Add"
          }`}</button>
        </div>
      </form>
    </Dialog>
  );
};

export default AddEditDocTemplate;
