import React from "react";
import { Input } from "@progress/kendo-react-inputs";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { TextArea } from "@progress/kendo-react-inputs";
import { NumericTextBox } from "@progress/kendo-react-inputs";
import { Checkbox } from "@progress/kendo-react-inputs";
import { RadioGroup } from "@progress/kendo-react-inputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { TimePicker } from "@progress/kendo-react-dateinputs";
import { DateTimePicker } from "@progress/kendo-react-dateinputs";

const FieldType = ({
    field = { dataType: "Text" },
    userScreenFieldId = "",
    formData = {},
    onInputChange = () => { },
    fromScreenDesigner = false,
}) => {
    const fieldValue = fromScreenDesigner
        ? null
        : formData[userScreenFieldId]?.[field.userScreenFieldId] || "";

    const handleChange = onInputChange
        ? (e) =>
            onInputChange(
                userScreenFieldId,
                field.userScreenFieldId,
                e.target.value
            )
        : () => { };



    let parsedChoices = [];
    try {
        parsedChoices = JSON.parse(field.choices || "[]");
    }
    catch (error) {
        console.error("Error parsing JSON:", error);
    }

    switch (field.dataType) {
        case "Text":
            return (
                <Input
                    required={field.required}
                    style={{
                        maxWidth:
                            field.length && field.length > 0 ? field.length + "px" : "100%",
                    }}
                    placeholder={field.hint}
                    value={fieldValue}
                    onChange={handleChange}
                />
            );
        case "Combobox":
            return (
                <ComboBox
                    required={field.required}
                    style={{ maxWidth: "100%" }}
                    data={parsedChoices}
                    value={fieldValue}
                    placeholder={field.hint}
                    onChange={handleChange}
                />
            );
        case "Date":
            return (
                <DatePicker
                    required={field.required}
                    format="dd/MM/yyyy"
                    style={{ maxWidth: "100%" }}
                    placeholder={field.hint}
                    value={fieldValue ? new Date(fieldValue) : null}
                    onChange={handleChange}
                />
            );
        case "Dropdown":
            return (
                <DropDownList
                    className={`dropdown`}
                    style={{ maxWidth: "100%" }}
                    iconClassName="wa"
                    value={fieldValue}
                    placeholder={field.hint}
                    data={parsedChoices}
                />
            );
        case "CheckBox":
            return (
                <div>
                    {(field?.choices ? JSON.parse(field.choices) : [" "]).map(
                        (choice, index) => (
                            <Checkbox
                                key={index}
                                label={choice}
                                value={choice}
                                onChange={handleChange}
                            />
                        )
                    )}
                </div>
            );
        case "Radio":
            const transformChoicesForRadio = (choices) => {
                return Object.entries(choices[0]).map(([label, value]) => ({
                    label: label,
                    value: value,
                }));
            };

            const transformedChoices = field.choices
                ? transformChoicesForRadio(JSON.parse(field.choices))
                : [" "];

            return (
                <RadioGroup
                    data={transformedChoices}
                    value={fieldValue}
                    onChange={handleChange}
                />
            );
        case "MultilineText":
            return (
                <TextArea
                    required={field.required}
                    style={{ height: "7vh", maxWidth: "100%" }}
                    autoSize={true}
                    placeholder={field.hint}
                    value={fieldValue}
                    onChange={handleChange}
                />
            );
        case "Integer":
            return (
                <NumericTextBox
                    required={field.required}
                    spinners={false}
                    style={{ maxWidth: "100%" }}
                    placeholder={field.hint}
                    value={fieldValue}
                    onChange={handleChange}
                />
            );

        case "Decimal":
            return (
                <NumericTextBox
                    required={field.required}
                    spinners={false}
                    format="n2"
                    style={{ maxWidth: "100%" }}
                    placeholder={field.hint}
                    value={fieldValue && parseFloat(fieldValue)}
                    onChange={handleChange}
                />
            );

        case "Time":
            return (
                <TimePicker
                    required={field.required}
                    placeholder={field.hint}
                    value={fieldValue ? new Date(fieldValue) : null}
                    style={{ maxWidth: "100%" }}
                    onChange={handleChange}
                />
            );
        case "DateTime":
            return (
                <DateTimePicker
                    required={field.required}
                    style={{ maxWidth: "100%" }}
                    placeholder={field.hint}
                    value={fieldValue ? new Date(fieldValue) : null}
                    onChange={handleChange}
                />
            );

        default:
            return null;
    }
};

export default FieldType;
