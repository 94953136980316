import * as React from "react";
import { MaskedTextBox, Input } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import { Label } from "@progress/kendo-react-labels";
import { useSelector, useDispatch } from "react-redux";
import { useRef, useState, useEffect } from "react";
import { getUserClaims } from "actions/currentuser.action";
import { updateCompany } from "actions/company.action";
import { createCompany } from "actions/company.action";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { Checkbox } from "@progress/kendo-react-inputs";
import { TextArea } from "@progress/kendo-react-inputs";
import { toast } from "react-toastify";
import * as api from "../../../api/index";
import styles from "../Settings.module.scss";
import CalenderCustomToggleButton from "components/common/CalenderCustomToogleButton";
import HMRCAuthentication from "./HMRCAuthentication";

const weekData = [
  { key: "Monday", value: 1 },
  { key: "Tuesday", value: 2 },
  { key: "Wednesday", value: 3 },
  { key: "Thursday", value: 4 },
  { key: "Friday", value: 5 },
  { key: "Saturday", value: 6 },
  { key: "Sunday", value: 7 },
];

const workingDaysData = [
  { key: "Mon", value: 1, isChecked: false, label: "Monday" },
  { key: "Tues", value: 2, isChecked: false, label: "Tuesday" },
  { key: "Wed", value: 4, isChecked: false, label: "Wednesday" },
  { key: "Thurs", value: 8, isChecked: false, label: "Thursday" },
  { key: "Fri", value: 16, isChecked: false, label: "Friday" },
  { key: "Sat", value: 32, isChecked: false, label: "Saturday" },
  { key: "Sun", value: 64, isChecked: false, label: "Sunday" },
];

const CompanyAccounting = (props) => {
  const [modalvalue, setmodalvalue] = useState(false);
  const [isError, setIsError] = useState(true);
  const [sub, setSub] = useState("");
  const [vatCompanyName, setVatCompanyName] = useState();
  const subData = useSelector((state) => state.currentuser.userSub);
  const [vatScheme, setVatScheme] = useState();
  const [vatPeriod, setVatPeriod] = useState();
  const noContentSetup = useSelector((state) => state.company.noContent);
  const [weekStartDay, setWeekStartDay] = useState("");
  const [workingDays, setWorkingDays] = useState(workingDaysData);

  useEffect(() => {
    if (
      props.component === "Wizard" &&
      subData !== "" &&
      subData !== undefined
    ) {
      setTimeout(() => {
        setSub(subData);
      }, 100);
    }
  }, [subData]);
  const dispatch = useDispatch();

  const subdata = useSelector((state) => {
    return state.company.company;
  });
  const [data, setData] = useState({});
  useEffect(() => {
    if (subdata !== undefined) {
      const defaultWD = subdata?.workingDays?.split(",");
      const cleanData = defaultWD?.map((t) => t.trim());
      const finalWD = workingDays?.map((d) => {
        if (cleanData.includes(d.label)) {
          return {
            ...d,
            isChecked: true,
          };
        }
        return d;
      });
      setWorkingDays([...finalWD]);
      setData(subdata);
      setWeekStartDay(subdata.weekStartDay);
    }
  }, [subdata]);

  useEffect(() => {
    if (props.component === "Wizard") {
      dispatch(getUserClaims());
    }
  }, []);

  let SERVICE_DATA = {
    line1: data?.addressLine1?.toString(),
    town: data?.town?.toString(),
    state: data?.state?.toString(),
    postcode: data?.postcode?.toString(),
    country: data?.country?.toString(),
    name: data?.displayName?.toString(),
    phone: data?.phone?.toString(),
    email: data?.email?.toString(),
    website: data?.website?.toString(),
    baseecn: data?.baseCurrency?.toString(),
  };

  const [serviceAddress, setServiceAddress] = useState(SERVICE_DATA);
  const submithandler = (e) => {
    setmodalvalue(false);
    const selWD = [];
    workingDays?.forEach((day) => {
      if (day.isChecked) {
        selWD.push(day.label);
      }
    });
    const datasent = {
      baseCurrency: serviceAddress.baseCurrency,
      yearStartDate,
    };

    if (noContentSetup === true) {
      dispatch(createCompany(subData.TenantID, subData.CD, datasent));
      props.moveNext();
    } else {
      dispatch(updateCompany(data.id, datasent));
      if (props.component === "Wizard") {
        props.moveNext();
      } else {
        toast.success("Company Updated");
      }
    }
  };
  const [yearStartDate, setYearStartDate] = useState(new Date());

  const onChangeServiceAddress = async (e) => {
    const name = e.target.name;
    const datatopost = {};
    if (name === "yearStartDate") {
      setYearStartDate(e.value);
      props.updateAddress({ yearStartDate: e.value });
      return;
    }
    datatopost[name] = e.target.value;
    data[name] = e.target.value;
    props.updateAddress(datatopost);
    setServiceAddress({ ...data });
    if (name === "vatNo") {
      props.updateAddress({ vatNo: e.value });
    }
  };
  const vatSchemeData = [
    { key: "Accrual Scheme", value: "Accrual" },
    { key: "Cash Scheme", value: "Cash" },
  ];
  const vatPeriodData = [
    { key: "Monthly", value: "Monthly" },
    { key: "Quarterly", value: "Quarterly" },
  ];

  const checkVATNo = async () => {
    if (serviceAddress?.vatNo?.length >= 9) {
      try {
        const data = await api.checkVatReturnNo(serviceAddress?.vatNo);
        setVatCompanyName(data?.data?.target?.name);
        setIsError(false);
      } catch (e) {
        setIsError(true);
      }
    } else {
      setIsError(true);
    }
  };
  const vatSchemeReturner = (value) => {
    return vatSchemeData.filter((item) => item.value === value);
  };
  const vatPeriodReturner = (value) => {
    return vatPeriodData.filter((item) => item.value === value);
  };
  useEffect(() => {
    checkVATNo();
  }, [serviceAddress]);
  useEffect(() => {
    setServiceAddress({
      line1: data?.addressLine1?.toString(),
      town: data?.town?.toString(),
      state: data?.state?.toString(),
      postcode: data?.postcode?.toString(),
      country: data?.country?.toString(),
      displayname: data?.displayName?.toString(),
      phone: data?.phone1?.toString(),
      email: data?.email?.toString(),
      website: data?.website?.toString(),
      baseCurrency: data?.baseCurrency?.toString(),
      vatNo: data?.vatNo,
    });
    setYearStartDate(new Date(data?.yearStartDate));
    setVatScheme(vatSchemeReturner(data?.vatScheme)[0]);
    setVatPeriod(vatPeriodReturner(data?.vatPeriod)[0]);
  }, [data]);
  return (
    <React.Fragment>
      <form onSubmit={submithandler} className={`${styles[`company`]}`}>
        <div>
          <div className="row mt-4">
            <div className="col-4 d-flex flex-column">
              <Label className="label">Base Currency</Label>
              <Input
                style={{ width: "255px", maxWidth: "400px", height: "44px" }}
                placeholder="Enter Detail"
                className="mt-1"
                name="baseCurrency"
                value={serviceAddress.baseCurrency}
                onChange={(e) => {
                  onChangeServiceAddress(e);
                }}
              />
            </div>
          </div>
        </div>
        <div className="col-3 d-flex flex-column mt-4">
          <Label className="label" style={{ width: "200px" }}>
            Financial Year Start Date
          </Label>
          <DatePicker
            style={{ width: "255px", maxWidth: "400px", height: "44px" }}
            className="datepicker mt-1"
            toggleButton={CalenderCustomToggleButton}
            value={yearStartDate}
            name="yearStartDate"
            onChange={(e) => {
              onChangeServiceAddress(e);
            }}
          />
        </div>
        <div className="row mt-4">
          <div className="d-flex align-items-center">
            <div className="d-flex flex-column me-4">
              <Label className="label">VAT Registration No</Label>
              <div className="d-flex">
                <Input
                  style={{ width: "255px", maxWidth: "400px", height: "44px" }}
                  placeholder="Enter No"
                  className="mt-1"
                  name="vatNo"
                  value={serviceAddress.vatNo}
                  onChange={(e) => {
                    onChangeServiceAddress(e);
                  }}
                />
                {!isError && (
                  <div className={`mt-3 ${styles[`vat-checkbox`]}`}>
                    <Checkbox defaultChecked value={true} />
                  </div>
                )}
              </div>
            </div>
            <div className="mt-4">
              <HMRCAuthentication />
            </div>
          </div>
          {/* {!isError &&
                        <div className={`${styles[`vat-company-data`]} mt-3 ms-2`}>
                            <p className={`fs-sm mt-3 ${styles[`company-name`]}`}>Company : </p>
                            <p className={`fs-sm mt-2`}>{vatCompanyName}</p>
                        </div>
                    } */}
        </div>
        <div className="row mt-4">
          <div className="d-flex flex-column">
            <Label className="label">VAT Scheme</Label>
            <ComboBox
              placeholder="Select VAT Scheme"
              style={{ width: "255px", maxWidth: "400px", height: "44px" }}
              iconClassName="wa"
              className="mt-1"
              clearButton={false}
              textField="key"
              value={vatScheme}
              data={vatSchemeData}
              onChange={({ value }) => {
                setVatScheme(value);
                props.updateAddress({ vatScheme: value?.value });
              }}
            />
          </div>
        </div>
        <div className="row mt-4">
          <div className="d-flex flex-column">
            <Label className="label">VAT Period</Label>
            <ComboBox
              placeholder="Select VAT Period"
              style={{ width: "255px", maxWidth: "400px", height: "44px" }}
              iconClassName="wa"
              className="mt-1"
              clearButton={false}
              textField="key"
              value={vatPeriod}
              data={vatPeriodData}
              onChange={({ value }) => {
                setVatPeriod(value);
                props.updateAddress({ vatPeriod: value?.value });
              }}
            />
          </div>
        </div>
      </form>
    </React.Fragment>
  );
};
export default CompanyAccounting;
