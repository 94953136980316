import React, { useEffect } from "react";
import { Tile } from "components/UIComponents/Tile";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import styles from "./DocumentRegister.module.scss";
import { getClientDocs, getMatterDocs } from "api";
import { DateFormatCell } from "components/common/formatter";
import { Button } from "@progress/kendo-react-buttons";
import { process } from "@progress/kendo-data-query";
import { useState } from "react";
import DocumentRegisterModal from "./DocumentRegisterModal";

export const DocRegisterListTile = ({ matterId, clientId }) => {
    const [list, setLists] = React.useState({ data: [], total: 50 });
    const [showDocRegister, setShowDocRegister] = useState(false);
    const [viewId, setViewId] = useState();

    const getMatterDocsData = async () => {
        try {
            const data = await getMatterDocs(matterId?.matterId);
            setLists({ data: data?.data?.data, total: data?.data?.total });
        } catch (error) { }
    };

    const getClientDocsData = async () => {
        try {
            const data = await getClientDocs(clientId?.clientId);
            setLists({ data: data?.data?.data, total: data?.data?.total });
        } catch (error) { }
    };

    const [dataState, setDataState] = React.useState({
        take: 100,
        skip: 0,
        sort: [
            {
                field: "documentDate",
                dir: "asc",
            },
        ],
    });

    const ViewDocs = (props) => {
        return (
            <td>
                <Button
                    onClick={() => {
                        setViewId(props.dataItem.documentRegisterID);
                        setShowDocRegister(true);
                    }}
                    className="button-small "
                >
                    View
                </Button>
            </td>
        );
    };

    const dataStateChange = (e) => {

        if (
            e.dataState.sort !== undefined &&
            e.dataState.skip !== 0 &&
            dataState.sort !== e.dataState.sort
        ) {
            setDataState({ ...e.dataState, skip: 0 });
        } else if (e.dataState?.sort?.[0] === undefined) {
            setDataState({
                ...e.dataState,
                sort: [
                    {
                        field: dataState?.sort?.[0].field,
                        dir: "asc",
                    },
                ],
            });
        } else {
            setDataState(e.dataState);
        }
    };

    useEffect(() => {
        if (clientId) {
            getClientDocsData();
        } else if (matterId) {
            getMatterDocsData();
        }
    }, []);

    return (
        <div className="container mt-4">
            <Tile
                heading="Document Register"
                className="py-2 mb-1 px-4  overflow-auto"
                height={"580px"}
                headingItems={
                    <div>
                        <Button
                            className="button-small bgBtnSecondary fw-bold me-1"
                            onClick={() => setShowDocRegister(true)}
                        >
                            Add Document Register
                        </Button>
                    </div>
                }
            >
                <Grid
                    data={process(list.data, dataState)}
                    onDataStateChange={dataStateChange}
                    className={`fs-md pb-1 ${styles.gridheight}`}
                    sortable={true}
                    resizable={true}
                    {...dataState}
                >
                    <GridColumn
                        field="documentDate"
                        title="Doc Date"
                        width="120px"
                        cell={DateFormatCell}
                        className="ps-3"
                        headerClassName="ps-3"
                    />
                    <GridColumn
                        field="reference"
                        title="Doc Ref"
                        width="140px"
                        className="gridBoldField"
                    />
                    <GridColumn field="typeName" title="Type" width="120px" />
                    <GridColumn field="location" title="Location" width="150px" />
                    <GridColumn field="address" title="Address" />
                    <GridColumn
                        field="reviewDate"
                        title="Review Date"
                        width="120px"
                        cell={DateFormatCell}
                    />
                    <GridColumn
                        field="destroyDate"
                        title="Destroy Date"
                        width="120px"
                        cell={DateFormatCell}
                    />
                    <GridColumn field="" title="  " width="100px" cell={ViewDocs} />
                </Grid>
            </Tile>

            {showDocRegister && (
                <DocumentRegisterModal
                    setshowMatterForm={() => {
                        setViewId(null);
                        setShowDocRegister(false);
                    }}
                    docId={viewId}
                    matterId={matterId}
                    updateData={() => {
                        setViewId(null);
                        setShowDocRegister(false);
                        if (clientId) {
                            getClientDocsData();
                        } else {
                            getMatterDocsData();
                        }
                    }}
                    clientId={clientId}
                />
            )}
        </div>
    );
};
