import * as React from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Input } from "@progress/kendo-react-inputs";
import { Label } from "@progress/kendo-react-labels";
import { useState } from "react";
import { toast } from "react-toastify";

const AddFolder = (props) => {
  const [folderName, setFolderName] = useState(
    props?.isEdit ? props?.isEdit?.name : null
  );
  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      if (props.isEdit) {
        props.updateFolder({
          ...props.isEdit,
          name: folderName,
        });
      } else {
        props.addFolder(folderName);
      }
    } catch (error) {
      console.log(error);
      toast.error("Unexpected Error Occured");
    }
  };

  return (
    <Dialog
      title={`${props?.isEdit ? "Edit" : "Add"} Folder`}
      onClose={props.cancelEdit}
      width={390}
      className="p-3"
    >
      <form onSubmit={handleSubmit}>
        <div
          className={`p-2 m-4 mt-0 ms-5 me-5`}
          style={{
            height: 260,
          }}
        >
          <div className="mt-3">
            <Label className="label mb-1 w-100">Folder Name&nbsp;</Label>
            <Input
              className="px-3 pt-1"
              style={{
                width: "260px",
                height: "32px",
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.05)",
              }}
              placeholder={"Add Folder"}
              name="folderName"
              required
              onChange={(e) => {
                setFolderName(e.value);
              }}
              value={folderName}
              type="text"
            />
          </div>
        </div>
        <div className="dialog-footer">
          <button className="common-btn me-4">{`${props?.isEdit ? "Update" : "Add"}`}</button>
        </div>
      </form>
    </Dialog>
  );
};

export default AddFolder;
