import { useEffect } from "react";
import { useDispatch } from "react-redux";

export const useDashboardData = (filters, apiFunction) => {
  const dispatch = useDispatch();
  const dataFetcher = () => {
    const today = new Date();
    const dayOfWeek = today.getDay();
    const startOfWeek = new Date(today);
    startOfWeek.setDate(
      today.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1)
    );
    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(startOfWeek.getDate() + 6);

    try {
      dispatch(
        apiFunction(
          filters?.teamId,
          filters?.feeEarnerRef,
          filters?.branch,
          filters?.FromDate || startOfWeek.toISOString().split("T")[0],
          filters?.ToDate || endOfWeek.toISOString().split("T")[0]
        )
      );
    } catch (error) {
      // Handle error
    }
  };

  useEffect(() => {
    if (filters) {
      dataFetcher();
    }
  }, [filters]);
};

export const graphUpdater = (data, initialSeries, setCategories, setSeries) => {
  let categories = [];
  let series = initialSeries.map((s) => ({ ...s, data: [] }));
  data?.forEach((item) => {
    categories.push(item.key);
    series[0]?.data?.push(
      item.actual !== undefined ? item.actual : item.mattersOpened
    );
    series[1]?.data?.push(
      item.target !== undefined ? item.target : item.mattersClosed
    );
  });

  setCategories(categories);
  setSeries(series);
};
