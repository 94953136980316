import * as React from "react";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { Button } from "@progress/kendo-react-buttons";
import { editCurrencyinfo } from "actions/currency.action";
import { useDispatch } from "react-redux";
import { ActiveCell } from "../GridComponents";
import { EditCommandCell, SignatureCell } from "../GridComponents";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { editApplication, getApplications } from "actions/application.action";
import { editDocType, getDocTypes } from "actions/docTypes.action";
import EditDocType from "./EditDocTypes";

const DocTypeList = () => {
  const [openForm, setOpenForm] = React.useState(false);
  const [data, setData] = React.useState([]);
  const dispatch = useDispatch();
  const [editItem, setEditItem] = React.useState({
    ProductID: 1,
  });
  const enterEdit = (item) => {
    setOpenForm(true);
    setEditItem(item);
  };
  const handleSubmit = (event) => {
    let data = {
      code: event.code,
      typeName: event.typeName,
      active: event.active,
    };
    dispatch(editDocType(event.docRegisterTypeID, data));
    setOpenForm(false);
  };
  const docTypeList = useSelector((state) => state.docTypes?.docTypeList);
  useEffect(() => {
    if (docTypeList !== null && docTypeList !== undefined) {
      docTypeList?.data.map((item) => {
        return (item.symbol = item?.code[0]);
      });
      setData(docTypeList);
    }
  }, [docTypeList]);

  useEffect(() => {
    dispatch(getDocTypes());
  }, []);
  const [products, setProducts] = React.useState({
    data: [],
    total: 20,
  });
  const [dataState, setDataState] = React.useState({
    take: 10,
    skip: 0,
  });

  const handleCancelEdit = () => {
    setOpenForm(false);
  };
  const MyEditCommandCell = (props) => (
    <EditCommandCell {...props} enterEdit={enterEdit} />
  );
  const dataStateChange = (e) => {
    setDataState(e.dataState);
  };

  const dataReceived = (products) => {
    setProducts(products);
  };

  return (
    <div className="settings-grid">
      <Grid {...dataState} data={data} onDataStateChange={dataStateChange}>
        <Column title=" " field="symbol" cell={SignatureCell} width="80" />
        <Column field="code" title="Code" />
        <Column field="typeName" title="Name" />
        <Column cell={ActiveCell} title="Active" width="80" />
        <Column title=" " cell={MyEditCommandCell} width="80" />
      </Grid>
      {openForm && (
        <EditDocType
          cancelEdit={handleCancelEdit}
          onSubmit={handleSubmit}
          item={editItem}
        />
      )}
    </div>
  );
};

export default DocTypeList;
