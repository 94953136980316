import {
    START_LOADING,
    STOP_LOADING,
    ERROR,
    SUCCESS,
    RESET,
    GET_DEPARTMENT_LIST,
    EDIT_DEPARTMENT_LIST
} from "../types/index.js";

const DepartmentsReducer = (
    state = {
        IsError: false,
        isSuccess: false,
        isLoading: undefined,
    },
    action
) => {
    switch (action.type) {
        case START_LOADING:
            return { ...state, isLoading: true };
        case STOP_LOADING:
            return { ...state, isLoading: false };
        case GET_DEPARTMENT_LIST:
            return {
                ...state,
                departmentsList: action.payload,
            };
        case EDIT_DEPARTMENT_LIST:
            return {
                ...state,
                departmentsList: action.payload,
            };
        case RESET:
            const data = state;
            data.departmentsList = undefined;
            return data;
        case ERROR:
            return { ...state, IsError: true, isSuccess: false };
        case SUCCESS:
            return { ...state, IsError: false, isSuccess: true };
        default:
            return state;
    }
};
export default DepartmentsReducer;
