import * as React from "react";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { AmountFormat } from "components/common/formatter";
import * as api from "api/index";
import styles from "./Matter.module.scss";
import DataList from "components/MatterDetails/DataList";
import { handleApiError } from "Helper";
import {
    ContainerCard,
    PrimaryBadgeComponent,
} from "components/MatterDetails/ContactCard";
import { MatterPermissions } from "./MatterPermissions";

const options = ["Details", "Activities", "Tasks", "Documents", "Ledger Card"];

const MattersCreditsandDefaults = (props) => {
    const matterDetails = useSelector((state) => state.mattersList);
    const [balances, setBalances] = useState({});
    const [accessibleUsers, setAccessibleUsers] = useState([]);
    let locale = useSelector((state) => state.company.company.locale);
    const [currentMatterDetails, setcurrentMatterDetails] = useState();
    useEffect(() => {
        if (matterDetails !== null) {
            setcurrentMatterDetails(
                matterDetails?.matterViewDetails[props.reference]?.data
            );

            setAccessibleUsers(matterDetails?.matterViewDetails[props.reference]?.data?.matterAccess);
        }
    }, []);
    const dataGetter = async () => {
        try {
            const data = await api.getMattersBalances(currentMatterDetails.matterId);
            if (data?.data?.data !== undefined) {
                setBalances(data.data.data);
            }
        } catch (error) {
            handleApiError(error);
        }
    };
    useEffect(() => {
        if (currentMatterDetails !== undefined) {
            dataGetter();
        }
    }, [currentMatterDetails]);




    return (
        <div className={`container-fluid`}>
            <div className="row mt-3 g-2 d-flex">

                <div className="col-12 col-xl-6">

                    <ContainerCard
                        height={"220px"}
                        className="p-3 ps-4 pe-4 pt-2 pb-0 overflow-auto"
                    >
                        <div className="">
                            <div
                                className="d-flex align-items-center justify-content-between"
                                style={{ height: "50px" }}
                            >
                                <div className={`${styles["section-title"]}`}>Credit Limit</div>
                            </div>
                            <hr className="mt-0" />
                            <div>
                                <DataList
                                    text="WIP Credit Limit:"
                                    value={AmountFormat(currentMatterDetails?.wipCreditLimit)}
                                    width={"w-100"}
                                    valueClass={"ms-4"}
                                //   className={'mt-1'}
                                />
                            </div>
                            <div>
                                <DataList
                                    text="Disb Credit Limit:"
                                    value={AmountFormat(currentMatterDetails?.disbCreditLimit)}
                                    width={"w-100"}
                                    valueClass={"ms-4"}
                                //   className={'mt-1'}
                                />
                            </div>
                            <div>
                                <DataList
                                    text="Bill Credit Limit:"
                                    value={AmountFormat(currentMatterDetails?.disbCreditLimit)}
                                    width={"w-100"}
                                    valueClass={"ms-4"}
                                //   className={'mt-1'}
                                />
                            </div>
                        </div>
                    </ContainerCard>
                </div>
                <div className="col-12 col-xl-6">
                    <ContainerCard
                        height={"220px"}
                        className="p-3 ps-4 pe-4 pt-2 pb-0 overflow-auto"
                    >
                        <div className="mx-auto" style={{ width: "90%" }}>
                            <div
                                className="d-flex align-items-center justify-content-between"
                                style={{ height: "50px" }}
                            >
                                <div className={`${styles["section-title"]}`}>
                                    Default Codes
                                </div>
                            </div>
                            <hr className="mt-0" />
                            <div className="mt-3 d-flex gap-5 w-100  w-80 mx-auto justify-content-between">
                                <div className="">
                                    <DataList
                                        text="Default DDA Bank:"
                                        statusChart={true}
                                        // className="mt-3"
                                        valueClass={"ms-4"}
                                        value={currentMatterDetails?.defaultDDABank}
                                        width={"w-100"}
                                        keyStyles={{ minWidth: "230px" }}
                                    />
                                    <DataList
                                        text="Default Profit Cost Nominal:"
                                        statusChart={true}
                                        valueClass={"ms-4"}
                                        // className="mt-3"
                                        value={currentMatterDetails?.defaultDDABank}
                                        width={"w-100"}
                                        keyStyles={{ minWidth: "230px" }}
                                    />
                                </div>
                            </div>
                        </div>
                    </ContainerCard>
                </div>

                <div className="col-12 col-xl-6">
                    <ContainerCard
                        height={"300px"}
                        className="ps-4 pe-4 pt-1 pb-0 overflow-auto"
                    >
                        <MatterPermissions
                            matterId={currentMatterDetails?.matterId}
                            accessibleUsers={accessibleUsers}
                            isView={true}
                            setAccessibleUsers={setAccessibleUsers}
                        />

                    </ContainerCard>
                </div>

            </div>
        </div>
    );
};

export default MattersCreditsandDefaults;
