import * as React from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import Document from "../../assets/fileIcons/Document.svg";

const DocTemplateRunner = () => {
  return (
    <div
      style={{
        backdropFilter: "blur(1px)",
      }}
    >
      <Dialog height={200} width={400}>
        <div className={`d-flex mt-3`}>
          <div className="justifiy-content-center d-flex flex-column align-items-center vw-100">
            <img
              src={Document}
              style={{
                height: "45px",
                width: "45px",
              }}
            ></img>
            <div className="mt-3">
              <div className={`mx-auto mb-2 fs-sm mt-1`}>
                <b>Executing Document Template...</b>
              </div>
              <div
                className={`mx-auto mb-2 fs-sm text-center mt-1`}
                style={{ color: "gray" }}
              >
                <b>Please Wait</b>
              </div>
            </div>
            <div className="spinner-border spinner mt-1" role="status"></div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default DocTemplateRunner;
