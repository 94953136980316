import {
  START_LOADING,
  STOP_LOADING,
  ERROR,
  SUCCESS,
  GET_CHIT_POSTING_LISTS,
  GET_BATCH_POSTING_LISTS,
} from "../types/index.js";

const chitPostingReducer = (
  state = {
    IsError: false,
    isSuccess: false,
    isLoading: undefined,
  },
  action
) => {
  switch (action.type) {
    case START_LOADING:
      return { ...state, isLoading: true };
    case STOP_LOADING:
      return { ...state, isLoading: false };
    case GET_CHIT_POSTING_LISTS:
      return {
        ...state,
        chitPostingList: action.payload,
      };
    case GET_BATCH_POSTING_LISTS:
      return {
        ...state,
        batchPostingList: action.payload,
      };
    case ERROR:
      return { ...state, IsError: true, isSuccess: false };
    case SUCCESS:
      return { ...state, IsError: false, isSuccess: true };
    default:
      return state;
  }
};
export default chitPostingReducer;
