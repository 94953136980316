export const Separator = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="2"
    height="24"
    viewBox="0 0 2 24"
    fill="none"
  >
    <path d="M1 0.5V24" stroke={props.color ? props.color :"#241CCA"} strokeWidth="0.5" />
  </svg>
);
