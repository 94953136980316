import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { DateFormatCell } from "components/common/formatter.js";
import styles from "./Casefile.module.scss";
import { HandleGridBody } from "components/common/HandleGridBody";
import FilterComponent from "components/CaseFiles/FilterComponent";
import { RedirectCell } from "components/common/GridComponents";
import { Popup } from "@progress/kendo-react-popup";
import CaseTaskForm from "./CaseTaskForm";

export default function CaseTasks(props) {
  const date = new Date();

  const [valueFrom, setValueFrom] = useState(false);
  const [valueTo, setValueTo] = useState(false);
  const [taskList, setTaskList] = useState({ data: [], total: 20 });
  const [isRefreshed, setIsRefreshed] = useState(false);
  const [eventDataState, setEventDataState] = useState();

  const { yearStartDate } = useSelector((state) => state.company.company);
  let yearsStartDate = new Date(yearStartDate);
  const [fromDate, setFromDate] = useState(
    yearsStartDate.toISOString().split("T")[0]
  );

  const [toDate, setToDate] = useState(date.toISOString().split("T")[0]);
  const [updateFilter, setUpdateFilter] = useState(false);
  const [dataState, setDataState] = useState({
    take: 50,
    skip: 0,
    sort: [
      {
        field: "date",
        dir: "asc",
      },
    ],
  });

  const [fromDateSelector, setFromDateSelector] = useState(yearsStartDate);
  const [toDateSelector, setToDateSelector] = useState(date);
  const [completed, setCompleted] = useState(false);

  const blurTimeoutRef = useRef(null);

  const [viewTask, setViewTask] = useState();
  const [isView, setView] = useState(false);

  const createState = (skip, take) => {
    let pagerSettings = {
      pageSizes: [10, 25, 50, 100],
    };
    return {
      pageSize: take,
      pageable: pagerSettings,
    };
  };
  const [state, setState] = useState(createState(0, 50));

  const dataStateChange = (e) => {
    const eventObject = { event: e?.dataState };
    setEventDataState(eventObject);
    if (
      e.dataState.sort !== undefined &&
      e.dataState.skip !== 0 &&
      dataState.sort !== e.dataState.sort
    ) {
      setDataState({ ...e.dataState, skip: 0 });
    } else if (e.dataState.sort[0] === undefined) {
      setDataState({
        ...e.dataState,
        sort: [
          {
            field: dataState.sort[0].field,
            dir: "asc",
          },
        ],
      });
    } else {
      setDataState(e.dataState);
    }
    setUpdateFilter(true);
  };

  const dataReceived = (data) => {
    if (data) {
      setTaskList(data);
    }
  };

  const handleRefresh = () => {
    if (eventDataState) {
      setDataState({
        ...eventDataState?.event,
        skip: 0,
      });
    } else {
      setDataState({
        ...dataState,
        take: 50,
        skip: 0,
      });
    }
    setIsRefreshed(!isRefreshed);
    setUpdateFilter(true);
  };

  const Redirect = (prop) => (
    <RedirectCell
      handleClick={() => {
        props?.setAddTask(true);
        setViewTask(prop.dataItem);
        setView(true);
      }}
      title="View Task"
    />
  );

  useEffect(() => {
    if (props.refreshClicked && props.activeItem === 2) {
      handleRefresh();
      props?.setRefreshClicked(false);
    }
  }, [props?.refreshClicked]);

  const onOpen = () => {
    props?.filterContentRef?.current.focus();
  };

  const onFocus = () => {
    clearTimeout(blurTimeoutRef.current);
  };

  const onBlurTimeout = () => {
    props?.setFilterClicked(false);
  };

  const onBlur = () => {
    clearTimeout(blurTimeoutRef.current);
    blurTimeoutRef.current = setTimeout(onBlurTimeout, 200);
  };

  useEffect(() => {
    return () => {
      clearTimeout(blurTimeoutRef.current);
    };
  }, []);

  useEffect(() => {
    if (updateFilter) {
      setUpdateFilter(false);
    }
  }, [updateFilter]);

  const onChangeFromDateSelect = (event) => {
    if (!valueFrom) {
      setValueFrom(true);
    }
    setFromDateSelector(event.value);
    let fromDate = null;
    let date = new Date(event.value);
    if (event.value) {
      fromDate = date.toISOString().split("T")[0];
    }
    setFromDate(fromDate);
  };
  const handleStatusChange = (e) => {
    setCompleted(e.value);
  };

  const onChangeToDateSelect = (event) => {
    if (!valueTo) {
      setValueTo(true);
    }
    setToDateSelector(event.value);
    let toDate = null;
    let date = new Date(event.value);
    if (event.value) {
      toDate = date.toISOString().split("T")[0];
    }
    setToDate(toDate);
  };

  const updateFilterValues = () => {
    const { setFilterClicked, setFilterApplied } = props;
    setUpdateFilter(true);
    setFilterClicked(false);

    if (!valueFrom && !valueTo && !completed) {
      setFromDateSelector(yearsStartDate);
      setToDateSelector(date);
      setFilterApplied(false);
      return;
    }
    setFilterApplied(true);
  };

  const handleReset = () => {
    setUpdateFilter(true);
    setValueFrom(false);
    setValueTo(false);
    setFromDateSelector(yearsStartDate);
    setToDateSelector(date);
    setCompleted(false);
    props?.setFilterApplied(false);
    props?.setFilterClicked(false);
  };

  return (
    <div>
      <Grid
        {...dataState}
        data={taskList}
        onDataStateChange={dataStateChange}
        sortable={true}
        className="fs-md mt-3 casefile_task_gridheight"
        pageable={state.pageable}
        pageSize={state.pageSize}       
      >
        <GridColumn
          field="date"
          title="Date"
          cell={DateFormatCell}
          width="150px"
        />
        <GridColumn field="type" title="Type" width="150px" />
        <GridColumn field="feeEarnerRef" title="Fee Earner" width="150px" />
        <GridColumn field="details" title="Details" />
        <GridColumn
          field="dateDueBy"
          title="Due Date"
          cell={DateFormatCell}
          width="150px"
        />
        <GridColumn width="60" cell={Redirect} />
      </Grid>
      <Popup
        show={props?.filterClicked}
        anchor={props?.filterAnchor?.current}
        anchorAlign={{
          horizontal: "right",
          vertical: "bottom",
        }}
        popupAlign={{
          horizontal: "right",
          vertical: "top",
        }}
        popupClass={`${styles["popup-content"]}`}
        onOpen={onOpen}
      >
        <div
          tabIndex={0}
          onFocus={onFocus}
          onBlur={onBlur}
          ref={props?.filterContentRef}
          role="menu"
          className="d-flex flex-column align-items-center"
        >
          <FilterComponent
            updateFilterValues={updateFilterValues}
            setUpdateFilter={setUpdateFilter}
            updateFilter={updateFilter}
            showPopup={true}
            valueFrom={valueFrom}
            setValueFrom={setValueFrom}
            fromDateSelector={fromDateSelector}
            onChangeFromDateSelect={onChangeFromDateSelect}
            valueTo={valueTo}
            setValueTo={setValueTo}
            toDateSelector={toDateSelector}
            onChangeToDateSelect={onChangeToDateSelect}
            handleChange={handleStatusChange}
            completed={completed}
            showCompleted={true}
            handleReset={handleReset}
          />
        </div>
      </Popup>
      <HandleGridBody
        matterId={props?.matterId}
        refresh={isRefreshed}
        updateFilter={updateFilter}
        completed={completed}
        fD={updateFilter && valueFrom ? fromDate : ""}
        tD={updateFilter && valueTo ? toDate : ""}
        dataState={dataState}
        onDataReceived={dataReceived}
        currentComponent="casefile-tasks"
      />

      {props?.addTask && (
        <CaseTaskForm
          matterId={props?.matterId}
          addFormText={props?.addFormText}
          feeEarnerRef={props.matterDetails?.feeEarnerRef}
          isView={isView}
          setView={setView}
          viewTask={viewTask}
          isRefreshed={isRefreshed}
          setIsRefreshed={setIsRefreshed}
          setCloseModal={() => {
            setViewTask("");
            setView(false);
            props.setAddTask(false);
          }}
        />
      )}
    </div>
  );
}
