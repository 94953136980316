import {
  START_LOADING,
  STOP_LOADING,
  ERROR,
  SUCCESS,
  GET_CURRENT_USER,
  NO_CONTENT_USER,
  UPDATE_USER,
  NEWUSER_SUB,
  CREATENEWUSER_RESULT,
  CUURENTPROF_PHOTOS,
  GET_PROFILE_PHOTO,
  CLEAR_PROFILE_PHOTO,
  EDIT_PROFILE_PHOTO,
  SET_HEADER_PHOTO,
  INITIAL_LOADING,
} from "../types/index.js";

const currentuserReducer = (
  state = {
    IsError: false,
    isSuccess: false,
    isLoading: undefined,
    loading: true,
    errorArise: "",
    userSub: { sub: "" },
    files: [{}],
    image: null,
    userlist: {
      userRef: "",
      firstname: "",
      lastname: "",
      displayname: "User",
      emailAddress: "user@gmail.com",
      contactID: 0,
      active: true,
      userID: "",
      userImageFileId: undefined,
      downloadURL: "",
      editUsersProfilePhoto: "",
      imageInfo: {
        documentFile: {
          downloadURL: "",
        },
      },
    },
  },
  action
) => {
  switch (action.type) {
    case START_LOADING:
      return { ...state, isLoading: true };
    case STOP_LOADING:
      return { ...state, isLoading: false };
    case GET_CURRENT_USER:
      return {
        ...state,
        userlist: action.payload,
      };
    case CREATENEWUSER_RESULT:
      return {
        ...state,
        errorArise: true,
      };
    case NO_CONTENT_USER:
      return {
        ...state,
        noContent: true,
      };
    case INITIAL_LOADING:
      return {
        ...state,
        loading: false,
      };
    case CUURENTPROF_PHOTOS:
      state.files = [action.payload];
      return { ...state };
    case NEWUSER_SUB:
      return {
        ...state,
        userSub: action.payload,
      };

    case UPDATE_USER:
      return {
        ...state,
        userlist: { ...state.userlist, ...action.payload },
      };
    case GET_PROFILE_PHOTO:
      return {
        ...state,
        downloadURL: action.payload.documentFile.downloadURL,
      };
    case CLEAR_PROFILE_PHOTO:
      return {
        ...state,
        downloadURL: "",
        editUsersProfilePhoto: {},
      };
    case SET_HEADER_PHOTO:
      return {
        ...state,
        image: action.payload,
        downloadURL: action.payload,
      };
    case EDIT_PROFILE_PHOTO:
      return {
        ...state,
        editUsersProfilePhoto: action.payload,
      };
    case ERROR:
      return { ...state, IsError: true, isSuccess: false };
    case SUCCESS:
      return { ...state, IsError: false, isSuccess: true };
    default:
      return state;
  }
};
export default currentuserReducer;
