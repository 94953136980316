import * as React from "react";
import { useSelector } from "react-redux";

export const CellRender = (props) => {
  const dataItem = props.originalProps.dataItem;
  const cellField = props.originalProps.field;
  const inEditField = dataItem[props.editField || ""];
  const additionalProps =
    cellField && cellField === inEditField
      ? {
          ref: (td) => {
            const input = td && td.querySelector("input");
            const activeElement = document.activeElement;

            if (
              !input ||
              !activeElement ||
              input === activeElement ||
              !activeElement.contains(input)
            ) {
              return;
            }

            if (input.type === "checkbox") {
              input.focus();
            } else {
              input.select();
            }
          },
        }
      : {
          onClick: () => {
            props.enterEdit(dataItem, cellField);
          },
        };
  const clonedProps = { ...props.td.props, ...additionalProps };
  return React.cloneElement(props.td, clonedProps, props.td.props.children);
};

export const RowRender = (props) => {
  const [state, changestate] = React.useState({ period: 0 });
  var data = useSelector((state) => state.transactionperiods.currentPeriods);
  React.useEffect(() => {
    setTimeout(() => {
      if (data !== null && data !== undefined) {
        changestate(data);
      }
    }, 1000);
  }, [data]);
  const available = props.originalProps.dataItem.period === state.period;
  const red = {
    color: "#0b1dc9",
    backgroundColor: "lightcyan",
    height: 50,
  };
  const green = {
    backgroundColor: "none",
    height: 40,
  };
  const trProps = {
    ...props.tr.props,
    style: available && props.component === "Transaction" ? red : green,
    onBlur: () => {
      props.exitEdit();
    },
  };
  return React.cloneElement(props.tr, { ...trProps }, props.tr.props.children);
};
