import {
  START_LOADING,
  STOP_LOADING,
  SUCCESS,
  GET_ACTIVITY_LIST,
  EDIT_ACTIVITY_LIST,
  ADD_ACTIVITY,
} from "../types/index";
import * as api from "../api/index.js";
import { handleApiError } from "Helper";
import { toast } from "react-toastify";

export const getActivity = (page, limit) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const datas = await api.getActivityAllList(page, limit);
    dispatch({ type: GET_ACTIVITY_LIST, payload: datas.data });
    dispatch({ type: STOP_LOADING });
    dispatch({ type: SUCCESS, payload: datas.message });
  } catch (error) {
    handleApiError(error);
  }
};

export const editActivity = (id, data) => async (dispatch) => {
  try {
    const datas = await api.editActivity(id, data);
    toast.success(`Activity '${data?.name}' has been updated.`);
    dispatch({ type: EDIT_ACTIVITY_LIST, payload: datas });
  } catch (error) {
    toast.error(`Error occcured while updating`);
    handleApiError(error);
  }
};

export const addActivity = (data) => async (dispatch) => {
  try {
    const datas = await api.addActivity(data);
    dispatch({ type: ADD_ACTIVITY, payload: datas });
  } catch (error) {
    handleApiError(error);
  }
};
