import React from "react";
import { Input } from "@progress/kendo-react-inputs";

const PropertiesInputField = ({ label, type, onChange, value }) => (
    <div className="d-flex w-100 mb-2 align-items-center">
        <label
            className="w-50 fw-semibold fs-sm pe-1" >
            {label}
        </label>
        <Input type={type} onChange={onChange} value={value}
            className={"" + (type === "number" ? " text-end pe-2" : "")}
            
        />
    </div>
);

export default PropertiesInputField;
