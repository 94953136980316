import * as React from "react";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Input, Checkbox } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import { Label } from "@progress/kendo-react-labels";
import { useState, useEffect } from "react";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { getActiveApplications } from "api";
import { MultiSelect } from "@progress/kendo-react-dropdowns";

const AddEdit = (props) => {
  const ADD_NEW = props.type === "add";

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [appData, setAppData] = useState([]);

  useEffect(() => {
    getApplications();
  }, []);

  const getApplications = async () => {
    const response = await getActiveApplications();
    setAppData(response.data.data);
  };

  return (
    <>
      <div className="p-3 ps-4">
        <Form
          ignoreModified
          onSubmit={(event) => {
            const newData = {
              ...event,
              active: ADD_NEW ? true : event.active,
              type: 0,
            };
            if (!ADD_NEW) {
              newData.rateCategoryId = props.item.rateCategoryId;
            }
            props.onSubmit(newData);
          }}
          initialValues={ADD_NEW ? null : props.item}
          render={(formRenderProps) => {
            return (
              <FormElement
                style={{
                  maxWidth: 1000,
                }}
              >
                <fieldset className={"k-form-fieldset"}>
                  <div className="mb-3">
                    <Label className="label mt-1 mb-2  w-100">
                      Rate Category Name&nbsp;
                    </Label>
                    <Field name={"rateCategoryName"} component={Input} />

                    {isSubmitted &&
                      !formRenderProps.valueGetter("rateCategoryName") && (
                        <div className="fs-sm my-1 text-danger">
                          <i>Rate Category is required</i>
                        </div>
                      )}
                  </div>
                  <div className="mb-3">
                    <Label className="label mt-1 mb-2  w-100">
                      Applications&nbsp;
                    </Label>

                    <Field
                      data={appData}
                      textField="appCode"
                      name={"applications"}
                      placeholder="Select App"
                      component={(props) => (
                        <MultiSelect {...props} className="py-2 px-1" />
                      )}
                      disabled={formRenderProps.valueGetter("allowAllApps")}
                    />
                    {isSubmitted &&
                      !formRenderProps.valueGetter("applications")?.length &&
                      !formRenderProps.valueGetter("allowAllApps") && (
                        <div className="fs-sm my-1 text-danger">
                          <i>Application is required</i>
                        </div>
                      )}
                  </div>
                  <div>
                    <Field
                      style={{
                        marginRight: "10px",
                        width: "20px",
                      }}
                      name={"allowAllApps"}
                      component={Checkbox}
                      label={"For all applications"}
                    />
                  </div>

                  {!ADD_NEW && (
                    <div>
                      <Field
                        style={{
                          marginRight: "10px",
                          width: "20px",
                        }}
                        name={"active"}
                        component={Checkbox}
                        label={"Active"}
                      />
                    </div>
                  )}
                </fieldset>
                <button
                  style={{ visibility: "hidden" }}
                  id="rates-form"
                  type={"submit"}
                ></button>
              </FormElement>
            );
          }}
        />
      </div>
      <div className="dialog-footer">
        <Button
          className="bgBtnSecondary common-btn me-2"
          onClick={props.cancelEdit}
        >
          Cancel
        </Button>
        <Button
          className="common-btn me-3"
          onClick={() => {
            document.getElementById("rates-form").click();
            setIsSubmitted(true);
          }}
          type={"submit"}
        >
          {ADD_NEW ? "Save" : "Update"}
        </Button>
      </div>
    </>
  );
};

export default AddEdit;
