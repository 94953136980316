import {
  START_LOADING,
  STOP_LOADING,
  ERROR,
  SUCCESS,
  GET_SOURCE,
  EDIT_SOURCE,
  GET_ACTIVE_SOURCE,
} from "../types/index.js";

const SourcesReducer = (
  state = {
    IsError: false,
    isSuccess: false,
    isLoading: undefined,
  },
  action
) => {
  switch (action.type) {
    case START_LOADING:
      return { ...state, isLoading: true };
    case STOP_LOADING:
      return { ...state, isLoading: false };
    case GET_SOURCE:
      return {
        ...state,
        sourcesList: action.payload,
      };
    case EDIT_SOURCE:
      return {
        ...state,
        sourcesList: action.payload,
      };
    case GET_ACTIVE_SOURCE:
      return {
        ...state,
        activeSources: action.payload,
      };
    case ERROR:
      return { ...state, IsError: true, isSuccess: false };
    case SUCCESS:
      return { ...state, IsError: false, isSuccess: true };
    default:
      return state;
  }
};
export default SourcesReducer;
