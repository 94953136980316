import React from "react";
import styles from "../Settings.module.scss"
import ConnectedImage from "../../../assets/Settings/Integrations/Connected.png"
import { BiErrorCircle } from "react-icons/bi";


export default function IntegrationCard({ name, image, onShowModal, connectionStatus, integrationType }) {
    return (
        <div className={`${styles['integration-card']} d-flex flex-column justify-space-between cursor-pointer`} onClick={() => onShowModal(integrationType)}>
            <div className={`${styles['badge']}`}>
                <ConnectionStatus status={connectionStatus} />
            </div>
            <div className={`m-auto ${styles['image-size']}`}>
                <img src={image} />
            </div>
            <div className={`${styles['integration-footer']} d-flex justify-center`}>
                <span className="mt-4 text-center ms-auto me-auto">{name}</span>
            </div>
        </div>
    );
}

export const ConnectionStatus = ({ status }) => {
    return (
        status ?
            <div className={`${styles['connection-badge']} mt-3 mb-auto`}>
                <div className="d-flex align-items-center justify-content-evenly">
                    <img className={`${styles['connected-image']} mb-3`} src={ConnectedImage} />
                    <div>
                        <p className="text-center">Connected</p>
                    </div>
                </div>
            </div>
            : <></>
    )

}