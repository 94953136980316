import React from "react";
import { BrowserRouter } from "react-router-dom";
import { ProSidebarProvider } from "react-pro-sidebar";
import "hammerjs";
import { useSelector, useDispatch } from "react-redux";
import { ToastContainer } from "react-toastify";
import { useOidc } from "@axa-fr/react-oidc";
import { Login } from "./pages/Login/LoginPage.jsx";
import { useEffect } from "react";
import { IntlProvider } from "@progress/kendo-react-intl";
import { LoadLocaleData } from "./components/common/localeData";
import { getCompany } from "./actions/company.action";
import { useState } from "react";
// import { TokenHandler } from "./api/axios.config";
import LoadingScreen from "./components/common/Loading";
import Layout from "./components/layout/Layout";
import FreshLogin from "./components/common/freshLogin";
import AccessDenied from "components/common/Modal/AccessDenied";
import { TokenHandler } from "api/axios.config";
import EventListenerComponent from "components/EventRegistrar";

import "@progress/kendo-theme-default/dist/all.css";
import "./App.scss";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";

const App = () => {
    const dispatch = useDispatch();
    const { isAuthenticated, login } = useOidc();
    const [locale, setLocale] = useState("en-GB");
    const subData = useSelector((state) => state.currentuser.userSub);
    const responseError = useSelector((state) => state.loginOn.responseError);
    const localeData = useSelector((state) => state.company.company.locale);
    const commonState = useSelector((state) => state.common);
    const [appViewMode, setAppViewMode] = useState("Web");

    useEffect(() => {
        if (!isAuthenticated) {
            login(window.location.href);
        }
    }, [isAuthenticated]);

    useEffect(() => {
        if (responseError === true) {
            login(window.location.href);
        }
    }, [responseError]);

    useEffect(() => {
        dispatch(getCompany());
    }, []);

    useEffect(() => {
        if (localeData !== "") {
            setLocale(localeData);
        }
    }, [localeData]);

    useEffect(() => {

       

        const params = new URLSearchParams(window.location.search);
        const paramValue = params.get("viewmode");
        if (paramValue) {
            setAppViewMode(paramValue);

            if (paramValue.toLowerCase() === "outlook") {
                //require("./styles/_outlook.scss");
                import('./styles/_outlook.scss');
            }
        }
    }, []);

    LoadLocaleData(localeData);

    if (isAuthenticated) {
        return (
            <IntlProvider locale={locale}>
                <ProSidebarProvider>
                    <BrowserRouter>
                        <EventListenerComponent />
                        <TokenHandler />
                        <FreshLogin />
                        {commonState.mainLoading && <LoadingScreen />}
                        {commonState.accessDenied.status && <AccessDenied />}
                        <Layout viewmode={appViewMode} />
                        <ToastContainer
                            closeButton
                            toastStyle={{
                                marginRight: "70px",
                                width: "100%",
                            }}
                            hideProgressBar
                            autoClose={2000}
                        />
                    </BrowserRouter>
                </ProSidebarProvider>
            </IntlProvider>
        );
    } else {
        return <Login />;
    }
};

export default App;
