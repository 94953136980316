import { Button } from "@progress/kendo-react-buttons";
import { Checkbox } from "@progress/kendo-react-inputs";
import SearchTextBox from "components/SearchComponent";
import React, { useState } from "react";
import { useRef } from "react";
import styles from "./CaseFiles.module.scss";
import EditIcon from "../../assets/common/editBoxIcon.png";
import { Dialog } from "@progress/kendo-react-dialogs";
import {
    getClientInterviews,
} from "api";
import { useEffect } from "react";
import { Loader } from "@progress/kendo-react-indicators";
import AddEditClientInterview from "./AddEditClientInterview";

export const ClientInterviews = (props) => {
    const searchRef = useRef();
    const [userScreens, setUserScreens] = useState([]);
    const [selectedQuestions, setSelectedQuestions] = useState([]);
    const [editDocument, setEditDocument] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [showTemplatesDialog, setShowTemplatesDialog] = useState(false);

    const getUserTemplates = async () => {
        try {
            setIsLoading(true);
            const data = await getClientInterviews();
            setUserScreens(allowedScreenIds(data?.data?.data));
        }
        catch (error) {
        }
        finally {
            setIsLoading(false);
        }
    };

    const allowedScreenIds = (screens) => {
        const ALREADY_ADDED_SCREENS = [];
        props.addedTemplates?.forEach((screenItem) => {
            if (!ALREADY_ADDED_SCREENS.includes(screenItem.clientInterviewId)) {
                ALREADY_ADDED_SCREENS.push(screenItem.clientInterviewId);
            }
        });
        return screens?.filter(
            (item) => !ALREADY_ADDED_SCREENS.includes(item.clientInterviewId)
        );
    };

    const selectQuestion = (type, question) => {
        if (type) {
            setSelectedQuestions([...selectedQuestions, question]);
        } else {
            setSelectedQuestions(
                selectedQuestions.filter(
                    (prevQuestion) =>
                        prevQuestion.clientInterviewId !== question?.clientInterviewId
                )
            );
        }
    };

    const addQuestions = () => {
        props.addScreens(selectedQuestions);
    };

    useEffect(() => {
        getUserTemplates();
    }, []);

    return (
        <>
            <Dialog
                onClose={() => props.cancelEdit()}
                // className="mattersDialog"
                width={700}
                //   height={500}
                title={"Select Client Interviews"}
            >
                <div className="p-3 px-4 ms-3 me-3">
                    <div className="d-flex align-items-center justify-content-between">
                        <SearchTextBox
                            width={"400px"}
                            ref={searchRef}
                            changeFilterValue={(e) => {

                            }}
                        />
                        <Button
                            className="common-btn"
                            onClick={() => setShowTemplatesDialog(true)}
                        >
                            Add New Interview
                        </Button>
                    </div>
                    <div
                        className={`container-fluid mt-4 ${isLoading && "d-flex align-items-center justify-content-center"
                            }`}
                        style={{
                            height: "400px",
                            overflow: "auto",
                        }}
                        z
                    >
                        {isLoading ? (
                            <Loader themeColor={"warning"} size={"large"} type={"pulsing"} />
                        ) : (
                            userScreens?.map((question, index) =>
                                props.questionIds?.includes(question?.clientInterviewId) ? (
                                    <></>
                                ) : (
                                    <DocTemplate
                                        key={index}
                                        question={question}
                                        selectedQuestions={selectedQuestions}
                                        selectQuestion={selectQuestion}
                                        onSelectEdit={() => {
                                            setEditDocument(question);
                                            setShowTemplatesDialog(true);
                                        }}
                                    />
                                )
                            )
                        )}
                    </div>
                </div>
                <div className="dialog-footer">
                    <button
                        className="common-btn me-5"
                        type={"submit"}
                        onClick={addQuestions}
                    >
                        {"Select"}
                    </button>
                </div>
                {showTemplatesDialog && (
                    //   <AddEditDocTemplate
                    //     matterId={props.matterId}
                    //     cancelEdit={() => {
                    //       setShowTemplatesDialog(false);
                    //       setEditDocument();
                    //     }}
                    //     updateTemplates={() => {
                    //       getUserTemplates();
                    //       setShowTemplatesDialog(false);
                    //     }}
                    //     isEdit={editDocument}
                    //   />
                    <AddEditClientInterview
                        matterId={props.matterId}
                        cancelEdit={() => {
                            setShowTemplatesDialog(false);
                        }}
                        updateTemplates={() => {
                            getUserTemplates();
                            setEditDocument();
                            setShowTemplatesDialog(false);
                        }}
                        isEdit={editDocument}
                    // addClientInterview={addClientInterview}
                    // cancelEdit={() => {
                    //   setShowClientInterview(false);
                    //   setEditClientInterview("");
                    // }}
                    // updateInterview={(id, data) => {
                    //   setWorkFlowItems((workFlowItems) =>
                    //     workFlowItems.map((item) => {
                    //       if (item.id === id) {
                    //         return {
                    //           ...data,
                    //         };
                    //       } else {
                    //         return item;
                    //       }
                    //     })
                    //   );
                    // }}
                    // isEdit={editClientInterview}
                    />
                )}
            </Dialog>
        </>
    );
};

const DocTemplate = ({
    question,
    onSelectEdit,
    selectQuestion,
    selectedQuestions,
}) => {
    return (
        <div
            className={`row ${styles["box-styles"]} p-2 d-flex align-items-center mt-2`}
        >
            <div className="col-1" style={{ maxWidth: "40px" }}>
                <Checkbox
                    defaultValue={false}
                    value={
                        selectedQuestions.filter(
                            (item) => item.clientInterviewId === question?.clientInterviewId
                        ).length
                    }
                    onChange={(e) => {
                        selectQuestion(e.value, { ...question, itemType: "Interview" });
                    }}
                />
            </div>
            <div className={`col ${styles["question-text"]}`}>{question?.name}</div>

            <div className={`col ${styles["question-text"]}`}>
                {question?.details}
            </div>

            <div className="col-1" style={{ maxWidth: "50px" }}>
                <img
                    className="cursor-pointer"
                    onClick={() => onSelectEdit()}
                    src={EditIcon}
                    alt="Edit Icon"
                ></img>
            </div>
        </div>
    );
};
