import React, { useState } from "react";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import {
    DateFormatCell,
    AmountFormatCellEmptyZero,
    AmountFormat,
} from "components/common/formatter";
import { Popup } from "@progress/kendo-react-popup";
import { Checkbox } from "@progress/kendo-react-inputs";
import { Icon } from "@progress/kendo-react-common";
import styles from "./LedgerGrid.module.scss";
import { useEffect } from "react";

const cellWithBackGround = (props, type, method) => {

    const style = { background: "", opacity: 1 };
    if (type === "CLIENT")
        style.background =
            "linear-gradient(180deg, #ECECEC 0%, rgba(190, 190, 190, 0.12) 100%)";
    if (type === "DDA")
        style.background = "linear-gradient(180deg, #D7D7D7 0%, #EAEAEA 100%)";
    if (type === "OFFICE")
        style.background =
            "linear-gradient(180deg, #FAFAFA 0%, rgba(225, 225, 225, 0.12) 100%)";


    const amount = props.dataItem[props.field];


    return (
        <td style={style} className="text-end bg-none">
            <span style={{ opacity: "0.95", background: "none" }}>
                {(() => {
                    if (!isNaN(amount)) {

                        if (method === "Credit" && amount < 0) {
                            return AmountFormat(Math.abs(amount));
                        }
                        if (method === "Debit" && amount > 0) {
                            return AmountFormat(Math.abs(amount));
                        }
                        if (method === "Amount") {
                            return AmountFormat(amount);
                        }

                    }
                    return "";
                })()}
            </span>
        </td>
    );
};

const CenterTextHeaderCell = (props) => (
    <span
        className="d-flex pb-2 justify-content-center"
        style={{ color: "#000000", fontSize: "13px", fontWeight: "700" }}
    >
        {props.title}
    </span>
);

export default function MatterGrid(props) {
    const anchor = React.useRef(null);
    const [show, setShow] = React.useState(false);
    const RefCell = (e) => {
        return (
            <td
                style={{ cursor: "pointer" }}
                onClick={(event) => {
                    props.handleActionRef(event, e.dataItem.transactionNo);
                }}
            >
                <u>{e.dataItem.reference}</u>
            </td>
        );
    };
    const maxHeight =
        props.comp === "ledgerCard" ? "calc(100vh - 260px)" : "calc(100vh - 320px)";
    const [gridData, setGridData] = React.useState([]);
    const [fixedScroll, setFixedScroll] = useState(props.fixedScroll);

    const onClick = () => {
        setShow(!show);
    };

    const scrollHandler = (event) => {
        const e = event.nativeEvent;
        if (
            e.target.scrollTop + 10 >=
            e.target.scrollHeight - e.target.clientHeight
        ) {
            props.getMoreData();
        }
    };

    useEffect(() => {
        setGridData(props.gridData);
    }, [props.gridData.length]);

    useEffect(() => {
        setFixedScroll(props.fixedScroll);
    }, [props.fixedScroll]);


    return (
        <>
            <div style={{ maxHeight: maxHeight, position: "relative" }}>
                <Grid
                    scroll
                    style={{ maxHeight: maxHeight, height: maxHeight }}
                    data={gridData}
                    onScroll={scrollHandler}
                    fixedScroll={fixedScroll}
                    className="pb-1 mb-1 grid-fs13"
                >
                    <GridColumn>
                        <GridColumn
                            field="date"
                            title="Date"
                            width="100px"
                            cell={DateFormatCell}
                        />
                        <GridColumn field="postingType" title="Type" width="60px" />
                        <GridColumn
                            field="reference"
                            title="Ref"
                            width="120px"
                            cell={RefCell}
                            className="text-wrap"
                        />
                        <GridColumn
                            field="details"
                            title="Details"
                            width="320px"
                            className="text-wrap" />

                        <GridColumn
                            field="vatAmount"
                            title="VAT"
                            width="90px"
                            headerCell={CenterTextHeaderCell}
                            cell={AmountFormatCellEmptyZero}                            
                        />
                    </GridColumn>

                    <GridColumn title="OFFICE" headerCell={CenterTextHeaderCell}>

                        <GridColumn
                            field="grossAmount"
                            title="Debit"
                            headerCell={CenterTextHeaderCell}
                            cell={(e) => cellWithBackGround(e, "OFFICE", "Debit")}
                            width="95px"
                        />
                        <GridColumn
                            field="grossAmount"
                            title="Credit"
                            className="p-0"
                            headerCell={CenterTextHeaderCell}
                            cell={(e) => cellWithBackGround(e, "OFFICE", "Credit")}
                            width="95px"
                        />

                        <GridColumn
                            field="balance"
                            title="Balance"
                            cell={(e) => cellWithBackGround(e, "OFFICE", "Amount")}
                            width="95px"
                        />
                    </GridColumn>

                    <GridColumn title="CLIENT" headerCell={CenterTextHeaderCell}>

                        <GridColumn
                            field="clientAmount"
                            title="Debit"
                            headerCell={CenterTextHeaderCell}
                            cell={(e) => cellWithBackGround(e, "CLIENT", "Debit")}
                            width="95px"
                        />

                        <GridColumn
                            field="clientAmount"
                            title="Credit"
                            headerCell={CenterTextHeaderCell}
                            cell={(e) => cellWithBackGround(e, "CLIENT", "Credit")}
                            width="95px"
                        />

                        <GridColumn
                            field="clientBalance"
                            title="Balance"
                            headerCell={CenterTextHeaderCell}
                            cell={(e) => cellWithBackGround(e, "CLIENT", "Amount")}
                            width="95px"
                        />
                    </GridColumn>
                    {props.showDDA && (
                        <GridColumn title="DDA" headerCell={CenterTextHeaderCell}>

                            <GridColumn
                                field="ddaAmount"
                                title="Debit"
                                headerCell={CenterTextHeaderCell}
                                cell={(e) => cellWithBackGround(e, "DDA", "Debit")}
                                width="95px"
                            />

                            <GridColumn
                                field="ddaAmount"
                                title="Credit"
                                headerCell={CenterTextHeaderCell}
                                cell={(e) => cellWithBackGround(e, "DDA", "Credit")}
                                width="95px"
                            />

                            <GridColumn
                                field="ddaBalance"
                                title="Balance"
                                headerCell={CenterTextHeaderCell}
                                cell={(e) => cellWithBackGround(e, "DDA", "Amount")}
                                width="95px"
                            />
                        </GridColumn>
                    )}
                </Grid>
                <div className={`${styles["setting-btn-container"]}`}>
                    <button
                        className={`${styles["setting-btn"]} border-0 `}
                        onClick={onClick}
                        ref={anchor}
                    >
                        <Icon name="gear" />
                    </button>
                    <Popup
                        popupAlign={{ horizontal: "right", vertical: "top" }}
                        anchorAlign={{ horizontal: "right", vertical: "bottom" }}
                        anchor={anchor.current}
                        show={show}
                        popupClass={"popup-content"}
                    >
                        <div className="p-3">
                            <Checkbox
                                value={props.showDDA}
                                onClick={() => props.setShowDDA((prev) => !prev)}
                                label={"Show DDA Account"}
                            />
                        </div>
                    </Popup>
                </div>
            </div>
        </>
    );
}
