import React, { useEffect, useState, useRef } from "react";
import { Loader } from "@progress/kendo-react-indicators";
import { createPortal } from "react-dom";

export default function LoadingScreen({
  themeColor = "warning",
  size = "large",
  type = "pulsing",
}) {
  return (
    <div className="loading">
      <Loader themeColor={themeColor} size={size} type={type} />
    </div>
  );
}
