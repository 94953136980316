import {
  START_LOADING,
  STOP_LOADING,
  ERROR,
  SUCCESS,
  GET_CURRENCY_LIST,
  UPDATE_CURRENCY,
  ADDEDNEWCURRENCY,
  RESET,
  GET_ACTIVE_CURRENCY,
  GET_PROFIT_COST_TEMPLATE,
  ADD_PROFIT_COST_TEMPLATE,
  GET_FEE_HEADER_ITEMS,
  CLEAR_FEE_HEADER_ITEM,
  GET_ACTIVE_FEE_HEADERS,
  GET_ACTIVE_FEE_ITEMS,
  EMPTY_ACTIVE_FEE_ITEMS,
} from "../types/index.js";

const ProfitCostTemplate = (
  state = {
    IsError: false,
    isSuccess: false,
    isLoading: undefined,
  },
  action
) => {
  switch (action.type) {
    case START_LOADING:
      return { ...state, isLoading: true };
    case STOP_LOADING:
      return { ...state, isLoading: false };
    case GET_PROFIT_COST_TEMPLATE:
      return {
        ...state,
        profitCostTemplateList: action.payload,
      };
    case ADD_PROFIT_COST_TEMPLATE:
      return {
        ...state,
      };
    case GET_FEE_HEADER_ITEMS:
      return {
        ...state,
        fixedFeeHeaderItems: action.payload,
      };
    case CLEAR_FEE_HEADER_ITEM:
      return {
        ...state,
        fixedFeeHeaderItems: [],
      };
    case GET_ACTIVE_FEE_HEADERS:
      return {
        ...state,
        activeFeeHeaders: action.payload,
      };
    case GET_ACTIVE_FEE_ITEMS:
      return {
        ...state,
        activeFeeItems: action.payload,
      };
    case EMPTY_ACTIVE_FEE_ITEMS:
      return {
        ...state,
        activeFeeItems: { data: [] },
      };
    case ERROR:
      return { ...state, IsError: true, isSuccess: false };
    case SUCCESS:
      return { ...state, IsError: false, isSuccess: true };
    default:
      return state;
  }
};
export default ProfitCostTemplate;
