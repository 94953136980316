import {
  START_LOADING,
  STOP_LOADING,
  SUCCESS,
  GET_CHIT_POSTING_LISTS,
  GET_BATCH_POSTING_LISTS,
} from "../types/index";
import * as api from "../api/index.js";
import { handleApiError } from "Helper";

export const getChitPostingList =
  (page, limit, parameter, type) => async (dispatch) => {
    try {
      dispatch({ type: START_LOADING });
      let query = "";
      if (parameter.postingStatus === "true") {
        query += `Posted=true`;
      } else if (parameter.postingStatus === "false") {
        query += `Posted=false`;
      }
      if (parameter.fromDate !== null && parameter.fromDate !== undefined) {
        query += `&fromDate=${parameter.fromDate}`;
      }
      if (parameter.toDate !== null && parameter.toDate !== undefined) {
        query += `&toDate=${parameter.toDate}`;
      }

      if (parameter.matterId !== undefined) {
        query += `&MatterId=${parameter.matterId}`;
      }
      if (parameter.postingTypes !== undefined) {
        parameter.postingTypes.map((item) => {
          query += `&PostingTypes=${item}`;
        });
      }
      const datas = await api.getChitPostingList(page, limit, query);
      if (type === 'batchPosting') {
        dispatch({ type: GET_BATCH_POSTING_LISTS, payload: datas.data });
      } else {
        dispatch({ type: GET_CHIT_POSTING_LISTS, payload: datas.data });
      }
      dispatch({ type: STOP_LOADING });
      dispatch({ type: SUCCESS, payload: datas.message });
    } catch (error) { handleApiError(error) }
  };
