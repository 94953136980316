import * as React from "react";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { groupBy } from "@progress/kendo-data-query";
import * as api from "api";
import {
  setExpandedState,
  setGroupIds,
} from "@progress/kendo-react-data-tools";
import { Dialog } from "@progress/kendo-react-dialogs";
import { NumericCell } from "../../../DibursementPayment/DisbursementInputField";
import { NumericTextBox } from "@progress/kendo-react-inputs";
import { handleApiError } from "Helper";
import formStyles from "../../../Contacts/Contact.module.scss";
let products = {};
const initialGroup = [
  {
    field: "month",
  },
];
const processWithGroups = (data, group) => {
  const newDataState = groupBy(data, group);
  setGroupIds({
    data: newDataState,
    group: group,
  });
  return newDataState;
};

const UserTimeTargets = (props) => {
  const [group, setGroup] = React.useState(initialGroup);
  const [addBillingTargets, setAddBillingTargets] = React.useState(false);
  const [maxCounter, setMaxCounter] = React.useState(props.data);
  const [error, setError] = React.useState(false);
  const [monthsData, setMonthsData] = React.useState([]);

  const [resultState, setResultState] = React.useState(
    processWithGroups(props.data, initialGroup)
  );
  const [collapsedState, setCollapsedState] = React.useState([]);
  const onGroupChange = React.useCallback((event) => {
    const newDataState = processWithGroups(products, event.group);
    setGroup(event.group);
    setResultState(newDataState);
  }, []);

  const onExpandChange = React.useCallback(
    (event) => {
      const item = event.dataItem;
      if (item.groupId) {
        const newCollapsedIds = !event.value
          ? [...collapsedState, item.groupId]
          : collapsedState.filter((groupId) => groupId !== item.groupId);
        setCollapsedState(newCollapsedIds);
      }
    },
    [collapsedState]
  );
  const newData = setExpandedState({
    data: resultState,
    collapsedIds: collapsedState,
  });

  const calculateAmount = () => {
    let value = 0;
    dataState.map((item) => {
      value = value + item.value;
    });

    return value;
  };
  const [dataState, setData] = React.useState(props.data);
  const [dataToPost, setDataToPost] = React.useState(dataState);

  React.useEffect(() => {
    calculateAmount();
  }, [dataState]);
  const handleSubmit = async () => {
    setError(false);
    let data = [];
    dataState.map((item_1) => {
      if (item_1.weekDayNo !== undefined) {
        let dataTemp = {};
        dataTemp.weekDayNo = item_1.weekDayNo;
        dataTemp.value = item_1.value;
        data.push(dataTemp);
      }
    });
    try {
      await api.editTimeTargets(props.item.userID, data);
    } catch (error) {
      setError(true);
      handleApiError(error);
      return;
    }

    props.cancelEdit();
  };
  const itemChange = (event) => {
    let dataTemp = [];
    const newData = dataState.map((item_1) => {
      if (item_1.counter === event.dataItem.counter) {
        item_1[event.field] = event.value;
      }
      dataTemp.push(item_1);
    });
    setDataToPost(dataTemp);
  };

  React.useEffect(() => {
    let dataState = [];
    newData.map((item) => {
      dataState.push(item.value);
    });
    setMonthsData(dataState);
  }, []);
  return (
    <Dialog
      width={1000}
      height={800}
      onClose={props.cancelEdit}
      className="d-flex flex-column h-100"
      title={`Time Target - ${props.item?.displayname} (${props.item?.userRef})`}
    >
      <div style={{ width: "80%" }} className="mx-auto mt-4">
        <Grid
          data={dataState}
          onItemChange={itemChange}
          style={{
            margin: "auto",
            height: 335,
            overflow: "auto",
          }}
          className="accounts-table mt-2 mb-3 mx-auto"
        >
          <Column field="weekDayName" title="Day" />
          <Column field="value" title="Target (Hours)" cell={NumericCell} />
        </Grid>
        {error ? (
          <p style={{ color: "red" }}>Error occured while posting the data</p>
        ) : (
          <p style={{ visibility: "hidden" }}>ok</p>
        )}
        <div>
          <p className="fs-lg mt-4">Time Target (Hours)</p>
          <div className="w-100  border-bottom border-opacity-50"></div>
        </div>
        <div className="float-end d-flex flex-column mt-4">
          <div className="right-align d-flex align-items-center justify-content-center">
            <div style={{ width: "100px" }}>
              <p className="fs-md mt-2">Weekly</p>
            </div>
            <NumericTextBox
              width={150}
              style={{ textAlign: "right" }}
              value={calculateAmount()}
              readOnly={true}
              placeholder="0.00"
              spinners={false}
              format="n2"
            />
          </div>
          <div className="right-align d-flex align-items-center justify-content-center">
            <div style={{ width: "100px" }}>
              <p className="fs-md mt-2">Monthly</p>
            </div>
            <NumericTextBox
              width={150}
              style={{ textAlign: "right" }}
              value={calculateAmount() * 4.35}
              readOnly={true}
              placeholder="0.00"
              spinners={false}
              format="n2"
            />
          </div>
          <div className="right-align d-flex align-items-center justify-content-center">
            <div style={{ width: "100px" }}>
              <p className="fs-md mt-2">Yearly</p>
            </div>
            <NumericTextBox
              width={150}
              style={{ textAlign: "right" }}
              value={calculateAmount() * 52}
              readOnly={true}
              placeholder="0.00"
              spinners={false}
              format="n2"
            />
          </div>
        </div>
      </div>
      <div
        className={` ${formStyles["form-footer"]} text-end`}
        style={{ height: "65px", marginTop: 215 }}
      >
        <button
          className="common-btn bgBtnSecondary border-white me-4"
          onClick={props.cancelEdit}
          type={"submit"}
        >
          Cancel
        </button>
        <button
          className="k-button common-btn me-5"
          type={"submit"}
          onClick={handleSubmit}
        >
          Save
        </button>
      </div>
    </Dialog>
  );
};
export default UserTimeTargets;
