import * as React from "react";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { useEffect, useState } from "react";
import "@progress/kendo-react-intl";
import "@progress/kendo-react-dropdowns";
import "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import ClientTabs from "../tabs/ClientTabs";
import MatterTabs from "../tabs/MatterTabs";
import ContactTabs from "../tabs/ContactTabs";
import SaleTabs from "../tabs/SaleTabs";
import NominalTabs from "../tabs/NominalTabs";
import PurchaseTabs from "../tabs/PurchaseTabs";
import ConsultantTabs from "../tabs/ConsultantTabs";
import AdminTabs from "../tabs/AdminTabs";
import FeeEarnerTabs from "../tabs/FeeEarnerTabs";
import ReportTabs from "../tabs/ReportTabs";
import LoadingScreen from "components/common/Loading";
import Dashboard from "pages/Dashboard/DasboardPage";
import CaseFilesHeader from "components/CaseFiles/CaseFilesHeader";
import CaseFileTabs from "components/tabs/CaseFileTabs";
import { useAllowedLiscence } from "hooks/useAllowedLiscence";
import { License } from "components/Enums/TabEnums.ts";
import Options from "pages/Settings/Options";
import { useSelector } from "react-redux";

const FirstLayerTabTitle = (props) => {
  let word =
    props.content.charAt(0).toUpperCase() +
    props.content.slice(1).replace("%20", " ");
  if (word === "accounts") {
    word = "Accounts";
  } else if (word === "Feeearner") {
    word = "Fee Earner";
  } else if (word === "Casefiles") {
    word = "Case Files";
  }
  const locations = [
    "Dashboard",
    "Contacts",
    "Clients",
    "Matters",
    "Fee Earner",
    "Case Files",
    "Nominal",
    "Purchase",
    "Consultant",
    "Accounts",
    "Case Management",
    "Admin",
    "Reports",
    "Settings",
  ];

  const user = useSelector((state) => state?.currentuser?.userSub?.Firstname);
  return (
    <div
      className="cursor-default"
      style={{
        color: "black",
        borderRadius: "0px",
        height: "100%",
        display: word === "Settings" && "none",
      }}
    >
      {word === "Dashboard" ? (
        <div className="app-bar-title ps-4 ">
          <h4 className="fw-semibold mb-1">Hello, {user}!</h4>
          <h5 className="mt-0 fs-sm text-secondary">
            {" "}
            Welcome to Linq Legal Systems
          </h5>
        </div>
      ) : locations.includes(word) ? (
        <span className="fs-xl fw-semibold app-bar-title ps-4 ">{word}</span>
      ) : (
        ""
      )}
    </div>
  );
};

export function getSubstring(str, start, end) {
  let char1 = str?.indexOf(start) + 1;
  let char2 = str?.lastIndexOf(end);
  if (char2 === 0) {
    char2 = undefined;
  }
  return str.substring(char1, char2);
}

const Tabs = (props) => {
  const location = useLocation();
  const [selected, setSelected] = React.useState(-1);
  const [tabs, setTabs] = React.useState(["Dashboard", "Case Files"]);
  const { subDataCheck } = useAllowedLiscence();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const showHeader = props.viewmode !== "outlook";

  const handleSelect = (e) => {
    setSelected(e.selected);
  };

  useEffect(() => {
    if (location.pathname !== "/") {
      let substring = getSubstring(location.pathname, "/", "/");
      let word =
        substring?.charAt(0)?.toUpperCase() +
        substring?.slice(1)?.replace("%20", " ");
      if (word === "accounts") {
        word = "Accounts";
      } else if (word === "Feeearner") {
        word = "Fee Earner";
      } else if (word === "Casefiles") {
        word = "Case Files";
      } else if (word === "Clients") {
        word = "Matters";
      } else if (word === "Contacts") {
        word = "Matters";
      }
      if (tabs.includes(word)) {
        setSelected(tabs.indexOf(word));
      } else {
        setTabs([...tabs, word]);
        setSelected(tabs.length);
      }
    } else {
      setSelected(0);
    }
  }, [location]);

  useEffect(() => {
    if (location.pathname === "/") {
      setSelected(0);
    }
  }, [location]);

  const removeTab = (tab) => {
    setTabs(tabs.filter((tabItem) => tabItem !== tab));
    navigate("/");
  };

  return (
    <>
      {/* {subDataCheck().includes(License.CASE_MANAGEMENT) && <CaseFilesHeader />} */}
      <TabStrip
        className={`h-100 headerTab bg-white border-topleft maintab
                         pt-4`}
        selected={selected}
        onSelect={handleSelect}
        keepTabsMounted={true}
      >
        {tabs.map((item, index) => {
          return (
            <TabStripTab
              title={
                (showHeader || item === "Dashboard") && (
                  <FirstLayerTabTitle
                    content={
                      item === "Case Files" || item === "Matters"
                        ? "Case Management"
                        : item
                    }
                    onTabRemove={removeTab}
                    selected={tabs.at(selected)}
                  />
                )
              }
              key={index}
            >
              {item === "Dashboard" && <Dashboard />}
              {(item === "Matters" ||
                item === "Clients" ||
                item === "Contacts") && (
                <MatterTabs
                  closeTab={() => {
                    removeTab("Matters");
                  }}
                />
              )}
              {/* {item === "Clients" && (
                <ClientTabs
                  closeTab={() => {
                    removeTab("Clients");
                  }}
                />
              )}
              {item === "Contacts" && (
                <ContactTabs
                  closeTab={() => {
                    removeTab("Contacts");
                  }}
                />
              )} */}
              {item === "Nominal" && (
                <NominalTabs
                  closeTab={() => {
                    removeTab("Nominal");
                  }}
                />
              )}
              {item === "Admin" && (
                <AdminTabs
                  closeTab={() => {
                    removeTab("Admin");
                  }}
                />
              )}
              {item === "Purchase" && (
                <PurchaseTabs
                  closeTab={() => {
                    removeTab("Purchase");
                  }}
                />
              )}
              {item === "Consultant" && (
                <ConsultantTabs
                  closeTab={() => {
                    removeTab("Consultant");
                  }}
                />
              )}

              {item === "Accounts" && (
                <SaleTabs
                  closeTab={() => {
                    removeTab("Accounts");
                  }}
                />
              )}
              {item === "Fee Earner" && (
                <FeeEarnerTabs
                  closeTab={() => {
                    removeTab("Fee Earner");
                  }}
                />
              )}
              {/* {item === "Case Files" && (
                // <div className="overflow-hidden">
                <MatterTabs
                  closeTab={() => {
                    removeTab("Matters");
                  }}
                />
                // </div>
              )} */}
              {item === "Reports" && (
                <ReportTabs
                  closeTab={() => {
                    removeTab("Reports");
                  }}
                />
              )}

              {item === "Settings" && <Options />}
            </TabStripTab>
          );
        })}
      </TabStrip>
      {loading && <LoadingScreen />}
    </>
  );
};

export default Tabs;
