import * as React from "react";
import { Button } from "@progress/kendo-react-buttons";
import styles from "../Settings.module.scss";
import ApplicationsList from "./ApplicationsList";
import EditApplication from "./EditApplication";
import * as api from "../../../api/index";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { getApplications } from "actions/application.action";

const Applcations = (props) => {
  const [addnewform, setaddnewform] = React.useState(false);
  const dispatch = useDispatch();

  const closeform = () => {
    setaddnewform(false);
    // props.onCancel();
  };
  React.useEffect(() => {
    if (props.onClose === false) {
      closeform();
    }
  }, [props.onClose]);
  const handleSubmit = async (e) => {
    setaddnewform(false);
    try {
      await api.addnewApplication({
        active: true,
        ...e,
      });
      toast.success("Department Added");
      dispatch(getApplications());
    } catch (error) {
      toast.error("Error Occured");
    }
  };

  return (
    <React.Fragment>
      <>
        <div className="row">
          <div className="col-lg-10 col-md-8"></div>
          <Button
            className={`common-btn col-lg-1 col-md-4 p-2 mt-5 ${
              styles[`button`]
            }`}
            onClick={() => {
              setaddnewform(true);
              props.open();
            }}
          >
            Add <br></br>
            Application
          </Button>
        </div>
        <div className={`row mx-auto mt-3 w-50`}>
          <ApplicationsList />
        </div>
      </>
      {addnewform && (
        <EditApplication
          cancelEdit={closeform}
          onSubmit={handleSubmit}
          type="addNew"
        />
      )}
    </React.Fragment>
  );
};

export default Applcations;
