import {
  START_LOADING,
  STOP_LOADING,
  ERROR,
  SUCCESS,
  ADD_NEW_APPLICATION,
  GET_APPLICATIONS,
  GET_ACTIVE_APPLICATIONS,
} from "../types/index.js";

const ApplicationReducer = (
  state = {
    IsError: false,
    isSuccess: false,
    isLoading: undefined,
    activeApplicationsList: {
      data : []
    }
  },
  action
) => {
  switch (action.type) {
    case START_LOADING:
      return { ...state, isLoading: true };
    case STOP_LOADING:
      return { ...state, isLoading: false };
    case GET_APPLICATIONS:
      return {
        ...state,
        applicationsList: action.payload,
      };
    case ADD_NEW_APPLICATION:
      return {
        ...state,
      };
    case GET_ACTIVE_APPLICATIONS:
      return {
        ...state,
        activeApplicationsList: action.payload,
      };
    case ERROR:
      return { ...state, IsError: true, isSuccess: false };
    case SUCCESS:
      return { ...state, IsError: false, isSuccess: true };
    default:
      return state;
  }
};
export default ApplicationReducer;
