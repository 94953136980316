import React from "react";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { Label } from "@progress/kendo-react-labels";
import { Skeleton } from "@progress/kendo-react-indicators";

export const ComboBoxComponent = ({
  label,
  style,
  data,
  textField,
  filterable,
  onFilterChange,
  value,
  onChange,
  placeholder,
  className,
  disabled,
}) => (
  <div className="d-flex flex-column mt-2">
    <Label className="label mb-1">{label}</Label>
    <ComboBox
      style={style}
      data={data}
      iconClassName="wa"
      textField={textField}
      filterable={filterable}
      onFilterChange={onFilterChange}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      className={className}
      disabled={disabled}
    />
  </div>
);

export const ChitRequestSkeleton = () => {
  return (
    <>
      <div className="d-flex flex-column">
        {Array.from({ length: 9 }).map((_, index) => (
          <div className="my-2 " key={`skeleton-wrapper-${index}`}>
            <Skeleton
              shape={"text"}
              style={{
                width: 125,
                height: 30,
                marginBottom: 5,
              }}
            />
            <Skeleton
              shape={"rectangle"}
              style={{
                width: 300,
                height: 40,
                marginBottom: 10,
              }}
            />
          </div>
        ))}
      </div>

      <div className="d-flex flex-column  my-4 ">
        <Skeleton
          shape={"rectangle"}
          style={{
            width: 425,
            height: 60,
            marginLeft: 100,
            marginBottom: 20,
          }}
        />
        <Skeleton
          shape={"rectangle"}
          style={{
            width: 425,
            marginLeft: 100,
            height: 60,
          }}
        />
      </div>
    </>
  );
};
