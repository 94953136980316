import * as React from "react";
import {
  Chart,
  ChartSeries,
  ChartSeriesItem,
} from "@progress/kendo-react-charts";
import { useState } from "react";
import { useEffect } from "react";
import { getDashboardCreditorsData } from "api";
import { ChartHeader } from "components/Dashboard";
import timeRecorded from "../../assets/DashboardIcons/timeRecorded.svg";
import creditorsIcon from "../../assets/DashboardIcons/creditors-icon.svg";

export const CreditorsChart = (props) => {
  const [type, setType] = useState("Month");
  const [filters, setFilters] = useState({});
  const [series, setSeries] = useState([]);

  const graphUpdater = (data) => {
    let pieChartData = data?.map((item) => {
      return {
        key: item.key,
        amount: item.amount,
      };
    });
    setSeries(pieChartData);
  };

  const dataFetcher = async () => {
    const today = new Date();
    const dayOfWeek = today.getDay();
    const startOfWeek = new Date(today);
    startOfWeek.setDate(
      today.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1)
    );
    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(startOfWeek.getDate() + 6);

    try {
      const data = await getDashboardCreditorsData(
        filters?.teamId,
        filters?.feeEarnerRef,
        filters?.branch,
        filters?.FromDate || startOfWeek.toISOString().split("T")[0],
        filters?.ToDate || endOfWeek.toISOString().split("T")[0]
      );

      graphUpdater(data?.data?.data);
    } catch (error) {
      // Handle error
    }
  };

  useEffect(() => {
    if (filters) {
      dataFetcher();
    }
  }, [filters]);

  const typesUpdater = () => {
    let types = [];
    if (filters?.feeearnerRef === null) {
      types.push({
        label: "By Fee Earner",
        value: "FeeEarner",
      });
    }
    if (filters?.appCode === null) {
      types.push({
        label: "By App",
        value: "Application",
      });
    }
    if (filters?.branch === null) {
      types.push({
        label: "By Branch",
        value: "Branch",
      });
    }
    if (filters?.month === null) {
      types.push({
        label: "By Month",
        value: "Month",
      });
    }
    setTypesData(types);
    let check = false;
    types.map((item) => {
      if (item.value === type) {
        check = true;
      }
    });
    if (!check) {
      setType("Month");
    }
  };
  const [typesData, setTypesData] = useState([]);
  useEffect(() => {
    setFilters(props.filters);
  }, [props.filters]);
  useEffect(() => {
    typesUpdater();
  }, [filters, type]);

  const chartDefaultV4Colors = [
    "#241CCA",
    "#27E7AC",
    "#00A8E1",
    "#F1F0FF",
    "#96A0FF",
    "#aa46be",
  ];

  return (
    <>
      <div className="h-100 p-2">
        <ChartHeader
          name={"Creditors"}
          type={"(Actual vs Targets)"}
          icon={creditorsIcon}
          color={"#F5F5F5"}
          duration={filters?.duration}
        />
        <Chart
          style={{
            height: "240px",
          }}
          seriesColors={chartDefaultV4Colors}
        >
          <ChartSeries>
            <ChartSeriesItem
              type="pie"
              overlay={{
                gradient: "sharpBevel",
              }}
              tooltip={{
                visible: true,
              }}
              data={series}
              categoryField="key"
              field="amount"
            />
          </ChartSeries>
        </Chart>
      </div>
    </>
  );
};
