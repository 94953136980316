import * as React from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Checkbox, NumericTextBox } from "@progress/kendo-react-inputs";
import EstimateFeeImage from "../../assets/estimate-fee.png";
import { AmountFormat, DateFormat } from "components/common/formatter";
import {
  Grid,
  GridColumn as Column,
  GridToolbar,
} from "@progress/kendo-react-grid";
import { useState } from "react";
import { Tile } from "components/UIComponents/Tile";
import { Button } from "@progress/kendo-react-buttons";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import CalenderCustomToggleButton from "components/common/CalenderCustomToogleButton";
import { useEffect } from "react";
import { getEstimates, updateEstimate, addEstimate } from "api";
import { toast } from "react-toastify";
import { Loader } from "@progress/kendo-react-indicators";
import EditIcon from "assets/common/editBoxIcon.png";
import SaveIcon from "assets/SaveIcon.png";

const editField = "inEdit";
export const DateFormatCell = (props) => {
  const handleChange = (e) => {
    if (props.onChange) {
      props.onChange({
        dataIndex: 0,
        dataItem: props.dataItem,
        field: props.field,
        syntheticEvent: e.syntheticEvent,
        value: e.target.value,
      });
    }
  };
  const { dataItem } = props;
  const field = props.field || "";
  const dataValue = dataItem[field];
  return (
    <td {...props.tdProps}>
      {dataItem.inEdit && dataItem.AddNew ? (
        <DatePicker
          value={dataValue ? new Date(dataValue) : null}
          onChange={handleChange}
        />
      ) : dataValue ? (
        DateFormat(dataValue)
      ) : (
        ""
      )}
    </td>
  );
};

export const FeeFormatCell = (props) => {
  const handleChange = (e) => {
    if (props.onChange) {
      props.onChange({
        dataIndex: 0,
        dataItem: props.dataItem,
        field: props.field,
        syntheticEvent: e.syntheticEvent,
        value: e.target.value,
      });
    }
  };

  const { dataItem } = props;
  const field = props.field || "";
  const dataValue = dataItem[field];
  return (
    <td {...props.tdProps}>
      {dataItem.inEdit && dataItem.AddNew ? (
        <NumericTextBox
          spinners={false}
          format="n2"
          placeholder="0.00"
          value={dataValue || ""}
          onChange={handleChange}
        />
      ) : (
        <span>{AmountFormat(dataValue)}</span>
      )}
    </td>
  );
};

const CommandCell = (props) => {
  const { edit, update, editField } = props;
  return (
    <td>
      {props.dataItem[editField] ? (
        <img
          onClick={() => update(props.dataItem)}
          className="cursor-pointer"
          alt="Save Icon"
          src={SaveIcon}
          width="20px"
          height={"20px"}
        ></img>
      ) : (
        <img
          onClick={() => edit(props.dataItem)}
          className="cursor-pointer"
          alt="Edit Icon"
          src={EditIcon}
          width="20px"
          height={"20px"}
        ></img>
      )}
    </td>
  );
};

const EstimateFee = (props) => {
  const [estimatesData, setEstimatesData] = useState([]);
  const [currentEstimatedFee, setCurrentEstimatedFee] = useState();
  const [loading, setLoading] = useState(true);

  const getEstimatesData = async () => {
    setLoading(true);
    try {
      const data = await getEstimates(props.matter);
      setEstimatesData([...data.data?.data]);
      // setCurrentEstimatedFee(data.data?.data?.pop());
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getEstimatesData();
  }, []);

  const addNewEstimate = () => {
    const newEstimate = {
      AddNew: true,
      inEdit: true,
      date: new Date(),
      notes: "",
      dateClientInformed: null,
      estimateValue: 0,
    };
    setEstimatesData([newEstimate, ...estimatesData]);
  };

  const enterEdit = (dataItem) => {
    setEstimatesData(
      estimatesData.map((item) =>
        item === dataItem ? { ...item, inEdit: true } : item
      )
    );
  };
  const updateEstimateData = async (dataItem) => {
    try {
      const updatedDataItem = { ...dataItem };
      if (dataItem?.dateChecked === false) {
        updatedDataItem.dateClientInformed = null;
      }
      if (dataItem.AddNew) {
        await addEstimate(
          {
            data: updatedDataItem,
          },
          props.matter
        );
      } else {
        await updateEstimate(
          {
            data: {
              notes: updatedDataItem.notes,
              dateClientInformed: updatedDataItem.dateClientInformed,
            },
          },
          props.matter,
          dataItem.estimateId
        );
      }
      setEstimatesData(
        estimatesData.map((item) =>
          item === dataItem ? { ...item, inEdit: false, AddNew: false } : item
        )
      );
      getEstimatesData();
      toast.success("Estimate Fee Updated");
    } catch (error) {
      toast.error("Unexpected Error Occured");
    }
  };

  const itemChange = (event) => {
    const newData = estimatesData.map((item) =>
      item === event.dataItem ? { ...item, [event.field]: event.value } : item
    );
    setEstimatesData(newData);
  };

  const DateClientCell = (props) => {
    const handleChange = (e) => {
      if (props.onChange) {
        props.onChange({
          dataIndex: 0,
          dataItem: props.dataItem,
          field: props.field,
          syntheticEvent: e.syntheticEvent,
          value: e.target.value,
        });
      }
    };
    const handleCheckboxChange = (e) => {
      const newData = estimatesData.map((item) =>
        item === props.dataItem ? { ...item, dateChecked: e.value } : item
      );
      setEstimatesData(newData);
    };
    const { dataItem } = props;
    const field = props.field || "";
    const dataValue = dataItem[field];

    return (
      <td {...props.tdProps}>
        {dataItem.inEdit ? (
          <div className="d-flex align-items-center gap-3 ms-2">
            <Checkbox
              className="mt-2"
              value={dataItem?.dateChecked}
              defaultValue={dataValue}
              onChange={handleCheckboxChange}
            />
            <DatePicker
              value={dataValue ? new Date(dataValue) : ""}
              onChange={handleChange}
            />
          </div>
        ) : dataValue ? (
          DateFormat(dataValue)
        ) : (
          ""
        )}
      </td>
    );
  };

  return (
    <Dialog
      title={"Update Estimate Fee"}
      onClose={props.cancelEdit}
      width={750}
    >
      <div>
        {loading ? (
          <div
            className="d-flex align-items-center justify-content-center h-100"
            style={{ minHeight: "600px" }}
          >
            <Loader themeColor={"warning"} size={"large"} type={"pulsing"} />
          </div>
        ) : (
          <div className=" px-4">
            <Tile height={"600px"} className="my-2 p-3 overflow-hidden">
              <Grid
                data={estimatesData}
                onItemChange={itemChange}
                editField={editField}
              >
                <GridToolbar>
                  <Button
                    title="Add New Estimate"
                    onClick={addNewEstimate}
                    type="button"
                    className="ms-auto button-small"
                  >
                    Add New Estimate
                  </Button>
                </GridToolbar>
                <Column
                  field="date"
                  title="Date"
                  cell={DateFormatCell}
                  headerClassName="ps-3 "
                  width="150px"
                />
                <Column
                  field="notes"
                  title="Comments"
                  headerClassName="ps-3"
                  width="160px"
                />
                <Column
                  field="dateClientInformed"
                  title="Client Notified"
                  cell={DateClientCell}
                  headerClassName="ps-3"
                  width="200px"
                />
                <Column
                  field="estimateValue"
                  title="Fee"
                  // width="80px"
                  headerClassName="ps-3"
                  cell={FeeFormatCell}
                  className="pe-2"
                  editor="text"
                />
                <Column
                  cell={(props) => (
                    <CommandCell
                      {...props}
                      edit={enterEdit}
                      update={updateEstimateData}
                      editField={editField}
                      // isLastRecord={
                      //   props.dataItem ===
                      //   estimatesData[estimatesData.length - 1]
                      // }
                    />
                  )}
                  width="50px"
                />
              </Grid>
            </Tile>
          </div>
        )}
      </div>

      <div className="dialog-footer">
        <button
          className="bgBtnSecondary common-btn me-2"
          onClick={props.cancelEdit}
        >
          Close
        </button>
      </div>
    </Dialog>
  );
};

export default EstimateFee;
