import React from "react";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { AmountFormat } from "components/common/formatter";
import styles from "./MatterLedger.module.scss";
import { isEmpty } from "components/common/Utils";

const MatterAccount = (props) => {
  const [currentmattersBal, setMattersBalances] = useState({
    matterRef: "This Matter",
    clientRef: "All Matters",
    matterBalances: {},
    clientBalances: {},
  });

  const mattersBalances = useSelector((state) => state.mattersList);
  useEffect(() => {
    if (
      mattersBalances?.mattersBalances?.data !== undefined &&
      props.matterId !== null &&
      props.component === "Matter Ledger"
    ) {
      setMattersBalances(mattersBalances?.mattersBalances?.data);
    } else if (
      mattersBalances?.mattersBalancesAllPostings !== undefined &&
      props.component === "All Postings" &&
      props?.matterId !== undefined
    ) {
      setMattersBalances(mattersBalances?.mattersBalancesAllPostings?.data);
    } else if (
      mattersBalances.mattersBalancesOfficePostings !== undefined &&
      props.component === "Office Postings"
    ) {
      setMattersBalances(mattersBalances?.mattersBalancesOfficePostings?.data);
    } else if (
      mattersBalances?.mattersBalancesClientPostings !== undefined &&
      props.component === "Client Postings"
    ) {
      setMattersBalances(mattersBalances?.mattersBalancesClientPostings.data);
    } else if (
      mattersBalances?.mattersChitRequest !== undefined &&
      props.component === "Chit Request"
    ) {
      setMattersBalances(mattersBalances?.mattersChitRequest.data);
    } else if (
      mattersBalances?.timeRecording !== undefined &&
      props.component === "Time Recording"
    ) {
      if (props?.clear) {
        setMattersBalances("");
      } else {
        setMattersBalances(mattersBalances?.timeRecording.data);
      }
    } else if (
      mattersBalances.mattersBills !== undefined &&
      props.component === "Bills"
    ) {
      if (props?.clear) {
        setMattersBalances("");
      } else {
        setMattersBalances(mattersBalances.mattersBills.data);
      }
    } else {
      setMattersBalances({
        matterBalances: {},
        clientBalances: {},
      });
    }
  }, [mattersBalances, props.clear]);
  return (
    <table
      className="balance-table"
      style={{ maxWidth: props.width, height: "100%", paddingBottom: "10px" }}
    >
      <thead>
        <tr>
          <th>Balances</th>
          <th style={{ width: "10px" }}></th>
          <th className="text-center">Office Bal</th>
          <th className="text-center">Client Bal</th>
          <th className="text-center">DDA Bal</th>
          <th className="text-center">Unpaid Bills</th>
          <th className="text-center">WIP</th>
        </tr>
      </thead>
      <tbody>
        <tr className="position-relative">
          <td className="fs-sm fw-semibold" style={{ minWidth: "80px" }}>
            {isEmpty(currentmattersBal.matterRef)
              ? "Matter"
              : currentmattersBal.matterRef}
          </td>
          <td></td>
          <AmountTD value={currentmattersBal.matterBalances?.officeBalance} />
          <AmountTD value={currentmattersBal.matterBalances?.clientBalance} />
          <AmountTD value={currentmattersBal.matterBalances?.ddaBalance} />
          <AmountTD value={currentmattersBal.matterBalances?.unpaidBills} />
          <AmountTD value={currentmattersBal.matterBalances?.wipBalance} />
        </tr>
        <tr>
          <td className="fs-sm fw-semibold">
            {/* {isEmpty(currentmattersBal.clientRef)
              ? "Client"
              : currentmattersBal.clientRef} */}
            {"Client"}
          </td>
          <td></td>
          <AmountTD value={currentmattersBal.clientBalances?.officeBalance} />
          <AmountTD value={currentmattersBal.clientBalances?.clientBalance} />
          <AmountTD value={currentmattersBal.clientBalances?.ddaBalance} />
          <AmountTD value={currentmattersBal.clientBalances?.unpaidBills} />
          <AmountTD value={currentmattersBal.clientBalances?.wipBalance} />
        </tr>
      </tbody>
    </table>
  );
};

export default MatterAccount;

const AmountTD = ({ value }) => {
  return (
    <td
      className="text-center pe-1"
      style={{ width: "90px", whiteSpace: "nowrap", overflow: "hidden" }}
    >
      {AmountFormat(value)}
    </td>
  );
};

{
  /* <td className="bt-value w-25 text-end">{AmountFormat(value2)}</td> */
}
