export const NominalTypeFormatCell = (props) => {
  let type = props.dataItem[props.field];
  return <td>{NominalTypeFormat(type)}</td>;
};

export const NominalTypeFormat = (type) => {
  if (type === "BalanceSheet") {
    type = "Balance Sheet";
  } else if (type === "ProfitAndLoss") {
    type = "Profit & Loss";
  } else if (type === "BalanceSheet_ClientBank") {
    type = "Client Bank";
  } else if (type === "BalanceSheet_DDABank") {
    type = "DDA Bank";
  } else if (type === "BalanceSheet_OfficeBank") {
    type = "Office Bank";
  } else if (type === "BalanceSheet_Other") {
    type = "Other";
  } else if (type === "ProfitAndLoss_Income") {
    type = "Income";
  } else if (type === "ProfitAndLoss_Expense") {
    type = "Expense";
  } else if (type === "ProfitAndLoss_Other") {
    type = "Other";
  }
  return type;
};
