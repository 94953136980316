export const enum Tabs {
  FEE_EARNER_DASHBOARD = "Fee Earner Dashboard",
  ACCOUNTS_DASHBOARD = "Accounts Dashboard",
  CLIENTS = "Clients",
  MATTERS = "Matters",
  CONTACTS = "Contacts",
  ACCOUNTS = "Accounts",
  NOMINAL = "Nominal",
  PURCHASE = "Purchase",
  CONSULTANT = "Consultant",
  ADMIN = "Admin",
  FEE_EARNER = "Fee Earner",
  REPORTS = "Reports",
  CASE_FILE = "Case File",
}

export const enum License {
  ACCOUNTS = "Accounts",
  CASE_MANAGEMENT = "CaseManagement",
}
