import React from "react";
import { useEffect, useRef, useState, useCallback } from "react";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { useSelector } from "react-redux";
import { URL } from "api";
import { useOidcAccessToken } from "@axa-fr/react-oidc";

const textField = "matterRef";
const keyField = "matterId";
const emptyItem = {
  [textField]: "loading ...",
  CustomerID: 0,
};
const pageSize = 30;
const loadingData = [];

while (loadingData.length < pageSize) {
  loadingData.push({ ...emptyItem });
}

const baseUrl = URL;

const MattersCombobox = (props) => {
  const { accessToken } = useOidcAccessToken();
  const init = {
    method: "GET",
    accept: "application/json",
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  };
  const dataCaching = useRef([]);
  const pendingRequest = useRef();
  const requestStarted = useRef(false);
  const [data, setData] = useState([]);
  const [value, setValue] = useState(null);
  const [total, setTotal] = useState(0);
  const [filter, setFilter] = useState();
  const skipRef = useRef(0);
  // const {accessToken} = useOidcAccessToken()
  const matterRefChange = useSelector(
    (state) => state.ledgerLinks.currentMatterRef
  );

  const itemRender = (li, itemProps) => {
    const dataItem = itemProps.dataItem;
    const itemChildren = (
      <div className="d-flex justify-content-center align-items-center flex-column w-100">
        <div
          className="d-flex flex-column justify-content-center ms-2 w-100 my-2 ps-2"
          style={{
            backgroundColor: "#F8F8F8",
            borderRadius: "5px",
            padding: "6px",
          }}
        >
          <div className="d-flex">
            <span className="fw-semibold fs-sm">{dataItem.matterRef}</span>
            <span className="ms-2 fw-semibold fs-sm text-decoration-underline">
              {dataItem.feeEarnerName}
            </span>
          </div>
          <span className="fs-sm">{dataItem.details}</span>
        </div>
        <hr
          className="ms-1 m-0"
          style={{
            width: "95%",
            border: "0.5px solid #E1E1E1",
          }}
        />
      </div>
    );
    return React.cloneElement(li, li.props, itemChildren);
  };

  useEffect(() => {
    if (matterRefChange !== null && props.component === "MatterLedger") {
      setValue({
        matterId: matterRefChange.matterId,
        matterRef: matterRefChange.matterRef,
        feeEarnerRef: matterRefChange.feeEarnerRef,
        feeEarnerName: matterRefChange.feeEarnerName,
        ...matterRefChange,
      });
      onChange({
        target: {
          value: {
            matterId: matterRefChange.matterId,
            matterRef: matterRefChange.matterRef,
            feeEarnerRef: matterRefChange.feeEarnerRef,
            feeEarnerName: matterRefChange.feeEarnerName,
            ...matterRefChange,
          },
        },
      });
    }
  }, [matterRefChange]);
  useEffect(() => {
    if (value !== null) {
      setValue(null);
    }
  }, [props.reset]);
  const resetCach = () => {
    dataCaching.current.length = 0;
  };

  const requestData = useCallback((skip, filter) => {
    if (requestStarted.current) {
      clearTimeout(pendingRequest.current);
      pendingRequest.current = setTimeout(() => {
        requestData(skip, filter);
      }, 50);
      return;
    }
    const search =
      filter !== undefined && filter !== "" ? `search=${filter}` : "";
    // if (isNaN(skip)) {
    //   skip = 5;
    // }
    const url =
      baseUrl + `${search}&page=${skip + 1}&limit=${pageSize}&active=true`;
    requestStarted.current = true;
    fetch(url, init)
      .then((response) => response.json())
      .then((json) => {
        const total = 10;
        const items = [];
        json.data.forEach((element, index) => {
          const { matterRef, matterId, feeEarnerRef, feeEarnerName } = element;
          const item = {
            [keyField]: matterId,
            [textField]: matterRef,
            feeEarnerRef: feeEarnerRef,
            feeEarnerName: feeEarnerName,
            ...element,
          };
          items.push(item);
          dataCaching.current[index + skip] = item;
        });

        if (skip === skipRef.current) {
          setData(items);
        }

        requestStarted.current = false;
      });
  }, []);
  useEffect(() => {
    requestData(0, filter);
    return () => {
      resetCach();
    };
  }, [filter, requestData]);
  const onFilterChange = useCallback((event) => {
    const filter = event.filter.value;
    resetCach();
    requestData(0, filter);
    setData(loadingData);
    skipRef.current = 0;
    setFilter(filter);
    if (props.onFilterChange) {
      props?.onFilterChange(event);
    }
  }, []);
  const shouldRequestData = React.useCallback((skip) => {
    for (let i = 0; i < pageSize; i++) {
      if (!dataCaching.current[skip + i]) {
        return true;
      }
    }
    return false;
  }, []);

  const getCachedData = useCallback((skip) => {
    let dataTemp = [];

    for (let i = 0; i < pageSize; i++) {
      dataTemp.push(dataCaching.current[i + skip] || { ...emptyItem });
    }

    return dataTemp;
  }, []);
  const pageChange = useCallback(
    (event) => {
      let newSkip = event.page.skip;
      if (isNaN(newSkip)) {
        return;
      }
      if (shouldRequestData(newSkip)) {
        requestData(newSkip, filter);
      }

      const data = getCachedData(newSkip);
      setData(data);
      skipRef.current = newSkip;
    },
    [getCachedData, requestData, shouldRequestData, filter]
  );
  const onChange = useCallback((event) => {
    const value = event.target.value;
    setValue(value);
    props.onChangematter(event);
  }, []);
  const styleObject = {
    width: props.width ? props.width : "300px",
    borderRadius: props.borderRadius ? props.borderRadius : null,
  };
  useEffect(() => {
    if (props.value !== null) {
      setValue(props.value);
    } else {
      setValue(null);
    }
  }, [props.value]);
  return (
    <ComboBox
      required={props.required}
      data={data}
      value={value}
      onChange={(e) => {
        if (props.component === "MatterLedger") {
          onChange(e);
        } else if (props.onChangematter) {
          props.onChangematter(e);
        } else {
          onChange(e);
        }
      }}
      dataItemKey={keyField}
      disabled={props.disabled}
      textField={textField}
      iconClassName="wa"
      placeholder={"Select Matter"}
      filterable={true}
      onFilterChange={onFilterChange}
      virtual={{
        pageSize: pageSize,
        skip: skipRef.current,
        total: total,
      }}
      onPageChange={pageChange}
      style={{
        ...styleObject,
        ...props.style,
        border: props.error && "1px solid red",
      }}
      itemRender={itemRender}
      className={props.className ? props.className + " px-2" : "px-2"}
    />
  );
};

export default MattersCombobox;
