import CONSTANT from "CONSTANT/Matter/PostingTypes";
import { API, baseURL } from "./axios.config";
export * from "./accountsAPI";

export const updateMatter = (id, data) =>
  API.patch(`/matters/${id}`, JSON.stringify(data), {
    headers: { "Content-Type": "application/json" },
  });

export const getDepartmentList = () =>
  API.get(`/Departments?active=true&orderby=name`);

export const getBankRecData = (nominlaId, reconcile) =>
  API.get(`/bankrec/${nominlaId}?todate=${reconcile}`);

export const addBankRecs = (nominalRef, data) =>
  API.post(`/bankrec/${nominalRef}/save`, JSON.stringify(data), {
    headers: { "Content-Type": "application/json" },
  });

export const sendMail = (matterId, data) =>
  API.post(`/CaseFile/${matterId}/NewEmail`, JSON.stringify(data), {
    headers: { "Content-Type": "application/json" },
  });

export const postBankRecs = (nominalRef, data) =>
  API.post(`/bankrec/${nominalRef}`, JSON.stringify(data), {
    headers: { "Content-Type": "application/json" },
  });

export const getCategoryList = () =>
  API.get(`/WorkTypes?active=true&orderby=name`);

export const addMatter = (data) =>
  API.post(`/matters`, JSON.stringify(data), {
    headers: { "Content-Type": "application/json" },
  });

export const saveActivity = (id, data) =>
  API.post(`/MatterActivities/${id}`, JSON.stringify({ data }), {
    headers: { "Content-Type": "application/json" },
  });

export const getRecordedTime = (
  feeEarner,
  status,
  FromDate,
  ToDate,
  page,
  limit
) =>
  API.get(
    `/Time/user/${feeEarner}?Billed=${status.value}&FromDate=${FromDate}&ToDate=${ToDate}&page=${page}&limit=${limit}`
  );

export const getRecordedTimeForMatter = (
  matterId,
  status,
  FromDate,
  ToDate,
  page,
  limit,
  parameter
) =>
  API.get(
    `/Time/Matter/${matterId}?Active=true&Billed=${status.value}&FromDate=${FromDate}&ToDate=${ToDate}&page=${page}&limit=${limit}&orderby=${parameter}`
  );

export const searchMatter = (page, limit, search) =>
  API.get(`/matters?search=${search}&page=${page}&limit=${limit}`);

export const getClientsNameAndRef = () =>
  API.get(
    "Clients?active=true&orderby=clientRef&fields=clientid,clientRef,clientName"
  );

export const getActivityType = () =>
  API.get(
    "Clients?active=true&orderby=clientRef&fields=clientid,clientRef,clientName"
  );

export const addClient = (data) =>
  API.post(`/Clients`, JSON.stringify({ data }), {
    headers: { "Content-Type": "application/json" },
  });

export const editClient = (id, data) =>
  API.patch(`/Clients/${id}`, JSON.stringify({ data }), {
    headers: { "Content-Type": "application/json" },
  });

export const getClientData = (id) => API.get(`/Clients/${id}`);

export const searchClient = (page, limit, search, parameter) =>
  API.get(
    `/Clients?search=${search}&page=${page}&limit=${limit}&orderby=${parameter}`
  );

export const getSuppliersList = (page, limit, parameter) =>
  API.get(
    `/Accounts/Suppliers?page=${page}&limit=${limit}&orderby=${parameter}`
  );

export const getConsultantsList = (page, limit, parameter) =>
  API.get(
    `/Accounts/Suppliers?type=Consultant&page=${page}&limit=${limit}&orderby=${parameter}`
  );

export const addSupplier = (data) =>
  API.post(`/Accounts/Suppliers`, JSON.stringify({ data }), {
    headers: { "Content-Type": "application/json" },
  });

export const getViewSupplier = (id) => API.get(`/Accounts/Suppliers/${id}`);

export const updateSupplier = (id, data) =>
  API.patch(`/Accounts/Suppliers/${id}`, JSON.stringify({ data }), {
    headers: { "Content-Type": "application/json" },
  });
export const searchSupplier = (page, limit, search) =>
  API.get(`/Accounts/Suppliers?search=${search}&page=${page}&limit=${limit}`);

export const getUsersList = (page, limit) =>
  API.get(`/Users?page=${page}&limit=${limit}`);

export const getUsersListSearch = (page, limit, search) =>
  API.get(`/Users?page=${page}&limit=${limit}&search=${search}`);

export const editUserinfo = (id, data) =>
  API.patch(`/Users/${id}`, JSON.stringify({ data }), {
    headers: { "Content-Type": "application/json" },
  });

export const getUserInfo = (id) => API.get(`/Users/${id}`);

export const addnewUser = (data) =>
  API.post(`/Users`, JSON.stringify({ data }), {
    headers: { "Content-Type": "application/json" },
  });

export const createNewUser = (data, userId) =>
  API.post(`/Users/me/${userId}`, JSON.stringify({ data }), {
    headers: { "Content-Type": "application/json" },
  });

export const getCurrentUser = () => API.get(`/Users/me`);

export const getAccessRightsData = () => API.get(`/Users/me/TeamAccess`);

export const getUserClaims = () =>
  API.get(`https://auth.linq.legal/connect/userinfo`);

export const getCompany = () => API.get(`/Company`);

export const updateCompany = (id, data) =>
  API.patch(`/Company/${id}`, JSON.stringify({ data }), {
    headers: { "Content-Type": "application/json" },
  });
export const createCompany = (id, code, data) =>
  API.post(`/Company/${id}/${code}`, JSON.stringify({ data }), {
    headers: { "Content-Type": "application/json" },
  });
export const completeCompany = (id, data) =>
  API.patch(`/Company/${id}/SetupCompleted`, JSON.stringify(data), {
    headers: { "Content-Type": "application/json" },
  });

export const getBranches = (page, limit) =>
  API.get(`/Branches?page=${page}&limit=${limit}`);

export const getBranchesnames = (page, limit) =>
  API.get(`/Branches?active=true`);

export const addnewbranch = (data) =>
  API.post(`/Branches`, JSON.stringify({ data }), {
    headers: { "Content-Type": "application/json" },
  });

export const editbranchinfo = (id, data) =>
  API.patch(`/Branches/${id}`, JSON.stringify({ data }), {
    headers: { "Content-Type": "application/json" },
  });

export const getBranchnames = () =>
  API.get(`/branches?fields=branchId,branchName&active=true`);

export const getVatcodes = (page, limit) =>
  API.get(`/Accounts/VatCodes?page=${page}&limit=${limit}`);

export const addnewVat = (data) =>
  API.post(`/Accounts/VatCodes`, JSON.stringify({ data }), {
    headers: { "Content-Type": "application/json" },
  });

export const editVatinfo = (id, data) =>
  API.patch(`/Accounts/VatCodes/${id}`, JSON.stringify({ data }), {
    headers: { "Content-Type": "application/json" },
  });

export const getCurrency = (page, limit) =>
  API.get(`/Accounts/Currencies?page=${page}&limit=${limit}`);

export const editCurrencyinfo = (id, data) =>
  API.patch(`/Accounts/Currencies/${id}`, JSON.stringify({ data }), {
    headers: { "Content-Type": "application/json" },
  });

export const getCategoryAllList = (page, limit) =>
  API.get(`/WorkTypes?page=${page}&limit=${limit}`);

export const editCategory = (id, data) =>
  API.patch(`/WorkTypes/${id}`, JSON.stringify({ data }), {
    headers: { "Content-Type": "application/json" },
  });

export const getWorkType = (id) => API.get(`/WorkTypes/${id}`);

export const getWorkTypeByDepartment = (departmentId) =>
  API.get(`/WorkTypes?departmentId=${departmentId}&orderby=name&active=true`);

export const addCategory = (data) =>
  API.post(`/WorkTypes`, JSON.stringify({ data }), {
    headers: { "Content-Type": "application/json" },
  });
export const getActivityAllList = () => API.get(`/ActivityTypes`);

export const editActivity = (id, data) =>
  API.patch(`/ActivityTypes/${id}`, JSON.stringify({ data }), {
    headers: { "Content-Type": "application/json" },
  });
export const addActivity = (data) =>
  API.post(`/ActivityTypes`, JSON.stringify({ data }), {
    headers: { "Content-Type": "application/json" },
  });

export const getTimeTypesList = () => API.get(`/Time/TimeTypes`);

export const editTimeType = (id, data) =>
  API.patch(`/Time/TimeTypes/${id}`, JSON.stringify({ data }), {
    headers: { "Content-Type": "application/json" },
  });

export const addTimeType = (data) =>
  API.post(`/Time/TimeTypes`, JSON.stringify({ data }), {
    headers: { "Content-Type": "application/json" },
  });

export const getActiveTimeTypes = () =>
  API.get(`/Time/TimeTypes?active=true&orderby=name`);

export const getDepartmentAllList = (page, limit) =>
  API.get(`/Departments?page=${page}&limit=${limit}`);

export const editDepartment = (id, data) =>
  API.patch(`/Departments/${id}`, JSON.stringify({ data }), {
    headers: { "Content-Type": "application/json" },
  });
export const addDepartment = (data) =>
  API.post(`/Departments`, JSON.stringify({ data }), {
    headers: { "Content-Type": "application/json" },
  });

export const getProfitCostDetails = () => API.get(`/Bill/FixedFeeHeaders`);

export const getActiveFeeHeaders = () =>
  API.get(
    `/Bill/FixedFeeHeaders?active=true&type=Profitcost&Fields=fixedFeeHeaderID,name`
  );

export const addnewCurrency = (data) =>
  API.post(`/Accounts/Currencies`, JSON.stringify({ data }), {
    headers: { "Content-Type": "application/json" },
  });

export const getApplications = (page, limit) => API.get(`/Applications`);
export const getDocTypes = (page, limit) => API.get(`/DocumentRegister/Types`);

export const getActiveApplications = (page, limit) =>
  API.get(`/Applications?active=true`);

export const addnewApplication = (data) =>
  API.post(`/Applications`, JSON.stringify({ data }), {
    headers: { "Content-Type": "application/json" },
  });

export const addNewDocType = (data) =>
  API.post(`/DocumentRegister/Types`, JSON.stringify({ data }), {
    headers: { "Content-Type": "application/json" },
  });

export const getBillingTargets = (id) =>
  API.get(`/targets/${id}/Billing?Active=true`);

export const editBillingTargets = (id, data) =>
  API.put(`/targets/${id}/Billing`, JSON.stringify({ data }), {
    headers: { "Content-Type": "application/json" },
  });

export const editAccessibleUsers = (id, data) =>
  API.put(`/Matters/${id}/UserAccess`, JSON.stringify({ data }), {
    headers: { "Content-Type": "application/json" },
  });

export const editTimeTargets = (id, data) =>
  API.put(`/targets/${id}/Time`, JSON.stringify({ data }), {
    headers: { "Content-Type": "application/json" },
  });

export const getTimeTargets = (id) => API.get(`/Targets/${id}/Time`);

export const editApplication = (id, data) =>
  API.patch(`/Applications/${id}`, JSON.stringify({ data }), {
    headers: { "Content-Type": "application/json" },
  });

export const editDocType = (id, data) =>
  API.patch(`/DocumentRegister/Types/${id}`, JSON.stringify({ data }), {
    headers: { "Content-Type": "application/json" },
  });

export const addProfitCostTemplate = (data) =>
  API.post(`/Bill/FixedFeeHeaders`, JSON.stringify({ data }), {
    headers: { "Content-Type": "application/json" },
  });

export const getActiveCurrency = () =>
  API.get(`/Accounts/Currencies?active=true`);

export const getTransactionPeriod = (page, limit) =>
  API.get(`/TransactionPeriods`);

export const getCurrentSessionTransactionPeriod = (sessionPeriod) =>
  API.get(`/TransactionPeriods/Current?sessionPeriod=${sessionPeriod}`);

export const getCurrentTransactionPeriod = () =>
  API.get(`/TransactionPeriods/Current`);

export const updateTransactionPeriod = (data) =>
  API.post(`/TransactionPeriods`, JSON.stringify({ data }), {
    headers: { "Content-Type": "application/json" },
  });

export const createposting = (data) =>
  API.post(`/Accounts/Postings`, JSON.stringify({ data }), {
    headers: { "Content-Type": "application/json" },
  });

export const createChitRequest = (data) =>
  API.post(`/Chits`, JSON.stringify({ data }), {
    headers: { "Content-Type": "application/json" },
  });

export const createpostingJournal = (data) =>
  API.post(`/Accounts/Postings`, JSON.stringify({ data }), {
    headers: { "Content-Type": "application/json" },
  });

export const getNominalRef = (
  page,
  limit,
  nominalRef,
  IncludeReversals,
  FromDate,
  ToDate
) => {
  let url = `/Accounts/Postings/Nominal/${nominalRef}`;
  if (IncludeReversals) {
    url += `?IncludeReversals=${IncludeReversals}&page=${page}&limit=${limit}`;
  }
  if (!IncludeReversals) {
    url += `?page=${page}&limit=${limit}`;
  }
  if (FromDate) {
    url += `&FromDate=${FromDate}`;
  }
  if (ToDate) {
    url += `&ToDate=${ToDate}`;
  }
  return API.get(url);
};

export const getSupplierRef = (
  page,
  limit,
  supplierRef,
  IncludeReversals,
  FromDate,
  ToDate,
  type
) => {
  let url = `/Accounts/Postings/Supplier/${supplierRef}`;
  if (type) {
    url += `?type=${type}`;
  }
  if (IncludeReversals) {
    url += `?IncludeReversals=${IncludeReversals}&page=${page}&limit=${limit}`;
  }
  if (!IncludeReversals) {
    url += `?page=${page}&limit=${limit}`;
  }
  if (FromDate) {
    url += `&FromDate=${FromDate}`;
  }
  if (ToDate) {
    url += `&ToDate=${ToDate}`;
  }
  return API.get(url);
};

export const getSuppliersByRef = (supplierRef) =>
  API.get(`/Accounts/Suppliers/Ref/${supplierRef}`);

export const getSupplierBalances = (supplierRef, FromDate, ToDate, type) => {
  let url = `/Accounts/Suppliers/Ref/${supplierRef}/Balances?FromDate=${FromDate}&ToDate=${ToDate}`;
  if (type) {
    url += `&type=${type}`;
  }
  return API.get(url);
};

export const getPostingtypes = (type) => {
  function getGroup(type) {
    switch (type) {
      case CONSTANT.OP:
        return "office";
      case CONSTANT.CP:
        return "client";
      case CONSTANT.BP:
        return "all";
      case CONSTANT.BNKP:
        return "bank";
      case CONSTANT.PI:
        return "purchase";
      case CONSTANT.DIS:
        return "disbursement";
      case CONSTANT.TRANS:
        return "transfers";
      default:
        return "all";
    }
  }
  return API.get(
    `/Accounts/postingtypes?fields=code,name&group=${getGroup(type)}`
  );
};

export const getChitPostingtypes = () =>
  API.get(`/Accounts/postingtypes?group=chits`);

export const getOfficePostingtypes = () =>
  API.get(`/Accounts/postingtypes?group=office`);

export const getClientPostingtypes = () =>
  API.get(`/Accounts/postingtypes?group=bank`);

export const getSupplierrefs = () => API.get(`/Accounts/Suppliers?active=true`);

export const getConsultantRefs = () =>
  API.get(`/Accounts/Suppliers?type=Consultant&active=true`);

export const getMattersRef = () => API.get(`/Matters?fields=matterid`);

export const getfeeearner = () => API.get(`/Users?active=true&feeearner=true`);

export const getactiveVats = () => API.get(`/Accounts/VatCodes?active=true`);

export const getMatters = () => API.get(`/Matters/?orderby=matterRef`);

export const URL = `${baseURL}/Matters?`;

export const ClientURL = `${baseURL}/Clients?`;

export const getPostingdetails = (id) => API.get(`/Accounts/Postings/${id}`);
export const getEmailBody = (id) => API.get(`/documents/${id}/email`);

export const getMattersdetails = (id) => API.get(`/Matters/${id}/basic`);

export const getMatterAllInfo = (id) => API.get(`/Matters/${id}`);

export const getMattersBalances = (id) => API.get(`/Accounts/Postings/Matters/${id}/Balances`);

export const getMatterDocDuplicate = (matterId, activityId) =>
  API.get(`/MatterActivities/${matterId}/${activityId}/duplicate`);

export const getMattersPostings = (id, page, limit) =>
  API.get(`/Accounts/Postings//Matters/${id}?Page=${page}&Limit=${limit}`);

export const getNominalJournalPosting = () =>
  API.get(`Nominals?archived=false&controlNominal=false`);

export const getDocument = (id, specificVersion) => {
  if (specificVersion) {
    return API.get(`Documents/File/${id}`);
  } else {
    return API.get(`Documents/${id}`);
  }
};
export const getDocumentEditor = (id, matterId) =>
  API.get(`documents/${id}/Edit/${matterId}`);

export const saveDocumentEditor = (id, matterId) =>
  API.post(`documents/${id}/Edit/${matterId}`);

export const getSumsubLevels = () => API.get(`/Sumsub/KycLevels`);

export const getSumsubSettings = () => API.get(`/Sumsub/Settings`);

export const saveSumsubSettings = (data) =>
  API.post(`/Sumsub/Settings`, JSON.stringify(data), {
    headers: { "Content-Type": "application/json" },
  });

export const postSumsubToken = (data) =>
  API.post(`sumsub/token`, JSON.stringify(data), {
    headers: { "Content-Type": "application/json" },
  });

export const cancelDocumentEditor = (id, matterId) =>
  API.delete(`documents/${id}/Edit/${matterId}`);

export const disconnectSumsub = () => API.delete(`/sumsub/token`);

export const createFileEntry = (file, data) =>
  API.post(`/Documents/File/${file} `, JSON.stringify(data), {
    headers: { "Content-Type": "application/json" },
  });

export const getDocIntelligenceData = (fileId, data) =>
  API.get(`/documents/File/${fileId}/Intelligence?analysisModel=iddocument`);

export const uploadCompleted = (fileid) =>
  API.patch(`/Documents/File/${fileid}/uploaded`, {
    headers: { "Content-Type": "application/json" },
  });

export const getSingleContact = (contactId) =>
  API.get(`/Contacts/${contactId}`);

export const getContactsList = (page, limit, parameter) =>
  API.get(`/Contacts?page=${page}&limit=${limit}&orderby=${parameter}`);

export const getContactsListAll = () => API.get(`/Contacts`);

export const getContactsListSearch = (search) =>
  API.get(`/Contacts?search=${search}`);

export const getClientContactsList = (page, limit, parameter) =>
  API.get(
    `/Contacts?ClientsOnly=true&page=${page}&limit=${limit}&orderby=${parameter}`
  );

export const getChitPostingList = (page, limit, parameter) =>
  API.get(`/Chits?${parameter}&page=${page}&limit=${limit}`);

export const getSingleMatterActivity = (matterId, activityId) =>
  API.get(`/MatterActivities/${matterId}/${activityId}`);

export const getContactsInfo = (id) => API.get(`/Contacts/${id}`);

export const createNewContact = (data, type) =>
  API.post(`/Contacts`, JSON.stringify({ data }), {
    headers: { "Content-Type": "application/json" },
  });

export const editContact = (id, data) =>
  API.patch(`/Contacts/${id}`, JSON.stringify({ data }), {
    headers: { "Content-Type": "application/json" },
  });

export const updateMatterActivity = (matterId, id, data) =>
  API.patch(`/MatterActivities/${matterId}/${id}`, JSON.stringify({ data }), {
    headers: { "Content-Type": "application/json" },
  });

export const createTimeFunctionality = (data) =>
  API.post(`/Time`, JSON.stringify({ data }), {
    headers: { "Content-Type": "application/json" },
  });

export const getDisbursementMatterList = (matterId) =>
  API.get(`/Accounts/Postings/Matters/${matterId}/UnpaidDisb`);

export const getPaySupplierList = (supplierId, transactionType, type) => {
  let url = `/Accounts/Postings/Supplier/${supplierId}/UnpaidDisb`;

  if (transactionType) {
    url += `?transactiontype=${transactionType}`;
  }
  if (type) {
    url += `?type=${type}`;
  }

  return API.get(url);
};

export const getUnpaidMatterList = (matterId) =>
  API.get(`/Accounts/Postings/Matters/${matterId}/UnpaidSales`);

export const getMatterTimeRecords = (matterId) =>
  API.get(`/Time/Matter/${matterId}?billed=false`);

export const getMatterDefaults = (matterId) =>
  API.get(`/matters/${matterId}/defaults`);

export const getMatterDisbRecords = (matterId) =>
  API.get(`/Accounts/Postings/Matters/${matterId}/UnbilledDisb`);

export const getPostingDocuments = (transcNo) =>
  API.get(`/Accounts/Postings/${transcNo}/Documents`);

export const postBill = (data) =>
  API.post(`/Bill`, JSON.stringify({ data }), {
    headers: { "Content-Type": "application/json" },
  });

export const editFeeHeader = (id, data) =>
  API.patch(`/Bill/FixedFeeHeaders/${id}`, JSON.stringify({ data }), {
    headers: { "Content-Type": "application/json" },
  });

export const createFixedFeeHeaderItems = (id, data) =>
  API.post(`/Bill/FixedFeeItems/${id}`, JSON.stringify({ data }), {
    headers: { "Content-Type": "application/json" },
  });

export const getFeeHeaderItems = (id) => API.get(`/Bill/FixedFeeItems/${id}`);

export const getActiveFeeItems = (id) =>
  API.get(`/Bill/FixedFeeItems/${id}?active=true`);

export const getActivePermissions = (id) =>
  API.get(`/Matters/${id}/UserAccess`);

export const editFeeHeaderItems = (id, data) =>
  API.put(`/Bill/FixedFeeItems/${id}`, JSON.stringify({ data }), {
    headers: { "Content-Type": "application/json" },
  });

export const getFileById = (fileID) =>
  API.get(`/Documents/File/${fileID}`, {
    headers: { "Content-Type": "application/json" },
  });

export const getActivityTypes = () =>
  API.get(`/ActivityTypes?orderby=name&active=true`);

export const getMatterActivities = (
  id,
  feeEarner,
  FromDate,
  ToDate,
  typeId,
  page,
  limit,
  parameter
) => {
  let url = `/MatterActivities/${id}?active=true&page=${page}&limit=${limit}`;
  // if (orderby.sort[0]) {
  //   url += `?orderby=${orderby.sort[0]?.field}${orderby.sort[0]?.dir === "asc" ? "" : " desc"
  //     }`;
  // }

  if (typeId) {
    url += `&typeId=${typeId}`;
  }
  if (feeEarner) {
    feeEarner?.map((item) => {
      url += `&FeeEarnerRef=${item}`;
    });
  }
  if (FromDate) {
    url += `&FromDate=${FromDate}`;
  }
  if (ToDate) {
    url += `&ToDate=${ToDate}`;
  }
  if (parameter) {
    url += `&orderby=${parameter}, activityId desc`;
  }
  return API.get(url);
};

export const getDashboardMatterData = (
  teamId,
  feeEarnerRef,
  branchId,
  fromDate,
  toDate
) => {
  let url = `/Dashboard/Matters?`;
  const parameters = constructApiQuery(
    branchId || null,
    teamId || null,
    feeEarnerRef || null
  );

  if (fromDate) {
    url += `&FromDate=${fromDate}`;
  }
  if (toDate) {
    url += `&ToDate=${toDate}`;
  }

  if (Object.keys(parameters).length) {
    url += `&${Object.keys(parameters)[0]}=${Object.values(parameters)[0]}`;
  }
  return API.get(url);
};

export const getDashboardDebtorsData = (
  teamId,
  feeEarnerRef,
  branchId,
  fromDate,
  toDate
) => {
  let url = `/Dashboard/Debtors?`;
  const parameters = constructApiQuery(
    branchId || null,
    teamId || null,
    feeEarnerRef || null
  );

  if (fromDate) {
    url += `&FromDate=${fromDate}`;
  }
  if (toDate) {
    url += `&ToDate=${toDate}`;
  }

  if (Object.keys(parameters).length) {
    url += `&${Object.keys(parameters)[0]}=${Object.values(parameters)[0]}`;
  }
  return API.get(url);
};

export const getDashboardCreditorsData = (
  teamId,
  feeEarnerRef,
  branchId,
  fromDate,
  toDate
) => {
  let url = `/Dashboard/Creditors?`;
  const parameters = constructApiQuery(
    branchId || null,
    teamId || null,
    feeEarnerRef || null
  );

  if (fromDate) {
    url += `&FromDate=${fromDate}`;
  }
  if (toDate) {
    url += `&ToDate=${toDate}`;
  }

  if (Object.keys(parameters).length) {
    url += `&${Object.keys(parameters)[0]}=${Object.values(parameters)[0]}`;
  }
  return API.get(url);
};

export const getDashboardTimeTargetData = (
  teamId,
  feeEarnerRef,
  branchId,
  fromDate,
  toDate
) => {
  let url = `/Dashboard/TimeTarget?`;
  const parameters = constructApiQuery(
    branchId || null,
    teamId || null,
    feeEarnerRef || null
  );
  if (fromDate) {
    url += `&FromDate=${fromDate}`;
  }
  if (toDate) {
    url += `&ToDate=${toDate}`;
  }

  if (Object.keys(parameters).length) {
    url += `&${Object.keys(parameters)[0]}=${Object.values(parameters)[0]}`;
  }
  return API.get(url);
};

export const getDashboardBillingTargetData = (
  teamId,
  feeEarnerRef,
  branchId,
  fromDate,
  toDate
) => {
  let url = `/Dashboard/BillingTarget?`;
  const parameters = constructApiQuery(
    branchId || null,
    teamId || null,
    feeEarnerRef || null
  );

  if (fromDate) {
    url += `&FromDate=${fromDate}`;
  }
  if (toDate) {
    url += `&ToDate=${toDate}`;
  }

  if (Object.keys(parameters).length) {
    url += `&${Object.keys(parameters)[0]}=${Object.values(parameters)[0]}`;
  }
  return API.get(url);
};

export const getClientsList = (page, limit, parameter, filterParameters) => {
  let url = `/clients?`;
  if (page) {
    url += `&page=${page}`;
  }
  if (limit) {
    url += `&limit=${limit}`;
  }
  if (parameter) {
    url += `&orderby=${parameter}`;
  }

  const parameters = constructApiQuery(
    filterParameters?.branch || null,
    filterParameters?.teamId || null,
    filterParameters?.feeEarnerRef || null
  );

  if (Object.keys(parameters).length) {
    url += `&${Object.keys(parameters)[0]}=${Object.values(parameters)[0]}`;
  }
  if (filterParameters?.search) {
    url += `&search=${filterParameters?.search}`;
  }

  return API.get(url);
};

export const getMattersList = (
  page,
  limit,
  parameter,
  filterParameters,
  status
) => {
  let url = `/matters?`;
  if (page) {
    url += `&page=${page}`;
  }
  if (limit) {
    url += `&limit=${limit}`;
  }
  if (parameter) {
    url += `&orderby=${parameter}`;
  }
  if (status) {
    url += `&status=${status}`;
  }

  const parameters = constructApiQuery(
    filterParameters?.branch || null,
    filterParameters?.teamId || null,
    filterParameters?.feeEarnerRef || null
  );

  if (Object.keys(parameters).length) {
    url += `&${Object.keys(parameters)[0]}=${Object.values(parameters)[0]}`;
  }
  if (filterParameters.openFrom) {
    url += `&OpenStartDate=${filterParameters.openFrom}`;
  }
  if (filterParameters.openTo) {
    url += `&OpenEndDate=${filterParameters.openTo}`;
  }
  if (filterParameters.search) {
    url += `&search=${filterParameters.search}`;
  }
  return API.get(url);
};

export const getMyMattersList = (page, limit, orderBy, filterParameters) => {
  let url = `/matters?`;
  if (page) {
    url += `&page=${page}`;
  }
  if (limit) {
    url += `&limit=${limit}`;
  }
  if (orderBy) {
    url += `&orderby=${orderBy}`;
  }
  if (filterParameters.appCode) {
    url += `&AppCode=${filterParameters.appCode}`;
  }
  if (filterParameters.feeEarnerRef) {
    url += `&FeeEarnerRef=${filterParameters.feeEarnerRef}`;
  }
  if (filterParameters.branch) {
    url += `&BranchId=${filterParameters.branch}`;
  }
  if (filterParameters.openFrom) {
    url += `&OpenStartDate=${filterParameters.openFrom}`;
  }
  if (filterParameters.openTo) {
    url += `&OpenEndDate=${filterParameters.openTo}`;
  }
  if (filterParameters.search) {
    url += `&search=${filterParameters.search}`;
  }
  return API.get(url);
};

export const createNewTeam = (data) =>
  API.post(`/Teams`, JSON.stringify({ data }), {
    headers: { "Content-Type": "application/json" },
  });

export const editTeam = (data, id) =>
  API.patch(`/Teams/${id}`, JSON.stringify({ data }), {
    headers: { "Content-Type": "application/json" },
  });

export const getTeamsById = (id) => API.get(`/Teams/${id}/users`);

export const getTeams = () => API.get(`/Teams`);

export const getUserandFeeearners = () =>
  API.get(`/Users?active=true&isFeeEarner=true`);

export const addRemoveTeamMembers = (data, id) =>
  API.put(`/Teams/${id}/users`, JSON.stringify({ data }), {
    headers: { "Content-Type": "application/json" },
  });

export const getSecurityProfiles = () => API.get(`/Profiles`);

export const getActiveSecurityProfiles = () => API.get(`/Profiles?active=true`);

export const createNewProfile = (data) =>
  API.post(`/Profiles`, JSON.stringify({ data }), {
    headers: { "Content-Type": "application/json" },
  });

export const editSecurityProfile = (data, id) =>
  API.patch(`/Profiles/${id}`, JSON.stringify({ data }), {
    headers: { "Content-Type": "application/json" },
  });

export const getSecurityProfilesItems = () => API.get(`/Profiles/Items`);

export const getSpecificProfiles = (id) => API.get(`/Profiles/${id}`);

export const updateSpecificProfiles = (data, id) =>
  API.patch(`/Profiles/${id}`, JSON.stringify({ data }), {
    headers: { "Content-Type": "application/json" },
  });

export const vatReturnConnect = (data) =>
  API.post(`/VatReturn/HMRC/Connect`, JSON.stringify({ data }), {
    headers: { "Content-Type": "application/json" },
  });

export const getVatReturnInfo = () => API.get(`/VatReturn/HMRC/AuthURL`);

export const checkVatReturnNo = (number) =>
  API.get(`/VatReturn/CheckVATNo/${number}`);

export const getVatObligations = () => API.get(`/vatreturn/obligations`);

export const getVatReturnHistory = () => API.get(`/VatReturn/VatHistory`);

export const getVatReturnData = (fromDate, toDate) =>
  API.get(`/VatReturn?fromdate=${fromDate}&todate=${toDate}`);

export const getClioInfo = (redirectLocation) =>
  API.get(`/Clio/AuthURL?redirectURL=${redirectLocation}`);

export const disconnectClio = () =>
  API.post(`/clio/disconnect`, {
    headers: { "Content-Type": "application/json" },
  });

export const clioConnect = (data) =>
  API.post(`/Clio/Connect`, JSON.stringify({ data }), {
    headers: { "Content-Type": "application/json" },
  });

export const clioSync = () =>
  API.post(`/Clio/SyncData`, {
    headers: { "Content-Type": "application/json" },
  });

export const hmrcSync = () =>
  API.post(`/VatReturn/HMRC/SyncObligations`, {
    headers: { "Content-Type": "application/json" },
  });

export const clioStatus = () => API.get(`/Clio/Status`);

export const getIntegrationDetails = () => API.get(`/integrations`);

export const importSampleChit = () => API.get(`/Chits/Import`);

export const getNotifications = () => API.get(`/Notifications`);

export const updateNotifications = (data) =>
  API.put(`/Notifications/Seen`, JSON.stringify(data), {
    headers: { "Content-Type": "application/json" },
  });

export const getClientRefExample = (
  clientName,
  lettersLength,
  numbersLength
) => {
  if (lettersLength === undefined && numbersLength === undefined) {
    return API.get(`/Clients/NewClientRef?clientname=${clientName}`);
  }
  return API.get(
    `/Clients/NewClientRef?clientname=${clientName}&lettersLength=${lettersLength}&numbersLength=${numbersLength}`
  );
};

export const getMatterRefExample = (
  clientRef,
  selection,
  separator,
  numbersLength
) => {
  if (
    selection === undefined &&
    numbersLength === undefined &&
    separator === undefined
  ) {
    return API.get(`/Matters/NewMatterRef?clientRef=${clientRef}`);
  }
  return API.get(
    `/Matters/NewMatterRef?clientRef=${clientRef}&selection=${selection}&separator=${separator}&numbersLength=${numbersLength}`
  );
};

export const getCaseWorkFlows = (appCode) =>
  API.get(`/workflows/folders?appCode=${appCode}&active=true`);

export const addDocumentTemplate = (data) =>
  API.post(`Workflows/DocumentTemplate`, JSON.stringify({ data }), {
    headers: { "Content-Type": "application/json" },
  });

export const editDocumentTemplate = (id, data) =>
  API.patch(`Workflows/DocumentTemplate/${id}`, JSON.stringify({ data }), {
    headers: { "Content-Type": "application/json" },
  });

export const addClientInterview = (data) =>
  API.post(`Workflows/ClientInterview`, JSON.stringify({ data }), {
    headers: { "Content-Type": "application/json" },
  });

export const editClientInterview = (id, data) =>
  API.patch(`Workflows/ClientInterview/${id}`, JSON.stringify({ data }), {
    headers: { "Content-Type": "application/json" },
  });

export const getClientInterviews = () => API.get(`Workflows/ClientInterview`);

export const getCaseWorkFlowsItem = (appCode, parentId) =>
  API.get(
    `/workflows?appCode=${appCode}&ParentID=${parentId}&active=true&orderby=IsFolder desc, Order`
  );

export const updateWorkFlow = (id, data) =>
  API.patch(`/Workflows/${id}`, JSON.stringify({ data }), {
    headers: { "Content-Type": "application/json" },
  });
export const updateWorkflowOrder = (appCode, parentId, data) =>
  API.patch(
    `/Workflows/Order?appCode=${appCode}&ParentID=${parentId}&active=true&orderby=IsFolder desc, Order`,
    JSON.stringify({ data }),
    {
      headers: { "Content-Type": "application/json" },
    }
  );

export const getWorkFlowTemplates = () =>
  API.get(`/workflows/DocumentTemplate`);

export const getWorkFlowTemplatesById = (id) =>
  API.get(`/workflows/DocumentTemplate/${id}`);

export const getWorkFlowClientInterviews = () =>
  API.get(`/workflows/ClientInterview`);

export const getWorkFlowClientInterviewsById = (id) =>
  API.get(`/workflows/ClientInterview/${id}`);

export const getAllWorkFlowInterviews = () => API.get(`/UserScreens`);

export const addCaseWorkflowRecord = (data) =>
  API.post(`/Workflows`, JSON.stringify({ data }), {
    headers: { "Content-Type": "application/json" },
  });

export const getWorkFlowInterviews = (userScreenId) =>
  API.get(`/UserScreens/${userScreenId}`);

export const getAllUserScreens = (screenType) =>
  API.get(`/UserScreens?Type=${screenType}`);

export const addUserScreen = (data) =>
  API.post(`/UserScreens`, JSON.stringify(data), {
    headers: { "Content-Type": "application/json" },
  });

export const updateScreen = (data, userScreenId) =>
  API.patch(`/UserScreens/${userScreenId}`, JSON.stringify(data), {
    headers: { "Content-Type": "application/json" },
  });

export const getMatterVariables = (matterId, InterviewId) =>
  API.get(`/Matters/${matterId}/Variables?InterviewId=${InterviewId}`);

export const updateMatterVariables = (matterId, data) =>
  API.put(`/Matters/${matterId}/Variables`, JSON.stringify({ data }), {
    headers: { "Content-Type": "application/json" },
  });

export const getDefaultNominals = (branchId) =>
  API.get(`/DefaultNominal/${branchId}`);

export const getNewDefaultNominals = (branchId) =>
  API.get(`/DefaultNominal/${branchId}/New`);

export const updateDefaultNominals = (branchId, data) =>
  API.post(`/DefaultNominal/${branchId}`, JSON.stringify(data), {
    headers: { "Content-Type": "application/json" },
  });

export const getSources = () => API.get(`/sources`);
export const getActiveSources = () =>
  API.get(`/sources?active=true&orderby=SourceName`);

export const editSource = (sourceId, data) =>
  API.patch(`/sources/${sourceId}`, JSON.stringify(data), {
    headers: { "Content-Type": "application/json" },
  });

export const addSource = (data) =>
  API.post(`/sources`, JSON.stringify(data), {
    headers: { "Content-Type": "application/json" },
  });

export const getReferrers = () => API.get(`/referrers`);

export const getActiveReferrers = () =>
  API.get(`/referrers?active=true&orderby=ReferrerName`);

export const editReferrer = (referrerId, data) =>
  API.patch(`/referrers/${referrerId}`, JSON.stringify(data), {
    headers: { "Content-Type": "application/json" },
  });

export const addReferrer = (data) =>
  API.post(`/referrers`, JSON.stringify(data), {
    headers: { "Content-Type": "application/json" },
  });

export const getContactsMatterData = (contactId) =>
  API.get(`/Contacts/${contactId}/Matters`);

export const getActiveContactCategories = () =>
  API.get(`/contactcategories?active=true&orderby=categoryName`);

export const createClientSetvicesTask = (matterId, data) =>
  API.post(`/ClientServices/${matterId}/Tasks`, JSON.stringify({ data }), {
    headers: { "Content-Type": "application/json" },
  });

export const getSuggestedContactCategories = (matterId) =>
  API.get(`/contactcategories/Suggested/${matterId}`);

export const getAllContactsforCategory = (categoryId, page, limit) =>
  API.get(
    `/Contacts?categoryid=${categoryId}&orderby=name&page=${page}&limit=${limit}`
  );

export const searchContactsforCategory = (categoryId, search) =>
  API.get(`/Contacts?categoryid=${categoryId}&search=${search}`);

export const getContactCategoryInfo = (categoryId) =>
  API.get(`/ContactCategories/${categoryId}`);

export const getContactDetails = (contactId) =>
  API.get(`/Contacts/${contactId}/People`);

export const attachMatterOnContact = (matterId, data) =>
  API.put(`/Matters/${matterId}/Contacts`, JSON.stringify({ data }), {
    headers: { "Content-Type": "application/json" },
  });

export const getContactsOnMatter = (matterId) =>
  API.get(`/Matters/${matterId}/Contacts?active=true`);

export const getClientNotes = (clientId) =>
  API.get(`/Clients/${clientId}/Notes`);

export const getClientDocs = (clientId) =>
  API.get(`/Clients/${clientId}/DocumentRegisters`);

export const getClientDocumentsList = (clientId) =>
  API.get(`/Clients/${clientId}/Documents`);

export const getMatterNotes = (matterId) =>
  API.get(`/Matters/${matterId}/Notes`);

export const getMatterRiskAssessmentQuestions = (matterId) =>
  API.get(`/Matters/${matterId}/RiskAssessments`);

export const getClientRiskAssessmentQuestions = (clientId) =>
  API.get(`/Clients/${clientId}/RiskAssessments`);

export const saveMatterRiskAssessmentQuestions = (matterId, data) =>
  API.post(`/Matters/${matterId}/RiskAssessments`, JSON.stringify(data), {
    headers: { "Content-Type": "application/json" },
  });
export const saveClientRiskAssessmentQuestions = (clientId, data) =>
  API.post(`/Clients/${clientId}/RiskAssessments`, JSON.stringify(data), {
    headers: { "Content-Type": "application/json" },
  });

export const addMatterNotes = (data, matterId) =>
  API.post(`/Matters/${matterId}/Notes`, JSON.stringify(data), {
    headers: { "Content-Type": "application/json" },
  });

export const updateMatterNotes = (data, noteId) =>
  API.patch(`/Matters/Notes/${noteId}`, JSON.stringify(data), {
    headers: { "Content-Type": "application/json" },
  });

export const addClientNotes = (data, clientId) =>
  API.post(`/Clients/${clientId}/Notes`, JSON.stringify(data), {
    headers: { "Content-Type": "application/json" },
  });

export const updateClientNotes = (data, noteId) =>
  API.patch(`/Clients/Notes/${noteId}`, JSON.stringify(data), {
    headers: { "Content-Type": "application/json" },
  });

export const getDocumentRegisters = (docId) =>
  API.get(`/DocumentRegister/${docId}`);

export const getActiveTypes = () =>
  API.get(`/DocumentRegister/Types?active=true&orderby=typeName`);

export const addMatterDocumentResgisters = (data, matterId) =>
  API.post(`/DocumentRegister/Matter/${matterId}`, JSON.stringify(data), {
    headers: { "Content-Type": "application/json" },
  });

export const updateDocumentResgister = (data, docId) =>
  API.patch(`/DocumentRegister/${docId}`, JSON.stringify(data), {
    headers: { "Content-Type": "application/json" },
  });

export const addClientDocumentResgisters = (data, clientId) =>
  API.post(`/DocumentRegister/Client/${clientId}`, JSON.stringify(data), {
    headers: { "Content-Type": "application/json" },
  });

export const addClientDocumentItem = (data, clientId) =>
  API.post(`/Clients/${clientId}/Documents`, JSON.stringify(data), {
    headers: { "Content-Type": "application/json" },
  });

export const updateClientDocumentItem = (data, clientId, documentId) =>
  API.patch(
    `/Clients/${clientId}/Documents/${documentId}`,
    JSON.stringify(data),
    {
      headers: { "Content-Type": "application/json" },
    }
  );

export const getMatterDocs = (matterId) =>
  API.get(`/Matters/${matterId}/DocumentRegisters`);

export const getEstimates = (matterId) =>
  API.get(`/Matters/${matterId}/Estimates`);

export const updateEstimate = (data, matterId, estimateId) =>
  API.patch(
    `/Matters/${matterId}/Estimates/${estimateId}`,
    JSON.stringify(data),
    {
      headers: { "Content-Type": "application/json" },
    }
  );

export const addEstimate = (data, matterId) =>
  API.post(`/Matters/${matterId}/Estimates`, JSON.stringify(data), {
    headers: { "Content-Type": "application/json" },
  });

export const getClientVerifications = (clientId) =>
  API.get(`/Verifications/${clientId}`);

export const getClientRiskAssessmentSettings = (clientId) =>
  API.get(`RiskAssessments/ClientType/${clientId}`);
export const getMatterRiskAssessmentSettings = (workTypeId) =>
  API.get(`RiskAssessments/WorkType/${workTypeId}`);

export const editClientRiskAssessmentSettings = (clientId, data) =>
  API.post(`RiskAssessments/ClientType/${clientId}`, JSON.stringify(data), {
    headers: { "Content-Type": "application/json" },
  });

export const editMatterRiskAssessmentSettings = (workTypeId, data) =>
  API.post(`RiskAssessments/WorkType/${workTypeId}`, JSON.stringify(data), {
    headers: { "Content-Type": "application/json" },
  });
export const getCountries = () => API.get(`/utilities/countries`);

export const updateProof = (data, clientId, proofId) =>
  API.patch(
    `/Verifications/${clientId}/Proof/${proofId}`,
    JSON.stringify(data),
    {
      headers: { "Content-Type": "application/json" },
    }
  );

export const addProof = (data, clientId) =>
  API.post(`/Verifications/${clientId}/Proof`, JSON.stringify(data), {
    headers: { "Content-Type": "application/json" },
  });

export const addDirector = (data, clientId) =>
  API.post(`/Verifications/${clientId}/AddPerson`, JSON.stringify(data), {
    headers: { "Content-Type": "application/json" },
  });

export const addOwners = (data, clientId) =>
  API.put(`/Verifications/${clientId}/Owners`, JSON.stringify(data), {
    headers: { "Content-Type": "application/json" },
  });

export const addRiskAssessmentQuestion = (data) =>
  API.post(`/RiskAssessments/Questions`, JSON.stringify(data), {
    headers: { "Content-Type": "application/json" },
  });

export const editRiskAssessmentQuestion = (data, id) =>
  API.patch(`/RiskAssessments/Questions/${id}`, JSON.stringify(data), {
    headers: { "Content-Type": "application/json" },
  });
export const getRiskAssessmentQuestions = (workTypeId) =>
  API.get(`/RiskAssessments/Questions`);

export const getMatterRates = (matterId, userRef) =>
  API.get(`/Matters/${matterId}/Rates/${userRef}`);

export const getRateCategories = () =>
  API.get(`/rates/RateCategories?active=true`);

export const getUserRatebyCategory = (categoryId) =>
  API.get(`/rates/${categoryId}`);

export const updateUserRates = (categoryId, data) =>
  API.put(`/rates/${categoryId}`, JSON.stringify({ data }), {
    headers: { "Content-Type": "application/json" },
  });

export const createRateCategory = (data) =>
  API.post(`/rates/RateCategories`, JSON.stringify({ data }), {
    headers: { "Content-Type": "application/json" },
  });

export const updateRateCategory = (categoryId, data) =>
  API.patch(`/rates/RateCategories/${categoryId}`, JSON.stringify({ data }), {
    headers: { "Content-Type": "application/json" },
  });

export const searchCompanies = (term) =>
  API.get(`/utilities/companieshouse/SearchCompanies?term=${term}`);

export const searchConflictCheck = (matterId, data) =>
  API.post(`/CaseFile/${matterId}/conflictcheck`, JSON.stringify({ data }), {
    headers: { "Content-Type": "application/json" },
  });

export const saveConflictCheckResults = (matterId, data) =>
  API.post(
    `/CaseFile/${matterId}/ConflictCheck/Save`,
    JSON.stringify({ data }),
    {
      headers: { "Content-Type": "application/json" },
    }
  );

export const getCompanyProfile = (companyNumber) =>
  API.get(`/utilities/companieshouse/CompanyProfile/${companyNumber}`);

export const getDocumentVerions = (documentId) =>
  API.get(`/documents/${documentId}/versions`);

export const downloadDocument = (documentId) =>
  API.get(`/documents/${documentId}/download`, {
    responseType: "blob", // Important for correct file download
  });

export const downloadDocumentAsPDF = (documentId) =>
  API.get(`/documents/${documentId}/download/pdf`, {
    responseType: "blob",
  });

export const downloadDocumentsAsZip = (zipFileName, documentIds) =>
  API.post(
    `/documents/download/zip/${zipFileName}`,
    JSON.stringify(documentIds),
    {
      responseType: "blob",
      headers: { "Content-Type": "application/json" },
    }
  );

export const shareDocument = (matterId, data) =>
  API.post(`/casefile//${matterId}/sharedocument`, JSON.stringify({ data }), {
    headers: { "Content-Type": "application/json" },
  });

export const runDocumentTemplate = (matterId, documentId) =>
  API.get(`/DocumentTemplates/RunTemplate/${matterId}/${documentId}`);

export const runClientInterview = (matterId, workflowItemId) =>
  API.get(`/ClientInterviews/${matterId}/${workflowItemId}`);

export const sendInterviewtoClient = (matterId, workflowItemId, data) =>
  API.post(
    `/ClientInterviews/${matterId}/${workflowItemId}`,
    JSON.stringify(data),
    {
      headers: { "Content-Type": "application/json" },
    }
  );
export function constructApiQuery(branch, team, feeEarner) {
  let query = {};
  if (feeEarner && feeEarner !== "ALL") {
    query.FeeEarnerRef = feeEarner;
  } else if (team && team !== -2) {
    query.TeamID = team;
  } else if (branch && branch > 0) {
    query.BranchId = branch;
  }

  return query;
}

export const getMatterTasks = (
  matterId,
  completed,
  FromDate,
  ToDate,
  page,
  limit,
  parameter
) => {
  let url = `/Tasks/matter/${matterId}?active=true&page=${page}&limit=${limit}&completed=false`;
  if (FromDate) {
    url += `&FromDate=${FromDate}`;
  }
  if (ToDate) {
    url += `&ToDate=${ToDate}`;
  }
  if (parameter) {
    url += `&orderby=${parameter}`;
  }
  if (completed) {
    url = url.replace("&completed=false", "");
  }

  return API.get(url);
};

export const addMatterTask = (matterId, data) =>
  API.post(`/Tasks/matter/${matterId}`, JSON.stringify({ data }), {
    headers: { "Content-Type": "application/json" },
  });

export const updateMatterTask = (taskId, data) =>
  API.patch(`/Tasks/${taskId}`, JSON.stringify({ data }), {
    headers: { "Content-Type": "application/json" },
  });

export const getClientMessages = (matterId) =>
  API.get(`/ClientServices/${matterId}/Messages`);
export const sendClientMessage = (matterId, data) =>
  API.post(`/ClientServices/${matterId}/Messages`, JSON.stringify({ data }), {
    headers: { "Content-Type": "application/json" },
  });

export const getSuggestedEmails = (matterId) =>
  API.get(`/Matters/${matterId}/Contacts/email`);

export const getNewEmail = (matterId, units) =>
  API.get(`/Matters/${matterId}/newemail?units=${units}`);

export const postESigning = (matterId, activityId, data) =>
  API.post(
    `/MatterActivities/${matterId}/${activityId}/ESign`,
    JSON.stringify({ data }),
    {
      headers: { "Content-Type": "application/json" },
    }
  );

export const getApplicationUserSetting = () =>
  API.get(`/Settings/application/user`);

export const getClientServicesTasks = (matterId) =>
  API.get(`/ClientServices/${matterId}/Tasks`);

export const getClientLoginAccess = (clientId, matterId) =>
  API.get(`/ClientServices/Portal/${clientId}/loginlink?matterid=${matterId}`);

export const getWorkTypeEngagementLetters = (workTypeId) =>
  API.get(`/WorkTypes/${workTypeId}/letters?active=true`);

export const updateWorkTypeEngagementLetters = (workTypeId, data) =>
  API.patch(`/WorkTypes/${workTypeId}/letters`, JSON.stringify({ data }), {
    headers: { "Content-Type": "application/json" },
  });

export const runTemplateForEngagementLetter = (templateId, matterId) =>
  API.get(`/Workflows/DocumentTemplate/${templateId}/RunTemplate/${matterId}`);

export const getClientTasksTemplate = (workTypeId) =>
  API.get(`/WorkTypes/${workTypeId}/ClientTasks`);

export const startOnboarding = (data) =>
  API.post(`/Onboarding`, JSON.stringify({ data }), {
    headers: { "Content-Type": "application/json" },
  });

export const updateOnboarding = (data, onboardingId) =>
  API.patch(`/Onboarding/${onboardingId}`, JSON.stringify({ data }), {
    headers: { "Content-Type": "application/json" },
  });

export const getOnboarding = (onboardingId) =>
  API.get(`/Onboarding/${onboardingId}`);
