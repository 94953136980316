export const ethnicity = [
  "Mixed",
  "White British",
  "Other White",
  "Other Asian",
  "Mixed White and Asian",
  "Mixed White and Black Caribbean",
  "White Gypsy or Irish Traveller",
  "British people",
  "Irish people",
];

export const genders = ["Male", "Female"];
export const disability = ["Yes", "No"];

export const typesList = [
  { typeId: 0, typeName: "Company" },
  { typeId: 1, typeName: "Person" },
];
export const companyViewTab = [
  "Contact Information",
  "Statement & Billing",
  "Credits & Defaults",
  "Wills & Deeds",
  "Documents",
  "Notes",
  "ID Check",
  "Risk Assessment",
];
export const companyEditTab = [
  "Contact Information",
  "Company Details",
  "Statement & Billing",
  "Credit & Defaults",
];
export const personViewTab = [
  "Contact Information",
  "Statement & Billing",
  "Credits & Defaults",
  "Wills & Deeds",
  "Documents",
  "Notes",
  "ID Check",
  "Risk Assessment",
];
export const personEditTab = [
  "Contact Information",
  "Statement & Billing",
  "Credit & Defaults",
];
