import React, { useState, useEffect, useRef } from 'react';
import { Input } from '@progress/kendo-react-inputs';



const RateCell = (props) => {
    const [value, setValue] = useState(props.dataItem[props.field]);
    const inputRef = useRef(props.dataIndex);
  
    useEffect(() => {
      if (props.isFocused) {
        inputRef.current.focus();
      }
    }, [props.isFocused]);
  
    const handleChange = (event) => {
      setValue(event.target.value);
  
      props?.onChange({
        dataIndex: props.dataIndex,
        dataItem: props.dataItem,
        field: props.field,
        syntheticEvent: event.syntheticEvent,
        value: event.target.value,
      });
    };
  
    return (
      <td>
        <Input
          ref={inputRef}
          key={props.dataIndex}
          value={value}
                onChange={handleChange}
                className="float-end align-items-end"
        />
      </td>
    );
  };

  export default RateCell;