import * as React from "react";
import { Button } from "@progress/kendo-react-buttons";
import { Dialog } from "@progress/kendo-react-dialogs";
import { useState } from "react";
import { toast } from "react-toastify";
import { Label } from "@progress/kendo-react-labels";
import { filterBy } from "@progress/kendo-data-query";
import SuccessModal from "components/common/Modal/success";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import * as api from "api/index";
import { useDispatch, useSelector } from "react-redux";
import styles from "../Settings.module.scss";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { clearProfilePhoto } from "actions/currentuser.action";
import { PageTabItemTitle } from "components/tabs/TabItem/PageTabItemTitle";
import { handleApiError } from "Helper";
// import AddEditProfile from "./AddEditProfile";
// import { AccountsGrid, CaseMangementGrid, ReportsGrid } from "./SecurityGrids";
import { useNavigate } from "react-router";
import { Input, RadioGroup } from "@progress/kendo-react-inputs";
import { FormComboBox } from "components/Matters";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { FiAlertCircle } from "react-icons/fi";
import { QuestionsPopup } from "./SelectQuestionBox";
import CancelImage from "../../../assets//common/redcross.png";

const Title = (props) => {
  return (
    <div
      // className="ms-2"
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        height: "100%",
      }}
    >
      <div
        className={` ${styles["tabs-container"]} d-flex`}
        style={{
          color: props.selected === props.content ? "black" : "#757575",
          fontWeight: props.selected === props.content ? "bold" : "",
        }}
      >
        {props.content}
      </div>
    </div>
  );
};

const RiskAssessment = (props) => {
  const [openForm, setOpenForm] = React.useState(false);
  const navigate = useNavigate();
  const [tabs, setTabs] = React.useState([
    "Case Managements",
    "Accounts",
    "Reports",
  ]);

  const [profileData, setProfIlesData] = useState([]);
  const [profileInfo, setProfileInfo] = useState();
  const [selected, setSelected] = React.useState(0);
  const [profiles, setProfiles] = useState([]);
  const [caseProfiles, setCaseProfiles] = useState([]);
  const [accountProfiles, setAccountProfiles] = useState([]);
  const [reportProfiles, setReportProfiles] = useState([]);
  const [gridData, setGridData] = useState([]);
  const [departmentData, setDepartmentData] = useState([]);
  const [departmentFilter, setDepartmentfilter] = useState([]);
  const [categoryData, setCaegoryData] = useState([]);
  const [categoryFilter, setCaegoryFilter] = useState([]);
  const [riskScores, setRiskScores] = useState({
    low: 0.0,
    medium: 0.0,
    high: 0.0,
    riskAssessmentId: null,
  });
  const [type, setType] = useState("Client");
  const [comboBoxState, setComboBoxState] = useState({
    department: "",
    workType: "",
  });
  const [clientType, setClientType] = useState("Person");
  const dispatch = useDispatch();
  const [editItem, setEditItem] = React.useState({
    ProductID: 1,
  });
  const [isInActive, setIsInactive] = useState(false);
  const [addnewform, setaddnewform] = React.useState(false);
  const [modalvalue, setmodalvalue] = React.useState(false);

  const handleComboBoxChange = (e, label) => {
    if (label === "department") {
      setComboBoxState({
        [label]: e.value,
        workType: null,
      });
    } else {
      setComboBoxState({
        ...comboBoxState,
        [label]: e.value,
      });
    }
  };

  const filterDepartment = (filter) => {
    const data = departmentData.slice();
    return filterBy(data, filter);
  };
  const departmentfilterChange = (event) => {
    setDepartmentfilter(filterDepartment(event.filter));
  };

  const filterCaegory = (filter) => {
    const data = categoryData.slice();
    return filterBy(data, filter);
  };
  const cateforyFilterChange = (event) => {
    setCaegoryFilter(filterCaegory(event.filter));
  };

  const getAssessmentData = async () => {
    try {
      let data = {};
      if (type === "Client") {
        data = await api.getClientRiskAssessmentSettings(clientType);
      } else if (type === "Matter" && comboBoxState["workType"]?.workTypeID) {
        data = await api.getMatterRiskAssessmentSettings(
          comboBoxState["workType"]?.workTypeID
        );
      }
      setGridData(data?.data?.data?.questions || []);
      setRiskScores({
        low: data.data?.data?.lowRiskScore || 0,
        high: data.data?.data?.highRiskScore || 0,
        medium: data.data?.data?.mediumRiskScore || 0,
        riskAssessmentId: data.data?.data?.riskAssessmentId,
      });
    } catch (error) {
      setGridData([]);
    }
  };

  const saveAssessmentData = async () => {
    try {
      const data = {
        lowRiskScore: parseInt(riskScores["low"]),
        highRiskScore: parseInt(riskScores["high"]),
        mediumRiskScore: parseInt(riskScores["medium"]),
        questions: gridData,
        riskAssessmentId: riskScores["riskAssessmentId"],
      };
      if (type === "Client") {
        await api.editClientRiskAssessmentSettings(clientType, {
          data: data,
        });
        toast.success("Risk Assessment Data Updated");
      } else if (type === "Matter" && comboBoxState["workType"]?.workTypeID) {
        await api.editMatterRiskAssessmentSettings(
          comboBoxState["workType"]?.workTypeID,
          {
            data: data,
          }
        );
        toast.success("Risk Assessment Data Updated");
      }
    } catch (error) {
      toast.error("Unexpected Error Occured");
    }
  };

  const getDepartmentData = async () => {
    const { data } = await api.getDepartmentList();
    setDepartmentData(data.data.slice());
    setDepartmentfilter(data.data.slice());
  };

  const getCategoryData = async () => {
    const { data } = await api.getCategoryList();
    setCaegoryData(data.data.slice());
    setCaegoryFilter(data.data.slice());
  };

  const [profileValue, setprofileValue] = useState();

  const onChangeRiskScores = (e) => {
    setRiskScores({
      ...riskScores,
      [e?.target?.props?.name]: e.value,
    });
  };

  React.useEffect(() => {
    getDepartmentData();
    getCategoryData();
  }, []);
  React.useEffect(() => {
    getAssessmentData();
  }, [clientType, comboBoxState["workType"], type]);

  const handleCancelEdit = () => {
    setOpenForm(false);
  };

  const title = () => {
    if (type === "add") {
      return "Add Profile";
    } else if (type === "edit") {
      return "Edit Profile";
    } else {
      return "Select Question";
    }
  };

  const handleRemove = (e) => {
    const newData = [...gridData].map((question) => {
      if (question.questionId === e) {
        return {
          ...question,
          active: false,
        };
      }
      return question;
    });
    setGridData(newData);
  };

  const CancelCell = (props) => {
    return (
      <td className="">
        <Tooltip anchorElement="target" position="top">
          <img
            title="Remove"
            alt="View Ledger"
            style={{ width: "18px", height: "18px" }}
            onClick={() => {
              handleRemove(props.dataItem?.questionId);
            }}
            className="cursor-pointer"
            src={CancelImage}
          ></img>
        </Tooltip>
      </td>
    );
  };

  const checkInactive = () => {
    if (profileValue?.active === false) {
      setIsInactive(true);
    } else {
      setIsInactive(false);
    }
  };

  React.useEffect(() => {
    checkInactive();
  }, [profileValue]);
  return (
    <>
      {!addnewform ? (
        <div className="w-75 mx-auto">
          <div className="d-flex justify-content-between align-items-center mt-3">
            <div className="d-flex align-items-center radio">
              <RadioGroup
                className="d-flex flex-row float-end me-3 gap-5"
                data={[
                  {
                    label: "Client",
                    value: "Client",
                  },
                  {
                    label: "Matter",
                    value: "Matter",
                  },
                ]}
                value={type}
                onChange={(e) => setType(e.value)}
              />
            </div>
            <div className="">
              <Button
                className={`common-btn`}
                onClick={(e) => {
                  //   setEditItem(profileValue);
                  //   setType("edit");
                  setOpenForm(true);
                }}
              >
                Add Question
              </Button>
            </div>
          </div>
          <div
            className="d-flex justify-content-between align-items-start mt-3"
            style={{
              height: "150px",
            }}
          >
            {type === "Client" ? (
              <>
                <div className="radio  d-flex flex-column">
                  <Label className="label mb-2 ms-2">Type</Label>
                  <RadioGroup
                    className="d-flex flex-row float-end"
                    style={{
                      gap: "38px",
                      marginLeft: "2px",
                    }}
                    data={[
                      {
                        label: "Person",
                        value: "Person",
                      },
                      {
                        label: "Company",
                        value: "Company",
                      },
                    ]}
                    value={clientType}
                    onChange={(e) => setClientType(e.value)}
                  />
                </div>
              </>
            ) : (
              <>
                <div className="">
                  <div className="d-flex flex-column">
                    <div className="d-flex flex-column gap-3">
                      <FormComboBox
                        wf={true}
                        isStyled={true}
                        width={"260px"}
                        onChangeComboBox={handleComboBoxChange}
                        textField="name"
                        label={"Department"}
                        placeholder={"Select Department"}
                        onFilterChange={departmentfilterChange}
                        data={departmentFilter}
                        state={comboBoxState}
                        name={"department"}
                      />
                      <div className="d-flex align-items-center gap-2">
                        <FormComboBox
                          wf={true}
                          isStyled={true}
                          width={"230px"}
                          onChangeComboBox={handleComboBoxChange}
                          label={"Work Type"}
                          placeholder={"Select Work Type"}
                          isFiltered={false}
                          data={categoryFilter.filter(
                            (item) =>
                              item.departmentId ===
                              comboBoxState["department"].departmentID
                          )}
                          state={comboBoxState}
                          textField="name"
                          name={"workType"}
                        />
                        <Tooltip anchorElement="target" position="top">
                          <FiAlertCircle
                            title="Select Department to see corresponding work-types "
                            className="mt-3"
                            style={{
                              height: "20px",
                              width: "20px",
                            }}
                          />
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            <div>
              <div className="d-flex right-align align-items-center">
                <Label
                  className="label"
                  style={{
                    width: "150px",
                  }}
                >
                  Low Risk Score
                </Label>
                <Input
                  name="low"
                  value={riskScores["low"]}
                  onChange={onChangeRiskScores}
                  style={{
                    width: "150px",
                    height: "32px",
                  }}
                  placeholder={"Add Score"}
                ></Input>
              </div>
              <div className="d-flex right-align align-items-center mt-2">
                <Label
                  className="label"
                  style={{
                    width: "150px",
                  }}
                >
                  Medium Risk Score
                </Label>
                <Input
                  name="medium"
                  value={riskScores["medium"]}
                  onChange={onChangeRiskScores}
                  style={{
                    width: "150px",
                    height: "32px",
                  }}
                  placeholder={"Add Score"}
                ></Input>
              </div>
              <div className="d-flex right-align align-items-center mt-2">
                <Label
                  className="label"
                  style={{
                    width: "150px",
                  }}
                >
                  High Risk Score
                </Label>
                <Input
                  name="high"
                  value={riskScores["high"]}
                  onChange={onChangeRiskScores}
                  style={{
                    width: "150px",
                    height: "32px",
                  }}
                  placeholder={"Add Score"}
                ></Input>
              </div>
            </div>
          </div>
          <div className={`container-fluid mt-2 w-100`}>
            <div className="settings-grid users-grid row risk-assessment">
              <Grid
                data={gridData.filter((question) => question.active)}
                style={{ height: "40vh" }}
                sortable={true}
                onItemChange={(e) => {
                  props.onChange("case", e);
                }}
              >
                <Column
                  field="question"
                  title="Questions"
                  editable={false}
                  className="gridBoldField ps-4"
                />
                <Column
                  field="yesWeight"
                  title="Yes Weight"
                  editable={false}
                  className="gridBoldField text-center"
                  width={"110px"}
                />{" "}
                <Column
                  field="noWeight"
                  title="No Weight"
                  width={"110px"}
                  editable={false}
                  className="gridBoldField text-center me-"
                />{" "}
                <Column
                  field="unknownWeight"
                  title="Unknown Weight"
                  width={"150px"}
                  editable={false}
                  className="gridBoldField text-center"
                />
                <Column
                  title=" "
                  width={"60px"}
                  cell={CancelCell}
                  editable={false}
                  className="gridBoldField text-center"
                />
              </Grid>
            </div>
          </div>

          <div className="pe-4 float-end mt-4"></div>
          <div
            className={`${styles["buttons-container"]} d-flex align-items-center justify-content-between col-12 p-3 pe-0`}
            style={{
              boxShadow: "none",
            }}
          >
            <div></div>
            <div className="d-flex align-items-center gap-2">
              <Button
                className={`common-btn bgBtnSecondary me-2`}
                onClick={(e) => {
                  navigate("/settings");
                }}
              >
                Cancel
              </Button>
              <Button
                className={`common-btn`}
                onClick={() => {
                  saveAssessmentData();
                }}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
      {modalvalue && (
        <SuccessModal
          onClose={() => {
            setmodalvalue(false);
          }}
          message={type === "edit" ? "Profile Updated" : "Profile Added"}
        />
      )}
      {openForm && (
        <Dialog
          onClose={handleCancelEdit}
          // className="mattersDialog"
          width={1000}
          //   height={500}
          title={title()}
        >
          <QuestionsPopup
            addQuestions={(e) => {
              setGridData([...gridData, ...e]);
              setOpenForm(false);
            }}
            questionIds={[...gridData].map((question) => {
              if (question?.active) {
                return question?.questionId;
              }
            })}
            type={type}
          />
        </Dialog>
      )}
    </>
  );
};

export default RiskAssessment;
