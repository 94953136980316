const CONSTANT = {
        SR: "Supplier Reference",
        NR: "Nominal Reference",
        NB: "No Banks",
        BRANCHES: "Branch",
        FE: "Fee Earner",
        FB: 'From To Bank',
        OB: "Office Bank",
        FBT: 'From Bank Transfer',
        TBT: 'To Bank Transfer',
        CB: "Client Bank",
        DDAB: "DDA Bank",
        AVATS: "Actove Vat's",
        MATTER: "Matter",
        POSTING_TYPE: "Posting Type"
}

export default CONSTANT