import {
    START_LOADING,
    STOP_LOADING,
    ERROR,
    SUCCESS,
    CLEAR_MATTER_TIME,
    BILL_ERROR,
    CLEAR_BILL_ERROR,
    CREATE_BILL,
    ADD_MATTER_DISB_BILL,
    CLEAR_MATTER_DISB_TIME,
    HANDLE_CASE_FILE_TABS,
} from "../types/index.js";

const CaseFilesReducer = (
    state = {
        IsError: false,
        isSuccess: false,
        isLoading: undefined,
        caseFilesTabs: [],
        openTab: undefined,
        error: null,
    },
    action
) => {
    switch (action.type) {
        case START_LOADING:
            return { ...state, isLoading: true };
        case STOP_LOADING:
            return { ...state, isLoading: false };
        case HANDLE_CASE_FILE_TABS:
            if (action.payload.type === "open") {
                return {
                    ...state,
                    caseFilesTabs: [...state.caseFilesTabs, action.payload.data],
                };
            } else if (action.payload.type === "close") {

                const index = action.payload.data.indexOf("-");
                let closedMatter = action.payload.data.substring(index + 2);

                const filteredTabs = state.caseFilesTabs?.filter(
                    (tab) => tab.matterDetails !== closedMatter
                );
                return {
                    ...state,
                    caseFilesTabs: [...filteredTabs],
                };
            }
            return {
                ...state,
                error: null,
            };
        case ERROR:
            return { ...state, IsError: true, isSuccess: false };
        case SUCCESS:
            return { ...state, IsError: false, isSuccess: true };
        default:
            return state;
    }
};
export default CaseFilesReducer;
