import * as React from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Input, Checkbox, TextArea } from "@progress/kendo-react-inputs";
import { formatDate } from "@progress/kendo-intl";
import { Label } from "@progress/kendo-react-labels";
import { DateInput, DatePicker } from "@progress/kendo-react-dateinputs";
import { Tile } from "components/UIComponents/Tile";
import styles from "./Notes.module.scss";
import CalenderCustomToggleButton from "components/common/CalenderCustomToogleButton";
import { useState } from "react";
import DataList from "components/MatterDetails/DataList";
import { useSelector } from "react-redux";
import {
  addClientNotes,
  addMatterNotes,
  updateClientNotes,
  updateMatterNotes,
} from "api";
import { toast } from "react-toastify";
import { CustomDateInput } from "components/common/CustomDateInput";

const EditNotes = (props) => {
  const VIEW_NOTE = props.item !== null;
  const [date, setDate] = useState(
    props.item?.date ? new Date(props.item?.date) : new Date()
  );
  const [notes, setNotes] = useState(props.item?.details || "");
  const [isEdited, setIsEdited] = useState(false);
  const [active, setActive] = useState(props.item?.active || false);
  let locale = useSelector((state) => state.company.company.locale);

  const handleSubmit = async () => {
    try {
      if (VIEW_NOTE && props.matterId) {
        await updateMatterNotes(
          {
            data: {
              active: active,
              date: date,
              details: notes,
              order: 0,
            },
          },
          props.item.noteId
        );
        toast.success("Note Updated");
      } else if (VIEW_NOTE && props.clientId) {
        await updateClientNotes(
          {
            data: {
              active: active,
              date: date,
              order: 0,
              details: notes,
            },
          },
          props.item.noteId
        );
        toast.success("Note Updated");
      } else if (props.matterId) {
        await addMatterNotes(
          {
            data: {
              date: date,
              details: notes,
            },
          },
          props.matterId
        );
        toast.success("Note Added");
      } else if (props.clientId) {
        await addClientNotes(
          {
            data: {
              date: date,
              details: notes,
            },
          },
          props.clientId
        );
        toast.success("Note Added");
      }
      props.onUpdateData();
      props.cancelEdit();
    } catch (error) {
      toast.error("Unexpected Error Occured");
    }
  };

  return (
    <Dialog
      title={VIEW_NOTE ? (isEdited ? "Update Note" : "View Note") : "Add Note"}
      onClose={props.cancelEdit}
      width={600}
      className="p-3"
    >
      <div className={`${styles["notes-tile"]} p-2 m-4 ms-5 me-5`}>
        {VIEW_NOTE && !isEdited ? (
          <div className="p-3 ps-4">
            <div>
              <DataList
                text={"Date:"}
                value={formatDate(new Date(props.item?.date), "d", locale)}
                className={"w-100 p-0"}
                keyClass={"mb-2"}
                valueClass={"mb-2"}
                keyStyles={{ minWidth: "120px" }}
              />
            </div>
            <div className="mt-2">
              <DataList
                text={"Notes:"}
                value={props.item?.details}
                className={"w-100 p-0"}
                keyClass={"mb-2"}
                valueClass={"mb-2"}
                keyStyles={{ minWidth: "120px" }}
              />
            </div>
            <div className="mt-2">
              <DataList
                text={"Added By:"}
                value={props.item?.addedByName}
                className={"w-100 p-0"}
                keyClass={"mb-2"}
                valueClass={"mb-2"}
                keyStyles={{ minWidth: "120px" }}
              />
            </div>
            <div className="mt-2">
              <DataList
                text={"Added On:"}
                value={
                  props.item?.addedOn &&
                  formatDate(new Date(props.item?.addedOn), "d", locale)
                }
                className={"w-100 p-0"}
                keyClass={"mb-2"}
                valueClass={"mb-2"}
                keyStyles={{ minWidth: "120px" }}
              />
            </div>
          </div>
        ) : (
          <div className="p-3 ps-4">
            <div className="form-date-picker note-date-picker">
              <Label className="label mt-1 mb-1 w-100">Date&nbsp;</Label>
              <DatePicker
                toggleButton={CalenderCustomToggleButton}
                dateInput={CustomDateInput}
                onChange={(e) => {
                  setDate(e.value);
                }}
                value={date}
                className="px-3"
                style={{
                  height: "32px",
                  width: "260px",
                }}
              />
            </div>

            <div className="mt-3">
              <Label className="label mb-1 w-100">Note&nbsp;</Label>
              <TextArea
                className="px-3 pt-1"
                style={{
                  width: "100%",
                  maxWidth: "95%",
                  height: "120px",
                }}
                placeholder={"Enter Details"}
                id="details"
                name="details"
                onChange={(e) => {
                  setNotes(e.value);
                }}
                value={notes}
                type="text"
                rows={6}
              />
            </div>
            {isEdited && (
              <div className="mt-3">
                <Checkbox
                  name="active"
                  label={"Active"}
                  value={active}
                  onChange={(e) => setActive(e.value)}
                />
              </div>
            )}
          </div>
        )}
      </div>
      <div className="dialog-footer">
        <button
          className="bgBtnSecondary common-btn me-2"
          onClick={() => {
            if (isEdited) {
              setIsEdited(false);
            } else {
              props.cancelEdit();
            }
          }}
        >
          Cancel
        </button>
        <button
          className="common-btn me-3"
          onClick={() => {
            if (VIEW_NOTE) {
              if (!isEdited) {
                setIsEdited(true);
              } else {
                handleSubmit();
              }
            } else {
              handleSubmit();
            }
          }}
        >
          {VIEW_NOTE ? (isEdited ? "Save" : "Edit") : "Save"}
        </button>
      </div>
    </Dialog>
  );
};

export default EditNotes;
