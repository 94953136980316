import * as React from "react";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { BranchesLoader } from "./branchesloader";
import EditBranch from "./Editbranch";
import { Button } from "@progress/kendo-react-buttons";
import { editBranch } from "actions/branches.action";
import { useDispatch } from "react-redux";
import { ActiveCell, SignatureCell } from "../GridComponents";
import { EditCommandCell } from "../GridComponents";
import styles from "../Settings.module.scss";
import AddressInputComponent from "components/common/AddressInputComponent";

const BranchNameCell = (props) => {
  return (
    <td>
      <div className="d-flex flex-column">
        <div>
          <span>{props.dataItem.branchName}</span>
        </div>
        <div className={styles[`addressLines`]}>
          <span>{props.dataItem.address.line1}</span>
          <span>{props.dataItem.address.line2}</span>
        </div>
      </div>
    </td>
  );
};

const BranchesTest = () => {
  const [openForm, setOpenForm] = React.useState(false);
  const dispatch = useDispatch();
  const [editItem, setEditItem] = React.useState({
    ProductID: 1,
  });
  const enterEdit = (item) => {
    setOpenForm(true);
    setEditItem(item);
  };
  const handleSubmit = (event) => {
    let data = {
      branchName: event.branchName,
      branchCode: event.branchCode,
      address: {
        line1: event.address.line1,
        line2: event.address.line2,
        town: event.address.town,
        state: event.address.state,
        postcode: event.address.postcode,
        country: event.address.country,
      },
      active: event.active,
    };
    dispatch(editBranch(event.branchId, data));
    setOpenForm(false);
  };
  const [products, setProducts] = React.useState({
    data: [],
    total: 20,
  });
  const [dataState, setDataState] = React.useState({
    take: 10,
    skip: 0,
  });

  const handleCancelEdit = () => {
    setOpenForm(false);
  };
  const MyEditCommandCell = (props) => (
    <EditCommandCell {...props} enterEdit={enterEdit} />
  );
  const dataStateChange = (e) => {
    setDataState(e.dataState);
  };

  const dataReceived = (products) => {
    products.data.map((item) => {
      return (item.symbol = item.branchName[0]);
    });
    setProducts(products);
  };

  return (
    <div className="settings-grid">
      <Grid {...dataState} data={products} onDataStateChange={dataStateChange}>
        <Column field="symbol" title=" " cell={SignatureCell} width="80" />
        <Column
          field="branchName"
          title="Name"
          cell={BranchNameCell}
          width="200"
        />
        <Column field="branchCode" className="ms-3" title="Code" width="90" />
        <Column field="address.town" title="Town" />
        <Column field="address.state" title="State" />
        <Column field="address.postcode" title="Postcode" />
        <Column field="address.country" title="Country" width={150} />
        <Column title="Active" cell={ActiveCell} width="80" />
        <Column title=" " cell={MyEditCommandCell} width="80" />
      </Grid>
      {openForm && (
        <EditBranch
          cancelEdit={handleCancelEdit}
          onSubmit={handleSubmit}
          item={editItem}
        />
      )}

      <BranchesLoader dataState={dataState} onDataReceived={dataReceived} />
    </div>
  );
};

export default BranchesTest;
