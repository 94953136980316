import * as React from "react";
import * as ReactDOM from "react-dom";
import { toODataString } from "@progress/kendo-data-query";
import { useDispatch, useSelector } from "react-redux";
import { getDepartment } from "actions/departments.action";

export const DepartmentLoader = (props) => {
    const dispatch = useDispatch();
    const { departmentsList } = useSelector((state) => state.departments);
    const lastSuccess = React.useRef("");
    const pending = React.useRef("");

    const requestDataIfNeeded = () => {
        if (
            pending.current ||
            toODataString(props.dataState) === lastSuccess.current
        ) {
            return;
        }
        pending.current = toODataString(props.dataState);
        dispatch(
            getDepartment(
                props.dataState.skip / props.dataState.take + 1,
                props.dataState.take
            )
        );
    };

    React.useEffect(() => {
        if (departmentsList ) {
            lastSuccess.current = pending.current;
            pending.current = "";
            if (toODataString(props.dataState) === lastSuccess.current) {
                props.onDataReceived.call(undefined, {
                    data: departmentsList .data,
                    total: departmentsList .total,
                });
            } else {
                requestDataIfNeeded();
            }
        }
        requestDataIfNeeded();
    }, [departmentsList , pending, props.dataState, lastSuccess]);
    return pending.current ? <LoadingPanel /> : null;
};

const LoadingPanel = () => {
    const loadingPanel = (
        <div className="k-loading-mask">
            <span className="k-loading-text">Loading</span>
            <div className="k-loading-image" />
            <div className="k-loading-color" />
        </div>
    );
    const gridContent = document && document.querySelector(".k-grid-content");
    return gridContent
        ? ReactDOM.createPortal(loadingPanel, gridContent)
        : loadingPanel;
};
