import {
  START_LOADING,
  STOP_LOADING,
  CREATED_POSTING,
  GET_CHIT_REQUEST_TYPES,
} from "../types/index";
import * as api from "../api/index.js";
import { handleApiError } from "Helper";

export const getChitPostingtypes = () => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const datas = await api.getChitPostingtypes();
    dispatch({ type: GET_CHIT_REQUEST_TYPES, payload: datas.data });
   dispatch({ type: STOP_LOADING });
  } catch (error) { handleApiError(error) }
};

export const createChitRequest = (data) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const datas = await api.createChitRequest(data);
    dispatch({ type: CREATED_POSTING, payload: datas });
  } catch (error) {handleApiError(error)}
};
