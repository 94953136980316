import React from "react";
import { Field } from "@progress/kendo-react-form";
import Title from "components/common/Title";
import PersonNameComponent from "components/common/Contact/PersonNameComponent/PersonNameComponent";
import ContactInformation from "components/common/ContactInformation";
import CustomInput from "components/common/Input";
import { FormComboBox } from "components/Matters";
import CustomDatePicker from "components/common/CustomDatePicker";
import { emailValidator, phoneValidator } from "components/common/validator";
import styles from "./Clients.module.scss";
import { ContainerCard } from "components/MatterDetails/ContactCard";

const CorrespondenceContact = ({ type, readOnly }) => {
  return (
    <div className="col-4">
      <ContainerCard
        height="600px"
        className="p-3 ps-4 pe-4 pt-2 pb-0 overflow-auto"
      >
        <div>
          <div
            className="d-flex align-items-center justify-content-between"
            style={{ height: "50px" }}
          >
            <div className={`${styles["section-title"]}`}>
              Correspondence Person
            </div>
          </div>
          <hr className="mt-0" />
          <div className="container">
            <div className="col-3">
              <Field
                name={`corrFirstName`}
                label="Name"
                width={"260px"}
                ph="Enter Name"
                component={CustomInput}
              />
            </div>
            <div className="col-3 mt-3">
              <Field
                name={`corrPhone`}
                label="Phone Number"
                type="number"
                width={"260px"}
                ph="Enter Phone Number"
                m
                component={CustomInput}
              />
            </div>
            <div className="col-3 mt-3">
              <Field
                name={`corrEmail`}
                label="Email"
                width={"260px"}
                ph="Enter Email Address"
                validator={emailValidator}
                required={false}
                component={CustomInput}
              />
            </div>
            <div className="col-3 mt-3">
              <Field
                name={`corrJobTitle`}
                label="Job Title"
                width={"260px"}
                ph="Enter Job Title"
                component={CustomInput}
              />
            </div>
          </div>
        </div>
      </ContainerCard>
    </div>
  );
};

export default CorrespondenceContact;
