import SettingMenuItemId from "./SettingMenuItemId.ts";

import CompanyImage from "../../assets/Settings/MenuSettings/CompanySettings.svg";
import BranchImage from "../../assets/Settings/MenuSettings/Branch.svg";
import IntegrationsImage from "../../assets/Settings/MenuSettings/Integrations.svg";
import ApplicationImage from "../../assets/Settings/MenuSettings/Applications.svg";
import CategoryImage from "../../assets/Settings/MenuSettings/Categories.svg";
import DepartmentsImage from "../../assets/Settings/MenuSettings/Departments.svg";
import ContactLinkImage from "../../assets/Settings/MenuSettings/ContactLinkType.svg";
import DefaultNominalImage from "../../assets/Settings/MenuSettings/DefaultNominals.svg";
import VATImage from "../../assets/Settings/MenuSettings/VATSettings.svg";
import CurrencyImage from "../../assets/Settings/MenuSettings/CurrencySetting.svg";
import ProfileImage from "../../assets/Settings/MenuSettings/MyProfile.svg";
import UserSettingsImage from "../../assets/Settings/MenuSettings/UserSettings.svg";
import SecurityProfileImage from "../../assets/Settings/MenuSettings/SecurityProfileIcon.svg";
import TeamsImage from "../../assets/Settings/MenuSettings/Teams.svg";
import FixedFeeBilllingImage from "../../assets/Settings/MenuSettings/FixedFeeBilling.svg";
import SourcesImage from "../../assets/Settings/MenuSettings/Sources.png";
import ReferrerImage from "../../assets/Settings/MenuSettings/Referrers.png";
import ActivityImage from "../../assets/Settings/MenuSettings/Activity.png";
import MatterSettingImage from "../../assets/Settings/MenuSettings/MatterSettings.png";

export const CompanySectionItems = [
  {
    name: "Company Settings",
    icon: CompanyImage,
    route: "/company",
    id: SettingMenuItemId.Company,
  },
  {
    name: "Branch Settings",
    icon: BranchImage,
    route: "/branches",
    id: SettingMenuItemId.Branches,
  },
  {
    name: "Integrations",
    icon: IntegrationsImage,
    route: "/integrations",
    id: SettingMenuItemId.Integrations,
  },
];

export const MatterSectionItems = [
  {
    name: "Matter Settings",
    icon: MatterSettingImage,
    route: "/mattersettings",
    id: SettingMenuItemId.MatterSettings,
  },
  {
    name: "Applications",
    icon: ApplicationImage,
    route: "/applications",
    id: SettingMenuItemId.Applications,
  },
  {
    name: "Departments",
    icon: DepartmentsImage,
    route: "/departments",
    id: SettingMenuItemId.Departments,
  },
  {
    name: "Work Types",
    icon: CategoryImage,
    route: "/worktypes",
    id: SettingMenuItemId.Worktypes,
  },
  {
    name: "Sources",
    route: "/sources",
    icon: SourcesImage,
    id: SettingMenuItemId.Sources,
  },
  {
    name: "Referrers",
    route: "/referrers",
    icon: ReferrerImage,
    id: SettingMenuItemId.Referrers,
  },
  {
    name: "Risk Assessment",
    route: "/riskassessment",
    icon: ReferrerImage,
    id: SettingMenuItemId.RiskAssessmentSettings,
  },
];

export const CaseSectionItems = [
  {
    name: "Activity Types",
    route: "/activitytypes",
    icon: ActivityImage,
    id: SettingMenuItemId.Activitytypes,
  },
  {
    name: "Time Types",
    route: "/timetypes",
    icon: ActivityImage,
    id: SettingMenuItemId.Timetypes,
  },
  {
    name: "Contact Categories",
    icon: ContactLinkImage,
    route: "/contactcategories",
    id: SettingMenuItemId.Contactcategories,
  },
  {
    name: "Document Register Types",
    icon: DefaultNominalImage,
    route: "/documentregistertypes",
    id: SettingMenuItemId.DocumentRegisterTypes,
  },
];

export const AccountsSectionItems = [
  {
    name: "Default Nominals",
    icon: DefaultNominalImage,
    route: "/defaultnominals",
    id: SettingMenuItemId.Defaultnominals,
  },
  {
    name: "VAT Settings",
    icon: VATImage,
    route: "/vat",
    id: SettingMenuItemId.Vat,
  },
  {
    name: "Currency Settings",
    icon: CurrencyImage,
    route: "/currencies",
    id: SettingMenuItemId.Currencies,
  },
];

export const UsersSectionItems = [
  {
    name: "My Profile",
    icon: ProfileImage,
    route: "/profile",
    id: SettingMenuItemId.Profile,
  },
  {
    name: "User Settings",
    icon: UserSettingsImage,
    route: "/users",
    id: SettingMenuItemId.Users,
  },
  {
    name: "Security Profiles",
    icon: SecurityProfileImage,
    route: "/securityprofiles",
    id: SettingMenuItemId.SecurityProfiles,
  },
  {
    name: "Teams",
    icon: TeamsImage,
    route: "/teams",
    id: SettingMenuItemId.Teams,
  },
  {
    name: "User Rates",
    icon: TeamsImage,
    route: "/userRates",
    id: SettingMenuItemId.UserRates,
  },
];

export const BillingSectionItems = [
  {
    name: "Fixed Fee Billing",
    icon: FixedFeeBilllingImage,
    route: "/fixedfeebilling",
    id: SettingMenuItemId.FixedFeeBilling,
  },
];

export const getBreadCrumb = (openoption) => {
  if (openoption === SettingMenuItemId.Profile) return "/ My Profile";
  if (openoption === SettingMenuItemId.Company) return "/ Company Settings";
  if (openoption === SettingMenuItemId.Users) return "/ User Settings";
  if (openoption === SettingMenuItemId.Branches) return "/ Branches";
  if (openoption === SettingMenuItemId.Defaultnominals)
    return "/ Default Nominals";
  if (openoption === SettingMenuItemId.Vat) return "/ VAT Settings";
  if (openoption === SettingMenuItemId.Currencies) return "/ Currency Settings";
  if (openoption === SettingMenuItemId.FixedFeeBilling)
    return "/ Fixed Fee Settings";
  if (openoption === SettingMenuItemId.Applications) return "/ Applications";
  if (openoption === SettingMenuItemId.Contactcategories)
    return "/ Contact Categories";
  if (openoption === SettingMenuItemId.Worktypes) return "/ Work Types";
  if (openoption === SettingMenuItemId.Departments) return "/ Departments";
  if (openoption === SettingMenuItemId.Activitytypes) return "/ Activity Types";
  if (openoption === SettingMenuItemId.Timetypes) return "/ Time Types";
  if (openoption === SettingMenuItemId.Teams) return "/ Teams";
  if (openoption === SettingMenuItemId.SecurityProfiles)
    return "/ Security Profiles";
  if (openoption === SettingMenuItemId.Integrations) return "/ Integrations";
  if (openoption === SettingMenuItemId.Sources) return "/ Sources";
  if (openoption === SettingMenuItemId.Referrers) return "/ Referrers";
  if (openoption === SettingMenuItemId.MatterSettings)
    return "/ Matter Settings";
  if (openoption === SettingMenuItemId.DocumentRegisterTypes)
    return "/ Document Register Types";
    if (openoption === SettingMenuItemId.RiskAssessmentSettings)
    return "/ Risk Assessment";
  if (openoption === SettingMenuItemId.UserRates)
    return "/ User Rates Category";
};
