import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./App.scss";
import { Provider } from "react-redux";
import store from "./store";
import { OidcProvider } from "@axa-fr/react-oidc";
import { configurationIdentityServer } from "./configuration/Configuration";
import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import themes from "./styles/themeSelection/themeList"

//Updated for react 18
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <OidcProvider configuration={configurationIdentityServer}>
    <Provider store={store}>
            {/* <React.StrictMode> */}
            {/*<ThemeSwitcherProvider*/}
            {/*    themeMap={themes}*/}
            {/*    defaultTheme="linq"*/}
            {/*    insertionPoint="styles-insertion-point"*/}
            {/*>*/}
                <App />
            {/*</ThemeSwitcherProvider>*/}
        {/* </React.StrictMode> */}
    </Provider>
  </OidcProvider>
);
