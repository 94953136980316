import * as React from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import { useSelector } from "react-redux";
import { NumericTextBox } from "@progress/kendo-react-inputs";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { Button } from "@progress/kendo-react-buttons";
import { useState, useEffect } from "react";

const AddBillingTargets = (props) => {
    const [dataAvailable, setDataAvailable] = useState(false);
    const [error, setError] = useState(undefined);
    const [data, setData] = React.useState([]);
    const [target, setTarget] = useState(0)
    const [month, setMonth] = useState(null)
    const branchesList = useSelector((state) => {
        let data = [{ branchId: null, branchName: "Any Branch" }]
        data.push(...state.branchesnames.branchesList)
        return data
    });
    const departmentList = [
        {
            departmentId: null,
            departmentName: "Any Department"
        }
    ]
    const [department, setDepartment] = React.useState({
        departmentId: null,
        departmentName: "Any Department"
    })
    const [branch, setBranch] = React.useState({ branchId: null, branchName: "Any Branch" })
    const handleSubmit = () => {
        let originalMonth = ""
        let counter = 0;
        props.data.map((item) => {
            item.items.map((item_1) => {
                if (item_1.month === month) {
                    originalMonth = item_1.originalMonth
                }
                if (item_1.counter > counter) {
                    counter = item_1.counter
                }
            })
        })
        let data = {
            branchId: branch?.branchId,
            value: target,
            departmentId: department?.departmentId,
            active: true,
            month: month,
            branchName: branch?.branchName,
            originalMonth: originalMonth
        }
        data.counter = counter + 1
        let tempData = props.data
        tempData.map((item) => {
            if (item.value === month) {
                item.items.push(data)
            }
        })
        props.setData(tempData)
        props.cancelEdit();
    };
    return (
        <Dialog
            onClose={props.cancelEdit}
            className="mattersDialog supplierDialog"
        >
            <h3 style={{ paddingTop: "50px" }}>Add Billing Target</h3>
            <div
                style={{
                    display: "flex",
                    flexWrap: "wrap",
                    alignItems: "flex-start",
                    gap: "5%",
                    marginTop: "10px",
                    marginLeft: "10px",
                }}
            >
                <div className="sectionstyle" style={{ gap: "3%" }}>
                    <p>Month</p>
                    <div className="validator">
                        <ComboBox
                            data={props.monthsData}
                            value={month}
                            onChange={(e) => { setMonth(e.value) }}
                            placeholder={"Select Month"}
                        />
                        {/* {error === true && (vatvalue === null || vatvalue === undefined) ? (
                            <i>Please Select Vat Code</i>
                        ) : (
                            ""
                        )} */}
                    </div>
                </div>
                <div className="sectionstyle" style={{ gap: "3%" }}>
                    <p>Branch</p>
                    <div className="validator">
                        <ComboBox
                            data={branchesList}
                            textField="branchName"
                            placeholder="Select Branch"
                            dataItemKey="branchId"
                            defaultValue={null}
                            value={branch}
                            onChange={(e) => {
                                setBranch(e.value)
                            }}
                        />
                        {/* {error === true &&
                            (nominal?.nominalRef === "" || nominal === null) ? (
                            <i>Please Select Nominal Ref</i>
                        ) : (
                            ""
                        )} */}
                    </div>
                </div>
                <div className="sectionstyle" style={{ gap: "3%" }}>
                    <p>Department</p>
                    <div className="validator">
                        <ComboBox
                            data={departmentList}
                            textField="departmentName"
                            placeholder="Select Department"
                            dataItemKey="departmentId"
                            value={department}
                            onChange={(e) => {
                                setDepartment(e.value)
                            }}
                        />
                        {/* {error === true &&
                            (feeearnerref === null || feeearnerref === undefined) ? (
                            <i>Please Select Fee Earner</i>
                        ) : (
                            ""
                        )} */}
                    </div>
                </div>
                <div className="sectionstyle right-align" style={{ gap: "3%" }}>
                    <p>Target</p>
                    <div className="validator">
                        <NumericTextBox
                            width={300}
                            value={target}
                            onChange={(e) => {
                                setTarget(e.target.value);
                            }}
                            spinners={false}
                            format="n2"
                            placeholder="0.00"
                        />
                        {/* {error === true &&
                            (feeearnerref === null || feeearnerref === undefined) ? (
                            <i>Please Select Fee Earner</i>
                        ) : (
                            ""
                        )} */}
                    </div>
                </div>
            </div>
            <div className="" style={{ marginTop: "15px" }}>
                <div className="mattersDialogButtons">
                    <button className="k-button common-btn me-1" onClick={handleSubmit}>
                        Save
                    </button>
                    <button
                        className="k-button common-btn"
                        onClick={() => {
                            props.cancelEdit();
                        }}
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </Dialog>
    );
};

export default AddBillingTargets;
