import { Loader } from "@progress/kendo-react-indicators";
import { getClientMessages, sendClientMessage } from "api";
import React, { useEffect, useRef, useState } from "react";

export function Messages({ matterId }) {
  const messagesEndRef = useRef(null);
  const [groupedMessages, setGroupedMessages] = useState({});
  const [messageInput, setMessageInput] = useState(""); // For message input
  const [loading, setLoading] = useState(false); // For loading state
  const [sendingMessage, setSendingMessage] = useState(false); // For loading state

  // Scroll to bottom on load and when new messages are added
  const scrollToBottom = () => {
    const container = messagesEndRef.current?.parentNode; // Get the parent container
    if (container) {
      container.scrollTop = container.scrollHeight;
    }
  };

  // Format date to "MMMM d, yyyy"
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  // Format time to "hh:mm A"
  const formatTime = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
  };

  const groupMessagesByDate = (messages) => {
    return messages.reduce((groups, message) => {
      const date = formatDate(message.date);
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(message);
      return groups;
    }, {});
  };

  const getClientMessagesData = async (matterId, shouldLoad = true) => {
    try {
      if (shouldLoad) setLoading(true); // Set loading to true while fetching data
      const response = await getClientMessages(matterId);
      const grouped = groupMessagesByDate(response.data.data);
      setGroupedMessages(grouped);
      if (shouldLoad) setLoading(false); // Set loading to false once data is fetched
      setTimeout(() => {
        scrollToBottom(); // Scroll to bottom after messages are rendered
      }, 500);
    } catch (error) {
      console.error("Error fetching messages:", error);
      if (shouldLoad) setLoading(false); // Set loading to false in case of error
    }
  };

  const sendClientMessagesData = async (e) => {
    e.preventDefault();
    if (messageInput.trim() === "") return; // Prevent sending empty messages
    try {
      setSendingMessage(true);
      await sendClientMessage(matterId, { message: messageInput });
      setMessageInput(""); // Clear the message input after sending
      getClientMessagesData(matterId, false); // Refresh messages and scroll to bottom
    } catch (error) {
      console.error("Error sending message:", error);
    } finally {
      setSendingMessage(false);
    }
  };

  useEffect(() => {
    getClientMessagesData(matterId);
  }, [matterId]);

  return (
    <div
      className="d-flex flex-column justify-content-between align-items-center px-5 py-3 mt-4 me-4 ms-3 rounded bg-light"
      style={{
        height: "calc(100vh - 370px)",
      }}
    >
      {/* Messages Section */}
      <div
        className="d-flex flex-column w-100 overflow-auto"
        style={{
          maxWidth: "800px",
          height: "calc(100vh - 370px)",
          overflowY: "scroll",
        }}
      >
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Loader size="large" type="infinite-spinner" />{" "}
            {/* Centered Loader */}
          </div>
        ) : (
          Object.keys(groupedMessages)
            .sort((a, b) => new Date(a) - new Date(b)) // Sort date groups in reverse order
            .map((date, index) => (
              <>
                <div key={index}>
                  {/* Date Heading */}
                  <div className="text-center text-muted fs-6 fw-medium mt-3 mb-2">
                    {date}
                  </div>

                  {/* Messages for the specific date */}
                  {groupedMessages[date]
                    .sort((a, b) => new Date(a.date) - new Date(b.date))
                    .map((message) => (
                      <div
                        key={message.clientMessageId}
                        className={`d-flex flex-column gap-2 mt-4 mb-4 ${
                          message.isMessageOut
                            ? "flex-column align-items-end text-end" // Outgoing message: align right
                            : "flex-row text-start" // Incoming message: align left
                        }`}
                      >
                        <div className="d-flex align-items-start gap-3">
                          {!message.isMessageOut && (
                            <div
                              className="rounded-circle"
                              style={{
                                width: "32px",
                                height: "32px",
                                backgroundColor: message.isMessageOut
                                  ? "#7D7AB4" // Color for outgoing message avatar
                                  : "#3BC4AF", // Color for incoming message avatar
                              }}
                            />
                          )}
                          <div>
                            <div
                              className={`d-flex gap-3 align-items-center  ${
                                message.isMessageOut
                                  ? "justify-content-end" // Outgoing message: align right
                                  : "flex-row" // Incoming message: align left
                              }`}
                            >
                              <div className="text-dark  fs-6  fw-semibold">
                                {message.senderName || message.senderUsername}
                              </div>
                              <div className="text-muted  fs-6  fw-semibold">
                                {formatTime(message.date)}
                              </div>
                            </div>

                            {/* Message content */}
                            <div
                              className="d-flex flex-column"
                              style={{
                                maxWidth: "600px",
                                wordWrap: "break-word",
                                backgroundColor: message.isMessageOut
                                  ? "#7D7AB4" // Outgoing message color
                                  : "#f1f1f1", // Incoming message color
                                color: message.isMessageOut ? "white" : "black",
                                padding: "10px",
                                borderRadius: "10px",
                              }}
                            >
                              <div className="text-start">
                                {message.message || "(No message)"}
                              </div>
                            </div>
                          </div>
                          {message.isMessageOut && (
                            <div
                              className="rounded-circle"
                              style={{
                                width: "32px",
                                height: "32px",
                                backgroundColor: message.isMessageOut
                                  ? "#7D7AB4" // Color for outgoing message avatar
                                  : "#00a2ed", // Color for incoming message avatar
                              }}
                            />
                          )}
                        </div>
                      </div>
                    ))}
                </div>
                <div ref={messagesEndRef} />
              </>
            ))
        )}

        {/* Empty div for scrolling to bottom */}
      </div>

      {/* Input Field Section */}
      <div
        className="d-flex justify-content-between align-items-center px-3 py-2 bg-white shadow-sm rounded-pill"
        style={{ maxWidth: "800px", width: "100%" }}
      >
        <form
          onSubmit={sendClientMessagesData}
          className="d-flex"
          style={{ flex: 1 }}
        >
          <input
            type="text"
            className="form-control border-0"
            placeholder="Type a message..."
            value={messageInput} // Controlled input
            onChange={(e) => setMessageInput(e.target.value)} // Update message input state
            disabled={loading} // Disable input while loading
            style={{ flex: 1 }}
          />
          {sendingMessage ? (
            <div className="spinner-border spinner mt-1" role="status"></div>
          ) : (
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/9669f9ea2c7eedd6ae8cf86dfb3f0392c2fc6476e66c270640d49b894fc62a71"
              className="rounded-circle ms-3"
              style={{ width: "36px", height: "36px", cursor: "pointer" }}
              alt="Avatar"
              onClick={sendClientMessagesData} // Trigger send on click
              typeof="submit"
              disabled={loading} // Disable sending while loading
            />
          )}
        </form>
      </div>
    </div>
  );
}
