import { clearLedgerLink } from 'actions/ledgerLinks.action';
import { clearMattersBalances, clearMattersPostings, getMattersBalances, getMattersInfo } from 'actions/matters.action';
import MatterLedgerGrid from 'components/LedgerGrid'
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { getPostingDetails } from 'actions/postingDetails.action';
import * as api from "../../api/index";


export default function MatterLedgerCardPage(props) {
    const dispatch = useDispatch();
    const anchor = React.useRef(null);
    const [showDDA, setShowDDA] = useState(true)
    const [show, setShow] = React.useState(false);
    const matterDetails = useSelector((state) => state.mattersList);
    const [currentMatterDetails, setcurrentMatterDetails] = useState();
    const [visible, setVisible] = React.useState(false);
    const [transNo, settransNo] = React.useState("");
    const [showForm, setshowForm] = React.useState(false);
    const [searchValue, setSearchValue] = React.useState();
    const [isSearched, setIsSearched] = React.useState(false);
    const [activeSupplierRefList, setActiveSupplierRefList] = React.useState([]);
    const [matterRef, setmatterref] = React.useState();
    const [currentmattersInfo, setMattersInfo] = useState({});
    const [currentmattersBal, setMattersBalances] = useState({
        matterBalances: {},
        clientBalances: {},
    });

    const [list, setLists] = React.useState([]);
    const [total, setTotal] = React.useState();
    const [fixedScroll, setFixedScroll] = useState(true);
    const [dataState, setDataState] = React.useState({
        take: 50,
        skip: 0,
    });

    const mattersInfo = useSelector((state) => state.mattersList.mattersDetails);
    const mattersBalances = useSelector(
        (state) => state.mattersList.mattersBalances
    );

    const onChangematter = (event) => {
        dispatch(clearMattersPostings());
        if (event === null) {
            dispatch(clearLedgerLink("Matters", ""));
            setLists({
                data: [],
                total: 0,
            });
            setMattersBalances({
                matterBalances: {},
                clientBalances: {},
            });
            setMattersInfo({});
            dispatch(clearMattersBalances("Matter Ledger"));
        }
        if (event !== null) {
            setIsSearched(true);
            setSearchValue(event);
            setmatterref(event);
            dispatch(getMattersInfo(event));
        }
    };

    useEffect(() => {
        setTimeout(() => {
            if (mattersInfo !== undefined) {
                setMattersInfo(mattersInfo.data);
            }
            if (mattersBalances !== undefined) {
                setMattersBalances(mattersBalances.data);
            }
        }, 1000);
    }, [mattersBalances, mattersInfo]);


    const apiDatFetcher = (page) => {
        api.getMattersPostings(props.reference, page, 200).then(data => {
            setLists(prevList => [...prevList, ...data.data.data])
            setTotal(data.data.total)
        }).catch(error => {
            console.error(error)
        })
    }

    const fetchMoreData = () => {
        let page = 1
        let totalRecords = (list.length)
        page = Math.ceil(totalRecords / 200) + 1
        if (totalRecords >= (total)) return
        setFixedScroll(true)
        apiDatFetcher(page)
    }

    const dataStateChange = (e) => {
        setDataState(e.dataState);
    };

    useEffect(() => {
        if (matterDetails !== null) {
            setcurrentMatterDetails(
                matterDetails?.matterViewDetails[props.reference]?.data
            );
        }
    });

    useEffect(() => {
        if (matterDetails.matterViewDetails[props.selTab]?.data.matterId)
            onChangematter(matterDetails.matterViewDetails[props.selTab].data.matterId)
}, [props.selTab])

    useEffect(() => {
      fetchMoreData();
      if (props?.refreshClicked && props.activeItem === 5) {
        props?.setRefreshClicked(false);
      }
    }, [props?.refreshClicked]);

    return (
        <div>
            <MatterLedgerGrid
                showDDA={showDDA}
                setShowDDA={setShowDDA}
                dataState={dataState}
                // fixedScroll={fixedScroll}
                getMoreData={fetchMoreData}
                dataStateChange={dataStateChange}
                comp="ledgerCard"
                gridData={list}
            />
        </div>

    )
}
