import * as React from "react";
import OAuth2Login from 'react-simple-oauth2-login';
import { toast } from "react-toastify";
import { useEffect } from "react";
import { useState } from "react";
import * as api from "../../../api/index"
import styles from "../Settings.module.scss"
import { getFraudPreventionHeaders } from "components/Hmrc/OidcHeaders";
import Auth2Authentication from "components/OAuth2Login/AuthComponent";



const HMRCAuthentication = () => {

    //OAuth2Login component need required fields, so need to define default state.
    const [credentials, setCredentials] = useState({
        authenticationBaseURL: "",
        responseType: "code",
        clientId: "",
        scope: "",
    });

    //Get Authentication information from API
    useEffect(() => {
        GetAuthenticationInfo()
    }, []);

    const GetAuthenticationInfo = async () => {
        const data = await api.getVatReturnInfo();
        setCredentials(data.data)
    };

    const functionLoader = async () => {
        const map = await getFraudPreventionHeaders()
        const response = await fetch("https://test-api.service.hmrc.gov.uk/test/fraud-prevention-headers/validate", {
            method: "GET", // or 'PUT'
            headers: { ...map, 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': 'false' },
        });
    }

    // useEffect(() => {
    //     functionLoader()
    // }, [])

    const onSuccess = async (response) => {

        //Data to connect using authorization code.
        const data = {
            code: response?.code,
            state: "9999999",
            vatNo: "286381514768",
            redirectURL: window.location.origin + "/oauth-callback"
        };

        //Call connect API
        try {
            const res = await api.vatReturnConnect(data)
            toast.success(res?.data)
        } catch (error) {
            toast.error('Error occured in connection')
        }
    };

    const onFailure = response => toast.error('Error occured in connection')


    return (
        <Auth2Authentication
            credentials={credentials}
            buttonText='Connect TO HMRC'
            onSuccess={onSuccess}
            onFailure={onFailure}
        />

    )

}

export default HMRCAuthentication;