import { TextArea } from "@progress/kendo-react-inputs";
import { Label } from "@progress/kendo-react-labels";
import {
  FormComboBox,
  FormDate,
  FormInput,
  FormNumericTextBox,
} from "components/Matters";
import React from "react";
import { CONSTANT } from "CONSTANT/Matter/MatterForm";
import {
  AmountFormat,
  DateFormat,
  DateFormat1,
  DateFormatCell,
} from "components/common/formatter";
import styles from "./DocumentRegister.module.scss";
import DataList from "components/MatterDetails/DataList";

export const DocRegisterDetailsTab = ({
  isView,
  locale,
  data,
  dateState,
  handleComboBoxChange,
  handleDateChange,
  comboBoxState,
  yesNoData,
  details,
  setDetails,
  feeEarner,
}) => {
  return (
    <>
      {isView ? (
        <div style={{ display: !isView && "none" }}>
          <div className="container pb-2" style={{ width: "1130px" }}>
            <div className="row g-2 mt-3">
              <div className="col">
                <div className="container">
                  <div className="row">
                    <div className="d-flex align-items-start justify-content-start mt-3 ps-3">
                      <div className="" style={{ width: "34%" }}>
                        <DataList
                          text={"Date of Document:"}
                          value={DateFormat1(data?.documentDate, locale)}
                          //
                          alignItems="align-items-start"
                          //
                          keyStyles={{ minWidth: "165px" }}
                        />
                        <DataList
                          text={"Lodgement Date:"}
                          value={DateFormat1(data?.lodgementDate, locale)}
                          //
                          alignItems="align-items-start"
                          //
                          keyStyles={{ minWidth: "165px" }}
                        />
                        <DataList
                          text={"Review Date:"}
                          value={DateFormat1(data?.reviewDate, locale)}
                          //
                          alignItems="align-items-start"
                          //
                          keyStyles={{ minWidth: "165px" }}
                        />

                        <DataList
                          text={"Do Not Destroy:"}
                          value={data?.doNotDestroy}
                          //
                          alignItems="align-items-start"
                          //
                          keyStyles={{ minWidth: "165px" }}
                        />
                      </div>
                      <div className="ps-5">
                        <DataList
                          text={"Codicil Date 1:"}
                          value={DateFormat1(data?.codicilDate1, locale)}
                          alignItems="align-items-start"
                          keyStyles={{ minWidth: "150px" }}
                        />
                        <DataList
                          text={"Codicil Date 2:"}
                          value={DateFormat1(data?.codicilDate2, locale)}
                          alignItems="align-items-start"
                          keyStyles={{ minWidth: "150px" }}
                        />
                        <div style={{ visibility: "hidden" }}>
                          <DataList
                            text={"Phone 2:"}
                            className={"visiblilty-hidden"}
                            value={""}
                            keyStyles={{
                              minWidth: "100px",
                            }}
                          />
                        </div>
                        <DataList
                          text={"Date of Will:"}
                          value={DateFormat1(data?.willDate, locale)}
                          alignItems="align-items-start"
                          keyStyles={{
                            minWidth: "150px",
                            marginTop: "-10px",
                          }}
                          valueStyles={{
                            marginTop: "-10px",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mt-4 ps-1">
                    <DataList
                      text={"Description:"}
                      value={data?.details}
                      alignItems="align-items-start"
                      keyStyles={{ minWidth: "150px" }}
                    />
                    <DataList
                      text={"Fee Earner:"}
                      value={data?.feeEarnerRef}
                      alignItems="align-items-start"
                      keyStyles={{ minWidth: "150px" }}
                    />
                    <DataList
                      text={"Is Deceased:"}
                      value={data?.isDeceased}
                      alignItems="align-items-start"
                      keyStyles={{ minWidth: "150px" }}
                    />
                    <DataList
                      text={"Is Deceased:"}
                      value={data?.isDeceased}
                      alignItems="align-items-start"
                      keyStyles={{ minWidth: "150px" }}
                    />
                    <DataList
                      text={"In Storage:"}
                      value={data?.isStorage}
                      alignItems="align-items-start"
                      keyStyles={{ minWidth: "150px" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          className="container pb-2"
          style={{
            width: "1130px",
            display: isView && "none",
          }}
        >
          <div className="row g-2 mt-3">
            <div className="col">
              <div className="container">
                <div className="row">
                  <div className="col-3 me-3">
                    <FormDate
                      className={"form-date-picker note-date-picker "}
                      wf={true}
                      required={true}
                      isStyled={true}
                      width={"260px"}
                      label={CONSTANT.DATE.DATE_OF_DOC}
                      onChangeDate={handleDateChange}
                      state={dateState}
                    />
                  </div>
                  <div className="col-3">
                    <FormDate
                      className={"form-date-picker note-date-picker "}
                      wf={true}
                      isStyled={true}
                      width={"260px"}
                      label={CONSTANT.DATE.CODICIL_DATE_1}
                      onChangeDate={handleDateChange}
                      state={dateState}
                    />
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-3 me-3">
                    <FormDate
                      className={"form-date-picker note-date-picker "}
                      wf={true}
                      isStyled={true}
                      width={"260px"}
                      label={CONSTANT.DATE.LODGEMENT_DATE}
                      onChangeDate={handleDateChange}
                      state={dateState}
                    />
                  </div>
                  <div className="col-3">
                    <FormDate
                      className={"form-date-picker note-date-picker "}
                      wf={true}
                      isStyled={true}
                      width={"260px"}
                      label={CONSTANT.DATE.CODICIL_DATE_2}
                      onChangeDate={handleDateChange}
                      state={dateState}
                    />
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-3 me-3">
                    <FormDate
                      className={"form-date-picker note-date-picker "}
                      wf={true}
                      isStyled={true}
                      width={"260px"}
                      label={CONSTANT.DATE.REVIEW_DATE}
                      onChangeDate={handleDateChange}
                      state={dateState}
                    />
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="d-flex flex-column col-3 me-3">
                    <FormComboBox
                      wf={true}
                      isStyled={true}
                      width={"260px"}
                      onChangeComboBox={handleComboBoxChange}
                      fiterable={false}
                      data={yesNoData}
                      state={comboBoxState}
                      label={"Do Not Destroy"}
                      name={"doNotDestroy"}
                    />
                  </div>
                  <div className="col-3">
                    <FormDate
                      className={"form-date-picker note-date-picker "}
                      wf={true}
                      isStyled={true}
                      width={"260px"}
                      label={CONSTANT.DATE.DATE_OF_WILL}
                      onChangeDate={handleDateChange}
                      state={dateState}
                    />
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="d-flex flex-column col-6">
                    <Label className={`label mb-1`}>Description</Label>
                    <TextArea
                      placeholder="Enter Details"
                      autoSize={true}
                      value={details}
                      style={{
                        height: "62px",
                        minWidth: "550px",
                      }}
                      onChange={(e) => setDetails(e.value)}
                    />
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col-3 me-3">
                    <FormComboBox
                      wf={true}
                      isStyled={true}
                      width={"260px"}
                      onChangeComboBox={handleComboBoxChange}
                      //   onFilterChange={feeEarnerfilterChange}
                      fiterable={false}
                      data={feeEarner}
                      textField="displayname"
                      state={comboBoxState}
                      name={CONSTANT.COMBOBOX.FEE_EARNER}
                    />
                  </div>
                  <div className="col-3 me-3">
                    <FormComboBox
                      wf={true}
                      isStyled={true}
                      width={"260px"}
                      onChangeComboBox={handleComboBoxChange}
                      fiterable={false}
                      data={yesNoData}
                      state={comboBoxState}
                      label={"Is Deceased"}
                      placeholder={"Select Option"}
                      name={"isDeceased"}
                    />
                  </div>
                  <div className="col-3 me-3">
                    <FormComboBox
                      wf={true}
                      isStyled={true}
                      width={"260px"}
                      onChangeComboBox={handleComboBoxChange}
                      fiterable={false}
                      data={yesNoData}
                      state={comboBoxState}
                      label={"In Storage"}
                      placeholder={"Select Option"}
                      name={"isStorage"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export const ExtraInfoTab = ({
  isView,
  locale,
  data,
  dateState,
  inputState,
  handleInputChange,
  handleDateChange,
  extraDetails,
  setExtraDetails,
}) => {
  return (
    <>
      {isView ? (
        <div
          style={{
            display: !isView && "none",
            width: "1130px",
          }}
        >
          <div className="container-fluid mt-4 pt-2">
            <p
              className={`${styles["section-title"]}`}
              style={{
                lineHeight: "10px",
              }}
            >
              Annual Cost
            </p>
            <hr className="mt-0 mb-3" />
            <div className="row">
              <div className="d-flex align-items-start justify-content-start px-4 ms-2">
                <div className="" style={{ width: "34%" }}>
                  <DataList
                    text={"Annual Fee:"}
                    value={AmountFormat(data?.annualFee)}
                    //
                    alignItems="align-items-start"
                    //
                    keyStyles={{ minWidth: "165px" }}
                  />
                  <DataList
                    text={"Renewal Date:"}
                    value={DateFormat1(data?.annualFeeDate, locale)}
                    //
                    alignItems="align-items-start"
                    //
                    keyStyles={{ minWidth: "165px" }}
                  />
                </div>
                <div className="ps-5">
                  <DataList
                    text={"Payment Type:"}
                    value={data?.paymentType}
                    alignItems="align-items-start"
                    keyStyles={{ minWidth: "150px" }}
                  />
                </div>
              </div>
            </div>
            <p
              className={`${styles["section-title"]} mt-3`}
              style={{
                lineHeight: "10px",
              }}
            >
              Acquired
            </p>
            <hr className="mt-0 mb-3" />
            <div className="row">
              <div className="d-flex align-items-start justify-content-start px-4 ms-2">
                <div className="" style={{ width: "34%" }}>
                  <DataList
                    text={"Ref:"}
                    value={data?.acquiredRef}
                    //
                    alignItems="align-items-start"
                    //
                    keyStyles={{ minWidth: "165px" }}
                  />
                  <DataList
                    text={"Acquired From:"}
                    value={data?.acquiredName}
                    //
                    alignItems="align-items-start"
                    //
                    keyStyles={{ minWidth: "165px" }}
                  />
                  <DataList
                    text={"Date Acquired:"}
                    value={DateFormat1(data?.acquiredDate, locale)}
                    alignItems="align-items-start"
                    keyStyles={{ minWidth: "165px" }}
                  />
                </div>
              </div>
            </div>
            <p
              className={`${styles["section-title"]} mt-3`}
              style={{
                lineHeight: "10px",
              }}
            >
              Sundry
            </p>
            <hr className="mt-0 mb-3" />
            <div className="row">
              <div className="d-flex align-items-start justify-content-start px-4 ms-2">
                <div className="" style={{ width: "34%" }}>
                  <DataList
                    text={"Internal Ref:"}
                    value={data?.internalReference}
                    //
                    alignItems="align-items-start"
                    //
                    keyStyles={{ minWidth: "165px" }}
                  />
                  <DataList
                    text={"Notes:"}
                    value={data?.extraDetails}
                    //
                    alignItems="align-items-start"
                    //
                    keyStyles={{ minWidth: "165px" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          className="container pb-2"
          style={{
            width: "1130px",
            display: isView && "none",
          }}
        >
          <div className="row g-2 mt-3">
            <div className="col">
              <div className="container">
                <div className="mt-2">
                  <p
                    className={`${styles["section-title"]}`}
                    style={{
                      lineHeight: "10px",
                    }}
                  >
                    Annual Cost
                  </p>
                  <hr className="mt-0 mb-3" />
                  <div className="row">
                    <div className="col-3 me-3">
                      <FormNumericTextBox
                        type="number"
                        differentName={true}
                        className={"right-align-input"}
                        wf={true}
                        isStyled={true}
                        width={"260px"}
                        state={inputState}
                        labelForBox={"Annual Fee"}
                        ph={"0.00"}
                        label={"annualFee"}
                        onChangeInput={handleInputChange}
                      />
                    </div>
                    <div className="col-3 me-3">
                      <FormInput
                        wf={true}
                        isStyled={true}
                        // className={"mt-3 "}
                        width={"260px"}
                        state={inputState}
                        ph={"Enter Type"}
                        label={"paymentType"}
                        labelForComboBox={"Payment Type"}
                        onChangeInput={handleInputChange}
                      />
                    </div>
                    <div className="col-3 me-3">
                      <FormDate
                        className={"form-date-picker note-date-picker "}
                        wf={true}
                        isStyled={true}
                        width={"260px"}
                        label={"annualFeeDate"}
                        customLabel={"Annual Fee Date"}
                        onChangeDate={handleDateChange}
                        state={dateState}
                      />
                    </div>
                  </div>
                  {/* <div className="row mt-3"></div> */}
                </div>
                <div className="mt-4">
                  <p
                    className={`${styles["section-title"]}`}
                    style={{
                      lineHeight: "10px",
                    }}
                  >
                    Acquired
                  </p>
                  <hr className="mt-0 mb-3" />
                  <div className="row">
                    <div className="col-3 me-3">
                      <FormInput
                        wf={true}
                        isStyled={true}
                        // className={"mt-3 "}
                        width={"260px"}
                        ph={"Enter Ref"}
                        state={inputState}
                        label={"acquiredRef"}
                        labelForComboBox={"Acquired Ref"}
                        onChangeInput={handleInputChange}
                      />
                    </div>
                    <div className="col-3 me-3">
                      <FormInput
                        wf={true}
                        isStyled={true}
                        // className={"mt-3 "}
                        width={"260px"}
                        state={inputState}
                        label={"acquiredName"}
                        ph={"Enter Name"}
                        labelForComboBox={"Acquired Name"}
                        onChangeInput={handleInputChange}
                      />
                    </div>
                    <div className="col-3 me-3">
                      <FormDate
                        className={"form-date-picker note-date-picker "}
                        wf={true}
                        isStyled={true}
                        width={"260px"}
                        label={"acquiredDate"}
                        customLabel={"Acquired Date"}
                        onChangeDate={handleDateChange}
                        state={dateState}
                      />
                    </div>
                  </div>
                </div>
                <div className="mt-4">
                  <p
                    className={`${styles["section-title"]}`}
                    style={{
                      lineHeight: "10px",
                    }}
                  >
                    Sundry
                  </p>
                  <hr className="mt-0 mb-3" />
                  <div className="row">
                    <div className="col-3 me-3">
                      <FormInput
                        wf={true}
                        isStyled={true}
                        // className={"mt-3 "}
                        width={"260px"}
                        state={inputState}
                        label={"internalReference"}
                        ph={"Enter Ref"}
                        labelForComboBox={"Acquired Ref"}
                        onChangeInput={handleInputChange}
                      />
                    </div>
                    <div className="col-6 me-3">
                      <Label className={`label mb-1`}>Notes</Label>
                      <TextArea
                        placeholder="Enter Details"
                        autoSize={true}
                        value={extraDetails}
                        style={{
                          height: "32px",
                          minWidth: "550px",
                        }}
                        onChange={(e) => setExtraDetails(e.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export const ExecutorsTab = ({
  isView,
  data,
  inputState,
  handleInputChange,
}) => {
  return isView ? (
    <div style={{ display: !isView && "none" }}>
      <div className="container pb-2" style={{ width: "1130px" }}>
        <div className="row g-2 mt-3">
          <div className="col">
            <div className="container">
              <div className="row">
                <div className="d-flex align-items-start justify-content-start mt-3 ps-3">
                  <div className="" style={{ width: "34%" }}>
                    <DataList
                      text={"Executor 1:"}
                      value={data?.executor1}
                      alignItems="align-items-start"
                      keyStyles={{ minWidth: "165px" }}
                    />
                    <DataList
                      text={"Executor 2:"}
                      value={data?.executor2}
                      alignItems="align-items-start"
                      keyStyles={{ minWidth: "165px" }}
                    />
                    <DataList
                      text={"Executor 3:"}
                      value={data?.executor3}
                      alignItems="align-items-start"
                      keyStyles={{ minWidth: "165px" }}
                    />
                    <DataList
                      text={"Executor 4:"}
                      value={data?.executor4}
                      alignItems="align-items-start"
                      keyStyles={{ minWidth: "165px" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div
      className="container pb-2"
      style={{
        width: "1130px",
        display: isView && "none",
      }}
    >
      <div className="row g-2 mt-3">
        <div className="col">
          <div className="container">
            <div className="mt-2">
              <div className="row">
                <div className="col-3 me-3">
                  <FormInput
                    wf={true}
                    isStyled={true}
                    // className={"mt-3 "}
                    width={"260px"}
                    state={inputState}
                    label={"executor1"}
                    labelForComboBox={"Executor 1"}
                    onChangeInput={handleInputChange}
                  />
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-3 me-3">
                  <FormInput
                    wf={true}
                    isStyled={true}
                    // className={"mt-3 "}
                    width={"260px"}
                    state={inputState}
                    label={"executor2"}
                    labelForComboBox={"Executor 2"}
                    onChangeInput={handleInputChange}
                  />
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-3 me-3">
                <FormInput
                  wf={true}
                  isStyled={true}
                  // className={"mt-3 "}
                  width={"260px"}
                  state={inputState}
                  label={"executor3"}
                  labelForComboBox={"Executor 3"}
                  onChangeInput={handleInputChange}
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-3 me-3">
                <FormInput
                  wf={true}
                  isStyled={true}
                  // className={"mt-3 "}
                  width={"260px"}
                  state={inputState}
                  label={"executor4"}
                  labelForComboBox={"Executor 4"}
                  onChangeInput={handleInputChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const TestatorsTab = ({
  isView,
  data,
  inputState,
  handleInputChange,
}) => {
  return (
    <>
      {isView ? (
        <div style={{ display: !isView && "none" }}>
          <div className="container pb-2" style={{ width: "1130px" }}>
            <div className="row g-2 mt-3">
              <div className="col">
                <div className="container">
                  <div className="row">
                    <div className="d-flex align-items-start justify-content-start mt-3 ps-3">
                      <div className="" style={{ width: "34%" }}>
                        <DataList
                          text={"Testator/Owner 1:"}
                          value={data?.testator1}
                          alignItems="align-items-start"
                          keyStyles={{ minWidth: "165px" }}
                        />
                        <DataList
                          text={"Testator/Owner 2:"}
                          value={data?.testator2}
                          alignItems="align-items-start"
                          keyStyles={{ minWidth: "165px" }}
                        />
                        <DataList
                          text={"Testator/Owner 3:"}
                          value={data?.testator3}
                          alignItems="align-items-start"
                          keyStyles={{ minWidth: "165px" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          className="container pb-2"
          style={{
            width: "1130px",
            display: isView && "none",
          }}
        >
          <div className="row g-2 mt-3">
            <div className="col">
              <div className="container">
                <div className="mt-2">
                  <div className="row">
                    <div className="col-3 me-3">
                      <FormInput
                        wf={true}
                        isStyled={true}
                        // className={"mt-3 "}
                        width={"260px"}
                        state={inputState}
                        label={"testator1"}
                        labelForComboBox={"Testator / Owner 1"}
                        onChangeInput={handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-3 me-3">
                      <FormInput
                        wf={true}
                        isStyled={true}
                        // className={"mt-3 "}
                        width={"260px"}
                        state={inputState}
                        label={"testator2"}
                        labelForComboBox={"Testator / Owner 2"}
                        onChangeInput={handleInputChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-3 me-3">
                    <FormInput
                      wf={true}
                      isStyled={true}
                      // className={"mt-3 "}
                      width={"260px"}
                      state={inputState}
                      label={"testator3"}
                      labelForComboBox={"Testator / Owner 3"}
                      onChangeInput={handleInputChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
