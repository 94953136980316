import {
  ADD_DASHBOARD_MATTER_FILTER,
  GET_DASHBOARD_BILLING_TARGET,
  GET_DASHBOARD_MATTER_DATA,
  START_LOADING,
  STOP_LOADING,
  SUCCESS,
} from "../types/index";
import * as api from "../api/index.js";
import { handleApiError } from "Helper";

export const addMattersDashboardFilter = (data) => async (dispatch) => {
  try {
    dispatch({ type: ADD_DASHBOARD_MATTER_FILTER, payload: data });
  } catch (error) {
    handleApiError(error);
  }
};

export const getDashboardBillingTargetData =
  (teamId, feeEarnerRef, branchId, fromDate, toDate) => async (dispatch) => {
    try {
      dispatch({ type: START_LOADING });

      const datas = await api.getDashboardBillingTargetData(
        teamId,
        feeEarnerRef,
        branchId,
        fromDate,
        toDate
      );
      dispatch({ type: GET_DASHBOARD_BILLING_TARGET, payload: datas.data });
      dispatch({ type: STOP_LOADING });
      dispatch({ type: SUCCESS, payload: datas.message });
    } catch (error) {
      handleApiError(error);
    }
  };

export const getDashboardMatterData =
  (teamId, feeEarnerRef, branchId, fromDate, toDate) => async (dispatch) => {
    try {
      dispatch({ type: START_LOADING });
      const datas = await api.getDashboardMatterData(
        teamId,
        feeEarnerRef,
        branchId,
        fromDate,
        toDate
      );
      dispatch({ type: GET_DASHBOARD_MATTER_DATA, payload: datas.data });
      dispatch({ type: STOP_LOADING });
      dispatch({ type: SUCCESS, payload: datas.message });
    } catch (error) {
      handleApiError(error);
    }
  };
