import React, { useState, useRef, useEffect } from "react";
import { process } from "@progress/kendo-data-query";
import { useDispatch, useSelector } from "react-redux";
import { getactiveVats } from "actions/vat.action";
import { getActiveCurrency } from "actions/currency.action";
import "@progress/kendo-react-intl";
import "@progress/kendo-react-dropdowns";
import "react-router-dom";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { Button } from "@progress/kendo-react-buttons";
import { HandleGridBody } from "components/common/HandleGridBody.js";
import { Checkbox } from "@progress/kendo-react-inputs";
import { Menu, MenuItem } from "@progress/kendo-react-layout";
import AddNominal from "./AddNewNominal.jsx";
import { AmountFormatCell } from "components/common/formatter";
import { NominalTypeFormatCell } from "components/common/nominalTypeFormatter";
import { ColumnsButton } from "components/common/ColumnsButton";
import {
  changeNominalToLedgerRef,
  getactiveNominals,
  getViewNominals,
} from "actions/nominals.action.js";
import SearchTextBox from "components/SearchComponent.jsx";
import styles from "./nominal.module.scss";
import { useNavigate } from "react-router-dom";
import {
  NameTextCell,
  RedirectCell,
} from "components/common/GridComponents.jsx";
import { getNominalsList } from "api/accountsAPI.js";


const columnsData = [
  {
    title: "Nominal Ref",
    field: "NominalRef",
    show: true,
  },
  {
    title: "Nominal Name",
    field: "NominalName",
    show: true,
  },
  {
    title: "Type",
    field: "Type",
    show: true,
  },
  {
    title: "Group",
    field: "Group",
    show: true,
  },
  {
    title: "VAT Code",
    field: "VATCode",
    show: true,
  },
  {
    title: "Currency",
    field: "Currency",
    show: true,
  },
  {
    title: "Balance",
    field: "Balance",
    show: true,
  },
];

const initialSort = [
  {
    field: "nominalRef",
    dir: "asc",
  },
];

const Nominal = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showState, setShowState] = useState();
  const [nominalList, setNominalList] = useState([]);
  const [searchValue, setSearchValue] = React.useState();
  const [isSearched, setIsSearched] = React.useState(false);
  const [list, setLists] = React.useState({
    data: [],
    total: 50,
  });

  const [dataState, setDataState] = React.useState({
    take: 50,
    skip: 0,
    sort: [
      {
        field: "nominalRef",
        dir: "asc",
      },
    ],
  });

  const [dataResult, setDataResult] = React.useState(process([], dataState));

  const getNominals = async () => {
    let parameter = "";
    if (archiveChecked) {
      parameter += "&IncludeArchive=true";
    }
    if (includeZeroChecked) {
      parameter += "&ExcludeZeroBalance=true";
    }
    if (searchValue) {
      parameter += `&search=${searchValue}`;
    }
    try {
      const data = await getNominalsList(parameter);
      setNominalList(data?.data.data);
      setDataResult(process(data?.data.data, dataState));
    } catch (error) {}
  };

  const [archiveChecked, setArchiveChecked] = useState(false);
  const archiveChange = () => setArchiveChecked(!archiveChecked);
  const [includeZeroChecked, setIncludeZeroChecked] = useState(false);
  const includeZeroCheckedChange = () =>
    setIncludeZeroChecked(!includeZeroChecked);
  const [columns, setColumns] = useState(columnsData);

  const Redirect = (props) => (
    <RedirectCell handleClick={() => handleAction(props.dataItem.nominalId)} />
  );

  useEffect(() => {
    getNominals();
  }, [archiveChecked, includeZeroChecked, searchValue]);

  React.useEffect(() => {
    dispatch(getactiveVats());
    dispatch(getactiveNominals());
    dispatch(getActiveCurrency());
  }, [dispatch]);

  const handleAction = (nominalId) => {
    dispatch(getViewNominals(nominalId));
    setShowState("View");
  };

  const createState = (skip, take) => {
    let pagerSettings = {
      pageSizes: [10, 25, 50, 100],
    };
    return {
      pageSize: take,
      pageable: pagerSettings,
    };
  };
  const [state, setState] = React.useState(createState(0, 10));
  const [eventDataState, setEventDataState] = React.useState();
  const searchRef = useRef();

  const dataStateChange = (e) => {
    const eventObject = { event: e.dataState };
    setEventDataState(eventObject);
    if (
      e.dataState.sort !== undefined &&
      e.dataState.skip !== 0 &&
      dataState.sort !== e.dataState.sort
    ) {
      setDataState({ ...e.dataState, skip: 0 });
    } else if (e.dataState.sort[0] === undefined) {
      setDataState({
        ...e.dataState,
        sort: [
          {
            field: dataState.sort[0].field,
            dir: "asc",
          },
        ],
      });
    } else {
      setDataState(e.dataState);
    }
  };

  const dataReceived = (list) => {
    if (list.data) {
      setLists(list);
    }
  };

  const onSubmit = (columns) => {
    setColumns(columns);
  };

  const LedgerCell = (props) => {
    return (
      <td>
        <span
          style={{ cursor: "pointer" }}
          onClick={() => {
            dispatch(changeNominalToLedgerRef(props.dataItem.nominalRef));
            navigate(`/nominal/nominalledger`);
          }}
        >
          <u>{props.dataItem.nominalRef}</u>
        </span>
      </td>
    );
  };

  const showColumn = (field) => {
    let result = columns.filter(
      (col) => col.field == field && col.show == true
    );
    if (result.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const handleRefresh = () => {
    setIsSearched(false);
    setArchiveChecked(false);
    setSearchValue("");
    searchRef.current.clearSearch();
    if (eventDataState) {
      setDataState({
        ...eventDataState?.event,
        skip: 0,
      });
    } else {
      setDataState({
        ...dataState,
        take: 50,
        skip: 0,
      });
    }
    getNominals();
  };

  return (
    <>
      <div className="page">
        <div className="grid-layout-container" style={{ marginBottom: 20 }}>
          <div className="title menu-button flex justify-space-between align-items-center">
            <div className="d-flex">
              <div className="d-flex">
                <SearchTextBox
                  ref={searchRef}
                  changeFilterValue={setSearchValue}
                />
                <Button
                  className="ms-3 border-0 common-btn"
                  icon="refresh"
                  onClick={handleRefresh}
                >
                  Refresh
                </Button>
              </div>
              <div
                className={`ms-4 ${styles["checkbox-label"]}`}
                style={{
                  flexDirection: "column",
                  display: "flex",
                  fontSize: 15,
                  fontWeight: "500",
                }}
              >
                <Checkbox
                  defaultChecked={false}
                  checked={includeZeroChecked}
                  onClick={includeZeroCheckedChange}
                  label={"Hide Zero Balance"}
                />
                <Checkbox
                  defaultChecked={false}
                  checked={archiveChecked}
                  onClick={archiveChange}
                  label={"Include Archive"}
                />
              </div>
            </div>

            <div className="d-flex">
              <ColumnsButton
                className=""
                columnsData={columnsData}
                onSubmit={onSubmit}
              />

              <Button
                className="common-btn"
                onClick={() => setShowState("Add")}
              >
                Add Nominal
              </Button>
            </div>
          </div>
        </div>

        <Grid
          data={dataResult}
          className={`mb-1 fs-md ${styles.nominal_gridheight}`}
          sortable={true}
          onDataStateChange={(e) => {
            setDataState(e.dataState);
            setDataResult(process(nominalList, e.dataState));
          }}
          {...dataState}
          pageable={{
            pageSizes: [10, 20, 50, 100],
          }}
          resizable={true}
        >
          {showColumn("NominalRef") && (
            <GridColumn
              field="nominalRef"
              title="Nominal Ref"
              width="140px"
              cell={LedgerCell}
            />
          )}

          {showColumn("NominalName") && (
            <GridColumn
              field="nominalName"
              title="Nominal Name"
              className="gridBoldField"
            />
          )}

          <GridColumn field="branchName" title="Branch" width="140px" />

          {showColumn("Type") && (
            <GridColumn
              field="typeName"
              title="Type"
              cell={NominalTypeFormatCell}
              width="180px"
            />
          )}
          {showColumn("Group") && (
            <GridColumn
              field="subTypeName"
              title="Sub Type"
              cell={NominalTypeFormatCell}
              width="140px"
            />
          )}
          {showColumn("VATCode") && (
            <GridColumn field="vatDetails" title="VAT" width="180px" />
          )}
          {showColumn("Currency") && (
            <GridColumn field="currencyCode" title="Currency" width="100px" />
          )}
          {showColumn("Balance") && (
            <GridColumn
              field="balance"
              title="Balance"
              width="120px"
              className="balanceCol balanceTd"
              cell={AmountFormatCell}
              headerClassName="grid-header-center"
            />
          )}
          {archiveChecked && (
            <GridColumn field="archived" title="Archive" width="80px" />
          )}
          <GridColumn width="60px" cell={Redirect} sortable={false} />
        </Grid>

        {/* <HandleGridBody
          dataState={dataState}
          onDataReceived={dataReceived}
          searchValue={searchValue}
          isSearched={isSearched}
          currentComponent="nominal"
        /> */}
      </div>

      {showState && (
        <AddNominal
          view={showState}
          updateNominals={getNominals}
          onClose={() => {
            setShowState(undefined);
          }}
        />
      )}
    </>
  );
};
export default Nominal;
