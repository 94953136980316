import { useEffect, useState } from "react";
import { Tile } from "components/UIComponents/Tile";
import { RadioGroup } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import IndividualIcon from "assets/Individual-Icon.png";
import CompanyIcon from "assets/Company-Icon.png";
import { Label } from "@progress/kendo-react-labels";
import { Input } from "@progress/kendo-react-inputs";
import { Form, Field } from "@progress/kendo-react-form";
import AddressInputComponent from "components/common/AddressInputComponent/index";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { CustomDateInput } from "components/common/CustomDateInput";
import CalenderCustomToggleButton from "components/common/CalenderCustomToogleButton";
import { useSelector, useDispatch } from "react-redux";
import styles from "./ClientDetails.module.scss";
import AutoCompleteInput from "components/AutoCompleteCompanies/AutoCompleteCompany";
import { CompanyItemRender } from "components/AutoCompleteCompanies/CompanyItemRender";
import { getBranchesnames } from "../../../actions/branchnames.action";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { filterBy } from "@progress/kendo-data-query";
import {
  getCompanyProfile,
  getClientRefExample,
  addClient,
  getClientData,
  getClientsList,
  editClient,
  startOnboarding,
} from "../../../api/index";
import {
  CompanyInformationView,
  ContactInformationView,
} from "components/Clients";
import { combineAddressLines } from "Helper";

const DEFAULT_STATE = {
  individual: {
    firstName: "",
    lastName: "",
    dateOfBirth: "",
    clientRef: "",
    branch: "",
    mobile: "",
    email: "",
    phone1: "",
    address: {
      line1: "",
      line2: "",
      town: "",
      state: "",
      postcode: "",
      country: "",
    },
  },
  company: {
    clientRef: "",
    corrFirstName: "",
    corrPhone: "",
    corrEmail: "",
    corrJobTitle: "",
    branch: "",
    phone1: "",
    email: "",
    address: {
      line1: "",
      line2: "",
      town: "",
      state: "",
      postcode: "",
      country: "",
    },
  },
};

const ClientDetails = ({ moveNext, formData }) => {
  const [clientType, setClientType] = useState(
    formData?.clientData?.clientType || "new"
  );
  const [clientSubType, setClientSubType] = useState(
    formData?.clientData?.clientSubType || "individual"
  );
  const [inputState, setInputState] = useState(
    formData?.clientData || DEFAULT_STATE
  );

  const [formKey, setFormKey] = useState(0);
  const [companyName, setCompanyName] = useState(
    formData?.clientData?.companyName || null
  );
  const [companyProfile, setCompanyProfile] = useState(null);
  const [editData, setEditData] = useState(
    formData?.clientData?.editData || {}
  );
  const [selectedClient, setSelectedClient] = useState(
    formData?.clientData || null
  );

  const dispatch = useDispatch();
  const { branchesList } = useSelector((state) => state.branchesnames);
  const { userlist: user } = useSelector((state) => state.currentuser);
  const [clientData, setClientData] = useState([]);
  const [clientListFiltered, setClientListFiltered] = useState();
  const [validationState, setValidationState] = useState(false);

  useEffect(() => {
    if (companyProfile?.company_number) {
      let selectedAddress =
        companyProfile.service_address ||
        companyProfile.registered_office_address ||
        {};

      let address = {
        line1: combineAddressLines(
          selectedAddress.address_line_1,
          selectedAddress.address_line_2
        ),
        town: selectedAddress?.locality,
        state: selectedAddress.region,
        postcode: selectedAddress?.postal_code,
        country: selectedAddress.country,
      };
      handleAddressChange(address);
      setFormKey((prev) => prev + 1);
    }
  }, [companyProfile?.company_number]);

  const handleInputChange = (value, label) => {
    setInputState((prevState) => ({
      ...prevState,
      [clientSubType]: {
        ...prevState[clientSubType],
        [label]: value,
      },
    }));
  };

  const handleAddressChange = (address) => {
    if (!address.line1) {
      address = {
        line1: "",
        line2: "",
        town: "",
        state: "",
        postcode: "",
        country: "",
      };
    }
    setInputState((prevState) => ({
      ...prevState,
      [clientSubType]: {
        ...prevState[clientSubType],
        address,
      },
    }));
  };

  const handleClientSubTypeChange = (subType) => {
    setClientSubType(subType);
    setValidationState(false);
  };

  const handleSelection = async (event) => {
    try {
      const response = await getCompanyProfile(event);
      setCompanyProfile(response?.data);
    } catch (error) {
      console.error("Failed to get company profile:", error);
    }
  };

  const getClientReference = async () => {
    const Name =
      clientSubType === "individual"
        ? `${inputState?.individual?.firstName} ${inputState?.individual?.lastName}`
        : companyName;
    if (!Name) {
      return;
    }
    try {
      const response = await getClientRefExample(Name);
      setInputState((prevState) => ({
        ...prevState,
        [clientSubType]: {
          ...prevState[clientSubType],
          clientRef: response?.data,
        },
      }));
    } catch (error) {}
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let hasError = false;

    if (clientType === "new") {
      if (clientSubType === "individual") {
        if (
          !inputState?.individual?.firstName ||
          !inputState?.individual?.lastName ||
          !inputState?.individual?.clientRef
        ) {
          hasError = true;
        }
      }

      if (clientSubType === "company") {
        if (!companyName || !inputState.company.clientRef) {
          hasError = true;
        }
      }
    }

    if (clientType === "existing") {
      if (!selectedClient) {
        hasError = true;
      }
    }

    setValidationState(hasError);

    if (!hasError) {
      try {
        const isNewClient = clientType === "new";
        const clientId = formData?.clientData?.clientId;

        if (isNewClient) {
          const payload = {
            clientRef: inputState[clientSubType].clientRef,
            clientName: companyName,
            branchId: inputState[clientSubType].branch?.branchId,
            active: true,
            type: clientSubType === "company" ? "Company" : "Person",
            contact: {
              email1: inputState[clientSubType].email,
              mobileNo: inputState[clientSubType].mobile,
              phone1: inputState[clientSubType].phone1,
              active: true,
              type: clientSubType === "company" ? "Company" : "Person",
              company: null,
              address: {
                ...inputState[clientSubType].address,
                line1: inputState[clientSubType].address.line1.split("\n")[0],
                line2: inputState[clientSubType].address.line1.split("\n")[1],
              },
              person: {
                firstName: inputState?.individual?.firstName,
                lastName: inputState.individual?.lastName,
                dateOfBirth: inputState.individual?.dateOfBirth,
              },
            },
          };

          if (clientSubType === "company") {
            payload.company = {
              corrFirstName: inputState.company.corrFirstName,
              corrPhone: inputState.company.corrPhone,
              corrEmail: inputState.company.corrEmail,
              corrJobTitle: inputState.company.corrJobTitle,
              companyRegNo: companyProfile?.company_number,
              companyName: companyProfile?.company_name,
              registeredAddress: {
                ...inputState.company.address,
                line1: inputState.company.address.line1.split("\n")[0],
                line2: inputState.company.address.line1.split("\n")[1],
              },
            };
          }

          const payloadResponse = !clientId
            ? await addClient(payload)
            : await editClient(clientId, payload);

          const responseClientId = payloadResponse?.data?.data?.clientId;

          const clientData = {
            clientId: responseClientId,
            clientRef: inputState[clientSubType].clientRef,
            companyName,
            editData: null,
            clientType,
            clientSubType,
            individual:
              clientSubType === "individual" ? inputState.individual : null,
            company: clientSubType === "company" ? inputState.company : null,
          };

          const onboardingResponse = await startOnboarding({
            clientId: responseClientId,
            active: true,
          });

          moveNext(
            {},
            {
              clientData,
              onboardingId: onboardingResponse?.data?.data?.onboardingId,
            }
          );
        } else {
          const clientData = {
            clientType: "existing",
            clientSubType: null,
            ...selectedClient,
            editData,
          };

          const onboardingResponse = await startOnboarding({
            clientId: selectedClient.clientId,
            active: true,
          });
          moveNext(
            {},
            {
              clientData,
              onboardingId: onboardingResponse?.data?.data?.onboardingId,
            }
          );
        }
      } catch (error) {
        console.error("Failed to add or edit client:", error);
      }
    }
  };

  const filterData = (filter) => {
    const data = clientData.slice();
    return filterBy(data, filter);
  };
  const filterChange = (event) => {
    setClientListFiltered(filterData(event.filter));
  };

  const fetchClientData = async (data) => {
    try {
      if (!data) {
        return;
      }
      setSelectedClient({
        clientId: data.clientId,
        clientName: data.clientName,
        clientRef: data.clientRef,
      });
      const response = await getClientData(data?.clientId);
      setEditData(response?.data?.data);
    } catch (error) {
      console.error("Failed to get client data:", error);
    }
  };

  const getAllClients = async () => {
    try {
      const response = await getClientsList();
      setClientData(response?.data?.data);
      setClientListFiltered(response?.data?.data);
    } catch (error) {
      console.error("Failed to get clients list:", error);
    }
  };
  useEffect(() => {
    dispatch(getBranchesnames());
  }, []);

  useEffect(() => {
    if (clientType === "existing") {
      getAllClients();
    }
  }, [clientType]);

  return (
    <Tile heading="Client Details" className="overflow-auto px-4 h-100">
      <div style={{ height: "calc(100% - 70px)" }}>
        <form onSubmit={handleSubmit} className="h-100">
          <div
            className="overflow-auto"
            style={{ height: "calc(100% - 55px)" }}
          >
            <div className={`${styles.container} `}>
              <div className="mx-2 my-3">
                <RadioGroup
                  data={[
                    { label: "New Client", value: "new" },
                    { label: "Existing Client", value: "existing" },
                  ]}
                  defaultValue={clientType}
                  layout={"horizontal"}
                  onChange={(e) => {
                    setClientType(e.value);
                    setValidationState(false);
                  }}
                />
              </div>
              {clientType === "new" && (
                <>
                  <div className="mb-4 mx-2 d-flex">
                    <div
                      style={{
                        border:
                          clientSubType === "individual"
                            ? "1px solid #241cca"
                            : "",
                      }}
                      onClick={() => handleClientSubTypeChange("individual")}
                      className={styles.customButton}
                    >
                      <img
                        src={IndividualIcon}
                        width={22}
                        height={22}
                        alt="profile icon"
                      />
                      <span
                        style={{
                          color:
                            clientSubType === "individual"
                              ? "#241cca"
                              : "#707070",
                        }}
                      >
                        Individual
                      </span>
                    </div>

                    <div
                      style={{
                        border:
                          clientSubType === "company"
                            ? "1px solid #241cca"
                            : "",
                      }}
                      className={styles.customButton}
                      onClick={() => handleClientSubTypeChange("company")}
                    >
                      <img
                        src={CompanyIcon}
                        width={22}
                        height={22}
                        alt="Company Icon"
                      />
                      <span
                        style={{
                          color:
                            clientSubType === "company" ? "#241cca" : "#707070",
                        }}
                      >
                        Company
                      </span>
                    </div>
                  </div>

                  <div className="row mb-2 ">
                    <div
                      className={
                        clientSubType === "company"
                          ? "col-xxl-8 col-12"
                          : "col-md-12 col-xl-10"
                      }
                    >
                      {clientSubType === "company" ? (
                        <div className="row mt-2">
                          <div className="col-lg-5 col-12 ">
                            <Label className="d-block mb-1">Company Name</Label>
                            <AutoCompleteInput
                              blurRef={getClientReference}
                              name="clientName"
                              label="Company Name"
                              ph="Enter Company Name"
                              className={`${styles.inputStyle} ${
                                validationState && !companyName
                                  ? styles.error
                                  : ""
                              }`}
                              itemRender={CompanyItemRender(handleSelection)}
                              onChange={(e) => setCompanyName(e.value)}
                              setClientName={setCompanyName}
                              value={companyName}
                            />
                          </div>
                        </div>
                      ) : (
                        <div className="row mt-2">
                          <div className="col-lg-5 mr-4 col-12 ">
                            <Label className="label d-block mb-1">
                              First Name
                            </Label>
                            <Input
                              onBlur={getClientReference}
                              name="firstName"
                              // required={true}
                              placeholder="Enter First Name"
                              className={`${styles.inputStyle} ${
                                validationState &&
                                !inputState?.individual?.firstName
                                  ? styles.error
                                  : ""
                              }`}
                              value={inputState?.individual?.firstName}
                              onChange={(e) =>
                                handleInputChange(e.value, "firstName")
                              }
                            />
                          </div>
                          <div className="col-lg-5 col-12 ">
                            <Label className="label d-block mb-1">
                              Last Name
                            </Label>
                            <Input
                              onBlur={getClientReference}
                              name="lastName"
                              // required={true}
                              placeholder="Enter Last Name"
                              className={`${styles.inputStyle} ${
                                validationState &&
                                !inputState?.individual?.lastName
                                  ? styles.error
                                  : ""
                              }`}
                              value={inputState?.individual?.lastName}
                              onChange={(e) =>
                                handleInputChange(e.value, "lastName")
                              }
                            />
                          </div>
                        </div>
                      )}
                      {clientSubType === "individual" && (
                        <div className="row mt-3 ">
                          <div className="col-4 form-date-picker note-date-picker check-date-picker">
                            <Label className="label mb-1 d-block">
                              Date Of Birth
                            </Label>
                            <DatePicker
                              className={styles.inputStyle}
                              id="date"
                              dateInput={CustomDateInput}
                              toggleButton={CalenderCustomToggleButton}
                              onChange={(e) =>
                                handleInputChange(e.value, "dateOfBirth")
                              }
                              value={inputState?.individual?.dateOfBirth}
                            />
                          </div>
                        </div>
                      )}
                      <div className="row mt-3">
                        {clientSubType === "company" ? (
                          <div className="col-lg-5 col-12 ">
                            <Label className="label d-block mb-1">
                              Phone No.
                            </Label>
                            <Input
                              name="Mobile"
                              type="text"
                              placeholder="Enter Phone Number"
                              value={inputState?.company?.phone1}
                              onChange={(e) =>
                                handleInputChange(e.value, "phone1")
                              }
                              className={styles.inputStyle}
                            />
                          </div>
                        ) : (
                          <div className="col-lg-5 col-12 ">
                            <Label className="label d-block mb-1">
                              Mobile No.
                            </Label>
                            <Input
                              name="Mobile"
                              type="text"
                              placeholder="Enter Mobile Number"
                              value={inputState?.individual?.mobile}
                              onChange={(e) =>
                                handleInputChange(e.value, "mobile")
                              }
                              className={styles.inputStyle}
                            />
                          </div>
                        )}

                        <div className="col-lg-5 col-12 ">
                          <Label className="label d-block mb-1 ">Email</Label>
                          <Input
                            name="email"
                            type="email"
                            placeholder="Enter Email"
                            value={inputState[clientSubType]?.email}
                            onChange={(e) =>
                              handleInputChange(e.target.value, "email")
                            }
                            className={styles.inputStyle}
                          />
                        </div>
                      </div>
                      <div className="mt-3">
                        <Form
                          initialValues={inputState[clientSubType]?.address}
                          key={`${clientSubType}-${formKey}`}
                          render={() => (
                            <AddressInputComponent
                              name="Address"
                              address={inputState[clientSubType]?.address}
                              setAddress={handleAddressChange}
                              width={"260px"}
                              contactComponent={`col-12 ${"col-lg-5"}`}
                            />
                          )}
                        ></Form>
                      </div>
                      <div className="row mt-3">
                        <div
                          className={`col-12 ${
                            clientSubType === "company"
                              ? "col-lg-5 col-12 "
                              : "col-lg-5"
                          }`}
                        >
                          <Label className="label d-block mb-1">
                            Client Ref
                          </Label>
                          <Input
                            maxLength={10}
                            name="clientRef"
                            // required={true}
                            placeholder="Enter Client Ref"
                            className={`${styles.inputStyle} ${
                              validationState &&
                              !inputState[clientSubType]?.clientRef
                                ? styles.error
                                : ""
                            }`}
                            value={inputState[clientSubType]?.clientRef}
                            onChange={(e) =>
                              handleInputChange(e.value, "clientRef")
                            }
                          />
                        </div>

                        <div className="col-lg-5 col-12">
                          <Label className="label d-block mb-1">Branch</Label>
                          <ComboBox
                            data={branchesList}
                            // required={true}
                            textField="branchName"
                            className={`${styles.inputStyle}`}
                            placeholder="Select Branch"
                            dataItemKey="branchId"
                            iconClassName="wa"
                            value={
                              inputState[clientSubType]?.branch
                                ? inputState[clientSubType]?.branch
                                : branchesList.find(
                                    (branch) =>
                                      branch.branchId === user.branchId
                                  )
                                ? branchesList.find(
                                    (branch) =>
                                      branch.branchId === user.branchId
                                  )
                                : ""
                            }
                            onChange={(e) =>
                              handleInputChange(e.value, "branch")
                            }
                          />
                        </div>
                      </div>
                    </div>
                    {clientSubType === "company" && (
                      <Tile
                        heading="Correspondence Person"
                        className="px-4 pt-2 pb-3 col-xxl-4 col-12 mt-3"
                      >
                        <div className="row px-xxl-3">
                          <div>
                            <Label className="label d-block mb-1">Name</Label>
                            <Input
                              name={`corrFirstName`}
                              style={{
                                height: "32px",
                              }}
                              ph="Enter Name"
                              value={inputState?.company?.corrFirstName}
                              onChange={(e) =>
                                handleInputChange(e.value, "corrFirstName")
                              }
                            />
                          </div>
                          <div className="mt-3">
                            <Label className="label d-block mb-1">
                              Phone Number
                            </Label>
                            <Input
                              name={`corrPhone`}
                              type="number"
                              style={{
                                height: "32px",
                              }}
                              ph="Enter Phone Number"
                              value={inputState?.company?.corrPhone}
                              onChange={(e) =>
                                handleInputChange(e.value, "corrPhone")
                              }
                            />
                          </div>
                          <div className="mt-3">
                            <Label className="label d-block mb-1">Email</Label>
                            <Input
                              name={`corrEmail`}
                              style={{
                                height: "32px",
                              }}
                              ph="Enter Email Address"
                              required={false}
                              value={inputState.company?.corrEmail}
                              onChange={(e) =>
                                handleInputChange(e.value, "corrEmail")
                              }
                            />
                          </div>
                          <div className="col-4 col-lg-12 my-3">
                            <Label className="label d-block mb-1">
                              Job Title
                            </Label>
                            <Input
                              name={`corrJobTitle`}
                              ph="Enter Job Title"
                              style={{
                                height: "32px",
                              }}
                              value={inputState.company?.corrJobTitle}
                              onChange={(e) =>
                                handleInputChange(e.value, "corrJobTitle")
                              }
                            />
                          </div>
                        </div>
                      </Tile>
                    )}
                  </div>
                </>
              )}
              {clientType === "existing" && (
                <div>
                  <div className="mx-3">
                    <Label className="label d-block mb-1">Client</Label>
                    <ComboBox
                      data={clientListFiltered}
                      textField="clientName"
                      className={
                        validationState && !selectedClient
                          ? `${styles.inputStyle} ${styles.error}`
                          : styles.inputStyle
                      }
                      placeholder="Select Client"
                      dataItemKey="clientId"
                      iconClassName="wa"
                      filterable={true}
                      onChange={(e) => fetchClientData(e?.value)}
                      onFilterChange={filterChange}
                      value={selectedClient}
                    />
                  </div>
                  {editData?.type === "Company" ? (
                    <CompanyInformationView data={editData} />
                  ) : (
                    <ContactInformationView data={editData} />
                  )}
                </div>
              )}
            </div>
          </div>

          <div
            className={`${styles.container} me-3`}
            style={{ height: "55px" }}
          >
            <hr className="m-0" />
            <Button
              className="mt-2 common-btn"
              style={{ float: "right" }}
              // type="submit"
            >
              Next
            </Button>
          </div>
        </form>
      </div>
    </Tile>
  );
};

export default ClientDetails;
