import React, { forwardRef } from "react";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";

const CustomTitleBar = (title) => {
  return (
    <div
      style={{
        fontSize: "16px",
        lineHeight: "1.3em",
        width: "250px",
        color: "red",
        display: "flex",
        alignItems: "center",
      }}
    >
      <span className="k-icon k-i-warning" style={{ marginRight: "5px" }} />
      Confirmation Dialog
    </div>
  );
};
const ConfirmationDialog = forwardRef(
  (
    { title = "Please confirm", onClose, onClickYes, onClickNo, content },
    ref
  ) => {
    return (
      <Dialog
        ref={ref}
        id="action_dialog"
        // height={180}
        title={<CustomTitleBar />}
        width={600}
        onClose={onClose}
      >
        <div className="w-100">
          <p
            style={{
              margin: "25px",
              textAlign: "center",
            }}
          >
            {content ? content : "Are you sure to remove file?"}
          </p>
          <div className="mt-2 d-flex align-center justify-content-center me-4 mb-3">
            <button
              className="common-btn me-2 bgBtnSecondary"
              onClick={onClickNo}
            >
              No
            </button>
            <button className="common-btn" onClick={onClickYes}>
              Yes
            </button>
          </div>
        </div>
      </Dialog>
    );
  }
);

export default ConfirmationDialog;
