import * as React from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Input, Checkbox, NumericTextBox } from "@progress/kendo-react-inputs";
import { Label } from "@progress/kendo-react-labels";
import styles from "../Settings.module.scss";
import {
  getDepartmentList,
  getActiveApplications,
  getRateCategories,
} from "api";
import { useEffect, useState } from "react";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { Tile } from "components/UIComponents/Tile";
import { getIncomeNominals } from "actions/nominals.action";
import { prepareApplicationData } from "components/common/ComboRepresentationFunctions";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "components/common/Loading";
import { handleApiError } from "Helper";

const CustomDepartmentCode = (fieldRenderProps) => {
  const {
    // The meta props of the Field.
    validationMessage,
    touched,
    visited,
    modified,
    valid,
    // The input props of the Field.
    value,
    onChange,
    onFocus,
    onBlur,
    // The custom props that you passed to the Field.
    ...others
  } = fieldRenderProps;

  const [departments, setDepartments] = React.useState([]);
  const [valueVat, setValueVat] = useState(null);

  const getActiveDepartments = async () => {
    try {
      const data = await getDepartmentList();
      setDepartments(data.data?.data);
      data?.data?.data.map((item) => {
        if (item.departmentID === value) {
          setValueVat(item);
        }
      });
    } catch (error) {}
  };
  const onValueChange = React.useCallback(
    (e) => {
      setValueVat(e.value);
      // onChange callback expects argument with 'value' property
      onChange({
        value: e.value,
      });
    },
    [onChange, value]
  );
  useEffect(() => {
    getActiveDepartments();
  }, []);
  return (
    <div onFocus={onFocus} onBlur={onBlur} className="d-flex flex-column">
      <Label className="label mt-1 mb-2  w-100">Department&nbsp;</Label>
      <ComboBox
        data={departments}
        className={`${styles["work-type-field-style"]}`}
        value={valueVat}
        iconClassName="wa"
        required={true}
        textField={"name"}
        onChange={onValueChange}
        placeholder={"Search Department"}
      />
      <label className={"k-checkbox-label"} htmlFor={others.id}>
        {others.label}
      </label>
    </div>
  );
};

const EditCategory = (props) => {
  const dispatch = useDispatch();

  const [application, setApplication] = useState();
  const [rateCategories, setRateCategories] = useState([]);
  const { nominalPofitAndLoss } = useSelector((state) => state.nominalList);
  const [profitAndLoss, setProfitAndLoss] = useState(nominalPofitAndLoss.data);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [applicationsData, rateCategoriesData] = await Promise.all([
          getActiveApplications(),
          getRateCategories(),
        ]);

        setApplication(prepareApplicationData(applicationsData?.data?.data));
        setRateCategories(rateCategoriesData?.data?.data);
        dispatch(getIncomeNominals());
      } catch (error) {
        handleApiError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [dispatch]);

  const ADD_NEW = props.type === "addNew";

// construct objects from the returned data to pass to the combobox component 
  const applicationObject = application?.find(
    (app) => app.appCode === props?.item?.appCode
  );

  const rateCategoryObject = rateCategories?.find(
    (rate) => rate.rateCategoryId === props?.item?.rateCategoryId
  );

  const defaultProfitCostObject = nominalPofitAndLoss?.data?.find(
    (nominal) => nominal.nominalRef === props?.item?.defaultProfitCost
  );

  const initialVals = ADD_NEW ? {} : {
    ...props.item,
    appCode: applicationObject,
    rateCategoryId: rateCategoryObject,
    defaultProfitCost: defaultProfitCostObject,
  };

  if (loading) {
    return <Spinner />; // Render a loading spinner while the data is being fetched
  }
  return (
    <Dialog
      title={ADD_NEW ? "Add Work Type" : `Edit ${props?.item?.name}`}
      onClose={props.cancelEdit}
      width={"55vw"}
    >
      <div className="p-3 px-4">
        <Form
          onSubmit={props.onSubmit}
          initialValues={initialVals}
          render={(formRenderProps) => (
            <FormElement
              style={{
                maxWidth: 1200,
              }}
            >
              <fieldset className={"k-form-fieldset"}>
                <div className="px-3">
                  <Tile
                    heading="Work Types"
                    className="mb-1 px-4 pb-2 overflow-auto"
                  >
                    <div className="mb-3">
                      <Field
                        name={"departmentId"}
                        className={`${styles["work-type-field-style"]}`}
                        component={CustomDepartmentCode}
                      />
                    </div>
                    <div className="mb-3">
                      <Label className="label mt-1 mb-2 w-100">
                        Work Type Code&nbsp;
                      </Label>
                      <Field
                        name={"code"}
                        component={Input}
                        className={`${styles["work-type-field-style"]}`}
                        maxLength={5}
                        placeholder={"Enter Code"}
                      />
                    </div>
                    <div className="mb-3">
                      <Label className="label mt-1 mb-2 w-100">
                        Work Type Name&nbsp;
                      </Label>
                      <Field
                        name={"name"}
                        component={Input}
                        className={`${styles["work-type-field-style"]}`}
                        maxLength={20}
                        placeholder={"Enter Name"}
                      />
                    </div>
                  </Tile>
                </div>
                <div className="px-3 mt-2 ">
                  <Tile
                    heading="Matter Default Values"
                    className="mb-1 px-4 pb-4 overflow-auto"
                  >
                    <div className="row mt-3">
                      <div className="col ">
                        <Label className="label">Application</Label>
                        <Field
                          wf={true}
                          isStyled={true}
                          className={`${styles["work-type-field-style"]}`}
                          iconClassName="wa"
                          textField="representation"
                          data={application}
                          name={"appCode"}
                          placeholder={"Application"}
                          component={ComboBox}
                        />
                      </div>
                      <div className="col ">
                        <Label className="label">WIP Credit Limit</Label>
                        <Field
                          name="wipCreditLimit"
                          className={`${styles["work-type-field-style"]}`}
                          placeholder="0.00"
                          spinners={false}
                          format="n2"
                          component={NumericTextBox}
                        />
                      </div>
                      <div className="col ">
                        <Label className="label mt-1">
                          Estimate Fee
                        </Label>
                        <Field
                          className={`${styles["work-type-field-style"]}`}
                          name={"estimateFee"}
                          component={NumericTextBox}
                          placeholder="0.00"
                          spinners={false}
                          format="n2"
                        />
                      </div>
                    </div>

                    <div className="row mt-3">
                      <div className="col ">
                        <Label className="label mt-1  w-100">
                          Review Days&nbsp;
                        </Label>
                        <Field
                            className={`${styles["work-type-field-style"]}`}
                          name={"reviewDays"}
                          component={NumericTextBox}
                          spinners={false}
                          placeholder="Add Review Days"
                        />
                      </div>
                      <div className="col ">
                        <Label className="label">Disb Credit Limit</Label>
                        <Field
                          name="disbCreditLimit"
                          className={`${styles["work-type-field-style"]}`}

                          placeholder="0.00"
                          spinners={false}
                          format="n2"
                          component={NumericTextBox}

                        />
                      </div>

                      <div className="col ">
                        <Label className="label">
                          Default Profit Cost Nominal
                        </Label>
                        <Field
                          wf={true}
                          isStyled={true}
                          iconClassName="wa"
                          className={`${styles["work-type-field-style"]}`}

                          data={profitAndLoss}
                          textField="nominalName"
                          name={"defaultProfitCost"}
                          placeholder={"Default Profit Cost Nominal"}
                          component={ComboBox}
                        />
                      </div>
                    </div>

                    <div className="row mt-3">
                      <div className="col ">
                        <Label className="label ">Rate Category</Label>
                        <Field
                          wf={true}
                          isStyled={true}
                          className={`${styles["work-type-field-style"]}`}

                          placeholder={"Rate Category"}
                          iconClassName="wa"
                          name={"rateCategoryId"}
                          data={rateCategories}
                          textField={"rateCategoryName"}
                          component={ComboBox}
                        />
                      </div>
                      <div className="col ">
                        <Label className="label">Bill Credit Limit</Label>
                        <Field
                          name="billCreditLimit"
                          className={`${styles["work-type-field-style"]}`}
                          placeholder="0.00"
                          spinners={false}
                          format="n2"
                          component={NumericTextBox}
                        />
                      </div>

                      <div className="col ">
                        {/* <Label className="label">WIP Credit Limit</Label>
                        <Field
                          name="creditControlStage"
                          style={{
                            width: "240px",
                            height: "32px",
                          }}
                          placeholder="e.g ABC"
                          component={Input}
                        /> */}
                      </div>
                    </div>
            
                  </Tile>
                  {ADD_NEW ? null : (
                      <div className="mt-3 mx-2">
                        <Field
                          defaultChecked={true}
                          name={"active"}
                          component={Checkbox}
                          label={"Active"}
                        />
                      </div>
                    )}
                </div>
              </fieldset>

              <button
                style={{  visibility: "hidden"}}
                id="category-form"
                type={"submit"}
              ></button>
            </FormElement>
          )}
        />
      </div>

      <div className="dialog-footer">
        <button
          className="bgBtnSecondary common-btn me-2"
          onClick={props.cancelEdit}
        >
          Cancel
        </button>
        <button
          className="common-btn me-3"
          onClick={() => {
            document.getElementById("category-form").click();
          }}
          type={"submit"}
        >
          {ADD_NEW ? "Save" : "Update"}
        </button>
      </div>
    </Dialog>
  );
};

export default EditCategory;
