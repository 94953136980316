import * as React from "react";
import { ActiveCell, SignatureCell } from "../GridComponents";
import { TimeLoader } from "./TimeLoader";
import EditTime from "./EditTime";
import { editTimeType } from "actions/timeType.action";
import List from "components/Setting/List";

const TimeList = () => {
  return (
    <List
      editAction={editTimeType}
      loaderComponent={TimeLoader}
      editFormComponent={EditTime}
      columns={[
        { field: "symbol", title: " ", cell: SignatureCell, width: "80" },
        { field: "code", title: "Code" },
        { field: "name", title: "Time Type" },
        { cell: ActiveCell, title: "Active", field: "active", width: "80" },
      ]}
    />
  );
};

export default TimeList;
