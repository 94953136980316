import * as React from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Input, Checkbox } from "@progress/kendo-react-inputs";
import { Label } from "@progress/kendo-react-labels";

const EditCurrency = (props) => {
  const ADD_NEW = props.type === "addNew";
  return (
    <Dialog
      title={ADD_NEW ? "Add new Currency" : `Edit ${props?.item?.name}`}
      onClose={props.cancelEdit}
      width={600}
    >
      <div className="p-3 ps-4">
        <Form
          onSubmit={props.onSubmit}
          initialValues={!ADD_NEW && props.item}
          render={(formRenderProps) => (
            <FormElement>
              <fieldset className={"k-form-fieldset"}>
                {ADD_NEW && (
                  <div className="mb-2">
                    <Label className="label mt-1 w-100">
                      Currency Code&nbsp;
                    </Label>
                    <Field
                      className="mt-1"
                      name={"currencyCode"}
                      component={Input}
                    />
                  </div>
                )}
                <div className="mb-2">
                  <Label className="label mt-1 w-100">Name&nbsp;</Label>
                  <Field className="mt-1" name={"name"} component={Input} />
                </div>
                <div className="mb-3">
                  <Label className="label mt-1">Symbol&nbsp;</Label>
                  <Field className="mt-1" name={"symbol"} component={Input} />
                </div>
                <Field
                  defaultChecked={true}
                  name={"active"}
                  component={Checkbox}
                  label={"Active"}
                />
              </fieldset>
              <button
                style={{ visibility: "hidden" }}
                id="currency-form"
                type={"submit"}
              ></button>
            </FormElement>
          )}
        />
      </div>
      <div className="dialog-footer">
        <button
          className="bgBtnSecondary common-btn me-2"
          onClick={props.cancelEdit}
        >
          Cancel
        </button>
        <button
          className="common-btn me-3"
          onClick={() => document.getElementById("currency-form").click()}
          type={"submit"}
        >
          {ADD_NEW ? "Save" : "Update"}
        </button>
      </div>
    </Dialog>
  );
};

export default EditCurrency;
