import * as React from "react";
import Chart from "components/Dashboard/Chart";
import { useState } from "react";
import { useEffect } from "react";
import styles from "./DashboardPage.module.scss";
import { ChartFooter, ChartHeader } from "components/Dashboard";
import timeRecorded from "../../assets/DashboardIcons/timeRecorded.svg";
import {
  useDashboardData,
  graphUpdater,
} from "../../components/Dashboard/useDashboardData";
import { AmountFormat } from "components/common/formatter";
import { getDashboardTimeTargetData } from "../../actions/time.action";
import { useSelector } from "react-redux";

export const TimeTagetChart = (props) => {
  const [type, setType] = useState("Month");
  const [filters, setFilters] = useState({});
  const initialSeries = [
    { name: "Time Recorded", data: [] },
    { name: "Target", data: [] },
  ];
  const [series, setSeries] = useState([]);
  const [categories, setCategories] = useState([]);

  useDashboardData(filters, getDashboardTimeTargetData);
  const { timeTarget } = useSelector((state) => state.time);

  useEffect(() => {
    if (timeTarget?.data) {
      graphUpdater(timeTarget.data, initialSeries, setCategories, setSeries);
    }
  }, [timeTarget.data]);
  const typesUpdater = () => {
    let types = [];
    if (filters?.feeearnerRef === null) {
      types.push({
        label: "By Fee Earner",
        value: "FeeEarner",
      });
    }
    if (filters?.appCode === null) {
      types.push({
        label: "By App",
        value: "Application",
      });
    }
    if (filters?.branch === null) {
      types.push({
        label: "By Branch",
        value: "Branch",
      });
    }
    if (filters?.month === null) {
      types.push({
        label: "By Month",
        value: "Month",
      });
    }
    setTypesData(types);
    let check = false;
    types.map((item) => {
      if (item.value === type) {
        check = true;
      }
    });
    if (!check) {
      setType("Month");
    }
  };
  const [typesData, setTypesData] = useState([]);
  useEffect(() => {
    setFilters(props.filters);
  }, [props.filters]);
  useEffect(() => {
    typesUpdater();
  }, [filters, type]);

  return (
    <>
      <div className="h-100 p-2">
        <ChartHeader
          name={"Time Recorded"}
          type={"(Actual vs Targets)"}
          param1={"Time Recorded"}
          param2={"Target"}
          icon={timeRecorded}
          color={"#F9F4FF"}
          duration={filters?.duration}
        />
        <div className="h-100 p-2">
          <Chart
            series={series}
            onSeriesClick={() => {}}
            categories={categories}
          ></Chart>
        </div>
        <ChartFooter param1={"Time Recorded"} param2={"Target"} data={series} />
      </div>
    </>
  );
};

export const TimeRecordedStatsCard = ({ filters }) => {
  const { timeTarget } = useSelector((state) => state.time);
  const totalActual =
    timeTarget?.data?.reduce((sum, item) => sum + item.actual, 0) || 0;

  return (
    <div className="d-flex flex-column align-items-start mt-4 py-3 px-4">
      <div
        className={`${styles.iconContainer}`}
        style={{
          backgroundColor: "#F9F4FF",
        }}
      >
        <img src={timeRecorded} alt="time Icon" />
      </div>
      <p className="fs-md fw-semibold mt-1 mb-1" style={{ color: "#9747FF" }}>
        Time Recorded
      </p>
      <p className="fs-xl fw-bold">&#163; {AmountFormat(totalActual)}</p>
    </div>
  );
};
