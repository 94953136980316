export const enum SettingMenuItemId {
  Company = 2,
  Branches = 4,
  Integrations = 18,

  Profile = 1,
  Users = 3,
  SecurityProfiles = 17,
  Teams = 16,

  Applications = 11,
  Departments = 14,
  Worktypes = 13,
  Sources = 19,
  Referrers = 20,

  Activitytypes = 15,
  Timetypes = 25,
  Contactcategories = 12,

  Defaultnominals = 5,
  Vat = 6,
  Currencies = 9,

  FixedFeeBilling = 10,
  MatterSettings = 21,
  DocumentRegisterTypes = 22,
  RiskAssessmentSettings = 23,
  UserRates = 24,
}

export default SettingMenuItemId;
