import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { clearNominalFields } from "actions/postings.action";
export const DropDownCell = (props) => {
  const EDIT_FIELD = "inEdit";
  const localizedData = useSelector((state) =>
    state.nominalList.nominalJournal.data.map((item) => {
      item.representation = item.nominalRef + " - " + item.nominalName;
      return item;
    })
  );
  const clearFields = useSelector((state) => state.postingsdocs.clearFields);
  const dispatch = useDispatch();
  const handleChange = (e) => {
    if (!clearFields) {
      dispatch(clearNominalFields(true));
    }
    if (props.onChange) {
      props.onChange({
        dataIndex: 0,
        dataItem: props.dataItem,
        field: props.field,
        syntheticEvent: e.syntheticEvent,
        value: e.value,
      });
    }
  };

  const { dataItem } = props;
  const field = props.field || "";
  const dataValue = dataItem[field] === null ? "" : dataItem[field];
  return (
    <td>
      {EDIT_FIELD ? (
        <DropDownList
          style={{
            width: "180px",
            height: "35px",
          }}
          onChange={handleChange}
          value={
            clearFields
              ? localizedData.find((c) => c.value === dataValue)
              : null
          }
          data={localizedData}
          textField="representation"
          iconClassName="wa"
          placeholder="Select Nominal Ref"
        />
      ) : (
        dataValue.nominalRef.toString()
      )}
    </td>
  );
};
