import excel from "../../assets/fileIcons/xlsx.png";
import wordIcon from "../../assets/fileIcons/docx.png";
import pptIcon from "../../assets/fileIcons/pptx.png";
import pdfIcon from "../../assets/fileIcons/pdf.png";
import emailIcon from "../../assets/fileIcons/msg.png";
import photoIcon from "../../assets/fileIcons/photo.png";
import txtIcon from "../../assets/fileIcons/txt.png";
import otherFormat from "../../assets/fileIcons/genericfile.png";

const powerPointExt = ["ppt", "pptx"];
const wordtExt = ["doc", "docx"];
const excelExt = ["xls", "xlsx"];
const pdfExt = ["pdf"];
const imagesExt = ["jpg", "png", "jpeg"];
const txtExt = ["txt"];
const emailExt = ["msg", "eml"];

export const fileIcon = (fileName) => {
  const ext = fileName?.split(".")?.pop()?.toLowerCase();
  return (
    <img
      style={{ width: "25px", height: "25px" }}
      className="cursor-pointer"
      alt="File Icon"
      src={
        pdfExt.includes(ext)
          ? pdfIcon
          : excelExt.includes(ext)
          ? excel
          : wordtExt.includes(ext)
          ? wordIcon
          : powerPointExt.includes(ext)
          ? pptIcon
          : imagesExt.includes(ext)
          ? photoIcon
          : txtExt.includes(ext)
          ? txtIcon
          : emailExt.includes(ext)
          ? emailIcon
          : otherFormat
      }
    />
  );
};

export default fileIcon;
