import * as React from "react";
import { Button } from "@progress/kendo-react-buttons";
import Vatlist from "./vatlist";
import styles from "../Settings.module.scss";
import EditVat from "./Editvat";
import * as api from "../../../api/index";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { getVatcodes } from "actions/vat.action";

const Vatcodes = (props) => {
  const [addnewform, setaddnewform] = React.useState(false);
  const dispatch = useDispatch();

  const closeform = () => {
    setaddnewform(false);
  };

  const handleSubmit = async (e) => {
    setaddnewform(false);
    try {
      await api.addnewVat({
        ...e,
        includeInVatReturn: true,
      });
      toast.success("VAT Added");
      dispatch(getVatcodes());
    } catch (error) {
      toast.error("Error Occured");
    }
  };

  return (
    <React.Fragment>
      <div className="d-flex flex-column">
        <div className="d-flex ms-auto mt-5 me-5">
          <Button
            className={`common-btn ${styles[`button`]} me-5`}
            onClick={() => {
              setaddnewform(true);
              props.open();
            }}
          >
            Add VAT
          </Button>
        </div>
        {props.component === "Wizard" && (
          <div className="buttonstyle">
            <Button
              onClick={() => {
                props.moveNext();
              }}
              className="common-btn"
            >
              Next
            </Button>
          </div>
        )}
        <div className={`mx-auto mt-5 w-50`}>
          <Vatlist />
        </div>
      </div>
      {addnewform && (
        <EditVat
          cancelEdit={closeform}
          type={"addNew"}
          onSubmit={handleSubmit}
        />
      )}
    </React.Fragment>
  );
};

export default Vatcodes;
