import React from "react";
import { Field } from "@progress/kendo-react-form";
import CustomInput from "components/common/Input";
import { FormComboBox, FormDate } from "components/Matters";
import { ethnicity, genders, disability } from "./constants";
import { ContainerCard } from "components/MatterDetails/ContactCard";
import styles from './Clients.module.scss';

const PersonalInformation = ({
  readOnly,
  comboBoxState,
  setComboBoxState,
  dateState,
  handleDateChange,
}) => {
  return (
    <div className="col-12">
      <ContainerCard
        height="600px"
        className="p-3 ps-4 pe-4 pt-2 pb-0 overflow-auto"
      >
        <div className="px-2">
          <div
            className="d-flex align-items-center justify-content-start"
            style={{ height: "50px" }}
          >
            <div className={`${styles["section-title"]}`}>Personal Details</div>
          </div>
          <hr className="mt-0" />
          <div
            className="container"
            style={{
              overflow: "auto",
              position: "relative",
              height: "calc(100vh - 400px)",
            }}
          >
            <div className="col-3">
              <FormComboBox
                name="gender"
                label="Gender"
                placeholder="Select Gender"
                wf={true}
                width={"260px"}
                mr={"5px"}
                data={genders}
                isStyled={true}
                state={comboBoxState}
                onChangeComboBox={(e) => {
                  setComboBoxState({
                    ...comboBoxState,
                    gender: e?.value,
                  });
                }}
              />
            </div>
            <div className="col-3 mt-3 mb-3 form-date-picker">
              <FormDate
                className={"form-date-picker note-date-picker "}
                wf={true}
                isStyled={true}
                width={"260px"}
                customLabel={"Date Of Birth"}
                label={"dateOfBirth"}
                onChangeDate={handleDateChange}
                state={dateState}
              />
            </div>
            <div className="col-3 mt-3">
              <Field
                name="niNumber"
                label="NI Number"
                mr={"5px"}
                ph="Enter National Insurance Number"
                width={"260px"}
                component={CustomInput}
              />
            </div>
            <div className="col-3 mt-3">
              <Field
                name="nameAtBirth"
                label="Name At Birth"
                mr={"5px"}
                ph="Enter Name at Birth"
                width="260px"
                component={CustomInput}
              />
            </div>{" "}
            <div className="col-3 mt-3">
              <Field
                name="placeOfBirth"
                label="Place Of Birth"
                mr={"5px"}
                ph="Enter Place Of Birth"
                width="260px"
                component={CustomInput}
              />
            </div>{" "}
            <div className="col-3 mt-3">
              <FormComboBox
                name="ethnicity"
                label="Ethnicity"
                placeholder="Select Ethnicity"
                wf={true}
                width={"260px"}
                mr={"5px"}
                data={ethnicity}
                isStyled={true}
                state={comboBoxState}
                onChangeComboBox={(e) => {
                  setComboBoxState({
                    ...comboBoxState,
                    ethnicity: e?.value,
                  });
                }}
              />
            </div>
            <div className="col-3 mt-3">
              <FormComboBox
                name="disability"
                label="Disability"
                placeholder="Select Disability"
                wf={true}
                width={"260px"}
                mr={"5px"}
                data={disability}
                isStyled={true}
                state={comboBoxState}
                onChangeComboBox={(e) => {
                  setComboBoxState({
                    ...comboBoxState,
                    disability: e?.value,
                  });
                }}
              />
            </div>{" "}
            <div className="col-3 mt-3 form-date-picker">
              <FormDate
                className={"form-date-picker note-date-picker"}
                wf={true}
                isStyled={true}
                width={"260px"}
                customLabel={"Date Of Death"}
                label={"dateOfDeath"}
                onChangeDate={handleDateChange}
                state={dateState}
              />
            </div>
          </div>
        </div>
      </ContainerCard>
    </div>
  );
};

export default PersonalInformation;
