import React from "react";

function Icon({ icon, className }) {
    const iconClasses = `icon icon--${icon} ${className}`;
    const iconClassesWithBackground = `icon icon--background icon--${icon} ${className}`;

    switch (icon) {
        case "Rectangle":
            return (
                <svg
                    width="15"
                    height="26"
                    viewBox="0 0 15 26"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <rect width="15" height="26" rx="5" fill="#F6DB8A" />
                </svg>
            );

        case "ellipse":
            return (
                <svg
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <circle cx="20" cy="20" r="20" fill="#ECECEC" />
                </svg>
            );
        case "filledEllipse":
            return (
                <svg
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <circle cx="20" cy="20" r="20" fill="#FFC000" />
                </svg>
            );

        case "download":
            return (
                <svg
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <rect
                        x="-2.88184"
                        y="-4.11792"
                        width="24.7059"
                        height="27.0588"
                        fill="#323232"
                    />
                </svg>
            );

        case "bell":
            return (
                <svg
                    width="34"
                    height="35"
                    viewBox="0 0 34 35"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M-3.92358 -1.80584H37.9226V38.7326H-3.92358V-1.80584Z"
                        fill="#1F1801"
                    />
                </svg>
            );

        case "label":
            return (
                <svg
                    width="15"
                    height="26"
                    viewBox="0 0 15 26"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <rect width="15" height="26" rx="5" fill="#F6DB8A" />
                </svg>
            );

        case "arrow-down":
            return (
                <svg
                    width="15"
                    height="8"
                    viewBox="0 0 15 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        opacity="0.5"
                        d="M14 1L7.5 7L1 0.999999"
                        stroke="#2D2D2D"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                </svg>
            );

        case "arrow-right":
            return (
                <svg width="6" height="11" viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path opacity="0.5" d="M0.5 1L5 5.5L0.5 10" stroke="black" strokeLinecap="round" />
                </svg>
            );
        case 'arrow-left':
            return <svg width="9" height="15" viewBox="0 0 9 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 13.5L2 7.5L8 1.5" stroke="black" strokeWidth="1.5" strokeLinecap="round" />
            </svg>

        case "search":
            return (
                <svg
                    className="svg-icon search-icon"
                    aria-labelledby="title desc"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 19.9 19.7"
                >
                    <title id="title">Search Icon</title>
                    <desc id="desc">A magnifying glass icon.</desc>
                    <g className="search-path" fill="none" stroke="#848F91">
                        <path strokeLinecap="square" d="M18.5 18.3l-5.4-5.4" />
                        <circle cx="8" cy="8" r="7" />
                    </g>
                </svg>
            );

        case "client":
            return (
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="-3" y="-2" width="21" height="19" fill="#FFC000" />
                </svg>
            )
        case "add":
            return (
                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" className="bi bi-plus" viewBox="0 0 16 16"> <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" /> </svg>
            )
        default:
            break;
    }
}

export default Icon;
