import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { AmountFormat } from "components/common/formatter";

const NominalBalances = (props) => {
  const [yearToDate, setYearToDate] = React.useState(undefined);
  const [selectedPeriod, setSelectedPeriod] = React.useState(undefined);
  const [total, setTotal] = React.useState(undefined);
  const { nominalRefBalances } = useSelector((state) => state.nominalList);

  useEffect(() => {
    if (nominalRefBalances && props.type === "NominalLedger") {
      let yearToDate = AmountFormat(nominalRefBalances.data.ytdBalance);
      setYearToDate(yearToDate);
      let selectedPeriod = AmountFormat(nominalRefBalances.data.periodBalance);
      setSelectedPeriod(selectedPeriod);
      let total = AmountFormat(nominalRefBalances.data.balance);
      setTotal(total);
    }
  }, [nominalRefBalances]);

  return (
    <div
      className="grid-layout-container"
      style={{ marginBottom: 25, width: "400px" }}
    >
      <table className="balance-table pb-2">
        <thead className="width-100">
          <tr>
            <th className="pb-2" colSpan={2}>
              Balances
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="bt-text">Total</td>
            <td className="bt-value text-end">
              {props.nominal !== null && total}
            </td>
          </tr>
          <tr>
            <td className="bt-text">Year to date</td>
            <td c className="bt-value text-end">
              {props.nominal !== null && yearToDate}
            </td>
          </tr>
          <tr>
            <td className="bt-text">Selected period</td>
            <td className="bt-value text-end">
              {props.nominal !== null && selectedPeriod}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
export default NominalBalances;
