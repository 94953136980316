import * as React from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Input, Checkbox, TextArea } from "@progress/kendo-react-inputs";
import { formatDate } from "@progress/kendo-intl";
import { Label } from "@progress/kendo-react-labels";
import { DateInput, DatePicker } from "@progress/kendo-react-dateinputs";
import { Tile } from "components/UIComponents/Tile";
import styles from "./index.module.scss";
import CalenderCustomToggleButton from "components/common/CalenderCustomToogleButton";
import { useState } from "react";
import DataList from "components/MatterDetails/DataList";
import { useSelector } from "react-redux";
import { addDirector, addOwners } from "api";
import { toast } from "react-toastify";
import { FormInput } from "components/Matters";
import { CustomDateInput } from "components/common/CustomDateInput";

const AddOwners = (props) => {
  const ownersData = props.data;
  const [date, setDate] = useState({
    owner1: ownersData?.owner1?.dateOfBirth
      ? new Date(ownersData?.owner1?.dateOfBirth)
      : null,
    owner2: ownersData?.owner2?.dateOfBirth
      ? new Date(ownersData?.owner2?.dateOfBirth)
      : null,
    owner3: ownersData?.owner3?.dateOfBirth
      ? new Date(ownersData?.owner3?.dateOfBirth)
      : null,
    owner4: ownersData?.owner4?.dateOfBirth
      ? new Date(ownersData?.owner4?.dateOfBirth)
      : null,
  });
  const [notes, setNotes] = useState(props.item?.details || "");
  const [inputState, setInputState] = useState({
    percentage_1: ownersData?.owner1?.heldPercentage || 0,
    percentage_2: ownersData?.owner1?.heldPercentage || 0,
    percentage_3: ownersData?.owner1?.heldPercentage || 0,
    percentage_4: ownersData?.owner1?.heldPercentage || 0,
    name_1: ownersData?.owner1?.firstName,
    name_2: ownersData?.owner2?.firstName,
    name_3: ownersData?.owner3?.firstName,
    name_4: ownersData?.owner4?.firstName,
  });
  const [isEdited, setIsEdited] = useState(false);
  const [active, setActive] = useState(props.item?.active || false);
  let locale = useSelector((state) => state.company.company.locale);
  const handleInputChange = (value, label) => {
    if (label.includes("percentage")) {
      const remainingPercentages = Object.keys(inputState).filter(
        (name) => name.includes('percentage') && name !== label
      );
      let sum = parseInt(value);
      for (let num = 0; num < remainingPercentages.length; num++) {
        sum = sum + parseInt(inputState[remainingPercentages[num]]);
      }
      if (sum > 100) return;
    }
    setInputState({
      ...inputState,
      [label]: value,
    });
  };

  const handleSubmit = async () => {
    try {
      const data = {
        owner1: {
          firstName: inputState["name_1"],
          dateOfBirth: date["owner1"],
          heldPercentage: inputState["percentage_1"],
        },
        owner2: {
          firstName: inputState["name_2"],
          dateOfBirth: date["owner2"],
          heldPercentage: inputState["percentage_2"],
        },
        owner3: {
          firstName: inputState["name_3"],
          dateOfBirth: date["owner3"],
          heldPercentage: inputState["percentage_3"],
        },
        owner4: {
          firstName: inputState["name_4"],
          dateOfBirth: date["owner4"],
          heldPercentage: inputState["percentage_4"],
        },
      };
      await addOwners({ data: data }, props.clientId);
      toast.success("Director Added");
      props.cancelEdit();
      props.onUpdateData();
    } catch (error) {
      toast.error("Unexpected Error Occured");
    }
  };

  return (
    <Dialog
      title={"Edit Beneficial Owner"}
      onClose={props.cancelEdit}
      width={1050}
      className="p-3"
    >
      <div className="p-4">
        <Tile
          heading="Proof Details"
          className="mb-2 ps-4 pe-4 overflow-auto"
          height={500}
        >
          <div className="conatiner-fluid">
            <div className="row mt-2 mb-4">
              <div className="col-3 me-5">
                <FormInput
                  className={"form-date-picker note-date-picker "}
                  wf={true}
                  isStyled={true}
                  width={"260px"}
                  labelForComboBox={"Name"}
                  differentName={true}
                  name={"name_1"}
                  onChangeInput={handleInputChange}
                  state={inputState}
                />
              </div>
              <div className="col-3 me-5 form-date-picker note-date-picker ">
                <Label className="label  mb-1 w-100">Date&nbsp;</Label>
                <DatePicker
                  toggleButton={CalenderCustomToggleButton}
                  dateInput={CustomDateInput}
                  onChange={(e) => {
                    setDate({
                      ...date,
                      owner1: e.value,
                    });
                  }}
                  value={date["owner1"]}
                  className="px-3"
                  style={{
                    height: "32px",
                    width: "260px",
                  }}
                />
              </div>
              <div className="col-3 me-2">
                <FormInput
                  className={"form-date-picker note-date-picker"}
                  wf={true}
                  isStyled={true}
                  width={"260px"}
                  type="number"
                  labelForComboBox={"Percentage Held"}
                  name={"percentage_1"}
                  differentName={true}
                  onChangeInput={handleInputChange}
                  state={inputState}
                />
              </div>
            </div>
            <hr />
            <div className="row mt-4 mb-4">
              <div className="col-3 me-5">
                <FormInput
                  className={"form-date-picker note-date-picker "}
                  wf={true}
                  isStyled={true}
                  width={"260px"}
                  labelForComboBox={"Name"}
                  differentName={true}
                  name={"name_2"}
                  onChangeInput={handleInputChange}
                  state={inputState}
                />
              </div>
              <div className="col-3 me-5 form-date-picker note-date-picker ">
                <Label className="label  mb-1 w-100">Date&nbsp;</Label>
                <DatePicker
                  toggleButton={CalenderCustomToggleButton}
                  dateInput={CustomDateInput}
                  onChange={(e) => {
                    setDate({
                      ...date,
                      owner2: e.value,
                    });
                  }}
                  value={date["owner2"]}
                  className="px-3"
                  style={{
                    height: "32px",
                    width: "260px",
                  }}
                />
              </div>
              <div className="col-3 me-2">
                <FormInput
                  className={"form-date-picker note-date-picker"}
                  wf={true}
                  isStyled={true}
                  width={"260px"}
                  type="number"
                  labelForComboBox={"Percentage Held"}
                  name={"percentage_2"}
                  differentName={true}
                  onChangeInput={handleInputChange}
                  state={inputState}
                />
              </div>
            </div>
            <hr />
            <div className="row mt-4 mb-4">
              <div className="col-3 me-5">
                <FormInput
                  className={"form-date-picker note-date-picker "}
                  wf={true}
                  isStyled={true}
                  width={"260px"}
                  labelForComboBox={"Name"}
                  differentName={true}
                  name={"name_3"}
                  onChangeInput={handleInputChange}
                  state={inputState}
                />
              </div>
              <div className="col-3 me-5 form-date-picker note-date-picker ">
                <Label className="label  mb-1 w-100">Date&nbsp;</Label>
                <DatePicker
                  toggleButton={CalenderCustomToggleButton}
                  dateInput={CustomDateInput}
                  onChange={(e) => {
                    setDate({
                      ...date,
                      owner3: e.value,
                    });
                  }}
                  value={date["owner3"]}
                  className="px-3"
                  style={{
                    height: "32px",
                    width: "260px",
                  }}
                />
              </div>
              <div className="col-3 me-2">
                <FormInput
                  className={"form-date-picker note-date-picker"}
                  wf={true}
                  isStyled={true}
                  width={"260px"}
                  type="number"
                  labelForComboBox={"Percentage Held"}
                  name={"percentage_3"}
                  differentName={true}
                  onChangeInput={handleInputChange}
                  state={inputState}
                />
              </div>
            </div>
            <hr />
            <div className="row mt-4 mb-2">
              <div className="col-3 me-5">
                <FormInput
                  className={"form-date-picker note-date-picker "}
                  wf={true}
                  isStyled={true}
                  width={"260px"}
                  labelForComboBox={"Name"}
                  differentName={true}
                  name={"name_4"}
                  onChangeInput={handleInputChange}
                  state={inputState}
                />
              </div>
              <div className="col-3 me-5 form-date-picker note-date-picker ">
                <Label className="label  mb-1 w-100">Date&nbsp;</Label>
                <DatePicker
                  toggleButton={CalenderCustomToggleButton}
                  dateInput={CustomDateInput}
                  onChange={(e) => {
                    setDate({
                      ...date,
                      owner4: e.value,
                    });
                  }}
                  value={date["owner4"]}
                  className="px-3"
                  style={{
                    height: "32px",
                    width: "260px",
                  }}
                />
              </div>
              <div className="col-3 me-2">
                <FormInput
                  className={"form-date-picker note-date-picker"}
                  wf={true}
                  isStyled={true}
                  width={"260px"}
                  type="number"
                  labelForComboBox={"Percentage Held"}
                  name={"percentage_4"}
                  differentName={true}
                  onChangeInput={handleInputChange}
                  state={inputState}
                />
              </div>
            </div>
          </div>
        </Tile>
      </div>
      {/* <div className={`${styles["director-tile"]} p-2 m-4 ms-5 me-5`}>
        <div className="p-3 ps-4">
          <div className="form-date-picker note-date-picker mt-2">
            <FormInput
              className={"form-date-picker note-date-picker "}
              wf={true}
              isStyled={true}
              width={"260px"}
              label={"Name"}
              onChangeInput={handleInputChange}
              state={inputState}
            />
          </div>

          <div className="mt-3">
            <FormInput
              className={"form-date-picker note-date-picker "}
              wf={true}
              isStyled={true}
              width={"260px"}
              label={"Last_Name"}
              onChangeInput={handleInputChange}
              state={inputState}
            />
          </div>

          <div className="form-date-picker note-date-picker mt-3">
            <Label className="label mt-1 mb-1 w-100">Date&nbsp;</Label>
            <DatePicker
              toggleButton={CalenderCustomToggleButton}
              onChange={(e) => {
                setDate(e.value);
              }}
              value={date}
              className="px-3"
              style={{
                height: "32px",
                width: "260px",
              }}
            />
          </div>
        </div>
      </div> */}
      <div className="dialog-footer">
        <button
          className="bgBtnSecondary common-btn me-2"
          onClick={() => {
            if (isEdited) {
              setIsEdited(false);
            } else {
              props.cancelEdit();
            }
          }}
        >
          Cancel
        </button>
        <button
          className="common-btn me-3"
          onClick={() => {
            handleSubmit();
          }}
        >
          {"Save"}
        </button>
      </div>
    </Dialog>
  );
};

export default AddOwners;
