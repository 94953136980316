import {
    START_LOADING,
    STOP_LOADING,
    ERROR,
    SUCCESS,
    GET_TRANSACTIONPERIODS,
    GET_CURRENTTRANSACTIONPERIODS,
    UPDATE_TRANSACTION,
} from "../types/index";
import * as api from "../api/index.js";
import { handleApiError } from "Helper";
import { toast } from "react-toastify";

export const getTransactionperiods = (page, limit) => async (dispatch) => {
    try {
        dispatch({ type: START_LOADING });
        const datas = await api.getTransactionPeriod(page, limit);
        dispatch({ type: GET_TRANSACTIONPERIODS, payload: datas?.data?.data });
        dispatch({ type: STOP_LOADING });
        dispatch({ type: SUCCESS, payload: datas.message });
    } catch (error) {
        handleApiError(error);
    }
};

export const getCurrentTransactionperiods = () => async (dispatch) => {

    try {
        dispatch({ type: START_LOADING });
        const datas = await api.getCurrentTransactionPeriod();

        dispatch({ type: GET_CURRENTTRANSACTIONPERIODS, payload: datas.data.data });
        dispatch({ type: STOP_LOADING });
        dispatch({ type: SUCCESS, payload: datas.message });
    } catch (error) {
        handleApiError(error);
    }
};

export const updateTransactionperiods = (data) => async (dispatch) => {
    try {
        dispatch({ type: START_LOADING });
        await api.updateTransactionPeriod(data);
        toast.success("Transaction Period Updated");
        const datas = await api.getTransactionPeriod(1, 100);
        dispatch({ type: GET_TRANSACTIONPERIODS, payload: datas?.data?.data });
        dispatch({ type: UPDATE_TRANSACTION, payload: datas.data });
        dispatch({ type: STOP_LOADING });
        dispatch({ type: SUCCESS, payload: datas.message });
    } catch (error) {
        toast.success("Error occured while updating Transaction Period");
        handleApiError(error);
    }
};
