import axios from "axios";
import { useOidcAccessToken } from "@axa-fr/react-oidc";
import { routeErrorHandler } from "Helper/routerErrorHandler";

export let API = null;
export let baseURL = localStorage.getItem("url");
export let TOKEN = ``;

export const TokenHandler = () => {
  const { accessToken } = useOidcAccessToken();
  TOKEN = "Bearer " + accessToken;
  return;
};

export const fetchAndSetBaseURL = async () => {
  if (!baseURL) {
    // Fetch the base URL only if it's not already set
    const response = await axios.get(
      "https://auth.linq.legal/connect/userinfo",
      {
        headers: {
          Authorization: TOKEN,
        },
      }
    );
    baseURL = response.data.APIURL;
    localStorage.setItem("url", baseURL);
  }

  // Only create the Axios instance if it doesn't already exist
  if (!API) {
    API = axios.create({ baseURL });

    // Set up request interceptor for adding the Authorization header
    API.interceptors.request.use((req) => {
      // const { accessToken } = useOidcAccessToken();
      if (TOKEN) {
        req.headers.Authorization = TOKEN;
      }
      return req;
    });

    API?.interceptors?.response?.use(
      (response) => {
        // Success handler: directly return the response data
        return response;
      },
      (error) => {
        const requestURL = error.config.url;
        const customError = routeErrorHandler(requestURL, error);
        return Promise.reject(customError);
      }
    );
  }

  return API;
};

export const getAPIInstance = async () => {
  if (!API) {
    await fetchAndSetBaseURL();
  }
  return API;
};
