import * as React from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import { useSelector } from "react-redux";
import { filterBy } from "@progress/kendo-data-query";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { NumericCell } from "../DibursementPayment/DisbursementInputField";
import { v4 as uuidV4 } from "uuid";
import { Form, FormElement } from "@progress/kendo-react-form";
import * as api from "../../api/index";
import { AmountFormatCell } from "components/common/formatter";
import { Button } from "@progress/kendo-react-buttons";
import { useState, useEffect } from "react";
import SearchComboBox from "components/common/SearchComboBox";
import CONSTANT from "CONSTANT/Matter/ComboxType";
import { Label } from "@progress/kendo-react-labels";

const BillProfitCostGrid = (props) => {
  const initialData = [
    {
      timeId: uuidV4(),
      type: "ProfitCost",
      date: new Date(),
      details: "",
      value: 0,
      billVat: 0,
      gross: 0,
      vatCode: 0,
    },
  ];

  const allInEdit = initialData.map((item) =>
    Object.assign(
      {
        inEdit: true,
      },
      item
    )
  );
  const [data, setData] = React.useState(allInEdit);
  const [dataAvailable, setDataAvailable] = useState(false);
  const [error, setError] = useState(undefined);
  const [error1, setError1] = useState(undefined);

  const [vatvalue, setvatvalue] = useState();
  const [vatsdata, setvatsData] = React.useState();
  const [nominal, setnominal] = useState();
  const [nominaldata, setnominaldata] = React.useState();
  const [feeearner, setfeeearnerData] = React.useState();
  const [feeearnerref, setfeeearnerref] = useState();
  const AmountNameHeader = (props) => {
    return <div style={{ textAlign: "center" }}>{props.title}</div>;
  };

  const calculateVatGross = () => {
    data.map((item) => {
      if (item.value !== undefined || item.value !== null) {
        item.billVat = (vatvalue?.rate / 100) * item.value;
        item.gross = item.value + item.billVat;
      }
    });
  };
  const addNew = () => {
    const uniqueTimeId = uuidV4();
    const newDataItem = {
      inEdit: true,
      timeId: uniqueTimeId,
      type: "ProfitCost",
      date: new Date(),
      details: "",
      value: 0,
      billVat: 0,
      gross: 0,
      vatCode: 0,
    };
    setData([...data, newDataItem]);
  };

  const onChangenominal = (event) => {
    setnominal(event.value);
  };
  const onChangevat = (event) => {
    setvatvalue(event.value);
    if (event.value !== null || event.value !== undefined) {
      data.map((item) => {
        if (item.value !== undefined || item.value !== null) {
          item.billVat = (event.value?.rate / 100) * item.value;
          item.gross = item.value - item.billVat;
        }
      });
    }
  };
  const filterData = (filter) => {
    const datas = activevatsdata.slice();
    return filterBy(datas, filter);
  };

  const filterChange = (event) => {
    setvatsData(filterData(event.filter));
  };
  const filternominalData = (filter) => {
    const data = nominallist.slice();
    return filterBy(data, filter);
  };
  const filternominal = (event) => {
    setnominaldata(filternominalData(event.filter));
  };
  const activevatsdata = useSelector((state) => {
    let list = state.vatcodes.activevats.data.map((item) => {
      const templist = item;
      templist.representation =
        item.vatCodeId + "  -  " + item.details + "  -  " + item.rate + "%";

      return templist;
    });
    return list;
  });
  const nominallist = useSelector((state) => {
    let array = [];
    let list = state.nominalList.activenominals.data.map((item) => {
      if (item.subType === "ProfitAndLoss_Income") {
        const templist = item;
        templist.representation = item.nominalRef + "  -  " + item.nominalName;
        array.push(item);
        return templist;
      }
    });
    return array;
  });
  const feeearnerdata = useSelector((state) => {
    return state.postingsdocs.feeearner.data;
  });

  const handleSubmit = (values) => {
    if (!vatvalue || !nominal.nominalRef || !values.feeEarner) {
      setError(true);
      return;
    }
    data.map((item) => {
      item.feeEarnerRef = values.feeEarner?.userRef;
      item.nominalRef = nominal?.nominalRef;
      item.vatCode = vatvalue?.vatCodeId;
    });
    if (data === 0) {
      setError1(true);
      return;
    }

    props.changeMatterList(data);
    const uniqueTimeId = uuidV4();
    setData([{ inEdit: true, timeId: uniqueTimeId }]);
    props.cancelEdit();
  };
  const itemChange = (e) => {
    let newData = data.map((item) => {
      if (item.timeId === e.dataItem.timeId) {
        item[e.field || ""] = e.value;
      }
      return item;
    });
    setData(newData);
    if (e.field === "value") {
      calculateVatGross();
    }
  };

  const addDefaultValues = () => {
    setvatvalue(
      activevatsdata.find(
        (item) => item.vatCodeId == props.defaultValues?.defaultVatCodeId
      )
    );
  };

  useEffect(() => {
    setTimeout(() => {
      setvatsData(activevatsdata.slice());
      setfeeearnerData(feeearnerdata.slice());
      addDefaultValues();
    }, 100);
  }, []);

  const getNominals = async () => {
    const { data } = await api.getactivenominals();
    let array = [];

    let list = data.data.map((item) => {
      if (item.subType === "ProfitAndLoss_Income") {
        const templist = item;
        templist.representation = item.nominalRef + "  -  " + item.nominalName;
        array.push(item);
        return templist;
      }
    });
    return array;
  };

  const fetchNominal = async () => {
    const data = await getNominals();
    setnominaldata(data.slice());
    setnominal(
      data.find(
        (item) => item.nominalRef == props.defaultValues?.defaultProfitNominal
      )
    );
  };

  useEffect(() => {
    fetchNominal();
  }, []);

  console.log(data)
  return (
    <Dialog title={`Profit Cost`} onClose={props.cancelEdit} width={"1100px"}>
      <>
        <div
          className="d-flex flex-column pt-5 pb-3"
          style={{ padding: "40px" }}
        >
          <div
            className="d-flex flex-wrap align-items-center"
            style={{ gap: "5%" }}
          >
            <Form
              ignoreModified={true}
              onSubmit={handleSubmit}
              // initialValues={initialData}
              // key={JSON.stringify(initialData)}
              render={(formRenderProps) => {
                return (
                  <div>
                    <FormElement id="timeRecord" name="timeRecord">
                      <fieldset>
                        {formRenderProps.visited &&
                          formRenderProps.errors &&
                          formRenderProps.errors.VALIDATION_SUMMARY && (
                            <div className={"k-messagebox k-messagebox-error"}>
                              {formRenderProps.errors.VALIDATION_SUMMARY}
                            </div>
                          )}

                        <div className="d-flex">
                          <div
                            className="d-flex flex-column mb-3 me-4"
                            style={{ width: "300px" }}
                          >
                            <Label>Vat Code</Label>
                            <div className="validator">
                              <ComboBox
                                style={{ width: "300px" }}
                                data={vatsdata}
                                textField={"representation"}
                                filterable={true}
                                iconClassName="wa"
                                onFilterChange={filterChange}
                                value={vatvalue}
                                onChange={onChangevat}
                                placeholder={"Search VAT Code"}
                              />
                              {error === true &&
                                (vatvalue === null ||
                                  vatvalue === undefined) && (
                                  <i>Please Select Vat Code</i>
                                )}
                            </div>
                          </div>

                          <div
                            className="d-flex flex-column mb-3 me-4"
                            style={{ width: "300px" }}
                          >
                            <Label>Nominal Ref</Label>
                            <div className="validator">
                              <ComboBox
                                style={{ width: "300px" }}
                                data={nominaldata}
                                textField={`representation`}
                                filterable={true}
                                onFilterChange={filternominal}
                                placeholder={"Search Nominal Ref"}
                                value={nominal}
                                iconClassName="wa"
                                required={true}
                                onChange={onChangenominal}
                              />
                              {error === true &&
                              (nominal?.nominalRef === "" ||
                                nominal === null) ? (
                                <i>Please Select Nominal Ref</i>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>

                          <div className="mb-3">
                            <SearchComboBox
                              width="300px"
                              required={true}
                              cbType={CONSTANT.FE}
                              placeholder={"Search Fee Earner Ref"}
                              name={"feeEarner"}
                              defaultValues={props.defaultValues}
                              defaultValueSetter={formRenderProps}
                            />
                          </div>
                        </div>
                      </fieldset>
                    </FormElement>
                  </div>
                );
              }}
            />
          </div>

          <div style={{ marginTop: "15px" }}>
            <Grid
              data={data}
              style={{ height: "350px", overflow: "auto" }}
              editField="inEdit"
              onItemChange={itemChange}
              dataItemKey={"timeId"}
            >
              <Column
                field="date"
                editor="date"
                title="Date"
                format="{0:d}"
                width={"200px"}
                className="p-2"
              />
              <Column field="details" title="Details" width={"200px"} />
              <Column
                field="value"
                editor="numeric"
                title="Value"
                cell={NumericCell}
                className="p-2"
              />
              <Column
                field="billVat"
                title="Vat"
                editable={false}
                cell={AmountFormatCell}
                className="p-2"
              />
              <Column
                field="gross"
                title="Gross"
                editable={false}
                cell={AmountFormatCell}
                className="p-2"
              />
            </Grid>
          </div>
          {error1 === true && (
            <i style={{ color: "red" }}>Select atleast 1 Record</i>
          )}
          <div>
            <Button
              title="Add new"
              className="common-btn thirdButton"
              style={{ marginTop: "10px" }}
              onClick={addNew}
            >
              Add new line
            </Button>
          </div>
        </div>

        <div className="dialog-footer">
          <button
            className="common-btn bgBtnSecondary border-0 me-3"
            onClick={() => {
              props.cancelEdit();
            }}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="k-button common-btn me-4"
            form="timeRecord"
          >
            Select
          </button>
        </div>
      </>
    </Dialog>
  );
};

export default BillProfitCostGrid;
