export const shortMonths = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const DateReturner = (date) => {
  var d = new Date(date);
  date = [
    d.getFullYear(),
    ("0" + (d.getMonth() + 1)).slice(-2),
    ("0" + d.getDate()).slice(-2),
  ].join("-");
  return date;
};

export function getFirstDayOfMonth(year, month) {
  return new Date(year, month, 1);
}

export function getLastDayOfMonth(year, month) {
  return new Date(year, month + 1, 0);
}
export function getMonthNumber(val) {
  let num = 0;
  shortMonths.map((item) => {
    if (item === val) {
      num = shortMonths.indexOf(item);
    }
    return item;
  });
  return num;
}

const branchIdReturner = (brancheslist, name) => {
  let id = null;
  brancheslist.map((item) => {
    if (item.branchId === name) {
      id = item.branchName;
    }
    return item;
  });
  return id;
};
const branchNameReturner = (brancheslist, name) => {
  let id = null;
  brancheslist.map((item) => {
    if (item.branchName === name) {
      id = item.branchId;
    }
    return item;
  });
  return id;
};

export const analyzeDashboardFilters = (e, branchesList) => {
  const paramsTemp = {};
  let fromDate = new Date("2023-01-29T08:00:00");
  let toDate = new Date();
  if (e.groupedBy === "FeeEarner") {
    paramsTemp.feeEarnerRef = e.groupingByParameter;
    paramsTemp.branch = e.filters.branch;
    paramsTemp.appCode = e.filters.appCode;
    if (e.filters.month === null) {
      paramsTemp.openTo = DateReturner(new Date());
      paramsTemp.openFrom = DateReturner(new Date("2023-01-29T08:00:00"));
    }
  } else if (e.groupedBy === "Application") {
    paramsTemp.appCode = e.groupingByParameter;
    paramsTemp.branch = e.filters.branch;
    paramsTemp.feeEarnerRef = e.filters.feeearnerRef;
    if (e.filters.month === null) {
      paramsTemp.openTo = DateReturner(new Date());
      paramsTemp.openFrom = DateReturner(new Date("2023-01-29T08:00:00"));
    }
  } else if (e.groupedBy === "Branch") {
    paramsTemp.branch = branchNameReturner(branchesList, e.groupingByParameter);
    paramsTemp.appCode = e.filters.appCode;
    paramsTemp.feeEarnerRef = e.filters.feeearnerRef;
    if (e.filters.month === null) {
      paramsTemp.openTo = DateReturner(new Date());
      paramsTemp.openFrom = DateReturner(new Date("2023-01-29T08:00:00"));
    }
  } else if (e.groupedBy === "Month") {
    paramsTemp.openTo = DateReturner(
      getLastDayOfMonth(
        new Date().getFullYear(),
        getMonthNumber(e.groupingByParameter.substring(0, 3))
      )
    );
    paramsTemp.openFrom = DateReturner(
      getFirstDayOfMonth(
        new Date().getFullYear(),
        getMonthNumber(e.groupingByParameter.substring(0, 3))
      )
    );
    fromDate = getFirstDayOfMonth(
      new Date().getFullYear(),
      getMonthNumber(e.groupingByParameter.substring(0, 3))
    );
    toDate = getLastDayOfMonth(
      new Date().getFullYear(),
      getMonthNumber(e.groupingByParameter.substring(0, 3))
    );
    paramsTemp.branch = e.filters.branch;
    paramsTemp.appCode = e.filters.appCode;
    paramsTemp.feeEarnerRef = e.filters.feeearnerRef;
  }
  return {
    parameters: paramsTemp,
    matterFilters: {
      ...paramsTemp,
      branchname:
        e.groupedBy === "Branch"
          ? e.groupingByParameter
          : branchIdReturner(branchesList, e.filters.branch),
    },
    fromDate: fromDate,
    toDate: toDate,
    includeEndStartDate: e.groupedBy === "Month",
  };
};
