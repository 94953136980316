import * as React from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import { useSelector, useDispatch } from "react-redux";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import * as api from "api/index";
import { AmountFormatCell } from "components/common/formatter";
import { Button } from "@progress/kendo-react-buttons";
import { useState, useEffect } from "react";
import { handleApiError } from "Helper";
import SearchComboBox from "components/common/SearchComboBox";
import CONSTANT from "CONSTANT/Matter/ComboxType";
import { Label } from "@progress/kendo-react-labels";
import { Form, FormElement } from "@progress/kendo-react-form";

const BillFixedHeadersGrid = (props) => {
  const initialData = {
    type: "ProfitCost",

    // details: "",
    // value: 0,
    // billVat: 0,
    // gross: 0,
    // vatCode: 0,
  };

  const [data, setData] = React.useState([]);
  const [changeTemplate, setChnageTemplate] = useState(false);
  const [error, setError] = useState(undefined);
  const [error1, setError1] = useState(undefined);

  const [vatvalue, setvatvalue] = useState();
  const [templatesData, setTemplatesData] = React.useState();
  const [feeearner, setfeeearnerData] = React.useState();
  const [feeearnerref, setfeeearnerref] = useState();
  const AmountNameHeader = (props) => {
    return (
      <div style={{ textAlign: "center", marginBottom: "8px" }}>
        {props.title}
      </div>
    );
  };

  const onChangevat = async (event) => {
    setvatvalue(event.value);
    try {
      const datas = await api.getActiveFeeItems(event.value.fixedFeeHeaderID);
      let dataList = [];

      datas.data.data.map((item) => {
        let data = Object.assign(
          {
            inEdit: true,
            billVat: 0,
            add: false,
            date: new Date(),
          },
          item
        );
        dataList.push(data);
      });
      setData(dataList);
    } catch (error) {
      handleApiError(error);
    }
  };

  const activeTemplatesData = useSelector(
    (state) => state.profitCostTemplate.activeFeeHeaders
  );

  const feeearnerdata = useSelector((state) => {
    return state.postingsdocs.feeearner.data;
  });

  const handleSubmit = (values) => {
    data.map((item) => {
      item.feeEarnerRef = values?.feeEarner.userRef;
      item.value = item.netAmount;
      item.billVat = item.vatAmount;
      item.billNet = item.netAmount;
      item.type = "ProfitCost";
    });
    let checkedData = [];
    data.map((item) => {
      if (item.add === true) {
        checkedData.push(item);
      }
    });
    if (data === 0) {
      setError1(true);
      return;
    }
    if (
      vatvalue === undefined ||
      values?.feeEarner === undefined ||
      feeearnerref === null
    ) {
      setError(true);
      return;
    }
    props.changeMatterList(checkedData);
    setData([]);
    props.cancelEdit();
  };
  const itemChange = (e) => {
    let newData = data.map((item) => {
      if (item.fixedFeeItemID === e.dataItem.fixedFeeItemID) {
        item[e.field || ""] = e.value;
      }
      return item;
    });
    setData(newData);
  };

  const fetchFeeHeader = async () => {
    const { data } = await api.getActiveFeeHeaders();
    setTemplatesData(data.data);
  };

  useEffect(() => {
    fetchFeeHeader();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      // setTemplatesData(activeTemplatesData?.data);
      setfeeearnerData(feeearnerdata.slice());
    }, 100);
  }, [activeTemplatesData]);
  return (
    <Dialog title={`Fixed Fee`} onClose={props.cancelEdit} width={"1100px"}>
      <div
        className="d-flex flex-column pt-4 pb-3 mb-3"
        style={{ padding: "40px" }}
      >
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            alignItems: "flex-start",
            gap: "5%",
            marginLeft: "10px",
          }}
        >
          <Form
            ignoreModified={true}
            onSubmit={handleSubmit}
            initialValues={initialData}
            key={JSON.stringify(initialData)}
            render={(formRenderProps) => {
              return (
                <div>
                  <FormElement id="timeRecord" name="timeRecord">
                    <fieldset className="d-flex me-3">
                      {formRenderProps.visited &&
                        formRenderProps.errors &&
                        formRenderProps.errors.VALIDATION_SUMMARY && (
                          <div className={"k-messagebox k-messagebox-error"}>
                            {formRenderProps.errors.VALIDATION_SUMMARY}
                          </div>
                        )}

                      <div className="d-flex flex-column mb-3 me-4">
                        <Label>Template</Label>
                        <div className="validator">
                          <ComboBox
                            data={templatesData}
                            textField={"name"}
                            filterable={true}
                            iconClassName="wa"
                            value={vatvalue}
                            style={{ width: "255px" }}
                            onChange={onChangevat}
                            placeholder={"Search Template"}
                          />
                          {error === true &&
                          (vatvalue === null || vatvalue === undefined) ? (
                            <i>Please Select Template</i>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>

                      <div className="mb-3">
                        <SearchComboBox
                          cbType={CONSTANT.FE}
                          name={"feeEarner"}
                          defaultValues={props.defaultValues}
                          defaultValueSetter={formRenderProps}
                          placeholder={"Select Fee Earner"}
                        />
                      </div>
                    </fieldset>
                  </FormElement>
                </div>
              );
            }}
          />
        </div>
        <div style={{ marginTop: "15px" }}>
          <Grid
            data={data}
            dataItemKey={"fixedFeeItemID"}
            onItemChange={itemChange}
            editField="inEdit"
            style={{ height: "350px", overflow: "auto" }}
          >
            <Column field="nominalRef" title="Nominal" editable={false} />
            <Column field="details" title="Details" editable={false} />
            <Column
              field="netAmount"
              title="Value"
              editable={false}
              cell={AmountFormatCell}
              headerCell={AmountNameHeader}
            />
            <Column
              field="netAmount"
              title="Net"
              editable={false}
              cell={AmountFormatCell}
              headerCell={AmountNameHeader}
            />
            <Column
              field="vatAmount"
              title="Bill Vat"
              editable={false}
              cell={AmountFormatCell}
              headerCell={AmountNameHeader}
            />
            <Column
              field="add"
              title="Select"
              editor="boolean"
              className="d-flex align-items-center justify-center mt-2 ms-3"
              width={"70px"}
            />
          </Grid>
          {error1 === true && (
            <i style={{ color: "red" }}>Select atleast 1 Record</i>
          )}
        </div>
        <div>
          <Button
            title="Select All"
            className="common-btn thirdButton mt-3"
            onClick={() => {
              let tempData = [];
              data.map((item) => {
                item.add = true;
                tempData.push(item);
              });
              setData(tempData);
            }}
          >
            Select All
          </Button>
        </div>
      </div>

      <div className="dialog-footer">
        <button
          className="common-btn bgBtnSecondary border-0 me-3"
          onClick={() => {
            props.cancelEdit();
          }}
        >
          Cancel
        </button>
        <button
          type="submit"
          className="k-button common-btn me-4"
          form="timeRecord"
        >
          Select
        </button>
      </div>
    </Dialog>
  );
};

export default BillFixedHeadersGrid;
