import {
  ADD_DASHBOARD_MATTER_FILTER,
  START_LOADING,
  GET_DASHBOARD_BILLING_TARGET,
  GET_DASHBOARD_MATTER_DATA,
} from "../types/index.js";

const DashboardReducer = (
  state = {
    billingTarget: [],
    mattersOpenedData: [],
  },
  action
) => {
  switch (action.type) {
    case ADD_DASHBOARD_MATTER_FILTER:
      return { ...state, filters: action.payload };
    case GET_DASHBOARD_BILLING_TARGET:
      return { ...state, billingTarget: action.payload };
    case GET_DASHBOARD_MATTER_DATA:
      return { ...state, mattersOpenedData: action.payload };

    default:
      return state;
  }
};
export default DashboardReducer;
