// handleDownloadAndUpload.js
import { toast } from "react-toastify";
import { saveActivity } from "api";
import { FileDocumentTypes } from "components/Enums/FileDocumentTypes.ts";

// Function to fetch the file and convert it to a Blob
async function fetchFileAsBlob(url) {
  const response = await fetch(url);
  if (!response.ok) {
    throw new Error("Failed to fetch the file");
  }
  const blob = await response.blob();
  const fileName = "Billing Guide.pdf";
  const file = new File([blob], fileName, { type: "application/pdf" });
  return file;
}

function replaceDisposition(url) {
  return url.replace("attachment", "inline");
}

// Function to handle the download and upload process
export async function handleDownloadAndUpload(
  url,
  matterID,
  uploadForm,
  onSuccess
) {
  try {
    const downloadedFile = await fetchFileAsBlob(url);

    const appendData = {
      filename: downloadedFile.name,
      fileID: "",
    };

    const parameters = {
      documentType: FileDocumentTypes.CaseFile,
      matterId: matterID,
    };

    const dataChoosen = await uploadForm(
      appendData,
      downloadedFile,
      parameters
    );
    const data = {
      details: 'Billing Guide',
      units: 0,
      fileId: dataChoosen?.data?.data?.fileID,
      subType: "FileNote",
    };

    await saveActivity(matterID, data);
    onSuccess();
  } catch (error) {
    console.error("Error during file download and upload:", error);
  }
}
