import React, { useEffect, useState } from "react";
import { Input } from "@progress/kendo-react-inputs";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { Button } from "@progress/kendo-react-buttons";
import SectionDivider from "components/Contact/sectionDivider";
import styles from "../../Settings.module.scss";
import Subsub from "../../../../assets/Settings/Integrations/Sumsub.png";
import {
  disconnectSumsub,
  getSumsubLevels,
  getSumsubSettings,
  postSumsubToken,
  saveSumsubSettings,
} from "api";
import { toast } from "react-toastify";

export default function SumsubPage({ connectionStatus, onClose }) {
  const [appToken, setAppToken] = useState("");
  const [secretKey, setSecretKey] = useState("");
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const [isConnecting, setIsConnecting] = useState(false);
  const [isDisconnecting, setIsDisconnecting] = useState(false);
  const [kycLevels, setKycLevels] = useState([]);
  const [currentKyc, setCurrentKyc] = useState(null);

  const handleInputChange = (setter, value) => {
    setter(value);
    setIsButtonEnabled(value && appToken && secretKey);
  };

  const syncHMRCStatus = async () => {
    setIsDisconnecting(true);
    try {
      await disconnectSumsub();
      onClose();
      toast.success("Synchronizing started...");
    } catch (error) {
      onClose();
      toast.error("Error occurred...");
    } finally {
      setIsDisconnecting(false);
    }
  };

  const getKycLevels = async () => {
    try {
      const res = await getSumsubLevels();
      if (res?.data?.data) {
        setKycLevels(res.data.data);
      }
    } catch (error) {
      toast.error("Error occurred...");
    }
  };

  const getKycOption = async () => {
    try {
      const res = await getSumsubSettings();
      if (res?.data?.data) {
        setCurrentKyc(res?.data?.data?.defaultKYCLevel);
      }
    } catch (error) {
      toast.error("Error occurred...");
    }
  };

  const postSumsubCredetials = async () => {
    setIsConnecting(true);
    try {
      await postSumsubToken({ appToken, secretKey });
      toast.success("Connected");
      onClose();
    } catch (error) {
      onClose();
      toast.error("Error occurred...");
    } finally {
      setIsButtonEnabled(false);
      setIsConnecting(false);
    }
  };

  const onChangeKycValue = async (e) => {
    try {
      await saveSumsubSettings({ defaultKYCLevel: e.value });
      toast.success("KYC Updated");
      setCurrentKyc(e.value);
      onClose();
    } catch (error) {
      onClose();
      toast.error("Error occurred...");
    } finally {
      setIsButtonEnabled(false);
    }
  };

  useEffect(() => {
    if (connectionStatus) {
      getKycLevels();
      getKycOption();
    }
  }, [connectionStatus]);

  return (
    <>
      {!connectionStatus ? (
        <div
          className={`d-flex align-items-start ${styles["clio-container"]} gap-3 p-4`}
        >
          <div>
            <img style={{ width: "200px", height: "100px" }} src={Subsub}></img>
          </div>
          <div className="mt-2 w-100">
            <SectionDivider sectionName="Sumsub ID Verification" />
            <div className="d-flex align-items-center mt-5">
              <p className={`${styles["label"]} mt-2`}>App Token :</p>
              <Input
                placeholder="Enter App Token"
                value={appToken}
                onChange={(e) => handleInputChange(setAppToken, e.target.value)}
              />
            </div>
            <div className="d-flex align-items-center mt-2">
              <p className={`${styles["label"]} mt-2`}>Secret Key:</p>
              <Input
                placeholder="Enter Secret Key"
                value={secretKey}
                onChange={(e) =>
                  handleInputChange(setSecretKey, e.target.value)
                }
              />
            </div>
            <Button
              className="common-btn mt-3"
              onClick={postSumsubCredetials}
              style={{ width: "165px" }}
              disabled={isConnecting}
            >
              {isConnecting ? "Connecting..." : "Connect"}
            </Button>
          </div>
        </div>
      ) : (
        <div
          className={`d-flex align-items-start ${styles["clio-container"]} gap-3 p-4`}
        >
          <div>
            <img style={{ width: "200px", height: "100px" }} src={Subsub}></img>
          </div>
          <div className="mt-2 w-100">
            <SectionDivider sectionName="Sumsub ID Verification" />
            <div className="d-flex align-items-center gap-2 mt-2">
              <p className={`${styles["label"]} mt-2`}>KYC Level:</p>
              <ComboBox
                data={kycLevels}
                value={currentKyc}
                placeholder="Select KYC Level"
                onChange={onChangeKycValue}
                iconClassName="wa"
              />
            </div>
            <div className="d-flex align-items-center gap-2 mt-3">
              <p className={`${styles["label"]} mt-2`}>Disconnect Sumsub</p>
              <Button
                className="common-btn"
                onClick={syncHMRCStatus}
                style={{ width: "165px" }}
                disabled={isDisconnecting}
              >
                {isDisconnecting ? "Disconnecting..." : "Disconnect"}
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
