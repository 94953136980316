import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "@progress/kendo-react-intl";
import "@progress/kendo-react-dropdowns";
import "react-router-dom";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { Button } from "@progress/kendo-react-buttons";
import { HandleGridBody } from "components/common/HandleGridBody.js";
import { Menu, MenuItem } from "@progress/kendo-react-layout";
import * as api from "api/index";
import AddClients from "./AddViewClients.jsx";
import { useNavigate } from "react-router-dom";
import styles from "./Clients.module.scss";
import SearchTextBox from "../../components/SearchComponent.jsx";
import { getClientDropDownData } from "actions/common.action.js";
import { FilterComponent } from "components/common/FiltersComponent";
import {
  changeClientEditPermission,
  getClientsAllInfo,
  getClientsList,
} from "../../actions/clients.action.js";
import { handleApiError } from "Helper/index.js";
import {
  NameTextCell,
  RedirectCell,
} from "components/common/GridComponents.jsx";
import { Checkbox } from "@progress/kendo-react-inputs";
import { Skeleton } from "@progress/kendo-react-indicators";
import { getClientData } from "api";
import MattersGridComponent from "../../components/Matters/MattersGridComponent.jsx";
import { getAccessRightsData } from "api";

const DetailComponent = (props) => {
  const data = props.dataItem.matters;
  const matters = data?.filter((matter) => !matter.closed);
  if (data && data?.length > 0) {
    return <MattersGridComponent Clientdata={matters} fromClient={true} />;
  } else {
    return <div className="text-center fw-semibold">No matters found</div>;
  }
};

const Clients = () => {
  const dispatch = useDispatch();
  const [showClientForm, setShowClientForm] = useState(false);
  const [editData, setEditData] = useState({});
  const [searchValue, setSearchValue] = useState();
  const [isSearched, setIsSearched] = useState(false);
  const [eventDataState, setEventDataState] = useState();
  const [list, setLists] = useState({ data: [], total: 50 });
  const [isView, setIsView] = useState(false);
  const [loadedContent, setLoadedContent] = useState(true);
  const [dataState, setDataState] = useState({ take: 50, skip: 0 });
  const [isRefreshed, setIsRefreshed] = useState(false);
  const [filterParameters, setFilterParameters] = useState({});
  const [showClientView, setshowClientView] = useState(false);
  const [allowAccessData, setAllowAccessData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [reference, setReference] = useState("");
  const [clientId, setClientId] = useState("");

  const searchRef = useRef();
  const viewEdit = useSelector((state) => state.clients.clinetEditPermission);
  const handleRefresh = () => {
    setIsSearched(false);
    // setSearchValue("");
    // searchRef.current.clearSearch();
    setIsRefreshed(!isRefreshed);
    if (eventDataState) {
      setDataState({
        ...eventDataState?.event,
        skip: 0,
      });
    } else {
      setDataState({
        ...dataState,
        take: 50,
        skip: 0,
      });
    }
  };
  const expandChange = (event) => {
    event.dataItem.expanded = event.value;
    // let clientID = event.dataItem.clientId;
    setLists({ data: [...list.data], total: list.total });
    if (!event.value || event.dataItem.matters) {
      return;
    }
  };

  const handleAction = async (props) => {
    try {
      const data = await api.getClientData(props?.dataItem?.clientId);
      dispatch(getClientsAllInfo(props?.dataItem?.clientRef, data?.data?.data));
      setEditData(props.dataItem);
    } catch (error) {
      handleApiError(error);
    }
  };

  const navigate = useNavigate();

  const clientsDataGetter = async (id) => {
    try {
      setIsLoading(true);
      const data = await getClientData(id);
      setEditData(data?.data?.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      alert("No client exists against this Id. Try again with different Id");
      navigate("/clients");
    }
  };

  const onClientRefClickActions = (props) => {
    setReference(props.dataItem?.clientRef);
    clientsDataGetter(props.dataItem?.clientId);
    setClientId(props.dataItem?.clientId);
    setshowClientView(true);
  };

  const createState = (skip, take) => {
    let pagerSettings = {
      pageSizes: [10, 25, 50, 100],
    };
    return {
      pageSize: take,
      pageable: pagerSettings,
    };
  };
  const [state, setState] = React.useState(createState(0, 50));

  const Redirect = (props) => (
    <RedirectCell handleClick={() => onClientRefClickActions(props)} />
  );

  const dataStateChange = (e) => {
    const eventObject = { event: e.dataState };
    setEventDataState(eventObject);
    if (
      e.dataState.sort !== undefined &&
      e.dataState.skip !== 0 &&
      dataState.sort !== e.dataState.sort
    ) {
      setDataState({ ...e.dataState, skip: 0 });
    } else if (e.dataState?.sort?.[0] === undefined) {
      setDataState({
        ...e.dataState,
        sort: [
          {
            field: "clientRef",
            dir: "asc",
          },
        ],
      });
    } else {
      setDataState(e.dataState);
    }
  };
  const dataReceived = (list) => {
    if (list.data) {
      setLists(list);
    }
  };
  const dotsImg = (e) => {
    return (
      <td>
        <Menu
          onSelect={() => {
            handleAction(e);
          }}
        >
          <MenuItem icon="k-icon k-i-more-vertical" style={{ marginLeft: -8 }}>
            <MenuItem text="Edit" />
            <MenuItem text="View" />
          </MenuItem>
        </Menu>
      </td>
    );
  };

  const ClientCell = (props) => {
    return (
      <td>
        <u
          style={{ cursor: "pointer" }}
          onClick={() => {
            onClientRefClickActions(props);
          }}
        >
          {props.dataItem.clientRef}
        </u>
      </td>
    );
  };

  const NoOfMattersCell = (props) => {
    return (
      <td className="text-center">
        {props.dataItem.noOfMatters > 0 && props.dataItem.noOfMatters}
      </td>
    );
  };

  const getAccessRights = async () => {
    try {
      const data = await getAccessRightsData();
      setAllowAccessData(data?.data?.data);
      setLoadedContent(false);
    } catch (error) {}
  };

  useEffect(() => {
    getAccessRights();
  }, []);

  const analyzeFilterParamters = (params) => {
    const paramsToFilter = {
      branch: params?.branch,
      appCode: params?.appCode,
      feeEarnerRef: params?.feeearnerRef,
      teamId: params?.teamId,
    };
    setFilterParameters(paramsToFilter);
  };

  const getHeaderItems = () => {
    return loadedContent ? (
      <Skeleton
        shape="rectangle"
        className="mb-3"
        style={{
          width: "100%",
          height: "70px",
        }}
      />
    ) : (
      <div className="d-flex align-items-end mb-3">
        <div className="">
          <FilterComponent
            allowAccessData={allowAccessData}
            updateFilters={(data) => analyzeFilterParamters(data)}
            isRefreshed={isRefreshed}
          />
        </div>
        <SearchTextBox
          ref={searchRef}
          changeFilterValue={(e) => {
            setIsSearched(true);
            setSearchValue(e);
          }}
        />

        <Button
          className="ms-3 common-btn me-1"
          icon="refresh"
          onClick={handleRefresh}
        >
          Refresh
        </Button>

        <Button
          className="common-btn ms-auto me-2"
          onClick={() => {
            setShowClientForm(true);
            setIsView(false);
            setEditData("");
          }}
        >
          Add Client
        </Button>
        <Button
          className="common-btn text-wrap"
          onClick={() => {
            handleOnboardingClickAction();
          }}
        >
          Client Onboarding
        </Button>
      </div>
    );
  };

  React.useEffect(() => {
    dispatch(getClientDropDownData());
  }, []);

  const handleOnboardingClickAction = () => {
    navigate(`/clients/onboarding`);
  };

  return (
    <div className="page">
      {getHeaderItems()}
      {React.useMemo(() => {
        return (
          <Grid
            {...dataState}
            data={list}
            detail={DetailComponent}
            expandField="expanded"
            onExpandChange={expandChange}
            onDataStateChange={dataStateChange}
            sortable={true}
            className="fs-md mt-3 clientlist_gridheight"
            pageable={state.pageable}
            pageSize={state.pageSize}
            resizable={true}
          >
            <GridColumn
              field="clientRef"
              title="Client Ref"
              cell={ClientCell}
              width="120px"
            />
            <GridColumn
              field="clientName"
              title="Client Name"
              className="gridBoldField"
            />

            <GridColumn field="feeEarnerRef" title="Fee Earner" width="100px" />
            <GridColumn field="type" title="Type" width="120px" />
            <GridColumn field="phoneNo" title="Phone" width="150px" />
            <GridColumn field="email" title="Email" width="220px" />
            <GridColumn field="address" title="Address" />
            <GridColumn
              field="noOfMatters"
              title="Matters"
              width="100px"
              cell={NoOfMattersCell}
            />
            <GridColumn width="60px" cell={Redirect} />
          </Grid>
        );
      }, [dataState, list])}

      {isSearched ? (
        <HandleGridBody
          dataState={dataState}
          onDataReceived={dataReceived}
          searchValue={(isSearched && searchValue) || null}
          isSearched={isSearched}
          isRefreshed={isRefreshed}
          currentComponent="clients"
          filterParameters={filterParameters}
        />
      ) : (
        <HandleGridBody
          dataState={dataState}
          onDataReceived={dataReceived}
          isRefreshed={isRefreshed}
          filterParameters={filterParameters}
          currentComponent="clients"
        />
      )}

      {(showClientForm || viewEdit) && (
        <Dialog
          className="dialog-xxl add-dialog"
          title={viewEdit ? "Update Client" : "Add Client"}
          onClose={() => {
            if (viewEdit) dispatch(changeClientEditPermission());
            setShowClientForm(false);
          }}
          open={() => {
            setShowClientForm(true);
          }}
        >
          <AddClients
            data={editData}
            isView={isView}
            formId={viewEdit ? "edit" : "add"}
            title={`Add Client`}
            onClose={() => {
              if (viewEdit) dispatch(changeClientEditPermission());
              setShowClientForm(false);
              clientsDataGetter(clientId);
              dispatch(
                getClientsList(
                  dataState.skip / dataState.take + 1,
                  dataState.take
                )
              );
            }}
            open={() => {
              setShowClientForm(true);
            }}
          />

          <DialogActionsBar>
            <div className="dialog-footer  ">
              <Button
                type="button"
                className="common-btn bgBtnSecondary border-0 me-2"
                onClick={() => {
                  if (viewEdit) {
                    dispatch(changeClientEditPermission());
                  }
                  setShowClientForm(false);
                }}
              >
                Cancel
              </Button>
              <Button
                className="common-btn me-4"
                type="submit"
                onClick={() =>
                  document.getElementById("client-submitter").click()
                }
                form={viewEdit ? "edit" : "add"}
              >
                {viewEdit ? "Update Client" : "Add Client"}
              </Button>
            </div>
          </DialogActionsBar>
        </Dialog>
      )}

      {showClientView && !isLoading && (
        <AddClients
          data={editData}
          formId={"view"}
          onClose={() => {
            setshowClientView(false);
          }}
          open={() => {
            setshowClientView(true);
          }}
          reference={reference}
          type={"VIEW"}
        />
      )}
    </div>
  );
};
export default Clients;
