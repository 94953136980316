import React, { useEffect } from "react";
import ClioImage from "../../../../assets/Settings/Integrations/Clio.png";
import SectionDivider from "components/Contact/sectionDivider";
import styles from "../../Settings.module.scss";
import ClioAuthentication from "./ClioAuthentication";
import { Input } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import { useState } from "react";
import { clioStatus, clioSync, disconnectClio } from "api";
import { toast } from "react-toastify";
import ConfirmationDialog from "components/common/ConfirmationDialog";
import { ConnectionStatus } from "../IntegrationCard";
import LoadingScreen from "components/common/Loading";
import { formatDate } from "@progress/kendo-intl";
import { useSelector } from "react-redux";

export default function ClioPage({ onClose }) {
  const [clioData, setClioData] = useState({});
  const [isConnected, setIsConnected] = useState("loading");
  const [syncMessage, setSyncMessage] = useState(false);
  const [confirmationDialog, setConfirmationDialog] = useState(false);
  let locale = useSelector((state) => state?.company?.company?.locale);

  const getClioStatusData = async () => {
    try {
      const data = await clioStatus();
      setIsConnected(data?.data?.connected);
      setClioData(data?.data);
    } catch (error) {}
  };

  const syncClioStatus = async () => {
    setSyncMessage(true);
    try {
      await clioSync();
      onClose();
      toast.success("Synchronizing started...");
    } catch (error) {
      onClose();
      toast.error("Error occured...");
    }
  };

  const disconnectClioServices = async () => {
    try {
      await disconnectClio();
      onClose();
      setConfirmationDialog(false);
      toast.success("Diconnected to Clio Services");
      setIsConnected(false);
    } catch (error) {
      onClose();
      toast.error("Error occured...");
    }
  };

  useEffect(() => {
    getClioStatusData();
  }, []);

  if (isConnected === "loading") return <LoadingScreen />;

  if (isConnected === false)
    return (
      <div
        className={`d-flex align-items-start ${styles["clio-container"]} p-4`}
      >
        <div>
          <img src={ClioImage}></img>
        </div>
        <div className="mt-2">
          <div>
            <SectionDivider
              sectionName="Clio Case Management System"
              component={"Clio"}
            />
          </div>
          <div className={`${styles["clio-text"]} mt-5 `}>
            <p>
              Clio is the cloud-based legal case management software that makes
              running your firm, organizing cases, and collaboration with
              clients from one place possible.
            </p>
          </div>
          <div
            className="mt-4"
            onClick={() => window.open("https://www.clio.com/", "_blank")}
          >
            <p className={`${styles["blue-text"]}`}>
              <u>More Details...</u>
            </p>
          </div>
          <div className="d-flex align-items-center ">
            <p className={`${styles["label"]} mt-2 pt-1 `}>
              Existing Clio Users :
            </p>
            <ClioAuthentication
              onClose={() => {
                setIsConnected(true);
                onClose();
              }}
            />
          </div>
        </div>
      </div>
    );

  if (isConnected === true)
    return (
      <>
        <div>
          <div className="float-end mt-2 me-3">
            <ConnectionStatus status={true} />
          </div>
          <div
            className={`d-flex align-items-start ${styles["clio-container"]} p-4`}
          >
            <div>
              <img src={ClioImage}></img>
            </div>
            <div className="mt-2 w-100">
              <div>
                <SectionDivider
                  sectionName="Clio Case Management System"
                  component={"Clio"}
                />
              </div>
              <div className="d-flex align-items-center mt-5">
                <p className={`${styles["label"]} mt-2`}>Connected By:</p>
                <Input readOnly value={clioData?.connectedBy} />
              </div>
              <div className="d-flex align-items-center mt-2">
                <p className={`${styles["label"]} mt-2`}>Connected On:</p>
                <Input
                  readOnly
                  value={formatDate(
                    new Date(clioData?.connectedDate),
                    "d",
                    locale
                  )}
                />
              </div>
              <div className="d-flex align-items-center mt-2">
                <p className={`${styles["label"]} mt-2`}>Full Synced On:</p>
                <Input
                  readOnly
                  value={formatDate(
                    new Date(clioData?.fullSyncDate),
                    "d",
                    locale
                  )}
                />
              </div>
              <div className="d-flex align-items-center mt-2">
                <p className={`${styles["label"]} mt-2`}>Last Updated On:</p>
                <Input
                  readOnly
                  value={formatDate(
                    new Date(clioData?.lastSyncDate),
                    "d",
                    locale
                  )}
                />
              </div>
              <div className="d-flex align-items-center mt-4">
                <p className={`${styles["label"]} mt-2`}></p>
                <Button
                  className="common-btn me-3"
                  onClick={syncClioStatus}
                  style={{ width: "165px" }}
                  disabled={syncMessage}
                >
                  Sync Data
                </Button>
                <Button
                  className="common-btn"
                  onClick={() => setConfirmationDialog(true)}
                  style={{ width: "165px" }}
                  disabled={syncMessage}
                >
                  Disconnect
                </Button>
              </div>
            </div>
          </div>
        </div>
        {confirmationDialog && (
          <ConfirmationDialog
            content={"Are you sure to disconnect to Clio system?"}
            onClickYes={() => {
              disconnectClioServices();
            }}
            onClickNo={() => setConfirmationDialog(false)}
            onClose={() => setConfirmationDialog(false)}
          />
        )}
      </>
    );
}
