import React, { useEffect, useState } from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import { ContainerCard } from "components/MatterDetails/ContactCard";
import {
  getApplicationUserSetting,
  getMatterRates,
  getNewEmail,
  getSuggestedEmails,
} from "api";
import { FormNumericTextBox, FormInput } from "components/Matters/index";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { CONSTANT } from "CONSTANT/Matter/MatterActivityForm";
import { Tooltip } from "@progress/kendo-react-tooltip";
import infoIcon from "../../../assets/CaseFileIcons/info-icon.png";
import { Label } from "@progress/kendo-react-labels";
import { Tile } from "components/UIComponents/Tile";
import { Loader } from "@progress/kendo-react-indicators"; // Import your loader component
import { toast } from "react-toastify";

const EmailDialog = ({ title, onClose, matterId, openExistingEmailDialog }) => {
  const SELECTED_FIELD = "selected";
  const [userSetting, setUserSetting] = useState();
  const [suggestedEmails, setSuggestedEmails] = useState([]);
  const [loading, setLoading] = useState(false); // State for loading
  const [inputState, setInputState] = useState({
    [CONSTANT.INPUT.UNITS]: 1,
    [CONSTANT.INPUT.MPU]: 6,
    [CONSTANT.INPUT.RATE]: 0.0,
    [CONSTANT.INPUT.VALUE]: 0.0,
  });

  const generateRandomId = () => Math.floor(Math.random() * 1000000); // Function to generate a random integer ID

  useEffect(() => {
    const fetchSuggestedEmails = async () => {
      try {
        const emails = await getSuggestedEmails(matterId);

        const emailsWithId = emails.data?.data.map((email) => ({
          ...email,
          id: generateRandomId(),
          inEdit: false,
        }));

        setSuggestedEmails([
          ...emailsWithId,
          {
            email: "",
            id: generateRandomId(),
            inEdit: true,
          },
        ]);
      } catch (error) {
        console.error("Failed to fetch suggested emails", error);
      }
    };

    const getEmailSetting = async () => {
      const result = await getApplicationUserSetting();
      setUserSetting(result?.data?.data?.defaultEmailClient);
    };

    getEmailSetting();
    fetchSuggestedEmails();
  }, [matterId]);

  const openEmailClient = (type, subject, body, suggestedEmails) => {
    const toList = suggestedEmails
      .filter((email) => email.selected) // Get active emails
      .map((email) => email.email?.trim())
      .join(",");

    switch (type) {
      case "DefaultEmailClient":
        window.open(
          `mailto:${toList}?subject=${encodeURIComponent(
            subject
          )}&body=${encodeURIComponent(body)}`,
          "_blank"
        );
        break;

      case "LinqApplication":
        openExistingEmailDialog({ to: toList, subject, body });
        break;

      case "LocalMail":
      case "LocalOutlook":
        window.open(
          `mailto:${toList}?subject=${encodeURIComponent(
            subject
          )}&body=${encodeURIComponent(body)}`,
          "_blank"
        );
        break;

      case "OfficeWebMail":
        window.open(
          `https://outlook.office.com/mail/deeplink/compose?to=${toList}&subject=${encodeURIComponent(
            subject
          )}&body=${encodeURIComponent(body)}`,
          "_blank"
        );
        break;

      default:
        console.error("Unknown email client type");
    }
  };

  const openNewMail = async () => {
    setLoading(true); // Show loader
    try {
      const data = await getNewEmail(
        matterId,
        inputState[CONSTANT.INPUT.UNITS]
      );
      openEmailClient(
        userSetting,
        data?.data?.data?.subject,
        data?.data?.data?.body,
        suggestedEmails
      );
    } catch (error) {
      console.error("Failed to open email", error);
      toast.error("Failed to open email");
    } finally {
      setLoading(false); // Hide loader after operation
    }
  };

  const CheckboxCell = (props) => {
    const handleChange = (event) => {
      const updatedItem = {
        ...props.dataItem,
        [props.field]: event.target.checked,
      };

      handleCellChange({
        dataItem: updatedItem,
        field: props.field,
        value: event.target.checked,
      });
    };
    return (
      <td className="">
        <input
          type="checkbox"
          style={{ width: "18px", height: "18px" }}
          className="cursor-pointer mt-1"
          checked={props.dataItem[props.field] || false}
          onChange={handleChange}
        />
      </td>
    );
  };

  const handleCellChange = (event) => {
    const updatedEmails = suggestedEmails.map((email) =>
      email.id === event.dataItem.id
        ? { ...email, [event.field]: event.value }
        : email
    );
    setSuggestedEmails(updatedEmails);
  };

  const [comboBoxState, setComboBoxState] = useState({
    [CONSTANT.COMBOBOX.TYPE]: "",
    [CONSTANT.COMBOBOX.FEE_EARNER]: "",
  });

  const [rateInfoText, setRateInfoText] = useState("");

  const getMatterRateValue = async () => {
    try {
      const data = await getMatterRates(
        "referenceId", // Replace with actual reference if needed
        comboBoxState[CONSTANT.COMBOBOX.FEE_EARNER]?.userRef
      );
      setInputState((prevState) => ({
        ...prevState,
        [CONSTANT.INPUT.RATE]: data?.data?.data?.rateValue,
      }));
      setRateInfoText(data?.data?.data?.rateInfo);
    } catch (error) {
      console.error("Error fetching matter rate value", error);
    }
  };

  useEffect(() => {
    if (comboBoxState[CONSTANT.COMBOBOX.FEE_EARNER] !== "") {
      getMatterRateValue();
    }
  }, [comboBoxState[CONSTANT.COMBOBOX.FEE_EARNER]]);

  useEffect(() => {
    calculateValue();
  }, [
    inputState[CONSTANT.INPUT.RATE],
    inputState[CONSTANT.INPUT.UNITS],
    inputState[CONSTANT.INPUT.MPU],
  ]);

  const handleInputChange = (value, label) => {
    setInputState((prevState) => ({
      ...prevState,
      [label]: value,
    }));
  };

  const onItemChange = (event) => {
    const editedItemID = event.dataItem.id;
    let selected = false;
    if (event.field === "email" && event.value?.trim() !== "") {
      selected = true;
    }
    const data = suggestedEmails.map((item) =>
      item.id === editedItemID
        ? { ...item, [event.field]: event.value, selected: selected }
        : item
    );
    setSuggestedEmails(data);
  };

  const calculateValue = () => {
    if (
      inputState[CONSTANT.INPUT.RATE] &&
      inputState[CONSTANT.INPUT.UNITS] &&
      inputState[CONSTANT.INPUT.MPU]
    ) {
      const value =
        (inputState[CONSTANT.INPUT.RATE] / 60) *
        (inputState[CONSTANT.INPUT.UNITS] * inputState[CONSTANT.INPUT.MPU]);
      setInputState((prevState) => ({
        ...prevState,
        [CONSTANT.INPUT.VALUE]: value,
      }));
    }
  };

  const handleComboBoxChange = (e, label) => {
    setComboBoxState({
      ...comboBoxState,
      [label]: e.value,
    });
  };

  return (
    <Dialog title={title} onClose={onClose} width={"800px"}>
      <div className="dialog-content p-3 pb-1">
        <ContainerCard className="p-3 ps-2 pe-2 pt-2 pb-0 overflow-auto">
          <Grid
            data={suggestedEmails}
            style={{ height: "300px" }}
            editField="inEdit"
            className="pb-1"
            onItemChange={onItemChange}
          >
            <GridColumn
              field={SELECTED_FIELD}
              width="50px"
              editable={false}
              cell={CheckboxCell}
              onChange={handleCellChange}
              title=" "
            />
            <GridColumn
              field="name"
              editable={false}
              title="Name"
              className="fs-sm fw-bold"
              width="180px"
            />
            <GridColumn
              field="email"
              title="Email"
              className="fs-sm"
              editor="text"
            />
            <GridColumn
              field="contactCategory"
              title="Category"
              className="fs-sm"
              editable={false}
              width="130px"
            />
          </Grid>
        </ContainerCard>
      </div>

      {loading && (
        <div
          className="loader-container d-flex justify-content-center align-items-center"
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Loader themeColor={"warning"} size={"large"} type={"pulsing"} />
        </div>
      )}

      <Tile
        heading="Time Record"
        headingClass=""
        className=" m-3 mt-1 p-3 ps-4 pe-4 pt-2 pb-0 overflow-auto"
        height={"160px"}
      >
        <div className="ps-2 d-flex align-items-center gap-3 ">
          <FormNumericTextBox
            type="number"
            width="186px"
            state={inputState}
            steppers={true}
            format=""
            labelForBox={"Units"}
            label={CONSTANT.INPUT.UNITS}
            onChangeInput={(e) => {
              handleInputChange(e, CONSTANT.INPUT.UNITS);
            }}
          />
          <div className="d-flex flex-column right-align">
            <div className="d-flex align-items-baseline ">
              <Label className="label mb-1">Rate</Label>
              <Tooltip anchorElement="target" position="top">
                <img
                  className="ms-1 cursor-pointer"
                  src={infoIcon}
                  title={rateInfoText}
                  style={{
                    height: "14px",
                    width: "14px",
                  }}
                />
              </Tooltip>
            </div>
            <FormInput
              type="number"
              width="186px"
              labelShow={false}
              state={inputState}
              label={CONSTANT.INPUT.RATE}
              onChangeInput={(e) =>
                handleInputChange(e.value, CONSTANT.INPUT.RATE)
              }
            />
          </div>
          <div className="">
            <div className="d-flex align-items-baseline ">
              <Label className="label mb-1">Value</Label>
              <Tooltip anchorElement="target" position="top">
                <img
                  className="ms-1 cursor-pointer"
                  src={infoIcon}
                  title={`MPU = ${inputState[CONSTANT.INPUT.MPU]}`}
                  style={{
                    height: "14px",
                    width: "14px",
                  }}
                />
              </Tooltip>
            </div>
            <FormInput
              type="number"
              width="186px"
              labelShow={false}
              state={inputState}
              className={"col-4"}
              label={CONSTANT.INPUT.VALUE}
              onChangeInput={(e) =>
                handleInputChange(e.value, CONSTANT.INPUT.VALUE)
              }
            />
          </div>
        </div>
      </Tile>
      <div className="dialog-footer">
        <Button className="common-btn bgBtnSecondary me-2" onClick={onClose}>
          Close
        </Button>
        <Button className="common-btn  me-3" onClick={openNewMail}>
          Open Email
        </Button>
      </div>
    </Dialog>
  );
};

export default EmailDialog;
