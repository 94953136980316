import * as React from "react";
import { orderBy } from "@progress/kendo-data-query";
import styles from "../Settings.module.scss";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { ActiveCell, EditCommandCell, SignatureCell } from "../GridComponents";
import { deleteItem } from "Services/contactLinksTypsService";
import { Button } from "@progress/kendo-react-buttons";
import ContactLinkTypeModal from "./ContactLinkTypeModal";
import { getContactLinks } from "api/settingApi";
import { toast } from "react-toastify";

const editField = "inEdit";
const initialSort = [
    {
        field: "linkType",
        dir: "asc",
    },
];

const ContactLinkDataGrid = () => {
    const [data, setData] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [currentEditItem, setCurrentEditItem] = React.useState("");
    const [sort, setSort] = React.useState(initialSort);

    const [modalData, setModalData] = React.useState({
        type: undefined,
        data: null,
    });

    const [modal, setModal] = React.useState(false);

    const getItems = async () => {
        try {
            const { data } = await getContactLinks()
            data.data.map((item) => {
                return (item.symbol = item?.categoryDetails ? item?.categoryDetails[0] : 'A');
            });

            setData([...data.data]);
        }
        catch (error) {
            console.log(error)
        }
    };
    React.useEffect(() => {
        getItems();
    }, []);

    const remove = (dataItem) => {
        const newData = [...deleteItem(data, dataItem)];
        setData(newData);
    };

    // Local state operations
    const discard = () => {
        const newData = [...data];
        newData.splice(0, 1);
        setData(newData);
    };
    const cancel = (dataItem) => {
        const originalItemIndex = data.findIndex(
            (p) => p.contactLinkTypeID === dataItem.contactLinkTypeID
        );
        const copyData = [...data];
        copyData[originalItemIndex] = currentEditItem;

        setData([...copyData]);
    };
    const enterEdit = (dataItem) => {
        return handleModalOpen(2, dataItem);
    };
    const itemChange = (event) => {
        const newData = data.map((item) =>
            item.contactLinkTypeID === event.dataItem.contactLinkTypeID
                ? {
                    ...item,
                    [event.field || ""]: event.value,
                }
                : item
        );
        toast.success("Link Type Updated");
        setData(newData);
    };

    const CommandCell = (props) => (
        <EditCommandCell
            {...props}
            enterEdit={enterEdit}
            remove={remove}
            discard={discard}
            cancel={cancel}
            editField={editField}
            loading={loading}
        />
    );

    const handleModalOpen = (actionType, actionData) => {
        if (!actionType) return alert("Please provide type");
        const modalDataOjbect = { ...modalData };
        modalDataOjbect.type = actionType;
        modalDataOjbect.data = actionData;
        setModalData({ ...modalDataOjbect });
        setModal(true);
    };

    return (
        <>
            <>
                <div className="row">
                    <div className="col-lg-10 col-md-8"></div>
                    <Button
                        className={`common-btn ms-3 col-lg-1 col-md-4 p-2 mt-5 ${styles[`button`]
                            }`}
                        onClick={() => {
                            handleModalOpen(1, null);
                        }}
                    >
                        Add Contact<br></br>
                        Category
                    </Button>
                </div>
                <div className={`row mx-auto mt-3`} style={{ width: "90%" }}>
                    <div className="settings-grid">
                        <Grid
                            resizable={true}
                            reorderable={true}
                            data={orderBy(data, sort)}
                            sortable={true}
                            sort={sort}
                            onSortChange={(e) => {
                                setSort(e.sort);
                            }}
                            rowHeight={40}
                            onItemChange={itemChange}
                            editField={editField}
                            scrollable="scrollable"
                            dataItemKey={"contactCategoryId"}
                        >
                            <Column
                                field="symbol"
                                title=" "
                                cell={SignatureCell}
                                width="80"
                            />
                            <Column field="categoryName" title="Name" />
                            <Column field="categoryDetails" title="Details" />
                            <Column field="labelForRef1" title="Reference 1" />
                            <Column field="labelForRef2" title="Reference 2" />
                            <Column field="labelForRef3" title="Reference 3" />
                            <Column
                                field="active"
                                cell={ActiveCell}
                                title="Active"
                                editor="boolean"
                                width="80"
                            />
                            <Column cell={CommandCell} width="80" />
                        </Grid>
                    </div>
                </div>
            </>
            {modal && (
                <ContactLinkTypeModal
                    cancelEdit={() => {
                        getItems();
                        setModal(false);
                    }}
                    onClose={() => {
                        getItems();
                        setModal(false);
                    }}
                    refreshData={() => getItems()}
                    data={modalData}
                />
            )}
        </>
    );
};
export default ContactLinkDataGrid;
