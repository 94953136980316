import {
  START_LOADING,
  STOP_LOADING,
  ERROR,
  SUCCESS,
  RESPONSE_ERROR_LOGIN,
  ADD_PROFIT_COST_TEMPLATE,
  GET_PROFIT_COST_TEMPLATE,
  EDIT_FEE_HEADER,
  GET_FEE_HEADER_ITEMS,
  CLEAR_FEE_HEADER_ITEM,
  GET_ACTIVE_FEE_HEADERS,
  GET_ACTIVE_FEE_ITEMS,
  EMPTY_ACTIVE_FEE_ITEMS,
} from "../types/index";
import * as api from "../api/index.js";
import { handleApiError } from "Helper";
import { toast } from "react-toastify";

export const getProfitCostDetails = (page, limit) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const datas = await api.getProfitCostDetails();
    dispatch({ type: GET_PROFIT_COST_TEMPLATE, payload: datas.data });
    dispatch({ type: STOP_LOADING });
    dispatch({ type: SUCCESS, payload: datas.message });
  } catch (error) {
    handleApiError(error);
  }
};

export const addProfitCostTemplate = (data) => async (dispatch) => {
  try {
    const datas = await api.addProfitCostTemplate(data);
    dispatch({ type: ADD_PROFIT_COST_TEMPLATE, payload: datas });
  } catch (error) {
    handleApiError(error);
  }
};

export const editFeeHeader = (id, data) => async (dispatch) => {
  try {
    const datas = await api.editFeeHeader(id, data);
    toast.success(`Template ${data?.name} has been updated`);
    dispatch({ type: EDIT_FEE_HEADER, payload: datas });
  } catch (error) {
    toast.error(`Error occured while updating`);
    handleApiError(error);
  }
};

export const createFixedFeeHeaderItems = (id, data) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const datas = await api.createFixedFeeHeaderItems(id, data);
    dispatch({ type: ADD_PROFIT_COST_TEMPLATE, payload: datas.data });
    dispatch({ type: STOP_LOADING });
    dispatch({ type: SUCCESS, payload: datas.message });
  } catch (error) {
    handleApiError(error);
  }
};

export const getFeeHeaderItems = (id) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const datas = await api.getFeeHeaderItems(id);
    dispatch({ type: GET_FEE_HEADER_ITEMS, payload: datas.data });
    dispatch({ type: STOP_LOADING });
    dispatch({ type: SUCCESS, payload: datas.message });
  } catch (error) {
    handleApiError(error);
  }
};

export const editFeeHeaderItems = (id, data) => async (dispatch) => {
  try {
    const datas = await api.editFeeHeaderItems(id, data);
  } catch (error) {
    handleApiError(error);
  }
};

export const clearFeeHeaderItem = () => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_FEE_HEADER_ITEM });
  } catch (error) {
    handleApiError(error);
  }
};

export const getActiveFixedFeeHeaders = () => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const datas = await api.getActiveFeeHeaders();
    dispatch({ type: GET_ACTIVE_FEE_HEADERS, payload: datas.data });
    dispatch({ type: STOP_LOADING });
    dispatch({ type: SUCCESS, payload: datas.message });
  } catch (error) {
    handleApiError(error);
  }
};

export const getActiveFeeItems = (id) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const datas = await api.getActiveFeeItems(id);
    dispatch({ type: GET_ACTIVE_FEE_ITEMS, payload: datas.data });
    dispatch({ type: STOP_LOADING });
    dispatch({ type: SUCCESS, payload: datas.message });
  } catch (error) {
    handleApiError(error);
  }
};

export const clearActiveFeeItems = () => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    dispatch({ type: EMPTY_ACTIVE_FEE_ITEMS });
    dispatch({ type: STOP_LOADING });
    dispatch({ type: SUCCESS });
  } catch (error) {
    handleApiError(error);
  }
};
