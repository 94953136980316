const ICE_CANDIDATE_IP_INDEX = 4;


const deviceIpData = {
    deviceIpString: '',
    deviceIpTimeStamp: '',
};

export const getDeviceLocalIPAsString = () => {
    return new Promise((resolve, reject) => {
        if (deviceIpData.deviceIpString !== '' && deviceIpData.deviceIpTimeStamp !== '') {
            resolve(deviceIpData);
        }
        const WebRTCConnection = RTCPeerConnection;
        if (!WebRTCConnection) {
            reject({ message: 'WEBRTC_UNSUPPORTED_BROWSER', error: undefined });
        }
        //RTCPeerConection is supported, so will try to find the IP
        const ip = [];
        let pc;
        try {
            pc = new WebRTCConnection();
        } catch (err) {
            reject({ message: 'WEBRTC_CONSTRUCTION_FAILED', error: err });
        }

        pc.onicecandidate = (event) => {
            if (!event || !event.candidate) {
                pc.close();
                if (ip.length < 1) {
                    reject({ message: 'NO_IP_FOUND', error: undefined });
                }
                deviceIpData.deviceIpString = ip.join(',');
                deviceIpData.deviceIpTimeStamp = new Date().toISOString();
                resolve(deviceIpData);
            } else if (event.candidate.candidate) {
                const candidateValues = event.candidate.candidate.split(' ');
                if (candidateValues.length > ICE_CANDIDATE_IP_INDEX) {
                    ip.push(candidateValues[ICE_CANDIDATE_IP_INDEX]);
                }
            }
        };
        pc.createDataChannel('');
        pc.createOffer()
            .then(pc.setLocalDescription.bind(pc))
            .catch((err) => {
                reject({ message: 'CREATE_CONNECTION_ERROR', error: err });
            });
    });
};


export const getBrowserPluginsAsString = () => {
    return Array.from(navigator.plugins, (plugin) => plugin && plugin.name)
        .filter((name) => name)
        .join(',');
};

const getFormattedOffset = () => {
    const offset = new Date().toString().split('GMT')[1];
    const hourOffset = `${offset[0]}${offset[1]}${offset[2]}`;
    const minuteOffset = `${offset[3]}${offset[4]}`;
    const formattedUTC = `${hourOffset}:${minuteOffset}`;
    return formattedUTC;
};


export const getTimezone = () => `UTC${getFormattedOffset()}`;

const validateAndGetScreenDetail = (value) => {
    if (isNaN(value)) {
        return null;
    } else {
        return value;
    }
};


export const getScreenWidth = () => validateAndGetScreenDetail(window.screen.width);

export const getScreenHeight = () => validateAndGetScreenDetail(window.screen.height);

export const getScreenScalingFactor = () => validateAndGetScreenDetail(window.devicePixelRatio);

export const getScreenColourDepth = () => validateAndGetScreenDetail(window.screen.colorDepth);

export const getWindowWidth = () => validateAndGetScreenDetail(window.innerWidth);

export const getWindowHeight = () => validateAndGetScreenDetail(window.innerHeight);


export const getBrowserDoNotTrackStatus = () => {
    const windowVar = window,
        navigatorVar = navigator;
    const isBrowserDoNotTrack =
        (windowVar.doNotTrack && windowVar.doNotTrack === '1') ||
        (navigatorVar.doNotTrack && (navigatorVar.doNotTrack === 'yes' || navigatorVar.doNotTrack === '1')) ||
        (navigatorVar.msDoNotTrack && navigatorVar.msDoNotTrack === '1') ||
        (windowVar.external &&
            windowVar.external.msTrackingProtectionEnabled &&
            windowVar.external.msTrackingProtectionEnabled());
    return isBrowserDoNotTrack ? 'true' : 'false';
};

export const getUserAgent = () => {
    return navigator.userAgent;
};
