import * as React from "react";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { ClientURL } from "api";
import { useOidcAccessToken } from "@axa-fr/react-oidc";
import Icon from "components/Global/icon";
import { useState } from "react";

const textField = "ContactName";
const keyField = "CustomerID";
const emptyItem = {
  [textField]: "loading ...",
  CustomerID: 0,
};
const pageSize = 10;
const loadingData = [];
while (loadingData.length < pageSize) {
  loadingData.push({
    ...emptyItem,
  });
}
const baseUrl = ClientURL;

const ClientComboBox = (props) => {
  const { accessToken } = useOidcAccessToken();
  const init = {
    method: "GET",
    accept: "application/json",
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  };
  const dataCaching = React.useRef([]);
  const pendingRequest = React.useRef();
  const requestStarted = React.useRef(false);
  const [data, setData] = React.useState([]);
  const [value, setValue] = React.useState(null);
  const [error, setError] = useState(false);
  const [errorData, setErrorData] = useState([]);
  const [total, setTotal] = React.useState(0);
  const [filter, setFilter] = React.useState("");
  const skipRef = React.useRef(0);
  const resetCach = () => {
    dataCaching.current.length = 0;
  };
  const requestData = React.useCallback((skip, filter) => {
    if (requestStarted.current) {
      clearTimeout(pendingRequest.current);
      pendingRequest.current = setTimeout(() => {
        requestData(skip, filter);
      }, 50);
      return;
    }
    const search =
      filter !== undefined && filter !== "" ? `search=${filter}` : "";
    const url =
      baseUrl +
      `${search}&page=${
        skip + 1
      }&limit=${pageSize}&active=true&orderby=clientRef&fields=clientid,clientRef,clientName,branchName`;
    requestStarted.current = true;
    fetch(url, init)
      .then((response) => {
        if (response.ok) return response.json();
        return Promise.reject(response);
      })
      .then((json) => {
        const total = 10;
        const items = [];
        json.data.forEach((element, index) => {
          const { clientid, clientName, branchName, clientRef } = element;
          const item = {
            [keyField]: clientid,
            [textField]: `${clientRef} - ${clientName}`,
            CustomerRef: clientRef,
            branchName: branchName,
          };
          items.push(item);
          dataCaching.current[index + skip] = item;
        });
        if (skip === skipRef.current) {
          setData(items);
          // setTotal(total);
        }
        requestStarted.current = false;
      })
      .catch((error) => {
        setData([]);
        setError(true);
      });
  }, []);
  React.useEffect(() => {
    requestData(0, filter);
    return () => {
      resetCach();
    };
  }, [filter, requestData]);
  const onFilterChange = React.useCallback((event) => {
    const filter = event.filter.value;
    resetCach();
    requestData(0, filter);
    setData(loadingData);
    skipRef.current = 0;
    setFilter(filter);
  }, []);

  const shouldRequestData = React.useCallback((skip) => {
    for (let i = 0; i < pageSize; i++) {
      if (!dataCaching.current[skip + i]) {
        return true;
      }
    }
    return false;
  }, []);

  const getCachedData = React.useCallback((skip) => {
    const data = [];
    for (let i = 0; i < pageSize; i++) {
      data.push(
        dataCaching.current[i + skip] || {
          ...emptyItem,
        }
      );
    }
    return data;
  }, []);
  const pageChange = React.useCallback(
    (event) => {
      const newSkip = event.page.skip;
      if (isNaN(newSkip)) {
        return;
      }
      if (shouldRequestData(newSkip)) {
        requestData(newSkip, filter);
      }
      const data = getCachedData(newSkip);
      setData(data);
      skipRef.current = newSkip;
    },
    [getCachedData, requestData, shouldRequestData, filter]
  );

  const onChange = React.useCallback((event) => {
    const value = event.target.value;

    if (value && value[textField] === emptyItem[textField]) {
      return;
    }
    setValue(value);
    props.onChangeClient(value);
  }, []);

  React.useEffect(() => {
    if (props.val) {
      onFilterChange({
        filter: {
          value: props.val?.ContactName,
        },
      });
      setValue(props.val);
    }
    if (props.val && data.length === 0) {
      setErrorData([props.val]);
    }
  }, [props.val]);
  return (
    <div>
      <ComboBox
        iconClassName="wa"
        icon={<Icon icon="arrow-down" />}
        data={error ? errorData : data}
        required
        value={value}
        onChange={onChange}
        dataItemKey={keyField}
        textField={textField}
        filterable={true}
        disabled={props.disabled}
        onFilterChange={onFilterChange}
        virtual={{
          pageSize: pageSize,
          skip: skipRef.current,
          total: total,
        }}
        onPageChange={pageChange}
        placeholder="Select Client"
        style={{
          width: props.width || "326px",
          maxWidth: "400px",
          height: "32px",
          boxShadow: props.boxShadow && "0px 1px 9px rgba(0, 0, 0, 0.12)",
        }}
      />
    </div>
  );
};

export default ClientComboBox;
