import React from "react";
import { DropDownList } from "@progress/kendo-react-dropdowns";

const DropdownCell = ({ dataItem, field = "", dataSource = [], onChange }) => {
  const localizedData = [
    { text: "Folder", value: 0 },
    { text: "Document", value: "Document" },
    { text: "User Screen", value: "UserScreen" },
    { text: "Interview", value: "Interview" },
  ];
  const handleChange = (event) => {
    const selectedValue = event.target.value.value;
    const value =
      typeof selectedValue === "object" ? [selectedValue] : selectedValue;

    onChange({
      dataIndex: 0,
      dataItem,
      field: field,
      syntheticEvent: event.syntheticEvent,
      value: value,
    });
  };

  const dataValue = dataItem[field] === null ? "" : dataItem[field];
  if (dataSource.length > 0) {
    const dropdownData = dataSource.map((item) => ({
      text: item.name,
      value: item,
    }));


    return (
      <React.Fragment>
          <DropDownList
            onChange={handleChange}
            data={dropdownData}
            textField="text"
            value={
              dropdownData.find(
                (item) =>
                  item.value?.name ===
                  (dataValue && dataValue[0] && dataValue[0]?.name)
              ) || { text: "Select User Screen", value: 0 }
            }
          />
      </React.Fragment>
    );
  }

  return (
    <td>
      {dataItem.adding ? (
        <DropDownList
          onChange={handleChange}
          value={
            localizedData.find((c) => c.value === dataValue) || localizedData[0]
          }
          data={localizedData}
          textField="text"
        />
      ) : (
        <p className="gridBoldField m-0">
          {dataValue === 0 || !dataValue
            ? "Folder"
            : dataValue === "UserScreen"
            ? "User Screen"
            : dataValue}{" "}
        </p>
      )}
    </td>
  );
};

export default DropdownCell;
