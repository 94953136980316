import useWindowEventHandlers from 'hooks/useEventDispatcher';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const EventListenerComponent = () => {

    const { registerHandler } = useWindowEventHandlers();
    const navigate = useNavigate();

    useEffect(() => {

        // Register window event handlers

        registerHandler('OpenClients', () => {
            navigate('/clients');
        });

        registerHandler('OpenMatters', () => {
            navigate('/matters');
        });

        registerHandler('OpenDashboard', () => {
            navigate('/dashboard');
        });

        registerHandler('OpenOnboarding', () => {
            navigate('/clients/onboarding');
        });
        

    }, [registerHandler, navigate]);

    return null;
};

export default EventListenerComponent;