import React from "react";
import { useOidc } from "@axa-fr/react-oidc";
import logo from "assets/logo.png";
import { useDispatch } from "react-redux";
import { loggedIn } from "actions/autologin.action";

export const Login = () => {
    const { login, isAuthenticated } = useOidc();
    const dispatch = useDispatch();

    return (
        <div className="">
            <div className="centerCard card-container w-50">
                <div className="login">
                    <img
                        src={logo}
                        alt="logo"
                        className={"k-icon"}
                        style={{ width: "auto", height: 60, marginTop: 5 }}
                    />
                    <div className="border-top"></div>
                    <h5 className="font-weight-400">Access the Application</h5>
                    {!isAuthenticated && (
                        <p>
                            <button
                                type="button"
                                className="common-btn font-14"
                                onClick={() => {
                                    login(window.location.pathname);
                                    dispatch(loggedIn());
                                }}
                            >
                                Login
                            </button>
                        </p>
                    )}
                </div>
            </div>
        </div>
    );
};
