import * as React from "react";
import TimeList from "./TimeList";
import EditTime from "./EditTime";
import { getTimeType } from "actions/timeType.action";
import EventType from "components/Setting/EventType";

const TimeTypes = (props) => {
  return (
    <EventType
      open={props.open}
      close={props.close}
      apiAddFunc="addTimeType"
      actionFunc={getTimeType}
      successMessage="Time Type Added"
      errorMessage="Error Occured"
      buttonLabel="Time Types"
      listComponent={TimeList}
      editFormComponent={EditTime}
    />
  );
};

export default TimeTypes;
