import * as React from "react";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { VatsLoader } from "./vat-loader.js";
import { Button } from "@progress/kendo-react-buttons";
import { editVatinfo } from "actions/vat.action.js";
import { useDispatch } from "react-redux";
import EditVat from "./Editvat.jsx";
import { ActiveCell, SignatureCell, EditCommandCell } from "../GridComponents.jsx";



const Vatlist = () => {
    const [openForm, setOpenForm] = React.useState(false);
    const dispatch = useDispatch();
    const [editItem, setEditItem] = React.useState({
        ProductID: 1,
    });
    const enterEdit = (item) => {
        setOpenForm(true);
        setEditItem(item);
    };
    const handleSubmit = (event) => {
        let data = {
            vatCodeId: event.vatCodeId,
            details: event.details,
            rate: event.rate,
            includeInVatReturn: event.includeInVatReturn,
        };
        dispatch(editVatinfo(event.vatCodeId, data));
        setOpenForm(false);
    };
    const [products, setProducts] = React.useState({
        data: [],
        total: 20,
    });
    const [dataState, setDataState] = React.useState({
        take: 10,
        skip: 0,
    });

    const handleCancelEdit = () => {
        setOpenForm(false);
    };
    const MyEditCommandCell = (props) => (
        <EditCommandCell {...props} enterEdit={enterEdit} />
    );
    const dataStateChange = (e) => {
        setDataState(e.dataState);
    };

    const dataReceived = (products) => {
        let items = []
        products.data.map((item) => {
            item.symbol = item.details[0]
            item.active = item.includeInVatReturn
            items.push(item)
        })
        setProducts(items);
    };

    return (
        <div className="settings-grid">
            <Grid
                {...dataState}
                data={products}
                onDataStateChange={dataStateChange}
            >
                <Column cell={SignatureCell} title=" " field="symbol" width="80" />
                <Column field="vatCodeId" title="VAT Code" className="text-center" />
                <Column field="details" title="Details" />
                <Column field="rate" title="Rate" className="text-right" />
                <Column cell={ActiveCell} title="Active" width="80" />
                <Column title="Edit" cell={MyEditCommandCell} width="80" />
            </Grid>
            {openForm && (
                <EditVat
                    cancelEdit={handleCancelEdit}
                    onSubmit={handleSubmit}
                    item={editItem}
                />
            )}

            <VatsLoader dataState={dataState} onDataReceived={dataReceived} />
        </div>
    );
};

export default Vatlist;
