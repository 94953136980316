import {
  START_LOADING,
  STOP_LOADING,
  SUCCESS,
  GET_SOURCE,
  EDIT_SOURCE,
  GET_ACTIVE_SOURCE,
} from "../types/index";
import * as api from "../api/index.js";
import { handleApiError } from "Helper";
import { toast } from "react-toastify";

export const getSources = (page, limit) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const datas = await api.getSources(page, limit);
    dispatch({ type: GET_SOURCE, payload: datas.data });
    dispatch({ type: STOP_LOADING });
    dispatch({ type: SUCCESS, payload: datas.message });
  } catch (error) {
    handleApiError(error);
  }
};

export const editSource = (id, data) => async (dispatch) => {
  try {
    const datas = await api.editSource(id, data);
    toast.success(`Source '${data?.sourceName}' has been updated`);
    dispatch({ type: EDIT_SOURCE, payload: datas });
  } catch (error) {
    toast.error("Department cant be updated");
    handleApiError(error);
  }
};

export const getActiveSources = (page, limit) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const datas = await api.getActiveSources();
    dispatch({ type: GET_ACTIVE_SOURCE, payload: datas.data });
    dispatch({ type: STOP_LOADING });
    dispatch({ type: SUCCESS, payload: datas.message });
  } catch (error) {
    handleApiError(error);
  }
};
