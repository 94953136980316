import * as React from "react";
import * as ReactDOM from "react-dom";
import { useState } from "react";
import styles from "../CaseFile/Casefile.module.scss";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import DefaultProfile from "../../assets/profile-icon-transparent.png";
import rightArrow from "../../assets/right-arrow.png";
import leftArrow from "../../assets/left-arrow.png";
import { FormComboBox } from "components/Matters/index";
import { filterBy } from "@progress/kendo-data-query";
import { CONSTANT } from "CONSTANT/Matter/AttachContact";
import { Tile } from "components/UIComponents/Tile";
import { ImageBadgeComponent } from "components/UIComponents/Badges";
import { Button } from "@progress/kendo-react-buttons";
import SearchTextBox from "components/SearchComponent";
import {
  getActiveContactCategories,
  getSuggestedContactCategories,
} from "api/index";
import { RedirectCell, CustomButton } from "components/common/GridComponents";
import CorrespondencePage from "./CorrespondencePage";
import { HandleGridBody } from "components/common/HandleGridBody";

const AttachContactPage = (props) => {
  const [comboBoxState, setComboBoxState] = useState({
    [CONSTANT.COMBOBOX.CATEGORY]: "",
  });
  const [contactCategories, setContactCategories] = useState();
  const [suggestedCategories, setSuggestedCategories] = useState();
  const [selectedSuggestion, setSelectedSuggestion] = useState("all");
  const [selectAll, setSelectAll] = useState("all");
  const [categoryId, setCategoryId] = useState();

  const [contactList, setContactList] = useState({ data: [], total: 0 });
  const [contactCategoriesFilter, setContactCategoriesFilter] = useState();
  const [fixedScroll, setFixedScroll] = useState(true);
  const [showNextPage, setShowNextPage] = useState(false);
  const [contactId, setContactId] = useState(null);
  const [contactType, setContactType] = useState(null);
  const [contactName, setContactName] = useState(null);

  const [dataState, setDataState] = React.useState({
    take: 50,
    skip: 0,
    sort: [
      {
        field: "name",
        dir: "asc",
      },
    ],
  });

  const createState = (skip, take) => {
    let pagerSettings = {
      pageSizes: [10, 25, 50, 100],
    };
    return {
      pageSize: take,
      pageable: pagerSettings,
    };
  };

  const [state, setState] = React.useState(createState(0, 50));
  const [searchValue, setSearchValue] = useState(null);
  const [isSearched, setIsSearched] = React.useState(false);
  const searchRef = React.useRef();
  const [startIndex, setStartIndex] = useState(0); // State to track the index of the first visible category
  const threshold = 7;

  React.useEffect(() => {
    getContactCategory();
    getSuggestedCategory();
  }, []);

  const dataStateChange = (e) => {
    if (
      e.dataState.sort !== undefined &&
      e.dataState.skip !== 0 &&
      dataState.sort !== e.dataState.sort
    ) {
      setDataState({ ...e.dataState, skip: 0 });
    } else if (e.dataState?.sort?.[0] === undefined) {
      setDataState({
        ...e.dataState,
        sort: [
          {
            field: dataState?.sort?.[0].field,
            dir: "asc",
          },
        ],
      });
    } else {
      setDataState(e.dataState);
    }
  };

  const dataReceived = (contactList) => {
    if (contactList.data) {
      setContactList(contactList);
    }
  };


  const handleSelection = async (event) => {
    if (event === "all") {
      setSelectedSuggestion("all");
    } else {
      setSelectAll(event?.value?.contactCategoryId );
      setCategoryId(
        event?.contactCategoryId || event?.value?.contactCategoryId
      );
      setSelectedSuggestion(event?.contactCategoryId );
    }
    setComboBoxState({
      ...comboBoxState,
      [CONSTANT.COMBOBOX.CATEGORY]: event.value,
    });
  };

  const filterCategory = (filter) => {
    const data = contactCategories.slice();
    return filterBy(data, filter);
  };
  const categoryFilterChange = (event) => {
    setContactCategoriesFilter(filterCategory(event.filter));
  };

  const getContactCategory = async () => {
    const { data } = await getActiveContactCategories();
    setContactCategories(data?.data);
    setContactCategoriesFilter(data.data?.slice());
  };

  const getSuggestedCategory = async () => {
    const { data } = await getSuggestedContactCategories(props.matterId);
    setSuggestedCategories(data?.data);
  };

  const Redirect = (props) => (
    <RedirectCell
      // handleClick={() => onMatterRefClickActions(props)}
      title="View Contact"
    />
  );

  const handleCategorySelect = (data) => {
    setContactId(data?.contactId);
    setContactType(data?.type);
    setContactName(data);
    setShowNextPage(true);
  };

  const toggleNextPage = () => {
    setShowNextPage(false);
  };

  const handleLeftArrowClick = () => {
    if (startIndex > 0) {
      setStartIndex(startIndex - 1);
    }
  };

  const handleRightArrowClick = () => {
    if (startIndex + threshold < suggestedCategories.length) {
      setStartIndex(startIndex + 1);
    }
  };

  const customButton = (e) => (
    <CustomButton
      handleClick={() => handleCategorySelect(e.dataItem)}
      text="Select"
    />
  );

  return (
    <Dialog
      className={styles["matter-form-dialog"]}
      title={"Attach Contact"}
      onClose={props.toggleDialog}
      width="65vw"
    >
      {!showNextPage && (
        <React.Fragment>
          <div
            style={{
              overflow: "hidden",
              height: "calc(100vh - 220px)",
            }}
          >
            <Tile heading="Select Category" className="px-4 pb-1 mx-5 my-2">
              {
                <div>
                  <div className="d-flex align-items-start justify-content-start mb-2 ">
                    {startIndex > 0 && (
                      <div
                        className={styles["attach-contact-arrow-container"]}
                        onClick={handleLeftArrowClick}
                      >
                        <img src={leftArrow} />
                      </div>
                    )}

                    <div key={"all"} onClick={() => handleSelection("all")}>
                      <ImageBadgeComponent
                        text={"All"}
                        className={
                          ((selectAll || selectedSuggestion === "all") 
                          ? "selected"
                          : "badge-container") 
                        }
                        // src={DefaultProfile}
                      />
                    </div>

                    {suggestedCategories &&
                      suggestedCategories
                        ?.slice(startIndex, startIndex + threshold)
                        .map((category) => (
                          <div
                            key={category?.contactCategoryId}
                            onClick={() => handleSelection(category)}
                          >
                            <ImageBadgeComponent
                              text={category?.categoryName}
                              className={
                                selectedSuggestion ===
                                category?.contactCategoryId
                                  ? "selected"
                                  : "badge-container"
                              }
                              src={DefaultProfile}
                            />
                          </div>
                        ))}

                    {startIndex + threshold < suggestedCategories?.length && (
                      <div
                        className={styles["attach-contact-arrow-container"]}
                        onClick={handleRightArrowClick}
                      >
                        <img src={rightArrow} />
                      </div>
                    )}
                  </div>
                </div>
              }
            </Tile>

            <Tile
              className="px-4 pt-0 pb-2 mx-5 mb-3"
              height={"calc(100vh - 350px)"}
              heading={
                <div className="d-flex align-items-center justify-content-between">
                  <SearchTextBox
                    ref={searchRef}
                    changeFilterValue={(e) => {
                      setIsSearched(true);
                      setSearchValue(e);
                    }}
                  />

                  {(selectedSuggestion == "all" || selectAll) && (
                    <FormComboBox
                      required={true}
                      isStyled={true}
                      wf={true}
                      width={"206px"}
                      //  name={CONSTANT.COMBOBOX.CATEGORY}
                      textField={"categoryName"}
                      state={comboBoxState}
                      data={contactCategoriesFilter}
                      val={comboBoxState[CONSTANT.COMBOBOX.CATEGORY]}
                      onChangeComboBox={handleSelection}
                      onFilterChange={categoryFilterChange}
                      className={"mx-2"}
                    />
                  )}
                </div>
              }
              headingItems={
                <>
                  <Button
                    className={`button-small p-3`}
                    // onClick={() => {
                    //   handleModalOpen(1, null);
                    // }}
                  >
                    Add New Contact
                  </Button>
                </>
              }
            >
              <Grid
                {...dataState}
                data={contactList}
                sortable={true}
                pageable={state.pageable}
                pageSize={state.pageSize}
                resizable={true}
                onDataStateChange={dataStateChange}
                className="grid-smallfont"
                fixedScroll={fixedScroll}
                style={{
                  height: "calc(100vh - 425px)",
                }}
              >
                <GridColumn
                  headerClassName="ps-4"
                  className="gridBoldField ps-4"
                  field="name"
                  title="Name "
                  width="120"
                />
                <GridColumn field="type" title="Type" width="80" />
                <GridColumn field="phone" title="Phone" width="80" />
                <GridColumn field="email" title="Email" width="80" />
                <GridColumn
                  field="contactCategory"
                  title="Category"
                  width="80"
                />
                <GridColumn field="fullAddress" title="Address" width="180" />
                <GridColumn width="30" cell={Redirect} />
                <GridColumn width="75" cell={customButton} />
              </Grid>
            </Tile>

            {categoryId && (
              <HandleGridBody
                id={categoryId}
                categoryState={comboBoxState}
                dataState={dataState}
                onDataReceived={dataReceived}
                currentComponent="attachContact"
                searchValue={(isSearched && searchValue) || null}
                isSearched={isSearched}
              />
            )}
            {selectedSuggestion === "all" && (
              <HandleGridBody
                type={"attachContact"}
                dataState={dataState}
                onDataReceived={dataReceived}
                currentComponent="contacts-attach"
                searchValue={(isSearched && searchValue) || null}
                isSearched={isSearched}
                // searchValue={filterValue}
              />
            )}
          </div>

          <div className="dialog-footer justify-content-start py-1 align-items-end">
            <Button
              className="common-btn my-2 bgBtnSecondary border-0 ms-5"
              onClick={props.toggleDialog}
            >
              Cancel
            </Button>
          </div>
        </React.Fragment>
      )}
      {showNextPage && (
  
          <React.Fragment>
            <CorrespondencePage
              contactsData={props?.contactsData}
              type={contactType}
              matterId={props.matterId}
              togglePage={toggleNextPage}
              contactId={contactId}
              contactName={contactName}
              toggleDialog={props.toggleDialog}
            />
          </React.Fragment>
        ) 
        }
    </Dialog>
  );
};

export default AttachContactPage;
