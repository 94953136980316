import React from "react";
import styles from "./index.module.scss";
import BranchIcon from "../../assets/DashboardIcons/branch.png";
import TeamIcon from "../../assets/DashboardIcons/team.png";
import FeeEarnerIcon from "../../assets/DashboardIcons/feeEarnerIcon.png";
import { formatDate } from "@progress/kendo-intl";
import MonthIcon from "../../assets/DashboardIcons/month.png";

export const DashboardCard = (props) => {
  return (
    <div
      className={`${styles["dashboard-card"]} ${props.className || ""}`}
      style={{ ...props.style }}
    >
      {props.children}
    </div>
  );
};

export const ComboBoxLabel = (props) => {
  switch (props.name) {
    case "Branch":
      return (
        <div className="d-flex align-items-center gap-2 mb-1">
          <div className={styles["combo-label-icon"]}>
            <img src={BranchIcon} alt="BranchIcon" />
          </div>
          <div className={styles["combo-label-font"]}>Branch</div>
        </div>
      );
    case "Team":
      return (
        <div className="d-flex align-items-center gap-2 mb-1">
          <div className={styles["combo-label-icon"]}>
            <img src={TeamIcon} alt="TeamIcon" />
          </div>
          <div className={styles["combo-label-font"]}>Team</div>
        </div>
      );
    case "Feeearner":
      return (
        <div className="d-flex align-items-center gap-2 mb-1">
          <div className={styles["combo-label-icon"]}>
            <img src={FeeEarnerIcon} alt="FeeEarnerIcon" />
          </div>
          <div className={styles["combo-label-font"]}>Fee Earner</div>
        </div>
      );
    case "Date Range":
      return (
        <div className="d-flex align-items-center gap-2 mb-1">
          <div className={styles["combo-label-icon"]}>
            <img src={MonthIcon} alt="MonthIcon" />
          </div>
          <div className={styles["combo-label-font"]}>Date Range</div>
        </div>
      );

    default:
      break;
  }
};

export const DashboardMatterItem = (props) => {
  const matter = props.item;
  return (
    <div className={`container fs-sm ${styles["matter-item"]} pt-2 my-2 `}>
      {/* <ImageContainer className="d-flex align-items-center justify-content-center">
                  <img className="m-auto" src={DefaultProfile} alt={DefaultProfile} />
                </ImageContainer> */}

      <div className="row">
        <div className="col fw-bold">
          <span
            className="cursor-pointer"
            onClick={() => props.onMatterRefClickActions(props.item)}
          >
            {matter.matterRef}
          </span>
          <span className="ps-2">
            <u
              onClick={() => props.onClientRefClickActions(props.item)}
              className="cursor-pointer"
            >
              {matter.clientName}{" "}
            </u>
          </span>
        </div>

        <div className="col justify-content-end text-end">
          {matter.appCode && (
            <>
              <span className="fw-bold">App: </span>
              <span> {matter.appCode} </span>
            </>
          )}

          <span className="fw-bold ps-3">Date Opened: </span>
          <span> {formatDate(new Date(matter.startDate), "dd/mm/yy")}</span>
        </div>
      </div>

      <div className="row mt-1">
        <div className="col">{TextTruncater(matter.details, 300)}</div>
      </div>
    </div>
  );
};

export const TextTruncater = (text, limit) => {
  if (text?.length > limit) return text.substring(1, limit) + "...";
  return text;
};

export const ChartHeader = ({ name, type, icon, color, duration }) => {
  return (
    <div className="d-flex align-items-center pt-2 mx-3">
      <div
        className={`${styles.iconContainer} me-3`}
        style={{
          backgroundColor: color,
        }}
      >
        <img src={icon} alt={icon} width={20} height={20} />
      </div>

      <div>
        <div>
          <span
            className={`${styles["small-text"]}`}
            style={{ color: "#707070" }}
          >
            {type}
          </span>
        </div>
        <div className={`fw-semibold ${styles["combo-label-font"]}`}>
          {name}
        </div>
      </div>

      <div className={`ms-auto ${styles[`chart-legend`]}`}>
        {duration || "This Week"}
      </div>
    </div>
  );
};

export const ChartFooter = ({ param1, param2 }) => {
  return (
    <div className="d-flex justify-content-between mx-4 mb-3">
      <div
        className={`d-flex gap-3 align-items-center ${styles[`chart-legend`]}`}
      >
        {param1}
        <div className={`${styles["param-1"]}`}></div>
      </div>

      <div
        className={`d-flex gap-3 align-items-center  ${styles[`chart-legend`]}`}
      >
        {param2}
        <div className={`${styles["param-2"]}`}></div>
      </div>
    </div>
  );
};
