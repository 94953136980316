import * as React from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Popup } from "@progress/kendo-react-popup";
import { Badge } from "@progress/kendo-react-indicators";
import { IoDocumentsOutline } from "react-icons/io5";
import { ProgressBar } from "@progress/kendo-react-progressbars";
import { RiUpload2Fill } from "react-icons/ri";
import { AiFillDelete } from "react-icons/ai";
import { useState } from "react";
import { getFileById } from "api";
import { setMainLoading } from "actions/common.action";
import ConfirmationDialog from "components/common/ConfirmationDialog";
import styles from "./Posting.module.scss";
import DocumentViewer from "components/common/DocumentViewer";
import { Overlay } from "components/Overlay";
import { toast } from "react-toastify";
import excelIcon from "../../assets/fileIcons/Excel.svg";
import wordIcon from "../../assets/fileIcons/Word.svg";
import emailIcon from "../../assets/fileIcons/Email.svg";
import pptIcon from "../../assets/fileIcons/PowerPoint.svg";
import otherFormat from "../../assets/fileIcons/Document.svg";
import pdfIcon from "../../assets/fileIcons/pdf.svg";
import { CrossIcon } from "components/tabs/TabTitle";

const powerPointExt = ["ppt", "pptx"];
const wordExt = ["doc", "docx"];
const excelExt = ["xls", "xlsx"];
const emailExt = ["msg", "eml"];

export const DocumentsUpload = (props) => {
    const [showProgress, setShowProgress] = useState(false);
    const [show, setShow] = React.useState(false);
    const [closeDialog, setCloseDialog] = React.useState(false);
    const [currentCloseItem, setCurrentCloseItem] = React.useState("");
    const [postingTypeError, setPostingTypeError] = React.useState(false);
    const pdfViewerRef = React.useRef();
    const confirmatinDiloagRef = React.useRef();
    const [data, setData] = useState([]);
    const [showDocViewer, setShowDocViewer] = useState(false);
    const anchor = React.useRef();
    const menuRef = React.useRef();
    const fileInputRef = React.useRef();

    const dispatch = useDispatch();

    const CloseDialog = () => {
        return (
            <ConfirmationDialog
                // ref={confirmatinDiloagRef}
                onClickYes={() => {
                    onFileRemove(currentCloseItem.fileID);
                }}
                onClickNo={() => setCloseDialog(false)}
                onClose={() => setCloseDialog(false)}
            />
        );
    };
    const handleDocumentButoon = (e) => {
        if (props.disabled) return;
        if (
            !props?.value &&
            [
                "All Postings",
                "Client Postings",
                "Chit Request",
                "Matter Activity",
            ].includes(props.type)
        )
            return setPostingTypeError(true);
        setShow((prev) => !prev);
    };

    const userdata = useSelector((state) => {
        return state.currentuser.userlist;
    });

    const changeHandler = async (e) => {
        try {
            setShowProgress(true);
            await props?.onUpload(e);
            if (props.type === "ID-CHECK") {
                setShow(false);
            }
            setShowProgress(false);
            // dispatch(addFiles(props.type , e.))
            e.target.value = null;
        } catch (error) {
            toast.error("Error Occured while uploading file");
            if (props.onError) props.onError();
            setShowProgress(false);
        }
    };

    const onFileRemove = (fileId) => {
        setCloseDialog(false);
        props?.onFileRemove(fileId);
    };

    const handleClose = (item) => {
        setCloseDialog(true);
        setCurrentCloseItem(item);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (anchor?.current && anchor?.current?.contains(event.target)) {
                return;
            }
            if (
                pdfViewerRef?.current &&
                pdfViewerRef?.current?.contains(event.target)
            ) {
                return;
            }
            if (
                confirmatinDiloagRef?.current &&
                confirmatinDiloagRef?.current?.contains?.(event.target)
            ) {
                return;
            }
            //pop container ref for closing documnet upload pop up when click outside it
            if (menuRef?.current && !menuRef?.current?.contains?.(event.target)) {
                setShow(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside, true);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside, true);
        };
    }, []);

    useEffect(() => {
        if (props?.value) return setPostingTypeError(false);
    }, [props?.value]);

    const renderPdfView = async (fileID) => {
        if (!fileID) return toast("File id is rquired");
        try {
            dispatch(setMainLoading(true));
            const { data } = await getFileById(fileID);
            setData([data.data]);
            setShowDocViewer(true);
            dispatch(setMainLoading(false));
        } catch (error) {
            dispatch(setMainLoading(false));
        }
    };

    const getIcon = (file) => {
        const ext = file?.split(".").pop();
        if (powerPointExt.includes(ext)) return pptIcon;
        else if (excelExt.includes(ext)) return excelIcon;
        else if (wordExt.includes(ext)) return wordIcon;
        else if (emailExt.includes(ext)) return emailIcon;
        else if (ext === "pdf") return pdfIcon;
        else return otherFormat;
    };

    return (
        <React.Fragment>
            {closeDialog && (
                <div ref={confirmatinDiloagRef}>
                    <CloseDialog />
                </div>
            )}
            {showDocViewer && (
                <DocumentViewer
                    upload={true}
                    data={data}
                    onClose={() => setShowDocViewer(false)}
                />
            )}
            <div
                className="d-flex align-items-center flex-column"
                style={{
                    opacity: props.disabled && 0.5,
                }}
            >
                {props.icon ? (
                    <img
                        onClick={(e) => handleDocumentButoon(e)}
                        className={`d-flex align-items-center cursor-pointer`}
                        // style={{ width: props.width ? props.width : "" }}
                        ref={anchor}
                        src={props.icon}
                        alt="Icon"
                    ></img>
                ) : (
                    <div
                        onClick={(e) => handleDocumentButoon(e)}
                        className={`d-flex align-items-center cursor-pointer`}
                        style={{ width: props.width ? props.width : "" }}
                        ref={anchor}
                    >
                        <div className="mt-3">
                            {props.isView ? (
                                <p className={styles["doc-upload-text"]}>Change</p>
                            ) : (
                                <p className={styles["doc-upload-text"]}>
                                    Attach
                                    <br /> Documents
                                </p>
                            )}
                        </div>
                        <div className={styles["vertical-rule"]}></div>
                        <div
                            className={`${styles["file-upload-icon"]} d-flex align-items-center justify-content-center position-relative`}
                        >
                            <RiUpload2Fill color="#DCDAFF" size={20} />
                            {!!props?.filesData.length && (
                                <Badge className={`${styles["count-badge"]}`} rounded="full">
                                    <span>{props?.filesData.length}</span>
                                </Badge>
                            )}
                        </div>
                    </div>
                )}

                {postingTypeError && (
                    <small className="text-danger text-sm text-center">
                        Please Select Posting type
                    </small>
                )}
            </div>

            <div className="content">
                <Popup
                    show={show}
                    popupClass={"doc_popup"}
                    animate={false}
                    anchor={anchor.current}
                    style={{ zIndex: 10000000 }}
                    anchorAlign={{ horizontal: "left", vertical: "top" }}
                    popupAlign={{ horizontal: "center", vertical: "bottom" }}
                >
                    <div className="" ref={menuRef}>
                        <div
                            className={`${styles["document-header"]} d-flex align-items-center justify-space-between`}
                        >
                            <div className="text-align-center ms-3">
                                <span className="fs-md">Documents</span>
                            </div>
                            <div
                                className="text-align-center cursor-pointer me-3"
                                onClick={() => setShow(false)}
                            >
                                <CrossIcon />
                            </div>
                        </div>
                        <div
                            style={{
                                height: "200px",
                                overflowY: "auto",
                                overflowX: "hidden",
                                borderRadius: "10px",
                            }}
                        >
                            {props?.filesData[0] !== undefined ? (
                                props?.filesData.map((item) => {
                                    return (
                                        <div
                                            className="row mt-2 border-bottom border-gray p-2 cursor-pointer"
                                            key={item.filename}
                                            onClick={() => renderPdfView(item.fileID)}
                                        >
                                            <div className="col-1">
                                                <img
                                                    width={25}
                                                    height={25}
                                                    src={getIcon(item.filename)}
                                                    alt="Icon"
                                                />
                                            </div>
                                            <div className={`${styles["file"]} col-9`}>
                                                <span className="d-block">{item.filename}</span>
                                                <span className="bt-value">
                                                    File is uploaded by {userdata?.displayname}
                                                </span>
                                            </div>
                                            <div className="col-2 d-flex justify-content-center">
                                                <DeleteContainer
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleClose(item);
                                                        setShow(false);
                                                    }}
                                                    className="ms-4 me-2"
                                                />
                                            </div>
                                        </div>
                                    );
                                })
                            ) : (
                                <div className="no-file-uploaded">
                                    <IoDocumentsOutline size={45} color="black" />
                                    <p className="p-alert">No Files to show</p>
                                </div>
                            )}
                            {showProgress && (
                                <ProgressBar
                                    value={props.progress}
                                    style={{
                                        height: "15px",
                                        fontWeight: "500",
                                    }}
                                    label={(labelProps) => {
                                        return (
                                            <strong>{Math.ceil(props.progress)}% Uploading</strong>
                                        );
                                    }}
                                    animation={{
                                        duration: 900,
                                    }}
                                />
                            )}
                        </div>

                        <div
                            className={`${styles[`doc-button-container`]
                                } mt-1 d-flex align-items-center justify-content-end`}
                        >
                            <button
                                className="common-btn bgBtnSecondary me-3"
                                onClick={() => setShow(false)}
                            >
                                Cancel
                            </button>
                            <label className="common-btn text-center me-3 " role="button">
                                <input
                                    name="file"
                                    type="file"
                                    onChange={changeHandler}
                                    ref={fileInputRef}
                                />
                                Upload
                            </label>
                        </div>
                        <div></div>
                    </div>
                </Popup>
            </div>
            {show && (
                <Overlay hideOverlay={() => { }}>
                    {/*Pass any data to the modal */}
                </Overlay>
            )}
        </React.Fragment>
    );
};

export const DeleteContainer = (props) => {
    return (
        <button
            type="button"
            className={`${styles["del-container"]} bg-light ${props.className}`}
            onClick={(e) => {
                if (props.onClick) props.onClick(e);
            }}
        >
            <span className={"d-flex justify-content-center"}>
                <AiFillDelete size={25} className="k-loader" />
            </span>
        </button>
    );
};
