import {
  START_LOADING,
  STOP_LOADING,
  ERROR,
  SUCCESS,
  RESET,
  GET_DOC_TYPE_LIST,
  EDIT_DOC_TYPE_LIST,
} from "../types/index.js";

const DocTypeReducer = (
  state = {
    IsError: false,
    isSuccess: false,
    isLoading: undefined,
  },
  action
) => {
  switch (action.type) {
    case START_LOADING:
      return { ...state, isLoading: true };
    case STOP_LOADING:
      return { ...state, isLoading: false };
    case GET_DOC_TYPE_LIST:
      return {
        ...state,
        docTypeList: action.payload,
      };
    case EDIT_DOC_TYPE_LIST:
      return {
        ...state,
        docTypeList: action.payload,
      };
    case ERROR:
      return { ...state, IsError: true, isSuccess: false };
    case SUCCESS:
      return { ...state, IsError: false, isSuccess: true };
    default:
      return state;
  }
};
export default DocTypeReducer;
