import React from "react";
import { Input } from "@progress/kendo-react-inputs";
import useMediaQuery from "hooks/useMediaQuery";
import { Loader } from "@progress/kendo-react-indicators";

const CustomInput = (props) => {
  const {
    width,
    className,
    style,
    textboxStyle,
    onBlurEvent,
    showLoader,
    ...others
  } = props;
  const matches = useMediaQuery("(min-width: 992px)");

  const textstyle = {
    width: props.width || "225px",
    marginRight: props.mr || "13px",
    height: "52px",
    maxWidth: !matches ? "400px" : "531px",
    ...textboxStyle,
  };

  return (
    <div
      style={{ ...style }}
      className={`${className ? className : ""}  d-flex flex-column `}
    >
      <Input
        maxLength={props.maxLength}
        style={{ ...textstyle }}
        labelClassName="label"
        placeholder={props.ph}
        onBlur={onBlurEvent}
        type="text"
        name={props.name}
        {...others}
      />
    </div>
  );
};

export default CustomInput;
