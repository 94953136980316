import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { useUploadForm } from "components/fileUploadHook";
import { setHeaderPhoto, updateUser } from "actions/currentuser.action";
import { addFiles } from "actions/files.action";
import EditForm from "./users/Editform";
import LoadingScreen from "components/common/Loading";
import { getProfilePhoto } from "actions/currentuser.action";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";

const Profile = (props) => {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { uploadForm } = useUploadForm();
  const [image, setimage] = useState(null);
  const navigate = useNavigate();
  const [editItem, setEditItem] = React.useState();
  const userdata = useSelector((state) => {
    return state.currentuser.userlist;
  });
  const dispatch = useDispatch();
  const [fileStatus, setFileStatus] = useState(null);
  const data = useSelector((state) => {
    return state.currentuser.userlist;
  });
  const picUpload = useSelector((state) => {
    return state.currentuser.files;
  });
  const dateFormatter = (dataToChange) => {
    let data = dataToChange;
    data.dateJoined =
      data.dateJoined !== null ? new Date(data.dateJoined) : null;
    data.dateQualified =
      data.dateQualified !== null ? new Date(data.dateQualified) : null;
    data.dateOfBirth =
      data.dateOfBirth !== null ? new Date(data.dateOfBirth) : null;
    data.dateLeft = data.dateLeft !== null ? new Date(data.dateLeft) : null;
    return data;
  };
  useEffect(() => {
    setEditItem(userdata);
    dispatch(getProfilePhoto(userdata.userImageDocumentId));
  }, [userdata]);
  const getData = async () => {
    try {
      if (userdata.userRef === "") {
        setIsLoading(true);
      } else {
        setIsLoading(false);
      }
    } catch (error) {}
  };

  const handleCancelEdit = () => {
    navigate("/settings");
  };
  const imageProfile = useSelector((state) => state.currentuser.image);
  function Submithandler(e) {
    var datasent = e;
    if (
      datasent.displayname === "" ||
      datasent.emailAddress === "" ||
      datasent.contactID === "" ||
      datasent.userRef === ""
    ) {
      setIsError(true);
    } else {
      setIsError(false);

      if (props.component === "Wizard") {
        if (
          datasent.displayname === data.displayname &&
          datasent.emailAddress === data.emailAddress &&
          datasent.contactID.toString() === data.contactID.toString()
        ) {
          props.moveNext();
        } else {
          dispatch(updateUser(data.userID, datasent));
          props.moveNext();
        }
      } else {
        if (fileStatus !== null) {
          datasent.userImageFileId = fileStatus.fileID;
          datasent.userImageDocumentId = fileStatus.fileID;
          datasent.downloadURL = fileStatus.downloadURL;
          datasent.imageInfo = {
            documentFile: {
              downloadURL: fileStatus.downloadURL,
            },
          };
          dispatch(setHeaderPhoto(fileStatus.downloadURL));
        }
        dispatch(updateUser(data.userID, datasent));
        // if (image !== null) {
        // }
      }
    }
    toast.success("Profile Updated");
  }
  useEffect(() => {
    getData();
  }, [userdata]);
  return (
    <div className="company">
      {!isLoading ? (
        <EditForm
          cancelEdit={handleCancelEdit}
          onSubmit={Submithandler}
          item={dateFormatter(userdata)}
          type={"profile"}
          image={imageProfile}
          setFileStatus={setFileStatus}
        />
      ) : (
        <LoadingScreen />
      )}
    </div>
  );
};
export default Profile;
