import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import "@progress/kendo-react-intl";
import { createposting, getfeeearner } from "actions/postings.action.js";
import "@progress/kendo-react-dropdowns";
import Modalposting from "../Postings/PostingModal.jsx";
import { getChitPostingtypes } from "actions/chitRequest.action.js";
import "react-router-dom";
import MattersCombobox from "../Postings/MattersComboBox.jsx";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { Checkbox, RadioGroup } from "@progress/kendo-react-inputs";
import { MultiSelect } from "@progress/kendo-react-dropdowns";
import { Label } from "@progress/kendo-react-labels";
import { HandleGridBody } from "components/common/HandleGridBody.js";
import { useState } from "react";
import {
  AmountFormatCell,
  DateFormatCell,
} from "components/common/formatter.js";
import { Button } from "@progress/kendo-react-buttons";
import BatchPostings from "pages/BatchPosting/index.jsx";
import { Dialog } from "@progress/kendo-react-dialogs";
import { CustomDateInput } from "components/common/CustomDateInput";
import CalenderCustomToggleButton from "components/common/CalenderCustomToogleButton.jsx";

const PostingStatus = [
  {
    label: "Unposted",
    value: "false",
  },
  {
    label: "Posted",
    value: "true",
  },
  {
    label: "All",
    value: "",
  },
];
const postingdata = [
  {
    code: "DUU",
    name: "Anticipated Disbursement",
  },
  {
    code: "DPU",
    name: "Paid Disbursement",
  },
  {
    code: "DIN",
    name: "Disbursement Invoice",
  },
  {
    code: "PIN",
    name: "Purchase Invoice",
  },
  {
    code: "OCR",
    name: "Office Credit",
  },
  {
    code: "ODR",
    name: "Office Debit",
  },
  {
    code: "CCR",
    name: "Client Credit",
  },
  {
    code: "CDR",
    name: "Client Debit",
  },
  {
    code: "DCR",
    name: "DDA Credit",
  },
  {
    code: "DDR",
    name: "DDA Debit",
  },
  {
    code: "BP",
    name: "Bank Payments",
  },
  {
    code: "BR",
    name: "Bank Receipts",
  },
  {
    code: "BT",
    name: "Bank Transfer",
  },
  {
    code: "CTO",
    name: "Client To Office",
  },
  {
    code: "OTC",
    name: "Office To Client",
  },
];
const ChitPosting = (props) => {
  const date = new Date();
  const dispatch = useDispatch();
  const [modalvalue, setmodalvalue] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [showBatchPosting, setShowBatchPosting] = useState(false);
  const [checkedList, setCheckedList] = useState([]);
  const [selectedList, setSelectedLisr] = useState([]);
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date()?.toISOString().split("T")[0]);
  const feeearnerdata = useSelector((state) => {
    return state.postingsdocs.feeearner.data;
  });
  const [matterref, setmatterref] = useState(null);
  const [list, setLists] = React.useState({
    data: [],
    total: 20,
  });
  const [filterParameters, setFilterParmeters] = useState({
    postingStatus: "false",
  });
  const [valueFrom, setValueFrom] = React.useState(false);
  const [valueTo, setValueTo] = React.useState(false);
  const [dataState, setDataState] = React.useState({
    take: 200,
    skip: 0,
    sort: [
      {
        field: "name",
        dir: "asc",
      },
    ],
  });
  const [valuePosting, setValuePosting] = React.useState([]);
  const [fromDateSelector, setFromDateSelector] = React.useState(date);
  const [postingStatus, setPostingStatus] = useState("false");

  const [postingTypes, setPostingTypes] = React.useState([
    { code: "0", name: "Loading" },
  ]);
  useEffect(() => {
    if (postingdata.length !== 1) {
      let list = [];
      postingdata.map((item) => {
        list.push(item.code);
      });
      setPostingTypes(list);
    }
  }, [postingdata]);
  const [toDateSelector, setToDateSelector] = React.useState(date);
  const { yearStartDate } = useSelector((state) => state.company.company);
  const onChangeFromDateSelect = (event) => {
    setFromDateSelector(event.value);
    let fromDate = null;
    let date = new Date(event.value);
    if (event.value) {
      fromDate = date?.toISOString().split("T")[0];
    }
    setFromDate(fromDate);
  };
  const onChangeToDateSelect = (event) => {
    setToDateSelector(event.value);
    let toDate = null;
    let date = new Date(event.value);
    if (event.value) {
      toDate = date?.toISOString().split("T")[0];
    }
    setToDate(toDate);
  };

  useEffect(() => {
    if (yearStartDate !== undefined) {
      let yearsToStartDate = new Date(yearStartDate);
      setFromDateSelector(yearsToStartDate);
      setFromDate(yearsToStartDate?.toISOString().split("T")[0]);
    }
  }, [yearStartDate]);

  useEffect(() => {}, []);

  const onChangePosting = (event) => {
    setValuePosting([...event.value]);
  };

  const createState = (skip, take) => {
    let pagerSettings = {
      pageSizes: [10, 25, 50, 100, 200],
    };
    return {
      pageSize: take,
      pageable: pagerSettings,
    };
  };
  const [state, setState] = React.useState(createState(0, 10));

  const changePostingStatus = (e) => {
    setPostingStatus(e.value);
  };
  const onChangematter = (event) => {
    if (event.target.value !== null && event.target.value !== undefined) {
      setmatterref(event?.target?.value?.matterId);
    } else {
      setmatterref(null);
    }
  };
  const dataStateChange = (e) => {
    if (
      e.dataState.sort !== undefined &&
      e.dataState.skip !== 0 &&
      dataState.sort !== e.dataState.sort
    ) {
      setDataState({ ...e.dataState, skip: 0 });
    } else if (e.dataState.sort[0] === undefined) {
      setDataState({
        ...e.dataState,
        sort: [
          {
            field: dataState.sort[0].field,
            dir: "asc",
          },
        ],
      });
    } else {
      setDataState(e.dataState);
    }
  };
  const PostCell = (props) => {
    return (
      <td>
        {props.dataItem.posted === false ? (
          <Checkbox
            style={{ marginLeft: "12px" }}
            type={"checkbox"}
            className="checkbox"
            checked={props.dataItem.select === true ? true : false}
            readOnly={true}
            onClick={() => {
              setLists((prevList) => {
                prevList.data.map((item) => {
                  if (item?.chitId === props.dataItem.chitId) {
                    item.select = !item.select;
                    if (item.select) {
                      setCheckedList((prevList) => [...prevList, item.chitId]);
                    } else if (checkedList.includes(item.chitId)) {
                      checkedList.splice(checkedList.indexOf(item.chitId), 1);
                      setCheckedList([...checkedList]);
                    }
                  }
                  return item;
                });
                return {
                  ...prevList,
                };
              });
            }}
          />
        ) : (
          ""
        )}
      </td>
    );
  };
  const filterParamatersBinding = () => {
    let parameters = {};
    if (matterref !== null) {
      parameters.matterId = matterref;
    }
    if (valuePosting.length !== 0) {
      parameters.postingTypes = valuePosting;
    }
    parameters.postingStatus = postingStatus;
    if (valueFrom) {
      parameters.fromDate = fromDate;
    }
    if (valueTo) {
      parameters.toDate = toDate;
    }
    setFilterParmeters(parameters);
  };

  useEffect(() => {
    filterParamatersBinding();
  }, [
    postingStatus,
    fromDateSelector,
    toDateSelector,
    valuePosting,
    matterref,
    valueFrom,
    valueTo,
  ]);

  useEffect(() => {
    dispatch(getChitPostingtypes());
    dispatch(getfeeearner());
  }, []);

  const addToBatch = () => {
    const data = list.data.filter((item) => item.select === true);
    data.map(async (item) => {
      item.postingCode = item.postingType;
      item.date = new Date(item.date);
      item.matterID = {
        matterRef: item.matterRef,
        matterId: item.matterID,
      };
      return item;
    });
    setSelectedLisr(data);
    if (props.component === "batchPosting") {
      props.onAddToBatch(data);
      props.onClose();
    } else {
      setShowBatchPosting(true);
    }
  };

  const dataReceived = (list) => {
    if (list.data) {
      list?.data?.map((item) => {
        
        item.select = false;
        item.postingField = postingdata?.filter(
          (item1) => item.postingType === item1.code
        )[0];
        item.postingType = item.postingField;
        if (
          item?.postingField?.code === "DCR" ||
          item?.postingField?.code === "DDR"
        ) {
          item.ddaBank = item.nominalInfo;
        }
        if (
          item?.postingField?.code === "CCR" ||
          item?.postingField?.code === "CDR"
        ) {
          item.clientBank = item.nominalInfo;
        }
        if (
          item?.postingField?.code === "OCR" ||
          item?.postingField?.code === "ODR"
        ) {
          item.officeBank = item.nominalInfo;
        }
        item.feeearner = feeearnerdata.filter(
          (item1) => item.feeEarnerRef === item1.userRef
        )[0];
        item.feeEarnerRef = item.feeearner;
        checkedList.map((item1) => {
          if (item.chitId === item1) {
            item.select = true;
          }
        });
      });

      if (props.component === "batchPosting") {
        const data = list.data.filter(
          (item) => item.postingField?.code === props.postingType
        );
        setLists({
          data: data,
          total: data.length,
        });
        return;
      }
      setLists(list);
    }
  };

  const handleRefresh = () => {
    setCheckedList([]);
    setRefresh(!refresh);
  };

  return (
    <>
      <div className="page fluid-container">
        {props.component !== "batchPosting" && (
          <div className="row">
            <div className="col-11 d-flex">
              <div className="d-flex flex-column mb-1">
                <Label className="mb-2 filter-font">Posting Type</Label>
                <MultiSelect
                  data={postingTypes}
                  onChange={onChangePosting}
                  value={valuePosting}
                  style={{ width: "250px" }}
                  size={"small"}
                />
              </div>

              <div className="d-flex flex-column ms-4 mb-1">
                <Label className="mb-2 filter-font">Matter</Label>
                <MattersCombobox
                  onChangematter={onChangematter}
                  width={"250px"}
                />
              </div>
              <div className="ms-4 mt-1">
                <div className="nominal-form-to-parent-row">
                  <div className="labels-containers">
                    <Label editorId={"dp"}>From</Label>
                    <div className="label-to">
                      <Label editorId="to">To</Label>
                    </div>
                  </div>
                </div>
                <div className="nominal-form-to-row">
                  <div
                    className="date-form-to-wrapper"
                    style={{ height: "40px" }}
                  >
                    <div className="from">
                      <div className="d-flex align-items-center justify-content-center">
                        <Checkbox
                          value={valueFrom}
                          className="ms-3 me-1"
                          onChange={(e) => {
                            setValueFrom(e.value);
                          }}
                          label={""}
                        />
                        <DatePicker
                          id="from"
                          className="date-picker"
                          value={fromDateSelector || null}
                          onChange={onChangeFromDateSelect}
                          dateInput={CustomDateInput}
                          toggleButton={CalenderCustomToggleButton}
                          defaultValue={fromDateSelector || null}
                          placeholder="Select to date"
                        />
                      </div>
                    </div>
                    <div className="divider"></div>
                    <div className="to">
                      <div className="d-flex align-items-center justify-content-center">
                        <Checkbox
                          value={valueTo}
                          className="ms-3 me-1"
                          onChange={(e) => {
                            setValueTo(e.value);
                          }}
                          label={""}
                        />
                        <DatePicker
                          id="to"
                          className="date-picker"
                          value={toDateSelector || null}
                          onChange={onChangeToDateSelect}
                          dateInput={CustomDateInput}
                          toggleButton={CalenderCustomToggleButton}
                          defaultValue={toDateSelector || null}
                          placeholder="Select to date"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="d-flex flex-column ms-4 radio">
                  <Label className="mb-2 filter-font">Type</Label>
                  <RadioGroup
                    data={PostingStatus}
                    onChange={changePostingStatus}
                    defaultValue={PostingStatus[0].value}
                    className="d-flex flex-row mt-1 gap-4"
                  />
                </div>
              </div>
              <div className="mt-4 ms-5">
                <Button className="common-btn" onClick={handleRefresh}>
                  <span className="d-flex align-items-center">
                    <span className="k-icon k-i-reload me-2"></span>
                    Refresh
                  </span>
                </Button>
              </div>
            </div>
          </div>
        )}
        <div className="row mt-3">
          <Grid
            {...dataState}
            data={list}
            onDataStateChange={dataStateChange}
            className={`fs-md`}
            style={{ maxHeight: "calc(100vh - 330px)" }}
            sortable={true}
            pageable={state.pageable}
            pageSize={state.pageSize}
          >
            <GridColumn field="postingType.code" title="Type" width={60} />
            <GridColumn field="matterRef" title="Matter" width="140px" />
            <GridColumn
              field="date"
              title="Date"
              cell={DateFormatCell}
              width="120px"
            />
            <GridColumn field="details" title="Details" />
            <GridColumn field="supplierRef" title="Supplier" />
            <GridColumn
              field="vatAmount"
              title="Vat"
              cell={AmountFormatCell}
              width="100px"
            />
            <GridColumn
              field="netAmount"
              title="Gross Amount"
              cell={AmountFormatCell}
              width="140px"
            />
            <GridColumn field="" title="" cell={PostCell} width="80px" />
          </Grid>
        </div>
        <div className="row mt-3 mb-3 d-flex">
          <Button
            className="float-end ms-auto me-3 common-btn"
            style={{ maxWidth: "130px" }}
            disabled={checkedList.length === 0}
            onClick={addToBatch}
          >
            Add to Batch
          </Button>
        </div>
        <HandleGridBody
          dataState={dataState}
          onDataReceived={dataReceived}
          currentComponent={
            props.component === "batchPosting" ? "batchPosting" : "chitPosting"
          }
          parameters={filterParameters}
          refresh={refresh}
        />
        <Modalposting
          value={modalvalue}
          reset={() => {
            //   resetfields();
          }}
          closeModal={() => {
            setmodalvalue(false);
          }}
          component={"Posting"}
        ></Modalposting>
      </div>
      {showBatchPosting && (
        <Dialog
          width={"1247px"}
          height={"80vh"}
          style={{ overflow: "hidden" }}
          title={`Batch Posting`}
          className="batch-posting-dialog"
          onClose={() => {
            setShowBatchPosting(false);
          }}
        >
          <div className="p-2" style={{ overflow: "hidden !important" }}>
            <BatchPostings
              type={"chitPosting"}
              selectedList={selectedList}
              onClose={() => {
                setShowBatchPosting(false);
                handleRefresh();
              }}
            />
          </div>
        </Dialog>
      )}
    </>
  );
};
export default ChitPosting;
