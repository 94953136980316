import * as React from "react";
import { useEffect } from "react";
import * as ReactDOM from "react-dom";
import { toODataString } from "@progress/kendo-data-query";
import { useDispatch, useSelector } from "react-redux";
import {
  getMattersList,
  getMattersPostings,
  getMyMattersPageList,
  fetchMatterActivities,
  fetchMatterTasks,
} from "../../actions/matters.action.js";
import { getNominalsList } from "../../actions/nominals.action.js";
import {
  getSuppliersList,
  getConsultantsList,
} from "../../actions/suppliers.action.js";
import { searchNominal } from "../../actions/nominals.action";
import { searchSupplier } from "../../actions/suppliers.action";
import { searchMatter } from "../../actions/matters.action";
import { getNominalRef } from "../../actions/postings.action";
import { getSupplierRef } from "../../actions/postings.action";
import { getClientsList, searchClient } from "../../actions/clients.action.js";
import {
  getContactsList,
  getContactsforCategory,
} from "../../actions/contacts.action.js";
import { getChitPostingList } from "../../actions/chitPosting.action.js";
import {
  getRecordedTimeForMatter,
  getRecordedTime,
} from "../../actions/time.action.js";

export const HandleGridBody = (props) => {
  const dispatch = useDispatch();
  const { mattersList } = useSelector((state) => state.mattersList);
  const { myMattersPageList } = useSelector((state) => state.mattersList);
  const { matterActivities } = useSelector((state) => state.mattersList);
  const { matterTasks } = useSelector((state) => state.mattersList);
  const { clientsList } = useSelector((state) => state.clients);
  const { nominalList } = useSelector((state) => state.nominalList);
  const { suppliersList } = useSelector((state) => state.suppliersList);
  const { consultantsList } = useSelector((state) => state.suppliersList);
  const { nominalRef } = useSelector((state) => state.postingsdocs);
  const { supplierRef } = useSelector((state) => state.postingsdocs);
  const { consultantRef } = useSelector((state) => state.postingsdocs);
  const { mattersPostings } = useSelector((state) => state.mattersList);
  const { contactsList } = useSelector((state) => state.contactsList);
  const { contactsByCategory } = useSelector((state) => state.contactsList);
  const { chitPostingList } = useSelector((state) => state.chitPosting);
  const { batchPostingList } = useSelector((state) => state.chitPosting);
  const { isUpdated } = useSelector((state) => state.suppliersList);
  const { isNominalUpdated } = useSelector((state) => state.nominalList);
  const { recordedTime } = useSelector((state) => state.time);
  const { MatterRecordedTime } = useSelector((state) => state.time);
  const { allContacts } = useSelector((state) => state.contactsList);

  const lastSuccess = React.useRef("");
  const pending = React.useRef("");
  const requestDataIfNeeded = () => {
    if (
      pending.current ||
      toODataString(props.dataState) === lastSuccess.current
    ) {
      return;
    }

    pending.current = toODataString(props.dataState);
    let skip = props.dataState.skip / props.dataState.take + 1;
    let take = props.dataState.take;

    if (props.currentComponent === "matters" && !props.searchValue) {
      if (props.dataState.sort !== undefined) {
        const item = props.dataState.sort[0];
        dispatch(
          getMattersList(skip, take, item, props.filterParameters, props.status)
        );
      } else {
        dispatch(
          getMattersList(skip, take, props.filterParameters, props.status)
        );
      }
    } else if (props.currentComponent === "myMattersPage") {
      if (props.dataState.sort !== undefined) {
        const item = props.dataState.sort[0];
        dispatch(
          getMyMattersPageList(skip, take, item, props.filterParameters)
        );
      } else {
        dispatch(
          getMyMattersPageList(skip, take, undefined, props.filterParameters)
        );
      }
    } else if (props.currentComponent === "matters" && props.searchValue) {
      dispatch(searchMatter(skip, take, props.searchValue));
    } else if (
      (props.currentComponent === "clients" && !props.isSearched) ||
      (props.currentComponent === "clients" && !props.searchValue)
    ) {
      if (props.dataState.sort !== undefined) {
        const item = props.dataState.sort[0];
        dispatch(getClientsList(skip, take, item, props.filterParameters));
      } else {
        dispatch(getClientsList(skip, take, undefined, props.filterParameters));
      }
    } else if (
      props.currentComponent === "clients" &&
      props.isSearched &&
      props.searchValue
    ) {
      if (props.dataState.sort !== undefined) {
        const item = props.dataState.sort[0];
        dispatch(
          searchClient(
            skip,
            take,
            props.searchValue,
            item,
            props.filterParameters
          )
        );
      } else {
        dispatch(
          searchClient(
            skip,
            take,
            props.searchValue,
            undefined,
            props.filterParameters
          )
        );
      }
    } else if (
      props.currentComponent === "contacts" ||
      props.currentComponent === "contacts-attach"
    ) {
      let item = props.dataState.sort ? props.dataState.sort[0] : undefined;
      dispatch(
        getContactsList(skip, take, item, props.searchValue, props?.type)
      );
    } else if (props.currentComponent === "attachContact") {
      if (props.dataState.sort !== undefined) {
        let item = props.dataState.sort[0];
        dispatch(
          getContactsforCategory(props.id, skip, take, props.searchValue, item)
        );
      } else {
        let item = undefined;
        dispatch(
          getContactsforCategory(props.id, skip, take, props.searchValue, item)
        );
      }
    }

    // timerecorded
    else if (props.currentComponent === "timeRecorded-Matter") {
      if (props.dataState.sort !== undefined) {
        let item = props.dataState.sort[0];
        dispatch(
          getRecordedTimeForMatter(
            props.matterId,
            props.status,
            props?.fD,
            props?.tD,
            skip,
            take,
            item
          )
        );
      } else {
        let item = undefined;
        dispatch(
          getRecordedTimeForMatter(
            props.matterId,
            props.status,
            props?.fD,
            props?.tD,
            skip,
            take,
            item
          )
        );
      }
    } else if (props.currentComponent === "timeRecorded") {
      if (props.dataState.sort !== undefined) {
        let item = props.dataState.sort[0];
        dispatch(
          getRecordedTime(
            props.feeEarner,
            props.status,
            props?.fD,
            props?.tD,
            skip,
            take,
            item
          )
        );
      } else {
        let item = undefined;
        dispatch(
          getRecordedTime(
            props.feeEarner,
            props.status,
            props?.fD,
            props?.tD,
            skip,
            take,
            item
          )
        );
      }
    } else if (props.currentComponent === "caseActivities") {
      if (props.dataState.sort !== undefined) {
        const item = props.dataState.sort[0];
        dispatch(
          fetchMatterActivities(
            props.matterId,
            props.feeEarner,
            props?.fD,
            props?.tD,
            props?.typeId,
            skip,
            take,
            item
          )
        );
      } else {
        dispatch(
          fetchMatterActivities(
            props.matterId,
            props.feeEarner,
            props?.fD,
            props?.tD,
            props?.typeId,
            skip,
            take
          )
        );
      }
    } else if (props.currentComponent === "casefile-tasks") {
      if (props.dataState.sort !== undefined) {
        const item = props.dataState.sort[0];
        dispatch(
          fetchMatterTasks(
            props.matterId,
            props.completed,
            props?.fD,
            props?.tD,
            skip,
            take,
            item
          )
        );
      } else {
        dispatch(
          fetchMatterTasks(
            props.matterId,
            props.completed,
            props?.fD,
            props?.tD,
            skip,
            take
          )
        );
      }
    } else if (
      props.currentComponent === "chitPosting" ||
      props.currentComponent === "batchPosting"
    ) {
      // if (props.dataState.sort !== undefined) {
      //   const item = props.dataState.sort[0];
      //   dispatch(getContactsList(skip, take, item));
      // } else {
      dispatch(
        getChitPostingList(skip, take, props.parameters, props.currentComponent)
      );
      // }
    } else if (props.currentComponent === "nominal" && !props.searchValue) {
      if (props.dataState.sort !== undefined) {
        const item = props.dataState.sort[0];
        dispatch(getNominalsList(skip, take, item));
      } else {
        dispatch(getNominalsList(skip, take));
      }
    } else if (props.currentComponent === "nominal" && props.searchValue) {
      dispatch(searchNominal(skip, take, props.searchValue));
    } else if (props.currentComponent === "suppliers" && !props.searchValue) {
      if (props.dataState.sort !== undefined) {
        const item = props.dataState.sort[0];
        dispatch(getSuppliersList(skip, take, item));
      } else {
        dispatch(getSuppliersList(skip, take));
      }
    } else if (props.currentComponent === "suppliers" && props.searchValue) {
      dispatch(searchSupplier(skip, take, props.searchValue));
    } else if (props.currentComponent === "consultants" && !props.searchValue) {
      if (props.dataState.sort !== undefined) {
        const item = props.dataState.sort[0];
        dispatch(getConsultantsList(skip, take, item));
      } else {
        dispatch(getConsultantsList(skip, take));
      }
    } else if (props.currentComponent === "consultants" && props.searchValue) {
      dispatch(searchSupplier(skip, take, props.searchValue));
    } else if (
      props.currentComponent === "nominalLedger" &&
      props.isSearched &&
      props.currentComponent === "nominalLedger" &&
      !props.searchValue
    ) {
      dispatch(
        getNominalRef(
          0,
          0,
          null,
          props.IncludeReversals,
          props.FromDate,
          props.ToDate
        )
      );
    } else if (
      props.currentComponent === "nominalLedger" &&
      props.isSearched &&
      props.searchValue
    ) {
      dispatch(
        getNominalRef(
          skip,
          take,
          props.searchValue,
          props.IncludeReversals,
          props.FromDate,
          props.ToDate
        )
      );
    } else if (
      (props.currentComponent === "supplierLedger" ||
        props.currentComponent === "consultantLedger") &&
      props.isSearched &&
      !props.searchValue
    ) {
      dispatch(
        getSupplierRef(
          0,
          0,
          null,
          props.IncludeReversals,
          props.FromDate,
          props.ToDate,
          props.type
        )
      );
    } else if (
      (props.currentComponent === "supplierLedger" ||
        props.currentComponent === "consultantLedger") &&
      props.isSearched &&
      props.searchValue
    ) {
      dispatch(
        getSupplierRef(
          skip,
          take,
          props.searchValue,
          props.IncludeReversals,
          props.FromDate,
          props.ToDate,
          props.type
        )
      );
    } else if (
      props.currentComponent === "mattersLedger" &&
      props.isSearched &&
      props.searchValue
    ) {
      dispatch(getMattersPostings(props.searchValue, skip, take));
    }
  };
  React.useEffect(() => {
    if (mattersList && props.currentComponent === "matters") {
      lastSuccess.current = pending.current;
      pending.current = "";
      if (
        toODataString(props.dataState) === lastSuccess.current ||
        props.isSearched
      ) {
        props.onDataReceived.call(undefined, {
          data: mattersList.data,
          total: mattersList.total,
        });
      } else {
        requestDataIfNeeded();
      }
    }
    requestDataIfNeeded();
  }, [
    mattersList,
    pending,
    props.dataState,
    props.filterParameters,
    props.status,
    props.refresh,
    lastSuccess,
    props.isSearched,
    props.searchValue,
  ]);

  React.useEffect(() => {
    if (myMattersPageList && props.currentComponent === "myMattersPage") {
      lastSuccess.current = pending.current;
      pending.current = "";
      if (
        toODataString(props.dataState) === lastSuccess.current ||
        props.isSearched
      ) {
        props.onDataReceived.call(undefined, {
          data: myMattersPageList?.data,
          total: myMattersPageList?.total,
        });
      } else {
        requestDataIfNeeded();
      }
    }
    if (
      !toODataString(props.dataState) === lastSuccess.current &&
      !props.isSearched
    ) {
      requestDataIfNeeded();
    }
  }, [
    myMattersPageList,
    props.refresh,
    props.filterParameters,
    pending,
    props.dataState,
    lastSuccess,
  ]);

  React.useEffect(() => {
    if (clientsList && props.currentComponent === "clients") {
      lastSuccess.current = pending.current;
      pending.current = "";
      if (
        toODataString(props.dataState) === lastSuccess.current ||
        props.isSearched
      ) {
        props.onDataReceived.call(undefined, {
          data: clientsList.data,
          total: clientsList.total,
        });
      } else {
        requestDataIfNeeded();
      }
    }
    requestDataIfNeeded();
  }, [
    clientsList,
    pending,
    props.dataState,
    lastSuccess,
    props.filterParameters,
    props.isRefreshed,
    props.isSearched,
    props.searchValue,
  ]);
  React.useEffect(() => {
    if (contactsList && props.currentComponent === "contacts") {
      lastSuccess.current = pending.current;
      pending.current = "";
      if (
        toODataString(props.dataState) === lastSuccess.current ||
        props.isSearched
      ) {
        props.onDataReceived.call(undefined, {
          data: contactsList.data,
          total: contactsList.total,
        });
      } else {
        requestDataIfNeeded();
      }
    }
    requestDataIfNeeded();
  }, [contactsList, pending, props.dataState, lastSuccess, props.searchValue]);
  // all contacts for attach contact
  React.useEffect(() => {
    if (allContacts && props.currentComponent === "contacts-attach") {
      lastSuccess.current = pending.current;
      pending.current = "";
      if (
        toODataString(props.dataState) === lastSuccess.current ||
        props.isSearched
      ) {
        props.onDataReceived.call(undefined, {
          data: allContacts.data,
          total: allContacts.total,
        });
      } else {
        requestDataIfNeeded();
      }
    }
    requestDataIfNeeded();
  }, [allContacts, pending, props.dataState, lastSuccess, props.searchValue]);

  React.useEffect(() => {
    if (contactsByCategory && props.currentComponent === "attachContact") {
      lastSuccess.current = pending.current;
      pending.current = "";
      if (
        toODataString(props.dataState) === lastSuccess.current ||
        props.isSearched
      ) {
        props.onDataReceived.call(undefined, {
          data: contactsByCategory?.data,
          total: contactsByCategory?.total,
        });
      } else {
        requestDataIfNeeded();
      }
    }
    requestDataIfNeeded();
  }, [
    contactsByCategory,
    props.id,
    pending,
    props.dataState,
    lastSuccess,
    props.searchValue,
    props.categoryState,
  ]);

  // time recorded

  React.useEffect(() => {
    if (props.currentComponent !== "timeRecorded-Matter") {
      return;
    }
    if (MatterRecordedTime) {
      lastSuccess.current = pending.current;
      pending.current = "";

      if (MatterRecordedTime[props?.matterId]) {
        props.onDataReceived.call(undefined, {
          data: MatterRecordedTime[props?.matterId]?.data,
          total: MatterRecordedTime[props?.matterId]?.total,
        });
      } else {
        requestDataIfNeeded();
      }
    }
    if (!MatterRecordedTime[props?.matterId] || props.updateFilter) {
      requestDataIfNeeded();
    }
  }, [
    MatterRecordedTime,
    props?.updateFilter,
    props?.matterId,
    pending,
    props.dataState,
    lastSuccess,
    props.refresh,
  ]);

  React.useEffect(() => {
    if (props.currentComponent !== "timeRecorded") {
      return;
    }
    if (recordedTime) {
      lastSuccess.current = pending.current;
      pending.current = "";
      if (
        toODataString(props.dataState) === lastSuccess.current ||
        props.isSearched
      ) {
        props.onDataReceived.call(undefined, {
          data: recordedTime?.data || recordedTime,
          total: recordedTime?.total,
        });
      } else {
        requestDataIfNeeded();
      }
    }
    requestDataIfNeeded();
  }, [
    recordedTime,
    props.status,
    props.fD,
    props.tD,
    props.feeEarner,
    pending,
    props.dataState,
    lastSuccess,
    props.refresh,
  ]);

  // case activities
  React.useEffect(() => {
    if (matterActivities && props.currentComponent === "caseActivities") {
      lastSuccess.current = pending.current;
      pending.current = "";
      if (matterActivities[props?.matterId]) {
        props.onDataReceived.call(undefined, {
          data: matterActivities[props?.matterId]?.data,
          total: matterActivities[props?.matterId]?.total,
        });
      } else {
        requestDataIfNeeded();
      }
    }
    if (!matterActivities[props?.matterId] || props.updateFilter) {
      requestDataIfNeeded();
    }
  }, [
    props?.updateFilter,
    matterActivities,
    props?.matterId,
    pending,
    props.dataState,
    lastSuccess,
    props.refresh,
    props.reload,
  ]);

  React.useEffect(() => {
    if (props.currentComponent !== "casefile-tasks") return;
    if (matterTasks) {
      lastSuccess.current = pending.current;
      pending.current = "";
      if (matterTasks[props?.matterId]) {
        props.onDataReceived.call(undefined, {
          data: matterTasks[props?.matterId]?.data,
          total: matterTasks[props?.matterId]?.total,
        });
      } else {
        requestDataIfNeeded();
      }
    }
    if (!matterTasks[props?.matterId] || props.updateFilter) {
      requestDataIfNeeded();
    }
  }, [
    props?.updateFilter,
    matterTasks,
    props?.matterId,
    pending,
    props.dataState,
    lastSuccess,
    props.refresh,
  ]);

  React.useEffect(() => {
    if (chitPostingList && props.currentComponent === "chitPosting") {
      lastSuccess.current = pending.current;
      pending.current = "";
      if (
        toODataString(props.dataState) === lastSuccess.current ||
        props.isSearched
      ) {
        props.onDataReceived.call(undefined, {
          data: chitPostingList.data,
          total: chitPostingList.total,
        });
      } else {
        requestDataIfNeeded();
      }
    }
    requestDataIfNeeded();
  }, [
    chitPostingList,
    pending,
    props.dataState,
    lastSuccess,
    props.parameters,
    props.refresh,
  ]);

  React.useEffect(() => {
    if (batchPostingList && props.currentComponent === "batchPosting") {
      lastSuccess.current = pending.current;
      pending.current = "";
      if (
        toODataString(props.dataState) === lastSuccess.current ||
        props.isSearched
      ) {
        props.onDataReceived.call(undefined, {
          data: batchPostingList.data,
          total: batchPostingList.total,
        });
      } else {
        requestDataIfNeeded();
      }
    }
    requestDataIfNeeded();
  }, [
    batchPostingList,
    pending,
    props.dataState,
    lastSuccess,
    props.parameters,
    props.refresh,
  ]);
  // nominal
  React.useEffect(() => {
    if (nominalList && props.currentComponent === "nominal") {
      lastSuccess.current = pending.current;
      pending.current = "";
      if (
        toODataString(props.dataState) === lastSuccess.current ||
        props.isSearched
      ) {
        props.onDataReceived.call(undefined, {
          data: nominalList.data,
          total: nominalList.total,
        });
      } else {
        requestDataIfNeeded();
      }
    }

    if (
      (!toODataString(props.dataState) === lastSuccess.current &&
        !props.isSearched) ||
      isNominalUpdated
    ) {
      requestDataIfNeeded();
    }
  }, [
    nominalList,
    pending,
    props.dataState,
    lastSuccess,
    props.isSearched,
    props.searchValue,
  ]);
  React.useEffect(() => {
    if (props.currentComponent !== "suppliers") {
      return;
    }
    if (suppliersList) {
      lastSuccess.current = pending.current;
      pending.current = "";

      if (
        toODataString(props.dataState) === lastSuccess.current ||
        props.isSearched
      ) {
        props.onDataReceived.call(undefined, {
          data: suppliersList.data,
          total: suppliersList.total,
        });
      } else {
        requestDataIfNeeded();
      }
    }
    if (
      (!toODataString(props.dataState) === lastSuccess.current &&
        !props.isSearched) ||
      isUpdated
    ) {
      requestDataIfNeeded();
    }
  }, [
    suppliersList,
    pending,
    props.dataState,
    lastSuccess,
    props.isSearched,
    props.searchValue,
  ]);

  React.useEffect(() => {
    if (props.currentComponent !== "consultants") {
      return;
    }
    if (consultantsList) {
      lastSuccess.current = pending.current;
      pending.current = "";
      if (
        toODataString(props.dataState) === lastSuccess.current ||
        props.isSearched
      ) {
        props.onDataReceived.call(undefined, {
          data: consultantsList.data,
          total: consultantsList.total,
        });
      } else {
        requestDataIfNeeded();
      }
    }
    if (
      (!toODataString(props.dataState) === lastSuccess.current &&
        !props.isSearched) ||
      isUpdated
    ) {
      requestDataIfNeeded();
    }
  }, [
    consultantsList,
    props.type,
    pending,
    props.dataState,
    lastSuccess,
    props.isSearched,
    props.searchValue,
  ]);

  React.useEffect(() => {
    if (nominalRef && props.currentComponent === "nominalLedger") {
      lastSuccess.current = pending.current;
      pending.current = "";

      if (
        toODataString(props.dataState) === lastSuccess.current ||
        props.isSearched
      ) {
        props.onDataReceived.call(undefined, {
          data: nominalRef.data,
          total: nominalRef.total,
        });
      } else {
        requestDataIfNeeded();
      }
    }
    if (
      !toODataString(props.dataState) === lastSuccess.current &&
      !props.isSearched
    ) {
      requestDataIfNeeded();
    }
  }, [
    nominalRef,
    pending,
    props.dataState,
    lastSuccess,
    props.isSearched,
    props.searchValue,
    props.IncludeReversals,
    props.isRefreshed,
  ]);
  React.useEffect(() => {
    if (props.currentComponent !== "supplierLedger") {
      return;
    }
    if (supplierRef) {
      lastSuccess.current = pending.current;
      pending.current = "";

      if (
        toODataString(props.dataState) === lastSuccess.current ||
        props.isSearched
      ) {
        props.onDataReceived.call(undefined, {
          data: supplierRef.data,
          total: supplierRef.total,
        });
      } else {
        requestDataIfNeeded();
      }
    }
    if (
      !toODataString(props.dataState) === lastSuccess.current &&
      !props.isSearched
    ) {
      requestDataIfNeeded();
    }
  }, [
    supplierRef,
    pending,
    props.dataState,
    lastSuccess,
    props.isSearched,
    props.searchValue,
    props.IncludeReversals,
    props.isRefreshed,
    props.type,
  ]);

  React.useEffect(() => {
    if (props.currentComponent !== "consultantLedger") {
      return;
    }
    if (consultantRef) {
      lastSuccess.current = pending.current;
      pending.current = "";

      if (
        toODataString(props.dataState) === lastSuccess.current ||
        props.isSearched
      ) {
        props.onDataReceived.call(undefined, {
          data: consultantRef.data,
          total: consultantRef.total,
        });
      } else {
        requestDataIfNeeded();
      }
    }
    if (
      !toODataString(props.dataState) === lastSuccess.current &&
      !props.isSearched
    ) {
      requestDataIfNeeded();
    }
  }, [
    consultantRef,
    pending,
    props.dataState,
    lastSuccess,
    props.isSearched,
    props.searchValue,
    props.IncludeReversals,
    props.isRefreshed,
    props.type,
  ]);

  React.useEffect(() => {
    if (mattersPostings && props.currentComponent === "mattersLedger") {
      lastSuccess.current = pending.current;
      pending.current = "";

      if (
        toODataString(props.dataState) === lastSuccess.current ||
        props.isSearched
      ) {
        props.onDataReceived.call(undefined, {
          data: mattersPostings.data,
          total: mattersPostings.total,
        });
      } else {
        requestDataIfNeeded();
      }
    }
    if (
      !toODataString(props.dataState) === lastSuccess.current &&
      !props.isSearched
    ) {
      requestDataIfNeeded();
    }
  }, [
    mattersPostings,
    pending,
    props.dataState,
    lastSuccess,
    props.isSearched,
    props.changed,
    props.searchValue,
    props.IncludeReversals,
    props.isRefreshed,
  ]);
  return pending.current ? <LoadingPanel /> : null;
};
const LoadingPanel = () => {
  const loadingPanel = (
    <div className="k-loading-mask">
      <span className="k-loading-text">Loading</span>
      <div className="k-loading-image" />
      <div className="k-loading-color" />
    </div>
  );
  const gridContent = document && document.querySelector(".k-grid-content");
  return gridContent
    ? ReactDOM.createPortal(loadingPanel, gridContent)
    : loadingPanel;
};
