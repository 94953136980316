import * as React from "react";
import { Input, Checkbox } from "@progress/kendo-react-inputs";
import EditForm from "../../../components/Setting/EditForm";

const EditTime = (props) => {
  return (
    <EditForm
      type={props.type}
      item={props.item}
      onSubmit={props.onSubmit}
      cancelEdit={props.cancelEdit}
      fields={[
        {
          name: "code",
          component: Input,
          label: "Code",
          validationMessage: "Code is required",
        },
        {
          name: "name",
          component: Input,
          label: "Time Type",
          validationMessage: "Time Type is required",
        },
        {
          name: "active",
          component: Checkbox,
          label: "Active",
          defaultChecked: true,
        },
      ]}
      formId="time-form"
      title="Time Type"
    />
  );
};

export default EditTime;
