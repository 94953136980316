import React from "react";
import {
  InputClearValue,
  InputSuffix,
  TextBox,
} from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";

const SearchTextBox = React.forwardRef((props, ref) => {
  const [localFilterValue, setLocalFilterValue] = React.useState("");

  React.useImperativeHandle(ref, () => ({
    clearSearch: () => {
      setLocalFilterValue("");
    },
  }));

  const handleFilterChange = (e) => {
    setLocalFilterValue(e.target.value);
  };

  const handleFilter = () => {
    props.changeFilterValue(localFilterValue);
  };

  const handleClear = () => {
    setLocalFilterValue("");
    props.changeFilterValue();
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleFilter();
    }
  };

  return (
    <div style={{ width: props.width || "280px" }}>
      <TextBox
        onKeyDown={handleKeyDown}
        value={localFilterValue}
        onChange={handleFilterChange}
        placeholder="Search here"
        suffix={() => (
          <>
            <InputClearValue
              className={`bg-light rounded-circle pe-1 ${
                localFilterValue ? "" : "d-none"
              }`}
              onClick={handleClear}
            >
              x
            </InputClearValue>

            <InputSuffix className="bgprimary">
              <Button
                style={{ width: "40px" }}
                className="h-100 search-button"
                onClick={handleFilter}
                fillMode={"flat"}
                rounded={null}
                icon="search"
              ></Button>
            </InputSuffix>
          </>
        )}
      />
    </div>
  );
});
export default SearchTextBox;
