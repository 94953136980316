import * as React from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import DownloadGif from "../../assets/download.gif";

const DoccIntelligenceModal = ({ data, closeModal, reset, downloadURL }) => {
  const images = require.context("../../assets/Logos", true);
  return (
    <div
      style={
        {
          // backdropFilter: "blur(1px)",
        }
      }
    >
      <Dialog
        height={220}
        width={400}
        style={{
          overflow: "hidden",
        }}
      >
        <div className={`d-flex`}>
          <div className="justifiy-content-center d-flex flex-column align-items-center vw-100">
            <img
              src={DownloadGif}
              style={{
                height: "80px",
                width: "80px",
                // margin: "auto",
                // marginTop: "-30px",
                // borderRadius: "20px",
                // overflow: "hidden",
              }}
            ></img>
            <div className="mt-3">
              <div className={`mx-auto mb-2 fs-sm mt-1`}>
                <b>Running Analysis</b>
              </div>
              <div
                className={`mx-auto mb-2 fs-sm text-center mt-1`}
                style={{ color: "gray" }}
              >
                <b>Please Wait</b>
              </div>
            </div>
            <div className="spinner-border spinner mt-1" role="status"></div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default DoccIntelligenceModal;
