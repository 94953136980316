import React from "react";
import styles from "../../pages/Clients/Clients.module.scss";
import { DateFormatCell } from "components/common/formatter";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { useNavigate } from "react-router";

const ClientMatters = (props) => {
  const createState = (skip, take) => {
    let pagerSettings = {
      pageSizes: [10, 25, 50, 100],
    };
    return {
      pageSize: take,
      pageable: pagerSettings,
    };
  };

  const navigate = useNavigate();
  const [state] = React.useState(createState(0, 10));

  const [dataState, setDataState] = React.useState({
    take: 100,
    skip: 0,
    sort: [
      {
        field: "matterRef",
        dir: "asc",
      },
    ],
  });

  const onMatterRefClickActions = (props) => {
    navigate(`/matters/${props.dataItem.matterId}`);
  };

  const MatterRefCell = (props) => {
    return (
      <td>
        <span
          style={{ cursor: "pointer" }}
          onClick={() => {
            onMatterRefClickActions(props);
          }}
        >
          <u>{props.dataItem.matterRef}</u>
        </span>
      </td>
    );
  };

  return (
    <>
      <div className={`container-fluid mt-3`}>
        <Grid
          {...dataState}
          data={props.list}
          className={`fs-md ${styles.mattersHeight}`}
          sortable={true}
          pageable={state.pageable}
          onDataStateChange={(e) => setDataState(e.dataState)}
          pageSize={state.pageSize}
          resizable={true}
        >
          <GridColumn
            field="matterRef"
            title="Matter Ref"
            width="140px"
            cell={MatterRefCell}
          />
          <GridColumn field="details" title="Details" width="300px" />
          <GridColumn field="clientName" title="Client Name" />
          <GridColumn field="feeEarnerRef" title="Fee Earner" width="120px" />
          <GridColumn field="supervisorRef" title="Supervisor" width="120px" />
          <GridColumn
            field="appCode"
            title="Application"
            width="120px"
            className="text-center"
          />
          <GridColumn field="branch" title="Branch" width="150px" />
          <GridColumn
            field="startDate"
            title="Date Opened"
            cell={DateFormatCell}
            width="130px"
          />

          <GridColumn field="status" title="Status" width="80px" />
        </Grid>
      </div>
    </>
  );
};

export default ClientMatters;
