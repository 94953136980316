import React, { useState, useEffect } from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import { Label } from "@progress/kendo-react-labels";
import { RadioGroup } from "@progress/kendo-react-inputs";
import { Input } from "@progress/kendo-react-inputs";
import { Editor, EditorTools } from "@progress/kendo-react-editor";
import { shareDocument, getSuggestedEmails } from "api";
import { fileIcon } from "components/common/fileIcon";
import styles from "../CaseFile/CaseWorkFlow/CaseWorkflow.module.scss";
import { handleApiError } from "Helper";
import Loader from "components/common/Loading";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import EmailListRenderer from "components/common/EmailComposition/EmailListRenderer";
import { ComboBox } from "@progress/kendo-react-dropdowns";
const {
  Bold,
  Italic,
  Underline,
  AlignLeft,
  AlignRight,
  AlignCenter,
  Indent,
  Outdent,
} = EditorTools;

const DocumentItem = ({ doc }) => {
  const getDisplayName = (name) => {
    if (!name) {
      return;
    }
    if (name.length <= 20) {
      return name;
    }
    const extension = name.includes(".") ? "." + name.split(".").pop() : "";
    return name?.substring(0, 20 - extension.length) + extension;
  };

  return (
    <div className={styles["document-item"]}>
      {fileIcon(doc.name)}
      <div>
        <span className="document-name-container ps-2 fs-sm ">
          {getDisplayName(doc?.name)}
        </span>
      </div>
    </div>
  );
};

const ShareDocumentModal = (props) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [shareType, setShareType] = useState("Attachment");
  const [selectedDocuments, setSelectedDocuments] = useState(
    props?.selectedDocuments
  );
  const [currentDoc, setCurrentDoc] = useState(props?.currentDoc);
  const [editorValue, setEditorValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [dialogVisible, setDialogVisible] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [Error, setError] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [suggestedEmails, setSuggestedEmails] = useState([]);

  const allFilesArePDFs =
    selectedDocuments?.length >= 1
      ? selectedDocuments.every((doc) => doc?.name?.endsWith(".pdf"))
      : currentDoc?.name?.endsWith(".pdf");

  const ShareType = [
    {
      label: "As Attachment",
      value: "Attachment",
    },
    ...(!allFilesArePDFs
      ? [
          {
            label: "As PDF Attachment",
            value: "AttachAsPDF",
          },
        ]
      : []),
    {
      label: "As File Link",
      value: "Link",
    },
  ];

  useEffect(() => {
    const fetchSuggestedEmails = async () => {
      try {
        const emails = await getSuggestedEmails(props.matterId);
        setSuggestedEmails(emails?.data?.data);
      } catch (error) {
        console.error("Failed to fetch suggested emails", error);
      } finally {
      }
    };

    fetchSuggestedEmails();
  }, []);
  const handleShareDocument = async () => {
    if (!email || !editorValue) {
      setError("Enter required fields");
      return;
    }
    setIsLoading(true);

    const data = {
      toRecipients: [...email?.email?.split(",")],
      message: editorValue,
      htmlBody: editorValue,
      isHtmlBody: true,
      linkExpiryDays: 0,
      documentIDs: [
        ...(selectedDocuments?.length > 0
          ? selectedDocuments.map((doc) => doc.id)
          : [currentDoc?.id]),
      ],
      shareType: shareType,
    };

    try {
      const response = await shareDocument(props.matterId, data);
      setDialogMessage("Document shared successfully!");
      setIsSuccess(true);
      // props.onClose();
    } catch (error) {
      handleApiError(error);
      setDialogMessage("An error occurred while sharing the document.");
      setIsSuccess(false);
    }
    setIsLoading(false);
    setDialogVisible(true);
  };

  const closeDialog = () => {
    setDialogVisible(false);
    setDialogMessage("");
    props.onClose();
  };

  const handleEmailChange = (event) => {
    setEmail(event.value);
  };

  const itemRender = (li, itemProps) => (
    <EmailListRenderer li={li} itemProps={itemProps} />
  );

  return (
    <>
      {!dialogVisible && (
        <Dialog title={"Share Document"} onClose={props.onClose} width={"40vw"}>
          <div className={`px-5 py-4`}>
            <div className="d-flex justify-content-between flex-wrap mb-2">
              {isLoading && <Loader />}

              <div className="d-flex flex-column mb-2">
                <Label className="fs-sm mb-1">Name</Label>
                <Input
                  placeholder={`Enter Name`}
                  className=" mb-2"
                  style={{
                    width: "300px",
                  }}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <Label className="fs-sm mb-1">Email</Label>
                <ComboBox
                  placeholder="Enter Email"
                  style={{
                    width: "100%",
                    maxWidth: "100%",
                  }}
                  data={suggestedEmails}
                  textField="email"
                  value={email}
                  onChange={handleEmailChange}
                  itemRender={itemRender}
                  allowCustom
                />
              </div>

              <div className="d-flex align-items-start mt-3 ">
                <RadioGroup
                  data={ShareType}
                  value={shareType}
                  required
                  onChange={(e) => setShareType(e.value)}
                />
              </div>
            </div>
            <div className="d-flex flex-wrap mt-2 mb-3">
              {selectedDocuments?.length > 0 ? (
                selectedDocuments?.map((doc, index) => (
                  <DocumentItem key={doc.id} doc={doc} />
                ))
              ) : currentDoc ? (
                <DocumentItem key={currentDoc.documentId} doc={currentDoc} />
              ) : null}
            </div>
            <span className="text-danger fs-sm mt-2">{Error}</span>

            <Editor
              tools={[
                [Bold, Italic, Underline],
                [AlignLeft, AlignCenter, AlignRight],
                [Indent, Outdent],
              ]}
              contentStyle={{
                height: 260,
              }}
              defaultContent={""}
              value={editorValue}
              onChange={(event) => {
                setEditorValue(event.html);
              }}
            />
          </div>

          <div className="dialog-footer">
            <Button
              className="common-btn me-4"
              type="submit"
              onClick={handleShareDocument}
            >
              Share
            </Button>
          </div>
        </Dialog>
      )}
      {dialogVisible && (
        <Dialog title={"Message"} onClose={closeDialog} width={"25vw"}>
          <div className="d-flex flex-column align-items-center p-4">
            {isSuccess ? (
              <FaCheckCircle size={32} color="green" />
            ) : (
              <FaTimesCircle size={32} color="red" />
            )}
            <p className="my-4">{dialogMessage}</p>
          </div>
          <div className="pe-4 dialog-footer w-100">
            <Button className="common-btn ms-auto" onClick={closeDialog}>
              Close
            </Button>
          </div>
        </Dialog>
      )}
    </>
  );
};

export default ShareDocumentModal;
