import * as React from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Input, Checkbox, NumericTextBox } from "@progress/kendo-react-inputs";
import { Label } from "@progress/kendo-react-labels";

const EditVat = (props) => {
  const ADD_NEW = props.type === "addNew";
  return (
    <Dialog
      title={ADD_NEW ? "Add new VAT" : `Edit ${props?.item?.details}`}
      onClose={props.cancelEdit}
      width={600}
    >
      <div className="p-3 ps-4">
        <Form
          onSubmit={props.onSubmit}
          initialValues={props.item}
          render={(formRenderProps) => (
            <FormElement>
              <fieldset className={"k-form-fieldset"}>
                {ADD_NEW && (
                  <div className="mb-2">
                    <Label className="label mt-1 mb-2  w-100">
                      VAT Code&nbsp;
                    </Label>
                    <Field
                      name={"vatCodeId"}
                      component={NumericTextBox}
                      spinners={false}
                    />
                  </div>
                )}
                <div className="mb-2">
                  <Label className="label mt-1 mb-2 w-100">Details&nbsp;</Label>
                  <Field name={"details"} component={Input} />
                </div>
                <div className="mb-3">
                  <Label className="label mt-1 mb-2 w-100">Rate %&nbsp;</Label>
                  <Field name={"rate"} component={NumericTextBox} format='n2' spinners={false} max={99} />
                </div>
                <div>
                  <Field
                    name={"includeInVatReturn"}
                    component={Checkbox}
                    defaultChecked={true}
                    label={"Active"}
                  />
                </div>
              </fieldset>
              <button
                style={{ visibility: "hidden" }}
                id="vat-form"
                type={"submit"}
              ></button>
            </FormElement>
          )}
        />
      </div>
      <div className="dialog-footer">
        <button
          className="bgBtnSecondary common-btn me-2"
          onClick={props.cancelEdit}
        >
          Cancel
        </button>
        <button
          className="common-btn me-3"
          onClick={() => document.getElementById("vat-form").click()}
          type={"submit"}
        >
          {ADD_NEW ? "Save" : "Update"}
        </button>
      </div>
    </Dialog>
  );
};

export default EditVat;
