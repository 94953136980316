import {
  START_LOADING,
  ERROR,
  ADD_DISBURSEMENT_MATTER_LIST,
  REMOVE_DISBURSEMENT_MATTER_LIST,
  GET_POSTING_DETAILS,
  GET_FEEEARNER,
  CREATED_POSTING,
  RESET_DATA,
  GET_NOMINALREF,
  GET_SUPPLIERREF,
  CLEAR_NOMINAL_JOURNAL_POSTING,
  ADD_OFFICE_CREDIT_MATTER_LIST,
  REMOVE_OFFICE_CREDIT_MATTER_LIST,
  GET_OFFICE_POSTING_DETAILS,
  GET_CLIENT_POSTING_DETAILS,
  ADD_PAY_SUPPLIER_LIST,
  REMOVE_SUPPLIER_LIST,
  GET_CONSULTANT_REF,
} from "../types/index";
import * as api from "../api/index.js";
import { handleApiError } from "Helper";

export const getPostingtypes = () => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const datas = await api.getPostingtypes();
    dispatch({ type: GET_POSTING_DETAILS, payload: datas.data });
  } catch (error) {
    handleApiError(error);
  }
};

export const getOfficePostingtypes = () => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const datas = await api.getOfficePostingtypes();
    dispatch({ type: GET_OFFICE_POSTING_DETAILS, payload: datas.data });
  } catch (error) {
    handleApiError(error);
  }
};
export const getClientPostingtypes = () => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const datas = await api.getClientPostingtypes();
    dispatch({ type: GET_CLIENT_POSTING_DETAILS, payload: datas.data });
  } catch (error) {
    handleApiError(error);
  }
};

export const resetdata = () => async (dispatch) => {
  dispatch({ type: RESET_DATA });
};

export const createposting = (data) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const datas = await api.createposting(data);
    dispatch({ type: CREATED_POSTING, payload: datas });
  } catch (error) {
    handleApiError(error);
  }
};

export const createJournalPosting = (data) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const datas = await api.createpostingJournal(data);
    dispatch({ type: CREATED_POSTING, payload: datas });
  } catch (error) {
    handleApiError(error);
  }
};

export const getfeeearner = () => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const { data } = await api.getfeeearner();
    dispatch({ type: GET_FEEEARNER, payload: data });
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const getNominalRef =
  (page, limit, nominalRef, IncludeReversals, FromDate, ToDate) =>
  async (dispatch) => {
    try {
      dispatch({ type: START_LOADING });
      const { data } = await api.getNominalRef(
        page,
        limit,
        nominalRef,
        IncludeReversals,
        FromDate,
        ToDate
      );
      dispatch({ type: GET_NOMINALREF, payload: data });
    } catch (error) {
      dispatch({ type: ERROR, payload: error });
    }
  };

export const getSupplierRef =
  (page, limit, supplierRef, IncludeReversals, FromDate, ToDate, type) =>
  async (dispatch) => {
    try {
      dispatch({ type: START_LOADING });
      const { data } = await api.getSupplierRef(
        page,
        limit,
        supplierRef,
        IncludeReversals,
        FromDate,
        ToDate,
        type
      );
      if (type) {
        dispatch({ type: GET_CONSULTANT_REF, payload: data });
      } else {
        dispatch({ type: GET_SUPPLIERREF, payload: data });
      }
    } catch (error) {
      handleApiError(error);
    }
  };

export const clearNominalFields = (value) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    dispatch({ type: CLEAR_NOMINAL_JOURNAL_POSTING, payload: value });
  } catch (error) {
    handleApiError(error);
  }
};

export const getDisbursementMatterList = (data) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const datas = await api.getDisbursementMatterList(data);
    dispatch({ type: ADD_DISBURSEMENT_MATTER_LIST, payload: datas.data.data });
  } catch (error) {
    handleApiError(error);
  }
};

export const removeDisbursementMatterList = () => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    dispatch({ type: REMOVE_DISBURSEMENT_MATTER_LIST });
  } catch (error) {
    handleApiError(error);
  }
};

export const getPaySupplierList = (data) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const datas = await api.getPaySupplierList(data);
    dispatch({ type: ADD_PAY_SUPPLIER_LIST, payload: datas.data.data });
  } catch (error) {
    handleApiError(error);
  }
};

export const removePaySupplierList = () => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    dispatch({ type: REMOVE_SUPPLIER_LIST });
  } catch (error) {
    handleApiError(error);
  }
};

export const getOfficeCreditMatterList = (data) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const datas = await api.getUnpaidMatterList(data);
    dispatch({ type: ADD_OFFICE_CREDIT_MATTER_LIST, payload: datas.data.data });
  } catch (error) {
    handleApiError(error);
  }
};

export const removeOfficeCreditMatterList = () => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    dispatch({ type: REMOVE_OFFICE_CREDIT_MATTER_LIST });
  } catch (error) {
    handleApiError(error);
  }
};
