import {
  START_LOADING,
  STOP_LOADING,
  ERROR,
  SUCCESS,
  GET_BRANCHES_NAMES,
  ADD_MATTER_TIME_BILL,
  CLEAR_MATTER_TIME,
  BILL_ERROR,
  CLEAR_BILL_ERROR,
  CREATE_BILL,
  ADD_MATTER_DISB_BILL,
  CLEAR_MATTER_DISB_TIME,
} from "../types/index.js";

const billReducer = (
  state = {
    IsError: false,
    isSuccess: false,
    isLoading: undefined,
    matterTimeRecords: [],
    matterDisbRecords: [],
    error: null,
  },
  action
) => {
  switch (action.type) {
    case START_LOADING:
      return { ...state, isLoading: true };
    case STOP_LOADING:
      return { ...state, isLoading: false };
    case ADD_MATTER_TIME_BILL:
      return { ...state, matterTimeRecords: action.payload };
    case CLEAR_MATTER_TIME:
      return { ...state, matterTimeRecords: [] };
    case ADD_MATTER_DISB_BILL:
      return { ...state, matterDisbRecords: action.payload };
    case CLEAR_MATTER_DISB_TIME:
      return { ...state, matterDisbRecords: [] };
    case BILL_ERROR:
      return {
        ...state,
        error: true,
      };
    case CREATE_BILL:
      return {
        ...state,
        error: false,
      };
    case CLEAR_BILL_ERROR:
      return {
        ...state,
        error: null,
      };
    case ERROR:
      return { ...state, IsError: true, isSuccess: false };
    case SUCCESS:
      return { ...state, IsError: false, isSuccess: true };
    default:
      return state;
  }
};
export default billReducer;
