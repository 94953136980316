import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "@progress/kendo-react-intl";
import "@progress/kendo-react-dropdowns";
import "react-router-dom";
import { useRef } from "react";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Label } from "@progress/kendo-react-labels";
import { Input, RadioGroup, TextArea } from "@progress/kendo-react-inputs";
import { Checkbox } from "@progress/kendo-react-inputs";
import { addSupplier, updateSupplier } from "actions/suppliers.action";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { filterBy } from "@progress/kendo-data-query";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { PageTabItemTitle } from "components/tabs/TabItem/PageTabItemTitle";
import { Button } from "@progress/kendo-react-buttons";
import { TransactionTypes } from "components/Enums/TransactionTypes.ts";
import { Form, Field } from "@progress/kendo-react-form";
import AddressInputComponent from "components/common/AddressInputComponent";
const data = [
  {
    label: "Disbursement Only",
    value: TransactionTypes.Disbursement,
  },
  {
    label: "Purchase Only",
    value: TransactionTypes.Purchase,
  },
  {
    label: "Both",
    value: TransactionTypes.All,
  },
];

const AddNewSupplier = (props) => {
  const dispatch = useDispatch();
  const [view, setView] = useState(props.viewType === "Add" ? "Add" : "View");
  const { viewSupplier } = useSelector((state) => state.suppliersList);
  const [selected, setSelected] = useState(0);
  const [tabs] = useState(["Details", "Defaults"]);
  const [error, setError] = useState(false);
  const [supplierId, setSupplierId] = useState();
  const [onHold, setOnHold] = useState(false);
  const [payWhenPaid, setPayWhenPaid] = useState(false);
  const [archived, setArchivedValue] = useState(false);
  const [supplierRef, setSupplierRef] = useState();
  const [supplierName, setSupplierName] = useState();
  const [type, setType] = useState();
  const [contactID, setContactID] = useState();
  const [vatCodeId, setVatCodeId] = useState(null);
  const [currencyCode, setCurrencyCode] = useState(null);
  const [nominalRef, setNominalRef] = useState();
  const [vatNumber, setVatNumber] = useState();
  const [creditLimit, setCreditLimit] = useState();
  const [termDays, setTermDays] = useState();
  const [notes, setNotes] = useState();
  const [payeeName, setPayeeName] = useState();
  const [transactionTypes, setTransactionTypes] = useState();
  const [balance, setBalance] = useState();
  const vatCodeIdRef = useRef();
  const [vatsdata, setvatsData] = useState();
  const [currencyData, setCurrencyData] = useState();
  const [address, setAddress] = useState({});

  const changeArchivedValue = (e) => {
    if (view === "View") {
      return;
    }
    setArchivedValue(!archived);
  };
  const changePayWhenPaid = (e) => {
    if (view === "View") {
      return;
    }
    setPayWhenPaid(!payWhenPaid);
  };
  const changeOnHold = (e) => {
    if (view === "View") {
      return;
    }
    setOnHold(!onHold);
  };
  const vatCodeReturner = (id) => {
    let index = {};
    activevatsdata.map((item) => {
      if (item.vatCodeId === id) {
        index = item;
      }
    });
    return index;
  };

  const currencyCodeReturner = (id) => {
    let index = {};
    activeCurrencyData.map((item) => {
      if (item.currencyCode === id) {
        index = item;
      }
    });
    return index;
  };

  const submitSupplier = (e) => {
    e.preventDefault();

    if (!supplierRef || !supplierName || !transactionTypes) {
      setError(true);
      return;
    }

    const payload = {
      supplierRef: supplierRef,
      supplierName: supplierName,
      type: parseInt(type),
      contactID: parseInt(contactID),
      vatCodeId: parseInt(vatCodeIdRef?.vatCodeId),
      currencyCode: currencyCode?.currencyCode,
      nominalRef: nominalRef,
      vatNumber: vatNumber,
      creditLimit: parseInt(creditLimit),
      termDays: parseInt(termDays),
      notes: notes,
      payeeName: payeeName,
      transactionTypes: transactionTypes,
      balance: balance,
      onHold: onHold,
      payWhenPaid: payWhenPaid,
      archived: archived,
      address: address,
    };

    if (view === "Add") {
      dispatch(addSupplier(payload));
    } else {
      dispatch(updateSupplier(supplierId, payload));
    }
    setError(false);
    props.onClose();
  };

  const changeTransactionType = (e) => {
    if (view === "View") {
      return;
    }
    setTransactionTypes(e.value);
  };

  useEffect(() => {
    if (viewSupplier && viewSupplier.data && view !== "Add") {
      setOnHold(viewSupplier.data.onHold);
      setSupplierId(viewSupplier.data.id);
      setPayWhenPaid(viewSupplier.data.payWhenPaid);
      setArchivedValue(viewSupplier.data.archived);
      setSupplierRef(viewSupplier.data.supplierRef);
      setSupplierName(viewSupplier.data.supplierName);
      setType(viewSupplier.data.type);
      setContactID(viewSupplier.data.contactID);
      setVatCodeId(vatCodeReturner(viewSupplier.data.vatCodeId));
      setCurrencyCode(currencyCodeReturner(viewSupplier.data.currencyCode));
      setNominalRef(viewSupplier.data.nominalRef);
      setVatNumber(viewSupplier.data.vatNumber);
      setCreditLimit(viewSupplier.data.creditLimit);
      setTermDays(viewSupplier.data.termDays);
      setNotes(viewSupplier.data.notes);
      setPayeeName(viewSupplier.data.payeeName);
      setTransactionTypes(viewSupplier.data.transactionTypes);
      setBalance(viewSupplier.data.balance);
      setAddress(viewSupplier.data.address);
    }
  }, [viewSupplier]);

  const { vatcodes } = useSelector((state) => state);
  const { currency } = useSelector((state) => state);

  const activevatsdata = useSelector((state) => {
    let list = state.vatcodes.activevats.data.map((item) => {
      const templist = item;
      templist.representation =
        item.vatCodeId + "  -  " + item.details + "  -  " + item.rate + "%";
      return templist;
    });
    return list;
  });

  const activeCurrencyData = useSelector((state) => {
    let list =
      state.currency.currencyList &&
      state.currency.currencyList.data.map((item) => {
        const templist = item;
        templist.representation =
          item.currencyCode + "  -  " + item.name + "  -  " + item.symbol;
        return templist;
      });
    return list;
  });

  useEffect(() => {
    setvatsData(activevatsdata.slice());
  }, [vatcodes]);

  useEffect(() => {
    if (currency && activeCurrencyData) {
      setCurrencyData(activeCurrencyData.slice());
    }
  }, [currency]);

  const filterData = (filter) => {
    const datas = activevatsdata.slice();
    return filterBy(datas, filter);
  };
  const currencyFilterData = (filter) => {
    const currencyData = activeCurrencyData.slice();
    return filterBy(currencyData, filter);
  };
  const filterChange = (event) => {
    setvatsData(filterData(event.filter));
  };
  const currencyFilterChange = (event) => {
    setCurrencyData(currencyFilterData(event.filter));
  };

  const DetailsTab = () => (
    <div>
      <div className="d-flex " style={{ gap: "25%" }}>
        <div>
          <div className="mt-4">
            <Label className="mb-1">Supplier Ref&nbsp;</Label>
            <div className="">
              <Input
                style={{
                  border: error && !supplierRef ? "1px solid red" : "",
                  minWidth: "300px",
                  width: "300px",
                }}
                value={supplierRef}
                readOnly={view === "View"}
                onChange={(e) => {
                  setSupplierRef(e.target.value);
                }}
                placeholder={"Enter Supplier Ref"}
              />
            </div>
          </div>
          <div className="mt-4">
            <Label className="mb-1">Supplier Name&nbsp;</Label>
            <Input
              style={{
                border: error && !supplierName ? "1px solid red" : "",
              }}
              value={supplierName}
              placeholder={"Enter Supplier Name"}
              readOnly={view === "View"}
              onChange={(e) => {
                setSupplierName(e.target.value);
              }}
            />
          </div>
          <div className="mt-4">
            <div>
              <Label className="mb-1">Payee Name&nbsp;</Label>
            </div>
            <Input
              value={payeeName}
              readOnly={view === "View"}
              placeholder={"Enter Payee Name"}
              onChange={(e) => {
                setPayeeName(e.target.value);
              }}
            />
          </div>
        </div>
        <div>
          <div className="mt-4">
            <div className="label">
              <Label>Transaction Types:&nbsp;</Label>
            </div>
            <div
              className={`mt-3 ${error && !transactionTypes ? "error" : ""}`}
            >
              <RadioGroup
                data={data}
                readOnly={view === "View"}
                className="gap-2 radio"
                value={transactionTypes}
                onChange={changeTransactionType}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="mt-4">
        <Label className="label mb-2">Notes&nbsp;</Label>
        <div>
          <TextArea
            value={notes}
            style={{ maxWidth: "none", width: "90%", height: "60px" }}
            placeholder={"Enter Details"}
            readOnly={view === "View"}
            onChange={(e) => {
              setNotes(e.target.value);
            }}
          />
        </div>
      </div>
      <div className="mt-4">
        <div className="d-flex radio">
          <Checkbox
            defaultChecked={false}
            onClick={changeOnHold}
            value={onHold}
            label={"On Hold"}
          />
        </div>
      </div>
      <div className="mt-4">
        <div className="d-flex radio">
          <Checkbox
            defaultChecked={false}
            value={archived}
            onClick={changeArchivedValue}
            label={"Archived"}
          />
        </div>
      </div>
    </div>
  );
  const DefaultsTab = () => (
    <div>
      <div className="d-flex ">
        <div className="col-5 me-5 ">
          <div className="mt-3">
            <Label className="mb-1 d-block">Vat Number&nbsp;</Label>
            <div>
              <Input
                style={{
                  width: "300px",
                }}
                value={vatNumber}
                readOnly={view === "View"}
                placeholder={"Enter VAT Number"}
                onChange={(e) => {
                  setVatNumber(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="mt-3">
            <Label className="mb-1 d-block">Vat Code&nbsp;</Label>
            <ComboBox
              data={vatsdata}
              style={{
                width: "300px",
              }}
              textField={"representation"}
              filterable={true}
              onFilterChange={filterChange}
              placeholder={"Search VAT Code"}
              value={vatCodeId}
              iconClassName="wa"
              onChange={(e) => {
                if (view === "View") {
                  return;
                }
                setVatCodeId(e.value);
              }}
            />
          </div>
          <div className="mt-3">
            <Label className="mb-1 d-block">Currency Code&nbsp;</Label>
            <ComboBox
              data={currencyData}
              style={{
                width: "300px",
              }}
              textField={"representation"}
              iconClassName="wa"
              filterable={true}
              onFilterChange={currencyFilterChange}
              placeholder={"Search Currency Code"}
              onChange={(e) => {
                if (view === "View") {
                  return;
                }
                setCurrencyCode(e.value);
              }}
              value={currencyCode}
            />
          </div>
        </div>
        <div className="ms-2">
          <div className="mt-3">
            <Label className="mb-1">Credit Limit&nbsp;</Label>
            <div>
              <Input
                value={creditLimit}
                readOnly={view === "View"}
                style={{ minWidth: "300px" }}
                placeholder={"Enter Limit"}
                type="number"
                onChange={(e) => {
                  setCreditLimit(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="mt-3">
            <Label className="mb-1">Term Days&nbsp;</Label>
            <div>
              <Input
                value={termDays}
                readOnly={view === "View"}
                type="number"
                placeholder={"Enter Term Days"}
                onChange={(e) => {
                  setTermDays(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="mt-3">
            <Label className="mb-1">Default Nominal&nbsp;</Label>
            <Input
              value={nominalRef}
              placeholder={"Enter Default Nominal"}
              readOnly={view === "View"}
              onChange={(e) => {
                setNominalRef(e.target.value);
              }}
            />
          </div>
          <div className="mt-4">
            <div className="d-flex radio mt-10">
              <Checkbox
                defaultChecked={false}
                value={payWhenPaid}
                onClick={changePayWhenPaid}
                label={"Pay When Paid"}
              />
            </div>
          </div>
        </div>
      </div>
      <Form
        initialValues={address}
        render={() => (
          <AddressInputComponent
            name="Address"
            readOnly={view === "View"}
            address={address}
            setAddress={setAddress}
            width={"300px"}
            contactComponent={`col-5 me-5`}
          />
        )}
      ></Form>
    </div>
  );

  const newSupplier = (
    <div className="">
      <Dialog
        title={`${view} Supplier`}
        onClose={props.onClose}
        className="add-dialog dialog-md"
      >
        <TabStrip
          keepTabsMounted={true}
          selected={selected}
          onSelect={(e) => setSelected(e.selected)}
          className="mt-4 ps-5 pagetab companytab w-100"
        >
          {tabs.map((item, index) => {
            return (
              <TabStripTab
                title={
                  <PageTabItemTitle
                    className={`mb-sm-4 mb-xl-0`}
                    title={item}
                    index={tabs.indexOf(item)}
                    selected={selected}
                  />
                }
                key={index}
              >
                <div className="mt-3 w-100">
                  {item === "Details" && DetailsTab()}
                  {item === "Defaults" && DefaultsTab()}
                </div>
              </TabStripTab>
            );
          })}
        </TabStrip>
        <DialogActionsBar>
          <div className="dialog-footer mt-1">
            <Button
              className={`k-button common-btn bgBtnSecondary me-3`}
              onClick={props.onClose}
            >
              {view === "View" ? "Back" : "Cancel"}
            </Button>
            {view !== "View" && (
              <Button
                type="submit"
                className="k-button common-btn me-3"
                onClick={submitSupplier}
              >
                Save
              </Button>
            )}
            {view === "View" && (
              <Button
                className="common-btn me-3"
                onClick={() => setView("Edit")}
              >
                Edit
              </Button>
            )}
          </div>
        </DialogActionsBar>
      </Dialog>
    </div>
  );
  return <div className="gridMargin">{newSupplier}</div>;
};
export default AddNewSupplier;
