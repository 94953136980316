export const routeErrorHandler = (requestURL, error) => {
  let response;

  switch (true) {
    case requestURL.includes("/accounts"):
      console.error(
        "Error occurred on /accounts:",
        error.response?.data || error.message
      );
      response = {
        customMessage: "Error in accounts service.",
        ...error.response?.data,
      };
      break;

    default:
      console.error("API Error:", error.response?.data || error.message);
      response = error.response?.data || error.message || "An error occurred";
      break;
  }

  return response;
};
