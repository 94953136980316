import * as React from "react";
import { useEffect } from "react";
import Notifications from "./Notifications";
import { Overlay } from "components/Overlay";
import styles from "./HeaderProfile.module.scss";

export const HeaderProfile = (props) => {
  const [show, setShow] = React.useState(false);
  useEffect(() => {
    const close = (e) => {
      if (e?.target?.alt === "user-avatar") return;
      if (e?.target?.role !== "button") setShow(false);
    };

    document.addEventListener("mousedown", close);
    return () => {
      document.removeEventListener("mousedown", close);
    };
  }, []);

  return (
    <>
      <div className={`d-flex align-items-center top-right m-3 me-4`}>
        <div
          className={`me-2 position-relative cursor-pointer ${styles["notifications-border"]}`}
        >
          <Notifications />
        </div>
        {show && <Overlay>{/*Pass any data to the modal */}</Overlay>}
      </div>
    </>
  );
};
