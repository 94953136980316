import * as React from "react";
import "@progress/kendo-react-intl";
import "@progress/kendo-react-dropdowns";
import "react-router-dom";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as api from "../../api/index";
import { AiOutlineDelete, AiOutlineEdit, AiOutlineFile } from "react-icons/ai";
import documentIcon from "../../assets/fileIcons/Document.svg";
import { formatDate, formatNumber } from "@progress/kendo-intl";
import {
  clearMattersBalances,
  getMattersBalances,
} from "actions/matters.action";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { Button } from "@progress/kendo-react-buttons";
import { createposting } from "actions/postings.action";
import MatterAccount from "../MatterLedger/MatterAccount";
import Modal from "../Settings/Modal";
import { AmountFormatCell, DateFormatCell } from "components/common/formatter";
import {
  FileCell,
  PayCell,
  VATAMountCell,
} from "components/BatchPosting/GridComponents";
import { PostingSubmitFilter } from "components/BatchPosting/SubmitFunction";
import Form from "components/BatchPosting/Form";
import { Dialog } from "@progress/kendo-react-dialogs";
import ChitPosting from "pages/ChitPosting/ChitPosting";
import Modalposting from "components/BatchPosting/PostingsModal";
import { handleApiError } from "Helper";
import DocumentViewer from "components/common/DocumentViewer";
import MatterDetails from "pages/MatterLedger/MatterDetails";
import { TransactionPeriod } from "components/common/TransactionPeriod";
import { toast } from "react-toastify";

const BatchPostings = ({ type, selectedList, onClose }) => {
  const editField = "inEdit";
  const [errorModal, setErrorModal] = useState(false);
  const [showDV, setShowDV] = React.useState(false);
  const [fileData, setFileData] = useState([]);
  const [updateDataIndex, setUpdateDataIndex] = useState(0);
  const [modalvalue, setmodalvalue] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [currentTransactionPeriod, setCurrentTransactionPeriod] = useState();
  let locale = useSelector((state) => state?.company?.company?.locale);
  const [initialData, setInitialData] = useState({
    date: new Date(Date.now()),
  });

  const dispatch = useDispatch();

  const [matterref, setmatterref] = useState(null);
  const [showChits, setShowChits] = useState(false);
  const [list, setList] = useState([]);
  const [renderList, setRenderList] = useState([]);
  const [updateResetData, setUpdateResetData] = useState({});
  const [selectedPostingCode, setSelectedPostingCode] = useState(null);
  const [item, setitem] = useState([
    { code: "0", name: "Loading", displayFields: [] },
  ]);

  const handleCancel = () => {
    return;
  };

  const handleClearFile = (event) => {
    setSelectedPostingCode();
    setFilesData([]);

    onChagngePosting(event);
  };

  useEffect(() => {
    if (type === "chitPosting") {
      console.log(selectedList);
      setList([...selectedList]);
    }
  }, [selectedList]);

  //dup
  const onChagngePosting = (event) => {
    const code = event?.value?.code;
    if (code === "PIN") {
      setRenderList(["PIN"]);
    }
    if (code === "DIN") {
      setRenderList(["DIN"]);
    }
    if (code === "OCR") {
      setRenderList(["OCR"]);
    }
    if (code === "ODR") {
      setRenderList(["ODR"]);
    }
    if (code === "CCR") {
      setRenderList(["CCR"]);
    }
    if (code === "CDR") {
      setRenderList(["CDR"]);
    }
    if (code === "DDR") {
      setRenderList(["DDR"]);
    }
    if (code === "DCR") {
      setRenderList(["DCR"]);
    }
    if (code === "DUU") {
      setRenderList(["DUU"]);
    }
    if (code === "CTO") {
      setRenderList(["CTO"]);
    }
    if (code === "OTC") {
      setRenderList(["OTC"]);
    }
    if (code === "BR") {
      setRenderList(["BR"]);
    }
    if (code === "BP") {
      setRenderList(["BP"]);
    }
    if (code === "BT") {
      setRenderList(["BT"]);
    }
    if (code === "DPU") {
      setRenderList(["DPU"]);
    }
    if (code === "CTC") {
      setRenderList(["CTC"]);
    }
    setSelectedPostingCode(code);
  };

  const handleRemove = (row) => {
    if (list.length === 0) return;
    const listcopy = [...list];
    listcopy.splice(row, 1);
    setList([...listcopy]);
  };

  const handleSubmit = async (e) => {
    const data = PostingSubmitFilter(list);
    if (data.vatAmount === null) {
      data.vatAmount = 0;
    }
    if (data.length === 0) return;
    const formattedData = data.map((postingItem) => {
      postingItem.transactionPeriod = currentTransactionPeriod?.period;
      return postingItem;
    });
    setLoading(true);
    try {
      const response = await api.createposting(formattedData);
      toast.success(
        `Posted Successfully for ${response?.data?.data[0]?.postingType} - ${response?.data?.data[0]?.transactionNo} `
      );
      setList([]);
    } catch (error) {
      toast.error("Error occured in posting");
    } finally {
      setLoading(false);
    }
  };

  const DocumentCounterCell = (e) => {
    return (
      <td>
        {console.log(e.dataItem)}
        {console.log(e?.dataItem?.documentFiles?.length)}

        <div style={{ display: "flex", alignItems: "center", gap: "2px" }}>
          {e?.dataItem?.documentFiles?.length > 0 && (
            <>
              <img
                role="button"
                src={documentIcon}
                alt="Document Icon"
                onClick={() => {}}
              ></img>
            </>
          )}
          <span>
            {e?.dataItem?.documentFiles?.length === 0
              ? ""
              : e?.dataItem?.documentFiles?.length}
          </span>
        </div>
      </td>
    );
  };

  const [matterId, setMatterId] = React.useState(null);
  const [matterIdTo, setMatterIdTo] = React.useState(null);

  const onChangematter = (event) => {
    if (event.value) {
      setMatterId(event.value);
      dispatch(getMattersBalances(event.value.matterId, "All Postings"));
      setmatterref(event.value.matterId);
    } else {
      setMatterId(null);
      setmatterref(null);
      dispatch(clearMattersBalances("All Postings"));
    }
  };

  const getCurrentTransactionPeriod = async () => {
    try {
      const data = await api.getCurrentSessionTransactionPeriod(
        JSON.parse(sessionStorage.getItem("sessionPeriod"))?.period
      );
      setCurrentTransactionPeriod(data.data?.data);
    } catch (error) {}
  };

  // dup
  const [filesData, setFilesData] = React.useState([]);

  const [isUpdate, setIsUpdate] = useState(false);
  //posting filer
  // const [postingDataN, setPostingDataN] = React.useState(item.slice());
  const [postingCode, setPostingCode] = React.useState();
  const [postingNames, setPostingNames] = React.useState();

  const addToBatch = (data) => {
    setList((prevList) => [...prevList, ...data]);
  };

  const handleUpdate = (props) => {
    const { dataIndex, dataItem } = props;
    onChangematter({ value: dataItem.matterID });
    console.log(dataItem);
    setMatterIdTo(dataItem?.matterIDTo);
    setFilesData(dataItem.documentFiles);
    setUpdateDataIndex(dataIndex);
    setUpdateResetData({ ...dataItem });
    setPostingCode(dataItem.postingType);
    setRenderList([dataItem.postingType.code]);

    setIsUpdate(true);
    setInitialData({ ...dataItem });
  };

  useEffect(() => {
    getCurrentTransactionPeriod();
  }, []);

  const dateReturner = () => {
    return `${currentTransactionPeriod.period} ( ${formatDate(
      new Date(currentTransactionPeriod.startDate),
      "d",
      locale
    )} - ${formatDate(
      new Date(currentTransactionPeriod.endDate),
      "d",
      locale
    )} )`;
  };

  const getTransactionPeriodText = () => {
    if (!currentTransactionPeriod) {
      return {
        color: "red",
        text: "Invalid Transaction Period",
      };
    }
    if (!currentTransactionPeriod?.closed) {
      return {
        color: "blue",
        text: dateReturner(),
      };
    } else if (currentTransactionPeriod?.closed) {
      return {
        color: "red",
        text: `${dateReturner()} Closed`,
      };
    }
  };

  const CommandCell = (props) => {
    return (
      <td style={{ display: "flex", flexDirection: "column" }}>
        <button
          type="button"
          onClick={() => handleUpdate(props)}
          className="k-button k-button-md k-rounded-md "
        >
          <AiOutlineEdit color="blue" />
        </button>

        <button
          type="button"
          style={{ marginTop: "5px" }}
          onClick={() => handleRemove(props.dataIndex)}
          className="k-button k-button-md k-rounded-md "
        >
          <AiOutlineDelete color="red" />
        </button>
      </td>
    );
  };

  return (
    <div
      className="container-fluid ps-0 pt-0"
      style={{ height: "calc(100vh - 100px)" }}
    >
      <div className="row ps-0 ms-0 g-0">
        {/* <div className="col-3"> */}
        <Form
          postingType={type}
          list={list}
          setList={setList}
          setIsUpdate={setIsUpdate}
          onChagngePosting={onChagngePosting}
          updateDataIndex={updateDataIndex}
          setInitialData={setInitialData}
          matterId={matterId}
          matterIdTo={matterIdTo}
          setMatterIdTo={setMatterIdTo}
          isUpdate={isUpdate}
          setErrorModal={() => setErrorModal(true)}
          setRenderList={setRenderList}
          renderList={renderList}
          onChangematter={onChangematter}
          initialData={initialData}
          setUpdateDataIndex={setUpdateDataIndex}
          item={item}
          changePostingCode={setPostingCode}
          postingCodeEdit={postingCode}
          openChits={() => setShowChits(true)}
          type={type}
        />
        {/* </div> */}

        <div className="col" style={{ overflowX: "auto" }}>
          <div
            style={{
              overflowX: "auto",
              minWidth: "1000px",
              whiteSpace: "nowrap",
            }}
          >
            <TransactionPeriod />
            <div style={{ minHeight: "110px" }} className="mt-5 ms-4 ps-2 me-3">
              {renderList[0] !== "PIN" && (
                <div className="row mt-2 mb-2">
                  <div className="col-6 d-flex justify-content-end g-0">
                    <MatterDetails matter={matterId} width={"100%"} />
                  </div>
                  <div className="col-6 d-flex justify-content-end">
                    <MatterAccount
                      mt="0px"
                      float="right"
                      component={"All Postings"}
                      matterId={matterId}
                    />
                  </div>
                </div>
              )}
            </div>

            <div className="row mt-4 ms-2 me-2">
              <div>
                <Grid
                  data={list}
                  editField={editField}
                  style={{
                    maxHeight: "calc(100vh - 370px)",
                    width: type === "chitPosting" ? "calc(820px)" : "100%",
                    overflow: "auto",
                  }}
                  className="text-align-right"
                >
                  <Column
                    title="Type"
                    field="postingType.code"
                    width="70px"
                    headerClassName="ps-3"
                  />
                  <Column
                    title="Matter"
                    field="matterID.matterRef"
                    width="100px"
                  />
                  <Column title="Nominal" field="nominalRef.nominalRef" />
                  <Column
                    title="Date"
                    field="date"
                    width="100px"
                    cell={DateFormatCell}
                  />
                  <Column title="Ref" field="reference" width="100px" />
                  <Column title="Detail" field="details" />
                  <Column
                    title="Net"
                    field="netAmount"
                    cell={AmountFormatCell}
                    width="80px"
                  />
                  <Column
                    title="Vat"
                    field="vatAmount"
                    cell={AmountFormatCell}
                    width="80px"
                  />
                  <Column title="Supplier" field="supplierRef.supplierRef" />
                  <Column title="Fee Earner" field="feeEarnerRef.userRef" />
                  <Column title="Payee(r)" cell={PayCell} width="120px" />
                  <Column
                    title="Docs"
                    field="documentFiles"
                    cell={DocumentCounterCell}
                    width="80px"
                  />
                  <Column cell={CommandCell} width="60px" />
                </Grid>

                <div className="buttonstyle">
                  <Button
                    className="common-btn"
                    disabled={loading}
                    onClick={handleSubmit}
                  >
                    Post
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default BatchPostings;
