import { SET_MAIN_LOADING,SET_CLIENT_DROPDONW_DATA } from "../types/index";
import * as api from '../api/index'
import { handleApiError } from "Helper";

export const setMainLoading = (payLoad) => async (dispatch) => {
  try {
    dispatch({ type: SET_MAIN_LOADING, payLoad: payLoad });
  } catch (error) {}
};

export const getClientDropDownData = () => async (dispatch,getState) => {
  let bData, ac,fe,aVats;
  try{
    if(!getState().common.branchesName?.length){
    const {data} = await api.getBranchesnames()
    bData = data.data
    }
    if(!getState().common.acctiveCurrencies?.length){
       const {data} = await api.getActiveCurrency()
       ac = data.data
      }
    if(!getState().common.feeEarner?.length){
    const {data} = await api.getfeeearner()
      fe= data.data
      }
    if(!getState().common.activeVats?.length){
       const {data} = await api.getactiveVats()
       aVats = data.data
        }

        if(!bData || !ac || !fe || !aVats) return
    dispatch({
       type: SET_CLIENT_DROPDONW_DATA, 
       payLoad: { branchesName: bData, acctiveCurrencies: ac, feeEarner:fe, activeVats: aVats } 
      });
       
  }catch(error){ handleApiError(error) }
}