import * as React from "react";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Label } from "@progress/kendo-react-labels";
import CustomInput from "components/common/Input";
import * as api from "../../api/index";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { DateFormatCell } from "components/common/formatter";
import { Tile } from "components/UIComponents/Tile";
import MatterModal from "components/Matters/MatterModal";
import { createNewContact, getCompanyProfile } from "api";
import { combineAddressLines, handleApiError } from "Helper";
import LoadingScreen from "components/common/Loading";
import { toast } from "react-toastify";
import {
    CategoryBadgeComponent,
    PrimaryBadgeComponent,
} from "components/MatterDetails/ContactCard";
import DataList from "components/MatterDetails/DataList";
import { FormComboBox } from "components/Matters";
import { CONSTANT } from "CONSTANT/Matter/MatterForm";
import { filterBy } from "@progress/kendo-data-query";
import { getContactLinks } from "api/settingApi";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import FiledStar from "../../assets/stars/filled.png";
import EmptyStar from "../../assets/stars/empty.png";
import ContactInformationComponent from "components/Contact/ContactInformation";
import AutoCompleteInput from "components/AutoCompleteCompanies/AutoCompleteCompany";
import { CompanyItemRender } from "components/AutoCompleteCompanies/CompanyItemRender";
import { emailValidator } from "components/common/validator";

const MODE = {
    NEW: 0,
    VIEW: 1,
    EDIT: 2,
};

const PERSON_CONSTANT = {
    PERSON: "Person",
    COMPANY: "Company",
};

const typeData = [
    { name: "Person", value: PERSON_CONSTANT.PERSON },
    { name: "Company", value: PERSON_CONSTANT.COMPANY },
];

const AddNewContact = (props) => {
    const [showModal, setShowModal] = useState(false);
    const dataFromStore = useSelector((state) => state.contactsList);
    const [modalMessage, setModalMessage] = useState("");
    const [modalSuccess, setModalSuccess] = useState(undefined);
    const [formSubmittig, setFormSubmitting] = useState(false);
    let locale = useSelector((state) => state.company.company.locale);
    const [type, setType] = useState(PERSON_CONSTANT.PERSON);
    const [selected, setSelected] = React.useState(0);
    const handleSelect = (e) => {
        setSelected(e.selected);
    };

    const [mode, setMode] = React.useState(
        props.viewMode ? MODE.VIEW : undefined
    );
    const [isSubmitted, setIsSubmitted] = useState(false);
    const handleModalClose = () => {
        setShowModal(false);
        if (modalSuccess) {
            // close model after success posting
            // props.close()
            if (props.contactId && mode === MODE.EDIT) {
                getContact(props.contactId);
            } else {
                props.close();
            }
            setMode(MODE.VIEW);
        }
    };

    const updateModalState = (modalShow, message, success) => {
        setShowModal(modalShow);
        setModalMessage(message);
        setModalSuccess(success);
    };

    const handleSubmit = async (dataItem) => {
        if (mode === MODE.VIEW) {
            setMode(MODE.EDIT);
            return;
        }

        if (
            !comboBoxState[CONSTANT.COMBOBOX.TYPE]?.value ||
            !comboBoxState[CONSTANT.COMBOBOX.CONTACT_LINK]?.categoryName
        ) {

            return;
        }

        setFormSubmitting(true);
        let data = { person: null, company: null };
        data.type = comboBoxState[CONSTANT.COMBOBOX.TYPE]?.value;
        data.contactCategory =
            comboBoxState[CONSTANT.COMBOBOX.CONTACT_LINK]?.categoryName;
        data.contactCategoryId =
            comboBoxState[CONSTANT.COMBOBOX.CONTACT_LINK]?.contactCategoryId;
        data.address = {
            line1: dataItem.line1.split("\n")[0],
            line2: dataItem.line1.split("\n")[1],
            town: dataItem.town,
            state: dataItem.state,
            postcode: dataItem.postcode,
            country: dataItem.country,
        };
        data.email1 = dataItem.email;
        data.phone1 = dataItem.phone1;
        data.phone2 = dataItem.phone2;
        data.mobileNo = dataItem.mobileNo;
        data.website = dataItem.website;
        data.active = true;

        if (data.type === PERSON_CONSTANT.PERSON) {
            if (!dataItem.firstName) {
                setFormSubmitting(false);
                return;
            }
            data.person = {
                title: dataItem.title,
                firstName: dataItem.firstName,
                middleName: dataItem.middleName,
                lastName: dataItem.lastName,
                letterTitle: "",
                jobTitle: "",
                nameAtBirth: dataItem.nameAtBirth,
                placeOfBirth: dataItem.placeOfBirth,
                dateOfBirth: dataItem.dateOfBirth || null,
                dateOfDeath: dataItem.dateOfDeath || null,
                // ethnicity: dataItem.ethnicity,
                ethnicity: 0,
                gender: dataItem.gender === "Male" ? "M" : "F",
                disability: 1,
                // disability: dataItem.disability,
                niNumber: dataItem.niNumber,
                other1: "",
            };
        }

        if (data.type === PERSON_CONSTANT.COMPANY) {
            if (!dataItem.companyName) {
                setFormSubmitting(false);
                return;
            }

            data.company = {
                companyName: dataItem.companyName,
                companyRegNo: dataItem.companyRegNo,
                corrEmail: dataItem.ccemail,
                corrFirstName: dataItem.ccFirstName,
                corrPhone: dataItem.ccphone1,
                corrJobTitle: dataItem?.ccJobTitle,
                vatNo: dataItem.vatNo,
                sicCode: dataItem.sicCode,
                registeredAddress: {
                    line1: dataItem.registeredAddressline1?.split("\n")[0],
                    line2: dataItem.registeredAddressline1?.split("\n")[1],
                    town: dataItem.registeredAddresstown,
                    state: dataItem.registeredAddressstate,
                    postcode: dataItem.registeredAddresspostcode,
                    country: dataItem.registeredAddresscountry,
                },
                correspondenceContact: {
                    phone1: dataItem.ccphone1,
                    phone2: dataItem.ccphone2,
                    mobileNo: dataItem.ccmobileNo,
                    email1: dataItem.ccemail,
                    active: true,
                    type: PERSON_CONSTANT.PERSON,

                    address: {
                        line1: dataItem.ccline1?.split("\n")[0],
                        line2: dataItem.ccline1?.split("\n")[1],
                        town: dataItem.cctown,
                        state: dataItem.ccstate,
                        postcode: dataItem.ccpostcode,
                        country: dataItem.cccountry,
                    },
                    person: {
                        title: dataItem.cctitle,
                        firstName: dataItem.ccFirstName,
                        middleName: dataItem.ccmiddleName,
                        lastName: dataItem.cclastName,
                    },
                    website: dataItem.ccwebsite,
                },
            };
        }
        try {
            if (props.viewMode) {
                await api.editContact(props.contactId, data);
            } else {
                await createNewContact(data, type);
            }
            setFormSubmitting(false);
            updateModalState(
                true,
                props.viewMode ? "Contact Upated" : "Contact Created",
                true
            );
        } catch (error) {
            setFormSubmitting(false);
            updateModalState(true, "Error", false);
            handleApiError(error);
        }
    };

    React.useEffect(() => {
        if (selected === 2) setSelected(0);
    }, [type]);

    const handleComboBoxChange = (e, label) => {
        setComboBoxState({
            ...comboBoxState,
            [label]: e.value,
        });
    };

    const [personState, setPersonState] = React.useState({
        title: "Mr",
        dateOfBirth: "",
        dateOfDeath: "",
        disability: "Physical",
        ethnicity: "White British",
        firstName: "",
        gender: "Male",
        jobTitle: "",
        lastName: "",
        letterTitle: "",
        middleName: "",
        nameAtBirth: "",
        niNumber: "",
        other1: "",
        placeOfBirth: "",
    });

    const [address, setAddress] = React.useState({
        country: "",
        line1: "",
        line2: "",
        postcode: "",
        state: "",
        town: "",
    });

    const [companyState, setCompanyState] = React.useState({
        companyName: "",
        companyRegNo: "",
        vatNo: "",
        sicCode: "",
        other1: "",
        registeredAddress: {
            line1: "",
            line2: "",
            town: "",
            state: "",
            postcode: "",
            country: "",
        },

        ccemail1: "",
        ccemail2: "",
        ccphone1: "",
        ccphone2: "",
        ccmobileNo: "",
        ccwebsite: "",
        ccnotes: "",
        other1: "",
        active: true,
        type: "Company",

        address: {
            ccline1: "",
            ccline2: "",
            cctown: "",
            ccstate: "",
            ccpostcode: "",
            cccountry: "",
        },
        ccPerson: {
            title: "",
            firstName: "",
            middleName: "",
            lastName: "",
        },
    });

    const [initialValues, setInitialValues] = React.useState({});

    const [state, setState] = React.useState({
        email: "",
        phone1: "",
        phone2: "",
        mobileNo: "",
        website: "",
        notes: "",
        other1: "",
        active: true,
        type: "Company",
    });
    const [comboBoxState, setComboBoxState] = useState({
        [CONSTANT.COMBOBOX.CONTACT_LINK]: "",
        [CONSTANT.COMBOBOX.TYPE]: "",
    });

    const [contactData, setContactData] = useState();
    const [gridData, setGridData] = useState([]);
    const [contactDataFilter, setContactDataFilter] = useState();
    const [typesFilter, setTypesFilter] = useState();

    const [loading, setLoading] = React.useState(
        mode === MODE.VIEW ? true : false
    );
    const [companyProfile, setCompanyProfile] = useState();

    const getContactTypes = async () => {
        try {
            const datas = await getContactLinks();
            setContactData(datas?.data?.data);
            setContactDataFilter(datas?.data?.data?.slice());
        } catch (error) {
            setContactData([]);
            setContactDataFilter([]);
        }
    };

    const getContact = (contactId) => {
        setLoading(true);
        try {
            api.getSingleContact(contactId).then(({ data }) => {
                setState({
                    ...state,
                    email: data?.data?.email1,
                    mobileNo: data?.data?.mobileNo,
                    phone1: data?.data?.phone1,
                    phone2: data?.data?.phone2,
                    website: data?.data?.website,
                    contactCategory: data?.data?.contactCategory,
                });
                setAddress({
                    country: data?.data?.address?.country,
                    line1: combineAddressLines(
                        data?.data?.address?.line1,
                        data?.data?.address?.line2
                    ),
                    postcode: data?.data?.address?.postcode,
                    state: data?.data?.address?.state,
                    town: data?.data?.address?.town,
                });
                setComboBoxState({
                    [CONSTANT.COMBOBOX.TYPE]: {
                        name: data?.data?.type,
                        value: data?.data?.type,
                    },
                    [CONSTANT.COMBOBOX.CONTACT_LINK]: {
                        categoryName: data?.data?.contactCategory,
                        contactCategoryId: data?.data?.contactCategoryId,
                    },
                });
                if (data?.data?.type === PERSON_CONSTANT.PERSON) {
                    setType(PERSON_CONSTANT.PERSON);
                    setPersonState({
                        title: data.data?.person?.title,
                        dateOfBirth: data?.data?.person?.dateOfBirth
                            ? new Date(data?.data?.person?.dateOfBirth)
                            : "",
                        dateOfDeath: data?.data?.person?.dateOfDeath
                            ? new Date(data.data.person.dateOfDeath)
                            : "",
                        disability: data?.data?.person?.disability,
                        ethnicity: data?.data?.person?.ethnicity,
                        firstName: data?.data?.person?.firstName,
                        gender: data?.data?.person?.gender === "F" ? "Female" : "Male",
                        lastName: data?.data?.person?.lastName,
                        middleName: data?.data?.person?.middleName,
                        nameAtBirth: data?.data?.person?.nameAtBirth,
                        name: data?.data?.name,
                        niNumber: data?.data?.person?.niNumber,
                        placeOfBirth: data?.data?.person?.placeOfBirth,
                    });
                    setLoading(false);
                } else if (data?.data?.type === PERSON_CONSTANT.COMPANY) {
                    setType(PERSON_CONSTANT.COMPANY);
                    setCompanyState({
                        companyName: data.data?.company?.companyName,
                        companyRegNo: data.data?.company?.companyRegNo,
                        vatNo: data.data?.company?.vatNo,
                        sicCode: data.data?.company?.sicCode,
                        registeredAddressline1:
                            data.data?.company?.registeredAddress?.line1 +
                            "\n" +
                            data.data?.company?.registeredAddress?.line2,
                        registeredAddresscountry:
                            data?.data.company?.registeredAddress?.country,
                        registeredAddresspostcode:
                            data?.data.company?.registeredAddress?.postcode,
                        registeredAddressstate:
                            data?.data.company?.registeredAddress?.state,
                        registeredAddresstown: data?.data.company?.registeredAddress?.town,
                        ccline1:
                            data?.data?.address?.line1 +
                            "\n" +
                            data.data.company?.correspondenceContact?.address?.line2,
                        cccountry: data?.data?.company?.address?.country,
                        ccpostcode: data?.data.address?.postcode,
                        ccstate: data?.data?.address?.state,
                        cctown: data?.data?.address?.town,
                        ccemail: data?.data?.company?.corrEmail,
                        ccJobTitle: data?.data?.company?.corrJobTitle,
                        ccmobileNo: data?.data?.mobileNo,
                        ccphone1: data?.data?.company?.corrPhone,
                        ccphone2: data?.data?.phone2,
                        cctitle: data?.data?.company?.corrTitle,
                        ccFirstName: data?.data.company?.corrFirstName,
                        ccmiddleName: data?.data.company?.middleName,
                        cclastName: data?.data.company?.corrLastName,
                    });
                    setLoading(false);
                } else {
                    toast.error("Error occured in fetching contact");
                    props.close();
                }
            });
        } catch (error) {
            toast.error("Error occured in fetching contact");
            props.close();
            handleApiError(error);
        }
    };

    const getContactGridData = async () => {
        try {
            const data = await api.getContactsMatterData(props.contactId);
            setGridData(data?.data?.data);
        } catch (error) { }
    };

    const NameCell = (e) => {
        return (
            <td className="gridBoldField">
                <span style={{ color: "black" }}>{e.dataItem[e.field]}</span>
            </td>
        );
    };

    const StarCell = (e) => {
        const coloredStars = Array.apply(null, Array(e.dataItem.rating || 0)).map(
            function (y, i) {
                return i;
            }
        );
        const unColoredStars = Array.apply(null, Array(5 - e.dataItem.rating)).map(
            function (y, i) {
                return i;
            }
        );
        return (
            <td className="">
                {coloredStars.map((item) => (
                    <img
                        key={item}
                        src={FiledStar}
                        className="me-1 mb-1"
                        style={{ height: "14px", width: "14px" }}
                    />
                ))}
                {unColoredStars.map((item) => (
                    <img
                        key={item}
                        src={EmptyStar}
                        className="me-1 mb-1"
                        style={{ height: "14px", width: "14px" }}
                    />
                ))}
            </td>
        );
    };

    React.useEffect(() => {
        if (companyProfile) {
            let selectedAddress =
                companyProfile.service_address ||
                companyProfile.registered_office_address ||
                {};

            let newAddress = {
                country: selectedAddress.country || "",
                line1: combineAddressLines(
                    selectedAddress.premises,
                    selectedAddress.address_line_1
                ),
                line2: selectedAddress.address_line_2 || "",
                postcode: selectedAddress.postal_code || "",
                state: selectedAddress.region || "",
                town: selectedAddress.locality || "",
            };

            setInitialValues({
                ...state,
                companyName: companyProfile.company_name || "",
                ...newAddress,
            });
        } else if (type === PERSON_CONSTANT.PERSON) {
            setInitialValues({
                ...state,
                ...personState,
                ...address,
            });
        } else {
            setInitialValues({
                ...state,
                ...companyState,
                ...address,
            });
        }
    }, [personState, companyState, type, companyProfile]);

    React.useEffect(() => {
        setIsSubmitted(false);
    }, [comboBoxState[CONSTANT.COMBOBOX.TYPE]?.value]);

    const filterCaegory = (filter) => {
        const data = contactData.slice();
        return filterBy(data, filter);
    };
    const cateforyFilterChange = (event) => {
        setContactDataFilter(filterCaegory(event.filter));
    };

    const filterType = (filter) => {
        const data = typeData.slice();
        return filterBy(data, filter);
    };
    const typeFilterChange = (event) => {
        setTypesFilter(filterType(event.filter));
    };
    React.useEffect(() => {
        if (props.viewMode) {
            setMode(MODE.VIEW);
            getContact(props.contactId);
            getContactGridData();
        } else {
            setMode(MODE.NEW);
            setComboBoxState({
                ...comboBoxState,
                [CONSTANT.COMBOBOX.TYPE]: { name: "Person", value: "Person" },
            });
        }
    }, [props.viewMode]);

    React.useEffect(() => {
        getContactTypes();
    }, []);

    const handleSelection = async (event) => {
        const response = await getCompanyProfile(event);
        setCompanyProfile(response?.data);
    };
    return (
        <Dialog
            title={
                mode === MODE.VIEW
                    ? "View Contact"
                    : mode === MODE.EDIT
                        ? "Edit Contact"
                        : "Add Contact"
            }
            onClose={props.close}
            className="dialog-xxl"
        >
            <div>
                {mode === MODE.VIEW ? (
                    loading ? (
                        <LoadingScreen />
                    ) : type === PERSON_CONSTANT.PERSON ? (
                        <div className="container-fluid  px-5 py-4">
                            <div className="row g-2">
                                <div className="col">
                                    <Tile
                                        heading="Contact"
                                        className="p-3 ps-4 pe-4 pt-2 pb-0 overflow-auto"
                                        height={"280px"}
                                        headingItems={
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div>
                                                    <CategoryBadgeComponent
                                                        text={initialValues.contactCategory}
                                                        className="mt-3 ps-3 pe-3 me-2"
                                                    />
                                                </div>
                                                <div>
                                                    <PrimaryBadgeComponent
                                                        text={"Person"}
                                                        className="mt-3 ps-3 pe-3"
                                                    />
                                                </div>
                                            </div>
                                        }
                                    >
                                        <div className="px-2">
                                            <div>
                                                <div style={{ width: "30%" }}>
                                                    <DataList
                                                        text={"Name:"}
                                                        value={initialValues?.name}
                                                        keyStyles={{ minWidth: "100px" }}
                                                        className={"w-100 mt-1"}
                                                    />
                                                </div>
                                                <div className="d-flex align-items-start justify-content-start mt-3">
                                                    <div style={{ width: "280px" }}>
                                                        <DataList
                                                            text={"Email:"}
                                                            value={initialValues.email}
                                                            keyClass={"mb-2"}
                                                            alignItems="align-items-start"
                                                            valueClass={"mb-2"}
                                                            keyStyles={{ minWidth: "100px" }}
                                                        />
                                                        <DataList
                                                            text={"Address:"}
                                                            className={"mt-2"}
                                                            alignItems="align-items-start"
                                                            keyStyles={{ minWidth: "100px" }}
                                                            value={
                                                                <>
                                                                    {initialValues?.line1 && (
                                                                        <>
                                                                            {initialValues?.line1} <br />
                                                                        </>
                                                                    )}
                                                                    {initialValues?.town && (
                                                                        <>
                                                                            {initialValues?.town} <br />
                                                                        </>
                                                                    )}{" "}
                                                                    {initialValues?.state && (
                                                                        <>
                                                                            {initialValues?.state} <br />
                                                                        </>
                                                                    )}{" "}
                                                                    {initialValues?.country && (
                                                                        <>
                                                                            {initialValues?.country} <br />
                                                                        </>
                                                                    )}
                                                                </>
                                                            }
                                                            keyClass={"mb-2"}
                                                            valueClass={"mb-2"}
                                                        />
                                                    </div>
                                                    <div className="ps-5">
                                                        <DataList
                                                            text={"Phone 1:"}
                                                            value={initialValues.phone1}
                                                            keyClass={"mb-2"}
                                                            valueClass={"mb-2"}
                                                            keyStyles={{ minWidth: "100px" }}
                                                        />
                                                        <DataList
                                                            text={"Phone 2:"}
                                                            className={"mt-2"}
                                                            value={initialValues.phone1}
                                                            keyClass={"mb-2"}
                                                            valueClass={"mb-2"}
                                                            keyStyles={{ minWidth: "100px" }}
                                                        />
                                                        <DataList
                                                            text={"Mobile:"}
                                                            className={"mt-2"}
                                                            value={initialValues.mobileNo}
                                                            keyClass={"mb-2"}
                                                            keyStyles={{ minWidth: "100px" }}
                                                            valueClass={"mb-2"}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Tile>
                                </div>
                            </div>
                            <div className="row g-2 mt-2">
                                <div className="col">
                                    <Tile
                                        heading="Matters"
                                        height={"350px"}
                                        className="p-3 px-4  pt-2 pb-0 overflow-auto"
                                    >
                                        <div className="px-2">
                                            <div>
                                                <Grid data={gridData} className={`fs-md`}>
                                                    <GridColumn
                                                        field="matterRef"
                                                        title="Matter Ref"
                                                        headerClassName="ps-4"
                                                        cell={NameCell}
                                                        width={"120px"}
                                                    />
                                                    <GridColumn
                                                        field="matterDetails"
                                                        title="Details"
                                                    // width="300px"
                                                    />
                                                    <GridColumn field="clientName" title="Client" />
                                                    <GridColumn
                                                        field="startDate"
                                                        title="Date Opened"
                                                        cell={DateFormatCell}
                                                        width="120px"
                                                    />
                                                    <GridColumn
                                                        field="dateCompleted"
                                                        title="Date Closed"
                                                        cell={DateFormatCell}
                                                        width="120px"
                                                    />
                                                    <GridColumn
                                                        field="ratings"
                                                        title="Ratings"
                                                        cell={StarCell}
                                                        width={"120px"}
                                                    />
                                                </Grid>
                                            </div>
                                        </div>
                                    </Tile>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="container-fluid px-5 py-4">
                            <div className="row g-2">
                                <div className="col-12 col-xl-7">
                                    <Tile
                                        heading="Contact"
                                        height={"280px"}
                                        className="p-3 ps-4 pe-4 pt-2 pb-0 overflow-auto"
                                        headingItems={
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div>
                                                    <CategoryBadgeComponent
                                                        text={initialValues.contactCategory}
                                                        className="mt-3 ps-3 pe-3 me-2"
                                                    />
                                                </div>
                                                <div>
                                                    <PrimaryBadgeComponent
                                                        text={"Company"}
                                                        className="mt-3 ps-3 pe-3"
                                                    />
                                                </div>
                                            </div>
                                        }
                                    >
                                        <div className="px-2">
                                            <div>
                                                <div style={{ width: "50%" }}>
                                                    <DataList
                                                        text={"Name:"}
                                                        value={initialValues?.companyName}
                                                        className={"w-100 mt-1 justify-content-start"}
                                                        keyStyles={{ minWidth: "80px" }}
                                                    />
                                                </div>
                                                <div className="d-flex align-items-start justify-content-start mt-3">
                                                    <div className="">
                                                        <DataList
                                                            text={"Email:"}
                                                            value={initialValues.email}
                                                            keyClass={"mb-2"}
                                                            alignItems="align-items-start"
                                                            valueClass={"mb-2"}
                                                            keyStyles={{ minWidth: "80px" }}
                                                        />
                                                        <DataList
                                                            text={"Address:"}
                                                            className={"mt-2"}
                                                            alignItems="align-items-start"
                                                            value={
                                                                <>
                                                                    {initialValues?.ccline1 && (
                                                                        <>
                                                                            {initialValues?.ccline1} <br />
                                                                        </>
                                                                    )}
                                                                    {initialValues?.cctown && (
                                                                        <>
                                                                            {initialValues?.cctown} <br />
                                                                        </>
                                                                    )}{" "}
                                                                    {initialValues?.ccstate && (
                                                                        <>
                                                                            {initialValues?.ccstate} <br />
                                                                        </>
                                                                    )}{" "}
                                                                    {initialValues?.cccountry && (
                                                                        <>
                                                                            {initialValues?.cccountry} <br />
                                                                        </>
                                                                    )}
                                                                </>
                                                            }
                                                            keyClass={"mb-2"}
                                                            valueClass={"mb-2"}
                                                            keyStyles={{ minWidth: "80px" }}
                                                        />
                                                    </div>
                                                    <div className="ps-5">
                                                        <DataList
                                                            text={"Phone 1:"}
                                                            value={initialValues.phone1}
                                                            keyClass={"mb-2"}
                                                            keyStyles={{ minWidth: "80px" }}
                                                            valueClass={"mb-2"}
                                                        />
                                                        <DataList
                                                            text={"Phone 2:"}
                                                            className={"mt-2"}
                                                            value={initialValues.phone1}
                                                            keyClass={"mb-2"}
                                                            valueClass={"mb-2"}
                                                            keyStyles={{ minWidth: "80px" }}
                                                        />
                                                        <DataList
                                                            text={"Mobile:"}
                                                            className={"mt-2"}
                                                            value={initialValues.mobileNo}
                                                            keyClass={"mb-2"}
                                                            valueClass={"mb-2"}
                                                            keyStyles={{ minWidth: "80px" }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Tile>
                                </div>
                                <div className="col-12 col-xl-5">
                                    <Tile
                                        height={"280px"}
                                        className="p-3 ps-4 pe-4 pt-2 pb-0 overflow-auto"
                                        heading="Correspondence"
                                    >
                                        <div className="px-2">
                                            <DataList
                                                text={"Name:"}
                                                value={initialValues?.ccFirstName}
                                                valueClass={"mb-2"}
                                                keyClass={"mb-2"}
                                                className={"w-100 mt-3"}
                                                keyStyles={{ minWidth: "80px" }}
                                            />
                                            <DataList
                                                text={"Phone:"}
                                                valueClass={"mb-2"}
                                                keyClass={"mb-2"}
                                                value={initialValues?.ccphone1}
                                                className={"w-100 mt-2"}
                                                keyStyles={{ minWidth: "80px" }}
                                            />
                                            <DataList
                                                text={"Email:"}
                                                valueClass={"mb-2"}
                                                keyClass={"mb-2"}
                                                value={initialValues?.ccemail}
                                                className={"w-100 mt-2"}
                                                keyStyles={{ minWidth: "80px" }}
                                            />
                                            <DataList
                                                text={"Job Title:"}
                                                valueClass={"mb-2"}
                                                keyClass={"mb-2"}
                                                value={initialValues?.ccJobTitle}
                                                className={"w-100 mt-2"}
                                                keyStyles={{ minWidth: "80px" }}
                                            />
                                        </div>
                                    </Tile>
                                </div>
                            </div>
                            <div className="row g-1 mt-2">
                                <div className="col">
                                    <Tile
                                        height={"350px"}
                                        className="p-3 px-4  pt-2 pb-0 overflow-auto"
                                        heading="People"
                                    >
                                        <div className="px-2">
                                            <div>
                                                <Grid data={gridData} className={`fs-md`}>
                                                    <GridColumn
                                                        field="correspondenceFirstName"
                                                        title="Name"
                                                        headerClassName="ps-4"
                                                        cell={NameCell}
                                                    />
                                                    <GridColumn
                                                        field="correspondencePhone"
                                                        title="Phone"
                                                    />{" "}
                                                    <GridColumn
                                                        field="correspondenceJobTitle"
                                                        title="Job Title"
                                                    />
                                                    <GridColumn
                                                        field="correspondenceEmail"
                                                        title="Email"
                                                    />
                                                    <GridColumn field="matterDetails" title="Matter" />
                                                    <GridColumn
                                                        field="ratings"
                                                        title="Ratings"
                                                        cell={StarCell}
                                                    />
                                                </Grid>
                                            </div>
                                        </div>
                                    </Tile>
                                </div>
                            </div>
                        </div>
                    )
                ) : (
                    <div>
                        <Form
                            ignoreModified={mode == MODE.NEW ? true : false}
                            initialValues={initialValues}
                            onSubmit={handleSubmit}
                            key={JSON.stringify(initialValues)}
                            render={(formRenderProps) => {
                                return (
                                    <FormElement id="contact-form">
                                        <div className="px-5 pt-4">
                                            <div className="row g-2">
                                                <div
                                                    className={` ${comboBoxState[CONSTANT.COMBOBOX.TYPE]?.value ===
                                                            "Company"
                                                            ? "col-12 col-xl-8"
                                                            : "col-12"
                                                        }`}
                                                >
                                                    <Tile
                                                        heading="Contact"
                                                        className="p-3 px-4 pt-2 pb-4 overflow-auto"
                                                        height={"645px"}
                                                    >
                                                        <div className="container">
                                                            <div className="d-flex">
                                                                <div className={"me-4"}>
                                                                    <FormComboBox
                                                                        wf={true}
                                                                        isStyled={true}
                                                                        width={"260px"}
                                                                        className={"mt-2"}
                                                                        // required
                                                                        onChangeComboBox={(e, name) => {
                                                                            formRenderProps.onChange("ok", {
                                                                                value: 1,
                                                                            });
                                                                            handleComboBoxChange(e, name);
                                                                        }}
                                                                        onFilterChange={cateforyFilterChange}
                                                                        data={contactDataFilter}
                                                                        state={comboBoxState}
                                                                        boxShadow={true}
                                                                        textField="categoryName"
                                                                        label={"Category"}
                                                                        name={CONSTANT.COMBOBOX.CONTACT_LINK}
                                                                    />
                                                                    {isSubmitted &&
                                                                        !comboBoxState[
                                                                            CONSTANT.COMBOBOX.CONTACT_LINK
                                                                        ]?.categoryName && (
                                                                            <div className="fs-sm my-1 text-danger">
                                                                                <i>Category is required</i>
                                                                            </div>
                                                                        )}
                                                                </div>
                                                                <div>
                                                                    <FormComboBox
                                                                        wf={true}
                                                                        isStyled={true}
                                                                        width={"260px"}
                                                                        isFiltered={false}
                                                                        className={"mt-2"}
                                                                        onChangeComboBox={(e, name) => {
                                                                            formRenderProps.onChange("ok", {
                                                                                value: 1,
                                                                            });
                                                                            handleComboBoxChange(e, name);
                                                                        }}
                                                                        onFilterChange={typeFilterChange}
                                                                        data={typeData}
                                                                        boxShadow={true}
                                                                        state={comboBoxState}
                                                                        textField="name"
                                                                        name={CONSTANT.COMBOBOX.TYPE}
                                                                    />
                                                                    {isSubmitted &&
                                                                        !comboBoxState[CONSTANT.COMBOBOX.TYPE]
                                                                            ?.value && (
                                                                            <div className="fs-sm my-1 text-danger">
                                                                                <i>Type is required</i>
                                                                            </div>
                                                                        )}
                                                                </div>
                                                            </div>
                                                            {comboBoxState[CONSTANT.COMBOBOX.TYPE]?.value ===
                                                                "Company" && (
                                                                    <div className="d-flex mt-3">
                                                                        <div>
                                                                            {mode === MODE.NEW ? (
                                                                                <>
                                                                                    <Label> Company Name </Label>
                                                                                    <Field
                                                                                        disabled={mode === MODE.VIEW}
                                                                                        name="companyName"
                                                                                        ph="Company Name"
                                                                                        component={AutoCompleteInput}
                                                                                        itemRender={CompanyItemRender(
                                                                                            handleSelection
                                                                                        )}
                                                                                        formRenderProps={formRenderProps}
                                                                                    />
                                                                                </>
                                                                            ) : (
                                                                                <Field
                                                                                    disabled={mode === MODE.VIEW}
                                                                                    name="companyName"
                                                                                    label="Company Name"
                                                                                    ph="Company Name"
                                                                                    width={"260px"}
                                                                                    component={CustomInput}
                                                                                />
                                                                            )}
                                                                            {isSubmitted &&
                                                                                !formRenderProps.valueGetter(
                                                                                    "companyName"
                                                                                ) && (
                                                                                    <div className="fs-sm my-1 text-danger">
                                                                                        <i>Company Name is required</i>
                                                                                    </div>
                                                                                )}
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            {comboBoxState[CONSTANT.COMBOBOX.TYPE]?.value ===
                                                                "Person" && (
                                                                    <>
                                                                        <div className="d-flex mt-3">
                                                                            <div
                                                                                className="me-4"
                                                                                style={{
                                                                                    width: "260px",
                                                                                }}
                                                                            >
                                                                                <Field
                                                                                    name={`firstName`}
                                                                                    // required
                                                                                    label="First Name"
                                                                                    width={"260px"}
                                                                                    ph="Add Name"
                                                                                    component={CustomInput}
                                                                                />
                                                                                {isSubmitted &&
                                                                                    !formRenderProps.valueGetter(
                                                                                        "firstName"
                                                                                    ) && (
                                                                                        <div className="fs-sm my-1 text-danger">
                                                                                            <i>First Name is required</i>
                                                                                        </div>
                                                                                    )}
                                                                            </div>
                                                                            <div>
                                                                                <Field
                                                                                    name={`lastName`}
                                                                                    label="Last Name"
                                                                                    width={"260px"}
                                                                                    ph="Add Name"
                                                                                    component={CustomInput}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                )}
                                                            <ContactInformationComponent
                                                                readOnly={mode === MODE.VIEW}
                                                                addType={""}
                                                                type={
                                                                    comboBoxState[CONSTANT.COMBOBOX.TYPE]?.value
                                                                }
                                                            />
                                                        </div>
                                                    </Tile>
                                                </div>
                                                {comboBoxState[CONSTANT.COMBOBOX.TYPE]?.value ===
                                                    "Company" && (
                                                        <div className="col-12 col-xl-4">
                                                            <Tile
                                                                heading="Correpondence Person"
                                                                height={"645px"}
                                                                className="p-3 px-4 pt-2 pb-0 overflow-auto"
                                                            >
                                                                <div className="container">
                                                                    <div className="row mt-3">
                                                                        <div className="col">
                                                                            <Field
                                                                                disabled={mode === MODE.VIEW}
                                                                                name="ccFirstName"
                                                                                label="Name"
                                                                                ph="Name"
                                                                                width={"260px"}
                                                                                component={CustomInput}
                                                                            />
                                                                        </div>
                                                                    </div>{" "}
                                                                    <div className="row mt-3">
                                                                        <div className="col">
                                                                            <Field
                                                                                disabled={mode === MODE.VIEW}
                                                                                name="ccphone1"
                                                                                label="Phone Number"
                                                                                ph="Number"
                                                                                width={"260px"}
                                                                                type="number"
                                                                                component={CustomInput}
                                                                            />
                                                                        </div>
                                                                    </div>{" "}
                                                                    <div className="row mt-3">
                                                                        <div className="col">
                                                                            <Field
                                                                                disabled={mode === MODE.VIEW}
                                                                                name="ccemail"
                                                                                label="Email"
                                                                                ph="Email"
                                                                                width={"260px"}
                                                                                component={CustomInput}
                                                                                validator={emailValidator}
                                                                            />
                                                                        </div>
                                                                    </div>{" "}
                                                                    <div className="row mt-3">
                                                                        <div className="col">
                                                                            <Field
                                                                                disabled={mode === MODE.VIEW}
                                                                                name="ccJobTitle"
                                                                                label="Job Title"
                                                                                ph="Job Title"
                                                                                width={"260px"}
                                                                                component={CustomInput}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Tile>
                                                        </div>
                                                    )}
                                            </div>
                                        </div>
                                        <button
                                            id="contact-form-submit"
                                            type="submit"
                                            form="contact-form"
                                            style={{ visibility: "hidden" }}
                                        ></button>
                                    </FormElement>
                                );
                            }}
                        />
                    </div>
                )}
            </div>
            {!props?.attachContact ? (
                <DialogActionsBar>
                    <div className={`dialog-footer`}>
                        {mode !== MODE.VIEW && (
                            <button
                                className="common-btn bgBtnSecondary border-white me-3"
                                onClick={() => setMode(MODE.VIEW)}
                            >
                                Cancel
                            </button>
                        )}
                        <button
                            form="contact-form"
                            disabled={formSubmittig}
                            type={mode === MODE.VIEW ? "button" : "submit"}
                            onClick={() => {
                                if (mode === MODE.VIEW) {
                                    setMode(MODE.EDIT);
                                } else {
                                    document.getElementById("contact-form-submit").click();
                                    setIsSubmitted(true);
                                }
                            }}
                            className="k-button common-btn me-5"
                        >
                            {!props.viewMode
                                ? formSubmittig
                                    ? "Creating contact ..."
                                    : "Add Contact"
                                : mode === MODE.VIEW
                                    ? "Edit"
                                    : formSubmittig
                                        ? "Updating Contact"
                                        : "Update Contact"}
                        </button>
                    </div>
                </DialogActionsBar>
            ) : (
                <DialogActionsBar>
                    <div className={`dialog-footer`}></div>
                </DialogActionsBar>
            )}
            <MatterModal
                showModal={showModal}
                message={modalMessage}
                success={modalSuccess}
                onClose={handleModalClose}
            />
        </Dialog>
    );
};

export default AddNewContact;
