import * as React from "react";
import { MaskedTextBox, Input } from "@progress/kendo-react-inputs";
import { Label } from "@progress/kendo-react-labels";
import { useSelector, useDispatch } from "react-redux";
import { useRef, useState, useEffect } from "react";
import { getUserClaims } from "actions/currentuser.action";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { Checkbox } from "@progress/kendo-react-inputs";
import { TextArea } from "@progress/kendo-react-inputs";
import { toast } from "react-toastify";
import styles from "../Settings.module.scss";
import CalenderCustomToggleButton from "components/common/CalenderCustomToogleButton";

export const weekData = [
  { key: "Sunday", value: 7 },
  { key: "Monday", value: 1 },
  { key: "Tuesday", value: 2 },
  { key: "Wednesday", value: 3 },
  { key: "Thursday", value: 4 },
  { key: "Friday", value: 5 },
  { key: "Saturday", value: 6 },
];

export const workingDaysData = [
  { key: "Mon", value: 1, isChecked: false, label: "Monday" },
  { key: "Tues", value: 2, isChecked: false, label: "Tuesday" },
  { key: "Wed", value: 4, isChecked: false, label: "Wednesday" },
  { key: "Thurs", value: 8, isChecked: false, label: "Thursday" },
  { key: "Fri", value: 16, isChecked: false, label: "Friday" },
  { key: "Sat", value: 32, isChecked: false, label: "Saturday" },
  { key: "Sun", value: 64, isChecked: false, label: "Sunday" },
];

const mapValueToWeekObject = (value) => {
  const index = weekData.findIndex((d) => d.value === value);
  return weekData[index];
};

const CompanyProfile = (props) => {
  const [modalvalue, setmodalvalue] = useState(false);
  const [isError, setIsError] = useState(false);
  const [sub, setSub] = useState("");
  const subData = useSelector((state) => state.currentuser.userSub);
  const noContentSetup = useSelector((state) => state.company.noContent);
  const [weekStartDay, setWeekStartDay] = useState("");
  const [workingDays, setWorkingDays] = useState(workingDaysData);

  useEffect(() => {
    if (
      props.component === "Wizard" &&
      subData !== "" &&
      subData !== undefined
    ) {
      setTimeout(() => {
        setSub(subData);
      }, 100);
    }
  }, [subData]);
  const dispatch = useDispatch();

  const subdata = useSelector((state) => {
    return state.company.company;
  });

  const [data, setData] = useState({});
  useEffect(() => {
    if (subdata !== undefined) {
      const defaultWD = subdata?.workingDays?.split(",");
      const cleanData = defaultWD?.map((t) => t.trim());
      const finalWD = workingDays?.map((d) => {
        if (cleanData.includes(d.label)) {
          return {
            ...d,
            isChecked: true,
          };
        }
        return d;
      });
      setWorkingDays([...finalWD]);
      setData(subdata);
      setWeekStartDay(subdata.weekStartDay);
    }
  }, [subdata]);

  const handleWorkingDays = (event, day) => {
    const { value } = event;
    const workingDaysClone = [...workingDays];
    const dayIndex = workingDaysClone.findIndex((d) => d.value === day.value);
    workingDaysClone[dayIndex].isChecked = value;
    props.updateAddress({ workingDays: [...workingDaysClone] });
    setWorkingDays([...workingDaysClone]);
  };
  useEffect(() => {
    if (props.component === "Wizard") {
      dispatch(getUserClaims());
    }
  }, []);

  let SERVICE_DATA = {
    addressLine1: data?.addressLine1?.toString(),
    town: data?.town?.toString(),
    state: data?.state?.toString(),
    postcode: data?.postcode?.toString(),
    country: data?.country?.toString(),
    name: data?.displayName?.toString(),
    phone1: data?.phone1?.toString(),
    email1: data?.email1?.toString(),
    website: data?.website?.toString(),
    baseecn: data?.baseCurrency?.toString(),
  };

  const [serviceAddress, setServiceAddress] = useState(SERVICE_DATA);
  const [yearStartDate, setYearStartDate] = useState(new Date());

  const onChangeServiceAddress = (e) => {
    const name = e.target.name;
    let data = serviceAddress;
    data[name] = e.target.value;
    let dataChanged = {};
    dataChanged[e.target.name] = e.target.value;
    props.updateAddress(dataChanged);
    setServiceAddress({ ...data });
  };

  useEffect(() => {
    setServiceAddress({
      addressLine1: data?.addressLine1?.toString(),
      town: data?.town?.toString(),
      state: data?.state?.toString(),
      postcode: data?.postcode?.toString(),
      country: data?.country?.toString(),
      displayname: data?.displayName?.toString(),
      phone1: data?.phone1?.toString(),
      email1: data?.email1?.toString(),
      website: data?.website?.toString(),
      baseCurrency: data?.baseCurrency?.toString(),
    });
    setYearStartDate(new Date(data?.yearStartDate));
  }, [data]);

  return (
    <React.Fragment>
      <form>
        <div className={`d-flex page vw-100 ${styles["company-details"]}`}>
          <div className="col">
            <div className="row mt-4">
              <div className="col-4 d-flex flex-column">
                <Label className="label" style={{ width: "max-content" }}>
                  Company Name
                </Label>
                <Input
                  style={{ width: "350px", height: "44px" }}
                  placeholder="Enter Detail"
                  className="mt-1"
                  name="displayname"
                  value={serviceAddress.displayname}
                  onChange={(e) => {
                    onChangeServiceAddress(e);
                  }}
                />
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-12">
                <Label className="label">Address</Label>
                <div className="mt-1 d-flex">
                  <div className="">
                    <TextArea
                      disabled={props.readOnly}
                      placeholder={`Address`}
                      type="text"
                      value={serviceAddress.addressLine1}
                      onChange={onChangeServiceAddress}
                      required={false}
                      maxLength={60}
                      name={`addressLine1`}
                      className={`${
                        styles[`input-address-component mt-0`]
                      } col-3`}
                      autoSize={false}
                      style={{ width: "350px", height: "150px" }}
                    />
                  </div>
                  <div className="ms-2">
                    <Input
                      style={{
                        width: "320px",
                        maxWidth: "400px",
                        height: "44px",
                      }}
                      placeholder="Enter City"
                      className="mt-1"
                      name="town"
                      value={serviceAddress.town}
                      onChange={(e) => {
                        onChangeServiceAddress(e);
                      }}
                    />
                    <div className="d-flex">
                      <Input
                        style={{
                          width: "187px",
                          marginRight: "8px",
                          maxWidth: "400px",
                          height: "44px",
                        }}
                        placeholder="Enter State"
                        className="mt-1"
                        name="state"
                        value={serviceAddress.state}
                        onChange={(e) => {
                          onChangeServiceAddress(e);
                        }}
                      />
                      <Input
                        style={{
                          width: "125px",
                          maxWidth: "400px",
                          height: "44px",
                        }}
                        placeholder="Enter Postcode"
                        className="mt-1"
                        name="postcode"
                        value={serviceAddress.postcode}
                        onChange={(e) => {
                          onChangeServiceAddress(e);
                        }}
                      />
                    </div>
                    <Input
                      style={{
                        width: "320px",
                        maxWidth: "400px",
                        height: "44px",
                      }}
                      placeholder="Enter Country"
                      className="mt-1"
                      name="country"
                      value={serviceAddress.country}
                      onChange={(e) => {
                        onChangeServiceAddress(e);
                      }}
                    />
                  </div>
                </div>
                <div className="d-flex flex-column mt-4">
                  <Label className="label">Phone</Label>
                  <Input
                    style={{
                      width: "255px",
                      maxWidth: "400px",
                      height: "44px",
                    }}
                    placeholder="Enter Detail"
                    className="mt-1"
                    name="phone1"
                    value={serviceAddress.phone1}
                    onChange={(e) => {
                      onChangeServiceAddress(e);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-12 d-flex flex-column">
                <Label className="label">Email</Label>
                <Input
                  style={{ maxWidth: "100%", height: "44px" }}
                  placeholder="Enter Detail"
                  className="mt-1"
                  name="email1"
                  value={serviceAddress.email1}
                  onChange={(e) => {
                    onChangeServiceAddress(e);
                  }}
                />
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-12 d-flex flex-column">
                <Label className="label">Website</Label>
                <Input
                  style={{ maxWidth: "100%", height: "44px" }}
                  placeholder="Enter Detail"
                  className="mt-1"
                  name="website"
                  value={serviceAddress.website}
                  onChange={(e) => {
                    onChangeServiceAddress(e);
                  }}
                />
              </div>
            </div>
          </div>

          <div className="col mt-4">
            <div className="d-flex flex-column">
              <Label className="label">Week Start Day</Label>
              <ComboBox
                placeholder=""
                style={{ width: "255px", maxWidth: "400px", height: "44px" }}
                iconClassName="wa"
                className="mt-1"
                clearButton={false}
                defaultValue={mapValueToWeekObject(data?.weekStartDay)}
                textField="key"
                data={weekData}
                onChange={({ value }) => {
                  setWeekStartDay(value?.value);
                  props.updateAddress({ weekStartDay: value?.value });
                }}
              />
            </div>
            <div className="mt-4">
              <div className="d-flex flex-column">
                <Label className="label">Working Days</Label>
                {workingDays?.map((day, index) => {
                  return (
                    <div key={index}>
                      <div
                        className={`d-flex align-items-center justify-content-center ${
                          styles[`weekDays`]
                        } ${!day.isChecked && styles[`opacity`]} mt-2`}
                      >
                        <span className={`${styles[`daysNames`]}`}>
                          {day.label}
                        </span>
                        <Checkbox
                          id={day.value.toString()}
                          checked={day.isChecked}
                          className="mt-2"
                          key={day.value}
                          onChange={(e) => handleWorkingDays(e, day)}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </form>
    </React.Fragment>
  );
};
export default CompanyProfile;
