import * as React from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Loader } from "@progress/kendo-react-indicators";
import varaibles from "../../styles/_theme.scss";
import { useNavigate } from "react-router";
import { Button } from "@progress/kendo-react-buttons";

const AccessRevokedModal = (props) => {
  const images = require.context("../../assets/Logos", true);
  const navigate = useNavigate("");
  return (
    <div
      style={{
        backdropFilter: "blur(1px)",
      }}
    >
      <Dialog height={200} width={400}>
        <div className={`d-flex mt-3`}>
          <div className="justifiy-content-center d-flex flex-column align-items-center vw-100">
            <img
              src={images(varaibles.collapsedLogo?.replace(/['"]+/g, ""))}
            ></img>
            <div className="mt-3">
              <div className={`text-center mx-auto mb-2 fs-sm mt-1`}>
                <b>Access Revoked</b>
              </div>
              <div
                className={`mx-auto mb-2 fs-sm text-center mt-1`}
                style={{ color: "gray" }}
              >
                <b>You donot have enough permissions</b>
              </div>
            </div>
            <div className="mt-2">
              <Button
                className="common-btn"
                onClick={() => {
                  props.removeTab();
                  navigate("/");
                }}
              >
                Back
              </Button>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default AccessRevokedModal;
