import React from "react";
import { AmountFormat } from "components/common/formatter";
import styles from "./Matter.module.scss";


const MatterClientAccount = ({ currentmattersBal }) => {
    return (
        <table
            className="balance-table matter-balance-table"
            // height="100%"
            style={{ width: "100%" }}
        >
            <thead>
                <tr>
                    <th>BALANCES</th>
                    <th style={{ width: "10px" }}></th>
                    <th className="text-center text-truncate">Office Bal</th>
                    <th className="text-center text-truncate">Client Bal</th>
                    <th className="text-center text-truncate">DDA Bal</th>
                    <th className="text-center text-truncate">UP Bills</th>
                    <th className="text-center text-truncate">WIP</th>
                </tr>
            </thead>
            <tbody>
                <tr className="">
                    <td style={{ fontSize: "12px", minWidth: "80px" }}>                        
                        This Matter
                    </td>
                    <td></td>
                    <AmountTD value={currentmattersBal.matterBalances?.officeBalance} />
                    <AmountTD value={currentmattersBal.matterBalances?.clientBalance} />
                    <AmountTD value={currentmattersBal.matterBalances?.ddaBalance} />
                    <AmountTD value={currentmattersBal.matterBalances?.unpaidBills} />
                    <AmountTD value={currentmattersBal.matterBalances?.wipBalance} />
                </tr>
                <tr>
                    <td style={{ fontSize: "12px" }}>                      
                        All Matters
                    </td>
                    <td></td>
                    <AmountTD value={currentmattersBal.clientBalances?.officeBalance} />
                    <AmountTD value={currentmattersBal.clientBalances?.clientBalance} />
                    <AmountTD value={currentmattersBal.clientBalances?.ddaBalance} />
                    <AmountTD value={currentmattersBal.clientBalances?.unpaidBills} />
                    <AmountTD value={currentmattersBal.clientBalances?.wipBalance} />
                </tr>
            </tbody>
        </table>
    );
};

export default MatterClientAccount;

const AmountTD = ({ value }) => {
    return (
        <td className="text-center pe-1" style={{ width: "90px" }}>
            {AmountFormat(value)}
        </td>
    );
};

export const ClientList = ({ client }) => {
    return (
        <div>
            <div className={`${styles["section-title"]} ps-4 pt-3 `}>Clients</div>
            <table
                className="balance-table matter-balance-table"
                style={{ boxShadow: "none" }}
                height="100%"
            >
                <thead>
                    <tr>
                        <th>Name:</th>
                        <th className="">Phone Number:</th>
                        <th className="">Email:</th>
                        <th className="">Address:</th>
                    </tr>
                </thead>
                <tbody>
                    <tr className="position-relative">
                        <DataTD value={client?.clientName} />
                        <DataTD value={client?.phoneNo} />
                        <DataTD value={client?.email} />
                        <DataTD value={client?.address} />
                    </tr>
                    {/* <hr /> */}
                    <tr></tr>
                </tbody>
            </table>
        </div>
    );
};

const DataTD = ({ value }) => {
    return (
        <td className="pe-1" style={{ width: "90px" }}>
            {value}
        </td>
    );
};
