export function breakArrayIntoChunks(arr, maxLength) {
    let result = "";
    let currentString = "";
  
    arr?.forEach((word) => {
      if ((currentString + " " + word).trim().length <= maxLength) {
        if (currentString.length > 0) {
          currentString += " " + word;
        } else {
          currentString = word;
        }
      } else {
        result += (result.length > 0 ? " " : "") + currentString;
        currentString = word;
      }
    });
  
    if (currentString.length > 0) {
      result += (result.length > 0 ? " " : "") + currentString;
    }
  
    return result;
  }
  