import React, { useEffect } from "react";
import styles from "./index.module.scss";
import { useState } from "react";
import { Messages } from "./Messages";
import Tasks from "./Tasks";

export const ClientServices = ({ matter, showScreens, setShowScreens }) => {
  const [currentActiveTab, setCurrentActiveTab] = useState("Client Tasks");

  return (
    <>
      {currentActiveTab === "Messages" && (
        <Messages matterId={matter?.matterId} />
      )}
      {currentActiveTab === "Client Tasks" && (
        <Tasks
          matter={matter}
          showScreens={showScreens}
          setShowScreens={setShowScreens}
        />
      )}
      <div
        className={`${styles["comapany-proof-tabs"]} mt-3 px-4 d-flex align-items-center gap-2`}
      >
        <Menutem
          tabName="Client Tasks"
          active={currentActiveTab}
          onClick={(e) => setCurrentActiveTab(e)}
        />
        <div className={`${styles["horizontal-bar"]}`}></div>
        <Menutem
          tabName="Messages"
          active={currentActiveTab}
          onClick={(e) => setCurrentActiveTab(e)}
        />
        <div className={`${styles["horizontal-bar"]}`}></div>
        <Menutem
          tabName="Case Studies"
          active={currentActiveTab}
          onClick={(e) => setCurrentActiveTab(e)}
        />
        <div className={`${styles["horizontal-bar"]}`}></div>
        <Menutem
          tabName="Shared Documents"
          active={currentActiveTab}
          onClick={(e) => setCurrentActiveTab(e)}
        />
      </div>
    </>
  );
};

const Menutem = (props) => {
  return (
    <div
      className={`cursor-pointer ms-3 ${
        props.active === props.tabName
          ? styles["active-tab"]
          : styles["in-active-tab"]
      }`}
      onClick={(e) => props.onClick(props.tabName)}
    >
      {props.tabName}
    </div>
  );
};
