import * as React from "react";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { useDispatch } from "react-redux";
import {EditCommandCell } from "../../pages/Settings/GridComponents";


const List = ({ 
  editAction, 
  editFormComponent, 
  loaderComponent,
  columns, 
  ...props 
}) => {
  const [openForm, setOpenForm] = React.useState(false);
  const dispatch = useDispatch();
  const [editItem, setEditItem] = React.useState({
    ProductID: 1,
  });
  const enterEdit = (item) => {
    setOpenForm(true);
    setEditItem(item);
  };
  const handleSubmit = (event) => {
    const id = event.activityTypeID ? event.activityTypeID : event?.timeTypeID;
    dispatch(editAction(id, event));
    setOpenForm(false);
};
  const [products, setProducts] = React.useState({
    data: [],
    total: 20,
  });
  const [dataState, setDataState] = React.useState({
    take: 10,
    skip: 0,
  });

  const handleCancelEdit = () => {
    setOpenForm(false);
  };
  const MyEditCommandCell = (props) => (
    <EditCommandCell {...props} enterEdit={enterEdit} />
  );
  const dataStateChange = (e) => {
    setDataState(e.dataState);
  };

  const dataReceived = (products) => {
    products.data.map((item) => {
      return (item.symbol = item.name[0]);
    });
    setProducts(products);
  };

  const LoaderComponent = loaderComponent;
  const EditFormComponent = editFormComponent;

  return (
    <div className="settings-grid">
      <Grid {...dataState} data={products} onDataStateChange={dataStateChange}>
        {columns.map((column, index) => (
          <Column key={index} {...column} />
        ))}
        <Column title=" " cell={MyEditCommandCell} width="60" />
      </Grid>
      {openForm && (
        <EditFormComponent
          cancelEdit={handleCancelEdit}
          onSubmit={handleSubmit}
          item={editItem}
        />
      )}

      <LoaderComponent dataState={dataState} onDataReceived={dataReceived} />

    </div>
  );
};

export default List;