import * as React from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Input, TextArea } from "@progress/kendo-react-inputs";
import { Label } from "@progress/kendo-react-labels";
import { useState } from "react";
import { toast } from "react-toastify";
import { ContainerCard } from "components/MatterDetails/ContactCard";
import { Tile } from "components/UIComponents/Tile";
import { IoIosAddCircleOutline } from "react-icons/io";
import { UserScreens } from "./AddUserScreens";
import { Grid } from "@progress/kendo-react-grid";
import styles from "./CaseFiles.module.scss";
import Document from "../../assets/fileIcons/Document.svg";
import Interview from "../../assets/CaseFileIcons/ClientInterview.svg";
import EditIcon from "../../assets/common/editBoxIcon.png";
import DragHandleCell from "pages/CaseFile/CaseWorkFlow/DragHandleCell";
import { ReorderContext } from "pages/CaseFile/CaseWorkFlow/CaseWorkFlow";
import { SvgIcon } from "@progress/kendo-react-common";
import UserScreenIcon from "../../assets/CaseFileIcons/UserScreen.svg";
import { reorderIcon } from "@progress/kendo-svg-icons";
import { MdCancel } from "react-icons/md";
import { addCaseWorkflowRecord } from "api";
import AddEditDocTemplate from "./AddEditDocTemplate";
import ScreenDesigner from "pages/CaseFile/CaseWorkFlow/ScreenDesigner/ScreenDesigner";
import AddEditClientInterview from "./AddEditClientInterview";
import { DocumentTemplates } from "./AddDocumentTemplate";
import { ClientInterviews } from "./ClientInterviews";

const WorkFlowDialog = (props) => {
  const [folderName, setFolderName] = useState(
    props.isEdit ? props?.isEdit?.name : null
  );
  const [details, setDetails] = useState(
    props.isEdit ? props?.isEdit?.details : null
  );
  const [showScreens, setShowScreens] = useState(false);
  const [showDocTemplate, setShowDocTemplate] = useState(false);
  const [showClientInterview, setShowClientInterview] = useState(false);
  const [editScreen, setEditScreen] = useState();
  const [editDocTemplate, setEditDocTemplate] = useState();
  const [editClientInterview, setEditClientInterview] = useState();
  const [workFlowItems, setWorkFlowItems] = useState(
    props.isEdit ? [...props?.isEdit?.workflowItems] : []
  );
  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      if (props.isEdit) {
        props.editWorkFlow({
          ...props.isEdit,
          name: folderName,
          details: details,
          workflowItems: workFlowItems,
        });
      } else {
        props.addWorkFlow({
          name: folderName,
          details: details,
          workflowItems: workFlowItems,
        });
      }
    } catch (error) {
      console.log(error);
      toast.error("Unexpected Error Occured");
    }
  };

  const addScreens = (data = []) => {
    setWorkFlowItems([...workFlowItems, ...data]);
    setShowScreens(false);
  };

  const addDocTemplate = (data) => {
    const updatedData = data.map((template) => ({
      ...template,
      itemType: "Document",
      active: true,
    }));
    setWorkFlowItems([...workFlowItems, ...updatedData]);
    setShowDocTemplate(false);
  };

  const addClientInterview = (data) => {
    const updatedData = data.map((template) => ({
      ...template,
      itemType: "Interview",
      active: true,
    }));
    setWorkFlowItems([...workFlowItems, ...updatedData]);
    setShowClientInterview(false);
  };
  return (
    <Dialog
      title={`${props?.isEdit ? "Edit" : "Add"} Workflow`}
      onClose={props.cancelEdit}
      width={700}
    >
      <form onSubmit={handleSubmit}>
        <div className="px-4 py-3">
          <ContainerCard
            className="p-3 ps-4 pe-4 pt-2 pb-0 overflow-auto"
            height="160px"
          >
            <div className="mt-3">
              <Label className="label mb-1 w-100">Workflow Name&nbsp;</Label>
              <Input
                className="px-3 pt-1"
                style={{
                  width: "260px",
                  height: "32px",
                }}
                placeholder={"Add Folder"}
                name="folderName"
                required
                onChange={(e) => {
                  setFolderName(e.value);
                }}
                value={folderName}
                type="text"
              />
            </div>
            <div className="mt-2">
              <Label className="label mb-1 w-100">Details</Label>
              <TextArea
                style={{
                  width: "100%",
                  maxWidth: "none",
                  height: "32px",
                }}
                placeholder={"Enter Details"}
                id="details"
                name="details"
                className="px-2"
                onChange={(e) => {
                  setDetails(e.value);
                }}
                type="text"
                value={details}
                rows={1}
              />
            </div>
          </ContainerCard>

          <Tile
            heading="Workflow Items"
            className="px-4 py-2 mt-2 overflow-auto"
            headingItems={
              <div className="d-flex gap-2">
                <button
                  type="button"
                  className="button-small d-flex align-items-center bgsecondary text-black gap-1 px-2 py-0"
                  onClick={() => setShowScreens(true)}
                >
                  <IoIosAddCircleOutline size={16} /> New Screen
                </button>
                <button
                  type="button"
                  className="button-small d-flex align-items-center bgsecondary text-black gap-1 px-2 py-0"
                  onClick={() => setShowDocTemplate(true)}
                >
                  <IoIosAddCircleOutline size={16} /> Document Template
                </button>
                {/* <button
                  type="button"
                  className="button-small d-flex align-items-center bgsecondary text-black gap-1 px-2 py-0"
                  onClick={() => setShowClientInterview(true)}
                >
                  <IoIosAddCircleOutline size={16} /> Client Interview
                </button> */}
              </div>
            }
            height="300px"
          >
            <div className="container">
              {workFlowItems
                ?.filter((screen) => screen.active)
                .map((item, index) => {
                  if (item.itemType === "Document") {
                    return (
                      <UserDocumentFlowItem
                        item={item}
                        key={index}
                        index={index}
                        onRemove={(itemId) => {
                          setWorkFlowItems((prevItems) =>
                            [...prevItems].map((item) => {
                              if (itemId === item.documentTemplateId) {
                                return {
                                  ...item,
                                  active: false,
                                };
                              }
                              return item;
                            })
                          );
                        }}
                        editDocument={(item) => {
                          setEditDocTemplate(item.documentTemplateId);
                          // setShowDocTemplate(true);
                        }}
                      />
                    );
                  }
                  if (item.itemType === "Interview") {
                    return (
                      <UserClientInterviewFlowItem
                        item={item}
                        key={index}
                        index={index}
                        onRemove={(itemId) => {
                          setWorkFlowItems((prevItems) =>
                            [...prevItems].map((item) => {
                              if (itemId === item.clientInterviewId) {
                                return {
                                  ...item,
                                  active: false,
                                };
                              }
                              return item;
                            })
                          );
                        }}
                        editClientInterview={(item) => {
                          setEditClientInterview(item.clientInterviewId);
                        }}
                      />
                    );
                  } else {
                    return (
                      <UserScreenWorkFlowItem
                        item={item}
                        key={index}
                        index={index}
                        onRemove={(itemId) => {
                          setWorkFlowItems((prevItems) =>
                            [...prevItems].map((item) => {
                              if (itemId === item.userScreenId) {
                                return {
                                  ...item,
                                  active: false,
                                };
                              }
                              return item;
                            })
                          );
                        }}
                        editDocument={(item) => {
                          setEditScreen(item);
                        }}
                      />
                    );
                  }
                })}
            </div>
            {/* <ReorderContext.Provider
              value={{
                reorder: reorder,
                dragStart: dragStart,
              }}
            >
              <DragAndDrop> */}

            {/* </DragAndDrop>
            </ReorderContext.Provider> */}
          </Tile>
        </div>
        <div className="dialog-footer">
          <button className="common-btn me-4">{`Save`}</button>
        </div>
      </form>

      {showScreens && (
        <UserScreens
          matterId={props.matterId}
          addedScreens={
            workFlowItems?.filter((screen) => screen.active === true)
            // : []
          }
          addScreens={addScreens}
          cancelEdit={() => setShowScreens(false)}
          screenType="UserScreen"
        />
      )}
      {showDocTemplate && (
        <DocumentTemplates
          matterId={props.matterId}
          addedTemplates={
            workFlowItems?.filter(
              (screen) =>
                screen.active === true && screen.itemType === "Document"
            )
            // : []
          }
          addScreens={addDocTemplate}
          cancelEdit={() => setShowDocTemplate(false)}
        />
      )}

      {showClientInterview && (
        <ClientInterviews
          cancelEdit={() => setShowClientInterview(false)}
          addedTemplates={workFlowItems?.filter(
            (screen) =>
              screen.active === true && screen.itemType === "Interview"
          )}
          addScreens={addClientInterview}
        />
      )}

      {editScreen && (
        <ScreenDesigner
          matterId={props.matterId}
          screenId={editScreen?.userScreenId}
          onClose={(isSuccess, data) => {
            if (isSuccess && data) {
              setWorkFlowItems((workFlowItem) =>
                workFlowItem.map((item) => {
                  if (item.userScreenId === data.userScreenId) {
                    return {
                      ...item,
                      name: data.name,
                    };
                  }
                  return item;
                })
              );
            }
            setEditScreen();
          }}
        />
      )}
      {editDocTemplate && (
        <AddEditDocTemplate
          matterId={props.matterId}
          cancelEdit={() => {
            setEditDocTemplate();
          }}
          updateTemplates={(data) => {
            const id = "documentTemplateId";
            const updatedItems = workFlowItems.map((item) =>
              item[id] === data[id] ? { ...item, ...data } : item
            );
            setWorkFlowItems(updatedItems);
            // getUserTemplates();
            setEditDocTemplate();
          }}
          isEdit={editDocTemplate}
          component={"WORK_FLOW"}
        />
      )}
      {editClientInterview && (
        <AddEditClientInterview
          matterId={props.matterId}
          cancelEdit={() => {
            setEditClientInterview();
          }}
          updateTemplates={(data) => {
            const id = "clientInterviewId";
            const updatedItems = workFlowItems.map((item) =>
              item[id] === data[id] ? { ...item, ...data } : item
            );
            setWorkFlowItems(updatedItems);
            setEditClientInterview();
          }}
          isEdit={editClientInterview}
          component={"WORK_FLOW"}
        />
      )}
    </Dialog>
  );
};

export default WorkFlowDialog;

const WorkFlowItem = ({
  item,
  index,
  onRemove,
  editItem,
  title,
  icon,
  nameKey,
  detailsKey,
}) => {
  return (
    <div
      className={`row ${styles["items-list"]} d-flex align-items-center`}
      style={{
        backgroundColor: index % 2 === 0 && "#F9F9FF",
      }}
    >
      <div className="d-flex col-4 gap-3 align-items-center">
        <SvgIcon
          style={{
            pointerEvents: "none",
            height: "20px",
            width: "20px",
          }}
          icon={reorderIcon}
        />
        <img
          style={{
            pointerEvents: "none",
            height: "20px",
            width: "20px",
          }}
          src={icon}
        />
        <div
          className={`text-truncate ${styles["question-text"]}`}
          style={{
            fontSize: "14px",
          }}
        >
          {item?.name}
        </div>
      </div>

      <div className={`col-4 text-truncate ${styles["question-text"]}`}>
        {item?.[nameKey]}
      </div>

      <div className={`col-2 text-truncate ${styles["question-text"]}`}>
        {item?.[detailsKey]}
      </div>

      <div className="d-flex col-2 gap-2 justify-content-end">
        <div>
          <img
            className="cursor-pointer"
            style={{ width: "18px", height: "18px" }}
            src={EditIcon}
            onClick={() => editItem(item)}
            alt="Edit Icon"
          ></img>
        </div>
        <MdCancel
          color="red"
          className="cursor-pointer"
          size={20}
          onClick={() => {
            const id =
              item?.userScreenId ||
              item?.clientInterviewId ||
              item?.documentTemplateId;

            onRemove(id, item.type);
          }}
        />
      </div>
    </div>
  );
};

const UserScreenWorkFlowItem = (props) => (
  <WorkFlowItem
    {...props}
    title="User Screen"
    icon={UserScreenIcon}
    nameKey="name"
    detailsKey="details"
    editItem={props.editDocument}
  />
);

const UserDocumentFlowItem = (props) => (
  <WorkFlowItem
    {...props}
    title={props.editDocument?.name}
    icon={Document}
    nameKey="templateName"
    detailsKey="details"
    editItem={props.editDocument}
  />
);

const UserClientInterviewFlowItem = (props) => (
  <WorkFlowItem
    {...props}
    title={props.editClientInterview?.name}
    icon={Interview}
    nameKey="processingFileName"
    detailsKey="details"
    editItem={props.editClientInterview}
  />
);
