import {
    START_LOADING,
    STOP_LOADING,
    SUCCESS,
    ADD_NEW_APPLICATION,
    GET_APPLICATIONS,
    GET_ACTIVE_APPLICATIONS,
    EDIT_APPLICATIONS,
} from "../types/index";
import * as api from "../api/index.js";
import { handleApiError } from "Helper";
import { toast } from "react-toastify";

export const getApplications = (page, limit) => async (dispatch) => {
    try {
        dispatch({ type: START_LOADING });
        const datas = await api.getApplications();
        dispatch({ type: GET_APPLICATIONS, payload: datas.data });
        dispatch({ type: STOP_LOADING });
        dispatch({ type: SUCCESS, payload: datas.message });
    } catch (error) {
        handleApiError(error);
    }
};

export const editApplication = (id, data) => async (dispatch) => {

    try {
        await api.editApplication(id, data);
        toast.success(`Application '${data?.appName}' has been updated`);
        const dataResponse = await api.getApplications();
        dispatch({ type: GET_APPLICATIONS, payload: dataResponse.data });
    } catch (error) {
        console.log(error);
        toast.error(`Error occured while updating`);
        handleApiError(error);
    }
};

export const addnewApplication = (data) => async (dispatch) => {
    try {
        const datas = await api.addnewApplication(data);
        dispatch({ type: ADD_NEW_APPLICATION, payload: datas });
    } catch (error) {
        handleApiError(error);
    }
};

export const getActiveApplications = (page, limit) => async (dispatch) => {
    try {
        dispatch({ type: START_LOADING });
        const datas = await api.getActiveApplications();
        dispatch({ type: GET_ACTIVE_APPLICATIONS, payload: datas.data });
        dispatch({ type: STOP_LOADING });
        dispatch({ type: SUCCESS, payload: datas.message });
    } catch (error) {
        handleApiError(error);
    }
};
