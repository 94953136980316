import {
  START_LOADING,
  STOP_LOADING,
  ERROR,
  SUCCESS,
  CREATE_TIME,
  RESET_TIME_ERROR,
  TIME_ERROR,
  GET_RECORDED_TIME,
  GET_RECORDED_TIME_FOR_MATTER,
  GET_DASHBOARD_TIME_TARGET,
} from "../types/index.js";

const timeReducer = (
  state = {
    IsError: false,
    isSuccess: false,
    isLoading: undefined,
    error: null,
    recordedTime: [],
    MatterRecordedTime: {},
    timeTarget: [],
  },
  action
) => {
  switch (action.type) {
    case START_LOADING:
      return { ...state, isLoading: true };
    case STOP_LOADING:
      return { ...state, isLoading: false };
    case TIME_ERROR:
      return {
        ...state,
        error: true,
      };
    case CREATE_TIME:
      return {
        ...state,
        error: false,
      };
    case RESET_TIME_ERROR:
      return {
        ...state,
        error: null,
      };
    case ERROR:
      return { ...state, IsError: true, isSuccess: false };
    case SUCCESS:
      return { ...state, IsError: false, isSuccess: true };
    case GET_RECORDED_TIME:
      return { ...state, recordedTime: action.payload };
    case GET_RECORDED_TIME_FOR_MATTER:
      return {
        ...state,
        MatterRecordedTime: {
          ...state.MatterRecordedTime,
          [action.id]: action.payload,
        },
      };

    case GET_DASHBOARD_TIME_TARGET:
      return { ...state, timeTarget: action.payload };
    default:
      return state;
  }
};

export default timeReducer;
