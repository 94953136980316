import * as React from "react";
import { useSelector } from "react-redux";
import { Input } from "@progress/kendo-react-inputs";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { useState } from "react";
import { toast } from "react-toastify";

const CurrentTransactions = () => {
  const [state, changestate] = React.useState({
    period: 0,
    startDate: new Date(),
    endDate: new Date(),
  });
  var data = useSelector((state) => state.transactionperiods.currentPeriods);
  const [sessionPeriod, setSessionPeriod] = useState(
    JSON.parse(sessionStorage.getItem("sessionPeriod"))
  );
  const transactionsData = useSelector(
    (state) => state.transactionperiods.transactionList
  );
  React.useEffect(() => {
    setTimeout(() => {
      if (data !== null && data !== undefined) {
        changestate(data);
      }
      setSessionPeriod(JSON.parse(sessionStorage.getItem("sessionPeriod")));
    }, 1000);
  }, [data]);
  return (
    <React.Fragment>
      <div className="container pb-2">
        <div className="d-flex align-items-center gap-4">
          <div className="d-flex flex-column">
            <label className="label  mb-1">Current Year</label>
            <Input
              width={100}
              style={{
                width: "180px",
              }}
              defaultValue={`${new Date(state.startDate).getFullYear()}`}
              disabled={true}
            ></Input>
          </div>

          <div className="d-flex flex-column">
            <label className="label mb-1">Current Period</label>
            <Input
              style={{
                width: "180px",
              }}
              width={100}
              value={state.period}
              disabled={true}
            ></Input>
          </div>

          <div className="d-flex flex-column">
            <label className="label mb-1">Session Period</label>
            <ComboBox
              data={transactionsData.filter((item) => !item.closed)}
              textField={`period`}
              filterable={true}
              className="ps-2"
              iconClassName="wa"
              placeholder={"Session Period"}
              style={{
                width: "250px",
              }}
              value={sessionPeriod}
              onChange={(e) => {
                if (e.value === null) return;
                setSessionPeriod(e.value);
                sessionStorage.setItem(
                  "sessionPeriod",
                  JSON.stringify(e.value)
                );
                toast.success(`Session Period updated to ${e.value?.period}`)
              }}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CurrentTransactions;
