import React, { useState, useEffect } from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import { Label } from "@progress/kendo-react-labels";
import { RadioGroup } from "@progress/kendo-react-inputs";
import { Input } from "@progress/kendo-react-inputs";
import { Editor, EditorTools } from "@progress/kendo-react-editor";
import { sendMail, shareDocument } from "api";
import { fileIcon } from "components/common/fileIcon";
import styles from "./index.module.scss";
import { handleApiError } from "Helper";
import Loader from "components/common/Loading";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import { useUploadForm } from "components/fileUploadHook";
import { FileDocumentTypes } from "components/Enums/FileDocumentTypes.ts";
import { onFileUpload } from "Services/fileDataService";
import { CrossIcon } from "components/tabs/TabTitle";
import { BiLoaderCircle } from "react-icons/bi";
import { getSuggestedEmails } from "api";
import { toast } from "react-toastify";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import EmailListRenderer from "./EmailListRenderer";
const {
  Bold,
  Italic,
  Underline,
  AlignLeft,
  AlignRight,
  AlignCenter,
  Indent,
  Outdent,
} = EditorTools;

const DocumentItem = ({ doc, setFiles }) => (
  <div className={styles["document-item"]} style={{ minWidth: "150px" }}>
    {fileIcon(doc.filename)}
    <div>
      <span className="document-name-container ps-2 fs-sm ">
        {doc?.filename && doc?.filename?.length <= 20
          ? doc?.filename
          : doc?.filename.substring(
              0,
              20 -
                (doc?.filename.includes(".")
                  ? doc?.filename.split(".").pop()?.length + 1
                  : 0)
            ) +
            (doc?.filename.includes(".")
              ? "." + doc?.filename.split(".").pop()
              : "")}
      </span>
    </div>
    <CrossIcon
      onClick={() => {
        setFiles(doc.fileID);
      }}
    />
  </div>
);

const EmailCompletion = (props) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState({
    toAddress: {
      email: props?.data?.to && props?.data?.to?.split(",")[0],
    },
    ccAddress: props?.data?.to,
    // bccAddress: "",
    htmlBody: props?.data?.body,
    subject: props?.data?.subject,
  });

  const [isFileLoading, setIsFileLoading] = useState(false);
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const { uploadForm } = useUploadForm();
  const [currentDoc, setCurrentDoc] = useState(props?.currentDoc);
  const [editorValue, setEditorValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [dialogVisible, setDialogVisible] = useState(false);
  const [filesData, setFilesData] = useState([]);
  const [dialogMessage, setDialogMessage] = useState("");
  const [Error, setError] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [suggestedEmails, setSuggestedEmails] = useState([""]);

  const allFilesArePDFs =
    selectedDocuments?.length >= 1
      ? selectedDocuments.every((doc) => doc?.name?.endsWith(".pdf"))
      : currentDoc?.name.endsWith(".pdf");
  const filePickerRef = React.useRef();
  const uploadRef = React.createRef();

  const handleUpload = async (e) => {
    try {
      setIsFileLoading(true);
      let data = onFileUpload(e);
      const dataChoosen = await uploadForm(data, e.target.files[0], {
        documentType: FileDocumentTypes.CaseFile,
        matterId: props.matter?.matterId,
      });
      data.fileID = dataChoosen.data.data.fileID;
      // setFileId(data.fileID);

      setSelectedDocuments([
        ...selectedDocuments,
        {
          ...dataChoosen?.data?.data,
        },
      ]);
    } catch (error) {
      toast.error("Error occured while adding attachement");
    } finally {
      setIsFileLoading(false);
    }
  };

  useEffect(() => {
    const fetchSuggestedEmails = async () => {
      try {
        const emails = await getSuggestedEmails(props.matter?.matterId);
        setSuggestedEmails(emails?.data?.data);
      } catch (error) {
        console.error("Failed to fetch suggested emails", error);
      } finally {
      }
    };

    fetchSuggestedEmails();
  }, []);

  const handleEmailChange = (event) => {
    setEmail({ ...email, toAddress: event.value });
  };

  const handleShareDocument = async () => {
    let isError = false;
    Object.keys(email).forEach((field) => {
      if (field === "ccAddress") return;
      if (
        email[field] === undefined ||
        email[field] === "" ||
        email[field] === null
      ) {
        isError = true;
      }
    });
    if (isError) {
      setError("All Fields are required");
      return;
    }
    setIsLoading(true);
    setError(undefined);

    const data = {
      ...email,
      toRecipients: email.toAddress.email.split(","),
      ccRecipients:
        email.ccAddress !== "" ? email.ccAddress.split(",") : undefined,
      isHtmlBody: true,
      attachmentFileId: selectedDocuments?.length
        ? [
            ...(selectedDocuments?.length > 0
              ? selectedDocuments.map((doc) => doc.fileID)
              : [currentDoc?.id]),
          ]
        : undefined,
    };
    try {
      await sendMail(props.matter?.matterId, {
        data: data,
      });
      toast.success("Mail Sent");
      props.onClose();
    } catch (error) {
      handleApiError(error);
      toast.error("An error occurred while sending mail.");
    }
    setIsLoading(false);
  };

  const closeDialog = () => {
    setDialogVisible(false);
    setDialogMessage("");
    if (isSuccess) {
      props.onClose();
    }
  };

  const itemRender = (li, itemProps) => (
    <EmailListRenderer li={li} itemProps={itemProps} />
  );

  return (
    <>
      {/* {!dialogVisible && ( */}
      <Dialog
        title={"Email Composition"}
        onClose={props.onClose}
        width={"50vw"}
      >
        <div className={`px-5 pb-4`}>
          <div className="d-flex justify-content-between flex-wrap mb-2">
            {isLoading && <Loader />}
          </div>
          <div className="d-flex align-items-center border-less-inputs">
            <Label
              style={{
                width: "80px",
              }}
              className="fs-sm"
            >
              To:
            </Label>
            <ComboBox
              placeholder="Enter Email"
              style={{
                width: "100%",
                maxWidth: "100%",
              }}
              data={suggestedEmails}
              textField="email"
              value={email["toAddress"]}
              onChange={handleEmailChange}
              itemRender={itemRender}
              allowCustom
            />
          </div>
          <hr className="mb-1 mt-1" />
          <div className="d-flex align-items-center border-less-inputs">
            <Label
              style={{
                width: "80px",
              }}
              className="fs-sm"
            >
              Cc:
            </Label>
            <Input
              placeholder={`Enter Emails`}
              style={{
                width: "100%",
                maxWidth: "100%",
              }}
              value={email["ccAddress"]}
              onChange={(e) =>
                setEmail({ ...email, ccAddress: e.target.value })
              }
            />
          </div>
          <hr className="mb-1 mt-1" />
          <div className="d-flex align-items-center border-less-inputs">
            <Label
              style={{
                width: "80px",
              }}
              className="fs-sm"
            >
              Subject:
            </Label>
            <Input
              placeholder={`Enter Subject`}
              style={{
                width: "100%",
                maxWidth: "100%",
              }}
              value={email["subject"]}
              required={true}
              onChange={(e) => setEmail({ ...email, subject: e.target.value })}
            />
          </div>
          <hr className="mb-3 mt-1" />
          <span className="text-danger fs-sm mt-2">{Error}</span>
          <div className="d-flex  mt-2 mb-3">
            <div
              className={`${styles["document-item"]} align-items-center justify-content-center me-3`}
              style={{
                minWidth: "150px",
                height: "35px",
              }}
              onClick={(e) => filePickerRef?.current?.click()}
            >
              <span className="text-center document-name-container px-3 py-2 fs-sm cursor-pointer">
                {isFileLoading ? <BiLoaderCircle /> : "Attach Document"}
              </span>
            </div>
            <div className={`${styles["vertical-bar"]} me-3 mt-1`}></div>
            <div className="d-flex flex-wrap gap-2">
              {selectedDocuments?.length > 0 &&
                selectedDocuments?.map((doc, index) => (
                  <DocumentItem
                    key={doc.id}
                    doc={doc}
                    setFiles={(id) => {
                      setSelectedDocuments((prevFiles) =>
                        prevFiles.filter((doc) => doc.fileID !== id)
                      );
                    }}
                  />
                ))}
            </div>
          </div>
          <Editor
            tools={[
              [Bold, Italic, Underline],
              [AlignLeft, AlignCenter, AlignRight],
              [Indent, Outdent],
            ]}
            contentStyle={{
              height: 260,
            }}
            defaultContent={""}
            value={email["htmlBody"] || ""}
            onChange={(e) => setEmail({ ...email, htmlBody: e.html })}
          />
        </div>

        <div className="dialog-footer">
          <Button
            className="common-btn me-4"
            type="submit"
            onClick={handleShareDocument}
          >
            Send
          </Button>
        </div>
      </Dialog>
      {/* )} */}
      {/* {dialogVisible && (
        <Dialog title={"Message"} onClose={closeDialog} width={"25vw"}>
          <div className="d-flex flex-column align-items-center p-4">
            {isSuccess ? (
              <FaCheckCircle size={32} color="green" />
            ) : (
              <FaTimesCircle size={32} color="red" />
            )}
            <p className="my-4">{dialogMessage}</p>
          </div>
          <div className="pe-4 dialog-footer w-100">
            <Button className="common-btn ms-auto" onClick={closeDialog}>
              Close
            </Button>
          </div>
        </Dialog>
      )} */}
      <input
        name="file"
        type="file"
        onChange={handleUpload}
        ref={filePickerRef}
        style={{
          visibility: "hidden",
        }}
      />
    </>
  );
};

export default EmailCompletion;
