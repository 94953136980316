import React, { useState, useRef, useEffect } from "react";
import { Button } from "@progress/kendo-react-buttons";
import {
    GridColumnMenuItemGroup,
    GridColumnMenuItemContent,
} from "@progress/kendo-react-grid";
export const ColumnsButton = (props) => {
    const [columns, setColumns] = useState(props.columnsData);
    const [columnsExpanded, setColumnsExpanded] = useState(false);
    const [filterExpanded, setFilterExpanded] = useState(false);
    const [value, setValue] = React.useState(undefined);

    const onColumnsSubmit = (columnsState) => {
        setColumns(columnsState);
    };
    const onToggleColumn = (id) => {
        const newColumns = columns.map((column, idx) => {
            return idx === id ? { ...column, show: !column.show } : column;
        });
        setColumns(newColumns);
        props.onSubmit(newColumns);
    };

    const onReset = (event) => {
        event.preventDefault();
        const newColumns = columns.map((col) => {
            return { ...col, show: true };
        });
        setColumns(newColumns);
        onColumnsSubmit(newColumns);
        props.onSubmit(newColumns);
        onCloseMenu();
    };
    const onMenuItemClick = () => {
        const value = !columnsExpanded;
        setColumnsExpanded(value);
        setFilterExpanded(value ? false : filterExpanded);
    };

    // const onSubmit = (event) => {
    //   if (event) {
    //     event.preventDefault();
    //   }
    //   onColumnsSubmit(columns);
    //   onCloseMenu();
    //   props.onSubmit(columns);
    // };
    const onCloseMenu = () => {
        setColumnsExpanded(value);
    };
    const onFilterExpandChange = (value) => {
        setFilterExpanded(value);
        setColumnsExpanded(value ? false : columnsExpanded);
    };

    const oneVisibleColumn = columns.filter((c) => c.show).length === 1;
    return (
        <div style={{ marginRight: 20 }}>
            <Button className="common-btn" onClick={onMenuItemClick}>
                {" "}
                <span className="column me-2"> Columns </span>{" "}
                <span className={"ps-3 k-icon k-i-arrow-chevron-down columnArrowStyling"} />{" "}
            </Button>
            <GridColumnMenuItemGroup>
                <GridColumnMenuItemContent
                    show={columnsExpanded}
                    className="popUpDropDown"
                >
                    <div className={"k-column-list-wrapper"}>
                        <form onReset={onReset}>
                            <div className={"k-column-list columnPadding"}>
                                {columns.map((column, idx) => (
                                    <div key={idx} className={"k-column-list-item"}>
                                        <span>
                                            <input
                                                id={`column-visiblity-show-${idx}`}
                                                className="k-checkbox k-checkbox-md k-rounded-md"
                                                type="checkbox"
                                                readOnly={true}
                                                disabled={column.show && oneVisibleColumn}
                                                checked={column.show}
                                                onClick={() => {
                                                    onToggleColumn(idx);
                                                }}
                                            />
                                            <label
                                                htmlFor={`column-visiblity-show-${idx}`}
                                                className="k-checkbox-label"
                                                style={{
                                                    userSelect: "none",
                                                }}
                                            >
                                                {column.title}
                                            </label>
                                        </span>
                                    </div>
                                ))}
                            </div>
                            <div className={"k-actions k-hstack k-justify-content-stretch"}>
                                <button
                                    type={"reset"}
                                    className={
                                        "k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                                    }
                                >
                                    Reset
                                </button>
                                {/* <button
                  className={
                    "k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                  }
                  style={{
                    backgroundColor: "#0b1dc9",
                    borderColor: "#0b1dc9",
                  }}
                >
                  Save
                </button> */}
                            </div>
                        </form>
                    </div>
                </GridColumnMenuItemContent>
            </GridColumnMenuItemGroup>
        </div>
    );
};
