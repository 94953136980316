import React, { useState, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import "@progress/kendo-react-intl";
import "@progress/kendo-react-dropdowns";
import "react-router-dom";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { Button } from "@progress/kendo-react-buttons";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Label } from "@progress/kendo-react-labels";
import { Input } from "@progress/kendo-react-inputs";
import { HandleGridBody } from "components/common/HandleGridBody.js";
import { changeLedgerLink } from "actions/ledgerLinks.action.js";
import { Menu, MenuItem } from "@progress/kendo-react-layout";
import { Checkbox } from "@progress/kendo-react-inputs";
import AddNewSupplier from "./AddNewSupplier.jsx";
import { AmountFormatCell } from "components/common/formatter";
import SearchTextBox from "components/SearchComponent.jsx";
import styles from "./Supplier.module.scss";
import { getViewSupplier } from "actions/suppliers.action.js";
import { getactiveVats } from "actions/vat.action.js";
import { getActiveCurrency } from "actions/currency.action.js";
import { useNavigate } from "react-router-dom";
import {
  BoldTextCell,
  NameTextCell,
  RedirectCell,
} from "components/common/GridComponents.jsx";

const Suppliers = ({ type, component }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [viewType, setViewType] = useState(null);
  const [visible, setVisible] = useState(false);
  const [value, setValue] = useState(undefined);
  const [list, setLists] = useState({
    data: [],
    total: 50,
  });
  const [dataState, setDataState] = useState({
    take: 50,
    skip: 0,
    sort: [
      {
        field: "supplierRef",
        dir: "asc",
      },
    ],
  });
  const [eventDataState, setEventDataState] = useState();
  const searchRef = useRef();
  const [archiveChecked, setArchiveChecked] = useState(false);
  const archiveChange = () => setArchiveChecked(!archiveChecked);
  const [searchValue, setSearchValue] = useState();
  const [isSearched, setIsSearched] = useState(false);

  const editorId = "firstName";
  const toggleDialog = (e) => {
    setVisible(!visible);
  };
  const createState = (skip, take) => {
    let pagerSettings = {
      pageSizes: [10, 25, 50, 100],
    };
    return {
      pageSize: take,
      pageable: pagerSettings,
    };
  };
  const [state, setState] = useState(createState(0, 10));

  const handleAction = (supplierId) => {
    dispatch(getViewSupplier(supplierId));
    setViewType("View");
    // if (e.item.text == "Edit") {
    //     setViewType('Edit')
    // } else if (e.item.text == "View") {
    // }
  };
  useEffect(() => {
    dispatch(getactiveVats());
    dispatch(getActiveCurrency());
  }, [dispatch]);

  const handleRefresh = () => {
    setIsSearched(false);
    setSearchValue("");
    setArchiveChecked(false);
    searchRef.current.clearSearch();
    if (eventDataState) {
      setDataState({
        ...eventDataState?.event,
        skip: 0,
      });
    } else {
      setDataState({
        ...dataState,
        take: 50,
        skip: 0,
      });
    }
  };

  const dataStateChange = (e) => {
    const eventObject = { event: e.dataState };
    setEventDataState(eventObject);
    if (
      e.dataState.sort !== undefined &&
      e.dataState.skip !== 0 &&
      dataState.sort !== e.dataState.sort
    ) {
      setDataState({ ...e.dataState, skip: 0 });
    } else if (e.dataState.sort[0] === undefined) {
      setDataState({
        ...e.dataState,
        sort: [
          {
            field: dataState.sort[0].field,
            dir: "asc",
          },
        ],
      });
    } else {
      setDataState(e.dataState);
    }
  };

  const dataReceived = (list) => {
    if (list.data) {
      setLists(list);
    }
  };
  const dotsImg = (e) => {
    return (
      <td>
        <Menu
          onSelect={(event) => {
            handleAction(event, e.dataItem.id);
          }}
        >
          <MenuItem icon="k-icon k-i-more-vertical" style={{ marginLeft: -8 }}>
            <MenuItem text="Edit" />
            <MenuItem text="View" />
          </MenuItem>
        </Menu>
      </td>
    );
  };

  const Redirect = (props) => (
    <RedirectCell handleClick={() => handleAction(props.dataItem.id)} />
  );

  const LedgerCell = (props) => {
    return (
      <td>
        <span
          style={{ cursor: "pointer" }}
          onClick={() => {
            dispatch(
              changeLedgerLink("Suppliers", {
                supplierId: props.dataItem.supplierId,
                supplierRef: props.dataItem.supplierRef,
                supplierName: props.dataItem.supplierName,
                payeeName: props.dataItem.payeeName,
              })
            );
            navigate("/purchase/supplierledger");
          }}
        >
          <u>{props.dataItem.supplierRef}</u>
        </span>
      </td>
    );
  };
  const children = visible ? (
    <div className="content">
      <Dialog
        title={"Please confirm"}
        onClose={toggleDialog}
        className="mattersDialog"
      >
        <h1
          style={{
            marginBottom: "20px",
            fontWeight: 500,
          }}
        >
          Posting Details
        </h1>
        <div className="mattersDialogField">
          <div className="label">
            <Label editorId={editorId}>Date&nbsp;</Label>
          </div>
          <span> 11/05/2022</span>
        </div>
        <div className="mattersDialogField">
          <div className="label">
            <Label editorId={editorId}>Ref&nbsp;</Label>
          </div>
          <span>9872001</span>
        </div>
        <div className="mattersDialogField">
          <div className="label">
            <Label editorId={editorId}>Details&nbsp;</Label>
          </div>
          <span>Payment to National Bridges</span>
        </div>
        <div className="mattersDialogField">
          <div className="label">
            <Label editorId={editorId}>Net Amount&nbsp;</Label>
          </div>
          <span>90.00</span>
        </div>
        <div className="mattersDialogField">
          <div
            className="label"
            style={{
              marginTop: "7px",
            }}
          >
            <Label editorId={editorId}>Notes&nbsp;</Label>
          </div>
          <Input
            id={editorId}
            value={value}
            onChange={(e) => setValue(e.value)}
          />
        </div>
        <div className="mattersDialogButtons">
          <button className="k-button reverseBtn" onClick={toggleDialog}>
            REVERSE
          </button>
          <button className="k-button editBtn" onClick={toggleDialog}>
            EDIT
          </button>
        </div>
      </Dialog>
    </div>
  ) : null;

  return (
    <div className="page">
      <div className="grid-layout-container" style={{ marginBottom: 20 }}>
        <div className="title menu-button flex justify-space-between align-items-center">
          <div className="d-flex">
            <SearchTextBox ref={searchRef} changeFilterValue={setSearchValue} />
            <Button
              className="ms-2 border-0 common-btn"
              icon="refresh"
              onClick={handleRefresh}
            >
              Refresh
            </Button>
            <div
              className="ps-4"
              style={{
                flexDirection: "column",
                display: "flex",
                fontSize: 15,
                fontWeight: "500",
              }}
            >
              <Checkbox defaultChecked={false} label={"Show Zero Balance"} />
              <Checkbox
                defaultChecked={false}
                checked={archiveChecked}
                onClick={archiveChange}
                label={"Include Archive"}
              />
            </div>
          </div>
          {!type && (
            <div>
              <Button
                className="common-btn"
                onClick={() => {
                  setViewType("Add");
                }}
              >
                Add Supplier
              </Button>
            </div>
          )}
        </div>
      </div>
      {children}
      {viewType !== null && (
        <AddNewSupplier
          viewType={viewType}
          onClose={() => {
            setViewType(null);
          }}
        />
      )}
      <Grid
        {...dataState}
        data={list}
        onDataStateChange={dataStateChange}
        className={`${styles.suppliers_gridheight} mb-1`}
        sortable={true}
        pageable={state.pageable}
        pageSize={state.pageSize}
        resizable={true}
      >
        <GridColumn
          field="supplierRef"
          title="Supplier Ref"
          width="140px"
          cell={LedgerCell}
        />
        <GridColumn
          field="supplierName"
          title="Supplier Name"
          className="gridBoldField"
        />

        <GridColumn field="currencyCode" title="Currency" width="100px" />
        <GridColumn field="payeeName" title="Payee Name" />
        <GridColumn field="transactionTypes" title="Type" width="120px" />
        <GridColumn
          field="balance"
          title="Balance"
          className="balanceCol balanceTd"
          cell={AmountFormatCell}
          width="120px"
          headerClassName="grid-header-center"
        />
        {archiveChecked && (
          <GridColumn field="archived" title="Archived" width="80px" />
        )}
        <GridColumn width="60px" cell={Redirect} sortable={false} />
      </Grid>

      <HandleGridBody
        dataState={dataState}
        onDataReceived={dataReceived}
        currentComponent={component ? component : "suppliers"}
        searchValue={searchValue}
        isSearched={isSearched}
        type={type ? type : null}
      />
    </div>
  );
};
export default Suppliers;
