import {
  START_LOADING,
  STOP_LOADING,
  ERROR,
  SUCCESS,
  TRANS_POSTING_DETAILS,
  TRANS_POSTING_DETAILS_RESET,
} from "../types/index.js";

const TransactionReducer = (
  state = {
    IsError: false,
    isSuccess: false,
    isLoading: undefined,
    transPostingList: {
      doubleEntries: [],
      postingInfo: {},
    },
  },
  action
) => {
  switch (action.type) {
    case START_LOADING:
      return { ...state, isLoading: true };
    case STOP_LOADING:
      return { ...state, isLoading: false };
    case TRANS_POSTING_DETAILS:
      return {
        ...state,
        transPostingList: action.payload.data.data,
      };
    case TRANS_POSTING_DETAILS_RESET:
      return {
        IsError: false,
        isSuccess: false,
        isLoading: undefined,
        transPostingList: {
          doubleEntries: [],
          postingInfo: {},
        },
      };

    case ERROR:
      return { ...state, IsError: true, isSuccess: false };
    case SUCCESS:
      return { ...state, IsError: false, isSuccess: true };
    default:
      return state;
  }
};
export default TransactionReducer;
