export const prepareFeeearnerData = (data) => {
  let list = data?.map((item) => {
    const templist = item;
    templist.representation = item?.userRef + "  -  " + item?.displayname;

    return templist;
  });
  return list;
};

export const prepareApplicationData = (data) => {
  let list = data?.map((item) => {
    const templist = item;
    templist.representation = item?.appCode + "  -  " + item?.appName;

    return templist;
  });
  return list;
};

export const preparePostingTypeData = (data) => {
  let list = data.map((item) => {
    const templist = item;
    templist.representation = item.code + "  -  " + item.name;
    return templist;
  });
  return list;
};
