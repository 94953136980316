import { Button } from "@progress/kendo-react-buttons";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { PageTabItemTitle } from "components/tabs/TabItem/PageTabItemTitle";
import React from "react";
import { useState } from "react";
import CompanyAccounting from "./CompanyAccounting";
import CompanyProfile from "./CompanyDetails";
import styles from "../Settings.module.scss";
import { updateCompany } from "actions/company.action";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import MatterSetup from "./MatterSetup";

const Company = () => {
    const [tabs, setTabs] = useState([
        "Company Details",
        "Financial Settings",
    ]);
    const [selected, setSelected] = React.useState(0);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [serviceAddress, setServiceAddress] = useState({});
    const updateAddress = (data) => {
        if (data.workingDays !== undefined && data.workingDays !== null) {
            const selWd = [];
            data?.workingDays?.map((day) => {
                if (day?.isChecked) {
                    selWd.push(day?.label);
                }
            });
            data.workingDays = selWd?.toString(",");
        }
        setServiceAddress({ ...serviceAddress, ...data });
    };
    const handleSelect = (e) => {
        setSelected(e.selected);
    };
    const subdata = useSelector((state) => {
        return state.company.company;
    });
    const submitHandler = () => {
        if (Object.keys(serviceAddress).length === 0) {
            toast.success("Company Updated");
        } else {
            dispatch(updateCompany(subdata.id, serviceAddress));
        }
    };
    return (
        <div className={`${styles[`company`]}`}>
            <TabStrip
                keepTabsMounted={true}
                selected={selected}
                onSelect={handleSelect}
                className="mt-3 pagetab"
            >
                {tabs.map((item, index) => {
                    return (
                        <TabStripTab
                            key={index}
                            title={
                                <PageTabItemTitle
                                    title={item}
                                    index={tabs.indexOf(item)}
                                    selected={selected}
                                />
                            }
                        >
                            <div className={`${styles[`company-tabs`]}`}>
                                {item === "Company Details" && (
                                    <CompanyProfile updateAddress={updateAddress} />
                                )}
                                {item === "Financial Settings" && (
                                    <CompanyAccounting updateAddress={updateAddress} />
                                )}
                            </div>
                        </TabStripTab>
                    );
                })}
            </TabStrip>
            {/* <div
        className={`d-flex float-end mt-auto ${styles[`button-margin`]}`}
      ></div> */}
            <div
                className={`${styles["buttons-container"]} d-flex align-items-center justify-content-between col-12 p-3 mt-2`}
                style={{ width: "90%", }}
            >
                <div></div>
                <div className="d-flex align-items-center gap-2 me-2">
                    <Button
                        className={`common-btn bgBtnSecondary me-3`}
                        type="button"
                        onClick={() => {
                            navigate(`/settings`);
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        className={`common-btn`}
                        type="button"
                        onClick={() => {
                            submitHandler();
                        }}
                    >
                        Update
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default Company;
