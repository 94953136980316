import * as React from "react";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { DateFormat } from "components/common/formatter";
import * as api from "api/index";
import styles from "./Matter.module.scss";
import DataList from "components/MatterDetails/DataList";
import { handleApiError } from "Helper";
import { ContainerCard } from "components/MatterDetails/ContactCard";
import { Checkbox } from "@progress/kendo-react-inputs";


const MattersCompletionPage = (props) => {
    const matterDetails = useSelector((state) => state.mattersList);
    const [balances, setBalances] = useState({});

    let locale = useSelector((state) => state.company.company.locale);
    const [currentMatterDetails, setcurrentMatterDetails] = useState();
    useEffect(() => {
        if (matterDetails !== null) {
            setcurrentMatterDetails(
                matterDetails?.matterViewDetails[props.reference]?.data
            );
        }
    });

    const dataGetter = async () => {
        try {
            const data = await api.getMattersBalances(currentMatterDetails.matterId);
            if (data?.data?.data !== undefined) {
                setBalances(data.data.data);
            }
        } catch (error) {
            handleApiError(error);
        }
    };

    useEffect(() => {
        if (currentMatterDetails !== undefined) {
            dataGetter();
        }
    }, [currentMatterDetails]);

    return (
        <div className={`container-fluid`}>
            <div className="row mt-3 g-2 d-flex">
                <div className="col-12 col-xl-6">
                    <ContainerCard
                        height={"220px"}
                        
                        className="mt-2 mb-3 p-3 ps-5 pe-5 pt-2 pb-0 overflow-auto"
                    >
                        <div className="">
                            <div
                                className="d-flex align-items-center justify-content-between"
                                style={{ height: "50px" }}
                            >
                                <div className={`${styles["section-title"]}`}>Completion</div>
                            </div>
                            <hr className="mt-0" />
                                                        

                            <div>
                                <DataList
                                    text="Closed:"
                                    value={
                                        <Checkbox
                                            readOnly={true}
                                            value={currentMatterDetails?.closed}
                                        />
                                    }
                                    width={"w-100"}
                                    valueClass={"ms-4"}
                                    keyStyles={{ minWidth: "150px" }}
                                //   className={'mt-1'}
                                />
                            </div>
                            <div>
                                <DataList
                                    text="Closed Date:"
                                    value={DateFormat(currentMatterDetails?.dateClosed)}
                                    width={"w-100"}
                                    valueClass={"ms-4"}
                                    keyStyles={{ minWidth: "150px" }}
                                //   className={'mt-1'}
                                />
                            </div>
                            <div>
                                <DataList
                                    text="Location:"
                                    value={currentMatterDetails?.branch}
                                    width={"w-100"}
                                    valueClass={"ms-4"}
                                    //   className={'mt-1'}
                                    keyStyles={{ minWidth: "150px" }}
                                />
                            </div>
                        </div>
                    </ContainerCard>
                </div>

                <div className="col-12 col-xl-6">
                    <ContainerCard
                        height={"220px"}                       
                        className="mt-2 mb-3 p-3 ps-4 pe-4 pt-2 pb-0 overflow-auto"
                    >
                        <div className="mx-auto" style={{ width: "90%" }}>
                            <div
                                className="d-flex align-items-center justify-content-between"
                                style={{ height: "50px" }}
                            >
                                <div className={`${styles["section-title"]}`}>GDRP</div>
                            </div>
                            <hr className="mt-0" />
                            <div className="mt-3 d-flex gap-5 w-100  w-80 mx-auto justify-content-between">
                                <div className="">
                                    <DataList
                                        statusChart={true}
                                        // className="mt-3"
                                        valueClass={"ms-4"}
                                        text="Deleted:"
                                        value={
                                            <Checkbox
                                                readOnly={true}
                                                value={currentMatterDetails?.closed}
                                            />
                                        }
                                        width={"w-100"}
                                        keyStyles={{ minWidth: "150px" }}
                                    />
                                    <DataList
                                        text="Date Deleted:"
                                        value={DateFormat(currentMatterDetails?.dateDeleted)}
                                        width={"w-100"}
                                        valueClass={"ms-4"}
                                        //   className={'mt-1'}
                                        keyStyles={{ minWidth: "150px" }}
                                    />
                                </div>
                            </div>
                        </div>
                    </ContainerCard>
                </div>
            </div>
        </div>
    );
};

export default MattersCompletionPage;
