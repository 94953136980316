import {
  START_LOADING,
  STOP_LOADING,
  ERROR,
  SUCCESS,
  GET_CONTACTS_LIST,
  GET_CONTACTS_INFO,
  CLEAR_CONTACT_INFO,
  CONTACT_CREATED,
  CONTACT_ERROR,
  RESET_ERROR,
  GET_CONTACTS_BY_CATEGORY,
  GET_CONTACTS_ON_MATTER,
  GET_ALL_CONTACTS,
} from "../types/index";
import * as api from "../api/index.js";
import { handleApiError } from "Helper";

export const getContactsList =
  (page, limit, sort, searchValue, type) => async (dispatch) => {
    try {
      if (page === undefined) {
        dispatch({ type: GET_CONTACTS_LIST, payload: undefined });
        return;
      }
      dispatch({ type: START_LOADING });
      let parameter;
      if (sort === undefined) {
        parameter = "name";
      } else {
        if (sort.dir === "asc") {
          parameter = sort.field;
        } else if (sort.dir === "desc") {
          parameter = sort.field + " desc";
        }
      }
      let datas;
      if (searchValue) {
        datas = await api.getContactsListSearch(searchValue);
      } else {
        datas = await api.getContactsList(page, limit, parameter);
      }
      if (type === 'attachContact') {
        dispatch({ type: GET_ALL_CONTACTS, payload: datas.data });
      } else {
      dispatch({ type: GET_CONTACTS_LIST, payload: datas.data });
      }
      dispatch({ type: STOP_LOADING });
      dispatch({ type: SUCCESS, payload: datas.message });
    } catch (error) { handleApiError(error) }
  };



export const getContactsforCategory = ( id, page, limit, searchValue) => async (dispatch) => {
  try {
    if (page === undefined) {
      dispatch({ type: GET_CONTACTS_BY_CATEGORY, payload: undefined });
      return;
    }
    dispatch({ type: START_LOADING });
    let datas;
    if (searchValue) {
      datas = await api.searchContactsforCategory(id, searchValue);
    } else {
        datas= await api.getAllContactsforCategory(id, page, limit);
    }
    dispatch({ type: GET_CONTACTS_BY_CATEGORY, payload: datas.data });
    dispatch({ type: STOP_LOADING });
    dispatch({ type: SUCCESS, payload: datas.message });
  } catch (error) {
    handleApiError(error);
  }
};


export const getContactsInfo = (id) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const datas = await api.getContactsInfo(id);
    dispatch({ type: GET_CONTACTS_INFO, payload: datas.data.data });
    dispatch({ type: STOP_LOADING });
    dispatch({ type: SUCCESS, payload: datas.message });
  } catch (error) { handleApiError(error) }
};

export const createNewContact = (data, type) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const datas = await api.createNewContact(data, type);
    dispatch({ type: GET_CONTACTS_LIST, payload: datas.data });
    dispatch({ type: CONTACT_CREATED });
    dispatch({ type: STOP_LOADING });
    dispatch({ type: SUCCESS, payload: datas.message });
  } catch (error) {
    handleApiError(error)
    dispatch({ type: CONTACT_ERROR });
  }
};

export const editContact = (id, data) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const datas = await api.editContact(id, data);
    dispatch({ type: GET_CONTACTS_LIST, payload: datas.data });
    dispatch({ type: STOP_LOADING });
    dispatch({ type: SUCCESS, payload: datas.message });
  } catch (error) { handleApiError(error) }
};

export const clearContactsInfo = () => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    dispatch({ type: CLEAR_CONTACT_INFO });
    dispatch({ type: STOP_LOADING });
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const clearContactsError = () => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    dispatch({ type: RESET_ERROR });
    dispatch({ type: STOP_LOADING });
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};


export const getContactsonMatter = (id) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const datas = await api.getContactsOnMatter(id);
    dispatch({ type: GET_CONTACTS_ON_MATTER, payload: datas.data });
    dispatch({ type: STOP_LOADING });
    dispatch({ type: SUCCESS, payload: datas.message });
  } catch (error) {
    handleApiError(error);
  }
}