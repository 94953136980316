import {
  START_LOADING,
  STOP_LOADING,
  SUCCESS,
  GET_USERS_LIST,
  ADDEDNEWUSER,
  UPDATE_USERS_LIST,
} from "../types/index";
import * as api from "../api/index.js";
import { handleApiError } from "Helper";
import { toast } from "react-toastify";

export const getUsersList = (page, limit) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const datas = await api.getUsersList(page, limit);
    dispatch({ type: GET_USERS_LIST, payload: datas.data });
    dispatch({ type: STOP_LOADING });
    dispatch({ type: SUCCESS, payload: datas.message });
  } catch (error) {
    handleApiError(error)
  }
};

export const getUsersListSearch = (page, limit, serach) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const datas = await api.getUsersListSearch(page, limit, serach);
    dispatch({ type: GET_USERS_LIST, payload: datas.data });
    dispatch({ type: STOP_LOADING });
    dispatch({ type: SUCCESS, payload: datas.message });
  } catch (error) {
    handleApiError(error)
  }
};

export const editUserinfo = (id, data) => async (dispatch) => {
  try {
    const datas = await api.editUserinfo(id, data);
    toast.success(`User '${data?.displayname}' has been updated`)
    dispatch({ type: GET_USERS_LIST, payload: datas });
  } catch (error) {
    toast.error('Error occured while updating')
    handleApiError(error)
  }
};

export const addnewUser = (data) => async (dispatch) => {
  try {
    const datas = await api.addnewUser(data);
    // dispatch({ type: ADDEDNEWUSER, payload: datas });
    dispatch({ type: GET_USERS_LIST, payload: datas });
    toast.success('New User Added')
  } catch (error) {
    handleApiError(error)
  }
};
