import {
  START_LOADING,
  STOP_LOADING,
  ERROR,
  SUCCESS,
  GET_VATCODES_LIST,
  RESPONSE_ERROR_LOGIN,
  UPDATE_VATCODE,
  GET_ACTIVEVATS,
} from "../types/index";
import * as api from "../api/index.js";
import { handleApiError } from "Helper";
import { toast } from "react-toastify";

export const getVatcodes = (page, limit) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const datas = await api.getVatcodes(page, limit);
    dispatch({ type: GET_VATCODES_LIST, payload: datas.data });
    dispatch({ type: STOP_LOADING });
    dispatch({ type: SUCCESS, payload: datas.message });
  } catch (error) {
    handleApiError(error);
  }
};

export const editVatinfo = (id, data) => async (dispatch) => {
  try {
    const datas = await api.editVatinfo(id, data);
    toast.success(`VAT '${data.details}' has been updated`);
    dispatch({ type: UPDATE_VATCODE, payload: datas });
  } catch (error) {
    toast.error("Error Occured while updating");
    handleApiError(error);
  }
};

export const addnewVat = (data) => async (dispatch) => {
  try {
    const datas = await api.addnewVat(data);
    dispatch({ type: GET_VATCODES_LIST, payload: datas.data });
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const getactiveVats = () => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const datas = await api.getactiveVats();
    dispatch({ type: GET_ACTIVEVATS, payload: datas.data });
  } catch (error) {
    handleApiError(error);
  }
};
