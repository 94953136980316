import * as React from "react";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { NumericTextBox } from "@progress/kendo-react-inputs";
import { ImCross } from "react-icons/im";
import { AmountFormatCell, DateFormatCell } from "components/common/formatter";
import styles from "./Bills.module.scss";
import { useState, useEffect } from "react";
import { Button } from "@progress/kendo-react-buttons";
import { Separator } from "assets/separator";
import { NameTextCell, TextHeader } from "components/common/GridComponents";
import { CrossIcon } from "components/tabs/TabTitle";
import { IoIosAddCircle } from "react-icons/io";

const DisbGrid = (props) => {
  const [data, setData] = React.useState([]);
  const [totalValues, setTotalValues] = useState({
    totalAmount: 0,
    totalVat: 0,
  });
  const calculateTotalAmount = () => {
    if (data[0] !== undefined) {
      let amount = {
        totalAmount: 0,
        totalVat: 0,
      };
      data.map((item) => {
        amount.totalAmount += item.netAmount;
        amount.totalVat += item.vatAmount;
      });
      setTotalValues(amount);
    } else {
      setTotalValues({
        totalAmount: 0,
        totalVat: 0,
      });
    }
  };
  const AmountNameHeader = (props) => {
    return (
      <div
        style={{
          textAlign: "center",
          marginTop: 0,
          marginBottom: "8px",
        }}
      >
        {" "}
        <span>{props.title}</span>
      </div>
    );
  };
  const MyEditCommandCell = (props) => {
    return (
      <td>
        <CrossIcon
          onClick={(e) => {
            let index = data.findIndex(
              (record) => record.timeId === props.dataItem.timeId
            );
            data.splice(index, 1);
            setData([...data]);
            calculateTotalAmount();
          }}
        />
      </td>
    );
  };

  const calculateTotalDisbBills = () => {
    let finalData = [];
    data.map((item) => {
      const datatemp = {
        postingId: item.postingId,
        netAmount: item.netAmount,
        vatAmount: item.vatAmount,
        vatCode: item.vatCode,
      };
      finalData.push(datatemp);
    });
    props.changeBillDisbData(finalData);
  };

  useEffect(() => {
    setData(props.data);
  }, [props.data]);
  useEffect(() => {
    setData([]);
  }, [props.reset]);
  useEffect(() => {
    calculateTotalAmount();
    calculateTotalDisbBills();
    props.changeTotalAmount(totalValues);
  }, [data]);

  return (
    <div className="" style={{ marginTop: "5px" }}>
      <div style={{ marginBottom: "15px" }}>
        <Button
          className={`k-button fw-semibold fs-sm bgBtnSecondary me-2  ${styles["custom-button"]}`}
          disabled={props.disabled}
          onClick={() => {
            props.openForm();
          }}
        >
          <span className="me-1 pb-1">
            <IoIosAddCircle
              style={{
                marginBottom: "2px",
              }}
            />
          </span>
          <span className="pt-1"> Select Disb.</span>
        </Button>
        <Button
          className={`k-button fw-semibold fs-sm bgBtnSecondary me-2  ${styles["custom-button"]}`}
          disabled={props.disabled}
        >
          <span className="me-1 pb-1">
            <IoIosAddCircle
              style={{
                marginBottom: "2px",
              }}
            />
          </span>
          <span className="pt-1"> Add Expense</span>
        </Button>
      </div>
      <Grid
        data={data}
        dataItemKey={"timeId"}
        editField="inEdit"
        style={{ maxHeight: "160px", overflow: "auto" }}
        className="grid-smallfont"
      >
        <Column
          field="date"
          title="Date"
          editable={false}
          cell={DateFormatCell}
          headerCell={TextHeader}
        />
        <Column
          field="details"
          title="Details"
          editable={false}
          cell={NameTextCell}
        />
        <Column
          field="feeEarnerRef"
          title="Fee Earner"
          editable={false}
          width={120}
          headerCell={TextHeader}
        />
        <Column
          field="netAmount"
          title="Value"
          editable={false}
          width={120}
          cell={AmountFormatCell}
          headerCell={AmountNameHeader}
        />
        <Column
          field="netAmount"
          title="Net"
          editable={false}
          cell={AmountFormatCell}
          width={120}
          headerCell={AmountNameHeader}
        />
        <Column
          field="vatAmount"
          title="Bill Vat"
          editable={false}
          width={120}
          cell={AmountFormatCell}
          headerCell={AmountNameHeader}
        />
        <Column cell={MyEditCommandCell} width="60" />
      </Grid>
      <div className="d-flex justify-content-end right-align mt-3">
        <div
          style={{ gap: "10px" }}
          className={`d-flex pe-2 py-0 justify-content-end`}
        >
          <div
            style={{
              width: "150px",
            }}
          >
            <div className={`${styles["font-small"]}`}>Total Net</div>
            <div className={`${styles["font-amount"]}`}>
              {totalValues?.totalAmount?.toFixed(2)}
            </div>
          </div>
          <div
            style={{
              width: "100px",
            }}
          >
            <div className={`${styles["font-small"]}`}>Total VAT</div>
            <div className={`${styles["font-amount"]}`}>
              {totalValues?.totalVat?.toFixed(2)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DisbGrid;
