import React from "react";
import { useState } from "react";

const useFilterState = () => {
  const [filterClicked, setFilterClicked] = useState(false);
  const [filterApplied, setFilterApplied] = useState(false);

  return {
    filterApplied,
    setFilterApplied,
    filterClicked,
    setFilterClicked,
  };
};

export default useFilterState;
