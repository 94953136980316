import * as React from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import {
  Checkbox,
  Input,
  NumericTextBox,
  TextArea,
} from "@progress/kendo-react-inputs";
import { Label } from "@progress/kendo-react-labels";
import styles from "../Settings.module.scss";

const EditBranch = (props) => {
  const ADD_NEW = props.type === "addNew";
  return (
    <Dialog
      title={ADD_NEW ? "Add Branch" : `Edit ${props?.item?.branchName}`}
      onClose={props.cancelEdit}
      width={700}
    >
      <div className="p-3 ps-4">
        <Form
          onSubmit={props.onSubmit}
          initialValues={props.item}
          render={(formRenderProps) => (
            <FormElement
              style={{
                maxWidth: 1200,
              }}
            >
              <fieldset className={"k-form-fieldset"}>
                <div className="d-flex align-items-center w-100 row">
                  <div className="mb-2 col-6">
                    <Label className="label mt-1 mb-2 me-2">Name&nbsp;</Label>
                    <Field
                      name={"branchName"}
                      component={Input}
                      placeholder="Enter Name"
                    />
                  </div>
                  <div className="mb-2 col-6">
                    <Label className="label mt-1 mb-2">Branch Code&nbsp;</Label>
                    <Field
                      name={"branchCode"}
                      component={NumericTextBox}
                      spinners={false}
                      placeholder="Only Digits ( 0 - 9 )"
                    />
                  </div>
                </div>
                <div className="d-flex flex-column row mt-3 mb-3">
                  <Label className="label">Address</Label>
                  <div className="mt-1 d-flex">
                    <div className="">
                      <Field
                        component={TextArea}
                        placeholder={`Address`}
                        type="text"
                        required={false}
                        maxLength={60}
                        name={"address.line1"}
                        className={`${
                          styles[`input-address-component mt-0 `]
                        } text-align-start`}
                        autoSize={false}
                        style={{
                          width: "320px",
                          maxWidth: "400px",
                          height: "150px",
                        }}
                      />
                    </div>
                    <div className="ms-2">
                      <Field
                        style={{
                          width: "320px",
                          maxWidth: "400px",
                          height: "44px",
                        }}
                        placeholder="Enter City"
                        className="mt-1"
                        name={"address.town"}
                        component={Input}
                      />
                      <div className="d-flex">
                        <Field
                          style={{
                            width: "187px",
                            marginRight: "8px",
                            maxWidth: "400px",
                            height: "44px",
                          }}
                          placeholder="Enter State"
                          className="mt-1"
                          name={"address.state"}
                          component={Input}
                        />
                        <Field
                          style={{
                            width: "125px",
                            maxWidth: "400px",
                            height: "44px",
                          }}
                          placeholder="Enter Postcode"
                          className="mt-1"
                          name={"address.postcode"}
                          component={Input}
                        />
                      </div>
                      <Field
                        style={{
                          width: "320px",
                          maxWidth: "400px",
                          height: "44px",
                        }}
                        placeholder="Enter Country"
                        className="mt-1"
                        name={"address.country"}
                        component={Input}
                      />
                    </div>
                  </div>
                </div>
                <Field
                  name={"active"}
                  defaultChecked={true}
                  component={Checkbox}
                  label={"Active"}
                />
              </fieldset>
              <button
                style={{ visibility: "hidden" }}
                id="branch-form"
                //   disabled={!formRenderProps.allowSubmit}
                type={"submit"}
              ></button>
            </FormElement>
          )}
        />
      </div>
      <div className="dialog-footer">
        <button
          className="bgBtnSecondary common-btn me-1"
          onClick={props.cancelEdit}
        >
          Cancel
        </button>
        <button
          className="common-btn me-3"
          //   disabled={!formRenderProps.allowSubmit}
          onClick={() => document.getElementById("branch-form").click()}
          type={"submit"}
        >
          {ADD_NEW ? "Save" : "Update"}
        </button>
      </div>
    </Dialog>
  );
};

export default EditBranch;
