import * as React from "react";
import { Button } from "@progress/kendo-react-buttons";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import styles from "../../pages/Settings/Settings.module.scss";
import EditForm from "./EditForm";
import List from "./List";
import * as api from "../../api/index";

const EventType = ({ 
  apiAddFunc, 
  actionFunc, 
  successMessage, 
  errorMessage, 
  buttonLabel, 
  listComponent, 
  editFormComponent, 
  ...props 
}) => {
  const [addnewform, setaddnewform] = React.useState(false);
  const dispatch = useDispatch();

  const closeform = () => {
    setaddnewform(false);
  };

  const handleSubmit = async (e) => {
    setaddnewform(false);
    try {
      await api[apiAddFunc](e);
      toast.success(successMessage);
      dispatch(actionFunc());
    } catch (error) {
      toast.error(errorMessage);
    }
  };

  React.useEffect(() => {
    if (props.onClose === false) {
      closeform();
    }
  }, [props.onClose]);

  const ListComponent = listComponent;
  const EditFormComponent = editFormComponent;

  return (
    <React.Fragment>
      <>
        <div className="row">
          <div className="col-lg-10 col-md-8"></div>
          <Button
                    className={`common-btn col-lg-1 col-md-4 p-2 mt-5 ${styles[`button`]}`}
            onClick={() => {
              setaddnewform(true);
              props.open();
            }}
          >
            Add <br></br>
            {buttonLabel}
          </Button>
        </div>
        <div className={`row mx-auto mt-3 w-50`}>
          <ListComponent />
        </div>
      </>
      {addnewform && (
        <EditFormComponent
          cancelEdit={closeform}
          onSubmit={handleSubmit}
          type="addNew"
        />
      )}
    </React.Fragment>
  );
};

export default EventType;