import styles from "./tabs.module.scss";

export const CrossIcon = (props) => {
  return (
    <span
      onClick={(e) => {
        if (props.onClick) props.onClick(e);
      }}
      className={`${styles["tabs-remove-icon-wrapper"]} ms-3
      d-flex justify-content-center align-items-center cursor-pointer`}
    >
      <span className={`${styles["r-icon"]} k-icon k-i-x`}></span>
    </span>
  );
};

const Title = (props) => {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        fontSize: "14px",
        justifyContent: "space-between",
        height: "100%",
        ...props.style,
      }}
    >
      <div
        className={` ${styles["tabs-container"]} d-flex`}
        style={{
          color: props.selected === props.content ? "black" : "#757575",
          fontWeight: props.selected === props.content ? "bold" : "",
        }}
      >
        <span
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            maxWidth: props.maxWidth,
          }}
        >
          {props.content}
        </span>
        <span
          onClick={(e) => {
            e.stopPropagation();
            props.onTabRemove(props.content);
          }}
          className={`${styles["tabs-remove-icon-wrapper"]} ms-3 mb-1 d-flex justify-content-center align-items-center`}
        >
          <span
            className={`${
              props.selected === props.content
                ? styles["r-icon"]
                : styles["r-icon-unselected"]
            } k-icon k-i-x`}
          ></span>
        </span>
      </div>
    </div>
  );
};

export default Title;
