import * as React from "react";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Input, Checkbox } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import { Label } from "@progress/kendo-react-labels";
import { useState, useEffect } from "react";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { getBranchnames } from "api";

const EditForm = (props) => {
  const ADD_NEW = props.type === "add";

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [branches, setBranches] = useState([]);

  useEffect(() => {
    getBranches();
  }, []);


  const getBranches = async () => {
    const response = await getBranchnames();
    setBranches(response.data.data);
  };

  return (
    <>
      <div className="p-3 ps-4">
        <Form
          ignoreModified
          onSubmit={(event) => {
            const formDataWithBranchId = {
              teamName: event.teamName,
              teamID: event.teamID,
              branchId: event?.branch?.branchId,
              branchName: event?.branch?.branchName,
              active: ADD_NEW ? true : event.active,
            };
            props.onSubmit(formDataWithBranchId);
          }}
          readOnly={true}
          initialValues={
            ADD_NEW
              ? null
              : {
                  ...props.item,
                  branch: {
                    branchId: props?.item?.branchId,
                    branchName: props?.item?.branchName,
                  },
                }
          }
          render={(formRenderProps) => {
            return (
              <FormElement
                style={{
                  maxWidth: 1000,
                }}
              >
                <fieldset className={"k-form-fieldset"}>
                  <div className="mb-3">
                    <Label className="label mt-1 mb-2  w-100">
                      Team Name&nbsp;
                    </Label>
                    <Field name={"teamName"} component={Input} />

                    {isSubmitted &&
                      !formRenderProps.valueGetter("teamName") && (
                        <div className="fs-sm my-1 text-danger">
                          <i>Team Name is required</i>
                        </div>
                      )}
                  </div>
                  <div className="mb-3">
                    <Label className="label mt-1 mb-2  w-100">
                      Branch Name&nbsp;
                    </Label>

                    <Field
                      style={{
                        width: "300px",
                      }}
                      data={branches}
                      name={"branch"}
                      textField={"branchName"}
                      placeholder="Select Branch"
                      component={ComboBox}
                    />
                    {isSubmitted && !formRenderProps.valueGetter("branch") && (
                      <div className="fs-sm my-1 text-danger">
                        <i>Branch Name is required</i>
                      </div>
                    )}
                  </div>

                  {!ADD_NEW && (
                    <div>
                      <Field
                        style={{
                          marginRight: "10px",
                          width: "20px",
                        }}
                        name={"active"}
                        component={Checkbox}
                        label={"Active"}
                      />
                    </div>
                  )}
                </fieldset>
                <button
                  style={{ visibility: "hidden" }}
                  id="team-form"
                  type={"submit"}
                ></button>
              </FormElement>
            );
          }}
        />
      </div>
      <div className="dialog-footer">
        <Button
          className="bgBtnSecondary common-btn me-2"
          onClick={props.cancelEdit}
        >
          Cancel
        </Button>
        <Button
          className="common-btn me-3"
          onClick={() => {
            document.getElementById("team-form").click();
            setIsSubmitted(true);
          }}
          type={"submit"}
        >
          {ADD_NEW ? "Save" : "Update"}
        </Button>
      </div>
    </>
  );
};

export default EditForm;
