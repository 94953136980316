import * as React from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import { useDispatch, useSelector } from "react-redux";
import { Checkbox, NumericTextBox } from "@progress/kendo-react-inputs";
import { Label } from "@progress/kendo-react-labels";
import { useState } from "react";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { TextArea } from "@progress/kendo-react-inputs";
import { useEffect } from "react";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import Icon from "components/Global/icon";
import styles from "./DocumentRegister.module.scss";
import SectionDivider from "components/Contact/sectionDivider";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import AddClients from "pages/Clients/AddViewClients";
import { Button } from "@progress/kendo-react-buttons";
import { IoIosAddCircle } from "react-icons/io";
import { getfeeearner } from "actions/postings.action";
import { PageTabItemTitle } from "components/tabs/TabItem/PageTabItemTitle";
import { getBranchesnames } from "actions/branchnames.action";
import { getContactLinks } from "api/settingApi";
import {
  addClientDocumentResgisters,
  addMatter,
  addMatterDocumentResgisters,
  getActiveApplications,
  getActiveTypes,
  getCategoryList,
  getClientsNameAndRef,
  getContactsListAll,
  getDepartmentList,
  getDocumentRegisters,
  getMatterAllInfo,
  getMatterRefExample,
  updateDocumentResgister,
  updateMatter,
} from "api/index";
import {
  getDDABankNominals,
  getIncomeNominals,
} from "actions/nominals.action";
import {
  FormComboBox,
  TabTitle,
  FormInput,
  FormDate,
  FormNumericTextBox,
} from "components/Matters/index";
import { CONSTANT } from "CONSTANT/Matter/MatterForm";
import MatterModal from "components/Matters/MatterModal";
import { filterBy } from "@progress/kendo-data-query";
import { prepareApplicationData } from "components/common/ComboRepresentationFunctions";
import { DeleteContainer } from "pages/Postings/DcoumentsUpload";
import { getActiveReferrers } from "actions/referrers.action";
import { getActiveSources } from "actions/sources.action";
import { ContainerCard } from "components/MatterDetails/ContactCard";
import { Field } from "@progress/kendo-react-form";
import {
  AmountFormat,
  DateFormat,
  DateFormat1,
  DateFormatCell,
} from "components/common/formatter";
import DataList from "components/MatterDetails/DataList";
import MattersCombobox from "pages/Postings/MattersComboBox";
import { toast } from "react-toastify";
import { Loader } from "@progress/kendo-react-indicators";
import {
  DocRegisterDetailsTab,
  ExecutorsTab,
  ExtraInfoTab,
  TestatorsTab,
} from "./DocRegsiterComponents";

const yesNoData = ["Yes", "No"];
const tabs = [
  "Details",
  "Movements",
  "Extra Info",
  "Permanent Out",
  "Notes",
  "Executors",
  "Testator/Owner",
];

const DocumentRegisterModal = (props) => {
  const [isView, setIsView] = useState(props.docId ? true : false);
  const isClient = props.clientId ? true : false;
  const { feeearner } = useSelector((state) => state.postingsdocs);
  const { activeSources } = useSelector((state) => state.sources);
  const { activeReferrers } = useSelector((state) => state.referrers);
  const { nominalPofitAndLoss, nominalDefaultDDABank } = useSelector(
    (state) => state.nominalList
  );
  let locale = useSelector((state) => state.company.company.locale);
  const [isLoading, setIsLoading] = useState(props.docId ? true : false);
  const [details, setDetails] = useState("");
  const [extraDetails, setExtraDetails] = useState("");
  const dispatch = useDispatch();
  const [data, setData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [docTypes, setDocTypes] = useState([]);
  const [modalMessage, setModalMessage] = useState("");
  const [modalSuccess, setModalSuccess] = useState(undefined);
  const [newMaterId, setNewMatterId] = useState({
    matterId: props?.matterId?.matterId,
    matterRef: props?.matterId?.matterRef,
    matterDetails: props?.matterId?.matterDetails,
  });
  const [closed, setClosed] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const [inputState, setInputState] = useState({
    annualFee: null,
  });

  const [comboBoxState, setComboBoxState] = useState({
    [CONSTANT.COMBOBOX.CLIENT]: {
      CustomerID: props.clientId?.clientId,
      ContactName: props.clientId?.clientName,
      CustomerRef: props.clientId?.clientRef,
    },
    [CONSTANT.COMBOBOX.SUPERVISIOR]: "",
    [CONSTANT.COMBOBOX.FEE_EARNER]: "",
    [CONSTANT.COMBOBOX.APPLICATION]: "",
    [CONSTANT.COMBOBOX.DEPARTMENT]: "",
    [CONSTANT.COMBOBOX.CATEGORY]: "",
    [CONSTANT.COMBOBOX.BRANCH]: "",
    [CONSTANT.COMBOBOX.DDA_BANK]: "",
    [CONSTANT.COMBOBOX.PROFIT_COST_NOMINAL]: "",
    [CONSTANT.COMBOBOX.CONTACT_LINK]: "",
    docType: "",
  });

  const [dateState, setDateState] = useState({
    [CONSTANT.DATE.START_DATE]: new Date(),
    [CONSTANT.DATE.CLOSED_DATE]: "",
    [CONSTANT.DATE.DELETED_DATE]: "",
    [CONSTANT.DATE.DOB_OS1]: "",
    [CONSTANT.DATE.DOB_OS2]: "",
  });

  const [feeEarner, setFeeEarner] = useState(feeearner.data);

  const getActiveDocTypes = async () => {
    try {
      const dataOutput = await getActiveTypes();
      setDocTypes(dataOutput?.data?.data);
      setComboBoxState({
        ...comboBoxState,
        docType: dataOutput?.data?.data?.filter(
          (item) => item.typeName === data?.typeName
        )[0],
      });
    } catch (error) {}
  };

  useEffect(() => {
    if (isView) {
      getDocumentDetails();
    }
  }, []);

  useEffect(() => {
    if (!isView) {
      getActiveDocTypes();
    }
  }, [isView]);

  const getDocumentDetails = async () => {
    try {
      const data = await getDocumentRegisters(props.docId);
      setData(data?.data?.data);
      setDetails(data?.data?.data?.details);
      setExtraDetails(data?.data?.data?.extraDetails);
      setInputState({
        [CONSTANT.INPUT.REFERENCE]: data.data.data?.reference || "",
        [CONSTANT.INPUT.LOCATION_CODE]: data.data.data?.location || "",
        ...data?.data?.data,
      });
      setComboBoxState({
        ...comboBoxState,
        [CONSTANT.COMBOBOX.FEE_EARNER]: {
          displayname: data.data.data?.feeEarnerName,
          userRef: data.data.data?.feeEarnerRef,
        },
        docType: data.data.data?.typeName,
        isDeceased: data?.data?.data?.isDeceased ? "Yes" : "No",
        isStorage: data?.data?.data?.inStorage,
      });

      setDateState({
        [CONSTANT.DATE.CODICIL_DATE_1]: new Date(data.data?.data?.codicilDate1),
        [CONSTANT.DATE.DATE_OF_DOC]: data.data?.data?.documentDate
          ? new Date(data.data?.data?.documentDate)
          : null,
        [CONSTANT.DATE.CODICIL_DATE_2]: data.data?.data?.codicilDate2
          ? new Date(data.data?.data?.codicilDate2)
          : null,
        [CONSTANT.DATE.LODGEMENT_DATE]: data.data?.data?.lodgementDate
          ? new Date(data.data?.data?.lodgementDate)
          : null,
        [CONSTANT.DATE.REVIEW_DATE]: data.data?.data?.reviewDate
          ? new Date(data.data?.data?.reviewDate)
          : null,
        [CONSTANT.DATE.DATE_OF_WILL]: data.data?.data?.willDate
          ? new Date(data.data?.data?.willDate)
          : null,
        annualFeeDate: data.data?.data?.annualFeeDate
          ? new Date(data.data?.data?.annualFeeDate)
          : null,
        acquiredDate: data.data?.data?.acquiredDate
          ? new Date(data.data?.data?.acquiredDate)
          : null,
      });
    } catch (error) {
    } finally {
      if (isLoading) setIsLoading(false);
    }
  };
  const filterFeeEarner = (filter) => {
    const data = feeearner.data.slice();
    return filterBy(data, filter);
  };
  const feeEarnerfilterChange = (event) => {
    setFeeEarner(filterFeeEarner(event.filter));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const parseDate = (newDate) => {
      if (!newDate) return null;
      var os = newDate?.getTimezoneOffset();
      return (newDate = new Date(newDate.getTime() - os * 60 * 1000));
    };
    const dataObject = {
      ...inputState,
      ...dateState,
      typeId: comboBoxState["docType"]?.docRegisterTypeID,
      typeCode: comboBoxState["docType"]?.code,
      matterRef: newMaterId?.matterRef,
      matterId: newMaterId?.matterId,
      details: details,
      extraDetails: extraDetails,
      reference: inputState[CONSTANT.INPUT.REFERENCE],
      location: inputState[CONSTANT.INPUT.LOCATION_CODE],
      reviewDate: dateState[CONSTANT.DATE.REVIEW_DATE],
      documentDate: dateState[CONSTANT.DATE.DATE_OF_DOC],
      lodgementDate: dateState[CONSTANT.DATE.LODGEMENT_DATE],
      feeEarnerRef: comboBoxState[CONSTANT.COMBOBOX.FEE_EARNER].userRef,
      willDate: dateState[CONSTANT.DATE.DATE_OF_WILL],
      codicilDate1: dateState[CONSTANT.DATE.CODICIL_DATE_1],
      codicilDate2: dateState[CONSTANT.DATE.CODICIL_DATE_2],
      isDeceased: comboBoxState["isDeceased"] === "Yes" ? true : false,
    };
    if (props.docId) {
      try {
        const res = await updateDocumentResgister(
          {
            data: dataObject,
          },
          props.docId
        );
        if (res?.status === 200) {
          props.updateData();
          toast.success("Doocument Register Updated");
        }
        return;
      } catch (err) {
        toast.error("Error Occurred while updating");
      }
    } else {
      try {
        if (props.matterId) {
          const res = await addMatterDocumentResgisters(
            { data: dataObject },
            props.matterId.matterId
          );
          if (res?.status === 200) {
            props.updateData();
            toast.success("Document Regsiter added");
          }
        } else if (props.clientId) {
          const res = await addClientDocumentResgisters(
            { data: dataObject },
            props.clientId.clientId
          );
          if (res?.status === 200) {
            props.updateData();
            toast.success("Document Regsiter added");
          }
        }
      } catch (err) {
        if (
          err?.response?.data !== "" &&
          err?.response?.data?.errorMessage?.includes(
            "The duplicate key value is"
          )
        ) {
          updateModalState(true, "Matter Ref Already Added", false);
        } else {
          updateModalState(true, "Error Occured", false);
        }
      }
    }
  };

  const [selected, setSelected] = React.useState(0);
  const handleSelect = (e) => {
    setSelected(e.selected);
  };

  const handleModalClose = () => {
    setShowModal(false);
    if (modalSuccess) {
      const myObj = {
        dataItem: {
          matterId: newMaterId,
          matterRef: inputState[CONSTANT.INPUT.MATTER_REF],
          matterDetails: details,
        },
      };
      props.setshowMatterForm();
      if (!props.isView) {
        props.onMatterRefClickActions(myObj);
      }
    }
  };

  const updateModalState = (modalShow, message, success) => {
    setShowModal(modalShow);
    setModalMessage(message);
    setModalSuccess(success);
  };

  const handleInputChange = (value, label) => {
    setInputState({
      ...inputState,
      [label]: value,
    });
  };

  const handleComboBoxChange = (e, label) => {
    setComboBoxState({
      ...comboBoxState,
      [label]: e.value,
    });
  };

  const handleDateChange = (e, label) => {
    setDateState({
      ...dateState,
      [label]: e.value,
    });
  };

  React.useEffect(() => {
    dispatch(getfeeearner());
  }, []);

  const handleClientChange = async (value) => {
    if (value !== null) {
      try {
        const response = await getMatterRefExample(value.CustomerRef);
        setInputState({
          ...inputState,
          [CONSTANT.INPUT.MATTER_REF]: response.data,
        });
      } catch (error) {}
    }
    setComboBoxState({
      ...comboBoxState,
      [CONSTANT.COMBOBOX.CLIENT]: value,
    });
  };

  return (
    <div>
      <Dialog
        className={styles["matter-form-dialog"]}
        title={
          isView
            ? "View Document Register"
            : props.docId
            ? "Edit Document Register"
            : "Add Document Register"
        }
        onClose={props.setshowMatterForm}
      >
        <div
          className="container pb-5 px-5 mt-3"
          style={{ width: "1250px", height: "80vh" }}
        >
          {isLoading ? (
            <div
              className="d-flex align-items-center justify-content-center h-100"
              style={{ minHeight: "580px" }}
            >
              <Loader themeColor={"warning"} size={"large"} type={"pulsing"} />
            </div>
          ) : (
            <form id="matterForm" onSubmit={handleSubmit}>
              <ContainerCard
                height={isView ? "115px" : "150px"}
                className="p-3 ps-4 pe-4 pt-2 mb-3 pb-0 overflow-auto"
              >
                <>
                  <div
                    className={`${isView && "pt-2"} container`}
                    style={{ display: !isView && "none" }}
                  >
                    <div className="row px-3">
                      <div className="d-flex align-items-start justify-content-start mt-3">
                        <div className="" style={{ width: "35%" }}>
                          {isClient ? (
                            <DataList
                              text={"Client"}
                              value={data?.clientName}
                              alignItems="align-items-start"
                              keyStyles={{ minWidth: "165px" }}
                            />
                          ) : (
                            <DataList
                              text={"Matter:"}
                              value={data?.matterDetails}
                              alignItems="align-items-start"
                              keyStyles={{ minWidth: "165px" }}
                            />
                          )}
                          <DataList
                            text={"Document Type:"}
                            value={data?.typeName}
                            //
                            alignItems="align-items-start"
                            //
                            keyStyles={{ minWidth: "165px" }}
                          />
                        </div>
                        <div className="ps-5">
                          <DataList
                            text={"Reference:"}
                            value={data?.reference}
                            //
                            alignItems="align-items-start"
                            //
                            keyStyles={{ minWidth: "165px" }}
                          />

                          <DataList
                            text={"Location Code:"}
                            value={data?.location}
                            //
                            alignItems="align-items-start"
                            //
                            keyStyles={{ minWidth: "165px" }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="container"
                    style={{ display: isView && "none" }}
                  >
                    <div className="row mt-1">
                      <div className="col-3 me-4">
                        {isClient ? (
                          <FormComboBox
                            className=""
                            fc={true}
                            name={CONSTANT.COMBOBOX.CLIENT}
                            val={comboBoxState[CONSTANT.COMBOBOX.CLIENT]}
                            onChangeClient={handleClientChange}
                            wf={true}
                            disabled={true}
                            isStyled={true}
                            // className={"mt-3 "}
                            label="Client"
                            width={"260px"}
                            boxShadow={true}
                          />
                        ) : (
                          <>
                            <Label className={`label mb-1`}>Matter</Label>
                            <MattersCombobox
                              onChangematter={(e) => {
                                setNewMatterId(e.value);
                              }}
                              value={newMaterId}
                              disabled={true}
                              style={{
                                width: "260px",
                                height: "32px",
                                boxShadow: "0px 1px 9px rgba(0, 0, 0, 0.12)",
                              }}
                            />
                          </>
                        )}
                      </div>
                      {/* <div className="col-1"></div> */}
                      <div className="col-3">
                        <div className="d-flex flex-column col-3">
                          <FormInput
                            required={true}
                            labelWidth
                            wf={true}
                            isStyled={true}
                            // className={"mt-3 "}
                            width={"260px"}
                            boxShadow={true}
                            state={inputState}
                            label={CONSTANT.INPUT.REFERENCE}
                            onChangeInput={handleInputChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="d-flex flex-column col-3 me-4">
                        <FormComboBox
                          className=""
                          fc={true}
                          required
                          name={"docType"}
                          state={comboBoxState}
                          onChangeComboBox={handleComboBoxChange}
                          wf={true}
                          textField={"typeName"}
                          isStyled={true}
                          disabled={props.docId}
                          data={docTypes}
                          placeholder={"Select Document Type"}
                          label={"Document Type"}
                          width={"260px"}
                        />
                      </div>
                      <div className="d-flex flex-column col-3">
                        <FormInput
                          labelWidth
                          wf={true}
                          isStyled={true}
                          // className={"mt-3 "}
                          width={"260px"}
                          // boxShadow={true}
                          state={inputState}
                          label={CONSTANT.INPUT.LOCATION_CODE}
                          onChangeInput={handleInputChange}
                        />
                      </div>
                    </div>
                  </div>
                </>
              </ContainerCard>
              <ContainerCard
                height={"550px"}
                className="p-3 ps-4 pe-4 pt-2 pb-0 overflow-auto"
              >
                <TabStrip
                  keepTabsMounted={true}
                  selected={selected}
                  onSelect={handleSelect}
                  className="mt-3 pagetab w-100"
                  style={{
                    width: "100%",
                  }}
                >
                  {tabs.map((tab, index) => (
                    <TabStripTab
                      key={index}
                      title={
                        <PageTabItemTitle
                          // className="mb-sm-4 mb-xl-0"
                          title={tab}
                          index={index}
                          selected={selected}
                        />
                      }
                    >
                      {tab === "Details" && (
                        <DocRegisterDetailsTab
                          data={data}
                          isView={isView}
                          handleComboBoxChange={handleComboBoxChange}
                          handleDateChange={handleDateChange}
                          comboBoxState={comboBoxState}
                          yesNoData={yesNoData}
                          details={details}
                          setDetails={setDetails}
                          feeEarner={feeEarner}
                          dateState={dateState}
                          locale={locale}
                        />
                      )}
                      {tab === "Movements" && (
                        <>
                          {isView ? (
                            <div style={{ display: !isView && "none" }}>
                              <div
                                className="container pb-2"
                                style={{ width: "1130px" }}
                              >
                                <div className="row g-2 mt-3">
                                  <Grid
                                    data={[]}
                                    className={`fs-md ${styles.mattersHeight}`}
                                  >
                                    <GridColumn
                                      field="feeEarner"
                                      title="Fee Earner"
                                      headerClassName="ps-4"
                                      width="240px"
                                    />
                                    <GridColumn
                                      field="code"
                                      title="Code"
                                      width="140px"
                                    />
                                    <GridColumn
                                      field="date"
                                      title="Date In/Out"
                                      width="140px"
                                      cell={DateFormatCell}
                                    />
                                    <GridColumn
                                      field="expectedDate"
                                      title="Expected"
                                      cell={DateFormatCell}
                                      width="140px"
                                    />
                                    <GridColumn
                                      field="description"
                                      title="Description"
                                    />
                                  </Grid>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}
                        </>
                      )}
                      {tab === "Extra Info" && (
                        <ExtraInfoTab
                          data={data}
                          isView={isView}
                          handleDateChange={handleDateChange}
                          inputState={inputState}
                          handleInputChange={handleInputChange}
                          dateState={dateState}
                          extraDetails={extraDetails}
                          setExtraDetails={setExtraDetails}
                          locale={locale}
                        />
                      )}
                      {tab === "Permanent Out" && (
                        <>
                          {isView ? (
                            <div style={{ display: !isView && "none" }}>
                              <div
                                className="container pb-2"
                                style={{ width: "1130px" }}
                              >
                                <div className="row g-2 mt-3">
                                  <div className="col">
                                    <div className="container">
                                      <div className="row">
                                        <div className="d-flex align-items-start justify-content-start mt-3 ps-3">
                                          <div
                                            className=""
                                            style={{ width: "34%" }}
                                          >
                                            <DataList
                                              text={"Sent Out By:"}
                                              value={data?.permanentOut}
                                              //
                                              alignItems="align-items-start"
                                              //
                                              keyStyles={{ minWidth: "165px" }}
                                            />

                                            <DataList
                                              text={"Name:"}
                                              value={data?.permanentOutName}
                                              //
                                              alignItems="align-items-start"
                                              //
                                              keyStyles={{ minWidth: "165px" }}
                                            />

                                            <DataList
                                              text={"Phone No:"}
                                              value={data?.permanentOutPhone}
                                              //
                                              alignItems="align-items-start"
                                              //
                                              keyStyles={{ minWidth: "165px" }}
                                            />
                                          </div>
                                          <div className="ps-5">
                                            <DataList
                                              text={"Date Out:"}
                                              value={DateFormat1(
                                                data?.permanentOutDate,
                                                locale
                                              )}
                                              //
                                              alignItems="align-items-start"
                                              //
                                              keyStyles={{ minWidth: "165px" }}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="row mt-4 ps-1">
                                        <DataList
                                          text={"Address:"}
                                          className={"mt-1"}
                                          alignItems="align-items-start"
                                          keyStyles={{ minWidth: "165px" }}
                                          value={
                                            <>
                                              {data?.permanentOutAddress
                                                ?.line1 && (
                                                <div>
                                                  {
                                                    data?.permanentOutAddress
                                                      ?.line1
                                                  }{" "}
                                                  <br className="mt-1" />
                                                </div>
                                              )}
                                              {data?.permanentOutAddress
                                                ?.town && (
                                                <div className="mt-1">
                                                  {
                                                    data?.permanentOutAddress
                                                      ?.town
                                                  }{" "}
                                                  <br className="mt-1" />
                                                </div>
                                              )}{" "}
                                              {data?.permanentOutAddress
                                                ?.state && (
                                                <div className="mt-1">
                                                  {
                                                    data?.permanentOutAddress
                                                      ?.state
                                                  }{" "}
                                                  <br className="mt-1" />
                                                </div>
                                              )}{" "}
                                              {data?.permanentOutAddress
                                                ?.country && (
                                                <div className="mt-1">
                                                  {
                                                    data?.permanentOutAddress
                                                      ?.country
                                                  }{" "}
                                                  <br className="mt-1" />
                                                </div>
                                              )}
                                            </>
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div
                              className="container pb-2"
                              style={{
                                width: "1130px",
                                display: isView && "none",
                              }}
                            >
                              <div className="row g-2 mt-4">
                                <div className="col">
                                  <div className="container">
                                    <div className="row">
                                      <div className="col-3 me-3">
                                        <FormNumericTextBox
                                          type="number"
                                          differentName={true}
                                          className={"right-align-input"}
                                          wf={true}
                                          ph={"0.00"}
                                          isStyled={true}
                                          width={"260px"}
                                          state={inputState}
                                          labelForBox={"Out By"}
                                          label={"permanentOutBy"}
                                          onChangeInput={handleInputChange}
                                        />
                                      </div>
                                      <div className="col-3">
                                        <FormDate
                                          className={
                                            "form-date-picker note-date-picker "
                                          }
                                          wf={true}
                                          isStyled={true}
                                          width={"260px"}
                                          label={"permanentOutDate"}
                                          customLabel={"Date Out"}
                                          onChangeDate={handleDateChange}
                                          state={dateState}
                                        />
                                      </div>
                                    </div>
                                    <div className="row mt-3">
                                      <div className="col-3 me-3">
                                        <FormInput
                                          wf={true}
                                          isStyled={true}
                                          // className={"mt-3 "}
                                          ph={"Enter Name"}
                                          width={"260px"}
                                          state={inputState}
                                          label={"permanentOutName"}
                                          labelForComboBox={"Name"}
                                          onChangeInput={handleInputChange}
                                        />
                                      </div>
                                    </div>
                                    <div className="row mt-3">
                                      <FormInput
                                        wf={true}
                                        isStyled={true}
                                        // className={"mt-3 "}
                                        width={"260px"}
                                        ph={"Enter Number"}
                                        state={inputState}
                                        label={"permanentOutPhone"}
                                        labelForComboBox={"Phone Number"}
                                        onChangeInput={handleInputChange}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </>
                      )}
                      {tab === "Notes" && (
                        <>
                          {isView ? (
                            <div style={{ display: !isView && "none" }}>
                              <div
                                className="container pb-2"
                                style={{ width: "1130px" }}
                              >
                                <div className="row g-2 mt-3">
                                  <div className="col">
                                    <div className="container">
                                      <div className="row mt-4 px-4">
                                        <p
                                          className={`${styles["notes-text"]}`}
                                        >
                                          {data?.details}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div
                              className="container pb-2"
                              style={{
                                width: "1130px",
                                display: isView && "none",
                              }}
                            >
                              <div className="row g-2 mt-3">
                                <div className="col">
                                  <div className="container">
                                    <div className="row mt-3">
                                      <div className="d-flex flex-column col-6">
                                        <Label className={`label mb-1`}>
                                          Notes
                                        </Label>
                                        <TextArea
                                          placeholder="Enter Details"
                                          autoSize={true}
                                          value={details}
                                          style={{
                                            height: "130px",
                                            minWidth: "1010px",
                                          }}
                                          onChange={(e) => setDetails(e.value)}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </>
                      )}
                      {tab === "Executors" && (
                        <ExecutorsTab
                          isView={isView}
                          inputState={inputState}
                          handleInputChange={handleInputChange}
                          data={data}
                        />
                      )}
                      {tab === "Testator/Owner" && (
                        <TestatorsTab
                          isView={isView}
                          inputState={inputState}
                          handleInputChange={handleInputChange}
                          data={data}
                        />
                      )}
                    </TabStripTab>
                  ))}
                  {props.isView && (
                    <TabStripTab
                      title={
                        <PageTabItemTitle
                          title="Completion"
                          index={1}
                          selected={selected}
                        />
                      }
                    >
                      <div
                        className="container pb-2"
                        style={{ width: "1130px" }}
                      >
                        <div className="row g-2">
                          <div className="col-6 pt-3">
                            <ContainerCard
                              height={"300px"}
                              className="p-3 ps-4 pe-4 pt-2 pb-0 overflow-auto"
                            >
                              <div
                                className="d-flex align-items-center justify-content-between"
                                style={{ height: "50px" }}
                              >
                                <div className={`${styles["section-title"]}`}>
                                  Completion
                                </div>
                              </div>
                              <hr className="mt-0" />
                              <Checkbox
                                label="Closed"
                                value={closed}
                                onChange={(e) => setClosed(e.value)}
                              />
                              <FormDate
                                disabled={!closed}
                                wf={true}
                                isStyled={true}
                                className={
                                  "mt-3 form-date-picker note-date-picker"
                                }
                                width={"260px"}
                                label={CONSTANT.DATE.CLOSED_DATE}
                                onChangeDate={handleDateChange}
                                state={dateState}
                              />
                              <FormInput
                                state={inputState}
                                className={"mt-3"}
                                wf={true}
                                isStyled={true}
                                width={"260px"}
                                label={CONSTANT.INPUT.LOCATION}
                                onChangeInput={handleInputChange}
                              />
                            </ContainerCard>
                          </div>{" "}
                          <div className="col-6 pt-3">
                            <ContainerCard
                              height={"300px"}
                              className="p-3 ps-4 pe-4 pt-2 pb-0 overflow-auto"
                            >
                              <div
                                className="d-flex align-items-center justify-content-between"
                                style={{ height: "50px" }}
                              >
                                <div className={`${styles["section-title"]}`}>
                                  GDRP
                                </div>
                              </div>
                              <hr className="mt-0" />
                              <Checkbox
                                label="Deleted"
                                onChange={(e) => setDeleted(e.value)}
                              />
                              <FormDate
                                disabled={!deleted}
                                className={
                                  "mt-3 form-date-picker note-date-picker"
                                }
                                wf={true}
                                isStyled={true}
                                width={"260px"}
                                label={CONSTANT.DATE.DELETED_DATE}
                                onChangeDate={handleDateChange}
                                state={dateState}
                              />
                            </ContainerCard>
                          </div>
                        </div>
                      </div>
                    </TabStripTab>
                  )}
                </TabStrip>
              </ContainerCard>
              <button
                id="doc-reg"
                style={{
                  visibility: "hidden",
                }}
                type="submit"
              ></button>
            </form>
          )}
        </div>

        <div className="dialog-footer">
          <Button
            className="common-btn bgBtnSecondary border-0 me-3"
            onClick={props.setshowMatterForm}
          >
            Cancel
          </Button>
          <Button
            className="common-btn me-4"
            type="button"
            form="matterForm"
            onClick={(e) => {
              if (isView) {
                setIsView(false);
                getActiveDocTypes();
              } else {
                // handleSubmit(e);
                document.getElementById("doc-reg").click();
              }
            }}
          >
            {isView ? "Edit" : "Save"}
          </Button>
        </div>
      </Dialog>
      <MatterModal
        showModal={showModal}
        message={modalMessage}
        success={modalSuccess}
        onClose={handleModalClose}
      />
    </div>
  );
};

export default DocumentRegisterModal;
