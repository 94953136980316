
import React from "react";
import FieldType from "./FieldType";
import { Label } from "@progress/kendo-react-labels";



const QuestionField = ({

    field = { label: "", required: false, gridWidth: 7, gridOffset: 0 },
    userScreenFieldId = "",
    isSubmitted = false,
    handleInputChange = () => { },
    validationMessage = () => { },
    fieldIndex = 0,
    formData = {},
    fromScreenDesigner = false,
}) => {



    const renderLabel = (field) => {
        return field.label ? (
            <Label className={"fw-semibold fs-sm mb-1 " +
                (field.labelAboveField ? " justify-content-end2 text-end" : " justify-content-end text-end")}> 
                
                {field.label}
                {field.required && <span style={{ color: "red" }}> *</span>}

            </Label>
        ) : null;
    };



    return (
        <div
            id="field"
            key={fieldIndex}
            className={
                `ps-2  align-items-center justify-content-center gap-3 col-md-${field.gridWidth || 12 - (field.gridOffset || 0)
                }  offset-md-${field.gridOffset || 0} ` +
                (field.labelAboveField ? "" : " d-flex ") +
                (fromScreenDesigner ? " mb-0 " : " mb-3 ")
            }
        >
            <div className={"align-items-center text-truncate " + (field.labelAboveField ? "" : " pe-3 text-end text-wrap")} >
                {renderLabel(field)}
            </div>

            <div
                className={" " + (field.labelAboveField ? "" : "col-md-8 ")}
                style={{
                    minWidth: fromScreenDesigner && "80%",
                }}
            >
                <FieldType
                    field={field}
                    userScreenFieldId={userScreenFieldId}
                    formData={formData}
                    onInputChange={handleInputChange}
                    fromScreenDesigner={fromScreenDesigner}
                />
                {validationMessage(field, userScreenFieldId)}
            </div>
                      

        </div>
    );
};

export default QuestionField;
