import * as React from "react";
import * as ReactDOM from "react-dom";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { groupBy } from "@progress/kendo-data-query";
import formStyles from "../../../Contacts/Contact.module.scss";
import * as api from "api/index";
import {
    setExpandedState,
    setGroupIds,
} from "@progress/kendo-react-data-tools";
import { Dialog } from "@progress/kendo-react-dialogs";
import { NumericCell } from "../../../DibursementPayment/DisbursementInputField";
import { NumericTextBox } from "@progress/kendo-react-inputs";
import AddBillingTargets from "./AddBillingTarget";
import { handleApiError } from "Helper";

let products = {};

const initialGroup = [
    {
        field: "month",
    },
];

const processWithGroups = (data, group) => {
    const newDataState = groupBy(data, group);
    setGroupIds({
        data: newDataState,
        group: group,
    });
    return newDataState;
};

const UserTargets = (props) => {


    const [group, setGroup] = React.useState(initialGroup);
    const [addBillingTargets, setAddBillingTargets] = React.useState(false);
    const [maxCounter, setMaxCounter] = React.useState(props.data);
    const [error, setError] = React.useState(false);
    const [monthsData, setMonthsData] = React.useState([]);

    const [resultState, setResultState] = React.useState(
        processWithGroups(props.data, initialGroup)
    );

    const [collapsedState, setCollapsedState] = React.useState([]);
    const onGroupChange = React.useCallback((event) => {
        const newDataState = processWithGroups(products, event.group);
        setGroup(event.group);
        setResultState(newDataState);
    }, []);

    const onExpandChange = React.useCallback(
        (event) => {
            const item = event.dataItem;
            if (item.groupId) {
                const newCollapsedIds = !event.value
                    ? [...collapsedState, item.groupId]
                    : collapsedState.filter((groupId) => groupId !== item.groupId);
                setCollapsedState(newCollapsedIds);
            }
        },
        [collapsedState]
    );

    const newData = setExpandedState({
        data: resultState,
        collapsedIds: collapsedState,
    });

    const calculateAmount = () => {
        let value = 0;
        dataState.map((item) => {
            value = value + item.value;
        });

        return value;
    };

    const [dataState, setData] = React.useState(props.data);
    const [dataToPost, setDataToPost] = React.useState(dataState);

    React.useEffect(() => {
        calculateAmount();
    }, [dataState]);

    const handleSubmit = async () => {
        setError(false);
        let values = [];
        let data = [];
        dataState.map((item_1) => {
            let itemToPost = {};
            values = [];
            let dataTemp = {};
            dataTemp.active = true;
            dataTemp.branchId = null;
            dataTemp.departmentId = null;
            dataTemp.value = item_1.value;
            itemToPost.month = item_1.originalMonth;
            values.push(dataTemp);
            itemToPost.targetValues = values;
            data.push(itemToPost);
        });
        try {
            await api.editBillingTargets(props.item.userID, data);
        } catch (error) {
            setError(true);
            handleApiError(error);
            return;
        }

        props.cancelEdit();
    };

    const itemChange = (event) => {
        let dataTemp = [];
        const newData = dataState.map((item_1) => {
            if (item_1.counter === event.dataItem.counter) {
                item_1[event.field] = event.value;
            }
            dataTemp.push(item_1);
        });
        setDataToPost(dataTemp);
    };

    React.useEffect(() => {
        let dataState = [];
        newData.map((item) => {
            dataState.push(item.value);
        });
        setMonthsData(dataState);
    }, []);



    return (

        <Dialog
            width={800}
            height={800}
            onClose={props.cancelEdit}
            className="d-flex flex-column h-100"
            title={` Billing Target - ${props.item?.displayname} (${props.item?.userRef})`}
        >
            <div style={{ width: "80%" }} className="mx-auto mt-3">
                <Grid
                    data={dataState}
                    onItemChange={itemChange}
                    rowHeight="10"
                    style={{
                        margin: "auto",
                        height: 500,
                        overflow: "auto",
                    }}                    
                >
                    <Column field="month" title="Month" className="ps-2" />
                    <Column field="value" title="Target" cell={NumericCell} width="200" headerClassName="grid-header-center" />

                </Grid>


                <div
                    style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        flexDirection: "column",
                        float: "right",
                        gap: "15px",
                    }}
                >
                    <div className=" fw-bold right-align d-flex align-items-center justify-content-center mt-4">

                        <div className="pe-3">
                            <p className="fs-md mt-2">Total Yearly Target</p>
                        </div>

                        <NumericTextBox
                            className="me-3 fw-bold"
                            width={180}
                            style={{ textAlign: "right" }}
                            value={calculateAmount()}
                            readOnly={true}
                            placeholder="0.00"
                            spinners={false}
                            format="n2"
                            
                        />
                    </div>
                </div>

                {addBillingTargets && (
                    <AddBillingTargets
                        monthsData={monthsData}
                        data={dataState}
                        setData={(item) => {
                            setData(item);
                        }}
                        cancelEdit={() => {
                            setAddBillingTargets(false);
                        }}
                    />
                )}
                {error ? (
                    <p style={{ color: "red" }}>Error occured while posting the data</p>
                ) : (
                    <p style={{ visibility: "hidden" }}>ok</p>
                )}
            </div>


            <div
                className={` ${formStyles["form-footer"]} text-end`}
                style={{ height: "65px", marginTop: 142 }}
            >
                <button
                    className="common-btn bgBtnSecondary border-white me-4"
                    onClick={props.cancelEdit}
                    type={"submit"}
                >
                    Cancel
                </button>
                <button
                    className="k-button common-btn me-5"
                    type={"submit"}
                    onClick={handleSubmit}
                >
                    Save
                </button>
            </div>
        </Dialog>
    );
};
export default UserTargets;
