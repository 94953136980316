import {
  START_LOADING,
  STOP_LOADING,
  SUCCESS,
  GET_CATEGORY_LIST,
  EDIT_CATEGORY_LIST,
  ADD_CATEGORY,
  GET_WORKTYPE_DEPARTMENT
} from "../types/index";
import * as api from "../api/index.js";
import { handleApiError } from "Helper";
import { toast } from "react-toastify";

export const getCategory = (page, limit) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const datas = await api.getCategoryAllList(page, limit);
    dispatch({ type: GET_CATEGORY_LIST, payload: datas.data });
    dispatch({ type: STOP_LOADING });
    dispatch({ type: SUCCESS, payload: datas.message });
  } catch (error) {
    handleApiError(error);
  }
};

export const getCategoryByDepartment = (id) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const datas = await api.getWorkTypeByDepartment(id);
    dispatch({ type: GET_WORKTYPE_DEPARTMENT, payload: datas.data });
    dispatch({ type: STOP_LOADING });
    dispatch({ type: SUCCESS, payload: datas.message });
  } catch (error) {
    handleApiError(error);
  }
}


export const editCategory = (id, data) => async (dispatch) => {
  try {
    const datas = await api.editCategory(id, data);
    toast.success(`Work Type '${data?.name}' has been updated`);
    dispatch({ type: EDIT_CATEGORY_LIST, payload: datas });
  } catch (error) {
    toast.error(`Error occured while updating`);
    handleApiError(error);
  }
};

export const addCategory = (data) => async (dispatch) => {
  try {
    const datas = await api.addCategory(data);
    dispatch({ type: ADD_CATEGORY, payload: datas });
  } catch (error) {
    handleApiError(error);
  }
};
