import {
  START_LOADING,
  STOP_LOADING,
  ERROR,
  SUCCESS,
  ADD_FILES,
  REMOVE_FILES,
  CLEAR_FILES,
  ADD_FILES_BILL,
  REMOVE_FILES_BILL,
  CLEAR_FILES_BILL,
  ADD_FILES_DISBURSEMENT_POSTING,
  REMOVE_FILES_DISBURSEMENT_POSTING,
  CLEAR_FILES_DISBURSEMENT_POSTING,
  ADD_FILES_OFFICE_AND_ALLOCATIONS,
  REMOVE_FILES_OFFICE_AND_ALLOCATIONS,
  CLEAR_FILES_OFFICE_AND_ALLOCATIONS,
  ADD_OFFICE_POSTINGS,
  REMOVE_OFFICE_POSTINGS,
  CLEAR_OFFICE_POSTINGS,
  ADD_CLIENT_POSTINGS,
  REMOVE_CLIENT_POSTINGS,
  CLEAR_CLIENT_POSTINGS,
  ADD_FILES_CHIT_REQUEST,
  REMOVE_FILES_CHIT_REQUEST,
  CLEAR_FILES_CHIT_REQUEST,
} from "../types/index.js";

const FilesDownload = (
  state = {
    IsError: false,
    isSuccess: false,
    isLoading: undefined,
    files: [],
    disbPaymentFiles: [],
    billFiles: [],
    officeBillandAllocationFiles: [],
    officePostingFiles: [],
    clientPostingFiles: [],
    chitRequestFiles: [],
  },
  action
) => {
  switch (action.type) {
    case START_LOADING:
      return { ...state, isLoading: true };
    case STOP_LOADING:
      return { ...state, isLoading: false };
    case ADD_FILES:
      state.files.push(action.payload);
      return { ...state };
    case REMOVE_FILES:
      state.files.map((item) => {
        if (item.filename === action.payload) {
          const index = state.files.indexOf(item);
          state.files.splice(index, 1);
        }
      });
      return { ...state };
    case CLEAR_FILES:
      state.files.splice(0, state.files.length);
      return { ...state };
    case ADD_FILES_DISBURSEMENT_POSTING:
      state.disbPaymentFiles.push(action.payload);
      return { ...state };
    case REMOVE_FILES_DISBURSEMENT_POSTING:
      state.disbPaymentFiles.map((item) => {
        if (item.filename === action.payload) {
          const index = state.disbPaymentFiles.indexOf(item);
          state.disbPaymentFiles.splice(index, 1);
        }
      });
      return { ...state };
    case CLEAR_FILES_DISBURSEMENT_POSTING:
      state.disbPaymentFiles.splice(0, state.disbPaymentFiles.length);
      return { ...state };
    case ADD_FILES_BILL:
      state.billFiles.push(action.payload);
      return { ...state };
    case REMOVE_FILES_BILL:
      state.billFiles.map((item) => {
        if (item.filename === action.payload) {
          const index = state.billFiles.indexOf(item);
          state.billFiles.splice(index, 1);
        }
      });
      return { ...state };
    case CLEAR_FILES_BILL:
      state.billFiles.splice(0, state.billFiles.length);
      return { ...state };
    case ADD_FILES_OFFICE_AND_ALLOCATIONS:
      state.officeBillandAllocationFiles.push(action.payload);
      return { ...state };
    case REMOVE_FILES_OFFICE_AND_ALLOCATIONS:
      state.officeBillandAllocationFiles.map((item) => {
        if (item.filename === action.payload) {
          const index = state.officeBillandAllocationFiles.indexOf(item);
          state.officeBillandAllocationFiles.splice(index, 1);
        }
      });
      return { ...state };
    case CLEAR_FILES_OFFICE_AND_ALLOCATIONS:
      state.officeBillandAllocationFiles.splice(
        0,
        state.officeBillandAllocationFiles.length
      );
      return { ...state };
    case ADD_OFFICE_POSTINGS:
      state.officePostingFiles.push(action.payload);
      return { ...state };
    case REMOVE_OFFICE_POSTINGS:
      state.officePostingFiles.map((item) => {
        if (item.filename === action.payload) {
          const index = state.officePostingFiles.indexOf(item);
          state.officePostingFiles.splice(index, 1);
        }
      });
      return { ...state };
    case CLEAR_OFFICE_POSTINGS:
      state.officePostingFiles.splice(0, state.officePostingFiles.length);
      return { ...state };
    case ADD_CLIENT_POSTINGS:
      state.clientPostingFiles.push(action.payload);
      return { ...state };
    case REMOVE_CLIENT_POSTINGS:
      state.clientPostingFiles.map((item) => {
        if (item.filename === action.payload) {
          const index = state.clientPostingFiles.indexOf(item);
          state.clientPostingFiles.splice(index, 1);
        }
      });
      return { ...state };
    case CLEAR_CLIENT_POSTINGS:
      state.clientPostingFiles.splice(0, state.clientPostingFiles.length);
      return { ...state };
    case ADD_FILES_CHIT_REQUEST:
      state.chitRequestFiles.push(action.payload);
      return { ...state };
    case REMOVE_FILES_CHIT_REQUEST:
      state.chitRequestFiles.map((item) => {
        if (item.filename === action.payload) {
          const index = state.chitRequestFiles.indexOf(item);
          state.chitRequestFiles.splice(index, 1);
        }
      });
      return { ...state };
    case CLEAR_FILES_CHIT_REQUEST:
      state.chitRequestFiles.splice(0, state.chitRequestFiles.length);
      return { ...state };
    case ERROR:
      return { ...state, IsError: true, isSuccess: false };
    case SUCCESS:
      return { ...state, IsError: false, isSuccess: true };
    default:
      return state;
  }
};
export default FilesDownload;
