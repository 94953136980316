import * as React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import Title from "./TabTitle";
import Suppliers from "../../pages/Purchase/Suppliers";
import SupplierLedger from "../../pages/Purchase/SupplierLedger";
import DisbursementPayment from "../../pages/DibursementPayment/DisbursementPayment";
import {
  useLocation,
  useNavigate,
  useParams,
  useMatch,
} from "react-router-dom";
import { addTab, removeTab } from "actions/tabs.actions";
import { nameFunction } from "components/common/formatter";
import BatchPostings from "pages/BatchPosting";
import CONSTANT from "CONSTANT/Matter/PostingTypes";
import PaySupplier from "pages/PaySupplier/PaySupplier";
import { Tabs } from "components/Enums/TabEnums.ts";
import { useAllowedLiscence } from "hooks/useAllowedLiscence";
import AccessRevokedModal from "components/common/AccessRevokedModal";

const PurchaseTabs = React.memo((props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data } = useParams();
  const [tabs, setTabs] = React.useState([]);
  const location = useLocation();
  const tabsData = useSelector((state) => state.tabsData.purchaseTabs);
  const { allowed } = useAllowedLiscence(Tabs.PURCHASE);

  const findContent = (item) => {
    switch (item) {
      case "suppliers":
        return "Suppliers";
      case "supplierledger":
        return "Supplier Ledger";
      case "purchaseinvoice":
        return "Purchase Invoice";
      case "disbursements":
        return "Disbursements";
      case "paydisbursement":
        return "Pay Disbursement";
      case "paysupplier":
        return "Pay Supplier";
      default:
        break;
    }
  };

  const urlDefiner = (e) => {
    if (params !== null) {
      let url = tabs[e]?.toLowerCase();
      if (url === undefined) {
        url = "";
      }
      // window.history.replaceState(null, '', `/nominal/${url}`)
      navigate(`/purchase/${url}`);
    }
  };

  const params = useMatch("purchase/:data");

  useEffect(() => {
    if (params !== null) {
      let data = params?.params?.data;
      if (!tabsData.includes(data?.toLowerCase())) {
        dispatch(addTab("purchase", data?.toLowerCase()));
      } else {
        setSelected(tabsData.indexOf(data?.toLowerCase()));
      }
    }
  }, [params]);

  useEffect(() => {
    if (tabsData) {
      setTabs(tabsData);
      let index = tabsData.indexOf(params?.params?.data.toLowerCase());
      setSelected(index === -1 ? 0 : index);
    }
  }, [tabsData]);

  const removeTabDispatcher = (tab) => {
    if (nameFunction(tab) === `${params?.params?.data}`) {
      let index = nameFunction(tabs[0]);
      navigate(`/purchase/${index}`);
    }
    if (tabs.length === 1) {
      navigate("/");
    }
    dispatch(removeTab("purchase", tab));
  };

  const [selected, setSelected] = React.useState(-1);

  const handleSelect = (e) => {
    urlDefiner(e.selected);
  };

  return (
  allowed ? 
    <TabStrip
      selected={selected}
      onSelect={handleSelect}
      keepTabsMounted={true}
      className="navigationTab"
    >
      {tabs.map((item, index) => {
        return (
          item && (
            <TabStripTab
              title={
                <Title
                  content={findContent(item)}
                  onTabRemove={removeTabDispatcher}
                  selected={findContent(tabs.at(selected))}
                  count={tabs.length}
                />
              }
              key={index}
            >
              <div style={{ height: "100%" }}>
                {item === "suppliers" && <Suppliers />}
                {item === "supplierledger" && <SupplierLedger />}
                {item === "purchaseinvoice" && (
                  <BatchPostings type={CONSTANT.PI} />
                )}
                {item === "paydisbursement" && <DisbursementPayment />}
                {item === "disbursements" && (
                  <BatchPostings type={CONSTANT.DIS} />
                )}
                {item === "paysupplier" && <PaySupplier />}
              </div>
            </TabStripTab>
          )
        );
      })}
    </TabStrip>
    :
    <AccessRevokedModal removeTab={() => props.closeTab()} />
  );
});

export default PurchaseTabs;
