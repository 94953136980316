import {
  START_LOADING,
  STOP_LOADING,
  ERROR,
  SUCCESS,
  GET_MATTERS_LIST,
  SEARCH_MATTER,
  GET_MATTERS_DETAILS,
  GET_MATTERS_BALANCES,
  GET_MATTERS_POSTINGS,
  CLEAR_MATTERS_POSTINGS,
  CLEAR_MATTERS_BALANCES,
  GET_MATTERS_VIEW_DETAILS,
  ADD_MATTER_VIEW_TAB,
  ADD_CONTACTS_VIEW_TAB,
  REMOVE_MATTER_VIEW_TAB,
  GET_MY_MATTERS_LIST,
  FETCH_MATTER_ACTIVITIES,
  FETCH_MATTER_TASKS,
  ADD_CLIENTS_VIEW_TAB,
} from "../types/index.js";

const getMatterTabName = (item) => {
  if (
    item?.matterDetails !== null &&
    item?.matterDetails !== "" &&
    item?.matterDetails !== undefined
  )
    return item?.matterDetails;
  return item.matterRef;
};

const mattersReducer = (
  state = {
    IsError: false,
    isSuccess: false,
    isLoading: undefined,
    matterViewDetails: {},
    openTab: undefined,
    matterViewTabs: [{ matterId: null, matterRef: "Matters List" }],
    matterActivities: {},
    matterTasks: {},
  },
  action
) => {
  switch (action.type) {
    case START_LOADING:
      return { ...state, isLoading: true };
    case STOP_LOADING:
      return { ...state, isLoading: false };
    case GET_MATTERS_LIST:
      return {
        ...state,
        mattersList: action.payload,
      };
    case GET_MY_MATTERS_LIST:
      return {
        ...state,
        myMattersPageList: action.payload,
      };
    case GET_MATTERS_DETAILS:
      return {
        ...state,
        mattersDetails: action.payload,
      };
    case GET_MATTERS_BALANCES:
      if (action.payload.component === "Matter Ledger") {
        return {
          ...state,
          mattersBalances: action.payload,
        };
      } else if (action.payload.component === "All Postings") {
        return {
          ...state,
          mattersBalancesAllPostings: action.payload,
        };
      } else if (action.payload.component === "Office Postings") {
        return {
          ...state,
          mattersBalancesOfficePostings: action.payload,
        };
      } else if (action.payload.component === "Client Postings") {
        return {
          ...state,
          mattersBalancesClientPostings: action.payload,
        };
      } else if (action.payload.component === "Chit Request") {
        return {
          ...state,
          mattersChitRequest: action.payload,
        };
      } else if (action.payload.component === "Bills") {
        return {
          ...state,
          mattersBills: action.payload,
        };
      } else if (action.payload.component === "Time Recording") {
        return {
          ...state,
          timeRecording: action.payload,
        };
      }
    case CLEAR_MATTERS_BALANCES:
      if (action.payload === "Matter Ledger") {
        return {
          ...state,
          matterDetails: undefined,
          mattersBalances: {
            data: {
              matterBalances: {},
              clientBalances: {},
            },
          },
        };
      } else if (action.payload === "All Postings") {
        return {
          ...state,
          mattersBalancesAllPostings: {
            data: {
              matterBalances: {},
              clientBalances: {},
            },
          },
        };
      } else if (action.payload === "Office Postings") {
        return {
          ...state,
          mattersBalancesOfficePostings: {
            data: {
              matterBalances: {},
              clientBalances: {},
            },
          },
        };
      } else if (action.payload === "Client Postings") {
        return {
          ...state,
          mattersBalancesClientPostings: {
            data: {
              matterBalances: {},
              clientBalances: {},
            },
          },
        };
      } else if (action.payload === "Chit Request") {
        return {
          ...state,
          mattersChitRequest: {
            data: {
              matterBalances: {},
              clientBalances: {},
            },
          },
        };
      }

    case GET_MATTERS_POSTINGS:
      return {
        ...state,
        mattersPostings: action.payload,
      };
    case CLEAR_MATTERS_POSTINGS:
      return {
        ...state,
        mattersPostings: [],
      };
    case SEARCH_MATTER:
      return {
        ...state,
        mattersList: action.payload,
      };
    case GET_MATTERS_VIEW_DETAILS:
      state.matterViewDetails[action.payload.data.matterRef] = action.payload;
      return {
        ...state,
      };
    case ADD_MATTER_VIEW_TAB:
      let check = false;
      state.matterViewTabs.map((item) => {
        if (item.matterRef === action.payload.matterRef) {
          state.openTab = action.payload;
          check = true;
        }
      });
      if (check) {
        return {
          ...state,
        };
      }
      state.matterViewTabs.push(action.payload);
      state.openTab = undefined;
      return {
        ...state,
      };

    case ADD_CLIENTS_VIEW_TAB:
      state.matterViewTabs.unshift("Clients List");
      return {
        ...state,
      };

    case ADD_CONTACTS_VIEW_TAB:
      state.matterViewTabs.unshift("Contacts List");
      return {
        ...state,
      };

    case REMOVE_MATTER_VIEW_TAB:
      let toRemove;
      state.matterViewTabs.map((item) => {
        if (getMatterTabName(item) === action.payload) {
          toRemove = state.matterViewTabs.indexOf(item);
        }
      });
      state.matterViewTabs.splice(toRemove, 1);
      state.openTab = undefined;
      return {
        ...state,
      };
    case FETCH_MATTER_ACTIVITIES:
      return {
        ...state,
        matterActivities: {
          ...state.matterActivities,
          [action.id]: action.payload,
        },
      };
    case FETCH_MATTER_TASKS:
      return {
        ...state,
        matterTasks: {
          ...state.matterTasks,
          [action.id]: action.payload,
        },
      };
    case ERROR:
      return { ...state, IsError: true, isSuccess: false };
    case SUCCESS:
      return { ...state, IsError: false, isSuccess: true };
    default:
      return state;
  }
};
export default mattersReducer;
