export const CONSTANT = {
  COMBOBOX: {
    CLIENT: "Lead_Client",
    SUPERVISIOR: "Supervisior",
    FEE_EARNER: "Fee_Earner",
    APPLICATION: "Application",
    DEPARTMENT: "Department",
    CATEGORY: "Work_Type",
    BRANCH: "Branch",
    DDA_BANK: "Default_DDA_Bank",
    PROFIT_COST_NOMINAL: "Default_Profit_Cost_Nominal",
    CONTACT_LINK: "Contact_Link",
    REFERRRER: "Referrer",
    SOURCE: "Source",
    TYPE: "Type",
    RATE_TYPE: "Rate_Type",
  },
  DATE: {
    START_DATE: "Start_Date",
    CLOSED_DATE: "Closed_Date",
    DELETED_DATE: "Deleted_Date",
    DOB_OS1: "Date_of_Birth_OS1",
    DOB_OS2: "Date_of_Birth_OS2",
    DATE_OF_DOC: "Date_of_Document",
    CODICIL_DATE_1: "Codicil_Date_1",
    LODGEMENT_DATE: "Lodgment_Date",
    CODICIL_DATE_2: "Codicil_Date_2",
    REVIEW_DATE: "Review Date",
    DATE_OF_WILL: "Date of Will",
  },
  INPUT: {
    LOCATION: "Location",
    MATTER_REF: "Matter_Ref",
    WIP_CREDIT_LIMIT: "WIP_Credit_Limit",
    DISP_CREDIT_LIMIT: "Disp_Credit_Limit",
    BILL_CREDIT_LIMIT: "Bill_Credit_Limit",
    LAST_NAME_OS1: "Last_Name_OS1",
    LAST_NAME_OS2: "Last_Name_OS2",
    FIRST_NAME_OS1: "Fisrt_Name_0S1",
    FIRST_NAME_OS2: "First_Name_0S2",
    POST_CODE_OS1: "Post_Code_0S1",
    POST_CODE_OS2: "Post_Code_OS2",
    CONTACT: "Contact",
    REFERENCE: "Reference",
    LOCATION_CODE: "Location_Code",
  },
};
