import * as React from "react";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Input, Checkbox, NumericTextBox } from "@progress/kendo-react-inputs";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { Label } from "@progress/kendo-react-labels";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import imageDefault from "assets/images.jpeg";
import { FaCamera } from "react-icons/fa";
import { CiViewList } from "react-icons/ci";
import DefaultProfile from "../../../assets/Default-Profile.png";
import * as api from "api/index";
import { useDispatch } from "react-redux";
import { useUploadForm } from "components/fileUploadHook";
import { addFiles } from "actions/files.action";
import UserBillingTargets from "./BillingTargets/UserBillingTargets";
import UserTimeTargets from "./TimeTargets/UserTimeTargets";
import { handleApiError } from "Helper";
import styles from "../Settings.module.scss";
import CalenderCustomToggleButton from "components/common/CalenderCustomToogleButton";
import { FileDocumentTypes } from "components/Enums/FileDocumentTypes.ts";
import { CustomDateInput } from "components/common/CustomDateInput";

const Days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const CustomSecurityDropDown = (fieldRenderProps) => {
  const {
    // The meta props of the Field.
    validationMessage,
    touched,
    visited,
    modified,
    valid,
    // The input props of the Field.
    value,
    onChange,
    onFocus,
    onBlur,
    // The custom props that you passed to the Field.
    ...others
  } = fieldRenderProps;
  const [securityProfiles, setSecurityProfiles] = useState([]);
  const dataFetcher = async (e) => {
    try {
      const data = await api.getActiveSecurityProfiles();
      let dataToPass = data?.data?.data;
      setSecurityProfiles(dataToPass);
    } catch (error) {
      handleApiError(error);
    }
  };
  const [valueNominal, setValueNominal] = useState(null);

  useEffect(() => {
    dataFetcher();
  }, []);

  useEffect(() => {
    if (securityProfiles.length > 0) {
      securityProfiles.map((item) => {
        if (item.profileId === value) {
          setValueNominal(item);
        }
      });
    }
  }, [securityProfiles]);
  const onValueChange = React.useCallback(
    (e) => {
      setValueNominal(e.value);
      onChange({
        value: e?.value?.profileId,
      });
    },
    [onChange, value]
  );
  return (
    <div
      onFocus={onFocus}
      onBlur={onBlur}
      className="d-flex flex-column user-edit-section"
    >
      <Label className="label mt-1 mb-2  w-100">Security Profile&nbsp;</Label>
      <ComboBox
        data={securityProfiles}
        style={{ width: "300px" }}
        className="ps-3"
        value={valueNominal}
        textField={"profileName"}
        iconClassName="wa"
        onChange={onValueChange}
        placeholder={"Search Security Profile"}
      />
      <label className={"k-checkbox-label"} htmlFor={others.id}>
        {others.label}
      </label>
    </div>
  );
};

const EditForm = (props) => {
  const [image, setimage] = useState(null);
  const dispatch = useDispatch();
  const [data, setData] = React.useState([]);
  const [type, setType] = useState();
  const [timeTargetData, setTimeTargetData] = useState([]);
  const [showTimeTargets, setShowTimeTargets] = useState(false);
  const [userRef, setUserRef] = useState(props.item?.userRef);
  const [imageUrl, setImageUrl] = useState(
    props.type === "profile" ? null : DefaultProfile
  );
  const [showTargets, setShowTargets] = useState(false);
  const { uploadForm } = useUploadForm();
  const profileURL = useSelector((state) => state.currentuser);
  useEffect(() => {
    if (profileURL.downloadURL && profileURL.downloadURL !== "") {
      setImageUrl(profileURL.downloadURL);
    } 
  }, [profileURL]);
  const READ_ONLY = props?.item?.type === "View";
  const branchesList = useSelector((state) => {
    let data = [{ branchId: null, branchName: "Any Branch" }];
    data.push(...state.branchesnames.branchesList);
    return data;
  });
  const branchFinder = (id) => {
    let name;
    branchesList.map((item) => {
      if (item.branchId === id) {
        name = item.branchName;
      }
    });
    return name;
  };
  const openBillingTargets = async () => {
    const dataFetcher = async () => {
      try {
        const data = await api.getBillingTargets(props.item.userID);
        const finalList = [];
        let counter = 1;
        data?.data?.data.map((item) => {
          let date = `${new Date(item.month).toLocaleString("default", {
            month: "long",
          })} ${new Date(item.month).toLocaleString("default", {
            year: "2-digit",
          })}`;
          item?.targetValues?.map((item_1) => {
            item_1.originalMonth = item.month;
            item_1.month = date;
            item_1.counter = counter;
            item_1.branchName = branchFinder(item_1.branchId);
            finalList.push(item_1);
            counter++;
          });
        });
        setData(finalList);
      } catch (error) {
        handleApiError(error);
      }
    };
    await dataFetcher();
    setShowTargets(true);
  };

  const openTimeTargets = async () => {
    const dataFetcher = async () => {
      try {
        const data = await api.getTimeTargets(props.item.userID);
        const finalList = [];
        let counter = 1;

        props.item.workingDays.split(", ").map((item) => {
          let isAdded = false;
          let object = {
            weekDayName: item,
            value: 0,
            counter: counter,
          };
          data?.data?.data.map((item_1) => {
            if (item === item_1.weekDayName) {
              object.value = item_1.value;
              object.weekDayNo = item_1.weekDayNo;
              isAdded = true;
            }
          });
          if (!isAdded) {
            object.value = 0;
            object.weekDayNo = Days.indexOf(item);
          }
          counter++;
          finalList.push(object);
        });
        setTimeTargetData(finalList);
      } catch (error) {
        handleApiError(error);
      }
    };
    await dataFetcher();
    setShowTimeTargets(true);
  };

  const changeHandler = async (e) => {
    setimage(e.target.files[0]);
    const form = new FormData();
    form.append("File", e.target.files[0]);
    const data = {
      filename: e.target.files[0].name,
    };
    const dataChoosen = await uploadForm(data, e.target.files[0], {
      documentType: FileDocumentTypes.UserImage,
      userRef: userRef,
    });
    document.getElementById("form-pic-submit")?.click();
    if (props.type === "profile") {
      props.setFileStatus(dataChoosen.data?.data);
    }
    if (props.type === "addNew") {
      dispatch(addFiles("Profile", dataChoosen));
    } else {
      dispatch(addFiles("EditProfile", dataChoosen));
    }
  };
  return (
    <>
      <div className="pt-4 ms-2 d-flex align-items-center justify-content-center">
        <Form
          onSubmit={(e) => {
            props.onSubmit({ userRef: userRef, ...e });
          }}
          initialValues={props.item}
          readOnly={true}
          render={(formRenderProps) => (
            <FormElement
              style={{
                width: props.type === "profile" ? "75%" : "90%",
              }}
            >
              <fieldset className={"k-form-fieldset"}>
                <div className="d-flex align-items-center justify-content-between">
                  <div className="">
                    <div className="mb-3">
                      <Label className="label">User Ref&nbsp;</Label>
                      <Input
                        name={"userRef"}
                        className="mt-1 ps-3"
                        value={userRef}
                        onChange={(e) => setUserRef(e.value)}
                        required
                        readOnly={READ_ONLY}
                        placeholder="Enter Details"
                      />
                    </div>
                    <button
                      id="form-pic-submit"
                      type="button"
                      style={{ visibility: "hidden" }}
                      onClick={() => {
                        formRenderProps.onChange("pic", { value: "updated" });
                      }}
                    ></button>
                    <div className="d-flex align-items-center gap-5">
                      <div className="mb-1">
                        <Label className="label mt-1">First Name&nbsp;</Label>
                        <Field
                          name={"firstName"}
                          component={Input}
                          required
                          readOnly={READ_ONLY}
                          className="mt-1 ps-3"
                          placeholder="Enter Details"
                        />
                      </div>
                      <div className="mb-1" style={{ width: "300px" }}>
                        <Label className="label mt-1">Date Joined&nbsp;</Label>
                        <Field
                          name={"dateJoined"}
                          toggleButton={CalenderCustomToggleButton}
                          placeholder="Select Date"
                          dateInput={CustomDateInput}
                          className="mt-1 ps-2 pe-1"
                          component={DatePicker}
                          disabled={READ_ONLY}
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="sectionstyle"
                    style={{ marginTop: "-30px", marginLeft: "200px" }}
                  >
                    <div
                      className={`${styles["image-container"]}`}
                      style={{ justifyContent: "flex-end", zIndex: 1 }}
                    >
                      {image === null ? (
                        <img
                          src={imageUrl}
                          style={{
                            cursor: "pointer",
                            width: "95px",
                            height: "95px",
                            borderRadius: "5px",
                            zIndex: 1,
                          }}
                        />
                      ) : (
                        <img
                          style={{
                            cursor: "pointer",
                            width: "95px",
                            height: "95px",
                            borderRadius: "5px",
                            zIndex: 1,
                          }}
                          src={
                            image !== null
                              ? URL.createObjectURL(image)
                              : DefaultProfile
                          }
                        />
                      )}
                      <div
                        style={{
                          marginLeft: "80px",
                          marginTop: "-25px",
                          zIndex: 10,
                          position: "relative",
                        }}
                        className={`${styles["image-icon-contaniner"]} d-flex align-items-center justify-content-center`}
                      >
                        {props?.item?.type !== "View" && (
                          <label
                            style={{ width: "max-content" }}
                            className="mb-1 cursor-pointer"
                          >
                            <input
                              name="file"
                              type="file"
                              onChange={changeHandler}
                            />
                            <FaCamera size={15} color="white" />
                          </label>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-center gap-5">
                  <div className="mb-3">
                    <Label className="label mt-1">Last Name&nbsp;</Label>
                    <Field
                      name={"lastName"}
                      className="mt-1 ps-3"
                      component={Input}
                      readOnly={READ_ONLY}
                      placeholder="Enter Details"
                    />
                  </div>
                  <div className="mb-3" style={{ width: "300px" }}>
                    <Label className="label mt-1">Date Qualified&nbsp;</Label>
                    <Field
                      name={"dateQualified"}
                      component={DatePicker}
                      toggleButton={CalenderCustomToggleButton}
                      placeholder="Select Date"
                      dateInput={CustomDateInput}
                      className="mt-1 ps-2 pe-1"
                      disabled={READ_ONLY}
                    />
                  </div>
                  <div className="mb-3" style={{ marginTop: "30px" }}>
                    <Field
                      name={"securityProfileId"}
                      component={CustomSecurityDropDown}
                      readOnly={READ_ONLY}
                    />
                    <Field
                      className={`${styles["checkbox"]} mt-3`}
                      name={"isAdmin"}
                      component={Checkbox}
                      readOnly={READ_ONLY}
                      label={"Admin"}
                    />
                  </div>
                </div>
                <div
                  className="d-flex align-items-center gap-5"
                  style={{ marginTop: "-20px" }}
                >
                  <div className="mb-3">
                    <Label className="label mt-1">Job Title&nbsp;</Label>
                    <Field
                      name={"jobTitle"}
                      component={Input}
                      className="mt-1 ps-3"
                      readOnly={READ_ONLY}
                      placeholder="Enter Details"
                    />
                  </div>
                  <div className="mb-3" style={{ width: "300px" }}>
                    <Label className="label mt-1">Date Left&nbsp;</Label>
                    <Field
                      className="mt-1 ps-2 pe-1"
                      name={"dateLeft"}
                      toggleButton={CalenderCustomToggleButton}
                      component={DatePicker}
                      placeholder="Select Date"
                      disabled={READ_ONLY}
                      dateInput={CustomDateInput}
                      // toggleButton={CalenderCustomToggleButton}
                    />
                  </div>
                </div>
                <div className="d-flex align-items-center gap-5">
                  <div className="mb-3">
                    <Label className="label mt-1">Email Address&nbsp;</Label>
                    <Field
                      className="mt-1 ps-3"
                      name={"emailAddress"}
                      readOnly={READ_ONLY}
                      placeholder="Enter Details"
                      required
                      component={Input}
                    />
                  </div>
                  <div className="mb-3 right-align">
                    <Label className="label mt-1">Default Rate&nbsp;</Label>
                    <Field
                      name={"defaultRate"}
                      className="mt-1 pe-3"
                      placeholder="0.00"
                      spinners={false}
                      format="n2"
                      component={NumericTextBox}
                      readOnly={READ_ONLY}
                      style={{
                        width: "300px",
                      }}
                    />
                  </div>
                </div>
                <div className="d-flex align-items-center gap-5">
                  <div className="mb-3">
                    <Label className="label mt-1">Phone No&nbsp;</Label>
                    <Field
                      name={"phoneNo"}
                      readOnly={READ_ONLY}
                      component={Input}
                      className="mt-1 ps-3"
                      placeholder="Enter Details"
                    />
                  </div>
                  <div className="mb-3 right-align d-flex align-items-center justify-content-center">
                    <div className="me-3">
                      <Label className="label mt-1">
                        Time Target (Daily Hours)&nbsp;
                      </Label>
                      <Field
                        name={"timeTarget"}
                        component={NumericTextBox}
                        readOnly={READ_ONLY}
                        spinners={false}
                        style={{ width: "300px", zIndex: "1" }}
                        defaultValue={0}
                        format="n2"
                        className="mt-1 pe-5"
                        placeholder="Enter Details"
                      />
                    </div>
                    <div
                      style={{
                        marginBottom: "-24px",
                        marginLeft: "-55px",
                        cursor: "pointer",
                        zIndex: "10",
                      }}
                    >
                      <CiViewList
                        size={30}
                        color={"#241CCA"}
                        onClick={openTimeTargets}
                      />
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-center gap-5">
                  <div className="mb-3">
                    <Label className="label mt-1">Mobile No&nbsp;</Label>
                    <Field
                      name={"mobileNo"}
                      readOnly={READ_ONLY}
                      component={Input}
                      style={{ zIndex: "1" }}
                      placeholder="Enter Details"
                      className="mt-1 ps-3"
                    />
                  </div>
                  <div className="mb-3 right-align d-flex align-items-center justify-content-center">
                    <div className="me-3">
                      <div className="me-3">
                        <Label className="label mt-1">
                          Billing Target (Current Year)&nbsp;
                        </Label>
                        <Field
                          name={"billingTarget"}
                          component={NumericTextBox}
                          readOnly={READ_ONLY}
                          spinners={false}
                          style={{ width: "300px", zIndex: "1" }}
                          defaultValue={0}
                          format="n2"
                          placeholder="Enter Details"
                          className="mt-1 pe-5"
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        marginBottom: "-24px",
                        marginLeft: "-70px",
                        cursor: "pointer",
                        zIndex: "10",
                      }}
                    >
                      <CiViewList
                        size={30}
                        color={"#241CCA"}
                        onClick={openBillingTargets}
                      />
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-center gap-2 mt-3">
                  <Field
                    className={styles["checkbox"]}
                    name={"active"}
                    readOnly={READ_ONLY}
                    defaultChecked={true}
                    component={Checkbox}
                    label={"Active"}
                  />
                  <Field
                    className={styles["checkbox"]}
                    name={"isFeeEarner"}
                    component={Checkbox}
                    readOnly={READ_ONLY}
                    label={"Fee Earner"}
                  />
                </div>
                {!props.isDialog && (
                  <div
                    className={`${styles["buttons-container"]} mt-4 d-flex align-items-center justify-content-between col-12 p-3`}
                  >
                    <div></div>
                    <div className="d-flex align-items-center gap-2 me-2">
                      <button
                        className="common-btn me-2 bgBtnSecondary"
                        onClick={props.cancelEdit}
                        type={"submit"}
                      >
                        Cancel
                      </button>
                      {props?.item?.type !== "View" && (
                        <button
                          className="common-btn"
                          disabled={!formRenderProps.allowSubmit}
                          type={"submit"}
                        >
                          {props.type === "addNew" ? "Save" : "Update"}
                        </button>
                      )}
                    </div>
                  </div>
                  // <div className="float-end">
                  // </div>
                )}
              </fieldset>
              {props.isDialog && (
                <button
                  style={{ visibility: "hidden" }}
                  id="user-form"
                  type={"submit"}
                ></button>
              )}
            </FormElement>
          )}
        />
        {showTargets ? (
          <UserBillingTargets
            cancelEdit={() => {
              setShowTargets(false);
            }}
            item={props.item}
            data={data}
            type={type}
          />
        ) : (
          ""
        )}
        {showTimeTargets ? (
          <UserTimeTargets
            cancelEdit={() => {
              setShowTimeTargets(false);
            }}
            item={props.item}
            data={timeTargetData}
          />
        ) : (
          ""
        )}
      </div>
      {props.isDialog && (
        <div className="dialog-footer">
          <button
            className="common-btn me-2 bgBtnSecondary"
            onClick={props.cancelEdit}
            type={"submit"}
          >
            Cancel
          </button>
          {props?.item?.type !== "View" && (
            <button
              className="common-btn me-3"
              onClick={() => document.getElementById("user-form")?.click()}
              type={"submit"}
            >
              {props.type === "addNew" ? "Save" : "Update"}
            </button>
          )}
        </div>
      )}
    </>
  );
};

export default EditForm;
