import { Button } from "@progress/kendo-react-buttons";
import { Checkbox } from "@progress/kendo-react-inputs";
import SearchTextBox from "components/SearchComponent";
import React, { useState } from "react";
import { useRef } from "react";
import styles from "./RiskAssessment.module.scss";
import EditIcon from "../../../assets/common/editBoxIcon.png";
import AddEditQuestion from "./AddNewQuestion";
import { Dialog } from "@progress/kendo-react-dialogs";
import {
    addRiskAssessmentQuestion,
    editRiskAssessmentQuestion,
    getRiskAssessmentQuestions,
} from "api";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { Loader } from "@progress/kendo-react-indicators";

export const QuestionsPopup = (props) => {
    const searchRef = useRef();
    const [questions, setQuestions] = useState([]);
    const [editItem, setEditItem] = useState(null);
    const [selectedQuestions, setSelectedQuestions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [addNewQuestion, setAddNewQuestion] = useState(false);

    const getRiskAssessmentQuestion = async () => {
        try {
            setIsLoading(true);
            const data = await getRiskAssessmentQuestions();
            setQuestions(data?.data?.data);
        }
        catch (error) {
        }
        finally {
            setIsLoading(false);
        }
    };

    const createQuestion = async (e) => {
        try {
            await addRiskAssessmentQuestion({ data: e });
            setAddNewQuestion(false);
            toast.success("Question Added");
        } catch (error) { }
    };

    const editQuestion = async (e) => {
        try {
            await editRiskAssessmentQuestion({ data: e }, e?.questionId);
            toast.success("Question Added");
        } catch (error) { }
    };
    const selectQuestion = (type, question) => {
        if (type) {
            setSelectedQuestions([...selectedQuestions, question]);
        } else {
            setSelectedQuestions(
                selectedQuestions.filter(
                    (prevQuestion) => prevQuestion.questionId !== question?.questionId
                )
            );
        }
    };

    const addQuestions = () => {
        props.addQuestions(selectedQuestions);
    };
    useEffect(() => {
        getRiskAssessmentQuestion();
    }, []);
    return (
        <>
            <div className="p-3 px-4 ms-3 me-3">
                <div className="d-flex align-items-center justify-content-between">
                    <SearchTextBox
                        width={"400px"}
                        ref={searchRef}
                        changeFilterValue={(e) => {

                        }}
                    />
                    <Button
                        className="common-btn"
                        onClick={() => setAddNewQuestion(true)}
                    >
                        Add New Question
                    </Button>
                </div>
                <div
                    className={`container-fluid mt-4 ${isLoading && "d-flex align-items-center justify-content-center"
                        }`}
                    style={{
                        height: "400px",
                        overflow: "auto",
                    }}
                    z
                >
                    {isLoading ? (
                        <Loader themeColor={"warning"} size={"large"} type={"pulsing"} />
                    ) : (
                        questions?.map((question, index) =>
                            props.questionIds.includes(question?.questionId) ? (
                                <></>
                            ) : (
                                <QuestionItem
                                    key={index}
                                    question={question}
                                    selectedQuestions={selectedQuestions}
                                    selectQuestion={selectQuestion}
                                    onSelectEdit={() => {
                                        setEditItem(question);
                                        setAddNewQuestion(true);
                                    }}
                                />
                            )
                        )
                    )}
                </div>
                {addNewQuestion && (
                    <Dialog
                        onClose={() => {
                            setEditItem(null);
                            setAddNewQuestion(false);
                        }}
                        // className="mattersDialog"
                        width={500}
                        title={`${editItem === null ? "Add" : "Edit"} Question`}
                    >
                        <AddEditQuestion
                            onSubmit={async (e) => {
                                if (editItem) {
                                    await editQuestion(e);
                                    setEditItem(null);
                                } else {
                                    await createQuestion(e);
                                }
                                setAddNewQuestion(false);
                                getRiskAssessmentQuestion();
                            }}
                            editItem={editItem}
                            onClose={() => {
                                setEditItem(null);
                                setAddNewQuestion(false);
                            }}
                            type={props.type}
                        />
                    </Dialog>
                )}
            </div>
            <div className="dialog-footer">
                <button
                    className="common-btn me-5"
                    type={"submit"}
                    onClick={addQuestions}
                >
                    {"Select"}
                </button>
            </div>
        </>
    );
};

const QuestionItem = ({
    question,
    onSelectEdit,
    selectQuestion,
    selectedQuestions,
}) => {
    return (
        <div
            className={`row ${styles["box-styles"]} p-2 d-flex align-items-center mt-2`}
        >
            <div className="col-1" style={{ maxWidth: "40px" }}>
                <Checkbox
                    defaultValue={false}
                    value={
                        selectedQuestions.filter(
                            (item) => item.questionId === question?.questionId
                        ).length
                    }
                    onChange={(e) => {
                        selectQuestion(e.value, question);
                    }}
                />
            </div>
            <div className={`col ${styles["question-text"]}`}>
                {question?.question}
            </div>

            <div className="col-1" style={{ minWidth: "100px" }}>
                <p className={`${styles["gray-text"]}`}>Yes Weight</p>
                <p className={`${styles["number-text"]} text-center me-4`}>
                    {question?.yesWeight}
                </p>
            </div>
            <div className="col-1" style={{ minWidth: "100px" }}>
                <p className={`${styles["gray-text"]}`}>No Weight</p>
                <p className={`${styles["number-text"]} text-center me-4`}>
                    {question?.noWeight}
                </p>
            </div>
            <div className="col-1" style={{ minWidth: "120px" }}>
                <p className={`${styles["gray-text"]}`}>Unknown Weight</p>
                <p className={`${styles["number-text"]} text-center me-4`}>
                    {question?.unknownWeight ? question?.unknownWeight : "-"}
                </p>
            </div>
            <div className="col-1" style={{ maxWidth: "50px" }}>
                <img
                    className="cursor-pointer"
                    onClick={() => onSelectEdit()}
                    src={EditIcon}
                ></img>
            </div>
        </div>
    );
};
