import {
  START_LOADING,
  STOP_LOADING,
  ERROR,
  SUCCESS,
  TRANS_POSTING_DETAILS,
  TRANS_POSTING_DETAILS_RESET,
} from "../types/index";
import * as api from "../api/index.js";
import { handleApiError } from "Helper";

export const getPostingDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const data = await api.getPostingdetails(id);
    dispatch({ type: TRANS_POSTING_DETAILS, payload: data });
    dispatch({ type: STOP_LOADING });
    dispatch({ type: SUCCESS, payload: data.message });
  } catch (error) {
    handleApiError(error)
  }
};

export const resetFields = (id) => (dispatch) => {
  try {
    dispatch({ type: TRANS_POSTING_DETAILS_RESET });
    dispatch({ type: STOP_LOADING });
    dispatch({ type: SUCCESS });
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};
