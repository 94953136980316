import {
  START_LOADING,
  STOP_LOADING,
  ERROR,
  SUCCESS,
  GET_CLIENTS_LIST,
  SEARCH_MATTER,
  RESPONSE_ERROR_LOGIN,
  ADD_CLIENTS_VIEW_TAB,
  REMOVE_CLIENT_VIEW_TAB,
  CHNAGE_CLINET_EIDT_PERMISSION,
  UPDATE_CLIENT_VIEW_TAB,
} from "../types/index";
import * as api from "../api/index.js";
import { handleApiError } from "Helper";

export const getClientsList =
  (page, limit, sort, filterParameters) => async (dispatch) => {
    try {
      if (page === undefined) {
        dispatch({ type: GET_CLIENTS_LIST, payload: undefined });
        return;
      }
      dispatch({ type: START_LOADING });
      let parameter;
      if (sort === undefined) {
        parameter = "clientRef";
      } else {
        if (sort.dir === "asc") {
          parameter = sort.field;
        } else if (sort.dir === "desc") {
          parameter = sort.field + " desc";
        }
      }
      if (filterParameters) filterParameters.search = undefined;
      const datas = await api.getClientsList(
        page,
        limit,
        parameter,
        filterParameters
      );
      dispatch({ type: GET_CLIENTS_LIST, payload: datas.data });
      dispatch({ type: STOP_LOADING });
      dispatch({ type: SUCCESS, payload: datas.message });
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 401) {
        dispatch({ type: RESPONSE_ERROR_LOGIN });
      }
      handleApiError(error);
      dispatch({ type: ERROR, payload: error });
    }
  };

export const searchClient =
  (page, limit, search, sort, filterParameters = {}) =>
  async (dispatch) => {
    try {
      let parameter;
      if (sort === undefined) {
        parameter = "clientRef";
      } else {
        if (sort.dir === "asc") {
          parameter = sort.field;
        } else if (sort.dir === "desc") {
          parameter = sort.field + " desc";
        }
      }
      if (search) {
        filterParameters.search = search;
      } else {
        filterParameters.search = undefined;
      }

      const res = await api.getClientsList(
        page,
        limit,
        parameter,
        filterParameters
      );
      dispatch({ type: GET_CLIENTS_LIST, payload: res.data });
    } catch (error) {
      if (error.response.status === 401) {
        dispatch({ type: RESPONSE_ERROR_LOGIN });
      }
      handleApiError(error);
      dispatch({ type: ERROR, payload: error });
    }
  };

export const getClientsAllInfo =
  (clientId, clientsData) => async (dispatch) => {
    try {
      let data = {
        id: clientId,
        data: clientsData,
      };
      dispatch({ type: ADD_CLIENTS_VIEW_TAB, payload: data });
      dispatch({ type: STOP_LOADING });
    } catch (error) {
      handleApiError(error);
    }
  };

export const removeClientsViewTab = (data) => async (dispatch) => {
  try {
    dispatch({ type: REMOVE_CLIENT_VIEW_TAB, payload: data });
    dispatch({ type: STOP_LOADING });
  } catch (error) {
    handleApiError(error);
  }
};

export const changeClientEditPermission = (data) => async (dispatch) => {
  try {
    dispatch({ type: CHNAGE_CLINET_EIDT_PERMISSION, payload: data });
  } catch (error) {
    handleApiError(error);
  }
};

export const updateClientViewTab = (data) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_CLIENT_VIEW_TAB, payload: data });
  } catch (error) {
    handleApiError(error);
  }
};
