import { CLOSE_PROFILE, OPEN_PROFILE } from "../types/index.js";

const OpenProfileReducer = (
  state = {
    open: false,
  },
  action
) => {
  switch (action.type) {
    case OPEN_PROFILE:
      return { open: true };
    case CLOSE_PROFILE:
      return { open: false };
    default:
      return state;
  }
};
export default OpenProfileReducer;
