import React, { useState, useEffect } from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";

import {
    getWorkFlowInterviews,
    getMatterVariables,
    updateMatterVariables,
} from "api";
import styles from "./CaseWorkflow.module.scss";
import QuestionField from "components/UserScreen/QuestionField";
import { Loader } from "@progress/kendo-react-indicators";

const UserScreen = ({ InterviewId, matterId, onClose }) => {
    const [Interview, setInterview] = useState("");
    const [questions, setQuestions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [formData, setFormData] = useState({});
    const [matterVariables, setMatterVariables] = useState([]);
    const [isSubmitted, setIsSubmitted] = useState(false);

    useEffect(() => {
        getInterviews();
    }, []);

    async function getInterviews() {
        try {
            const response = await getWorkFlowInterviews(InterviewId);
            setInterview(response.data.data.name);
            setQuestions(response.data.data.pages);
            getMatterVariable(response.data.data.pages);
        } catch (error) {
            console.error("Error fetching interviews:", error);
        }
    }

    const getMatterVariable = async (questions) => {
        try {
            const response = await getMatterVariables(matterId, InterviewId);
            setMatterVariables(response.data.data);
            // response.data.data.forEach((item) => {
            //   questions.forEach((question) => {
            //     console.log(question);
            //     const field = question.userScreenFields.find(
            //       (field) => field.variable === item.variableNo.toString()
            //     );
            //     console.log(field);
            //     if (field) {
            //       setFormData((prevData) => ({
            //         ...prevData,
            //         [question.userScreenPageId]: {
            //           ...prevData[question.userScreenPageId],
            //           [field.userScreenFieldId]: item.variablevalue
            //             ? item.variablevalue
            //             : null,
            //         },
            //       }));
            //     }
            //   });
            // });
            const resultObject = {};
            questions.forEach((question) => {
                resultObject[question.userScreenPageId] = {};
                question.userScreenFields.forEach((field) => {
                    const value = response.data?.data?.filter(
                        (item) => item.variableNo.toString() === field.variable
                    )[0];
                    resultObject[question.userScreenPageId][field.userScreenFieldId] =
                        value ? value.variablevalue : null;
                });
                setFormData(resultObject);
            });
        } catch (error) {
            console.error("Error fetching matter variables:", error);
        } finally {
            setLoading(false);
        }
    };
    const validationError = () => {
        let hasError = false;

        Object.entries(formData).forEach(([questionId, fields]) => {
            Object.entries(fields).forEach(([fieldId, value]) => {
                const field = questions
                    .find(
                        (question) => question.userScreenPageId.toString() === questionId
                    )
                    ?.userScreenFields.find(
                        (field) => field.userScreenFieldId.toString() === fieldId
                    );
                if (field && field.required && !value) {
                    hasError = true;
                }
            });
        });
        return hasError;
    };

    const validationMessage = (field, questionId) => {
        const fieldValue = formData[questionId]?.[field?.userScreenFieldId] || "";
        if (isSubmitted && field.required && !fieldValue) {
            return (
                <div className="fs-sm my-1" style={{ color: "red" }}>
                    {`${field.label} is required`}
                </div>
            );
        }
    };
    const handleContinue = async () => {
        setIsSubmitted(true);
        const data = Object.entries(formData).map(([questionId, fields]) =>
            Object.entries(fields).map(([fieldId, value]) => {
                const question = questions.find(
                    (question) => question?.userScreenPageId?.toString() === questionId
                );
                const variableNo = question.userScreenFields.find(
                    (field) => field?.userScreenFieldId?.toString() === fieldId
                ).variable;

                const matterVariable = matterVariables.find(
                    (variable) => variable.variableNo.toString() === variableNo
                );
                return {
                    variableNo,
                    variableValue: value,
                    version: matterVariable ? matterVariable.version : null,
                };
            })
        );

        if (!validationError()) {
            try {
                const filteredData = data.map((subArray) =>
                    subArray.filter((item) => item.variableNo)
                );
                if (filteredData.some((subArray) => subArray.length > 0)) {
                    await updateMatterVariables(matterId, ...filteredData);
                }
            } catch (error) {
                console.error("Error updating matter variables:", error);
            }
            onClose();
        }
    };

    const handleInputChange = (questionId, fieldId, value) => {
        setFormData((prevData) => ({
            ...prevData,
            [questionId]: {
                ...prevData[questionId],
                [fieldId]: value,
            },
        }));
    };

    return (
        <Dialog title={Interview} onClose={onClose} width="50vw">
            <div className={`${styles["userscreen-container"]}`}>
                {questions?.map((question, index) => (
                    <div key={index}>
                        <p className="pt-3 fw-semibold">{question.heading}</p>
                        <p className="fs-md">{question.subHeading} </p>
                        <p className="fs-md ">{question.preText}</p>

                        <div style={{ height: "65vh", overflow: "auto" }}>
                            <div className="container">
                                {loading ? (
                                    <div className="d-flex align-items-center justify-content-center">
                                        <Loader
                                            themeColor={"warning"}
                                            size={"large"}
                                            type={"pulsing"}
                                        />
                                    </div>
                                ) : (
                                    <div className="row m-3">
                                        {question?.userScreenFields
                                            ?.filter((field) => field.active)
                                            .map((field, fieldIndex) => (
                                                <QuestionField
                                                    question={question}
                                                    fieldIndex={fieldIndex}
                                                    formData={formData}
                                                    field={field}
                                                    userScreenFieldId={question.userScreenPageId}
                                                    handleInputChange={handleInputChange}
                                                    validationMessage={validationMessage}
                                                    isSubmitted={isSubmitted}
                                                />
                                            ))}
                                    </div>
                                )}
                            </div>

                            <p className="py-1 fs-md">{question.postText}</p>

                            <div className="d-flex justify-content-end align-items-end mx-5">
                                <Button
                                    className="common-btn button-small p-3 bgBtnPrimary border-0 "
                                    onClick={handleContinue}
                                    type="submit"
                                >
                                    Continue
                                </Button>
                            </div>

                            <p className="fw-semibold fs-md">{question?.underText}</p>
                        </div>
                    </div>
                ))}
            </div>
        </Dialog>
    );
};

export default UserScreen;
