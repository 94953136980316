import * as React from "react";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import {
  Input,
  Checkbox,
  NumericTextBox,
  TextArea,
} from "@progress/kendo-react-inputs";
import { Label } from "@progress/kendo-react-labels";
import DefaultImage from "../../../assets/check_box.-default.png";
import { useState } from "react";

const AddEditQuestion = (props) => {
  const IS_CLIENT = props.type === "Client";
  const [showUnknown, setShowUnknown] = useState(
    props.editItem?.unknownText ? true : false
  );
  return (
    // <div className="p-3 ps-4">
    <Form
      onSubmit={props.onSubmit}
      initialValues={props.editItem}
      readOnly={true}
      render={(formRenderProps) => (
        <FormElement
          style={{
            maxWidth: 1000,
          }}
        >
          <fieldset className={"k-form-fieldset"}>
            <div className="p-3 ps-5 pe-5">
              <Label className="label mt-2 mb-4">
                {IS_CLIENT ? "For Clients" : "For Matter"}
              </Label>
              <div className="mb-3 d-flex flex-column">
                <Label className="label mb-2">Question</Label>
                <Field
                  name={"question"}
                  component={TextArea}
                  placeHolder={"Enter Question"}
                  style={{
                    maxWidth: "480px",
                    height: "120px",
                  }}
                />
              </div>
              <div className="mb-3 d-flex align">
                <Label
                  className="label"
                  style={{
                    width: "150px",
                  }}
                >
                  Option
                </Label>
                <Label className="label">Weight</Label>
              </div>
              <div className="mb-3 d-flex">
                <div
                  className="d-flex align-items-center"
                  style={{
                    width: "150px",
                  }}
                >
                  <img height={"16px"} width={"16px"} src={DefaultImage}></img>
                  <Label className="label ms-3">Yes</Label>
                </div>
                <Field
                  name={"yesWeight"}
                  component={NumericTextBox}
                  spinners={false}
                  placeholder={"Enter Weight"}
                  style={{
                    width: "120px",
                    height: "32px",
                  }}
                />
              </div>
              <div className="mb-3 d-flex">
                <div
                  className="d-flex align-items-center"
                  style={{
                    width: "150px",
                  }}
                >
                  <img height={"16px"} width={"16px"} src={DefaultImage}></img>
                  <Label className="label ms-3">No</Label>
                </div>
                <Field
                  name={"noWeight"}
                  component={NumericTextBox}
                  spinners={false}
                  placeholder={"Enter Weight"}
                  style={{
                    width: "120px",
                    height: "32px",
                  }}
                />
              </div>
              <div className="mb-3 d-flex">
                <div
                  className="d-flex align-items-center"
                  style={{
                    width: "150px",
                  }}
                >
                  <Checkbox
                    value={showUnknown}
                    onChange={(e) => {
                      if (!e.value) {
                        formRenderProps.onChange("unknownText", { value: "" });
                      }
                      setShowUnknown(e.value);
                    }}
                    className="me-3 mt-2"
                  />
                  <Field
                    defaultChecked={true}
                    name={"unknownText"}
                    disabled={!showUnknown}
                    component={Input}
                    style={{
                      width: "110px",
                      height: "32px",
                    }}
                    placeHolder={"Enter Text"}
                  />
                </div>
                <Field
                  name={"unknownWeight"}
                  component={NumericTextBox}
                  spinners={false}
                  placeholder={"Enter Weight"}
                  disabled={!showUnknown}
                  style={{
                    width: "120px",
                    height: "32px",
                  }}
                />
              </div>
            </div>
            <div className="dialog-footer">
              <button
                className="common-btn bgBtnSecondary me-1"
                onClick={props.onClose}
              >
                Cancel
              </button>
              <button
                className="common-btn me-2"
                disabled={!formRenderProps.allowSubmit}
                type={"submit"}
              >
                {IS_CLIENT ? "Update" : "Save"}
              </button>
            </div>
          </fieldset>
        </FormElement>
      )}
    />
    // </div>
  );
};

export default AddEditQuestion;
