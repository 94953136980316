import React from "react";
import styles from "./CaseFiles.module.scss";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { getSubstring } from "components/layout/Tabs";

const CaseFilesHeader = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const tabs = useSelector((state) => state.caseFiles.caseFilesTabs);
  const getLocationName = () => {
    let substring = getSubstring(location.pathname, "/", "/");
    return substring.replace("%20", " ");
  };

  if (!tabs.length) return;

  return getLocationName() === "casefiles" ? (
    // <div className={`${styles["tabs-header"]}`}>
    //   <div
    //     className={`${styles["case-file"]} ${styles["case-file-opened"]} d-flex align-items-center justify-content-between`}
    //     style={{ maxWidth: "600px" }}
    //   >
    //     <div></div>
    //     <div
    //       className={`position-relative d-flex gap-2 align-items-center cursor-pointer`}
    //       onClick={() => navigate("/matters")}
    //     >
    //       {/*<div className={`${styles["case-number"]} px-2 pt-0 pb-0`}>*/}
    //       {/*    {tabs.length}*/}
    //       {/*</div>*/}
    //       <div style={{ fontWeight: 500 }}>Case Files</div>
    //     </div>
    //     <div
    //       className="me-3 cursor-pointer"
    //       onClick={() => navigate("/matters")}
    //     >
    //       <DropSvg open={true} />
    //     </div>
    //   </div>
    // </div>
    <></>
  ) : (
    <div className={`${styles["tabs-header"]}`}>
      <div
        className={`${styles["case-file"]} d-flex align-items-center justify-content-between`}
      >
        <div></div>
        <div
          className={`ps-4 position-relative d-flex gap-2 align-items-center cursor-pointer`}
          onClick={() => navigate(`/casefiles`)}
        >
          <div
            className={`${styles["case-number"]} d-flex align-items-center justify-content-center`}
          >
            {tabs.length}
          </div>
          <div className={`${styles["case-file-text"]}`}>Case Files</div>
        </div>
        <div
          className="me-4 pe-3 pb-1 cursor-pointer"
          onClick={() => navigate(`/casefiles`)}
        >
          <DropSvg open={false} />
        </div>
      </div>
    </div>
  );
};

export default CaseFilesHeader;

export const DropSvg = ({ open }) => {
  return !open ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="8"
      viewBox="0 0 14 8"
      fill="none"
    >
      <path
        d="M1 1L7 7L13 1"
        stroke="#262626"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="8"
      viewBox="0 0 14 8"
      fill="none"
    >
      <path
        d="M1 7L7 1L13 7"
        stroke="#262626"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const MenuList = ({ imageSrc, name, onClick, border, icon, index }) => (
  <div className={styles["list-item"]} role="button">
    <div
      role="button"
      onClick={onClick}
      className=" ps-3 flex align-items-center  pe-0"
      style={{
        padding: index === 1 ? "0px 0px 10px 0px" : "10px 0px 10px 0px",
      }}
    >
      {icon ? (
        <div role="button" style={{ width: "40px" }} className="mb-1">
          {icon}
        </div>
      ) : (
        <img
          role="button"
          src={imageSrc}
          alt="Icons"
          style={{ marginRight: "15px" }}
        />
      )}
      <div role="button" className={`${styles.list}`}>
        {name}
      </div>
    </div>
    {border && <div className={`${styles["border-bottom"]}`}></div>}
  </div>
);
