import * as React from "react";
import { Button } from "@progress/kendo-react-buttons";
import Currencylist from "./currencylist";
import styles from "../Settings.module.scss"
import { getCurrency } from "actions/currency.action";
import * as api from "../../../api/index"
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import EditCurrency from "./Editcurrency";

const Currency = (props) => {
  const [addnewform, setaddnewform] = React.useState(false);
  const closeform = () => {
    setaddnewform(false);
  };
  const dispatch = useDispatch();
  React.useEffect(() => {
    if (props.onClose === false) {
      closeform();
    }
  }, [props.onClose]);

  const handleSubmit = async (e) => {
    setaddnewform(false);
    try {
      await api.addnewCurrency(e);
      toast.success('Currency Added')
      dispatch(getCurrency())
    } catch (error) {
      toast.error('Error Occured')
    }
  }

  return (
    <React.Fragment>
      <>
        <div className="row">
          <div className="col-lg-10 col-md-8"></div>
          <Button
            className={`common-btn col-lg-1 col-md-4 p-2 mt-5 ${styles[`button`]}`}
            onClick={() => {
              setaddnewform(true);
              props.open();
            }}
          >
            Add <br></br>
            Currency
          </Button>
        </div>
        <div className={`row mx-auto mt-3 w-50`}>
          <Currencylist />
        </div>
      </>
      {addnewform &&
        <EditCurrency
          cancelEdit={closeform}
          onSubmit={handleSubmit}
          type='addNew'
        />
      }
    </React.Fragment>
  );
};

export default Currency;
