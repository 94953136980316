import {
  START_LOADING,
  STOP_LOADING,
  ERROR,
  SUCCESS,
  GET_CURRENCY_LIST,
  UPDATE_CURRENCY,
  ADDEDNEWCURRENCY,
  RESET,
  GET_ACTIVE_CURRENCY,
  CHANGE_HEADER_TAB_NAME,
} from "../types/index.js";

const HeaderName = (
  state = {
    IsError: false,
    isSuccess: false,
    isLoading: undefined,
    headerName: "Dashboard",
  },
  action
) => {
  switch (action.type) {
    case START_LOADING:
      return { ...state, isLoading: true };
    case STOP_LOADING:
      return { ...state, isLoading: false };
    case CHANGE_HEADER_TAB_NAME:
      state.headerName = action.payload;
      return { ...state };
    case ERROR:
      return { ...state, IsError: true, isSuccess: false };
    case SUCCESS:
      return { ...state, IsError: false, isSuccess: true };
    default:
      return state;
  }
};
export default HeaderName;
