import {
  GET_COMPANY,
  UPDATE_COMPANY,
  COMPLETE_COMPANY,
  NO_CONTENT_COMPANY,
} from "../types/index";
import * as api from "../api/index.js";
import { handleApiError } from "Helper";
import { toast } from "react-toastify";

export const getCompany = () => async (dispatch) => {
  try {
    const datas = await api.getCompany();
    if (datas.status === 204) {
      dispatch({ type: NO_CONTENT_COMPANY });
    } else {
      dispatch({ type: GET_COMPANY, payload: datas.data.data });
    }
  } catch (error) { handleApiError(error) }
};

export const updateCompany = (id, data) => async (dispatch) => {
  try {
    const datas = await api.updateCompany(id, data);
    dispatch({ type:GET_COMPANY, payload: datas.data.data });
    toast.success('Company Updated')
  } catch (error) {handleApiError(error)}
};

export const createCompany = (id, code, data) => async (dispatch) => {
  try {
    const datas = await api.createCompany(id, code, data);
  } catch (error) {handleApiError(error)}
};

export const completeCompany = (id, data) => async (dispatch) => {
  try {
    const datas = await api.completeCompany(id, data);
    dispatch({ type: COMPLETE_COMPANY, payload: datas.data.data });
  } catch (error) {handleApiError(error)}
};
