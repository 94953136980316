import { BadgeComponent, BarComponent, GridComponent, Heading, VatContainer, VatReturnAmountComponent } from "components/VatReturn";
import React from "react";
import { useEffect, useState } from "react";
import styles from "../../components/VatReturn/VatReturn.module.scss"


const salesPurchasesExcludingVAT = [
    {
        box: 6,
        details: 'Total value of sales and all other outputs excluding any VAT',
        amount: 463.67,
    }, {
        box: 7,
        details: 'Total value of purchase and all other outputs excluding any VAT',
        amount: 467.00,
    }
]


const ECSuppliesPurchasesExcludingVAT = [
    {
        box: 8,
        details: 'Total value of supplies of goods and related cost ( excluding VAT) from Northern Ireland to EU Member States',
        amount: 463.67,
    }, {
        box: 9,
        details: 'Total value of acquisitions of goods and related cost ( excluding VAT) from EU Member States to Northern Ireland',
        amount: 467.00,
    }
]

const calculationTempData = [
    {
        box: 1,
        details: 'VAT due in the period on sales and other outputs',
        amount: 468.67,
    }, {
        box: 2,
        details: 'VAT due in the period on acquisitions of goods made in Northern Ireland from EU Member States',
        amount: 468.67,
    }, {
        box: 3,
        details: 'Total VAT due (the sum of boxes 1 and 2)',
        amount: 468.67,
    },
    {
        box: 4,
        details: 'VAT reclaimed in the period on purchase and other inputs ( including acquisitions in Northern Ireland from EU Member States)',
        amount: 468.67,
    }, {
        box: 5,
        details: 'Total VAT due (the sum of boxes 1 and 2)',
        amount: 468.67,
    },
]

const VatReturnTab = (props) => {
    const [calculationsData, setCalculationData] = useState(calculationTempData)
    const [salesExcludingVat, setSalesExcludingVat] = useState(salesPurchasesExcludingVAT)
    const [suppliesExcludingVat, setSuppliesExcludingVat] = useState(ECSuppliesPurchasesExcludingVAT)

    const updateVatInformation = () => {
        let item = props.data
        setTimeout(() => {
            setCalculationData(prevData => {
                return [{
                    ...prevData[0], amount: item.vatDueSales
                }, {
                    ...prevData[1], amount: item.vatDueAcquisitions
                }, {
                    ...prevData[2], amount: item.totalVatDue
                }, {
                    ...prevData[3], amount: item.vatReclaimedCurrPeriod
                }, {
                    ...prevData[4], amount: item.netVatDue
                }]
            })
            setSalesExcludingVat(prevData => {
                return [{
                    ...prevData[0], amount: item.totalValueSalesExVAT
                }, {
                    ...prevData[1], amount: item.totalValuePurchasesExVAT
                }]
            })
            setSuppliesExcludingVat(prevData => {
                return [{
                    ...prevData[0], amount: item.totalValueGoodsSuppliedExVAT
                }, {
                    ...prevData[1], amount: item.totalAcquisitionsExVAT
                }]
            })
        }, 100);
    }

    const updateData = (box, value) => {
        if (box >= 1 && box <= 5) {
            setCalculationData(prevData => {
                const data = prevData.map((item) => {
                    if (item.box === box) {
                        item.amount = value
                    }
                    return item;
                })
                return data
            })
        }
        else if (box === 6 || box === 7) {
            setSalesExcludingVat(prevData => {
                const data = prevData.map((item) => {
                    if (item.box === box) {
                        item.amount = value
                    }
                    return item;
                })
                return data
            })
        }
        else if (box === 8 || box === 9) {
            setSuppliesExcludingVat(prevData => {
                const data = prevData.map((item) => {
                    if (item.box === box) {
                        item.amount = value
                    }
                    return item;
                })
                return data
            })
        }
    }

    useEffect(() => {
        updateVatInformation()
    }, [props.data])

    return <>
        <div className={`container-fluid`}>
            <div className="row mt-2">
                <div className="col-12 d-flex">
                    <p className="fs-md me-3">Linq Legal Software Ltd</p>
                    <p className={`${styles['gray-text-color']} fs-md font-weight-400 `}>VAT Number 410905040</p>
                </div>
            </div>
            <div className="row">
                <div className="col-12 d-flex">
                    <BadgeComponent text='Due in 30 days' />
                    <p className={`${styles[`text-light-color`]} ms-3 me-3`}>|</p>
                    <p className={`${styles[`text-light-color`]} me-3`}>07 Sep 2023</p>
                    <p className={`${styles[`text-light-color`]} me-3`}>|</p>
                    <p className={`${styles[`text-light-color`]} me-3`}>Cash</p>
                    <p className={`${styles[`text-light-color`]} me-3`}>|</p>
                    <p className={`${styles[`text-light-color`]} me-3`}>Quarterly</p>
                    <p className={`${styles[`text-light-color`]} me-3`}>|</p>
                    <BadgeComponent text='Late claims included' />
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <BarComponent />
                </div>
            </div>
            <div className="row mt-3">
                <p className="fs-md me-3">VAT Calculations</p>
            </div>
            {calculationsData?.map((item, index) => {
                return <VatReturnAmountComponent
                    key={index}
                    box={item.box}
                    updateData={(box, value) => { updateData(box, value) }}
                    details={item.details}
                    amount={item.amount}
                />
            })}
            <div className="row mt-1">
                <p className="fs-md me-3">Sales and Purchases Excluding VAT</p>
            </div>
            {salesExcludingVat?.map((item, index) => {
                return <VatReturnAmountComponent
                    box={item.box}
                    key={index}
                    details={item.details}
                    updateData={(box, value) => { updateData(box, value) }}
                    amount={item.amount}
                />
            })}
            <div className="row mt-1">
                <p className="fs-md me-3">EC Supplies and Purchases Excluding VAT</p>
            </div>
            {suppliesExcludingVat?.map((item, index) => {
                return <VatReturnAmountComponent
                    box={item.box}
                    key={index}
                    details={item.details}
                    updateData={(box, value) => { updateData(box, value) }}
                    amount={item.amount}
                />
            })}
        </div>
    </>
}

export default VatReturnTab;