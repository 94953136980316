import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import "@progress/kendo-react-intl";
import "@progress/kendo-react-dropdowns";
import "react-router-dom";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Label } from "@progress/kendo-react-labels";
import { Input } from "@progress/kendo-react-inputs";
import { RadioGroup, Checkbox } from "@progress/kendo-react-inputs";
import {
  addNominal,
  clearViewNominals,
  updateNominal,
} from "../../actions/nominals.action";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { Button } from "@progress/kendo-react-buttons";
import LoadingScreen from "components/common/Loading";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { PageTabItemTitle } from "components/tabs/TabItem/PageTabItemTitle";
import ConfirmationDialog from "components/common/ConfirmationDialog";
import { toast } from "react-toastify";
import Branch from "components/common/Branch";
import { getBranchesnames } from "api";

const data = [
  {
    label: "Balance Sheet",
    value: "BalanceSheet",
  },
  {
    label: "Profit & Loss",
    value: "ProfitAndLoss",
  },
];
const BalanceSheet = [
  {
    label: "Office Bank",
    value: "BalanceSheet_OfficeBank",
  },
  {
    label: "Client Bank",
    value: "BalanceSheet_ClientBank",
  },
  {
    label: "DDA Bank",
    value: "BalanceSheet_DDABank",
  },
  {
    label: "Other",
    value: "BalanceSheet_Other",
  },
];
const ProfitAndLoss = [
  {
    label: "Income",
    value: "ProfitAndLoss_Income",
  },
  {
    label: "Expense",
    value: "ProfitAndLoss_Expense",
  },
  {
    label: "Other",
    value: "ProfitAndLoss_Other",
  },
];

const AddNominal = (props) => {
  const dispatch = useDispatch();
  const [vatsdata, setvatsData] = React.useState();
  const [selected, setSelected] = React.useState(0);
  const [branch, setBranch] = useState(null);
  const [tabs] = useState(["Details"]);
  const [confirmationDialog, setConfirmationDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [currencyData, setCurrencyData] = React.useState();
  const [error, setError] = useState(false);
  const [nominalType, setNominalType] = useState(null);
  const [nominalSubType, setNominalSubType] = useState(null);
  const [archived, setArchivedValue] = useState(false);
  const [nominalRef, setNominalRef] = useState();
  const [nominalId, setNominalId] = useState();
  const [nominalName, setNominalName] = useState();
  const [view, setView] = useState(props.view === "Add" ? "Add" : "View");
  const [vatCodeId, setVatCodeId] = useState();
  const [currencyCode, setCurrencyCode] = useState();
  const [controlValue, setControlValue] = useState(false);
  const { viewNominals } = useSelector((state) => state.nominalList);
  const [branchList, setBranchList] = useState([]);
  const fetchBranchesNames = async () => {
    try {
      const { data } = await getBranchesnames();
      const result = data.data.map((branch) => {
        branch.representation = `${branch.branchCode} - ${branch.branchName}`;
        return branch;
      });
      setBranchList(result);
    } catch (error) {}
  };

  const submitNominal = (e) => {
    e.preventDefault();
    // if (vatCodeId?.vatCodeId === undefined) {
    //   setError(true);
    //   return;
    // }
    // if (currencyCode?.currencyCode === undefined) {
    //   setError(true);
    //   return;
    // }

    if (!nominalName || !nominalRef || nominalType) {
      setError(true);
      return;
    }

    if (
      nominalType === "BalanceSheet" &&
      (nominalSubType === "BalanceSheet_OfficeBank" ||
        nominalSubType === "BalanceSheet_ClientBank") &&
      !nominalName.toLowerCase().includes("client")
    ) {
      setConfirmationDialog(true);
      return;
    }
    nominalSubmitter();
  };

  const nominalSubmitter = () => {
    const payload = {
      nominalRef: nominalRef,
      nominalName: nominalName,
      vatCodeId: vatCodeId?.vatCodeId,
      currencyCode: currencyCode?.currencyCode,
      type: nominalType,
      branchId: branch.branchId,
      subType: nominalSubType,
      control: controlValue,
      archived: archived,
    };
    if (view === "Add") {
      dispatch(addNominal(payload));
    } else {
      dispatch(updateNominal(nominalId, payload));
    }
    newNominalDialog();
  };

  const newNominalDialog = async (e) => {
    reset();
    dispatch(clearViewNominals());
    props.onClose();
    setTimeout(async () => {
      await props.updateNominals();
    }, 3000);
  };

  const showError = () =>
    toast.error("Cant edit this field as this nominal has postings.");

  const changeNominalType = (e) => {
    if (view === "View") {
      return;
    }
    if (viewNominals?.data?.havePostings) return showError();
    setNominalType(e.value);
  };

  const changeNominalSubType = (e) => {
    if (view === "View") {
      return;
    }
    if (viewNominals?.data?.havePostings) return showError();
    setNominalSubType(e.value);
  };

  const changeArchivedValue = (e) => {
    if (view === "View") {
      return;
    }
    setArchivedValue(!archived);
  };

  const onChangeBranch = async (e) => {
    if (view === "View") {
      return;
    }
    try {
      setBranch(e);
    } catch (error) {}
  };

  const activevatsdata = useSelector((state) => {
    let list = state.vatcodes.activevats.data.map((item) => {
      const templist = item;
      templist.representation =
        item.vatCodeId + "  -  " + item.details + "  -  " + item.rate + "%";
      return templist;
    });
    return list;
  });

  const reset = () => {
    setNominalRef(null);
    setNominalName(null);
    setNominalType(null);
    setVatCodeId(null);
    setCurrencyCode(null);
    setArchivedValue(null);
    setNominalSubType(null);
  };

  const vatCodeReturner = (id) => {
    let index = {};
    activevatsdata.map((item) => {
      if (item.vatCodeId === id) {
        index = item;
      }
    });
    return index;
  };

  const currencyCodeReturner = (id) => {
    let index = {};
    activeCurrencyData.map((item) => {
      if (item.currencyCode === id) {
        index = item;
      }
    });
    return index;
  };

  const branchCodeReturner = (id) => {
    let index = {};
    branchList.map((item) => {
      if (item.branchId === id) {
        index = item;
      }
    });
    return index;
  };

  useEffect(() => {
    if (
      nominalType === "BalanceSheet" &&
      nominalSubType === "BalanceSheet_Other"
    ) {
      setControlValue(true);
    } else if (controlValue) {
      setControlValue(false);
    }
  }, [nominalType, nominalSubType]);

  const activeCurrencyData = useSelector((state) => {
    let list =
      state.currency.currencyList &&
      state.currency.currencyList.data.map((item) => {
        const templist = item;
        templist.representation =
          item.currencyCode + "  -  " + item.name + "  -  " + item.symbol;
        return templist;
      });
    return list;
  });

  useEffect(() => {
    fetchBranchesNames();
    setvatsData(activevatsdata?.slice());
  }, []);

  useEffect(() => {
    setCurrencyData(activeCurrencyData?.slice());
  }, []);

  useEffect(() => {
    if (viewNominals && viewNominals.data && view !== "Add") {
      setNominalRef(viewNominals.data.nominalRef);
      setNominalName(viewNominals.data.nominalName);
      setNominalId(viewNominals.data.nominalId);
      setNominalType(viewNominals.data.type);
      setVatCodeId(vatCodeReturner(viewNominals.data.vatCodeId));
      setCurrencyCode(currencyCodeReturner(viewNominals.data.currencyCode));
      setArchivedValue(viewNominals.data.archived);
      setNominalSubType(viewNominals.data.subType);
      setBranch(branchCodeReturner(viewNominals.data.branchId));
      setIsLoading(false);
    }
  }, [viewNominals, branchList]);

  const DetailsTab = () => (
    <div className="d-flex w-100" style={{ gap: "20%" }}>
      <div>
        <div className="d-flex flex-column 4">
          <Label className="mb-1">Nominal Ref&nbsp;</Label>
          <Input
            className="col-6"
            readOnly={view === "View"}
            style={{
              minWidth: "300px",
              border: error && !nominalRef ? "1px solid red" : "",
            }}
            placeholder="Enter Nominal Ref"
            value={nominalRef}
            onChange={(e) => setNominalRef(e.value)}
          />
        </div>
        <div className="d-flex flex-column mt-3">
          <Label className="mb-1">Nominal Name&nbsp;</Label>
          <Input
            className="col-6"
            readOnly={view === "View"}
            style={{
              minWidth: "300px",
              border: error && !nominalName ? "1px solid red" : "",
            }}
            placeholder="Enter Nominal Name"
            value={nominalName}
            onChange={(e) => setNominalName(e.value)}
          />

          <div className="mt-3">
            <Branch
              value={branch}
              onChange={onChangeBranch}
              controlled={true}
              name="branchId"
              component={"DefaultNominals"}
              width="300px"
              labelClass="mb-1"
            />
          </div>
          {vatsdata && (
            <div className="d-flex flex-column mt-3">
              <Label className="mb-1">VAT Code&nbsp;</Label>
              <ComboBox
                className="col-6"
                data={vatsdata}
                textField={"representation"}
                filterable={true}
                // onFilterChange={filterChange}
                readOnly={view === "View"}
                style={{ minWidth: "300px" }}
                iconClassName="wa"
                placeholder={"Search VAT Code"}
                value={vatCodeId}
                onChange={(e) => {
                  if (view === "View") {
                    return;
                  }
                  setVatCodeId(e.value);
                }}
              />
            </div>
          )}
          {currencyData && (
            <div className="d-flex flex-column mt-3">
              <Label className="mb-1">Currency Code&nbsp;</Label>
              <ComboBox
                className="col-6"
                data={currencyData}
                iconClassName="wa"
                textField={"representation"}
                filterable={true}
                style={{ minWidth: "300px" }}
                readOnly={view === "View"}
                placeholder={"Search Currency Code"}
                value={currencyCode}
                onChange={(e) => {
                  if (view === "View") {
                    return;
                  }
                  setCurrencyCode(e.value);
                }}
              />
            </div>
          )}
          <div style={{ width: "100px" }} className="mt-3 checkbox-label">
            <Checkbox
              defaultChecked={false}
              readOnly={view === "View"}
              value={archived}
              onClick={changeArchivedValue}
              label={"Archived"}
            />
          </div>
        </div>
      </div>
      <div>
        <div className="mt-4">
          <div className="d-flex radio gap-5 col-3 checkbox-label">
            <Checkbox
              defaultChecked={false}
              value={controlValue}
              readOnly={view === "View"}
              onClick={(e) => {
                if (view === "View") {
                  return;
                }
                if (
                  nominalType === "BalanceSheet" &&
                  nominalSubType === "BalanceSheet_Other"
                ) {
                  return;
                }
                if (viewNominals?.data?.havePostings) return showError();
                setControlValue(e.value);
                setNominalType("BalanceSheet");
                setNominalSubType("BalanceSheet_Other");
              }}
              label={"Control Nominal"}
            />
          </div>
        </div>
        <div className="mt-4">
          <div className="label">
            <Label>Nominal Types:&nbsp;</Label>
          </div>
          <div className={`mt-3 ${error && !nominalType ? "error" : ""}`}>
            <RadioGroup
              data={data}
              readOnly={view === "View"}
              className="gap-2 radio"
              value={nominalType}
              onChange={changeNominalType}
            />
          </div>
        </div>
        {nominalType && nominalType === "BalanceSheet" && (
          <div className="mt-5 radio">
            <div className="label">
              <Label>Nominal Sub Types:&nbsp;</Label>
            </div>
            <div className="mt-3">
              <RadioGroup
                data={BalanceSheet}
                readOnly={view === "View"}
                value={nominalSubType}
                className="gap-2"
                onChange={changeNominalSubType}
              />
            </div>
          </div>
        )}
        {nominalType && nominalType === "ProfitAndLoss" && (
          <div className="mt-5 radio">
            <div className="label">
              <Label>Nominal Sub Types:&nbsp;</Label>
            </div>
            <div className="mt-3">
              <RadioGroup
                className="gap-2"
                readOnly={view === "View"}
                value={nominalSubType}
                data={ProfitAndLoss}
                onChange={changeNominalSubType}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );

  const newNominal =
    view !== "Add" && isLoading ? (
      <LoadingScreen />
    ) : (
      <Dialog
        onClose={newNominalDialog}
        // width={800}
        title={`${view} Nominal`}
        className="add-dialog dialog-md"
      >
        <div style={{ height: 500 }}>
          <TabStrip
            keepTabsMounted={true}
            selected={selected}
            onSelect={(e) => setSelected(e.selected)}
            className="mt-4 ps-5 pb-4 pagetab companytab w-100"
          >
            {tabs.map((item, index) => {
              return (
                <TabStripTab
                  title={
                    <PageTabItemTitle
                      className={`mb-sm-4 mb-xl-0`}
                      title={item}
                      index={tabs.indexOf(item)}
                      selected={selected}
                    />
                  }
                  key={index}
                >
                  <div className="mt-4 w-100">
                    {item === "Details" && DetailsTab()}
                  </div>
                </TabStripTab>
              );
            })}
          </TabStrip>
        </div>
        <DialogActionsBar>
          <div className="dialog-footer ">
            <Button
              className={`k-button common-btn bgBtnSecondary me-3`}
              onClick={newNominalDialog}
            >
              {view === "View" ? "Back" : "Cancel"}
            </Button>
            {view !== "View" && (
              <Button
                className="k-button common-btn me-3"
                onClick={submitNominal}
              >
                Save
              </Button>
            )}
            {view === "View" && (
              <Button
                className="common-btn me-3"
                onClick={() => setView("Edit")}
              >
                Edit
              </Button>
            )}
          </div>
        </DialogActionsBar>
        {confirmationDialog && (
          <ConfirmationDialog
            content={
              'Are you sure to save Client Bank / DDA Bank without using the word "client" in nominal name?'
            }
            onClickYes={() => {
              nominalSubmitter();
            }}
            onClickNo={() => setConfirmationDialog(false)}
            onClose={() => setConfirmationDialog(false)}
          />
        )}
      </Dialog>
    );

  return <>{newNominal}</>;
};
export default AddNominal;
