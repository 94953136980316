import * as React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import Title from "./TabTitle";
import {
  useLocation,
  useNavigate,
  useParams,
  useMatch,
} from "react-router-dom";
import { addTab, removeTab } from "actions/tabs.actions";
import { nameFunction } from "components/common/formatter";
import { Tabs } from "components/Enums/TabEnums.ts";
import { useAllowedLiscence } from "hooks/useAllowedLiscence";
import AccessRevokedModal from "components/common/AccessRevokedModal";
import ConsultantsList from "pages/Consultant/ConsultantsList";
import ConsultantLedger from "pages/Consultant/ConsultantLedger";
import PayConsultant from "pages/Consultant/PayConsultant";

const ConsultantTabs = React.memo((props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data } = useParams();
  const [tabs, setTabs] = React.useState([]);
  const location = useLocation();
  const tabsData = useSelector((state) => state.tabsData.consultantTabs);
  const { allowed } = useAllowedLiscence(Tabs.CONSULTANT);

  const findContent = (item) => {
    switch (item) {
      case "consultants":
        return "Consultants";
      case "consultantledger":
        return "Consultant Ledger";
      case "payconsultant":
        return "Pay Consultant";

      default:
        break;
    }
  };

  const urlDefiner = (e) => {
    if (params !== null) {
      let url = tabs[e]?.toLowerCase();
      if (url === undefined) {
        url = "";
      }
      // window.history.replaceState(null, '', `/nominal/${url}`)
      navigate(`/consultant/${url}`);
    }
  };

  const params = useMatch("consultant/:data");

  useEffect(() => {
    if (params !== null) {
      let data = params?.params?.data;
      if (!tabsData.includes(data?.toLowerCase())) {
        dispatch(addTab("consultant", data?.toLowerCase()));
      } else {
        setSelected(tabsData.indexOf(data?.toLowerCase()));
      }
    }
  }, [params]);

  useEffect(() => {
    if (tabsData) {
      setTabs(tabsData);
      let index = tabsData.indexOf(params?.params?.data.toLowerCase());
      setSelected(index === -1 ? 0 : index);
    }
  }, [tabsData]);

  const removeTabDispatcher = (tab) => {
    if (nameFunction(tab) === `${params?.params?.data}`) {
      let index = nameFunction(tabs[0]);
      navigate(`/consultant/${index}`);
    }
    if (tabs.length === 1) {
      navigate("/");
    }
    dispatch(removeTab("consultant", tab));
  };

  const [selected, setSelected] = React.useState(-1);

  const handleSelect = (e) => {
    urlDefiner(e.selected);
  };

  return allowed ? (
    <TabStrip
      selected={selected}
      onSelect={handleSelect}
      keepTabsMounted={true}
      className="navigationTab"
    >
      {tabs.map((item, index) => {
        return (
          item && (
            <TabStripTab
              title={
                <Title
                  content={findContent(item)}
                  onTabRemove={removeTabDispatcher}
                  selected={findContent(tabs.at(selected))}
                  count={tabs.length}
                />
              }
              key={index}
            >
              <div style={{ height: "100%" }}>
                {item === "consultants" && <ConsultantsList />}
                {item === "consultantledger" && <ConsultantLedger />}
                {item === "payconsultant" && <PayConsultant />}
              </div>
            </TabStripTab>
          )
        );
      })}
    </TabStrip>
  ) : (
    <AccessRevokedModal removeTab={() => props.closeTab()} />
  );
});

export default ConsultantTabs;
