

export default function InfoBox({ text, title, className }) {

    return <>

        {text && (
            <div className={`infoBox p-2 pt-1 ${className}`}>
                {title && (
                    <>
                        <span id="title">{title}:</span>
                        <span> </span>
                    </>
                )}
                <span>{text}</span>
            </div>
        )}
    </>
};



