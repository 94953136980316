import * as React from "react";
import { useState } from "react";
import {
  ExpansionPanel,
  ExpansionPanelContent,
} from "@progress/kendo-react-layout";
import { Reveal } from "@progress/kendo-react-animation";
import { Tile } from "components/UIComponents/Tile";
import { Label } from "@progress/kendo-react-labels";
import { Button } from "@progress/kendo-react-buttons";

const OpenMatter = ({ formData }) => {
  console.log(formData);
  const data = [
    {
      id: "Client",
      label: "Client Details",
      content: (
        <>
          <Label>Client Name: </Label>
          <p>{formData?.clientData?.clientName}</p>
        </>
      ),
      expanded: true,
    },
    {
      id: "Matter",
      label: "Matter Details",
      content: (
        <>
          <Label>Matter Ref: </Label>
          <p>{formData?.matterDetails?.matterRef}</p>
        </>
      ),
      expanded: true,
    },
    {
      id: "Conflict",
      label: "Conflict Check",
      expanded: false,
    },
    {
      id: "Risk",
      label: "Risk Assessment",
      content: (
        <>
          <Label>Status: </Label>
          <p>{formData?.riskAssessment?.status}</p>
        </>
      ),
      expanded: true,
    },

    {
      id: "Engagement",
      label: "Engagement Letter",
    },
    {
      id: "Tasks",
      label: "Client Task Details",
      expanded: true,
    },
  ];
  const [expanded, setExpanded] = useState("Client");

  return (
    <div className="">
      <Tile
        heading="Matter Opening"
        height={"calc(100vh -  205px)"}
        className="p-3 ps-4 pe-4 pt-2 pb-0 overflow-auto"
      >
        <div
          className="d-flex flex-column justify-content-between"
          style={{ height: "calc(100vh -  305px)" }}
        >
          <div>
            {data.map((item) => (
              <ExpansionPanel
                title={item.label}
                expanded={expanded === item.id}
                tabIndex={0}
                key={item.id}
                onAction={(event) => {
                  if (item.expanded) {
                    setExpanded(event.expanded ? "" : item.id);
                  }
                }}
                expandIcon={item.expanded ? undefined : () => null} // Remove icon if not expandable
              >
                <Reveal>
                  {expanded === item.id && (
                    <ExpansionPanelContent>
                      <div className="content">
                        <span className="content-text">{item.content}</span>
                      </div>
                    </ExpansionPanelContent>
                  )}
                </Reveal>
              </ExpansionPanel>
            ))}
          </div>

          <div className="d-flex justify-content-end ">
            <Button className="common-btn mx-2">Close</Button>
            <Button className="common-btn">Open Matter</Button>
          </div>
        </div>
      </Tile>
    </div>
  );
};
export default OpenMatter;
