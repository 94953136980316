import * as React from "react";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Label } from "@progress/kendo-react-labels";
import { useState, useEffect } from "react";
import Download from "../../assets/download-icon.png";
import Printer from "../../assets/printer-icon.png";
import { toast } from "react-toastify";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { Input, NumericTextBox, TextArea } from "@progress/kendo-react-inputs";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { PageTabItemTitle } from "components/tabs/TabItem/PageTabItemTitle";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { AmountFormatCell, DateFormatCell } from "components/common/formatter";
import { Button } from "@progress/kendo-react-buttons";
import { getPostingdetails, getDocument } from "api";
import LoadingScreen from "components/common/Loading";
import { Tile } from "components/UIComponents/Tile";
import documentIcon from "../../assets/fileIcons/Document.svg";
import styles from "./Posting.module.scss";
import DocumentViewer from "components/common/DocumentViewer";
import EditPosting from "./EditPosting";

const ViewPosting = (props) => {
  const [mainData, setMainData] = useState();
  const [postingInfo, setPostingInfo] = useState({});
  const [doubleEntries, setDoubleEntries] = useState([]);
  const [postingName, setPostingName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showDocument, setShowDocument] = useState(false);
  const [documentViewerData, setDocumentViewerData] = useState([]);
  const [showEditPosting, setShowEditPosting] = useState(false);

  const getPostingDetails = async () => {
    try {
      setIsLoading(true);
      const data = await getPostingdetails(props.transNo);
      setMainData(data?.data?.data);
      setDoubleEntries(data?.data?.data?.doubleEntries);
      setPostingName(data?.data?.data?.postingName);
      setPostingInfo(data?.data?.data?.postingInfo);
    } catch (error) {
      props.cancelEdit();
      toast.error("Error occured while fetching posting details.");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getPostingDetails();
  }, []);

  const showDocumentFile = async (id) => {
    const { data } = await getDocument(id);
    setDocumentViewerData([data.data]);
    setShowDocument(true);
  };

  return (
    <div>
      <Dialog
        title={"Posting Details"}
        onClose={props.cancelEdit}
        className="dialog-lg "
      >
        <div>
          {isLoading ? (
            <LoadingScreen />
          ) : (
            <div className="px-lg-5 px-4">
              <div
                className="fs-6 fw-bold d-flex align-items-center justify-content-between
            
             mt-3 border  rounded px-4 py-1
            "
                style={{
                  backgroundColor: "#F8F8F8",
                  borderColor: "#E6E6E6",
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                }}
              >
                <div>
                  <span>
                    {postingInfo.postingType} - {postingName}
                  </span>
                </div>
                <div className="d-flex">
                  <div className="border px-2 py-1 rounded me-2">
                    <img
                      className=" cursor-pointer "
                      height={16}
                      width={16}
                      src={Printer}
                      alt={"Print"}
                    />
                  </div>
                  <div className="border px-2 py-1 rounded me-2">
                    <img
                      className="cursor-pointer"
                      height={16}
                      width={16}
                      src={Download}
                      alt={"Download"}
                    />
                  </div>
                </div>
              </div>
              <div className="d-flex mt-2">
                <Tile className="mt-3 py-4 px-lg-4 px-3 me-2 w-75">
                  <div className="d-flex justify-content-between mt-2">
                    <div>
                      <Label className="mb-1 d-block">Date</Label>
                      <DatePicker
                        id="date"
                        value={new Date(postingInfo.date)}
                        className={`${styles["input-style"]} ps-2`}
                      />
                    </div>
                    <div>
                      <Label className="mb-1 d-block">Net Amount</Label>
                      <NumericTextBox
                        value={postingInfo.netAmount}
                        spinners={false}
                        format="n2"
                        placeholder="0.00"
                        className={`${styles["input-style"]} pe-3 right-align`}
                      />
                    </div>
                  </div>
                  <div className="d-flex justify-content-between mt-2">
                    <div>
                      <Label className="mb-1 d-block">Matter</Label>
                      <Input
                        value={mainData?.matterRef}
                        className={`${styles["input-style"]} ps-3`}
                      />
                    </div>
                    <div>
                      <Label className="mb-1 d-block">VAT Amount</Label>
                      <NumericTextBox
                        value={postingInfo.vatAmount}
                        spinners={false}
                        className={`${styles["input-style"]} pe-3 right-align`}
                        format="n2"
                        placeholder="0.00"
                      />
                    </div>
                  </div>
                  <div className="d-flex justify-content-between mt-2">
                    <div>
                      <Label className="d-block mb-1">Reference</Label>
                      <Input
                        value={postingInfo?.reference}
                        className={`${styles["input-style"]} ps-3`}
                      />
                    </div>
                    <div className="right-align ">
                      <Label className="d-block mb-1">VAT Code</Label>
                      <Input
                        value={`${postingInfo.vatCode} - ${postingInfo.vatRate} %`}
                        className={`${styles["input-style"]} pe-4`}
                      />
                    </div>
                  </div>
                  <div className="d-flex justify-content-between mt-2">
                    <div className="me-3">
                      <Label className="mb-1 d-block">Fee Earner</Label>
                      <Input
                        value={postingInfo?.feeEarnerRef}
                        className={`${styles["input-style"]} ps-3`}
                      />
                    </div>
                    <div>
                      <Label className="mb-1 d-block">Gross Amount</Label>
                      <NumericTextBox
                        value={postingInfo.netAmount + postingInfo.vatAmount}
                        spinners={false}
                        format="n2"
                        placeholder="0.00"
                        className={`${styles["input-style"]} pe-3 right-align`}
                      />
                    </div>
                  </div>
                  <div className="d-flex mt-2">
                    <div className="w-100">
                      <Label className="mb-1">Details</Label>
                      <div className="mw-100 w-100">
                        <Input
                          value={postingInfo?.details}
                          className="ps-3 mw-100 w-100"
                          style={{ height: "32px" }}
                        />
                      </div>
                    </div>
                  </div>
                </Tile>
                <Tile className="py-4 px-lg-4 px-3 mt-3">
                  <div className="d-flex justify-content-center align-items-start flex-column mt-2">
                    <Label className="mb-1 mt-2">Transaction Period</Label>
                    <Input
                      value={postingInfo.transactionPeriod}
                      className={`${styles["input-style"]} ps-3`}
                    />
                    <Label className="mb-1 mt-2">Transaction No</Label>
                    <Input
                      value={props.transNo}
                      className={`${styles["input-style"]} ps-3`}
                    />
                    <Label className="mb-1 mt-2">Added By</Label>
                    <Input
                      value={mainData?.addedBy}
                      className={`${styles["input-style"]} ps-3`}
                    />
                    <Label className="mb-1 mt-2">Date Added</Label>
                    <DatePicker
                      className={`${styles["input-style"]} ps-2`}
                      id="date"
                      value={
                        mainData?.dateAdded
                          ? new Date(mainData?.dateAdded)
                          : null
                      }
                    />

                    {mainData?.documents?.length > 0 && (
                      <div className="mt-2">
                        {mainData.documents.map((doc, index) => (
                          <div
                            key={index}
                            className="d-flex align-items-center my-1  cursor-pointer"
                            onClick={() => showDocumentFile(doc)}
                          >
                            <img
                              src={documentIcon}
                              alt="Document"
                              width={18}
                              height={20}
                            />
                            <p
                              style={{
                                color: "#1D3F6E",
                                textDecoration: "underline",
                                backgroundColor: "#F8F8F8",
                              }}
                              className="fs-md mb-0 ms-2"
                            >
                              Document {index + 1} Attached
                            </p>
                          </div>
                        ))}
                      </div>
                    )}

                    {showDocument && (
                      <DocumentViewer
                        onClose={() => setShowDocument(false)}
                        data={documentViewerData}
                        hideToolbar={true}
                      />
                    )}
                  </div>
                </Tile>
              </div>

              <hr />
              <Tile className="py-3 px-4 mt-3">
                <TabStrip
                  keepTabsMounted={true}
                  selected={0}
                  className="mt-2 mb-1 pagetab w-100"
                >
                  {["Double Entry"].map((item, index) => {
                    return (
                      <TabStripTab
                        key={index}
                        title={<PageTabItemTitle title={item} />}
                      >
                        {item === "Double Entry" && (
                          <div className="mt-2">
                            <Grid
                              data={doubleEntries}
                              style={{ maxHeight: "40vh", overflow: "auto" }}
                              className="grid-fs13"
                            >
                              <GridColumn
                                field="date"
                                title="Date"
                                width="120px"
                                cell={DateFormatCell}
                              />
                              <GridColumn
                                field="nominalRef"
                                title="Nominal Ref"
                                width="120px"
                              />
                              <GridColumn field="details" title="Details" />
                              <GridColumn
                                field="amount"
                                title="Amount"
                                cell={AmountFormatCell}
                                width="100px"
                                className="pe-3"
                              />
                              <GridColumn
                                className="text-end pe-4"
                                title="Rec No"
                                field="bankRecId"
                                width="80px"
                              />
                            </Grid>
                          </div>
                        )}
                      </TabStripTab>
                    );
                  })}
                </TabStrip>
              </Tile>
            </div>
          )}
        </div>
        <DialogActionsBar>
          <div className="dialog-footer w-100">
            <Button
              className="common-btn bgBtnSecondary border-0 me-3"
              onClick={() => {
                props.cancelEdit();
              }}
            >
              Close
            </Button>
            <Button
              className="common-btn border-0 me-5"
              onClick={() => {
                setShowEditPosting(true);
              }}
            >
              Edit
            </Button>
          </div>
        </DialogActionsBar>
      </Dialog>
      {showEditPosting && (
        <EditPosting
          cancelViewPosting={props.cancelEdit}
          postingInfo={postingInfo}
          doubleEntries={doubleEntries}
          postingName={postingName}
          transNo={props.transNo}
          cancelEdit={() => setShowEditPosting(false)}
        />
      )}
    </div>
  );
};

export default ViewPosting;
