import * as React from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Label } from "@progress/kendo-react-labels";

const EditForm = ({
  type,
  item,
  onSubmit,
  cancelEdit,
  fields,
  formId,
  title,
}) => {
  const ADD_NEW = type === "addNew";
  const [isSubmitted, setIsSubmitted] = React.useState(false);
  return (
    <Dialog
      title={ADD_NEW ? `Add ${title}` : `Edit ${item?.name}`}
      onClose={cancelEdit}
      width={600}
    >
      <div className="p-3 ps-4">
        <Form
          onSubmit={onSubmit}
          initialValues={item}
          render={(formRenderProps) => (
            <FormElement
              style={{
                maxWidth: 1200,
              }}
            >
              <fieldset className={"k-form-fieldset"}>
                {fields.map((field) =>
                
                  ADD_NEW &&
                  field.name === "active" ? null : (
                    <div className="mb-2" key={field.name} style={{}}>
                      {field?.component?.render?.displayName !==
                        "KendoCheckbox" && (
                        <Label className="label mt-1 mb-1 w-100">
                          {field.label}&nbsp;
                        </Label>
                      )}
                      <Field
                        style={{
                          paddingBottom: "10px",
                          marginRight:
                            field?.component?.render?.displayName ==
                            "KendoCheckbox"
                              ? "10px"
                              : undefined,
                        }}
                        name={field.name}
                        component={field.component}
                        defaultChecked={field.defaultChecked}
                        label={
                          field?.component?.render?.displayName ==
                          "KendoCheckbox"
                            ? field.label
                            : null
                        }
                      />
                      {isSubmitted &&
                        !formRenderProps.valueGetter(field.name) && (
                          <div className="fs-sm my-1 text-danger">
                            <i>{field.validationMessage}</i>
                          </div>
                        )}
                    </div>
                  )
                )}
              </fieldset>
              <button
                style={{ visibility: "hidden" }}
                id={formId}
                type={"submit"}
              ></button>
            </FormElement>
          )}
        />
      </div>
      <div className="dialog-footer">
        <button className="bgBtnSecondary common-btn me-2" onClick={cancelEdit}>
          Cancel
        </button>
        <button
          className="common-btn me-3"
          onClick={() => {
            document.getElementById(formId).click();
            setIsSubmitted(true);
          }}
          type={"submit"}
        >
          {ADD_NEW ? "Save" : "Update"}
        </button>
      </div>
    </Dialog>
  );
};

export default EditForm;
