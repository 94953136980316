import {
  START_LOADING,
  STOP_LOADING,
  ERROR,
  SUCCESS,
  GET_TRANSACTIONPERIODS,
  GET_CURRENTTRANSACTIONPERIODS,
} from "../types/index.js";

const TransactionPeriodReducer = (
  state = {
    IsError: false,
    isSuccess: false,
    isLoading: undefined,
    mattersList: [],
    transactionList:[]
  },
  action
) => {
  switch (action.type) {
    case START_LOADING:
      return { ...state, isLoading: true };
    case STOP_LOADING:
      return { ...state, isLoading: false };
    case GET_TRANSACTIONPERIODS:
      return {
        ...state,
        transactionList: action.payload,
      };
    case GET_CURRENTTRANSACTIONPERIODS:
      return {
        ...state,
        currentPeriods: action.payload,
      };
    case ERROR:
      return { ...state, IsError: true, isSuccess: false };
    case SUCCESS:
      return { ...state, IsError: false, isSuccess: true };
    default:
      return state;
  }
};
export default TransactionPeriodReducer;
