import React, { useEffect, useState } from "react";
import { process } from "@progress/kendo-data-query";
import { Tile } from "components/UIComponents/Tile";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import styles from "./Notes.module.scss";
import { getClientNotes, getMatterNotes } from "api";
import { DateFormatCell } from "components/common/formatter";
import { Button } from "@progress/kendo-react-buttons";
import EditNotes from "./NotesModal";

export const NotesListTile = ({ matterId, clientId }) => {
  const [list, setLists] = React.useState({ data: [], total: 50 });
  const [showNotes, setShowNotes] = useState(false);
  const [noteItem, setNoteItem] = useState(null);

  const getMatterNotesData = async () => {
    try {
      const data = await getMatterNotes(matterId);
      setLists({ data: data?.data?.data, total: data?.data?.total });
    } catch (error) {}
  };

  const getClientNotesData = async () => {
    try {
      const data = await getClientNotes(clientId);
      setLists({ data: data?.data?.data, total: data?.data?.total });
    } catch (error) {}
  };

  const [dataState, setDataState] = React.useState({
    take: 100,
    skip: 0,
    sort: [
      {
        field: "documentDate",
        dir: "asc",
      },
    ],
  });

  const ViewNotes = (e) => {
    return (
      <td>
        <Button
          className="button-small"
          onClick={() => {
            setNoteItem(e.dataItem);
            setShowNotes(true);
          }}
        >
          View
        </Button>
      </td>
    );
  };

  const dataStateChange = (e) => {
    if (
      e.dataState.sort !== undefined &&
      e.dataState.skip !== 0 &&
      dataState.sort !== e.dataState.sort
    ) {
      setDataState({ ...e.dataState, skip: 0 });
    } else if (e.dataState?.sort?.[0] === undefined) {
      setDataState({
        ...e.dataState,
        sort: [
          {
            field: dataState?.sort?.[0].field,
            dir: "asc",
          },
        ],
      });
    } else {
      setDataState(e.dataState);
    }
  };

  useEffect(() => {
    if (clientId) {
      getClientNotesData();
    } else if (matterId) {
      getMatterNotesData();
    }
  }, []);

  return (
    <div className="container mt-4">
      <Tile
        heading="Notes"
        className="mb-2 px-4 py-2 overflow-auto"
        height={"580px"}
        headingItems={
          <div>
            <Button
              className="button-small bgBtnSecondary fw-bold me-1"
              onClick={() => setShowNotes(true)}
            >
              Add Note
            </Button>
          </div>
        }
      >
        <Grid
          {...dataState}
          data={process(list.data, dataState)}
          onDataStateChange={dataStateChange}
          className={`fs-md pb-1 ${styles.gridheight}`}
          sortable={true}
          resizable={true}
        >
          <GridColumn
            field="date"
            cell={DateFormatCell}
            title="Date"
            width="120px"
            className="gridBoldField ps-3"
            headerClassName="ps-3"
          />
          <GridColumn field="details" title="Note" />
          <GridColumn field="addedByRef" title="Added By" width="120px" />
          <GridColumn field="" title="  " width="100px" cell={ViewNotes} />
        </Grid>
      </Tile>

      {showNotes && (
        <EditNotes
          item={noteItem}
          cancelEdit={() => {
            setNoteItem(null);
            setShowNotes(false);
          }}
          onUpdateData={() => {
            if (clientId) {
              getClientNotesData();
            } else if (matterId) {
              getMatterNotesData();
            }
          }}
          clientId={clientId}
          matterId={matterId}
        />
      )}
    </div>
  );
};
