import { CrossIcon } from "components/tabs/TabTitle";
import * as React from "react";
import { ImCross } from "react-icons/im";
export const MyCommandCell = (props) => {
  const { dataItem } = props;
  const inEdit = dataItem[props.editField];
  const isNewItem = dataItem.ProductID === undefined;
  return inEdit ? (
    <td className="k-command-cell">
      <div
        onClick={() =>
          isNewItem ? props.discard(dataItem) : props.cancel(dataItem)
        }
        className="cursor-pointer"
      >
        <CrossIcon />
      </div>
    </td>
  ) : (
    <td className="k-command-cell">
      <div onClick={() => props.remove(dataItem)} className="cursor-pointer">
        <CrossIcon />
      </div>
    </td>
  );
};
