import React, { useState } from 'react';
import { Input } from "@progress/kendo-react-inputs";


const ValidationCell = (props) => {
    const inputRef = React.useRef(null);
    const [shouldFocus, setShouldFocus] = useState(true);
  
  
  
    React.useEffect(() => {
      if (props.Itemfield === "correspondenceFirstName") {
        setShouldFocus(true);
      } else {
        setShouldFocus(false);
      }
    }, [props.Itemfield]);
  
    React.useEffect(() => {
      if (props.dataItem.inEdit && shouldFocus && props.Itemfield === "correspondenceFirstName") {
        inputRef.current.focus();
      }
    }, [shouldFocus]);
  
  
  
  
    const handleOnChange = (e) => {
      props.itemChange({
        
          field: "correspondenceFirstName",
          value: e.target.value,
          dataItem: props.dataItem,
        
      });
    };
    return (
      <>
      {props.dataItem.inEdit ? (
      <td onFocus={() => setShouldFocus(true)} onBlur={() => setShouldFocus(false)}>
        
        <Input
        style={{
          border: props.isError ? "1px solid red" : "1px solid #E0E0E0",
          marginTop: props.isError ? "1.1rem" : "0px",
          height: props.isError ? "38px" : "40px",
        }}
          required
          ref={inputRef}
          value={props.dataItem[props.field]}
          onChange={handleOnChange}
  
        />
       {props.isError ? (
              <i style={{ color: "red", fontSize: "12px" }}>
                { "Name is Required" }
              </i>
        ) : null}
      </td>
      ) : (
        <td className="fs-7 fw-bold px-4">{props.dataItem[props.field]}</td>
      )}
      </>
    );
  };

export default ValidationCell;
