import {
  START_LOADING,
  STOP_LOADING,
  ERROR,
  SUCCESS,
  GET_COMPANY,
  UPDATE_COMPANY,
  COMPLETE_COMPANY,
  NO_CONTENT_COMPANY,
} from "../types/index.js";

const companyReducer = (
  state = {
    IsError: false,
    isSuccess: false,
    isLoading: undefined,
    company: {
      companyCode: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      displayName: "",
      addressLine1: "",
      addressLine2: "",
      town: "",
      state: "",
      postcode: "",
      country: "",
      phone1: "",
      phone2: "",
      fax: "",
      email1: "",
      email2: "",
      website: "",
      monthEnd: 0,
      vatScheme: '',
      vatPeriod: '',
      yearEnd: 0,
      baseCurrency: "",
      cashAccounting: true,
      workingDays: 0,
      id: 0,
      locale: "",
      dateAdded: "2022-08-14T16:34:54.567Z",
      dateUpdated: "2022-08-14T16:34:54.567Z",
      workingDays: ''
    },
  },
  action
) => {
  switch (action.type) {
    case START_LOADING:
      return { ...state, isLoading: true };
    case STOP_LOADING:
      return { ...state, isLoading: false };
    case GET_COMPANY:
      return {
        ...state,
        company: action.payload,
      };
    case NO_CONTENT_COMPANY:
      return {
        ...state,
        noContent: true,
      };
    case UPDATE_COMPANY:
      return {
        ...state,
        company: action.payload,
      };
    case COMPLETE_COMPANY:
      return {
        ...state,
        setupstatus: action.payload.setupCompleted,
      };
    case ERROR:
      return { ...state, IsError: true, isSuccess: false };
    case SUCCESS:
      return { ...state, IsError: false, isSuccess: true };
    default:
      return state;
  }
};
export default companyReducer;
