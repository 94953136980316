import { LOGGED_IN, LOGGED_OUT } from "../types/index";

export const loggedIn = () => async (dispatch) => {
  try {
    dispatch({ type: LOGGED_IN });
  } catch (error) {}
};

export const loggedOut = () => async (dispatch) => {
  try {
    dispatch({ type: LOGGED_OUT });
  } catch (error) {}
};
