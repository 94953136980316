import * as React from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import { useSelector } from "react-redux";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { AmountFormatCell, DateFormatCell } from "components/common/formatter";
import { useState, useEffect } from "react";
import { NameTextCell } from "components/common/GridComponents";
import { Checkbox } from "@progress/kendo-react-inputs";

const MattersDisbRecords = (props) => {
  const [dataAvailable, setDataAvailable] = useState(false);
  const [data, setData] = React.useState([]);
  const AmountNameHeader = (props) => {
    return (
      <div style={{ textAlign: "center", marginBottom: "8px" }}>
        {props.title}
      </div>
    );
  };
  const matters = useSelector((state) => {
    if (state.billsMatterData.matterDisbRecords !== undefined) {
      let dataList = [];
      let list = state.billsMatterData.matterDisbRecords.map((item) => {
        dataList.push(
          Object.assign(
            {
              inEdit: true,
              billVat: 0,
              add: false,
            },
            item
          )
        );
      });
      return dataList;
    } else {
      return ["LIST_EMPTY"];
    }
  });

  const handleSubmit = () => {
    let dataToPost = [];
    data.map((item) => {
      if (item.add === true) {
        dataToPost.push(item);
      }
    });
    props.changeMatterDisbList(dataToPost);
    props.cancelEdit();
  };
  const itemChange = (e) => {
    let newData = data.map((item) => {
      if (item.postingId === e.dataItem.postingId) {
        item.add = e.value;
      }
      return item;
    });
    setData(newData);
  };

  useEffect(() => {
    setTimeout(() => {
      if (
        matters[0] !== null &&
        matters[0] !== "LIST_EMPTY" &&
        matters[0] !== undefined &&
        dataAvailable === false
      ) {
        setData(matters);
        setDataAvailable(true);
      } else if (matters[0] === "LIST_EMPTY") {
        setData([]);
        setDataAvailable(false);
      }
    }, 0);
  }, [matters]);

  const SelectionHeaderCell = (props) => {
    return (
      <div className="gap-2 mb-2 d-flex">
        <span>{props.title}</span>
        <Checkbox
          style={{
            width: "18px",
            height: "18px",
            boxShadow: "0px 0px 16px rgba(0, 0, 0, 0.1)",
          }}
          onChange={(e) => {
            if (e.value) {
              setData((prevData) =>
                prevData.map((item) => ({ ...item, add: true }))
              );
            } else {
              setData((prevData) =>
                prevData.map((item) => ({ ...item, add: false }))
              );
            }
          }}
          name="isCounselFee"
          value={data.filter((item) => !item.add).length === 0}
        />
      </div>
    );
  };

  return (
    <Dialog
      title={`Disbursement Records for Matter`}
      onClose={props.cancelEdit}
      width={"1100px"}
    >
      <div className="d-flex flex-column pt-3 pb-4" style={{ padding: "40px" }}>
        <div style={{ marginTop: "15px" }}>
          <Grid
            data={data}
            dataItemKey={"postingId"}
            onItemChange={itemChange}
            editField="inEdit"
            style={{ maxHeight: "500px" }}
          >
            <Column
              field="date"
              title="Date"
              editable={false}
              cell={DateFormatCell}
            />
            <Column
              field="details"
              title="Details"
              editable={false}
              cell={NameTextCell}
            />
            <Column field="feeEarnerRef" title="Fee Earner" editable={false} />
            <Column
              field="netAmount"
              title="Value"
              editable={false}
              cell={AmountFormatCell}
              headerCell={AmountNameHeader}
            />
            <Column
              field="netAmount"
              title="Net"
              editable={false}
              cell={AmountFormatCell}
              headerCell={AmountNameHeader}
            />
            <Column
              field="vatAmount"
              title="Bill Vat"
              editable={false}
              cell={AmountFormatCell}
              headerCell={AmountNameHeader}
            />
            <Column
              field="add"
              title="Select"
              className="d-flex align-items-center justify-center mt-2 ms-3"
              editor="boolean"
              width={"90px"}
              headerCell={SelectionHeaderCell}
            />
          </Grid>
        </div>
      </div>
      <div className="dialog-footer">
        <button
          className="common-btn bgBtnSecondary border-0 me-3"
          onClick={() => {
            props.cancelEdit();
          }}
        >
          Cancel
        </button>
        <button
          type="submit"
          className="k-button common-btn me-4"
          onClick={handleSubmit}
        >
          Select
        </button>
      </div>
    </Dialog>
  );
};

export default MattersDisbRecords;
