import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCompany } from "../../actions/company.action";
import {
    getUserClaims,
    getCurrentUser,
} from "../../actions/currentuser.action";
import { getactiveVats } from "../../actions/vat.action";
import ModalLoading from "./LoadingModal";
import { Overlay } from "components/Overlay";
import { getCurrentTransactionPeriod } from "api";
import { getfeeearner } from "actions/postings.action";
import { getAPIInstance } from "api/axios.config";

const FreshLogin = () => {
    const dispatch = useDispatch();

    const initialLoading = useSelector((state) => state?.currentuser?.loading);

    const getTransactionPeriod = async () => {
        try {
            const data = await getCurrentTransactionPeriod();
            sessionStorage.setItem("sessionPeriod", JSON.stringify(data?.data?.data));
        } catch (error) { }
    };

    const baseURLHandler = async () => {

        try {

            await getAPIInstance();
            dispatch(getUserClaims());
            dispatch(getCompany());
            dispatch(getCurrentUser());
            getTransactionPeriod();
            dispatch(getfeeearner());
            dispatch(getactiveVats());
        }
        catch (error) { }
    };

    // List of API Calls done when the user logs in :

    useEffect(() => {
        baseURLHandler();
    }, []);

    return initialLoading ? (
        <>
            <ModalLoading />
            <Overlay />
        </>
    ) : (
        <></>
    );
};

export default FreshLogin;
