import * as React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import Title from "./TabTitle";
import ChitRequest from "../../pages/ChitRequest/ChitRequest";
import AddTime from "../../pages/Time/AddTime";
import TimeRecorded from "../../components/Time/TimeRecorded";
import {
  useLocation,
  useNavigate,
  useParams,
  useMatch,
} from "react-router-dom";
import { addTab, removeTab } from "actions/tabs.actions";
import { getRecordedTime } from "actions/time.action.js";
import { nameFunction } from "components/common/formatter";
import MyMatters from "pages/MyMatters/MyMatters";
import MyMattersPageComponent from "components/Matters/MattersGridComponent";
import { Tabs } from "components/Enums/TabEnums.ts";
import { useAllowedLiscence } from "hooks/useAllowedLiscence";
import AccessRevokedModal from "components/common/AccessRevokedModal";

const FeeEarnerTabs = React.memo((props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data } = useParams();
  const [tabs, setTabs] = React.useState([]);
  const location = useLocation();
  const tabsData = useSelector((state) => state.tabsData.feeearnerTabs);
  const { allowed } = useAllowedLiscence(Tabs.FEE_EARNER);
  const user = useSelector((state) => state.currentuser.userlist);

  const findContent = (item) => {
    switch (item) {
      case "chitrequest":
        return "Chit Request";
      case "timerecording":
        return "Time Recording";
      case "timerecorded":
        return "Time Recorded";
      case "mymatters":
        return "My Matters";
      default:
        break;
    }
  };

  const urlDefiner = (e) => {
    if (params !== null) {
      let url = tabs[e]?.toLowerCase();
      if (url === undefined) {
        url = "";
      }
      navigate(`/feeearner/${url}`);
    }
  };

  const params = useMatch("feeearner/:data");

  useEffect(() => {
    if (params !== null) {
      let data = params?.params?.data;
      if (!tabs.includes(data?.toLowerCase())) {
        dispatch(addTab("feeearner", data?.toLowerCase()));
      } else {
        setSelected(tabs.indexOf(data?.toLowerCase()));
      }
    }
  }, [params]);

  useEffect(() => {
    if (tabsData) {
      setTabs(tabsData);
      let index = tabsData.indexOf(params?.params?.data.toLowerCase());
      setSelected(index === -1 ? 0 : index);
    }
  }, [tabsData]);

  const removeTabDispatcher = (tab) => {
    if (nameFunction(tab) === `${params?.params?.data}`) {
      let index = nameFunction(tabs[0]);
      navigate(`/feeearner/${index}`);
    }
    if (tabs.length === 1) {
      navigate("/");
    }
    dispatch(removeTab("feeearner", tab));
  };

  const [selected, setSelected] = React.useState(-1);

  const handleSelect = (e) => {
    urlDefiner(e.selected);
  };

  return allowed ? (
    <TabStrip
      selected={selected}
      onSelect={handleSelect}
      keepTabsMounted={true}
      className="navigationTab"
    >
      {tabs.map((item, index) => {
        return (
          item && (
            <TabStripTab
              title={
                <Title
                  content={findContent(item)}
                  onTabRemove={removeTabDispatcher}
                  selected={findContent(tabs.at(selected))}
                  count={tabs.length}
                />
              }
              key={index}
            >
              <div>
                {item === "chitrequest" && <ChitRequest />}
                {item === "timerecorded" && (
                  <TimeRecorded
                    className="page"
                    fromCaseFile={false}
                    parameter={user.userRef}
                  />
                )}
                {item === "timerecording" && 
                <AddTime fromFeeEarner={true} />
                }
                {/* {item === 'mymatters' && <MyMatters />} */}
                {item === "mymatters" && (
                  <MyMattersPageComponent component={"myMattersPage"} />
                )}
              </div>
            </TabStripTab>
          )
        );
      })}
    </TabStrip>
  ) : (
    <AccessRevokedModal removeTab={() => props.closeTab()} />
  );
});

export default FeeEarnerTabs;
