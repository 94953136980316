import React, { useState } from "react";
import styles from "./Onboarding.module.scss";
import ClientDetails from "./Client/ClientDetails";
import { Stepper, Step } from "@progress/kendo-react-layout";
import MatterDetails from "./MatterDetails/MatterDetails";
import ConflictCheck from "../../pages/CaseFile/ConflictCheck";
import { RiskAssessment } from "components/RiskAssessments";
import EngagementLetters from "./EngagementLetters/EngagementLetters";
import Tasks from "pages/CaseFile/ClientServices/Tasks";
import OpenMatter from "./OpenMatter/OpenMatter";
import stepperIcon from "assets/stepper-icon.svg";

const Onboarding = () => {
  const steps = [
    { label: "Client", component: ClientDetails, completed: false },
    { label: "Matter", component: MatterDetails, completed: false },
    { label: "Conflict Check", component: ConflictCheck, completed: false },
    { label: "Risk Assessment", component: RiskAssessment, completed: false },
    {
      label: "Engagement Letter",
      component: EngagementLetters,
      completed: false,
    },
    { label: "Client Tasks", component: Tasks, completed: false },
    { label: "Opening Matter", component: OpenMatter, completed: false },
  ];

  const [currentStep, setCurrentStep] = useState(0);
  const [formData, setFormData] = useState({});
  const [stepStatus, setStepStatus] = useState(steps);

  const CurrentComponent = steps[currentStep].component;

  const CustomStep = (props) => {
    return (
      <Step {...props}>
        <div
          key={props.index}
          className={`${styles.step} ${
            props.index <= currentStep ? styles.completed : ""
          }`}
        >
          <div className={styles.stepContent}>
            <span className={styles.stepNumber}>{props.index + 1}</span>
            <span className={styles.stepName}>{props.label}</span>
            {props.index < steps.length - 1 && (
              <img src={stepperIcon} alt="stepperIcon" />
            )}
          </div>
        </div>
      </Step>
    );
  };

  const moveNext = (e, data) => {
    if (data) {
      setFormData((prevData) => ({
        ...prevData,
        ...data,
      }));

      setStepStatus((prevStatus) =>
        prevStatus.map((step, index) =>
          index === currentStep ? { ...step, completed: true } : step
        )
      );
    }

    if (e.value === undefined) {
      if (currentStep < steps.length - 1) {
        setCurrentStep(currentStep + 1);
        return;
      }
    } else {
      if (stepStatus[e.value - 1]?.completed || e.value === 0) {
        setCurrentStep(e.value);
      }
    }
  };

  return (
    <div className={styles.onboarding}>
      <div className={styles.progressBar}>
        <Stepper
          className="customStepper me-2"
          value={currentStep}
          onChange={moveNext}
          items={steps}
          item={CustomStep}
        />
      </div>

      <div className={styles.componentContainer}>
        <CurrentComponent moveNext={moveNext} formData={formData} />
      </div>
    </div>
  );
};

export default Onboarding;
