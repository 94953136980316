import {
  START_LOADING,
  STOP_LOADING,
  ERROR,
  SUCCESS,
  GET_USERS_LIST,
  ADDEDNEWUSER,
  UPDATE_USERS_LIST,
  RESET,
  UPLOAD_PHOTOS,
  REMOVE_UPLOAD_PHOTOS,
} from "../types/index.js";

const usersReducer = (
  state = {
    IsError: false,
    isSuccess: false,
    isLoading: undefined,
    mattersList: [],
    files: [{}],
  },
  action
) => {
  switch (action.type) {
    case START_LOADING:
      return { ...state, isLoading: true };
    case STOP_LOADING:
      return { ...state, isLoading: false };
    case GET_USERS_LIST:
      return {
        ...state,
        usersList: action.payload,
      };
    case UPDATE_USERS_LIST:
      return {
        ...state,
        usersList: action.payload,
      };
    case ADDEDNEWUSER:
      return {
        ...state,
      };
    case UPLOAD_PHOTOS:
      state.files = [action.payload];
      return { ...state };
    case REMOVE_UPLOAD_PHOTOS:
      state.files = [{}];
      return { ...state };
    case RESET:
      const data = state;
      data.usersList = undefined;
      return data;
    case ERROR:
      return { ...state, IsError: true, isSuccess: false };
    case SUCCESS:
      return { ...state, IsError: false, isSuccess: true };
    default:
      return state;
  }
};
export default usersReducer;
