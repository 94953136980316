import React, { useState, useCallback } from "react";
import { Button } from "@progress/kendo-react-buttons";
import { useDraggable } from "@progress/kendo-react-common";
import styles from "../CaseWorkflow.module.scss";
import Picker from "../../../../assets/picker.png";

const DraggableButton = ({ fieldType, setDragData }) => {
    const hint = React.useRef(null);
    const input = React.useRef(null);

    const [dragged, setDragged] = useState(false);
    const [initial, setInitial] = useState(null);

    const handleDragStart = useCallback(
        (event) => {
            setDragged(true);
            setInitial({
                x: event.clientX,
                y: event.clientY,
            });
            setDragData(fieldType); // Set the drag data in the shared state
        },
        [fieldType, setDragData]
    );

    const handleDrag = useCallback(
        (event) => {
            if (!hint.current || !hint.current.element || !initial) {
                return;
            }
            const transform = `translate(${event.clientX - initial.x}px, ${event.clientY - initial.y
                }px)`;
            hint.current.element.style.transition = "none";
            hint.current.element.style.transform = transform;
        },
        [initial]
    );

    const handleDragEnd = useCallback(() => {
        if (!hint.current || !hint.current.element) {
            return;
        }
        hint.current.element.style.transition = "transform .3s ease-in-out";
        hint.current.element.style.transform = null;
        setDragged(false);
        setInitial(null);
    }, []);

    useDraggable(
        input,
        {
            onDragStart: handleDragStart,
            onDrag: handleDrag,
            onDragEnd: handleDragEnd,
        },
        {
            hint: hint,
        }
    );

    return (
        <React.Fragment>
            <Button
                ref={input}
                className={`justify-content-start ps-0 rounded-start ${styles["draggable-button"]}`}
            >
                <img className={styles["picker-icon"]} src={Picker} alt="pickerIcon" />
                <span className={`${styles["draggable-text"]} px-3 font-sm fw-semibold`}>
                    {fieldType}
                </span>
            </Button>

            {dragged && (
                <Button
                    ref={hint}
                    className={`justify-content-start ps-0 rounded-start ${styles["draggable-button"]}`}
                >
                    <img className={styles["picker-icon"]} src={Picker} alt="pickerIcon" />
                    <span className={`${styles["draggable-text"]} px-3 font-sm fw-semibold`}>
                        {fieldType}
                    </span>
                </Button>
            )}
        </React.Fragment>
    );
};

export default DraggableButton;
