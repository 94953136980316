import {
  START_LOADING,
  STOP_LOADING,
  HANDLE_CASE_FILE_TABS,
} from "../types/index";
import * as api from "../api/index.js";
import { handleApiError } from "Helper";

export const handleCaseFilesTabs = (type, data) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    dispatch({
      type: HANDLE_CASE_FILE_TABS,
      payload: {
        type: type,
        data: data,
      },
    });
    dispatch({ type: STOP_LOADING });
  } catch (error) {
    handleApiError(error);
  }
};
