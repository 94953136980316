import {
  START_LOADING,
  STOP_LOADING,
  SUCCESS,
  GET_CATEGORY_LIST,
  EDIT_CATEGORY_LIST,
  ADD_CATEGORY,
  GET_DEPARTMENT_LIST,
  EDIT_DEPARTMENT_LIST,
  ADD_DEPARTMENT,
} from "../types/index";
import * as api from "../api/index.js";
import { handleApiError } from "Helper";
import { toast } from "react-toastify";

export const getDepartment = (page, limit) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const datas = await api.getDepartmentAllList(page, limit);
    dispatch({ type: GET_DEPARTMENT_LIST, payload: datas.data });
    dispatch({ type: STOP_LOADING });
    dispatch({ type: SUCCESS, payload: datas.message });
  } catch (error) {
    handleApiError(error);
  }
};

export const editDepartment = (id, data) => async (dispatch) => {
  try {
    const datas = await api.editDepartment(id, data);
    toast.success(`Department '${data?.name}' has been updated`);
    dispatch({ type: EDIT_DEPARTMENT_LIST, payload: datas });
  } catch (error) {
    toast.error("Department cant be updated");
    handleApiError(error);
  }
};

export const addDepartment = (data) => async (dispatch) => {
  try {
    const datas = await api.addDepartment(data);
    dispatch({ type: ADD_DEPARTMENT, payload: datas });
  } catch (error) {
    handleApiError(error);
  }
};
