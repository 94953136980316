import React from "react";
import styles from "./MatterDetails.module.scss";

const DataList = ({
  text,
  value,
  type,
  width,
  statusChart,
  commercial,
  alignItems,
  className,
  valueClass,
  keyClass,
  valueStyles = {},
  keyStyles = {},
}) => (
  <div
    className={`d-flex ${!statusChart && "justify-content-between"} ${
      type === "ContactCard" && "mt-2 ms-2"
    } ${width} ${className} ${alignItems ? alignItems : "align-items-center"}`}
  >
    <p
      className={`${styles["detail-key"]} ${keyClass}`}
      style={{ minWidth: commercial && "120px", ...keyStyles }}
    >
      {text}
    </p>
    <p
      className={`${styles["detail-value"]} ${valueClass}`}
      style={{ width: !statusChart && "85%", ...valueStyles }}
    >
      {value}
    </p>
  </div>
);

export default DataList;
