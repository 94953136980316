import * as React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import Title from "./TabTitle";
import Bills from "../../pages/Bills/Bills";
import MatterLedger from "../../pages/MatterLedger/MatterLedger";
import OfficeCreditAndAllocations from "../../pages/OfficeCreditAndAllocations/OfficeCreditAndAllocations";
import BatchPosting from "../../pages/BatchPosting";
import { useNavigate, useMatch } from "react-router-dom";
import { addTab, removeTab } from "actions/tabs.actions";
import { nameFunction } from "components/common/formatter";
import BatchPostings from "../../pages/BatchPosting";
import CONSTANT from "CONSTANT/Matter/PostingTypes";
import { Tabs } from "components/Enums/TabEnums.ts";
import { useAllowedLiscence } from "hooks/useAllowedLiscence";
import AccessRevokedModal from "components/common/AccessRevokedModal";

const SaleTabs = React.memo((props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [tabs, setTabs] = React.useState([]);
  const tabsData = useSelector((state) => state.tabsData.accountTabs);
  const { allowed } = useAllowedLiscence(Tabs.ACCOUNTS);
  const findContent = (item) => {
    switch (item) {
      case "billing":
        return "Billing";
      case "officepostings":
        return "Office Postings";
      case "clientpostings":
        return "Client Postings";
      case "allpostings":
        return "All Postings";
      case "matterledger":
        return "Matter Ledger";
      case "officeallocations":
        return "Office Allocations";
      case "batchposting":
        return "Batch Posting";
      case "transfer":
        return "Transfers";
      default:
        break;
    }
  };

  const urlDefiner = (e) => {
    if (params !== null) {
      let url = tabs[e]?.toLowerCase();
      if (url === undefined) {
        url = "";
      }
      window.history.replaceState(null, "", `/accounts/${url}`);
    }
  };

  const params = useMatch("accounts/:data");
  useEffect(() => {
    if (params !== null) {
      let data = params?.params?.data;
      if (!tabsData.includes(data?.toLowerCase())) {
        dispatch(addTab("Accounts", data?.toLowerCase()));
      } else {
        setSelected(tabsData.indexOf(data?.toLowerCase()));
      }
    }
  }, [params]);

  useEffect(() => {
    if (tabsData) {
      setTabs(tabsData);
      let index = tabsData.indexOf(params?.params?.data.toLowerCase());
      setSelected(index === -1 ? 0 : index);
    }
  }, [tabsData]);

  const removeTabDispatcher = (tab) => {
    if (nameFunction(tab) === `${params?.params?.data}`) {
      let index = nameFunction(tabs[0]);
      navigate(`/accounts/${index}`);
    }
    if (tabs.length === 1) {
      navigate("/");
    }
    dispatch(removeTab("Accounts", tab));
  };

  const [selected, setSelected] = React.useState(-1);
  useEffect(() => {
    urlDefiner(selected);
  }, [selected]);

  const handleSelect = (e) => {
    setSelected(e.selected);
    urlDefiner(e.selected);
  };

  return allowed ? (
    <TabStrip
      selected={selected}
      onSelect={handleSelect}
      keepTabsMounted={true}
      className="navigationTab"
    >
      {tabs.map((item, index) => {
        return (
          item && (
            <TabStripTab
              title={
                <Title
                  content={findContent(item)}
                  onTabRemove={removeTabDispatcher}
                  selected={findContent(tabs.at(selected))}
                  count={tabs.length}
                />
              }
              key={index}
            >
              <div style={{ height: "100%" }}>
                {item === "transfer" && <BatchPostings type={CONSTANT.TRANS} />}
                {item === "officepostings" && (
                  <BatchPostings type={CONSTANT.OP} />
                )}
                {item === "clientpostings" && (
                  <BatchPostings type={CONSTANT.CP} />
                )}
                {item === "billing" && <Bills />}
                {item === "matterledger" && <MatterLedger />}
                {item === "officeallocations" && <OfficeCreditAndAllocations />}
                {item === "batchposting" && <BatchPosting type={CONSTANT.BP} />}
              </div>
            </TabStripTab>
          )
        );
      })}
    </TabStrip>
  ) : (
    <AccessRevokedModal removeTab={() => props.closeTab()} />
  );
});

export default SaleTabs;
