import React, {
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import CustomInput from "./Input";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import AddressInputComponent from "./AddressInputComponent";
import { emailValidator } from "./validator";

const CONSTANT = {
  PERSON: "Person",
  COMPANY: "Company",
};

const ContactInformation = ({ readOnly, type, tab = "", addType }) => {
  return (
    <>
      <div className="row">
        <Field
          disabled={readOnly}
          mr="20px"
          name={`${addType}phone1`}
          label="Phone Number"
          ph="Add Phone Number"
          width="206px"
          component={CustomInput}
          className="col-4"
        />
        <Field
          disabled={readOnly}
          className={"col-4"}
          name={`${addType}phone2`}
          label="Secondary Phone"
          ph="Add Phone Number"
          width="206px"
          component={CustomInput}
        />
        <Field
          className={"col-4"}
          disabled={readOnly}
          label="Mobile Number"
          name={`${addType}mobileNo`}
          ph="Add Mobile Number"
          width="206px"
          component={CustomInput}
        />
      </div>
      <div className="row mt-2 max-100">
        <Field
          className="col-8"
          disabled={readOnly}
          label="Email"
          name={`${addType}email`}
          validator={emailValidator}
          ph="Add Email"
          width="100%"
          component={CustomInput}
        />
        <Field
          disabled={readOnly}
          label="Website"
          name={`${addType}website`}
          ph="Add Website"
          width="206px"
          className="col-4"
          component={CustomInput}
        />
      </div>

      <AddressInputComponent
        name="Address"
        readOnly={readOnly}
        type={addType}
        style={{ marginTop: "15px", width: "100%" }}
      />
    </>
  );
};

export default ContactInformation;
