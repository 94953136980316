import {
    START_LOADING,
    STOP_LOADING,
    ERROR,
    SUCCESS,
    RESET,
    GET_CATEGORY_LIST,
    EDIT_CATEGORY_LIST,
    GET_WORKTYPE_DEPARTMENT
} from "../types/index.js";

const CategoriesReducer = (
    state = {
        IsError: false,
        isSuccess: false,
        isLoading: undefined,
        workTypeList: [],
    },
    action
    
) => {

    switch (action.type) {
        
        case START_LOADING:
            return { ...state, isLoading: true };
        case STOP_LOADING:
            return { ...state, isLoading: false };
        case GET_CATEGORY_LIST:
            return {
                ...state,
                categoriesList: action.payload,
            };
        case GET_WORKTYPE_DEPARTMENT:
            return {
                ...state,
                workTypeList: action.payload,
            };    
        case EDIT_CATEGORY_LIST:
            return {
                ...state,
                categoriesList: action.payload,
            };
        case RESET:
            const data = state;
            data.categoriesList = undefined;
            return data;
        case ERROR:
            return { ...state, IsError: true, isSuccess: false };
        case SUCCESS:
            return { ...state, IsError: false, isSuccess: true };
        default:
            return state;
    }
};
export default CategoriesReducer;
