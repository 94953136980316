import {
  START_LOADING,
  STOP_LOADING,
  SUCCESS,
  GET_REFERRER,
  EDIT_REFERRER,
  GET_ACTIVE_REFERRERS,
} from "../types/index";
import * as api from "../api/index.js";
import { handleApiError } from "Helper";
import { toast } from "react-toastify";

export const getReferrers = (page, limit) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const datas = await api.getReferrers(page, limit);
    dispatch({ type: GET_REFERRER, payload: datas.data });
    dispatch({ type: STOP_LOADING });
    dispatch({ type: SUCCESS, payload: datas.message });
  } catch (error) {
    handleApiError(error);
  }
};

export const editReferrer = (id, data) => async (dispatch) => {
  try {
    const datas = await api.editReferrer(id, data);
    toast.success(`Referrer '${data?.referrerName}' has been updated`);
    dispatch({ type: EDIT_REFERRER, payload: datas });
  } catch (error) {
    toast.error("Referrer cant be updated");
    handleApiError(error);
  }
};

export const getActiveReferrers = (page, limit) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const datas = await api.getActiveReferrers(page, limit);
    dispatch({ type: GET_ACTIVE_REFERRERS, payload: datas.data });
    dispatch({ type: STOP_LOADING });
    dispatch({ type: SUCCESS, payload: datas.message });
  } catch (error) {
    handleApiError(error);
  }
};
