import { TABS_CONFIG, TABS_CONFIG_REMOVE } from '../types/index';


export const addTab = (component, name) => async (dispatch) => {
    try {
        let data = {
            type: component,
            data: name
        }
        dispatch({ type: TABS_CONFIG, payload: data })
    } catch (error) {
    }
};

export const removeTab = (component, name) => async (dispatch) => {
    try {
        let data = {
            type: component,
            data: name
        }
        dispatch({ type: TABS_CONFIG_REMOVE, payload: data })
    } catch (error) {
    }
};