export const CONSTANT = {
    COMBOBOX: {
        TYPE: "Type",
        FEE_EARNER: "Fee_Earner",
    },
    DATE: {
        DATE: "Date",
    },
    INPUT: {
        DETAILS: "Details",
        UNITS: "Units",
        RATE: "Rate",
        MPU: "Mpu",
        VALUE: "Value",
    }
}