import * as React from "react";
import * as api from "../../../api/index";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Button } from "@progress/kendo-react-buttons";
import BranchesTest from "./brancheslist";
import styles from "../Settings.module.scss";
import EditBranch from "./Editbranch";
import { getBranchesList } from "actions/branches.action";

const Branches = (props) => {
  const [addnewform, setaddnewform] = React.useState(false);
  const dispatch = useDispatch();
  const closeform = () => {
    setaddnewform(false);
    // props.onCancel();
  };
  React.useEffect(() => {
    if (props.onClose === false) {
      closeform();
    }
  }, [props.onClose]);

  const handleSubmit = async (e) => {
    setaddnewform(false);
    try {
      await api.addnewbranch(e);
      toast.success("Branch Added");
      dispatch(getBranchesList());
    } catch (error) {
      toast.error("Error Occured");
    }
  };

  return (
    <React.Fragment>
      <>
        <div className="row">
          <div className="col-lg-10 col-md-8"></div>
          <Button
            className={`common-btn col-lg-1 col-md-4 p-2 mt-5 ${
              styles[`button`]
            }`}
            onClick={() => {
              setaddnewform(true);
              props.open();
            }}
          >
            Add <br></br>
            Branch
          </Button>
        </div>
        {props.component === "Wizard" && (
          <div className="buttonstyle">
            <Button
              onClick={() => {
                props.moveNext();
              }}
              className="common-btn"
            >
              Next
            </Button>
          </div>
        )}
        <div className={`row mx-auto mt-5 ${styles[`branches-grid`]}`}>
          <BranchesTest />
        </div>
      </>
      {addnewform && (
        <EditBranch
          cancelEdit={closeform}
          onSubmit={handleSubmit}
          type="addNew"
        />
      )}
    </React.Fragment>
  );
};

export default Branches;
