import * as React from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import { AiOutlineCheckCircle } from "react-icons/ai"

const SuccessModal = ({ message, onClose }) => {

    return (
        <Dialog width={400} height={230}>
            <div className="d-flex flex-column justify-content-around align-items-center h-100 px-5 text-center">
                <AiOutlineCheckCircle size={"50px"} color="green" />
                <p style={{ fontWeight: "500", fontSize: "18px" }}> {message}</p>
                <button className={`common-btn border-0  `} onClick={onClose}>Close</button>
            </div>
        </Dialog >

    );
};

export default SuccessModal;
