import * as React from "react";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { useDispatch, useSelector } from "react-redux";
import { Checkbox, NumericTextBox } from "@progress/kendo-react-inputs";
import { Label } from "@progress/kendo-react-labels";
import { useState, useEffect } from "react";
import { TextArea } from "@progress/kendo-react-inputs";
import styles from "./Matter.module.scss";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import AddClients from "pages/Clients/AddViewClients";
import { Button } from "@progress/kendo-react-buttons";
import { getfeeearner } from "actions/postings.action";
import { PageTabItemTitle } from "components/tabs/TabItem/PageTabItemTitle";
import { getBranchesnames } from "actions/branchnames.action";
import { getContactLinks } from "api/settingApi";
import {
  addMatter,
  getActiveApplications,
  getCategoryList,
  getClientsNameAndRef,
  getContactsListAll,
  getDepartmentList,
  getMatterAllInfo,
  getMatterRefExample,
  updateMatter,
  getWorkType,
  getActivePermissions,
  editAccessibleUsers,
} from "api/index";

import { getDDABankNominals, getIncomeNominals } from "actions/nominals.action";

import {
  FormComboBox,
  TabTitle,
  FormInput,
  FormDate,
} from "components/Matters/index";

import { CONSTANT } from "CONSTANT/Matter/MatterForm";
import MatterModal from "components/Matters/MatterModal";
import { filterBy } from "@progress/kendo-data-query";
import {
  prepareApplicationData,
  prepareFeeearnerData,
} from "components/common/ComboRepresentationFunctions";
import { getActiveReferrers } from "actions/referrers.action";
import { getActiveSources } from "actions/sources.action";
import { ContainerCard } from "components/MatterDetails/ContactCard";
import { Field } from "@progress/kendo-react-form";
import { Tooltip } from "@progress/kendo-react-tooltip";
import Loader from "components/common/Loading";
import MatterView from "./MatterViewPage";
import { MatterPermissions } from "./MatterPermissions";
import {
  getMattersAllInfo,
  getMattersAllURLInfo,
} from "actions/matters.action";
import { Tile } from "components/UIComponents/Tile";
import EstimateFeeImage from "../../assets/estimate-fee.png";
import EstimateFee from "./EstimateFee";

const MatterForm = (props) => {
  const [formType, setFormType] = useState(() => {
    if (props.reference && props.isView) return "VIEW";
    else return "ADD_NEW";
  });

  const { branchesList } = useSelector((state) => state.branchesnames);
  const matterDetails = useSelector((state) => state.mattersList);
  const { feeearner } = useSelector((state) => state.postingsdocs);
  const { activeSources } = useSelector((state) => state.sources);
  const { activeReferrers } = useSelector((state) => state.referrers);
  const { userlist: user } = useSelector((state) => state.currentuser);
  const { nominalPofitAndLoss, nominalDefaultDDABank } = useSelector(
    (state) => state.nominalList
  );
  const [details, setDetails] = useState("");
  const dispatch = useDispatch();
  const [departmentData, setDepartmentData] = useState([]);
  const [departmentFilter, setDepartmentfilter] = useState([]);
  const [categoryData, setCaegoryData] = useState([]);
  const [categoryFilter, setCaegoryFilter] = useState([]);
  const [superVisor, setSuperVisor] = useState(feeearner.data);
  const [profitAndLoss, setProfitAndLoss] = useState(nominalPofitAndLoss.data);
  const [activeSourcesData, setActiveSourcesData] = useState([]);
  const [activeReferrersData, setActiveReferrersData] = useState([]);
  const [contact, setContact] = useState([{ contact: "", contactLink: "" }]);
  const [contactLinks, setContactLinks] = useState();
  const [addClient, setAddClient] = useState(false);
  const [contactLinksFilter, setContactLinksFilter] = useState();
  const [clientData, setClientData] = useState();
  const [application, setApplication] = useState();
  const [contactData, setContactData] = useState();
  const [contactDataFilter, setContactDataFilter] = useState();
  const [applicationFilter, setApplicationFilter] = useState();
  const [accessibleUsers, setAccessibleUsers] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [modalSuccess, setModalSuccess] = useState(undefined);
  const [newMaterId, setNewMatterId] = useState(undefined);
  const [closed, setClosed] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const [loading, setLoading] = useState(false);

  const [inputState, setInputState] = useState({
    [CONSTANT.INPUT.LOCATION]: "",
    [CONSTANT.COMBOBOX.MATTER_REF]: "",
    [CONSTANT.INPUT.WIP_CREDIT_LIMIT]: "",
    [CONSTANT.INPUT.DISP_CREDIT_LIMIT]: "",
    [CONSTANT.INPUT.BILL_CREDIT_LIMIT]: "",
    [CONSTANT.INPUT.LAST_NAME_OS1]: "",
    [CONSTANT.INPUT.FIRST_NAME_OS2]: "",
    [CONSTANT.INPUT.FIRST_NAME_OS2]: "",
    [CONSTANT.INPUT.LAST_NAME_OS2]: "",
    [CONSTANT.INPUT.POST_CODE_OS1]: "",
    [CONSTANT.INPUT.POST_CODE_OS2]: "",
    [CONSTANT.INPUT.CONTACT]: "",
  });

  const [comboBoxState, setComboBoxState] = useState({
    [CONSTANT.COMBOBOX.CLIENT]: "",
    [CONSTANT.COMBOBOX.SUPERVISIOR]: "",
    [CONSTANT.COMBOBOX.FEE_EARNER]: user?.isFeeEarner
      ? {
          userRef: user?.userRef,
          representation: `${user?.userRef} - ${user.displayname} `,
        }
      : null,
    [CONSTANT.COMBOBOX.APPLICATION]: "",
    [CONSTANT.COMBOBOX.DEPARTMENT]: "",
    [CONSTANT.COMBOBOX.CATEGORY]: "",
    [CONSTANT.COMBOBOX.BRANCH]: "",
    [CONSTANT.COMBOBOX.DDA_BANK]: "",
    [CONSTANT.COMBOBOX.PROFIT_COST_NOMINAL]: "",
    [CONSTANT.COMBOBOX.CONTACT_LINK]: "",
    [CONSTANT.COMBOBOX.RATE_TYPE]: "",
    accessibleUser: "",
  });

  const [dateState, setDateState] = useState({
    [CONSTANT.DATE.START_DATE]: new Date(),
    [CONSTANT.DATE.CLOSED_DATE]: "",
    [CONSTANT.DATE.DELETED_DATE]: "",
    [CONSTANT.DATE.DOB_OS1]: "",
    [CONSTANT.DATE.DOB_OS2]: "",
    Opened: new Date(),
  });

  const [feeEarner, setFeeEarner] = useState(feeearner.data);
  const [showEstimatedFee, setShowEstimatedFee] = useState(false);

  const getAccessibleUsers = async (id) => {
    try {
      const users = await getActivePermissions(id);
      setAccessibleUsers(users?.data?.data);
    } catch (error) {}
  };

  useEffect(() => {
    setFeeEarner(feeearner.data);
    setSuperVisor(feeearner.data);
  }, [feeearner]);

  useEffect(() => {
    setProfitAndLoss(nominalPofitAndLoss.data);
  }, [nominalPofitAndLoss]);

  useEffect(() => {
    if (activeReferrers) setActiveReferrersData(activeReferrers.data);
  }, [activeReferrers]);

  useEffect(() => {
    if (activeSources) setActiveSourcesData(activeSources.data);
  }, [activeSources]);

  useEffect(() => {
    if (
      comboBoxState[CONSTANT.COMBOBOX.CATEGORY]?.workTypeID &&
      formType === "ADD_NEW"
    ) {
      getWorkTypeData();
    }
  }, [comboBoxState[CONSTANT.COMBOBOX.CATEGORY]?.workTypeID]);

  const getWorkTypeData = async () => {
    setLoading(true);
    const { data } = await getWorkType(
      comboBoxState[CONSTANT.COMBOBOX.CATEGORY]?.workTypeID
    );
    const response = data.data;

    setInputState((prevState) => ({
      ...prevState,
      [CONSTANT.INPUT.WIP_CREDIT_LIMIT]: response?.wipCreditLimit || "",
      [CONSTANT.INPUT.DISP_CREDIT_LIMIT]: response?.disbCreditLimit || "",
      [CONSTANT.INPUT.BILL_CREDIT_LIMIT]: response?.billCreditLimit || "",
    }));

    const appItem = application.find(
      (item) => item.appCode === response.appCode
    );
    const profitAndLossItem = profitAndLoss.find(
      (item) => item.nominalRef === response.defaultProfitCost
    );

    setComboBoxState((prevState) => ({
      ...prevState,
      [CONSTANT.COMBOBOX.APPLICATION]: {
        appCode: response.appCode,
        appName: appItem?.appName,
        representation: response.appCode + "-" + appItem?.appName,
      },
      [CONSTANT.COMBOBOX.PROFIT_COST_NOMINAL]: {
        nominalRef: response.defaultProfitCost,
        nominalName: profitAndLossItem?.nominalName,
      },
    }));
    setLoading(false);
  };

  const filterFeeEarner = (filter) => {
    const data = feeearner.data.slice();
    return filterBy(data, filter);
  };
  const feeEarnerfilterChange = (event) => {
    setFeeEarner(filterFeeEarner(event.filter));
  };

  const filterProfitAndLoss = (filter) => {
    const data = nominalPofitAndLoss.data.slice();
    return filterBy(data, filter);
  };
  const profitAndLossfilterChange = (event) => {
    setProfitAndLoss(filterProfitAndLoss(event.filter));
  };

  const filterSuperVisor = (filter) => {
    const data = feeearner.data.slice();
    return filterBy(data, filter);
  };
  const superVisorfilterChange = (event) => {
    setSuperVisor(filterSuperVisor(event.filter));
  };

  const filterrApplication = (filter) => {
    const data = application.slice();
    return filterBy(data, filter);
  };
  const applicationfilterChange = (event) => {
    setApplicationFilter(filterrApplication(event.filter));
  };

  const filterDepartment = (filter) => {
    const data = departmentData.slice();
    return filterBy(data, filter);
  };
  const departmentfilterChange = (event) => {
    setDepartmentfilter(filterDepartment(event.filter));
  };

  const filterCaegory = (filter) => {
    const data = categoryData.slice();
    return filterBy(data, filter);
  };
  const cateforyFilterChange = (event) => {
    setCaegoryFilter(filterCaegory(event.filter));
  };

  const filterContact = (filter) => {
    const data = contactData.slice();
    return filterBy(data, filter);
  };
  const contactFilterChange = (event) => {
    setContactDataFilter(filterContact(event.filter));
  };

  const filterContactLinks = (filter) => {
    const data = contactLinks.slice();
    return filterBy(data, filter);
  };
  const contactLinksFilterChange = (event) => {
    setContactLinksFilter(filterContactLinks(event.filter));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const parseDate = (newDate) => {
      if (!newDate) return null;
      var os = newDate?.getTimezoneOffset();
      return (newDate = new Date(newDate.getTime() - os * 60 * 1000));
    };
    const dataObject = {
      matterRef: inputState[CONSTANT.INPUT.MATTER_REF] || null,
      details: details,
      estimate: inputState["estimateValue"],
      feeEarnerRef: comboBoxState[CONSTANT.COMBOBOX.FEE_EARNER].userRef || null,
      clientId: comboBoxState[CONSTANT.COMBOBOX.CLIENT].CustomerID || null,
      supervisorRef:
        comboBoxState[CONSTANT.COMBOBOX.SUPERVISIOR]?.userRef || null,
      appCode: comboBoxState[CONSTANT.COMBOBOX.APPLICATION].appCode || null,
      branchId: comboBoxState[CONSTANT.COMBOBOX.BRANCH].branchId || null,
      startDate: parseDate(dateState[CONSTANT.DATE.START_DATE]) || null,
      dateCompleted: parseDate(dateState[CONSTANT.DATE.CLOSED_DATE]) || null,
      otherside1_Firstname: inputState[CONSTANT.INPUT.FIRST_NAME_OS1] || null,
      otherside1_Lastname: inputState[CONSTANT.INPUT.LAST_NAME_OS1] || null,
      customField1: null,
      otherside1_Postcode: inputState[CONSTANT.INPUT.POST_CODE_OS1] || null,
      otherside1_DateOfBirth:
        parseDate(dateState[CONSTANT.DATE.DOB_OS1]) || null,
      otherside2_Lastname: inputState[CONSTANT.INPUT.LAST_NAME_OS2] || null,
      otherside2_Firstname: inputState[CONSTANT.INPUT.FIRST_NAME_OS2] || null,
      otherside2_Postcode: inputState[CONSTANT.INPUT.POST_CODE_OS2] || null,
      otherside2_DateOfBirth:
        parseDate(dateState[CONSTANT.DATE.DOB_OS2]) || null,
      wipCreditLimit: inputState[CONSTANT.INPUT.WIP_CREDIT_LIMIT] || null,
      disbCreditLimit: inputState[CONSTANT.INPUT.DISP_CREDIT_LIMIT] || null,
      billCreditLimit: inputState[CONSTANT.INPUT.BILL_CREDIT_LIMIT] || null,
      defaultDDABank:
        comboBoxState[CONSTANT.COMBOBOX.DDA_BANK].nominalName || null,
      defaultProfitCost:
        comboBoxState[CONSTANT.COMBOBOX.PROFIT_COST_NOMINAL].nominalRef || null,
      application: comboBoxState[CONSTANT.COMBOBOX.APPLICATION].name || null,
      departmentId:
        comboBoxState[CONSTANT.COMBOBOX.DEPARTMENT]?.departmentID || -1,
      workTypeId: comboBoxState[CONSTANT.COMBOBOX.CATEGORY]?.workTypeID || -1,
      sourceId: comboBoxState[CONSTANT.COMBOBOX.SOURCE]?.sourceID || -1,
      referrerId: comboBoxState[CONSTANT.COMBOBOX.REFERRRER]?.referrerID || -1,
      location: inputState[CONSTANT.INPUT.LOCATION] || null,
      closed: closed,
      dateClosed: parseDate(dateState[CONSTANT.DATE.CLOSED_DATE]) || null,
      matterAccess: accessibleUsers?.length ? accessibleUsers : undefined,
      contacts: contact?.map((item) => {
        return {
          contactCategoryId: item?.contactLink?.contactCategoryId,
          active: item?.contactLink?.active,
          contactId: item?.contact?.contactId,
          name: item.contact?.name,
        };
      }),
      status: "Open",
      rateType: comboBoxState[CONSTANT.COMBOBOX.RATE_TYPE]?.rateType || null,
      rateValue: inputState["rateValue"],
    };

    if (!dataObject.contacts[0]?.contactId) {
      dataObject.contacts = [];
    }

    if (formType === "EDIT") {
      try {
        const res = await updateMatter(
          matterDetails?.matterViewDetails[props.reference]?.data?.matterId,
          {
            data: dataObject,
          }
        );
        if (res?.status === 200) {
          updateModalState(true, "Matter Updated Successfully", true);
        }
        return;
      } catch (err) {
        if (
          err?.response?.data !== "" &&
          err?.response?.data?.errorMessage.includes(
            "The duplicate key value is"
          )
        ) {
          updateModalState(true, "Matter Already Added", false);
        } else {
          updateModalState(true, "Error! Matter Upate Failed", false);
        }
      }
    } else {
      try {
        const res = await addMatter({ data: dataObject });
        if (res?.status === 200) {
          updateModalState(true, "Matter Added Successfully", true);
          setNewMatterId(res.data.data.matterId);
        }
      } catch (err) {
        if (
          err?.response?.data !== "" &&
          err?.response?.data?.errorMessage?.includes(
            "The duplicate key value is"
          )
        ) {
          updateModalState(true, "Matter Ref Already Added", false);
        } else {
          updateModalState(true, "Error Occured", false);
        }
      }
    }
  };

  useEffect(() => {
    if (
      formType === "VIEW" &&
      matterDetails?.matterViewDetails[props.reference]?.data?.matterId
    ) {
      getMatterData();
    }
  }, [matterDetails]);

  const [selected, setSelected] = React.useState(0);
  const handleSelect = (e) => {
    setSelected(e.selected);
  };

  const handleModalClose = () => {
    setShowModal(false);
    if (modalSuccess) {
      const myObj = {
        dataItem: {
          matterId: newMaterId,
          matterRef: inputState[CONSTANT.INPUT.MATTER_REF],
          matterDetails: details,
        },
      };
      dispatch(
        getMattersAllInfo(
          matterDetails?.matterViewDetails[props.reference]?.data?.matterId
        )
      );
      setFormType("VIEW");
      if (formType === "ADD_NEW") {
        props.onMatterRefClickActions(myObj);
      }
    }
  };

  const updateModalState = (modalShow, message, success) => {
    setShowModal(modalShow);
    setModalMessage(message);
    setModalSuccess(success);
  };

  const handleContactAdd = () => {
    const contactCopy = [...contact];
    contactCopy.push({ contact: {}, contactLink: { active: true } });
    setContact([...contactCopy]);
  };

  const handleContactDelete = (index, value) => {
    const activeTrue = contact.filter(
      (item) => item.contactLink.active === true
    );
    if (activeTrue.length === 1) {
      const contactCopy = [...contact];
      contactCopy[index].contactLink = {
        active: false,
        contactCategoryId: value.contactLink.contactCategoryId,
      };
      contactCopy[index].contact = { contactId: value.contact.contactId };
      setContact([...contactCopy]);
    } else {
      const contactCopy = [...contact];
      contactCopy[index].contactLink.active = false;
      setContact([...contactCopy]);
    }
  };
  const getMatterData = async () => {
    const res = await getMatterAllInfo(
      matterDetails?.matterViewDetails[props.reference]?.data?.matterId
    );
    const { data } = res;
    setDetails(data.data.details);
    setInputState({
      [CONSTANT.INPUT.MATTER_REF]: data.data.matterRef || "",
      [CONSTANT.INPUT.LAST_NAME_OS1]: data.data.otherside1_Lastname || "",
      [CONSTANT.INPUT.FIRST_NAME_OS1]: data.data.otherside1_Firstname || "",
      [CONSTANT.INPUT.FIRST_NAME_OS2]: data.data.otherside2_Firstname || "",
      [CONSTANT.INPUT.LAST_NAME_OS2]: data.data.otherside2_Lastname || "",
      [CONSTANT.INPUT.POST_CODE_OS1]: data.data.otherside1_Postcode || "",
      [CONSTANT.INPUT.POST_CODE_OS2]: data.data.otherside2_Postcode || "",
      [CONSTANT.INPUT.WIP_CREDIT_LIMIT]: data.data.wipCreditLimit || "",
      [CONSTANT.INPUT.DISP_CREDIT_LIMIT]: data.data.disbCreditLimit || "",
      [CONSTANT.INPUT.BILL_CREDIT_LIMIT]: data.data.billCreditLimit || "",
      [CONSTANT.INPUT.LOCATION]: data.data.location || "",
      ["estimateValue"]: data.data.estimate || "",
      ["rateValue"]: data.data.rateValue || "",
    });

    setComboBoxState({
      [CONSTANT.COMBOBOX.BRANCH]: {
        branchId: data.data.branchId,
        branchName: data.data.branch,
      },
      [CONSTANT.COMBOBOX.FEE_EARNER]: {
        representation: data.data.feeEarnerName,
        userRef: data.data.feeEarnerRef,
      },
      [CONSTANT.COMBOBOX.DDA_BANK]: { nominalName: data.data.defaultDDABank },
      [CONSTANT.COMBOBOX.PROFIT_COST_NOMINAL]: {
        nominalRef: data.data.defaultProfitCost,
        nominalName: profitAndLoss.find(
          (item) => item.nominalRef === data.data.defaultProfitCost
        )?.nominalName,
      },
      [CONSTANT.COMBOBOX.CLIENT]: {
        CustomerID: data.data.client?.clientId,
        ContactName: data.data.client?.clientName,
        CustomerRef: data.data.client?.clientRef,
      },
      [CONSTANT.COMBOBOX.APPLICATION]: {
        appName: data.data.application,
        appCode: data.data.appCode,
      },
      [CONSTANT.COMBOBOX.SUPERVISIOR]: {
        displayname: data.data.supervisorName,
        userRef: data.data.supervisorRef,
      },
      [CONSTANT.COMBOBOX.REFERRRER]: {
        referrerName: data.data.referrer,
        referrerID: data.data.referrerId,
      },
      [CONSTANT.COMBOBOX.SOURCE]: {
        sourceName: data.data.source,
        sourceID: data.data.sourceId,
      },
      [CONSTANT.COMBOBOX.DEPARTMENT]: {
        name: data.data.department,
        departmentID: data.data.departmentId,
      },
      [CONSTANT.COMBOBOX.CATEGORY]: {
        name: data.data.workType,
        workTypeID: data.data.workTypeId,
      },
      [CONSTANT.COMBOBOX.RATE_TYPE]: {
        rateType: data.data.rateType,
        representation:
          data.data.rateType === "MatterFlatRate"
            ? "Matter Flat Rate"
            : data.data.rateType === "FeeEarnerDefault"
            ? "Fee Earner Default"
            : "Rate Category",
      },
    });

    setDateState({
      [CONSTANT.DATE.START_DATE]: new Date(data.data.startDate),
      [CONSTANT.DATE.CLOSED_DATE]: data.data.dateCompleted
        ? new Date(data.data.dateCompleted)
        : null,
      [CONSTANT.DATE.DOB_OS1]: data.data.otherside1_DateOfBirth
        ? new Date(data.data.otherside1_DateOfBirth)
        : null,
      [CONSTANT.DATE.DOB_OS2]: data.data.otherside2_DateOfBirth
        ? new Date(data.data.otherside2_DateOfBirth)
        : null,
    });
    setClosed(data?.data?.closed);
    setAccessibleUsers(data?.data?.matterAccess);

    const datas = await getContactLinks();
    const contactData1 = data?.data?.contacts.map((item) => {
      return {
        contact: { contactId: item?.contactId, name: item?.name },
        contactLink: {
          categoryName: item?.matterContactCategory,
          linkType: item?.matterContactCategory,
          contactCategoryId: datas.data.data?.find(
            (ind) => ind?.categoryName === item?.matterContactCategory
          )?.contactCategoryId,
          active: true,
        },
      };
    });

    const emptyContact = [
      {
        contact: { contactId: "", name: "" },
        contactLink: { linkType: "" },
      },
    ];

    let finalContact;
    if (contactData1.length) {
      finalContact = contactData1;
    } else {
      finalContact = emptyContact;
    }

    setContact(finalContact);
  };

  const onChangeContact = (index, value) => {
    const contactCopy = [...contact];

    contactCopy[index].contact = {
      contactId: value.contactId,
      name: value.name,
    };
    setContact([...contactCopy]);
  };

  const onChangeContactLink = (index, e) => {
    const contactCopy = [...contact];
    contactCopy[index].contactLink = e.value;
    contactCopy[index].contactLink.active = true;

    setContact([...contactCopy]);
  };

  const FeeEarnerCell = (props) => {
    return (
      <td className="">
        <span>{props.dataItem?.feeEarnerName}</span>
      </td>
    );
  };

  const handleInputChange = (value, label) => {
    setInputState({
      ...inputState,
      [label]: value,
    });
  };

  const handleComboBoxChange = (e, label) => {
    setComboBoxState({
      ...comboBoxState,
      [label]: e.value,
    });
  };

  const handleDateChange = (e, label) => {
    setDateState({
      ...dateState,
      [label]: e.value,
    });
  };

  const getContactLinksType = async () => {
    const { data } = await getContactLinks();
    setContactLinks(data.data.slice());
    setContactLinksFilter(data.data.slice());
  };

  const getDepartmentData = async () => {
    const { data } = await getDepartmentList();
    setDepartmentData(data.data.slice());
    setDepartmentfilter(data.data.slice());
  };

  const getCategoryData = async () => {
    const { data } = await getCategoryList();
    setCaegoryData(data.data.slice());
    setCaegoryFilter(data.data.slice());
  };

  const getApplication = async () => {
    const datas = await getActiveApplications();
    const preparedData = prepareApplicationData(datas?.data?.data);
    setApplication(preparedData);
    setApplicationFilter(preparedData);
  };

  // useEffect(() => {
  //   setTimeout(() => {
  //     setCaegoryData();
  //   }, 100);
  // }, [comboBoxState[CONSTANT.COMBOBOX.DEPARTMENT]]);

  const handleAccesibleUsers = async (matterId, feeEarnerRef, type) => {
    try {
      if (type === "add") {
        await editAccessibleUsers(matterId, [
          {
            feeEarnerRef: feeEarnerRef,
            active: true,
          },
        ]);
      } else {
        await editAccessibleUsers(matterId, [
          {
            feeEarnerRef: feeEarnerRef,
            active: false,
          },
        ]);
      }
    } catch (error) {}
  };

  const getContacts = async () => {
    try {
      const datas = await getContactsListAll();
      setContactData(datas.data.data.slice());
      setContactDataFilter(datas.data.data.slice());
    } catch (error) {
      setContactData([]);
      setContactDataFilter([]);
    }
  };

  useEffect(() => {
    if (formType === "EDIT" || formType === "ADD_NEW") {
      dispatch(getfeeearner());
      dispatch(getActiveReferrers());
      dispatch(getActiveSources());
      dispatch(getBranchesnames());
      getContactLinksType();
      dispatch(getIncomeNominals());
      dispatch(getDDABankNominals());
      getDepartmentData();
      getCategoryData();
      getApplication();
      getContacts();
    }
  }, [formType]);

  const handleClientChange = async (value) => {
    if (value !== null) {
      try {
        const response = await getMatterRefExample(value.CustomerRef);
        setInputState({
          ...inputState,
          [CONSTANT.INPUT.MATTER_REF]: response.data,
        });
      } catch (error) {}
      const comboBoxClone = {
        ...comboBoxState,
        [CONSTANT.COMBOBOX.CLIENT]: value,
      };
      if (value.branchName) {
        comboBoxClone[CONSTANT.COMBOBOX.BRANCH] = branchesList.filter(
          (branch) => branch.branchName === value.branchName
        )[0];
      }
      setComboBoxState({ ...comboBoxClone });
    }
  };

  useEffect(() => {
    if (branchesList?.length && user?.branchId && formType === "ADD_NEW") {
      const defaultBranch = branchesList.filter(
        (branch) => branch.branchId === user.branchId
      )[0];
      setComboBoxState({
        ...comboBoxState,
        [CONSTANT.COMBOBOX.BRANCH]: defaultBranch,
      });
    }
  }, [branchesList]);

  return (
    <div className={styles["matter-form-dialog1"]}>
      {formType === "VIEW" ? (
        <Dialog
          className="dialog-xxl "
          title={
            formType === "VIEW"
              ? "View Matter"
              : formType === "EDIT"
              ? "Update Matter"
              : "Add Matter"
          }
          onClose={props.setshowMatterForm}
        >
          <MatterView
            reference={props.reference}
            editMatter={() => setFormType("EDIT")}
          />

          <DialogActionsBar>
            <div className="dialog-footer">
              <Button
                className="common-btn border-0 me-4"
                style={{ maxWidth: "60px" }}
                onClick={() => {
                  setFormType("EDIT");
                }}
              >
                Edit
              </Button>
            </div>
          </DialogActionsBar>
        </Dialog>
      ) : (
        <Dialog
          className="dialog-xxl add-dialog"
          title={
            formType === "VIEW"
              ? "View Matter"
              : formType === "EDIT"
              ? "Update Matter"
              : "Add Matter"
          }
          onClose={() => {
            if (formType === "ADD_NEW") props.setshowMatterForm();
            else setFormType("VIEW");
          }}
        >
          <div className="pt-3 ps-5 pe-2">
            <div className="row">
              <div className={`styles["view-tittle"] col`}>
                <form id="matterForm" onSubmit={handleSubmit}>
                  <TabStrip
                    keepTabsMounted={true}
                    selected={selected}
                    onSelect={handleSelect}
                    className="mt-3 pagetab"
                  >
                    <TabStripTab
                      title={
                        <PageTabItemTitle
                          className="mb-sm-42 mb-xl-0"
                          title="Matter Information"
                          index={0}
                          selected={selected}
                        />
                      }
                    >
                      <div className="container-fluid ps-1 pb-2">
                        {loading ? (
                          <Loader />
                        ) : (
                          <div className="row g-2 mt-3">
                            <div className="col-12 col-xl-8">
                              <ContainerCard
                                height={"100%"}
                                width={"100%"}
                                className="p-3 ps-4 pe-4 pt-2 pb-0 overflow-auto"
                              >
                                <div className="px-2">
                                  <div
                                    className="d-flex align-items-center justify-content-between"
                                    style={{ height: "50px" }}
                                  >
                                    <div
                                      className={`${styles["section-title"]}`}
                                    >
                                      Matter Details
                                    </div>
                                  </div>
                                  <hr className="mt-0" />

                                  <div className="container">
                                    <div className="row">
                                      <div className="col-5">
                                        <FormComboBox
                                          className=""
                                          fc={true}
                                          name={CONSTANT.COMBOBOX.CLIENT}
                                          val={
                                            comboBoxState[
                                              CONSTANT.COMBOBOX.CLIENT
                                            ]
                                          }
                                          onChangeClient={handleClientChange}
                                          wf={true}
                                          isStyled={true}
                                          // className={"mt-3 "}
                                          width={"260px"}
                                          boxShadow={true}
                                        />
                                      </div>
                                      <div className="col"></div>
                                      <div className="col-6">
                                        <FormComboBox
                                          isStyled={true}
                                          wf={true}
                                          data={branchesList}
                                          textField="branchName"
                                          width={"260px"}
                                          boxShadow={true}
                                          state={comboBoxState}
                                          name={CONSTANT.COMBOBOX.BRANCH}
                                          onChangeComboBox={
                                            handleComboBoxChange
                                          }
                                        />
                                      </div>
                                    </div>
                                    <div className="row mt-3">
                                      <div className="d-flex flex-column col-5">
                                        <FormInput
                                          required={true}
                                          labelWidth
                                          wf={true}
                                          labelForComboBox={"Reference"}
                                          isStyled={true}
                                          // className={"mt-3 "}
                                          width={"260px"}
                                          boxShadow={true}
                                          state={inputState}
                                          label={CONSTANT.INPUT.MATTER_REF}
                                          onChangeInput={handleInputChange}
                                        />
                                      </div>
                                    </div>

                                    <div className="row mt-3">
                                      <div className="d-flex flex-column">
                                        <Label className={`label mb-1`}>
                                          Description
                                        </Label>
                                        <TextArea
                                          required
                                          placeholder="Enter Details"
                                          autoSize={true}
                                          value={details}
                                          style={{
                                            height: "62px",
                                            minWidth: "100%",
                                          }}
                                          onChange={(e) => setDetails(e.value)}
                                        />
                                      </div>
                                    </div>

                                    <div className="row mt-3">
                                      <div className="col-5">
                                        <FormComboBox
                                          wf={true}
                                          isStyled={true}
                                          width={"260px"}
                                          onChangeComboBox={
                                            handleComboBoxChange
                                          }
                                          onFilterChange={feeEarnerfilterChange}
                                          data={prepareFeeearnerData(feeEarner)}
                                          textField="representation"
                                          state={comboBoxState}
                                          name={CONSTANT.COMBOBOX.FEE_EARNER}
                                        />
                                      </div>
                                      <div className="col"></div>
                                      <div className="col-6">
                                        <FormDate
                                          className={
                                            "form-date-picker note-date-picker "
                                          }
                                          wf={true}
                                          isStyled={true}
                                          width={"260px"}
                                          label={"Opened"}
                                          onChangeDate={handleDateChange}
                                          state={dateState}
                                        />
                                      </div>
                                    </div>

                                    <div className="row mt-3">
                                      <div className="col-5">
                                        <FormComboBox
                                          wf={true}
                                          isStyled={true}
                                          width={"260px"}
                                          onChangeComboBox={
                                            handleComboBoxChange
                                          }
                                          onFilterChange={
                                            superVisorfilterChange
                                          }
                                          data={superVisor}
                                          textField="displayname"
                                          state={comboBoxState}
                                          name={CONSTANT.COMBOBOX.SUPERVISIOR}
                                        />
                                      </div>
                                      <div className="col"></div>
                                      <div className="col-6">
                                        <FormComboBox
                                          wf={true}
                                          isStyled={true}
                                          width={"260px"}
                                          onChangeComboBox={
                                            handleComboBoxChange
                                          }
                                          textField="name"
                                          onFilterChange={
                                            departmentfilterChange
                                          }
                                          data={departmentFilter}
                                          state={comboBoxState}
                                          name={CONSTANT.COMBOBOX.DEPARTMENT}
                                        />
                                      </div>
                                    </div>

                                    <div className="row mt-3">
                                      <div className="col-5">
                                        <FormComboBox
                                          onChangeComboBox={
                                            handleComboBoxChange
                                          }
                                          wf={true}
                                          isStyled={true}
                                          width={"260px"}
                                          onFilterChange={
                                            applicationfilterChange
                                          }
                                          textField="representation"
                                          data={applicationFilter}
                                          state={comboBoxState}
                                          name={CONSTANT.COMBOBOX.APPLICATION}
                                        />
                                      </div>
                                      <div className="col"></div>
                                      <div className="col-6">
                                        <div className="d-flex align-items-center gap-2">
                                          <FormComboBox
                                            wf={true}
                                            isStyled={true}
                                            width={"260px"}
                                            onChangeComboBox={
                                              handleComboBoxChange
                                            }
                                            onFilterChange={
                                              cateforyFilterChange
                                            }
                                            data={categoryFilter.filter(
                                              (item) =>
                                                item.departmentId ===
                                                comboBoxState[
                                                  CONSTANT.COMBOBOX.DEPARTMENT
                                                ].departmentID
                                            )}
                                            state={comboBoxState}
                                            textField="name"
                                            name={CONSTANT.COMBOBOX.CATEGORY}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </ContainerCard>
                            </div>

                            <div className="col-12 col-xl-4 ">
                              <Tile
                                heading={"Rates"}
                                height={"300px"}
                                className={`${styles["section-title"]} px-4 pt-2 pb-0 overflow-auto`}
                              >
                                <div className="px-2">
                                  <div className="ms-3 mt-3 right-align">
                                    <Label className="mb-1 label">
                                      Estimate Fee
                                    </Label>
                                    <div className=" d-flex  align-items-center ">
                                      <NumericTextBox
                                        id="netAmount"
                                        onChange={(e) =>
                                          handleInputChange(
                                            e.value,
                                            "estimateValue"
                                          )
                                        }
                                        value={inputState["estimateValue"]}
                                        style={{
                                          width: "265px",
                                          height: "32px",
                                        }}
                                        className="me-2 pe-2"
                                        spinners={false}
                                        format="n2"
                                        placeholder="0.00"
                                      />
                                      <img
                                        src={EstimateFeeImage}
                                        className="cursor-pointer"
                                        width={18}
                                        height={18}
                                        onClick={() =>
                                          setShowEstimatedFee(true)
                                        }
                                      />
                                    </div>
                                    {showEstimatedFee && (
                                      <EstimateFee
                                        cancelEdit={() =>
                                          setShowEstimatedFee(false)
                                        }
                                        matter={
                                          matterDetails?.matterViewDetails[
                                            props.reference
                                          ]?.data?.matterId
                                        }
                                      />
                                    )}
                                  </div>
                                  <div className="d-flex align-items-center mt-3 ms-3">
                                    <FormComboBox
                                      placeholder={"Select Rate Type"}
                                      iconClassName="wa"
                                      wf={true}
                                      isStyled={true}
                                      width={"265px"}
                                      data={[
                                        {
                                          rateType: "FeeEarnerDefault",
                                          representation: "Fee Earner Default",
                                        },
                                        {
                                          rateType: "MatterFlatRate",
                                          representation: "Matter Flat Rate",
                                        },
                                        {
                                          rateType: "RateCategory",
                                          representation: "Rate Category",
                                        },
                                      ]}
                                      textField="representation"
                                      state={comboBoxState}
                                      name={CONSTANT.COMBOBOX.RATE_TYPE}
                                      onChangeComboBox={handleComboBoxChange}
                                      val={
                                        comboBoxState[
                                          CONSTANT.COMBOBOX.RATE_TYPE
                                        ]
                                      }
                                    />
                                  </div>

                                  {comboBoxState[CONSTANT.COMBOBOX.RATE_TYPE]
                                    ?.rateType === "MatterFlatRate" && (
                                    <div className="mt-3 ms-3">
                                      <Label className="mb-1 label d-block">
                                        Fix Rate
                                      </Label>
                                      <NumericTextBox
                                        id="netAmount"
                                        onChange={(e) =>
                                          handleInputChange(
                                            e.value,
                                            "rateValue"
                                          )
                                        }
                                        value={inputState["rateValue"]}
                                        style={{
                                          width: "265px",
                                          height: "32px",
                                        }}
                                        className="right-align pe-2"
                                        spinners={false}
                                        format="n2"
                                        placeholder="0.00"
                                      />
                                    </div>
                                  )}
                                </div>
                              </Tile>

                              <div className="mt-2">
                                <ContainerCard
                                  height={"280px"}
                                  className="p-3 ps-4 pe-4 pt-2 pb-0 overflow-auto"
                                >
                                  <div className="px-2">
                                    <div
                                      className="d-flex align-items-center justify-content-between"
                                      style={{ height: "50px" }}
                                    >
                                      <div
                                        className={`${styles["section-title"]}`}
                                      >
                                        Marketing
                                      </div>
                                    </div>
                                    <hr className="mt-0" />
                                    <div className="container">
                                      <div className="row">
                                        <FormComboBox
                                          onChangeComboBox={
                                            handleComboBoxChange
                                          }
                                          wf={true}
                                          isStyled={true}
                                          width={"280px"}
                                          textField="sourceName"
                                          data={activeSourcesData}
                                          state={comboBoxState}
                                          name={CONSTANT.COMBOBOX.SOURCE}
                                        />
                                      </div>
                                      <div className="row">
                                        <FormComboBox
                                          onChangeComboBox={
                                            handleComboBoxChange
                                          }
                                          wf={true}
                                          isStyled={true}
                                          className={"mt-3"}
                                          width={"280px"}
                                          onFilterChange={
                                            applicationfilterChange
                                          }
                                          textField="referrerName"
                                          data={activeReferrersData}
                                          state={comboBoxState}
                                          name={CONSTANT.COMBOBOX.REFERRRER}
                                        />
                                      </div>
                                      {formType === "ADD" && (
                                        <div className="row mt-3 right-align">
                                          <Label className="mb-1 label">
                                            Estimate Fee
                                          </Label>
                                          <NumericTextBox
                                            id="netAmount"
                                            onChange={(e) =>
                                              handleInputChange(
                                                e.value,
                                                "estimateValue"
                                              )
                                            }
                                            value={inputState["estimateValue"]}
                                            style={{
                                              width: "280px",
                                              height: "32px",
                                              marginLeft: "12px",
                                            }}
                                            // className="ms-3"
                                            spinners={false}
                                            format="n2"
                                            placeholder="0.00"
                                          />
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </ContainerCard>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="container"></div>
                    </TabStripTab>
                    {formType === "EDIT" && (
                      <TabStripTab
                        title={
                          <PageTabItemTitle
                            title="Completion"
                            index={1}
                            selected={selected}
                          />
                        }
                      >
                        <div
                          className="container pb-2 "
                          style={{ width: "1130px" }}
                        >
                          <div className="row g-2">
                            <div className="col-6 pt-3">
                              <ContainerCard
                                height={"300px"}
                                className="p-3 ps-4 pe-4 pt-2 pb-0 overflow-auto"
                              >
                                <div
                                  className="d-flex align-items-center justify-content-between"
                                  style={{ height: "50px" }}
                                >
                                  <div className={`${styles["section-title"]}`}>
                                    Completion
                                  </div>
                                </div>
                                <hr className="mt-0" />
                                <Checkbox
                                  label="Closed"
                                  value={closed}
                                  onChange={(e) => setClosed(e.value)}
                                />
                                <FormDate
                                  disabled={!closed}
                                  wf={true}
                                  isStyled={true}
                                  className={
                                    "mt-3 form-date-picker note-date-picker"
                                  }
                                  width={"260px"}
                                  label={CONSTANT.DATE.CLOSED_DATE}
                                  onChangeDate={handleDateChange}
                                  state={dateState}
                                />
                                <FormInput
                                  state={inputState}
                                  className={"mt-3"}
                                  wf={true}
                                  isStyled={true}
                                  width={"260px"}
                                  label={CONSTANT.INPUT.LOCATION}
                                  onChangeInput={handleInputChange}
                                />
                              </ContainerCard>
                            </div>{" "}
                            <div className="col-6 pt-3">
                              <ContainerCard
                                height={"300px"}
                                className="p-3 ps-4 pe-4 pt-2 pb-0 overflow-auto"
                              >
                                <div
                                  className="d-flex align-items-center justify-content-between"
                                  style={{ height: "50px" }}
                                >
                                  <div className={`${styles["section-title"]}`}>
                                    GDRP
                                  </div>
                                </div>
                                <hr className="mt-0" />
                                <Checkbox
                                  label="Deleted"
                                  onChange={(e) => setDeleted(e.value)}
                                />
                                <FormDate
                                  disabled={!deleted}
                                  className={
                                    "mt-3 form-date-picker note-date-picker"
                                  }
                                  wf={true}
                                  isStyled={true}
                                  width={"260px"}
                                  label={CONSTANT.DATE.DELETED_DATE}
                                  onChangeDate={handleDateChange}
                                  state={dateState}
                                />
                              </ContainerCard>
                            </div>
                          </div>
                        </div>
                      </TabStripTab>
                    )}

                    <TabStripTab
                      title={
                        <PageTabItemTitle
                          title="Credit & Defaults"
                          index={formType == "EDIT" ? 2 : 1}
                          selected={selected}
                        />
                      }
                    >
                      <div
                        className="container pb-2 pt-3 "
                        style={{ width: "100%" }}
                      >
                        <div className="row g-2">
                          <div className="col-12 col-xl-6">
                            <ContainerCard
                              height={"280px"}
                              className="p-3 ps-4 pe-4 pt-2 pb-0 overflow-auto"
                            >
                              <div
                                className="d-flex align-items-center justify-content-between"
                                style={{ height: "50px" }}
                              >
                                <div className={`${styles["section-title"]}`}>
                                  Credit Limit
                                </div>
                              </div>
                              <hr className="mt-0" />
                              <div>
                                <FormInput
                                  wf={true}
                                  width={"260px"}
                                  isStyled={true}
                                  type="number"
                                  state={inputState}
                                  label={CONSTANT.INPUT.WIP_CREDIT_LIMIT}
                                  onChangeInput={handleInputChange}
                                />
                                <FormInput
                                  type="number"
                                  className={"mt-3"}
                                  wf={true}
                                  width={"260px"}
                                  isStyled={true}
                                  state={inputState}
                                  label={CONSTANT.INPUT.DISP_CREDIT_LIMIT}
                                  onChangeInput={handleInputChange}
                                />
                                <FormInput
                                  type="number"
                                  className={"mt-3"}
                                  wf={true}
                                  width={"260px"}
                                  isStyled={true}
                                  state={inputState}
                                  label={CONSTANT.INPUT.BILL_CREDIT_LIMIT}
                                  onChangeInput={handleInputChange}
                                />
                              </div>
                            </ContainerCard>
                          </div>
                          <div className="col-12 col-xl-6">
                            <ContainerCard
                              height={"280px"}
                              className="p-3 ps-4 pe-4 pt-2 pb-0 overflow-auto"
                            >
                              <div
                                className="d-flex align-items-center justify-content-between"
                                style={{ height: "50px" }}
                              >
                                <div className={`${styles["section-title"]}`}>
                                  Default Codes
                                </div>
                              </div>
                              <hr className="mt-0" />
                              <div>
                                <FormComboBox
                                  wf={true}
                                  width={"260px"}
                                  isStyled={true}
                                  onChangeComboBox={handleComboBoxChange}
                                  textField="nominalName"
                                  data={nominalDefaultDDABank.data}
                                  state={comboBoxState}
                                  name={CONSTANT.COMBOBOX.DDA_BANK}
                                />
                                <FormComboBox
                                  className={"mt-3"}
                                  wf={true}
                                  isStyled={true}
                                  width={"260px"}
                                  onChangeComboBox={handleComboBoxChange}
                                  onFilterChange={profitAndLossfilterChange}
                                  data={profitAndLoss}
                                  textField="nominalName"
                                  state={comboBoxState}
                                  name={CONSTANT.COMBOBOX.PROFIT_COST_NOMINAL}
                                />
                              </div>
                            </ContainerCard>
                          </div>
                        </div>

                        <div className="row g-2 mt-0">
                          <div className="col-12 col-xl-6">
                            <ContainerCard className="mt-1 p-3 ps-4 pe-4 pt-2 pb-0 overflow-auto">
                              <MatterPermissions
                                isView={false}
                                accessibleUsers={accessibleUsers}
                                setAccessibleUsers={setAccessibleUsers}
                                matterId={
                                  matterDetails?.matterViewDetails[
                                    props.reference
                                  ]?.data?.matterId
                                }
                              />
                            </ContainerCard>
                          </div>
                        </div>
                      </div>
                    </TabStripTab>

                    <TabStripTab
                      title={
                        <PageTabItemTitle
                          title="Other Side"
                          index={formType == "EDIT" ? 4 : 2}
                          selected={selected}
                        />
                      }
                    >
                      <div
                        className="container pb-2 pt-3 "
                        style={{ width: "100%" }}
                      >
                        <div className="row g-2">
                          <div className="col-12 col-xl-6">
                            <ContainerCard
                              height={"400px"}
                              className="p-3 ps-4 pe-4 pt-2 pb-0 overflow-auto"
                            >
                              <div
                                className="d-flex align-items-center justify-content-between"
                                style={{ height: "50px" }}
                              >
                                <div className={`${styles["section-title"]}`}>
                                  Other Side 1
                                </div>
                              </div>
                              <hr className="mt-0" />
                              <div>
                                <FormInput
                                  labelWidth
                                  wf={true}
                                  labelForComboBox={"First Name"}
                                  isStyled={true}
                                  className={"mt-2 "}
                                  ph={"Enter Name"}
                                  label={CONSTANT.INPUT.FIRST_NAME_OS1}
                                  width={"260px"}
                                  state={inputState}
                                  //   label={""}
                                  onChangeInput={handleInputChange}
                                />
                                <FormInput
                                  labelWidth
                                  wf={true}
                                  labelForComboBox={"Last Name"}
                                  isStyled={true}
                                  className={"mt-3 "}
                                  label={CONSTANT.INPUT.LAST_NAME_OS1}
                                  width={"260px"}
                                  state={inputState}
                                  ph={"Enter Name"}
                                  onChangeInput={handleInputChange}
                                />
                                <FormDate
                                  className={
                                    "form-date-picker note-date-picker mt-3"
                                  }
                                  wf={true}
                                  isStyled={true}
                                  customLabel={"Date of Birth"}
                                  width={"260px"}
                                  label={CONSTANT.DATE.DOB_OS1}
                                  onChangeDate={handleDateChange}
                                  state={dateState}
                                />
                                <FormInput
                                  labelWidth
                                  wf={true}
                                  labelForComboBox={"Post Code"}
                                  isStyled={true}
                                  className={"mt-3 "}
                                  differentName={true}
                                  name={CONSTANT.INPUT.POST_CODE_OS1}
                                  ph={"Enter Code"}
                                  width={"260px"}
                                  state={inputState}
                                  label={""}
                                  onChangeInput={handleInputChange}
                                />
                              </div>
                            </ContainerCard>
                          </div>
                          <div className="col-12 col-xl-6">
                            <ContainerCard
                              height={"400px"}
                              className="p-3 ps-4 pe-4 pt-2 pb-0 overflow-auto"
                            >
                              <div
                                className="d-flex align-items-center justify-content-between"
                                style={{ height: "50px" }}
                              >
                                <div className={`${styles["section-title"]}`}>
                                  Other Side 2
                                </div>
                              </div>
                              <hr className="mt-0" />
                              <div>
                                <FormInput
                                  labelWidth
                                  wf={true}
                                  labelForComboBox={"First Name"}
                                  isStyled={true}
                                  className={"mt-2 "}
                                  label={CONSTANT.INPUT.FIRST_NAME_OS2}
                                  width={"260px"}
                                  ph={"Enter Name"}
                                  state={inputState}
                                  //   label={""}
                                  onChangeInput={handleInputChange}
                                />
                                <FormInput
                                  labelWidth
                                  wf={true}
                                  labelForComboBox={"Last Name"}
                                  isStyled={true}
                                  className={"mt-3 "}
                                  label={CONSTANT.INPUT.LAST_NAME_OS2}
                                  width={"260px"}
                                  ph={"Enter Name"}
                                  state={inputState}
                                  onChangeInput={handleInputChange}
                                />
                                <FormDate
                                  className={
                                    "form-date-picker note-date-picker mt-3"
                                  }
                                  wf={true}
                                  isStyled={true}
                                  customLabel={"Date of Birth"}
                                  width={"260px"}
                                  label={CONSTANT.DATE.DOB_OS2}
                                  onChangeDate={handleDateChange}
                                  state={dateState}
                                />
                                <FormInput
                                  labelWidth
                                  wf={true}
                                  labelForComboBox={"Post Code"}
                                  isStyled={true}
                                  className={"mt-3 "}
                                  differentName={true}
                                  name={CONSTANT.INPUT.POST_CODE_OS2}
                                  width={"260px"}
                                  ph={"Enter Code"}
                                  state={inputState}
                                  label={""}
                                  onChangeInput={handleInputChange}
                                />
                              </div>
                            </ContainerCard>
                          </div>
                        </div>
                      </div>
                    </TabStripTab>
                  </TabStrip>
                </form>
              </div>
            </div>
          </div>

          <DialogActionsBar>
            <div className="dialog-footer">
              <Button
                className="common-btn bgBtnSecondary border-0 me-2"
                onClick={props.setshowMatterForm}
              >
                Cancel
              </Button>
              <Button
                className="common-btn me-4"
                type="submit"
                // onClick={() => {
                //   if (formType === "VIEW") setFormType("EDIT");
                //   else document.getElementById("matterForm")?.submit();
                // }}
                form="matterForm"
              >
                {formType === "VIEW"
                  ? "Edit"
                  : formType === "EDIT"
                  ? "Update Matter"
                  : "Add Matter"}
              </Button>
            </div>
          </DialogActionsBar>
        </Dialog>
      )}
      {addClient && (
        <Dialog
          title={"Add Client"}
          width={1350}
          onClose={() => {
            setAddClient(false);
          }}
          open={() => {
            setAddClient(true);
          }}
        >
          <div className={`container p-0 `}>
            <AddClients
              formId={"add"}
              title={`Add Client`}
              component={"Add Matters"}
              onChangeClient={(e) =>
                handleClientChange({
                  ContactName: e.name,
                  CustomerID: e.id,
                  CustomerRef: e.ref,
                })
              }
              onClose={() => {
                setAddClient(false);
              }}
              open={() => {
                setAddClient(true);
              }}
            />
          </div>
          <div className="dialog-footer mt-2">
            <Button
              className="common-btn bgBtnSecondary border-0 me-4"
              onClick={() => {
                setAddClient(false);
              }}
            >
              Cancel
            </Button>
            <Button className="common-btn me-4" type="submit" form={"add"}>
              {"Add Client"}
            </Button>
          </div>
        </Dialog>
      )}

      <MatterModal
        showModal={showModal}
        message={modalMessage}
        success={modalSuccess}
        onClose={handleModalClose}
      />
    </div>
  );
};

export default MatterForm;
