import { Checkbox } from "@progress/kendo-react-inputs";
import styles from "./Settings.module.scss";
import image from "../../assets/image 48.png";
import { AiFillEdit } from "react-icons/ai";
import EditIcon from "../../assets/edit.png";

export const SignatureCell = (props) => {
  const color = props.dataIndex % 2 === 0;
  return (
    <td>
      <span
        className={
          styles[`active-circle`] +
          " " +
          (color ? styles["odd"] : styles["even"]) +
          " d-flex align-items-center justify-content-center"
        }
      >
        <span
          className={
            styles[`icon-design `] +
            " " +
            (color ? styles["odd"] : styles["even"])
          }
        >
          {props.dataItem[props.field]}
        </span>
      </span>
    </td>
  );
};

export const ActiveCell = (props) => {
  return (
    <td className={`${styles[`checkbox`] + " " + styles[`marginLeft`]}`}>
      <Checkbox
        checked={props.dataItem.active === true ? true : false}
        readOnly={true}
      />
    </td>
  );
};

export const AllowedDocumentCell = (props) => {
  return (
    <td className={`${styles[`checkbox`] + " " + styles[`marginLeft`]} `}>
      <Checkbox
        checked={props.dataItem.allowDocument === true ? true : false}
        readOnly={true}
      />
    </td>
  );
};

export const AllowedTypeCell = (props) => {
  return (
    <td className={`${styles[`checkbox`] + " " + styles[`marginLeft`]}`}>
      <Checkbox
        checked={props.dataItem.allowTime === true ? true : false}
        readOnly={true}
      />
    </td>
  );
};

export const EditCommandCell = (props) => {
  return (
    <td>
      <img
        src={EditIcon}
        className="cursor-pointer"
        onClick={() => {
          props.enterEdit(props.dataItem);
        }}
      />
    </td>
  );
};
