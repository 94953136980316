import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "@progress/kendo-react-intl";
import "@progress/kendo-react-dropdowns";
import "react-router-dom";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { RedirectCell } from "components/common/GridComponents";
import { getContactsonMatter } from "../../actions/contacts.action";
import AddNewContact from "../Contacts/AddViewContact";
import AttachContactPage from "pages/AttachContacts/AttachContactPage";

const CaseContactsPage = (props) => {
  const [contactList, setContactList] = React.useState({
    data: [],
    total: 20,
  });

  const [dataState, setDataState] = React.useState({
    take: 10,
    skip: 0,
    sort: [
      {
        field: "date",
        dir: "asc",
      },
    ],
  });

  const [contactInfo, setContactInfo] = useState({ type: "", contactId: "" });
  const [formView, setFormView] = React.useState(false);
  const contactsList = useSelector(
    (state) => state.contactsList?.contactsOnMatter?.data
  );

  const dispatch = useDispatch();

  const getAllContactsonMatter = async () => {
    dispatch(getContactsonMatter(props.matterId));
  };

  useEffect(() => {
    if ( props.activeItem === 4) {
    getAllContactsonMatter()
    }
    if (props?.refreshClicked && props.activeItem === 4) {
      props?.setRefreshClicked(false);
    }
  }, [props.refreshClicked]);

  useEffect(() => {
    setContactList(contactsList);
  }, [contactsList?.length]);

  const createState = (skip, take) => {
    let pagerSettings = {
      pageSizes: [10, 25, 50, 100],
    };
    return {
      pageSize: take,
      pageable: pagerSettings,
    };
  };
  const [state, setState] = React.useState(createState(0, 10));

  const dataStateChange = (e) => {
    if (
      e.dataState.sort !== undefined &&
      e.dataState.skip !== 0 &&
      dataState.sort !== e.dataState.sort
    ) {
      setDataState({ ...e.dataState, skip: 0 });
    } else if (e.dataState.sort[0] === undefined) {
      setDataState({
        ...e.dataState,
        sort: [
          {
            field: dataState.sort[0].field,
            dir: "asc",
          },
        ],
      });
    } else {
      setDataState(e.dataState);
    }
  };

  const handleGridClick = (type, contactId) => {
    setContactInfo({ type, contactId });
    setFormView(true);
  };

  const Redirect = (props) => (
    <RedirectCell
      handleClick={() =>
        handleGridClick(props.dataItem?.type, props?.dataItem?.contactId)
      }
      title="View Contact"
    />
  );

  const CustomCell = (props) => {
    const item = props.dataItem;
    const references = [item.reference1, item.reference2, item.reference3]
      .filter(Boolean)
      .join(" / ");
    return <td>{references}</td>;
  };

  return (
    <div className="container-fluid p-0 mt-3">
      <Grid
        {...dataState}
        data={contactList}
        onDataStateChange={dataStateChange}
        sortable={true}
        className="fs-md mt-3"
        pageable={state.pageable}
        pageSize={state.pageSize}
        style={{ minHeight: "calc(100vh - 250px)" }}
      >
        <GridColumn width="150" field="name" title="Name" />
        <GridColumn width="120" field="phone" title="Phone" />
        <GridColumn width="150" field="email" title="Email" />
        <GridColumn width="120" field="fullAddress" title="Address" />
        <GridColumn
          width="120"
          field="correspondenceFirstName"
          title="Corr Person"
        />
        <GridColumn
          width="120"
          field="correspondenceEmail"
          title="Corr Email"
        />
        <GridColumn
          width="120"
          field="correspondencePhone"
          title="Corr Phone"
        />
        <GridColumn width="120" cell={CustomCell} title="References" />

        <GridColumn width="40" cell={Redirect} />
      </Grid>

      {formView && (
        <AddNewContact
          attachContact={true}
          type={contactInfo.type}
          viewMode={true}
          contactId={contactInfo.contactId}
          close={() => {
            // setshowAddNewForm(false);
            setFormView(false);
          }}
        />
      )}

      {props.attachContact && (
        <AttachContactPage
          contactsData={getAllContactsonMatter}
          visible={props.attachContact}
          matterId={props?.matterId}
          toggleDialog={() => props?.setAttachContact(!props?.attachContact)}
        />
      )}
    </div>
  );
};
export default CaseContactsPage;
