
export const configurationIdentityServer = {
    client_id: "LinqWebApp01",
    redirect_uri: window.location.origin + "/callback",
    silent_redirect_uri: window.location.origin + "/authentication/callback",
    scope: "openid api1.access",
    authority: "https://auth.linq.legal",
    // service_worker_relative_url: "/OidcServiceWorker.js",
    service_worker_only: false,
};
