import { ConnectionStatus } from "pages/Settings/Integrations/IntegrationCard";
import React from "react";
import styles from "./index.module.scss";

export default function SectionDivider({ sectionName, component }) {
  return (
    <div className="row mt-4 mb-3">
      <div className={`col-3 ${component === "Clio" && "col-8"}`}>
        <span className={styles["section-title"]}> {sectionName}</span>
      </div>
      <div
        className={`col-9 d-flex align-items-center ${
          component === "Clio" && "col-4"
        }`}
      >
        {component === "Clio" ? (
          <></>
        ) : (
          <div className="w-100  border-bottom border-opacity-50"></div>
        )}
      </div>
    </div>
  );
}

export const SectionDividerFull = () => {
  return (
    <div className="row mt-4 mb-3">
      <div className="col d-flex align-items-center">
        <div className="w-100  border-bottom border-opacity-50"></div>
      </div>
    </div>
  );
};
