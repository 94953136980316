import {
  START_LOADING,
  STOP_LOADING,
  SUCCESS,
  GET_DOC_TYPE_LIST,
} from "../types/index";
import * as api from "../api/index.js";
import { handleApiError } from "Helper";
import { toast } from "react-toastify";

export const getDocTypes = (page, limit) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const datas = await api.getDocTypes();
    dispatch({ type: GET_DOC_TYPE_LIST, payload: datas.data });
    dispatch({ type: STOP_LOADING });
    dispatch({ type: SUCCESS, payload: datas.message });
  } catch (error) {
    handleApiError(error);
  }
};

export const editDocType = (id, data) => async (dispatch) => {
  try {
    await api.editDocType(id, data);
    toast.success(`Document Type '${data?.typeName}' has been updated`);
    const dataResponse = await api.getDocTypes();
    dispatch({ type: GET_DOC_TYPE_LIST, payload: dataResponse.data });
  } catch (error) {
    console.log(error);
    toast.error(`Error occured while updating`);
    handleApiError(error);
  }
};

export const addNewDocType = (data) => async (dispatch) => {
  try {
    await api.addNewDocType(data);
    dispatch({ type: GET_DOC_TYPE_LIST, payload: [] });
  } catch (error) {
    handleApiError(error);
  }
};
