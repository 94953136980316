import * as React from "react";
import OAuth2Login from 'react-simple-oauth2-login';
import styles from "../../pages/Settings/Settings.module.scss"



const Auth2Authentication = ({ credentials, onSuccess, onFailure, buttonText }) => {
    return (
        <OAuth2Login
            authorizationUrl={credentials?.authenticationBaseURL}
            responseType={credentials.responseType}
            clientId={credentials?.clientId}
            className={`common-btn k-button ${styles[`hmrcbutton`]}`}
            scope={credentials?.scope}
            redirectUri={window.location.origin + "/oauth-callback"}
            buttonText={buttonText}
            onSuccess={onSuccess}
            onFailure={onFailure} />
    );

}

export default Auth2Authentication;