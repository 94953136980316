import * as React from "react";
import { Button } from "@progress/kendo-react-buttons";
import styles from "../Settings.module.scss";
import * as api from "../../../api/index";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import EditDocType from "./EditDocTypes";
import { getDocTypes } from "actions/docTypes.action";
import DocTypeList from "./DocTypesList";

const DocTypes = (props) => {
  const [addnewform, setaddnewform] = React.useState(false);
  const dispatch = useDispatch();

  const closeform = () => {
    setaddnewform(false);
    // props.onCancel();
  };
  React.useEffect(() => {
    if (props.onClose === false) {
      closeform();
    }
  }, [props.onClose]);
  const handleSubmit = async (e) => {
    setaddnewform(false);
    try {
      await api.addNewDocType({
        active: true,
        ...e,
      });
      toast.success("Document Type Added");
      dispatch(getDocTypes());
    } catch (error) {
      toast.error("Error Occured");
    }
  };

  return (
    <React.Fragment>
      <>
        <div className="row">
          <div className="col-lg-10 col-md-8"></div>
          <Button
            className={`common-btn col-lg-1 col-md-4 p-2 mt-5 ${
              styles[`button`]
            }`}
            onClick={() => {
              setaddnewform(true);
            }}
          >
            Add <br></br>
            Document Type
          </Button>
        </div>
        <div className={`row mx-auto mt-3 w-50`}>
          <DocTypeList />
        </div>
      </>
      {addnewform && (
        <EditDocType
          cancelEdit={closeform}
          onSubmit={handleSubmit}
          type="addNew"
        />
      )}
    </React.Fragment>
  );
};

export default DocTypes;
