import {
  START_LOADING,
  STOP_LOADING,
  ERROR,
  SUCCESS,
  ADD_MATTER_TIME_BILL,
  CLEAR_MATTER_TIME,
  BILL_ERROR,
  CLEAR_BILL_ERROR,
  CREATE_BILL,
  ADD_MATTER_DISB_BILL,
  CLEAR_MATTER_DISB_TIME,
} from "../types/index";
import * as api from "../api/index.js";
import { handleApiError } from "Helper";

export const getMatterTimeRecords = (matterId) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const data = await api.getMatterTimeRecords(matterId);
    dispatch({ type: ADD_MATTER_TIME_BILL, payload: data.data.data });
    dispatch({ type: STOP_LOADING });
    dispatch({ type: SUCCESS, payload: data.message });
  } catch (error) {handleApiError(error)}
};

export const getMatterDisbRecords = (matterId) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const data = await api.getMatterDisbRecords(matterId);
    dispatch({ type: ADD_MATTER_DISB_BILL, payload: data.data.data });
    dispatch({ type: STOP_LOADING });
    dispatch({ type: SUCCESS, payload: data.message });
  } catch (error) { handleApiError(error) }
};

export const postBill = (datatosend) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const data = await api.postBill(datatosend);
    dispatch({ type: CREATE_BILL, payload: data.data });
    dispatch({ type: STOP_LOADING });
    dispatch({ type: SUCCESS, payload: data.message });
  } catch (error) {
    handleApiError(error)
    dispatch({ type: BILL_ERROR, payload: error });
    
  }
};

export const clearMatterDisbRecords = (data) => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_MATTER_DISB_TIME });
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const clearMatterTimeRecords = (data) => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_MATTER_TIME });
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const clearBillError = () => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    dispatch({ type: CLEAR_BILL_ERROR });
    dispatch({ type: STOP_LOADING });
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};
