import * as React from "react";
import { Input, Checkbox } from "@progress/kendo-react-inputs";
import EditForm from "../../../components/Setting/EditForm";

const EditActivity = (props) => {
  return (
    <EditForm
      type={props.type}
      item={props.item}
      onSubmit={props.onSubmit}
      cancelEdit={props.cancelEdit}
      fields={[
        {
          name: "name",
          component: Input,
          label: "Name",
          validationMessage: "Name is required",
        },
        {
          name: "code",
          component: Input,
          label: "Code",
          validationMessage: "Code is required",
        },
        {
          name: "active",
          component: Checkbox,
          label: "Active",
          defaultChecked: true,
        },
        { name: "allowTime", component: Checkbox, label: "Time" },
        { name: "allowDocument", component: Checkbox, label: "Documents" },
      ]}
      formId="activity-form"
      title="Activity Type"
    />
  );
};

export default EditActivity;
