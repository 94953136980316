export const insertItem = (data, item) => {
  data.unshift(item);

  return data;
};

export const deleteItem = (data, item) => {
  let index = data.findIndex(
    (record) => record.contactLinkTypeID === item.contactLinkTypeID
  );
  data.splice(index, 1);
  return data;
};
