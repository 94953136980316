import * as React from "react";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { ProductsLoader } from "./users-loader";
import EditForm from "./Editform";
import { Button } from "@progress/kendo-react-buttons";
import { Dialog } from "@progress/kendo-react-dialogs";
import { ActiveCell } from "../GridComponents";
import * as api from "api/index";
import { editUserinfo, getUsersList } from "actions/users.action";
import { useDispatch, useSelector } from "react-redux";
import styles from "../Settings.module.scss";
import { clearProfilePhoto, getProfilePhoto } from "actions/currentuser.action";
import { SignatureCell, EditCommandCell } from "../GridComponents";
import { Checkbox } from "@progress/kendo-react-inputs";
import Modal from "../Modal";
import { useRef } from "react";
import SearchTextBox from "components/SearchComponent";
import { addnewUser } from "actions/users.action";
import { handleApiError } from "Helper";
import { toast } from "react-toastify";

export const AdminCell = (props) => {
  return (
    <td className={`${styles[`checkbox`] + " " + styles[`marginLeft`]}`}>
      <Checkbox
        type={"checkbox"}
        checked={props.dataItem.isAdmin === true ? true : false}
        readOnly={true}
      />
    </td>
  );
};
export const FeeearnerCell = (props) => {
  return (
    <td className={`${styles[`checkbox`] + " " + styles[`marginLeft`]}`}>
      <Checkbox
        type={"checkbox"}
        checked={props.dataItem.isFeeEarner === true ? true : false}
        readOnly={true}
      />
    </td>
  );
};

const UsersList = (props) => {
  const editProfilePhoto = useSelector(
    (state) => state.currentuser.editUsersProfilePhoto
  );

  const [openForm, setOpenForm] = React.useState(false);
  const [isRefreshed, setIsRefreshed] = React.useState(false);
  const dispatch = useDispatch();
  const [editItem, setEditItem] = React.useState({
    ProductID: 1,
  });

  const createState = (skip, take) => {
    let pagerSettings = {
      pageSizes: [10, 25, 50, 100],
    };
    return {
      pageSize: take,
      pageable: pagerSettings,
    };
  };

  const NameCell = (props) => {
    return (
      <td>
        <div className="d-flex flex-column">
          <div>
            <span>{props.dataItem.fullName}</span>
          </div>
          <div className={styles[`addressLines`] + " " + styles[`pointer`]}>
            <span>
              <u
                onClick={() => {
                  enterEdit(props.dataItem, "View");
                }}
              >
                {props.dataItem.userRef}
              </u>
            </span>
          </div>
        </div>
      </td>
    );
  };

  const [state, setState] = React.useState(createState(0, 10));
  const [addnewform, setaddnewform] = React.useState(false);
  const [modalvalue, setmodalvalue] = React.useState(false);
  const [searchValue, setSearchValue] = React.useState();
  const [isSearched, setIsSearched] = React.useState(false);
  const [showOptions, setShowOption] = React.useState("list");
  const filesCurrentData = useSelector((state) => state.usersList.files);
  const searchRef = useRef();

  const closeform = () => {
    setaddnewform(false);
    // props.onCancel();
  };

  React.useEffect(() => {
    if (props.onClose === false) {
      closeform();
    }
  }, [props.onClose]);

  const handleSubmitFinal = async (event) => {
    try {
      let data = event;
      data.userImageFileId = filesCurrentData[0].fileID;
      await api.addnewUser(data);
      toast.success("New User Added");
      dispatch(getUsersList(1, 100));
      setOpenForm(false);
      setaddnewform(false);
    } catch (error) {
      toast.error(error?.message);
    }
  };

  const enterEdit = async (item, view) => {
    const dataToPass = await dataFetcher({ dataItem: item });
    dataToPass.type = "Edit";
    if (view !== undefined) {
      dataToPass.type = "View";
    }
    let data = dateFormatter(dataToPass);
    if (data.userImageDocumentId !== null) {
      dispatch(getProfilePhoto(data.userImageDocumentId));
    }
    setOpenForm(true);
    setEditItem(data);
  };

  const handleSubmit = (event) => {
    let data = event;
    if (editProfilePhoto !== "") {
      data.userImageFileId = editProfilePhoto?.fileID;
    }
    dispatch(editUserinfo(event.userID, data));
    dispatch(clearProfilePhoto());

    setOpenForm(false);
  };

  const [products, setProducts] = React.useState({
    data: [],
    total: 20,
  });

  const [dataState, setDataState] = React.useState({
    take: 50,
    skip: 0,
  });

  const handleCancelEdit = () => {
    dispatch(clearProfilePhoto());
    setOpenForm(false);
  };
  const MyEditCommandCell = (props) => (
    <EditCommandCell {...props} enterEdit={enterEdit} />
  );

  const dataFetcher = async (e) => {
    try {
      const data = await api.getUserInfo(e.dataItem.userID);
      let dataToPass = data?.data?.data;
      dataToPass.timeTarget =
        dataToPass.timeTarget === null ? 0 : dataToPass.timeTarget;
      return dataToPass;
    } catch (error) {
      handleApiError(error);
    }
  };

  const dateFormatter = (dataToChange) => {
    let data = dataToChange;
    data.dateJoined =
      data.dateJoined !== null ? new Date(data.dateJoined) : null;
    data.dateQualified =
      data.dateQualified !== null ? new Date(data.dateQualified) : null;
    data.dateOfBirth =
      data.dateOfBirth !== null ? new Date(data.dateOfBirth) : null;
    data.dateLeft = data.dateLeft !== null ? new Date(data.dateLeft) : null;
    return data;
  };

  const dataStateChange = (e) => {
    setDataState(e.dataState);
  };

  const dataReceived = (products) => {
    let items = [];
    [...products.data].map((item) => {
      let firstName = item.firstName !== null ? item.firstName : " ";
      let lastName = item.lastName !== null ? item.lastName : " ";
      item.fullName = firstName + " " + lastName;
      item.symbol = item.fullName[0];
      items.push(item);
    });
    setProducts({
      ...products,
      data: items,
    });
  };

  return (
    <>
      <div className="w-100 mx-auto">
        <div className="d-flex justify-content-between">
          <div className="ps-3 mt-5">
            <SearchTextBox
              ref={searchRef}
              changeFilterValue={(e) => {
                setSearchValue(e);
              }}
            />
          </div>
          <div className="pe-3">
            <Button
              className={`common-btn mt-5`}
              onClick={() => {
                setaddnewform(true);
                props.open();
              }}
            >
              Add User
            </Button>
          </div>
        </div>

        <div className={`row mx-auto1 mt-3 w-100`}>
          <div className="settings-grid users-grid col-12">
            <Grid
              pageable={state.pageable}
              pageSize={state.pageSize}
              {...dataState}
              data={products}
              sortable={true}
              style={{ width: "100%" }}
              onDataStateChange={dataStateChange}
            >
              {/* <Column field="userRef" title="User Ref" cell={UserRefCell} /> */}
              <Column
                field="symbol"
                title=" "
                cell={SignatureCell}
                width={"80"}
              />
              <Column field="fullName" title=" " cell={NameCell} width="200" />
              <Column field="jobTitle" title="Job Title" width="220px" />
              <Column field="emailAddress" title="Email" width="280" />
              <Column field="phoneNo" title="Phone No" width="140" />
              <Column cell={FeeearnerCell} title="Is FeeEarner" width="100" />
              <Column cell={AdminCell} title="Admin" width="80" />
              <Column cell={ActiveCell} title="Active" width="80" />
              <Column title="" cell={MyEditCommandCell} width="80" />
            </Grid>

            <ProductsLoader
              dataState={dataState}
              searchValue={searchValue}
              isRefreshed={isRefreshed}
              isSearched={isSearched}
              onDataReceived={dataReceived}
            />
          </div>
        </div>
      </div>

      {addnewform && (
        <Dialog
          width={1150}
          onClose={() => {
            setaddnewform(false);
          }}
          title="Add User"
        >
          <div className="company">
            <EditForm
              type={"addNew"}
              onSubmit={handleSubmitFinal}
              cancelEdit={() => {
                setaddnewform(false);
              }}
              isDialog
            />
          </div>
        </Dialog>
      )}

      <Modal
        value={modalvalue}
        display={"User has been added"}
        closeform={closeform}
        component="User"
      ></Modal>

      {openForm && (
        <Dialog onClose={handleCancelEdit} width={1150} title="Edit User">
          <div className="company">
            <EditForm
              cancelEdit={handleCancelEdit}
              onSubmit={handleSubmit}
              isDialog
              item={editItem}
            />
          </div>
        </Dialog>
      )}
    </>
  );
};

export default UsersList;
