import * as React from "react";
// import Modal from "../Modal";
import { Button } from "@progress/kendo-react-buttons";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as api from "../../api/index";
import { NumericTextBox } from "@progress/kendo-react-inputs";
import { Form, FormElement } from "@progress/kendo-react-form";
// import { addTimeFunctionality } from "/actions/time.action";
import DisbursementGrid from "./DisbursementGrid";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { Input } from "@progress/kendo-react-inputs";
import { Label } from "@progress/kendo-react-labels";
import { useEffect } from "react";
import { DocumentsUpload } from "../Postings/DcoumentsUpload";
import { TextArea } from "@progress/kendo-react-inputs";
import MattersCombobox from "../Postings/MattersComboBox";
import Modalposting from "components/BatchPosting/PostingsModal";
import SearchComboBox from "components/common/SearchComboBox";
import CONSTANT from "CONSTANT/Matter/ComboxType";
import {
  createposting,
  getDisbursementMatterList,
  removeDisbursementMatterList,
} from "actions/postings.action";
import { clearFilesDispPayment } from "actions/files.action";
import { useUploadForm } from "components/fileUploadHook";
import { onFileUpload } from "Services/fileDataService";
import { usePreComponentCalls } from "components/layout/PreComponentLoad";
import { toast } from "react-toastify";
import { CustomDateInput } from "components/common/CustomDateInput";
import CalenderCustomToggleButton from "components/common/CalenderCustomToogleButton";
import { TransactionPeriod } from "components/common/TransactionPeriod";

const DisbursementPayment = (props) => {
  const dispatch = useDispatch();
  const date = new Date();
  const { handleCalls } = usePreComponentCalls();
  useEffect(() => {
    handleCalls("Pay Disbursement");
  }, []);
  const [details, setDetails] = useState("");
  const [reference, setReference] = useState("");
  const [payee, setPayee] = useState("");
  const [amount, setAmount] = useState(0);
  const [dateselector, setdateselector] = useState(date);
  const [matterRef, setmatterref] = React.useState();
  const [data, setData] = useState([]);
  const [allocation, setAllocation] = useState(null);
  const [resetmatter, setresetmatter] = useState(false);
  const [modalvalue, setmodalvalue] = useState(false);
  const [initialData, setInitialData] = useState({
    date: new Date(Date.now()),
  });
  const [error, setError] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const filesCurrentData = useSelector(
    (state) => state.filesDownload.disbPaymentFiles
  );

  const getMatterData = async (matterId) => {
    if (matterId) {
      const datas = await api.getDisbursementMatterList(matterId);
      datas?.data?.data.forEach((element) => {
        element.inEdit = true;
        element.add = false;
        element.amount = 0;
      });
      setData(datas?.data?.data);
    } else {
      setData([]);
    }
  };

  const onChangematter = (event) => {
    try {
      getMatterData(event?.value?.matterId);
      setmatterref(event?.value?.matterId);
    } catch (error) {
      console.error(error);
    }
  };
  const onChangedateselect = (event) => {
    setdateselector(event.value);
  };

  async function Submithandler(value) {
    const data = [
      {
        timeId: 0,
        postingType: "DUP",
        reference: reference,
        matterID: matterRef,
        feeEarnerRef: value?.feeEarner?.userRef,
        date: dateselector,
        netAmount: amount,
        transactionPeriod: 202201,
        currencyCode: "GBP",
        details: details,
        reference: reference,
        Payee: payee,
        OfficeBank: value?.officeBank?.nominalRef,
      },
    ];

    if (allocation !== null) {
      data[0].allocations = allocation;
    }
    if (filesCurrentData[0] !== undefined) {
      const docsId = [];
      filesCurrentData.map((item) => {
        docsId.push(item.fileID);
      });
      data[0].DocumentFiles = docsId;
    }
    let totalAllocatedAmount = 0;
    allocation.forEach((item) => (totalAllocatedAmount += item.amount));
    if (totalAllocatedAmount != amount) {
      toast.error("Allocated amount should be equal to net amount");
      return;
    }
    setLoading(true);
    try {
      const response = await api.createposting(data);
      setmodalvalue({
        code: response?.status,
        data: response?.data,
      });
    } catch (error) {
      setmodalvalue({
        code: error?.response?.status,
        data: error?.response.data,
      });
    }
  }
  const reset = () => {
    document.getElementById("reset-button").click();
    setDetails("");
    setAmount(0);
    setPayee("");
    setReference("");
    setmodalvalue();
    getMatterData(matterRef);
    setLoading(false);
    setError(false);
    dispatch(clearFilesDispPayment());
  };

  const { isSuccess, uploadForm, progress } = useUploadForm();

  const [filesData, setFilesData] = React.useState([]);

  const handleUpload = async (e) => {
    let data = onFileUpload(e);
    setFilesData([...filesData, data]);
    const dataChoosen = await uploadForm(data, e.target.files[0]);
    data.fileID = dataChoosen.data.data.fileID;
  };
  const onFileRemove = (fileId) => {
    setFilesData((prev) => prev.filter((file) => file.fileID !== fileId));
  };

  return (
    <div
      className="container-fluid ps-0 pt-0"
      style={{ height: "calc(100vh - 100px)" }}
    >
      <div className="row h-100 g-0">
        <div
          className="ms-2 bgForm col-3 pt-4 d-flex justify-content-center align-item-center"
          style={{
            width: "380px",
            background: "#F8F8F8",
            overflow: "auto",
            maxHeight: "calc(100vh - 129px)",
          }}
        >
          <Form
            ignoreModified={true}
            onSubmit={Submithandler}
            initialValues={initialData}
            key={JSON.stringify(initialData)}
            render={(formRenderProps) => {
              return (
                <FormElement
                  name="disbform"
                  id="disbform"
                  style={{
                    width: "85%",
                  }}
                >
                  <fieldset>
                    {formRenderProps.visited &&
                      formRenderProps.errors &&
                      formRenderProps.errors.VALIDATION_SUMMARY && (
                        <div className={"k-messagebox k-messagebox-error"}>
                          {formRenderProps.errors.VALIDATION_SUMMARY}
                        </div>
                      )}
                    <div className="mb-3">
                      <Label className="label">Matter</Label>
                      <div
                        style={{
                          borderRadius: "10px",
                          boxShadow: "0px 0px 16px rgba(0, 0, 0, 0.1)",
                        }}
                      >
                        <MattersCombobox
                          onChangematter={onChangematter}
                          reset={resetmatter}
                        />
                      </div>
                    </div>
                    <div className="mb-3">
                      <Label className="label">Date</Label>
                      <div
                        style={{
                          borderRadius: "10px",
                          boxShadow: "0px 0px 16px rgba(0, 0, 0, 0.1)",
                        }}
                      >
                        <DatePicker
                          className="datepicker px-2"
                          value={dateselector}
                          onChange={onChangedateselect}
                          dateInput={CustomDateInput}
                          toggleButton={CalenderCustomToggleButton}
                          defaultValue={dateselector}
                        />
                      </div>
                    </div>
                    <div className="mb-3">
                      <SearchComboBox
                        name="officeBank"
                        cbType={CONSTANT.OB}
                        width="auto"
                        boxShadow={true}
                      />
                    </div>
                    <div className="mb-3">
                      <Label className="label">Reference</Label>
                      <div
                        style={{
                          borderRadius: "10px",
                          boxShadow: "0px 0px 16px rgba(0, 0, 0, 0.1)",
                        }}
                      >
                        <Input
                          width={200}
                          value={reference}
                          onChange={(e) => {
                            setReference(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="mb-3 right-align">
                      <Label className="label">Amount</Label>
                      <div
                        style={{
                          borderRadius: "10px",
                          boxShadow: "0px 0px 16px rgba(0, 0, 0, 0.1)",
                        }}
                      >
                        <NumericTextBox
                          width={300}
                          style={{ textAlign: "right" }}
                          value={amount}
                          onChange={(e) => {
                            setAmount(e.target.value);
                          }}
                          placeholder="0.00"
                          spinners={false}
                          format="n2"
                        />
                      </div>
                    </div>

                    <div className="mb-3">
                      <SearchComboBox
                        name="feeEarner"
                        cbType={CONSTANT.FE}
                        width="auto"
                        boxShadow={true}
                      />
                    </div>
                    <div className="mb-3">
                      <Label className="label">Payee</Label>
                      <div
                        style={{
                          borderRadius: "10px",
                          boxShadow: "0px 0px 16px rgba(0, 0, 0, 0.1)",
                        }}
                      >
                        <Input
                          width={200}
                          value={payee}
                          onChange={(e) => {
                            setPayee(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="mb-3">
                      <Label className="label">Details</Label>
                      <div
                        style={{
                          borderRadius: "10px",
                          boxShadow: "0px 0px 16px rgba(0, 0, 0, 0.1)",
                        }}
                      >
                        <TextArea
                          rows={4}
                          placeholder="Enter Details"
                          value={details}
                          onChange={(e) => {
                            setDetails(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="d-flex justify-content-end">
                      <DocumentsUpload
                        progress={progress}
                        filesData={filesData}
                        onFileRemove={onFileRemove}
                        onError={() => {
                          const newFiles = filesData.splice(
                            filesData.length,
                            1
                          );
                          setFilesData([...newFiles]);
                        }}
                        onUpload={handleUpload}
                        type={"DUP"}
                      />
                    </div>
                    <button
                      id="reset-button"
                      type="reset"
                      style={{ visibility: "hidden" }}
                      onClick={() => {
                        formRenderProps.onChange("feeEarner", {
                          value: null,
                        });
                        formRenderProps.onChange("officeBank", {
                          value: null,
                        });
                      }}
                    ></button>
                    <button
                      id="submit-button"
                      type="button"
                      style={{ visibility: "hidden" }}
                      onClick={() => {
                        formRenderProps.onSubmit();
                      }}
                    ></button>
                  </fieldset>
                </FormElement>
              );
            }}
          />
        </div>
        <div className="col">
          <TransactionPeriod />
          <div className="mt-4 ms-3 me-3">
            <DisbursementGrid
              setAmount={(value) => {
                // setAmount(value);
              }}
              updateAllocations={(value) => {
                setAllocation(value);
              }}
              data={data}
            />
            <div className="buttonstyle">
              <Button
                form="disbform"
                className="common-btn"
                onClick={() => document.getElementById("submit-button").click()}
                type="button"
                disabled={loading}
              >
                Post
              </Button>
            </div>
          </div>
        </div>
      </div>
      <Modalposting
        data={modalvalue}
        closeModal={() => {
          setmodalvalue();
          setLoading(false);
        }}
        reset={reset}
      ></Modalposting>
    </div>
  );
};
export default DisbursementPayment;
