import { GridComponent, Heading, VatContainer } from "components/VatReturn";
import React from "react";
import styles from "../../components/VatReturn/VatReturn.module.scss"
import { useState } from "react";
import VatDetails from "./VatDetials";
import * as api from '../../api/index'
import { useEffect } from "react";
import { DateFormatFull } from "components/common/formatter";

const VatReturn = () => {
    const [showDetails, setShowDetails] = useState(false);
    const [vatData, setVatData] = useState([]);
    const [completedVatData, setCompletedVatData] = useState([]);
    const [item, setItem] = useState({});

    const dataAnalyzer = (item, type) => {
        let data = {
            fromDate: new Date(item.fromDate).toISOString().split("T")[0],
            toDate: new Date(item.toDate).toISOString().split("T")[0],
            period: `${DateFormatFull(item.fromDate)} - ${DateFormatFull(item.dueDate)}`,
            amount: item.vatAmount,
            submissionDetails: type === 'completed' && `Submitted on ${DateFormatFull(item.datePosted)} by ${item.postedByFullName}`,
        }
        return data;
    }

    const updateData = async () => {
        try {
            const data = await api.getVatReturnHistory()
            data.data?.data?.map((item) => {
                const tempItem = dataAnalyzer(item, 'completed')
                setCompletedVatData(prevItem => [...prevItem, tempItem])
            })
            const dataHistory = await api.getVatObligations()
            dataHistory.data?.data?.map((item) => {
                const tempItem = dataAnalyzer(item, 'current')
                setVatData(prevItem => [...prevItem, tempItem])
            })
        } catch (error) {
            alert(error)
        }
    };

    useEffect(() => {
        updateData()
    }, []);

    const openVatDetails = async (props) => {
        setItem(props);
        setShowDetails(true);
    };


    return (
        <>
            {showDetails === false ?
                <div className={`page container-fluid ${styles['vat-return-container']} mt-3 pt-1`}>
                    <div className="row">
                        <p>VAT information from HMRC as of 06 Jun 2023</p>
                    </div>

                    <div className="row">
                        <div className="col-4">
                            <VatContainer style={{ borderRadius: '10px 0px 0px 10px'}} >
                                <p className={styles[`text-light-color`]}>VAT amount refundable</p>
                                <p className="fs-lg">Nothing to pay</p>
                            </VatContainer>
                        </div>
                        <div className="col-4">
                            <VatContainer>
                                <p className={styles[`text-light-color`]}>Payment due</p>
                                <p className="fs-lg">None</p>
                                <u><p>View previous payments</p></u>
                            </VatContainer>
                        </div>
                        <div className="col-4">
                            <VatContainer style={{ borderRadius: '0px 10px 10px 0px' }}>
                                <p className={styles[`text-light-color`]}>Payment method</p>
                                <p className="fs-lg">Refund from HMRC</p>
                            </VatContainer>
                        </div>
                    </div>


                    <div className="row mt-2">
                        <Heading name={'Current'} />
                    </div>
                    <div className="row mt-2">
                        <div className="col-12">
                            <GridComponent data={vatData} showDetails={openVatDetails} />
                        </div>
                    </div>
                    <div className="row mt-2">
                        <Heading name={'Completed'} />
                    </div>
                    <div className="row mt-2">
                        <div className="col-12">
                            <GridComponent data={completedVatData} showDetails={openVatDetails} />
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-12">
                            <p className={styles[`text-light-color`]}>
                                *By remaining connected to the HMRC MTD system, the Xero service will engage in on-going communications with HMRC systems. These communications include sending submission contents, and browser data (such as IP address and other metadata) as is legally required to be sent to HMRC for security and fraud prevention purposes. To learn more, including how to disconnect your Xero accounts from the HMRC MTD system
                            </p>
                        </div>
                    </div>
                </div> :
                <VatDetails onBack={() => { setShowDetails(false) }} item={item} />
            }
        </>
    );
}

export default VatReturn;