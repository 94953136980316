import * as React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import Title from "./TabTitle";
import Nominal from "../../pages/Nominal/Nominal";
import BatchPostings from "../../pages/BatchPosting/index";
import NominalLedger from "../../pages/Nominal/NominalLedger";
import JournalPostings from "../../pages/JournalPosting/JournalPosting";
import { useLocation, useNavigate, useMatch } from "react-router-dom";
import { addTab, removeTab } from "actions/tabs.actions";
import { nameFunction } from "components/common/formatter";
import CONSTANT from "CONSTANT/Matter/PostingTypes";
import VatReturn from "pages/VatReturn/VatSummary";
import BankRec from "pages/BankRec";
import { Tabs } from "components/Enums/TabEnums.ts";
import { useAllowedLiscence } from "hooks/useAllowedLiscence";
import AccessRevokedModal from "components/common/AccessRevokedModal";

const NominalTabs = React.memo((props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [tabs, setTabs] = React.useState([]);
  const location = useLocation();
  const { allowed } = useAllowedLiscence(Tabs.NOMINAL);

  const tabsData = useSelector((state) => state.tabsData.nominalTabs);

  const findContent = (item) => {
    switch (item) {
      case "nominals":
        return "Nominals";
      case "journalposting":
        return "Journal Posting";
      case "bankpostings":
        return "Bank Postings";
      case "nominalledger":
        return "Nominal Ledger";
      case "bankrec":
        return "Bank Rec";
      case "vatreturn":
        return "VAT Return";
      default:
        break;
    }
  };

  const urlDefiner = (e) => {
    if (params !== null) {
      let url = tabs[e]?.toLowerCase();
      if (url === undefined) {
        url = "";
      }
      // window.history.replaceState(null, '', `/nominal/${url}`)
      navigate(`/nominal/${url}`);
    }
  };

  const params = useMatch("/nominal/:data");

  useEffect(() => {
    if (params !== null) {
      let data = params?.params?.data;
      if (!tabs.includes(data?.toLowerCase())) {
        dispatch(addTab("nominals", data?.toLowerCase()));
      } else {
        setSelected(tabs.indexOf(data?.toLowerCase()));
      }
    }
  }, [params]);

  useEffect(() => {
    if (tabsData) {
      setTabs(tabsData);
      let index = tabsData.indexOf(params?.params?.data?.toLowerCase());
      setSelected(index === -1 ? 0 : index);
    }
  }, [tabsData]);

  const removeTabDispatcher = (tab) => {
    if (nameFunction(tab) === `${params?.params?.data}`) {
      let index = nameFunction(tabs[0]);
      navigate(`/nominal/${index}`);
    }
    if (tabs.length === 1) {
      navigate("/");
    }
    dispatch(removeTab("nominals", tab));
  };

  const [selected, setSelected] = React.useState(-1);

  const handleSelect = (e) => {
    urlDefiner(e.selected);
  };

  return allowed ? (
    <TabStrip
      selected={selected}
      onSelect={handleSelect}
      keepTabsMounted={true}
      className="navigationTab"
    >
      {tabs?.map((item, index) => {
        return (
          item && (
            <TabStripTab
              title={
                <Title
                  content={findContent(item)}
                  onTabRemove={removeTabDispatcher}
                  selected={findContent(tabs.at(selected))}
                  count={tabs.length}
                />
              }
              key={index}
            >
              <div style={{ height: "100%" }}>
                {item === "nominals" && <Nominal />}
                {item === "nominalledger" && <NominalLedger />}
                {item === "journalposting" && <JournalPostings />}
                {item === "bankpostings" && (
                  <BatchPostings type={CONSTANT.BNKP} />
                )}
                {item === "vatreturn" && <VatReturn />}
                {item === "bankrec" && <BankRec />}
              </div>
            </TabStripTab>
          )
        );
      })}
    </TabStrip>
  ) : (
    <AccessRevokedModal removeTab={() => props.closeTab()} />
  );
});

export default NominalTabs;
