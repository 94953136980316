import React, { useState, useRef } from "react";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import * as api from "../../api/index";
import { useEffect } from "react";
import { Label } from "@progress/kendo-react-labels";
import { GrRefresh } from "react-icons/gr";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import CalenderCustomToggleButton from "components/common/CalenderCustomToogleButton";
import { NumericTextBox } from "@progress/kendo-react-inputs";
import {
  Grid,
  getSelectedState,
  GridColumn as Column,
} from "@progress/kendo-react-grid";
import { useSelector } from "react-redux";
import { Input } from "@progress/kendo-react-inputs";
import {
  AmountFormat,
  DateFormat1,
  DateFormat,
} from "components/common/formatter";
import { getter } from "@progress/kendo-react-common";
import { Icon } from "@progress/kendo-react-common";
import { InputClearValue, TextBox } from "@progress/kendo-react-inputs";
import { process, filterBy } from "@progress/kendo-data-query";
import { toast } from "react-toastify";
import { Button } from "@progress/kendo-react-buttons";
import InfoBox from "components/Global/InfoBox";
import { NominalTypeFormat } from "components/common/nominalTypeFormatter";
import { handleApiError } from "Helper";
import { CustomDateInput } from "components/common/CustomDateInput";

const DATA_ITEM_KEY = "postingId";
const SELECTED_FIELD = "selected";
const idGetter = getter(DATA_ITEM_KEY);

const labelTextStyle = {
  fontSize: "14px",
  fontWeight: 500,
  lineHeight: "17px",
  marginBottom: "6px",
};

export default function BankRec() {
  let locale = useSelector((state) => state.company.company.locale);
  const [nominalData, setNominalData] = useState();
  const [nominalFilterData, setNominalFilterData] = useState();
  const [nominalValue, setNominalvalue] = useState(null);
  const [reconcile, setReconcile] = useState(new Date());
  const [StatementBalance, setStatementBalance] = React.useState(0.0);
  const [lastStatementBalance, setLastStatementBalance] = React.useState(0.0);
  const [difference, setDifference] = React.useState(0.0);
  const [gridData, setGridData] = React.useState([]);
  const [filterGridData, setFilterGridData] = useState([]);
  // const [setSelected, setSelected] = useState(false)
  const [header, setHeader] = useState("");
  const [dataState, setDataState] = React.useState({});
  const [loading, setLoading] = useState(false);
  const [selectedState, setSelectedState] = React.useState({});
  const [dataResult, setDataResult] = React.useState(
    process(
      gridData?.map((dataItem) =>
        Object.assign(
          {
            selected: false,
          },
          dataItem
        )
      ),
      {}
    )
  );
  const [filterValue, setFilterValue] = useState("");
  const [filterArray, setFilterArray] = useState([]);

  const fetchData = async () => {
    const { data } = await api.getBankRecNominals();
    setNominalData(data.data);
    setNominalFilterData(data.data);
  };

  const filterNominalData = (filter) => {
    const data = nominalData.slice();
    return filterBy(data, filter);
  };

  const fetchBankRec = async (nominalId, reconcile) => {
    const res = await api.getBankRecData(nominalId, DateFormat1(reconcile));
    setLastStatementBalance(res.data.data.header.lastRecStatement);
    setStatementBalance(res.data.data.statementBalance);
    const result = [...res.data.data.items];
    setHeader(res.data.data.header);
    const updatedResult = result?.map((item) => {
      return {
        ...item,
        add: false,
        inEdit: true,
      };
    });
    setGridData(updatedResult);
    setFilterGridData(updatedResult);
    setDataResult({
      ...process(
        updatedResult?.map((dataItem) =>
          Object.assign(
            {
              selected: false,
            },
            dataItem
          )
        ),
        {}
      ),
    });

    const saveItem = result
      .map((item) => {
        if (item.saved) {
          return item;
        }
      })
      .filter((item) => item !== undefined)
      .map((item) => {
        const obj = {
          [item.postingId]: item.saved,
        };
        return obj;
      });

    let outputObj = {};
    let recursive = (obj, name) => {
      for (let key in obj) {
        if (typeof obj[key] == "object") {
          recursive(obj[key], +key);
        } else {
          outputObj[key] = obj[key];
        }
      }
    };
    recursive(saveItem, "");
    setSelectedState(outputObj);
  };

  useEffect(() => {
    if (nominalValue?.nominalRef) {
      fetchBankRec(nominalValue?.nominalRef, reconcile);
    }
  }, [nominalValue, reconcile]);

  const filterNominal = (event) => {
    if (!event.value) {
      setDataResult([]);
      setGridData([]);
      setFilterGridData([]);
      return;
    }
    setNominalFilterData(filterNominalData(event.filter));
  };

  useEffect(() => {
    fetchData();
    getSelectedAmount();
  }, []);

  const onSearchValueChange = (ev) => {
    let value = ev.value;
    const data = value.split(",");
    setFilterValue(value);
    setFilterArray(data);
    // setFilterArray(data);
    //// To show only filter data uncommented below lines
    //let newData = gridData.filter((item) => {
    //    let match = false;
    //    for (const property in item) {
    //        if (
    //            item[property]
    //                ?.toString()
    //                ?.toLocaleLowerCase()
    //                .indexOf(value?.toLocaleLowerCase()) >= 0
    //        ) {
    //            match = true;
    //        }

    //        if (
    //            item[property]?.toLocaleDateString &&
    //            item[property]?.toLocaleDateString().indexOf(value) >= 0
    //        ) {
    //            match = true;
    //        }
    //    }
    //    return match;
    //});
    //setFilterGridData(newData);
    //let clearedPagerDataState = { ...dataState, take: 10, skip: 0 };
    //let processedData = process(newData, clearedPagerDataState);
    //setDataResult({ ...processedData });
    //setDataState(clearedPagerDataState);
  };

  const dataStateChange = (event) => {
    setDataResult({ ...process(filterGridData, event.dataState) });
    setDataState(event.dataState);
  };

  const onSelectionChange = React.useCallback(
    (event) => {
      if (event.syntheticEvent === undefined) {
        return;
      }
      const newSelectedState = getSelectedState({
        event,
        selectedState: selectedState,
        dataItemKey: DATA_ITEM_KEY,
      });

      setSelectedState(newSelectedState);
    },
    [selectedState]
  );

  const getHighlight = (valueUsed, filter, filteredValue) => {
    let value = valueUsed;
    let index = value
      .toLocaleLowerCase()
      .indexOf(filteredValue.toLocaleLowerCase());

    if (index >= 0) {
      let left = value.substr(0, index);
      let right = value.substring(index + filter.length, value.length);
      return (
        <React.Fragment>
          {left}
          <span className="shadow-lg" style={{ backgroundColor: "#FFC000" }}>
            {value?.substr(index, filter.length)}
          </span>
          {right}
        </React.Fragment>
      );
    }
  };

  const cellRender = React.useCallback(
    (td, props) => {
      let value = td.props.children;
      // for (let items in filterArray) {
      if (props.field === "netAmount") {
        value = td.props.children.replace(",", "");
      }

      for (let items of filterArray) {
        if (
          items &&
          items.length > 0 &&
          value?.substr &&
          value?.toLocaleLowerCase().indexOf(items?.toLocaleLowerCase()) >= 0
        ) {
          const children = getHighlight(
            value,
            items?.toLocaleLowerCase(),
            items
          );
          return React.cloneElement(td, [props], [children]);
        }
      }
      return td;
    },
    [filterArray]
  );

  const PaymentCell = (props) => {
    const dateField = props.dataItem[props.field];
    if (dateField >= 0) {
      return cellRender(
        <td style={{ textAlign: "right" }}>{AmountFormat(dateField)}</td>,
        props
      );
    } else {
      return <td></td>;
    }
  };

  const DateCell = (props) => {
    const dateField = props.dataItem[props.field];
    if (dateField) {
      return cellRender(<td>{DateFormat(dateField)}</td>, props);
    } else {
      return <td></td>;
    }
  };

  const RecieptCell = (props) => {
    const dateField = props.dataItem[props.field];
    if (dateField < 0) {
      return cellRender(
        <td style={{ textAlign: "right" }}>{AmountFormat(dateField)}</td>,
        props
      );
    } else {
      return <td></td>;
    }
  };

  function sumArray(array) {
    if (Array.isArray(array)) {
      let sum = 0;

      for (const item of array) {
        sum += item;
      }
      return sum;
    }
  }

  const getSelectedAmount = () => {
    const selected = dataResult?.data?.map((item) => {
      if (
        selectedState?.hasOwnProperty(item.postingId) &&
        selectedState?.[item?.postingId]
      ) {
        return item.netAmount;
      } else {
        return 0;
      }
    });

    const unSelected = dataResult?.data?.map((item) => {
      if (
        selectedState?.hasOwnProperty(item.postingId) &&
        selectedState?.[item.postingId]
      ) {
        return 0;
      } else {
        return item?.netAmount;
      }
    });
    const sumSel = sumArray(selected);
    const sumunSel = sumArray(unSelected);

    return {
      sumSel,
      sumunSel,
    };
  };

  const dataReturner = () => {
    let unSelected = dataResult?.data?.map((item) => {
      if (
        selectedState.hasOwnProperty(item.postingId) &&
        selectedState[item.postingId]
      ) {
        return item.postingId;
      } else {
        return 0;
      }
    });

    let selected = dataResult?.data?.map((item) => {
      if (
        selectedState.hasOwnProperty(item.postingId) &&
        selectedState[item.postingId]
      ) {
        return 0;
      } else {
        return item.postingId;
      }
    });

    let filterSelected = selected.filter((item) => item !== 0);
    let filterunSelected = unSelected.filter((item) => item !== 0);

    const data = {
      data: {
        toDate: reconcile,
        statementBalance: StatementBalance,
        selectedItems: [...filterunSelected],
        unselectedItems: [...filterSelected],
      },
    };
    return data;
  };

  const checkDifferenceAmount = () => {
    const diffAmount =
      StatementBalance - (lastStatementBalance + getSelectedAmount().sumSel);
    if (isNaN(diffAmount)) {
      setDifference(0);
      return;
    }
    setDifference(diffAmount);
  };

  const handleSave = async () => {
    setLoading(true);
    const data = dataReturner();
    const res = await api.addBankRecs(nominalValue?.nominalRef, data);

    setLoading(false);

    if (res) return toast.success("Bank Rec Saved");
    else return toast.error("Failed");
  };

  const handlePost = async () => {
    try {
      if (difference === 0) {
        const data = dataReturner();
        data.data.balance_RecDate = header.balance_RecDate;
        const res = await api.postBankRecs(nominalValue?.nominalRef, data);
        fetchBankRec(nominalValue?.nominalRef, reconcile);
        if (res) return toast.success("Bank Rec Posted");
        else return toast.error("Failed");
      } else {
        toast.error("Difference value should be '0'");
      }
    } catch (error) {
      handleApiError(error);
    }
  };
  const reset = () => {
    setNominalvalue(null);
    setHeader("");
    setStatementBalance(0);
    setLastStatementBalance(0);
    setDifference(0);
    resetSearch();
  };

  useEffect(() => {
    checkDifferenceAmount();
  }, [StatementBalance, getSelectedAmount]);

  const BooleanHeaderCell = (props) => (
    <span
      className="d-flex pb-2 "
      style={{ color: "#000000", fontSize: "15px", fontWeight: "700" }}
    >
      Select
    </span>
  );

  const resetSearch = () => {
    setFilterArray([]);
    setFilterValue("");
  };

  return (
    <div className="page">
      <div className="row">
        <div className="col-6">
          <div className="d-flex align-items-start">
            <Label className="me-4 label mt-2 pt-1">Nominal Ref</Label>
            <div>
              <ComboBox
                style={{ width: "300px" }}
                data={nominalFilterData}
                textField={"nominalName"}
                filterable={true}
                onFilterChange={filterNominal}
                value={nominalValue}
                onChange={(e) => {
                  if (e.value === null) {
                    reset();
                    return;
                  }
                  setNominalvalue(e.value);
                }}
                placeholder={"Search Nominals"}
              />
              <InfoBox
                className="mt-2 w-100"
                text={
                  nominalValue !== null &&
                  `${NominalTypeFormat(
                    nominalValue?.type
                  )} - ${NominalTypeFormat(nominalValue?.subType)}`
                }
                title="Type"
              />
            </div>
            <Button
              className="common-btn ms-3"
              icon="refresh"
              disabled={nominalValue === null}
              onClick={() => {
                fetchBankRec(nominalValue?.nominalRef, reconcile);
                resetSearch();
              }}
            >
              Refresh
            </Button>
          </div>
        </div>

        <div className="col-6">
          <div
            className="ms-auto"
            style={{
              width: "500px",
              height: "150px",
              background: "#FFFFFF",
              boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.1)",
              borderRadius: "10px",
            }}
          >
            <div className="container">
              <div className="row pt-3 px-3 ">
                <div className="col-6 pe-5">
                  <div className="d-flex justify-content-between">
                    <p className="bt-text">Balance</p>
                    <p className="bt-value text-end">
                      {AmountFormat(header.balance || 0)}
                    </p>
                  </div>
                  <div className="d-flex justify-content-between">
                    <p className="bt-text">Bal @Rec Date</p>
                    <p className="bt-value text-end">
                      {AmountFormat(header.balance_RecDate || 0)}
                    </p>
                  </div>
                  <div className="d-flex justify-content-between">
                    <p className="bt-text">Payments</p>
                    <p className="bt-value text-end">
                      {AmountFormat(header.payments || 0)}
                    </p>
                  </div>
                  <div className="d-flex justify-content-between">
                    <p className="bt-text">Receipts</p>
                    <p className="bt-value text-end">
                      {AmountFormat(header.receipts || 0)}
                    </p>
                  </div>
                </div>
                <div className="col-6">
                  <div className="d-flex justify-content-between">
                    <p className="bt-text">Selected</p>
                    <p className="bt-value text-end">
                      {AmountFormat(getSelectedAmount().sumSel)}
                    </p>
                  </div>
                  <div className="d-flex justify-content-between">
                    <p className="bt-text">Unselected</p>
                    <p className="bt-value text-end">
                      {AmountFormat(getSelectedAmount().sumunSel)}
                    </p>
                  </div>
                  <div className="d-flex justify-content-between">
                    <p className="bt-text">Last Rec Statement</p>
                    <p className="bt-value text-end">
                      {AmountFormat(header.lastRecStatement || 0)}
                    </p>
                  </div>
                  <div className="d-flex justify-content-between">
                    <p className="bt-text">Last Rec by</p>
                    <p className="bt-value text-end">{header.lastRecBy}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row mt-3">
        <div className="d-flex align-items-end">
          <div className="me-4" style={{ width: "246px", maxWidth: "246px" }}>
            <Label className="mb-1">Reconcile to</Label>
            <DatePicker
              className="datepicker"
              dateInput={CustomDateInput}
              toggleButton={CalenderCustomToggleButton}
              value={reconcile}
              onChange={({ value }) => setReconcile(value)}
            />
          </div>
          <div className="d-flex flex-column">
            <Label className="mb-1">Search</Label>
            <TextBox
              value={filterValue}
              onChange={onSearchValueChange}
              style={{ width: "246px" }}
              placeholder={"Search"}
              suffix={() => (
                <React.Fragment>
                  {filterValue !== "" && (
                    <InputClearValue onClick={resetSearch}>
                      <Icon name="x" />
                    </InputClearValue>
                  )}
                </React.Fragment>
              )}
            />
          </div>
          <div
            className="ms-auto right-align "
            style={{ width: "246", maxWidth: "246px" }}
          >
            <Label className="mb-1">Statement Balance</Label>
            <NumericTextBox
              id="netAmount"
              onChange={(e) => setStatementBalance(e.value)}
              value={StatementBalance}
              spinners={false}
              format="n2"
              placeholder="0.00"
            />
          </div>

          <div
            className="ms-4 right-align"
            style={{ width: "246", maxWidth: "246px" }}
          >
            <Label className="mb-1">Difference</Label>
            <NumericTextBox
              id="netAmount"
              // onChange={(e) => setDifference(e.value)}
              value={difference}
              spinners={false}
              format="n2"
              placeholder="0.00"
              disabled={true}
            />
          </div>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col bankRec">
          <Grid
            sortable={true}
            cellRender={cellRender}
            data={dataResult.data?.map((item) => ({
              ...item,
              [SELECTED_FIELD]: selectedState[idGetter(item)],
            }))}
            {...dataState}
            onDataStateChange={dataStateChange}
            dataItemKey={DATA_ITEM_KEY}
            selectedField={SELECTED_FIELD}
            selectable={{
              enabled: true,
              drag: false,
              cell: false,
              row: true,
              mode: "multiple",
            }}
            onSelectionChange={onSelectionChange}
            editField="inEdit"
            className="pb-1"
            style={{ height: "calc(100vh - 480px)" }}
          >
            <Column
              field="date"
              title="Date"
              editable={false}
              cell={DateCell}
              width="120px"
            />
            <Column
              field="postingType"
              title="Type"
              editable={false}
              width="80px"
            />
            <Column
              field="matterRef"
              title="Matter Ref"
              editable={false}
              width="120px"
            />
            <Column field="details" title="Details" editable={false} />
            <Column
              field="transactionPeriod"
              title="Period"
              editable={false}
              width="80px"
            />
            <Column
              field="netAmount"
              title="Receipt"
              cell={RecieptCell}
              editable={false}
              width="120px"
            />
            <Column
              field="netAmount"
              title="Payment"
              cell={PaymentCell}
              editable={false}
              width="120px"
            />
            {/*<Column field="saved" title="Doc" editable={false} width="80px" />*/}
            <Column
              field={SELECTED_FIELD}
              headerCell={BooleanHeaderCell}
              title="Select"
              editor="boolean"
              width="80px"
            />
          </Grid>

          <div className="d-flex justify-content-end mt-2 pb-2">
            <Button className="common-btn bgBtnSecondary" onClick={handleSave}>
              {loading ? "Saving..." : "Save"}
            </Button>
            <Button className="common-btn ms-3" onClick={handlePost}>
              {"Post"}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
