import styles from "./Tile.module.scss";

export const Tile = (props) => {
  return (
    <div
      className={` ${styles["tile-shadow"]} ${props.className}`}
      style={{
        ...props.style,
        height: props.height,
        width: props.width,
      }}
    >
      {props.heading && (
        <div className={`${props.headingClass}`}>
          <div
            className="d-flex align-items-center justify-content-between"
            style={{ height: "50px" }}
          >
            <div className={`${styles["tile-heading"]}`}>{props.heading}</div>
            {props.headingItems}
          </div>
          <hr className="mt-0" />
        </div>
      )}

      {props.children}
    </div>
  );
};
