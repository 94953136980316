const CONSTANT = {
    OP: "Office Postings",
    CP: "Client Postings",
    BNKP: "Bank Postings",
    BP: "Batch Postings",
    PI: "Purchase Invoice",
    DIS: "Disbursements",
    TRANS: "Transfers",
}

export default CONSTANT