import {
  START_LOADING,
  STOP_LOADING,
  ERROR,
  SUCCESS,
  GET_POSTING_DETAILS,
  CLEAR_NOMINAL_JOURNAL_POSTING,
  GET_MATTERSLIST,
  GET_FEEEARNER,
  CREATED_POSTING,
  RESET_DATA,
  GET_NOMINALREF,
  GET_SUPPLIERREF,
  GET_CONSULTANT_REF,
  ADD_DISBURSEMENT_MATTER_LIST,
  REMOVE_DISBURSEMENT_MATTER_LIST,
  ADD_OFFICE_CREDIT_MATTER_LIST,
  REMOVE_OFFICE_CREDIT_MATTER_LIST,
  GET_CLIENT_POSTING_DETAILS,
  GET_OFFICE_POSTING_DETAILS,
  ADD_PAY_SUPPLIER_LIST,
  REMOVE_SUPPLIER_LIST,
} from "../types/index.js";

const PostingsDetails = (
  state = {
    IsError: false,
    isSuccess: false,
    isLoading: undefined,
    clearFields: true,
    disbMatterList: [],
    paySupplierList: [],
    officeCreditMatterList: [],
    postingstypes: { data: [{ displayFields: [] }] },
    postingsOfficetypes: { data: [{ displayFields: [] }] },
    postingsClienttypes: { data: [{ displayFields: [] }] },
    mattersList: { data: [] },
    feeearner: { data: [] },
    getNominalRef: { data: [] },
    getSupplierRef: { data: [] },
    data: { data: [{ transactionNo: "Loading" }] },
  },
  action
) => {
  switch (action.type) {
    case START_LOADING:
      return { ...state, isLoading: true };
    case STOP_LOADING:
      return { ...state, isLoading: false };
    case GET_POSTING_DETAILS:
      return {
        ...state,
        postingstypes: action.payload,
      };
    case GET_CLIENT_POSTING_DETAILS:
      return {
        ...state,
        postingsClienttypes: action.payload,
      };
    case GET_OFFICE_POSTING_DETAILS:
      return {
        ...state,
        postingsOfficetypes: action.payload,
      };
    case GET_MATTERSLIST:
      return {
        ...state,
        mattersList: action.payload,
      };
    case GET_FEEEARNER:
      return {
        ...state,
        feeearner: action.payload,
      };
    case CLEAR_NOMINAL_JOURNAL_POSTING:
      return {
        ...state,
        clearFields: action.payload,
      };
    case CREATED_POSTING:
      return {
        ...state,
        data: action.payload,
      };
    case GET_NOMINALREF:
      return {
        ...state,
        nominalRef: action.payload,
      };
    case GET_SUPPLIERREF:
      return {
        ...state,
        supplierRef: action.payload,
      };
    case GET_CONSULTANT_REF:
      return {
        ...state,
        consultantRef: action.payload,
      };
    case RESET_DATA:
      const temp = state;
      temp.IsError = false;
      temp.data = { data: [{ transactionNo: "Loading" }] };
      return temp;
    case ERROR:
      return {
        ...state,
        IsError: true,
        isSuccess: false,
        errorData: action.payload,
      };
    case ADD_DISBURSEMENT_MATTER_LIST:
      return {
        ...state,
        disbMatterList: action.payload,
      };
    case REMOVE_DISBURSEMENT_MATTER_LIST:
      return {
        ...state,
        disbMatterList: [],
      };
    case ADD_PAY_SUPPLIER_LIST:
      return {
        ...state,
        paySupplierList: action.payload,
      };
    case REMOVE_SUPPLIER_LIST:
      return {
        ...state,
        paySupplierList: [],
      };
    case ADD_OFFICE_CREDIT_MATTER_LIST:
      return {
        ...state,
        officeCreditMatterList: action.payload,
      };
    case REMOVE_OFFICE_CREDIT_MATTER_LIST:
      return {
        ...state,
        officeCreditMatterList: [],
      };
    case SUCCESS:
      return { ...state, IsError: false, isSuccess: true };
    default:
      return state;
  }
};
export default PostingsDetails;
