import React from "react";
import ViewLogo from "../../assets/view-icon.png";
import ChitRequestIcon from "../../assets/chitRequest.png";
import LedgerIcon from "../../assets/ledger.png";
import CaseFileIcon from "../../assets/Settings/MenuSettings/case-file.png";
import { License } from "components/Enums/TabEnums.ts";
import { BsThreeDots } from "react-icons/bs";
import { useAllowedLiscence } from "hooks/useAllowedLiscence";
import { Popup } from "@progress/kendo-react-popup";
import TimeRecordingIcon from "../../assets/timeRecords.png";
import styles from "../../pages/Matters/Matter.module.scss";

export const List = ({ imageSrc, name, onClick }) => (
  <div
    className={`${styles["list-item"]} flex align-items-center justify-content-start px-3 py-2 cursor-pointer`}
    onClick={onClick}
    role="button"
  >
    <img
      role="button"
      src={imageSrc}
      alt="Image"
      style={{ marginRight: "10px" }}
    />
    <span role="button" style={{ fontWeight: "600", fontSize: "12px" }}>
      {name}
    </span>
  </div>
);

export const MatterOptionsList = ({
  handleOptionClick,
  handleCellClick,
  selectedCell,
  ...props
}) => {
  const { subDataCheck } = useAllowedLiscence();
  const hasCaseManagement = subDataCheck().includes(License.CASE_MANAGEMENT);
  return (
    <>
      <List
        imageSrc={ViewLogo}
        name="View Matter"
        onClick={() => {
          handleOptionClick("viewMatter", selectedCell || props);
          handleCellClick(null);
        }}
      />
      {hasCaseManagement && (
        <>
          <List
            imageSrc={CaseFileIcon}
            name="Casefile"
            onClick={() => {
              handleOptionClick("casefile", selectedCell || props);
              handleCellClick(null);
            }}
          />
          <List
            imageSrc={TimeRecordingIcon}
            name="Add Time"
            onClick={() => {
              handleOptionClick("addTime", selectedCell || props);
              handleCellClick(null);
            }}
          />
        </>
      )}
      <List
        imageSrc={ChitRequestIcon}
        name="Add Chit Request"
        onClick={() => {
          handleOptionClick("addChitRequest", selectedCell || props);
          handleCellClick(null);
        }}
      />
      <List
        imageSrc={LedgerIcon}
        name="View Ledger"
        onClick={() => {
          handleOptionClick("viewLedger", selectedCell || props);
          handleCellClick(null);
        }}
      />
    </>
  );
};

export const MatterOptions = ({
  handleOptionClick,
  handleCellClick,
  selectedCell,
  ...props
}) => {
  const anchor = React.useRef(null);
  const [isPopupVisible, setIsPopupVisible] = React.useState(false);

  React.useEffect(() => {
    setIsPopupVisible(selectedCell && props.id === selectedCell.id);
  }, [selectedCell]);

  const handleClick = (e) => {
    props?.setShow(false);
    if (isPopupVisible) {
      setIsPopupVisible(false);
      handleCellClick(null);
    } else {
      setIsPopupVisible(true);
      handleCellClick(props);
    }
  };

  React.useEffect(() => {
    const close = (e) => {
      if (e?.target?.role === "menu-grid") return;
      if (e?.target?.role !== "button") {
        setIsPopupVisible(false);
        handleCellClick(null);
      }
      if (!anchor.current) {
        setIsPopupVisible(false);
        handleCellClick(null);
      }
    };

    document.addEventListener("mousedown", close);

    return () => {
      document.removeEventListener("mousedown", close);
    };
  }, []);

  return (
    <td className="cursor-pointer">
      <div>
        <div
          ref={anchor}
          role="menu-grid"
          onClick={handleClick}
          style={{
            boxShadow: "0px 0px 10px 2px rgba(0,0,0,0.1)",
            borderRadius: "10%",
            width: "25px",
            height: "20px",
          }}
          className={`d-flex justify-content-center align-items-center cursor-pointer p-1 m-1a ${styles["menu-hover"]}`}
        >
          <BsThreeDots role="menu-grid" />
        </div>
        <Popup
          show={isPopupVisible}
          anchor={anchor.current}
          anchorAlign={{ horizontal: "right", vertical: "bottom" }}
          popupAlign={{ horizontal: "right", vertical: "top" }}
          animate={false}
          popupClass={`${styles["popup-content"]}`}
        >
          <MatterOptionsList
            handleOptionClick={handleOptionClick}
            handleCellClick={handleCellClick}
            {...props}
          />
        </Popup>
      </div>
    </td>
  );
};
