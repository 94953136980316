import React from "react";
import RedirectLogo from "../../assets/view icon.svg";
import ViewLogo from "../../assets/view-icon.png";
import { Tooltip } from "@progress/kendo-react-tooltip";

export const RedirectCell = (props) => {
  return (
    <td
      style={{ cursor: "pointer" }}
      onClick={(event) => {
        if (props.handleClick) props.handleClick(event);
      }}
    >
      <Tooltip anchorElement="target" position="top">
        <img
          title={props.title}
          alt={props.title}
          src={ViewLogo}
          style={{ width: "18px", height: "18px" }}
        ></img>
      </Tooltip>
    </td>
  );
};

export const NameTextCell = (props) => {
  return (
    <td style={{ cursor: "pointer", fontWeight: 1000, color: "black" }}>
      {props.dataItem[props.field]}
    </td>
  );
};

export const TextHeader = (props) => {
  return (
    <div
      style={{
        marginBottom: "8px",
      }}
    >
      {" "}
      <span className="ms-3"> {props.title}</span>
    </div>
  );
};

export const CustomButton = (props) => {
  return (
    <td>
      <button
        className="button-small p-1"
        onClick={props.handleClick}
        style={{
          color: "white",
          textAlign: "center",
          fontSize: "10px",
          border: "none",
        }}
      >
        {props.text}
      </button>
    </td>
  );
};
