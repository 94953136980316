import { getTabName } from "components/tabs/ClientTabs.jsx";
import {
  START_LOADING,
  STOP_LOADING,
  ERROR,
  SUCCESS,
  GET_CLIENTS_LIST,
  ADD_CLIENTS_VIEW_TAB,
  REMOVE_CLIENT_VIEW_TAB,
  CHNAGE_CLINET_EIDT_PERMISSION,
  UPDATE_CLIENT_VIEW_TAB,
} from "../types/index.js";

const clientsReducer = (
  state = {
    IsError: false,
    isSuccess: false,
    isLoading: undefined,
    clientViewTabs: ["Clients List"],
    clientViewData: [],
    openTab: undefined,
    clinetEditPermission: false,
    clinetTabEditData: undefined,
  },
  action
) => {
  switch (action.type) {
    case START_LOADING:
      return { ...state, isLoading: true };
    case STOP_LOADING:
      return { ...state, isLoading: false };
    case GET_CLIENTS_LIST:
      return {
        ...state,
        clientsList: action.payload,
      };
    case CHNAGE_CLINET_EIDT_PERMISSION:
      return {
        ...state,
        clinetEditPermission: !state.clinetEditPermission,
        clinetTabEditData: action.payload,
      };
    case ADD_CLIENTS_VIEW_TAB:
      let check = false;
      state.clientViewTabs.map((item) => {
        if (item.clientRef === action.payload.data.clientRef) {
          state.openTab = action.payload.data.clientRef;
          check = true;
        }
      });
      if (check) {
        return {
          ...state,
        };
      }
      state.clientViewTabs.push(action.payload.data);
      state.openTab = undefined;
      return {
        ...state,
      };
    case REMOVE_CLIENT_VIEW_TAB:
      state.clientViewTabs.map((item) => {
        if (
          getTabName(item) === action.payload &&
          getTabName(item) !== undefined
        ) {
          const index = state.clientViewTabs.indexOf(item);
          state.clientViewTabs.splice(index, 1);
          state.openTab = undefined;
        }
      });
      return {
        ...state,
      };
    case UPDATE_CLIENT_VIEW_TAB:
      const clientViewTabsCopy = [...state.clientViewTabs];
      const tabIndex = clientViewTabsCopy.findIndex(
        (item) => item?.clientId === action.payload.clientId
      );
      if (tabIndex) {
        clientViewTabsCopy[tabIndex] = action.payload;
      }
      return {
        ...state,
        clientViewTabs: [...clientViewTabsCopy],
      };
    case ERROR:
      return { ...state, IsError: true, isSuccess: false };
    case SUCCESS:
      return { ...state, IsError: false, isSuccess: true };
    default:
      return state;
  }
};
export default clientsReducer;
