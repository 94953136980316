import * as React from "react";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { NameTextCell, RedirectCell } from "components/common/GridComponents";
import styles from "components/Dashboard/index.module.scss";

const tasks = [
  {
    taskId: "#65364",
    matter: "HM006/001",
    clientName: "John Smith",
    details: "Culpa architecto consequatur voluptate...",
    dueDate: "31/10/2023",
    status: "Pending",
  },
  {
    taskId: "#65364",
    matter: "HM006/001",
    clientName: "John Smith",
    details: "Culpa architecto consequatur voluptate...",
    dueDate: "31/10/2023",
    status: "Pending",
  },
  {
    taskId: "#65364",
    matter: "HM006/001",
    clientName: "John Smith",
    details: "Culpa architecto consequatur voluptate...",
    dueDate: "31/10/2023",
    status: "Pending",
  },
  {
    taskId: "#65364",
    matter: "HM006/001",
    clientName: "John Smith",
    details: "Culpa architecto consequatur voluptate...",
    dueDate: "31/10/2023",
    status: "Pending",
  },
  {
    taskId: "#65364",
    matter: "HM006/001",
    clientName: "John Smith",
    details: "Culpa architecto consequatur voluptate...",
    dueDate: "31/10/2023",
    status: "Pending",
  },
  {
    taskId: "#65364",
    matter: "HM006/001",
    clientName: "John Smith",
    details: "Culpa architecto consequatur voluptate...",
    dueDate: "31/10/2023",
    status: "Pending",
  },
  {
    taskId: "#65364",
    matter: "HM006/001",
    clientName: "John Smith",
    details: "Culpa architecto consequatur voluptate...",
    dueDate: "31/10/2023",
    status: "Pending",
  },
  {
    taskId: "#65364",
    matter: "HM006/001",
    clientName: "John Smith",
    details: "Culpa architecto consequatur voluptate...",
    dueDate: "31/10/2023",
    status: "Pending",
  },
  {
    taskId: "#65364",
    matter: "HM006/001",
    clientName: "John Smith",
    details: "Culpa architecto consequatur voluptate...",
    dueDate: "31/10/2023",
    status: "Pending",
  },
  {
    taskId: "#65364",
    matter: "HM006/001",
    clientName: "John Smith",
    details: "Culpa architecto consequatur voluptate...",
    dueDate: "31/10/2023",
    status: "Pending",
  },
  {
    taskId: "#65364",
    matter: "HM006/001",
    clientName: "John Smith",
    details: "Culpa architecto consequatur voluptate...",
    dueDate: "31/10/2023",
    status: "Pending",
  },
];

const TasksGrid = () => {
  return (
    <div className="settings-grid dashboard-grid h-100">
      <div
        className={`${styles["combo-label-font"]} fw-semibold p-3 ps-4 pb-2`}
      >
        Tasks
      </div>
      <Grid
        style={{
          paddingBottom: "10px",
        }}
        data={[]}
      >
        <GridColumn field="taskId" title="Task ID" />
        <GridColumn field="matter" title="Matter" width={150} />
        <GridColumn
          field="clientName"
          title="Client Name"
          width={150}
          cell={NameTextCell}
        />
        <GridColumn field="details" title="Details" width={350} />
        <GridColumn field="dueDate" title="Due Date" />
        <GridColumn field="status" title="Status" />
        <GridColumn title=" " cell={RedirectCell} />
      </Grid>
    </div>
  );
};
export default TasksGrid;
