import * as React from "react";
import * as ReactDOM from "react-dom";
import { toODataString } from "@progress/kendo-data-query";
import { useDispatch, useSelector } from "react-redux";
import { getVatcodes } from "actions/vat.action";

export const VatsLoader = (props) => {
  const dispatch = useDispatch();
  const { vatcodesList } = useSelector((state) => state.vatcodes);
  const lastSuccess = React.useRef("");
  const pending = React.useRef("");
  const requestDataIfNeeded = () => {
    if (
      pending.current ||
      toODataString(props.dataState) === lastSuccess.current
    ) {
      return;
    }
    pending.current = toODataString(props.dataState);
    dispatch(
      getVatcodes(
        props.dataState.skip / props.dataState.take + 1,
        props.dataState.take
      )
    );
  };

  React.useEffect(() => {
    if (vatcodesList) {
      lastSuccess.current = pending.current;
      pending.current = "";
      if (toODataString(props.dataState) === lastSuccess.current) {
        props.onDataReceived.call(undefined, {
          data: vatcodesList.data,
          total: vatcodesList.total,
        });
      } else {
        requestDataIfNeeded();
      }
    }
    requestDataIfNeeded();
  }, [vatcodesList, pending, props.dataState, lastSuccess]);
  return pending.current ? <LoadingPanel /> : null;
};

const LoadingPanel = () => {
  const loadingPanel = (
    <div className="k-loading-mask">
      <span className="k-loading-text">Loading</span>
      <div className="k-loading-image" />
      <div className="k-loading-color" />
    </div>
  );
  const gridContent = document && document.querySelector(".k-grid-content");
  return gridContent
    ? ReactDOM.createPortal(loadingPanel, gridContent)
    : loadingPanel;
};
