import * as React from "react";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { useDispatch } from "react-redux";
import { ActiveCell, EditCommandCell, SignatureCell } from "../GridComponents";
import { SourcesLoader } from "./SourcesLoader";
import { editSource } from "actions/sources.action";
import AddEditSource from "./AddEditSource";

const SourcesList = () => {
  const [openForm, setOpenForm] = React.useState(false);
  const dispatch = useDispatch();
  const [editItem, setEditItem] = React.useState({
    ProductID: 1,
  });
  const enterEdit = (item) => {
    setOpenForm(true);
    setEditItem(item);
  };
  const handleSubmit = (event) => {
    let dataToPost = {
      data: {
        ...event,
      },
    };
    dispatch(editSource(event.sourceID, dataToPost));
    setOpenForm(false);
  };
  const [products, setProducts] = React.useState({
    data: [],
    total: 20,
  });
  const [dataState, setDataState] = React.useState({
    take: 10,
    skip: 0,
  });

  const handleCancelEdit = () => {
    setOpenForm(false);
  };
  const MyEditCommandCell = (props) => (
    <EditCommandCell {...props} enterEdit={enterEdit} />
  );
  const dataStateChange = (e) => {
    setDataState(e.dataState);
  };

  const dataReceived = (products) => {
    products?.data?.map((item) => {
      return (item.symbol = item?.sourceName[0]);
    });
    setProducts(products);
  };

  return (
    <div className="settings-grid container">
      <Grid {...dataState} data={products} onDataStateChange={dataStateChange}>
        <Column field="symbol" title=" " cell={SignatureCell} width="80" />
        <Column field="sourceName" title="Name" />
        <Column field="sourceDetails" title="Details" />
        <Column cell={ActiveCell} title="Active" field="active" width="80" />
        <Column title=" " cell={MyEditCommandCell} width="60" />
      </Grid>
      {openForm && (
        <AddEditSource
          cancelEdit={handleCancelEdit}
          onSubmit={handleSubmit}
          item={editItem}
        />
      )}

      <SourcesLoader dataState={dataState} onDataReceived={dataReceived} />
    </div>
  );
};

export default SourcesList;
