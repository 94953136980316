import * as React from "react";
import OAuth2Login from "react-simple-oauth2-login";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { useState } from "react";
import * as api from "../../../../api/index";
import Auth2Authentication from "components/OAuth2Login/AuthComponent";

const CALL_BACK_URL = `${window.location.origin}/oauth-callback`;

const ClioAuthentication = ({ onClose, text }) => {
  const [loading, setLoading] = useState(true);

  //OAuth2Login component need required fields, so need to define default state.
  const [credentials, setCredentials] = useState({
    authenticationBaseURL: "",
    responseType: "code",
    clientId: "",
    scope: "",
  });

  //Get Authentication information from API
  useEffect(() => {
    GetAuthenticationInfo();
  }, []);

  const GetAuthenticationInfo = async () => {
    const data = await api.getClioInfo(CALL_BACK_URL);
    setCredentials(data.data);
    setLoading(false);
  };

  const onSuccess = async (response) => {
    const data = {
      code: response?.code,
      state: "9999999",
      redirectURL: CALL_BACK_URL,
    };

    try {
      const res = await api.clioConnect(data);
      if (onClose) onClose();
      toast.success(res?.data);
    } catch (error) {
      toast.error("Error occured in connection");
    }
  };

  const onFailure = (response) => {
    toast.error("Error occured in connection");
  };

  return !loading ? (
    <Auth2Authentication
      credentials={credentials}
      buttonText={text ? text : "Connect to Clio"}
      onSuccess={onSuccess}
      onFailure={onFailure}
    />
  ) : (
    <></>
  );
};

export default ClioAuthentication;
