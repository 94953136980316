import * as React from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Input, Checkbox } from "@progress/kendo-react-inputs";
import { Label } from "@progress/kendo-react-labels";

const AddEditReferrer = (props) => {
  const ADD_NEW = props.type === "addNew";
  return (
    <Dialog
      title={ADD_NEW ? "Add Referrer" : `Edit ${props?.item?.referrerName}`}
      onClose={props.cancelEdit}
      width={600}
    >
      <div className="p-3 ps-4">
        <Form
          onSubmit={props.onSubmit}
          initialValues={props.item}
          render={(formRenderProps) => (
            <FormElement
              style={{
                maxWidth: 1200,
              }}
            >
              <fieldset className={"k-form-fieldset"}>
                <div className="mb-2">
                  <Label className="label mt-1 mb-2 w-100">Name&nbsp;</Label>
                  <Field
                    name={"referrerName"}
                    component={Input}
                    placeHolder="Enter Name"
                  />
                </div>
                <div className="mb-2">
                  <Label className="label mt-1 mb-2 w-100">Details&nbsp;</Label>
                  <Field
                    name={"referrerDetails"}
                    component={Input}
                    placeHolder="Enter Details"
                  />
                </div>
                <div className="d-flex align-items-centeer gap-3">
                  <Field
                    defaultChecked={true}
                    name={"active"}
                    className="mt-2"
                    component={Checkbox}
                    label={"Active"}
                  />
                  <Field
                    defaultChecked={true}
                    name={"forClients"}
                    className="mt-2"
                    component={Checkbox}
                    label={"For Clients"}
                  />
                  <Field
                    defaultChecked={true}
                    name={"forMatters"}
                    className="mt-2"
                    component={Checkbox}
                    label={"For Matters"}
                  />
                </div>
              </fieldset>
              <button
                style={{ visibility: "hidden" }}
                id="source-form"
                type={"submit"}
              ></button>
            </FormElement>
          )}
        />
      </div>
      <div className="dialog-footer">
        <button
          className="bgBtnSecondary common-btn me-2"
          onClick={props.cancelEdit}
        >
          Cancel
        </button>
        <button
          className="common-btn me-3"
          //   disabled={!formRenderProps.allowSubmit}
          onClick={() => document.getElementById("source-form").click()}
          type={"submit"}
        >
          {ADD_NEW ? "Save" : "Update"}
        </button>
      </div>
    </Dialog>
  );
};

export default AddEditReferrer;
