import {
  START_LOADING,
  STOP_LOADING,
  ERROR,
  SUCCESS,
  CHANGE_REF,
  CLEAR_REF,
  OPEN_MATTER_VIEW,
} from "../types/index.js";

const ledgerLinks = (
  state = {
    IsError: false,
    isSuccess: false,
    isLoading: undefined,
    currentMatterRef: null,
    currentNominalRef: null,
    currentSupplierRef: null,
    currentMatterLedger: null,
    matterViewPage: null,
  },
  action
) => {
  switch (action.type) {
    case START_LOADING:
      return { ...state, isLoading: true };
    case STOP_LOADING:
      return { ...state, isLoading: false };
    case CHANGE_REF:
      if (action.payload.type === "Matters") {
        return { ...state, currentMatterRef: action.payload.ref };
      } else if (action.payload.type === "Nominals") {
        return { ...state, currentNominalRef: action.payload.ref };
      } else if (action.payload.type === "MatterLedger") {
        return { ...state, currentMatterLedger: action.payload.ref };
      } else if (action.payload.type === "Suppliers") {
        return { ...state, currentSupplierRef: action.payload.ref };
      }
    case CLEAR_REF:
      if (action.payload.type === "Matters") {
        return { ...state, currentMatterRef: null };
      } else if (action.payload.type === "Nominals") {
        return { ...state, currentNominalRef: null };
      } else if (action.payload.type === "MatterLedger") {
        return { ...state, currentMatterLedger: null };
      } else if (action.payload.type === "Suppliers") {
        return { ...state, currentSupplierRef: null };
      }
    case OPEN_MATTER_VIEW:
      return {
        ...state,
        matterViewPage: action.payload.ref,
      };
    case ERROR:
      return { ...state, IsError: true, isSuccess: false };
    case SUCCESS:
      return { ...state, IsError: false, isSuccess: true };
    default:
      return state;
  }
};
export default ledgerLinks;
