import { CONSTANT } from "CONSTANT/Matter/MatterForm";
import { CONSTANT as MatterConstant } from "CONSTANT/Matter/MatterActivityForm";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import Icon from "components/Global/icon";
import { Label } from "@progress/kendo-react-labels";
import { DateInput, DatePicker } from "@progress/kendo-react-dateinputs";
import { Input, NumericTextBox } from "@progress/kendo-react-inputs";
import styles from "pages/Matters/Matter.module.scss";
import ClientComboBox from "components/common/ClientComboBox";
import { AmountFormat } from "components/common/formatter";
import { useSelector } from "react-redux";
import CalenderCustomToggleButton from "components/common/CalenderCustomToogleButton";
import { CustomDateInput } from "components/common/CustomDateInput";
import { Checkbox } from "@progress/kendo-react-inputs";

export const FormComboBox = ({
  wf,
  cb = false,
  onFilterChange,
  required = false,
  val,
  width,
  onChangeClient,
  isFiltered,
  label,
  name,
  state,
  boxShadow,
  data,
  fc = true,
  className,
  onChangeComboBox,
  placeholder,
  disabled,
  isStyled,
  styles = {},
  textField,
  inputClassName,
}) => {
  const labelCopy = name;
  let labelView = labelCopy?.replaceAll("_", " ");
  const labelToProcess = [
    CONSTANT.COMBOBOX.DDA_BANK,
    CONSTANT.COMBOBOX.PROFIT_COST_NOMINAL,
  ];
  if (labelToProcess.includes(labelCopy))
    labelView = labelView?.replaceAll("Default", "");

  const toRender = () => {
    if (name === CONSTANT.COMBOBOX.CLIENT) {
      return (
        <ClientComboBox
          val={val}
          width={width || "326px"}
          disabled={disabled}
          boxShadow={boxShadow}
          height="32px"
          onChangeClient={onChangeClient}
        />
      );
    } else {
      return (
        <ComboBox
          textField={textField}
          style={
            isStyled
              ? {
                  width: wf ? width || "100%" : "323px",
                  maxWidth: "323px",
                  height: "32px",
                  boxShadow: boxShadow && "0px 1px 9px rgba(0, 0, 0, 0.12)",
                  ...styles,
                }
              : {}
          }
          filterable={isFiltered !== undefined ? isFiltered : true}
          required={required}
          clearButton={cb}
          onFilterChange={onFilterChange}
          iconClassName="wa"
          icon={<Icon icon="arrow-down" />}
          data={data}
          disabled={disabled}
          name={name ? name : ""}
          value={state[name]}
          placeholder={placeholder || `Select ${labelView ? labelView : ""}`}
          onChange={(e) => onChangeComboBox(e, name)}
          className={inputClassName ? inputClassName : ""}
        />
      );
    }
  };
  return (
    <div
      className={`${className} d-flex ${
        fc ? "flex-column" : "align-items-center"
      }`}
    >
      <Label
        className="label"
        style={{
          width: labelToProcess.includes(labelCopy) ? "100%" : "110px",
          marginBottom: "4px",
          fontSize: "13px",
        }}
      >
        {" "}
        {label || name?.replaceAll("_", " ")}
      </Label>
      {toRender()}
    </div>
  );
};

export const FormInput = ({
  width,
  required = false,
  isStyled = true,
  label,
  labelForComboBox,
  ph,
  labelShow = true,
  type = "text",
  format = "n2",
  steppers = false,
  onChangeInput,
  boxShadow,
  fc = true,
  className,
  differentName,
  state,
  name,
  w100 = false,
  labelWidth,
  inputClassName,
}) => {
  const labelCopy = label;
  let labelView = labelCopy?.replaceAll("_", " ");
  // const labelToProcess = [
  //   CONSTANT.INPUT.POST_CODE_OS1,
  //   CONSTANT.INPUT.POST_CODE_OS2,
  //   CONSTANT.INPUT.LAST_NAME_OS1,
  //   CONSTANT.INPUT.LAST_NAME_OS2,
  // ];
  // if (labelToProcess.includes(labelCopy)) labelView = labelView.slice(0, -3);

  const isAmount = [
    MatterConstant.INPUT.MPU,
    MatterConstant.INPUT.UNITS,
    MatterConstant.INPUT.RATE,
    MatterConstant.INPUT.VALUE,
    CONSTANT.INPUT.BILL_CREDIT_LIMIT,
    CONSTANT.INPUT.DISP_CREDIT_LIMIT,
    CONSTANT.INPUT.WIP_CREDIT_LIMIT,
  ];
  return (
    <div
      className={`${className} d-flex ${
        fc ? "flex-column" : "align-items-center"
      }`}
    >
      {labelShow && (
        <Label
          style={{ width: labelWidth ? "152px" : "auto", fontSize: "13px" }}
          className="mb-1 label"
        >
          {labelForComboBox || labelView}
        </Label>
      )}
      <Input
        style={
          isStyled && !w100
            ? {
                width: width ? width : "326px",
                maxWidth: "400px",
                height: "32px",
                boxShadow: boxShadow && "0px 1px 9px rgba(0, 0, 0, 0.12)",
              }
            : w100
            ? { width: "100%", maxWidth: "100%" }
            : {}
        }
        placeholder={ph || ` Enter ${labelView}`}
        required={required}
        className={`${inputClassName ? inputClassName : ""} ${
          isAmount.includes(label) ? "text-end" : ""
        }`}
        type={type}
        value={
          differentName
            ? type === "number"
              ? Number(state[name])
              : state[name]
            : isAmount.includes(label)
            ? AmountFormat(Number(state[label]))
            : state[label]
        }
        onChange={(e) =>
          onChangeInput(e.target.value, differentName ? name : label)
        }
      />
    </div>
  );
};

export const FormNumericTextBox = ({
  width,
  required = false,
  isStyled = true,
  label,
  labelForBox,
  ph,
  onChangeInput,
  boxShadow,
  fc = true,
  className,
  format = "n2",
  steppers = false,
  differentName,
  state,
  name,
  w100 = false,
  labelWidth,
}) => {
  return (
    <div
      className={`${className} d-flex ${
        fc ? "flex-column" : "align-items-center"
      } right-align`}
    >
      <Label
        style={{ width: labelWidth ? "152px" : "auto", fontSize: "13px" }}
        className="mb-1 label"
      >
        {labelForBox}
      </Label>
      <NumericTextBox
        style={
          isStyled && !w100
            ? {
                width: width ? width : "326px",
                maxWidth: "400px",
                height: "32px",
                boxShadow: boxShadow && "0px 1px 9px rgba(0, 0, 0, 0.12)",
              }
            : w100
            ? { width: "100%", maxWidth: "100%" }
            : {}
        }
        placeholder={ph || ` Enter ${labelForBox}`}
        required={required}
        value={state[label]}
        className={"text-end"}
        onChange={(e) => onChangeInput(e.target.value, label)}
        spinners={steppers}
        format={format}
      />
    </div>
  );
};

export const FormDate = ({
  isStyled,
  disabled,
  className,
  label,
  customLabel,
  width,
  required,
  onChangeDate,
  state,
  checkbox,
  handleCheckboxChange,
  checked,
}) => {
  let locale = useSelector((state) => state.company.company.locale);

  const labelCopy = label;
  let labelView = labelCopy?.replaceAll("_", " ");
  const labelToProcess = [CONSTANT.DATE.DOB_OS2, CONSTANT.DATE.DOB_OS1];
  if (labelToProcess.includes(labelCopy)) labelView = labelView.slice(0, -3);
  return (
    <div className={`${className} d-flex flex-column `}>
      <Label className={`label mb-1`}>{customLabel || labelView}</Label>
      <div className={checkbox ? "date-form-to-wrapper" : ""}>
        {checkbox && (
          <Checkbox
            className="ms-2 mt-auto mb-auto"
            checked={checked}
            onChange={handleCheckboxChange}
          />
        )}
        <DatePicker
          className={checkbox ? "date-picker w-100 px-2" : "px-2"}
          onBlur={() => {
            if (state[label] === null || state[label] === undefined) {
              onChangeDate(
                {
                  value: null,
                },
                label
              );
            }
          }}
          required={required}
          toggleButton={(props) => (
            <CalenderCustomToggleButton
              {...props}
              show={state[label] !== null && state[label] !== undefined}
              onCancel={() =>
                onChangeDate(
                  {
                    value: null,
                  },
                  label
                )
              }
            />
          )}
          disabled={disabled}
          dateInput={CustomDateInput}
          formatPlaceholder={{
            year: " year ",
            month: " month ",
            day: " day ",
          }}
          style={
            isStyled
              ? {
                  width: width ? width : "323px",
                  maxWidth: "323px",
                  height: "32px",
                }
              : {}
          }
          value={state[label]}
          placeholder="Select Date"
          onChange={(e) => onChangeDate(e, label)}
        />
      </div>
    </div>
  );
};
