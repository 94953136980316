import React from "react";
import { useState, useEffect } from "react";
import { Button } from "@progress/kendo-react-buttons";
import { Label } from "@progress/kendo-react-labels";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { toast } from "react-toastify";
import styles from "../Settings.module.scss";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import {
    getRateCategories,
    getUserRatebyCategory,
    updateUserRates,
    createRateCategory,
    updateRateCategory,
} from "api";
import { handleApiError } from "Helper";
import { Dialog } from "@progress/kendo-react-dialogs";
import AddEdit from "./AddEdit";
import RateCell from "./RateCell";
import SuccessModal from "components/common/Modal/success";
import { useNavigate } from "react-router";




const UserRates = (props) => {
    const [ratesData, setRatesData] = useState([]);
    const [SelectedRateValue, setSelectedRateValue] = useState(null);
    const [rateCategories, setRateCategories] = useState([]);
    const [openForm, setOpenForm] = useState(false);
    const [type, setType] = useState("");
    const [editItem, setEditItem] = useState({
        ProductID: 1,
    });
    const [focusedInputIndex, setFocusedInputIndex] = useState(null);
    const [modalvalue, setmodalvalue] = useState(false);
    const navigate = useNavigate();


    useEffect(() => {
        getCategories();
    }, []);

    const handleCancelEdit = () => {
        setOpenForm(false);
    };

    const handleSubmit = async (event) => {
        try {
            if (type === "add") {
                if (!event.rateCategoryName || (!event.applications?.length && !event.allowAllApps)) {
                    return;
                }
                await createRateCategory(event);
                setmodalvalue(true);
            } else if (type === "edit") {
                if (!event.rateCategoryName || (!event.applications?.length && !event.allowAllApps)) {
                    return;
                }
                await updateRateCategory(event.rateCategoryId, event);
                setmodalvalue(true);
                setSelectedRateValue(event);
            }
        } catch (error) {
            handleApiError(error);
        }
        setOpenForm(false);
    };

    const getCategories = async () => {
        try {
            const response = await getRateCategories();
            setRateCategories(response?.data?.data);
        } catch (error) {
            handleApiError(error);
        }
    };

    const handleCategoryChange = async (e) => {
        try {
            if (e.value) {
                setSelectedRateValue(e.value);
                const response = await getUserRatebyCategory(e.value?.rateCategoryId);
                const ratesData = response?.data?.data;

                if (ratesData) {
                    setRatesData(ratesData);
                }
            } else {
                setSelectedRateValue(null);
                setRatesData([]);
            }
        } catch (error) {
            handleApiError(error);
        }
    };


    const handleSave = async () => {
        try {
            await updateUserRates(ratesData[0].rateCategoryId, ratesData);
            toast.success("Rates Updated");
        } catch (error) {
            handleApiError(error);
        }
    };

    const itemChange = (event) => {
        const dataIndex = ratesData.findIndex(
            (item) => item.userRef === event.dataItem.userRef
        );
        setFocusedInputIndex(dataIndex);
        const newData = ratesData.map((item) =>
            item.userRef === event.dataItem.userRef
                ? { ...item, rateValue: event.value }
                : item
        );
        setRatesData(newData);
    };

    return (
        <>
            <div>
                <div className="w-75 mx-auto">
                    <div className="d-flex justify-content-between align-items-start mt-4">
                        <div className="d-flex align-items-center">
                            <div className="d-flex flex-column">
                                <Label className="label mb-2">Rate Category</Label>
                                <ComboBox
                                    style={{
                                        width: "350px",
                                    }}
                                    data={rateCategories}
                                    iconClassName="wa"
                                    textField={"rateCategoryName"}
                                    value={SelectedRateValue}
                                    onChange={handleCategoryChange}
                                    placeholder={"Select Category"}
                                />
                            </div>
                        </div>
                        <div className="pe-4 mt-4">
                            <Button
                                className={`common-btn me-3`}
                                disabled={!SelectedRateValue}

                                onClick={(e) => {
                                    setEditItem(SelectedRateValue);
                                    setType("edit");
                                    setOpenForm(true);
                                }}
                            >
                                Edit Category
                            </Button>
                            <Button
                                className={`common-btn`}
                                onClick={(e) => {
                                    setType("add");
                                    setOpenForm(true);
                                }}
                            >
                                Add New
                            </Button>
                        </div>
                    </div>
                    <div className={`row mt-5 w-100`}>
                        <div className="settings-grid users-grid ">
                            <Grid
                                data={ratesData}
                                style={{ height: "55vh" }}
                                sortable={true}
                                onItemChange={itemChange}
                                dataItemKey={"userRef"}
                            >
                                <Column field="userRef" title="User Ref" width="220px" />
                                <Column field="displayName" title="Name" />
                                <Column
                                    field="rateValue"
                                    title="Rate"
                                    width={150}
                                    cell={(props) => (
                                        <RateCell
                                            {...props}
                                            isFocused={focusedInputIndex === props.dataIndex}
                                        />
                                    )}
                                    style="float: right"
                                />
                            </Grid>
                        </div>
                    </div>
                </div>

                <div className="pe-4 float-end mt-3"></div>
                <div className={`${styles["buttons-container"]} d-flex align-items-center justify-content-between col-12 p-3 `}>
                    <div></div>
                    <div className="d-flex align-items-center gap-2 me-2">
                        <Button
                            className={`common-btn bgBtnSecondary me-2`}
                            onClick={(e) => {
                                navigate("/settings");
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            className={`common-btn`}
                            onClick={() => {
                                handleSave();
                            }}
                        >
                            Save
                        </Button>
                    </div>
                </div>
            </div>

            {modalvalue && (
                <SuccessModal
                    onClose={() => {
                        getCategories();
                        setmodalvalue(false);
                    }}
                    message={type !== "add" ? "Category Updated" : "Category Added"}
                />
            )}

            {openForm && (
                <Dialog
                    onClose={handleCancelEdit}
                    width={600}
                    title={type === "add" ? "Add Rate Category" : "Edit Rate Category"}
                >
                    <div className="company">
                        <AddEdit
                            cancelEdit={handleCancelEdit}
                            onSubmit={handleSubmit}
                            item={editItem}
                            type={type}
                        />
                    </div>
                </Dialog>
            )}
        </>
    );
};

export default UserRates;
