import * as React from "react";
import IntegrationCard from "./IntegrationCard";
import ClioImage from "../../../assets/Settings/Integrations/Clio.png";
import HMRCImage from "../../../assets/Settings/Integrations/HMRC.png";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import AiVerification from "../../../assets/Settings/Integrations/AiVerfication.png";
import SolicitorsRegulation from "../../../assets/Settings/Integrations/SolicitorsRegulation.png";
import Sumsub from "../../../assets/Settings/Integrations/Sumsub.png";
import ClioPage from "./Clio/ClioPage";
import { getIntegrationDetails } from "api";
import { useEffect } from "react";
import HmrcPage from "./Hmrc/HmrcPage";
import SumsubPage from "./Sumsub/SumsubPage";

const connections = [
  {
    name: "HMRC MTD VAT Submission",
    connected: false,
    image: HMRCImage,
    integrationType: "HMRCVat",
  },
  {
    name: "Solicitors Register data share",
    connected: false,
    image: SolicitorsRegulation,
    integrationType: "Verify365",
  },
  {
    name: "Sumsub ID verifications",
    connected: false,
    image: Sumsub,
    integrationType: "Sumsub",
  },
];

const Integrations = (props) => {
  const [onShowModal, setOnShowModal] = React.useState();
  const [integrationData, setIntegrationData] = React.useState(connections);
  const getIntegrations = async () => {
    try {
      const response = await getIntegrationDetails();
      console.log(response);
      setIntegrationData((prevData) => {
        const result = [];
        prevData.map((item) => {
          response?.data?.data?.forEach((resItem) => {
            if (
              item.integrationType === resItem.integrationType &&
              resItem.show
            ) {
              let object = {
                ...item,
                ...resItem,
              };
              result.push(object);
            }
          });
        });
        return result;
      });
    } catch (error) {}
  };
  useEffect(() => {
    getIntegrations();
  }, []);

  return (
    <React.Fragment>
      <div className="vh-100 overflow-auto mb-5">
        <div className={`ms-4 mt-4 d-flex flex-wrap gap-5 mb-5`}>
          {integrationData.map((connection, index) => (
            <IntegrationCard
              key={index}
              name={connection?.name}
              connectionStatus={connection?.connected}
              image={connection?.image}
              onShowModal={setOnShowModal}
              integrationType={connection?.integrationType}
            />
          ))}
        </div>
        {onShowModal && (
          <Dialog
            title={
              integrationData.filter(
                (item) => item.integrationType === onShowModal
              )[0]?.name
            }
            onClose={() => {
              setOnShowModal();
            }}
            width={"70%"}
            height={"550px"}
          >
            <div className={`container p-0`} style={{ height: "430px" }}>
              <div className="p-2">
                {onShowModal === "Clio" && (
                  <ClioPage
                    connectionStatus={
                      integrationData.filter(
                        (item) => item.integrationType === "Clio"
                      )[0]?.connected
                    }
                    onClose={() => {
                      getIntegrations();
                    }}
                  />
                )}
                {onShowModal === "HMRCVat" && (
                  <HmrcPage
                    connectionStatus={
                      integrationData.filter(
                        (item) => item.integrationType === "HMRCVat"
                      )[0]?.connected
                    }
                    onClose={() => {
                      setOnShowModal();
                      getIntegrations();
                    }}
                  />
                )}
                {onShowModal === "Sumsub" && (
                  <SumsubPage
                    connectionStatus={
                      integrationData.filter(
                        (item) => item.integrationType === "Sumsub"
                      )[0]?.connected
                    }
                    onClose={() => {
                      // setOnShowModal();
                      getIntegrations();
                    }}
                  />
                )}
              </div>
            </div>
            <div className="dialog-footer mt-auto w-100">
              <Button
                className="common-btn bgBtnSecondary border-0 me-4"
                onClick={() => {
                  setOnShowModal();
                }}
              >
                Cancel
              </Button>
            </div>
          </Dialog>
        )}
      </div>
    </React.Fragment>
  );
};

export default Integrations;
