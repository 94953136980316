import * as React from "react";
import { useState, useEffect, useRef } from "react";
import { Grid, GridColumn, GridToolbar } from "@progress/kendo-react-grid";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import CustomInput from "components/common/Input";
import { Tile } from "components/UIComponents/Tile";
import { Button } from "@progress/kendo-react-buttons";
import { getContactDetails } from "api/index";
import { RedirectCell } from "components/common/GridComponents";
import { getSingleContact } from "api/index";
import { getContactCategoryInfo, attachMatterOnContact } from "api/index";
import { toast } from "react-toastify";
import { handleApiError } from "Helper";
import { Checkbox } from "@progress/kendo-react-inputs";
import ValidationCell from "./ValidationCell";

const CorrespondencePage = (props) => {
    const [contactList, setContactList] = useState();
    const [Itemfield, setField] = useState("");
    const [fixedScroll, setFixedScroll] = useState(true);
    const [correspondenceData, setCorrespondenceData] = useState();
    const [selectedId, setSelectedId] = useState(null);
    const [buttonClicked, setButtonClicked] = useState(false);
    const [references, setReferences] = useState({
        showRef1: false,
        showRef2: false,
        showRef3: false,
        labelForRef1: "",
        labelForRef2: "",
        labelForRef3: "",
    });
    const [editID, setEditID] = useState(null);
    const [isError, setisError] = useState(false);

    React.useEffect(() => {
        if (props.type === "Company") {
            getAllContactDetails();
        }
    }, [props?.type]);

    useEffect(() => {
        getContact();
    }, []);

    useEffect(() => {
        setCorrespondenceData(correspondenceData);
    }, [correspondenceData]);

    const getAllContactDetails = async () => {
        const { data } = await getContactDetails(props.contactId);

        const newData = data.data?.map((item, index) => ({
            ...item,
            id: index + 1,
        }));

        setContactList(newData);
    };

    const Redirect = (props) => <RedirectCell title="View Contact" />;

    const handleSelectButton = (data, e) => {
        setisError(false);
        setButtonClicked(false);
        if (data?.correspondenceFirstName !== "") {
            setContactList(
                contactList.filter(
                    (item) => !(item.inEdit && item.correspondenceFirstName == "")
                )
            );
        }
        setEditID(null);

        if (data) {
            setCorrespondenceData(data);
            setSelectedId(data.id); // Check the selected item
        } else {
            setCorrespondenceData(null);
        }
    };

    const customButton = (props) => {
        const onchangeCheckbox = (e) => {
            setButtonClicked(false);
            if (selectedId === props.dataItem.id) {
                setSelectedId(null); // Uncheck if already checked
            } else {
                setSelectedId(props.dataItem.id); // Check if not already checked
            }

            if (e.target.value) {
                handleSelectButton(props.dataItem, e);
            } else {
                setCorrespondenceData(null);
                handleSelectButton(null, e);
            }
        };

        return (
            <td>
                <Checkbox
                    className="mx-1 p-1"
                    onChange={onchangeCheckbox}
                    value={props.dataItem.id}
                    defaultChecked={true}
                    checked={selectedId === props.dataItem.id}
                />
            </td>
        );
    };

    const getContact = async () => {
        try {
            const { data } = await getSingleContact(props?.contactId);
            const { contactCategoryId } = data?.data ?? {};

            if (contactCategoryId) {
                getReferences(contactCategoryId);
            }
        } catch (error) {
            handleApiError(error);
        }
    };

    const getReferences = async (contactCategoryId) => {
        const { data } = await getContactCategoryInfo(contactCategoryId);
        const newData = data?.data || {};
        const {
            showRef1,
            showRef2,
            showRef3,
            labelForRef1,
            labelForRef2,
            labelForRef3,
        } = newData;
        setReferences({
            showRef1,
            showRef2,
            showRef3,
            labelForRef1,
            labelForRef2,
            labelForRef3,
        });
    };

    const addToMatter = async (dataItem) => {
        let correspondenceDataItem;

        if (dataItem) {
            correspondenceDataItem = {
                ...correspondenceData,
                ...dataItem,
                contactId: props.contactId,
            };
        } else {
            correspondenceDataItem = {
                ...correspondenceData,
                contactId: props.contactId,
            };
        }

        if (!isError) {
            setEditID(null);
            try {
                const response = await attachMatterOnContact(
                    props?.matterId,
                    correspondenceDataItem
                );
                if (response.status === 200) {
                    props.toggleDialog();
                    toast.success("Contact attached to Matter, successfully!");
                    {
                        props?.contactsData();
                    }
                }
            } catch (error) {
                toast.error("Error attaching contact to Matter");
            }
        } else {
            console.log("Error", correspondenceDataItem);
        }
    };

    const handleSubmit = async (dataItem) => {
        addToMatter(dataItem);
    };

    const AddReferences = () => {
        return (
            <Tile
                heading="References"
                className="px-4 mt-2 pb-3 mx-5"
                height={props.type === "Company" ? "" : "calc(100vh - 550px)"}
            >
                <Form
                    onSubmit={handleSubmit}
                    render={(formRenderProps) => {
                        return (
                            <>
                                <FormElement className="d-flex  align-items-center justify-content-between">
                                    {references.showRef1 && (
                                        <Field
                                            disabled={false}
                                            name="reference1"
                                            label={references?.labelForRef1 || "Reference 1"}
                                            required={false}
                                            ph="Reference 1"
                                            className="mb-4 "
                                            style={{ width: "200px", height: "30px" }}
                                            component={CustomInput}
                                        />
                                    )}
                                    {references.showRef2 && (
                                        <Field
                                            disabled={false}
                                            name="reference2"
                                            label={references?.labelForRef2 || "Reference 2"}
                                            required={false}
                                            ph="Reference 2"
                                            className="mb-4"
                                            style={{ width: "200px", height: "30px" }}
                                            component={CustomInput}
                                        />
                                    )}
                                    {references.showRef3 && (
                                        <Field
                                            disabled={false}
                                            name="reference3"
                                            label={references?.labelForRef3 || "Reference 3"}
                                            required={false}
                                            ph="Reference 3"
                                            className="mb-4"
                                            style={{ width: "200px", height: "30px" }}
                                            component={CustomInput}
                                        />
                                    )}

                                    <Button
                                        id={
                                            formRenderProps.valueGetter("reference1") ||
                                                formRenderProps.valueGetter("reference2") ||
                                                formRenderProps.valueGetter("reference3")
                                                ? "attach-contact"
                                                : ""
                                        }
                                        type="submit"
                                        style={{ visibility: "hidden" }}
                                    ></Button>
                                </FormElement>
                            </>
                        );
                    }}
                />
            </Tile>
        );
    };

    const MyValidationCell = React.memo((props) => (
        <ValidationCell
            {...props}
            isError={isError}
            itemChange={itemChange}
            Itemfield={Itemfield}
        />
    ));

    const itemChange = (event) => {
        const inEditID = event.dataItem.id;
        const field = event.field || "";
        setField(field);
        setContactList((prevState) =>
            prevState?.map((item) =>
                item.id === inEditID ? { ...item, [field]: event.value } : item
            )
        );
        if (field === "correspondenceFirstName") {
            if (event.value === "") {
                setisError(true);
            } else {
                setisError(false);
            }
        }
        setCorrespondenceData((prevState) => ({
            ...prevState,
            [field]: event.value,
        }));
    };

    const addRecord = (props) => {
        if (!buttonClicked) {
            setButtonClicked(true);
            const newID = contactList?.length + 1 || 1;
            const newRecord = {
                inEdit: true,
                id: newID,
                rating: 0,
                correspondenceFirstName: "",
                correspondenceLastName: "",
                correspondenceJobTitle: "",
                correspondenceEmail: "",
                correspondencePhone: "",
            };

            setContactList((prevState) => [...prevState, newRecord]);
            setEditID(newID);
            setSelectedId(newID);
            setisError(true);
        } else return;
    };

    const closeEdit = (event) => {
        if (event.target === event.currentTarget) {
            setEditID(null);
        }
    };

    return (
        <>
            <div
                style={{
                    height: "calc(100vh - 220px)",
                    overflow: "hidden",
                }}
            >
                {props.type === "Company" && (
                    <div
                        className="px-5"
                        style={{
                            // height: "calc(100vh - 220px)",
                            overflow: "hidden",
                        }}
                    >
                        <p className="fs-6 fw-bold text-start mt-3 mb-1">
                            {props?.contactName?.name}
                        </p>
                        <div className="d-flex align-items-center justify-content-between">
                            <p className="fs-6 text-start ">
                                {props?.contactName?.fullAddress}
                            </p>
                            <p>{props?.contactName?.email}</p>
                            <p>{props?.contactName?.phone}</p>
                        </div>
                        <Tile
                            height={"calc(100vh - 455px)"}
                            heading="People"
                            className="px-4 mb-1"
                        >
                            <Grid
                                className="grid-smallfont"
                                data={contactList?.map((item) => ({
                                    ...item,
                                    inEdit: item.id === editID || !item.id,
                                }))}
                                sortable={true}
                                resizable={true}
                                fixedScroll={fixedScroll}
                                style={{
                                    height: "calc(100vh - 530px)",
                                }}
                                editField="inEdit"
                                onItemChange={itemChange}
                            >
                                <GridToolbar>
                                    <div onClick={closeEdit}>
                                        <button
                                            title="Add new"
                                            className="text-center button-small common-btn bgBtnPrimary border-0 pt-2"
                                            onClick={addRecord}
                                        >
                                            Add new
                                        </button>
                                    </div>
                                </GridToolbar>
                                <GridColumn
                                    headerClassName="ps-4"
                                    className="gridBoldField ps-4 "
                                    field="correspondenceFirstName"
                                    title="Name "
                                    width="150"
                                    resizable={true}
                                    cell={MyValidationCell}
                                />

                                <GridColumn
                                    field="correspondencePhone"
                                    title="Phone"
                                    width="150"
                                />
                                <GridColumn
                                    field="correspondenceEmail"
                                    title="Email"
                                    width="150"
                                />
                                <GridColumn
                                    field="correspondenceJobTitle"
                                    title="Job Title"
                                    width="150"
                                />
                                <GridColumn width="30" cell={Redirect} />
                                <GridColumn width="80" cell={customButton} />
                            </Grid>
                        </Tile>
                    </div>
                )}

                {(references.showRef1 ||
                    references.showRef2 ||
                    references.showRef3) && <AddReferences />}
            </div>

            <div className="dialog-footer align-items-center justify-content-between ">
                <div>
                    <Button
                        className="common-btn bgBtnPrimary border-0 ms-5"
                        onClick={() => {
                            props.togglePage();
                        }}
                    >
                        Back
                    </Button>
                    <Button
                        className="common-btn bgBtnSecondary border-0 ms-2"
                        onClick={() => {
                            props.toggleDialog();
                        }}
                    >
                        Cancel
                    </Button>
                </div>
                <Button
                    className="common-btn bgBtnPrimary border-0 me-5"
                    onClick={() => {
                        const button = document.getElementById("attach-contact");
                        if (button) {
                            button.click();
                        } else {
                            handleSubmit("");
                        }
                    }}
                >
                    Attach Contact
                </Button>
            </div>
        </>
    );
};

export default CorrespondencePage;
