import { Dialog } from "@progress/kendo-react-dialogs";
import { Loader } from "@progress/kendo-react-indicators";
import { createClientSetvicesTask, getClientData } from "api";
import { formatDate } from "@progress/kendo-intl";
import DataList from "components/MatterDetails/DataList";
import { Tile } from "components/UIComponents/Tile";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Button } from "@progress/kendo-react-buttons";

export const ClientIdVerification = ({ matterId, onClose, client }) => {
  const [isLoading, setIsLoading] = useState(false);
  const user = useSelector((state) => state.currentuser.userlist);

  const idVerificationSubmitter = async (id) => {
    try {
      setIsLoading(true);
      await createClientSetvicesTask(matterId, {
        date: new Date(),
        details: "ID Check",
        // "dateDueBy": "2024-10-30",
        feeEarnerRef: user?.userRef,
        userScreenId: null,
        active: true,
        type: "IDVerification",
      });
      toast.success("ID Verification task added");
      onClose();
    } catch (error) {
      toast.error("Unexpected Error Occured");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog
      className="add-dialog"
      title={"ID Verification"}
      width={700}
      onClose={onClose}
    >
      <div
        style={{
          height: "280px",
        }}
      >
        {isLoading ? (
          <div className="d-flex align-items-center justify-content-center h-100">
            <Loader size="large" />
          </div>
        ) : (
          <div className="container p-4">
            <div className="row g-2">
              <div className="col-12">
                <Tile
                  heading="Personal Details"
                  height={"230px"}
                  className="px-4 py-2 overflow-auto"
                >
                  <div className="d-flex align-items-start justify-content-start">
                    <div className="w-100">
                      <DataList
                        text={"Client Name:"}
                        value={client?.clientName}
                        valueClass={"mb-2"}
                        keyClass={"mb-2"}
                        className={"w-100"}
                      />
                      <DataList
                        text={"Date of birth:"}
                        value={
                          client?.dateOfBirth
                            ? formatDate(
                                new Date(client?.dateOfBirth),
                                "d",
                                "en-GB"
                              )
                            : ""
                        }
                        keyClass={"mb-2"}
                        valueClass={"mb-2"}
                        className={"w-100 mt-1"}
                      />
                      <DataList
                        text={"Phone No"}
                        value={client?.phoneNo}
                        keyClass={"mb-2"}
                        valueClass={"mb-2"}
                        className={"w-100 mt-1"}
                      />
                      <DataList
                        text={"Email"}
                        value={client?.email}
                        keyClass={"mb-2"}
                        valueClass={"mb-2"}
                        className={"w-100 mt-1"}
                      />
                      <DataList
                        text={"Address"}
                        value={client?.address}
                        keyClass={"mb-2"}
                        valueClass={"mb-2"}
                        className={"w-100 mt-1"}
                      />
                    </div>
                  </div>
                </Tile>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="dialog-footer">
        <Button
          className="bgBtnSecondary common-btn me-2"
          type={"submit"}
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          className="common-btn me-3"
          type={"submit"}
          onClick={idVerificationSubmitter}
          disabled={isLoading}
        >
          Send
        </Button>
      </div>
    </Dialog>
  );
};
