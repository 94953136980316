import { ERROR, RESPONSE_ERROR_LOGIN, ACCESS_DENIED } from "../types/index";
import store from "../store";
import { toast } from "react-toastify";

export const combineAddressLines = (line1, line2) => {
  let line_1 = line1,
    line_2 = line2;
  if (!line1) line_1 = "";
  if (!line2) line_2 = "";

  return `${line_1}\n${line_2}`;
};

const ethnicity = [
  "Mixed",
  "White British",
  "Other White",
  "Other Asian",
  "Mixed White and Asian",
  "Mixed White and Black Caribbean",
  "White Gypsy or Irish Traveller",
  "British people",
  "Irish people",
];
const disability = ["Intellectual", "Physical", "Sensory", "Mental illness"];

export const setClinetFormInitialValues = (
  clientData,
  vatCodeName,
  feeEarnerName,
  ccname,
  bname
) => {
  let personStateCopy = clientData;
  let contact;
  let address;
  let bankAcccount;
  let person;
  contact = { ...personStateCopy?.contact };
  address = { ...personStateCopy.contact?.address };
  bankAcccount = { ...personStateCopy.contact?.bankAcccount };
  person = { ...personStateCopy.contact?.person };
  person.dateOfBirth = person?.dateOfBirth ? new Date(person.dateOfBirth) : "";
  person.dateOfDeath = person?.dateOfDeath ? new Date(person.dateOfDeath) : "";
  person.gender = person.gender === "M" ? "Male" : "Female";
  person.ethnicity = ethnicity.find(
    (item, index) => index === person.ethnicity
  );
  person.disability = disability.find(
    (item, index) => index === person.disability
  );
  contact.email = contact?.email1;

  return {
    ...personStateCopy,
    ...contact,
    ...person,
    ...address,
    ...bankAcccount,
    line1: combineAddressLines(contact?.address?.line1, contact.address?.line2),
    vatCodeId: {
      vatCodeId: personStateCopy.vatCodeId,
      details: vatCodeName,
      representation: vatCodeName
        ? `${vatCodeName?.vatCodeId} - ${vatCodeName?.details} - ${vatCodeName.rate}%`
        : "",
    },
    feeEarner: {
      userRef: personStateCopy.feeEarnerRef,
      displayname: feeEarnerName,
      representation: feeEarnerName
        ? `${feeEarnerName?.userRef} -${feeEarnerName?.displayname} `
        : "",
    },
    currency: {
      currencyCode: personStateCopy.currencyCode,
      name: ccname,
      representation: ccname ? `${ccname?.currencyCode} - ${ccname?.name}` : "",
    },
    branch: { branchId: personStateCopy.branchId, branchName: bname },
    siline1: combineAddressLines(
      personStateCopy.billingAddress?.line1,
      personStateCopy.billingAddress?.line2
    ),
    sitown: personStateCopy.statementAddress?.town,
    sistate: personStateCopy.statementAddress?.state,
    sipostcode: personStateCopy.statementAddress?.postcode,
    sicountry: personStateCopy.statementAddress?.country,
    siline1: combineAddressLines(
      personStateCopy.statementAddress?.line1,
      personStateCopy.statementAddress?.line2
    ),
    bitown: personStateCopy.billingAddress?.town,
    bistate: personStateCopy.billingAddress?.state,
    bipostcode: personStateCopy.billingAddress?.postcode,
    bicountry: personStateCopy.billingAddress?.country,
    biline1: combineAddressLines(
      personStateCopy.billingAddress?.line1,
      personStateCopy.billingAddress?.line2
    ),
    ...personStateCopy.contact?.company,
    // ...personStateCopy.contact.company.correspondenceContact,
    corrEmail: personStateCopy.contact?.company?.corrEmail,
    corrPhone: personStateCopy.contact?.company?.corrPhone,
    corrJobTitle: personStateCopy.contact?.company?.corrJobTitle,
    corrFirstName: personStateCopy.contact?.company?.corrFirstName,
    correspondenceContactphone2:
      personStateCopy.contact?.company?.correspondenceContact?.phone2,
    correspondenceContactmobileNo:
      personStateCopy.contact?.company?.correspondenceContact?.mobileNo,
    ...personStateCopy.contact?.company?.correspondenceContact?.person,
    registeredAddressline1: combineAddressLines(
      personStateCopy.contact?.company?.registeredAddress?.line1,
      personStateCopy.contact?.company?.registeredAddress?.line2
    ),
    registeredAddresstown:
      personStateCopy.contact?.company?.registeredAddress?.town,
    registeredAddressstate:
      personStateCopy.contact?.company?.registeredAddress?.state,
    registeredAddresspostcode:
      personStateCopy.contact?.company?.registeredAddress.postcode,
    registeredAddresscountry:
      personStateCopy.contact?.company?.registeredAddress.country,

    correspondenceContactline1: combineAddressLines(
      personStateCopy.contact?.company?.correspondenceContact?.address?.line1,
      personStateCopy.contact?.company?.correspondenceContact?.address?.line2
    ),
    correspondenceContacttown:
      personStateCopy.contact?.company?.correspondenceContact?.address?.town,
    correspondenceContactstate:
      personStateCopy.contact?.company?.correspondenceContact?.address?.state,
    correspondenceContactpostcode:
      personStateCopy.contact?.company?.correspondenceContact?.address
        ?.postcode,
    correspondenceContactcountry:
      personStateCopy.contact?.company?.correspondenceContact?.address?.country,
  };
};

export const handleApiError = (error) => {
  if (error?.response?.status === 401) {
    store.dispatch({ type: RESPONSE_ERROR_LOGIN });
  }
  if (error?.response?.status === 403) {
    store.dispatch({
      type: ACCESS_DENIED,
      payLoad: { status: true, message: error?.response?.data },
    });
  }
  if (error?.response?.data) {
    toast.error(error.response.data.message);
  }
  store.dispatch({ type: ERROR, payload: error });
};
