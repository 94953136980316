import * as React from "react";
import editIcon from "../../../assets/common/editBoxIcon.png";
import saveIcon from "../../../assets/common/green-check.png";
import deleteIcon from "../../../assets/common/delete.svg";

import cancelIcon from "../../../assets/common/redcross.png";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import ConfirmationDialog from "components/common/ConfirmationDialog";
import { getCaseWorkFlowsItem } from "api";

export const MyCommandCell = (props) => {
  const { dataItem, appCode } = props;
  const inEdit = dataItem[props.editField];
  const isNewItem = dataItem.workflowId === undefined;
  const [visible, setVisible] = React.useState(false);
  const [showMsg, setShowMsg] = React.useState(false);

  const MessageDialog = (props) => {
    return (
      <Dialog title={"Message"} onClose={toggleMsgDialog} width={450}>
        <p className="text-center fs-lg p-3">
          You are not allowed to delete a folder having subitems!
        </p>
        <div className="d-flex align-items-center justify-content-end mt-3 me-4 pe-1 pb-3">
          <Button className="button-small common-btn" onClick={toggleMsgDialog}>
            OK
          </Button>
        </div>
      </Dialog>
    );
  };

  const onDeleteData = () => {
    props.update(dataItem, false);
    setVisible(!visible);
  };

  const toggleDialog = () => {
    setVisible(!visible);
  };

  const toggleMsgDialog = () => {
    setShowMsg(!showMsg);
  };

  const handleDelete = async () => {
    const items = await getCaseWorkFlowsItem(appCode, dataItem?.workflowId);

    if (items.data?.data?.length === 0) {
      toggleDialog();
    } else {
      toggleMsgDialog();
    }
  };

  return inEdit ? (
    <td className="k-command-cell">
      <img
        className="cursor-pointer me-3 "
        onClick={() =>
          isNewItem ? props.add(dataItem) : props.update(dataItem)
        }
        src={saveIcon}
        alt={isNewItem ? "Add" : "Update"}
      />

      <img
        className="cursor-pointer"
        onClick={() =>
          isNewItem ? props.discard(dataItem) : props.cancel(dataItem)
        }
        src={cancelIcon}
        alt={isNewItem ? "Discard" : "Cancel"}
      />
    </td>
  ) : (
    <td className="k-command-cell">
      <img
        className="cursor-pointer me-3"
        onClick={() => props.edit(dataItem)}
        src={editIcon}
        style={{
          width: "20px",
          height: "20px",
        }}
        alt="Edit"
      />

      <img
        className="cursor-pointer me-2"
        onClick={() => handleDelete()}
        src={deleteIcon}
        style={{
          width: "20px",
          height: "20px",
        }}
        alt="Edit"
      />

      {visible && (
        <ConfirmationDialog
          content={`Are you sure you want to remove ${
            dataItem.workflowItemsType === 0
              ? "folder"
              : dataItem.workflowItemsType
          } "${dataItem.name}"`}
          onClickYes={() => {
            onDeleteData();
          }}
          onClickNo={() => toggleDialog()}
          onClose={() => toggleDialog()}
        />
      )}

      {showMsg && <MessageDialog />}
    </td>
  );
};
