import * as React from "react";
import { Button } from "@progress/kendo-react-buttons";
import { Dialog } from "@progress/kendo-react-dialogs";
import { useState } from "react";
import { toast } from "react-toastify";
import { Label } from "@progress/kendo-react-labels";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import SuccessModal from "components/common/Modal/success";

import * as api from "api/index";
import { useDispatch, useSelector } from "react-redux";
import styles from "../Settings.module.scss";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { clearProfilePhoto } from "actions/currentuser.action";
import { PageTabItemTitle } from "components/tabs/TabItem/PageTabItemTitle";
import { handleApiError } from "Helper";
import AddEditProfile from "./AddEditProfile";
import { AccountsGrid, CaseMangementGrid, ReportsGrid } from "./SecurityGrids";
import { useNavigate } from "react-router";

const Title = (props) => {
    return (
        <div
            // className="ms-2"
            style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                height: "100%",
            }}
        >
            <div
                className={` ${styles["tabs-container"]} d-flex`}
                style={{
                    color: props.selected === props.content ? "black" : "#757575",
                    fontWeight: props.selected === props.content ? "bold" : "",
                }}
            >
                {props.content}
            </div>
        </div>
    );
};

const SecurityProfiles = (props) => {
    const [openForm, setOpenForm] = React.useState(false);
    const navigate = useNavigate();
    const [tabs, setTabs] = React.useState([
        "Case Managements",
        "Accounts",
        "Reports",
    ]);

    const [profileData, setProfIlesData] = useState([]);
    const [actualFeeEarners, setActualFeeEarners] = useState([]);
    const [profileInfo, setProfileInfo] = useState();
    const [selected, setSelected] = React.useState(0);
    const [profiles, setProfiles] = useState([]);
    const [caseProfiles, setCaseProfiles] = useState([]);
    const [accountProfiles, setAccountProfiles] = useState([]);
    const [reportProfiles, setReportProfiles] = useState([]);
    const dispatch = useDispatch();
    const [editItem, setEditItem] = React.useState({
        ProductID: 1,
    });
    const [isInActive, setIsInactive] = useState(false);
    const [addnewform, setaddnewform] = React.useState(false);
    const [type, setType] = React.useState("");
    const [modalvalue, setmodalvalue] = React.useState(false);

    const handleSubmit = async (event) => {
        try {
            if (type === "add") {
                await api.createNewProfile(event);
                setmodalvalue(true);
            } else if (type === "edit") {
                await api.editSecurityProfile(event, event.profileId);
                setmodalvalue(true);
                setprofileValue(event);
            } else if (type === "copy") {
                const data = {
                    ...profileValue,
                    profileName: event.profileName,
                    profileId: undefined,
                };
                await api.createNewProfile(data);
                setmodalvalue(true);
            }
        } catch (error) {
            handleApiError(error);
        }
        dispatch(clearProfilePhoto());
        setOpenForm(false);
    };

    const [products, setProducts] = React.useState({
        data: [],
        total: 20,
    });

    const saveData = async () => {
        let caseCount = 0;
        let reportCount = 0;
        let accountCount = 0;
        caseProfiles.map((item) => {
            if (item.allowAccess) {
                caseCount += item.value;
            }
        });
        accountProfiles.map((item) => {
            if (item.allowAccess) {
                accountCount += item.value;
            }
        });
        reportProfiles.map((item) => {
            if (item.allowAccess) {
                reportCount += item.value;
            }
        });
        try {
            let data = {
                profileId: profileValue.profileId,
                profileName: profileValue.profileName,
                caseAccessCode: caseCount,
                accountAccessCode: accountCount,
                reportAccessCode: reportCount,
            };
            await api.updateSpecificProfiles(data, profileInfo.profileId);
            toast.success("Settings Updated");
            // await onChangeProfile({ value: profileValue })
        } catch (error) {
            handleApiError(error);
        }
    };

    const [profileValue, setprofileValue] = useState();
    const handleChange = (type, e) => {
        let temp = profiles;
        if (type === "case") {
            caseProfiles?.map((item) => {
                if (item.name === e.dataItem.name) {
                    return (item.allowAccess = e.value);
                }
            });
            setCaseProfiles([...caseProfiles]);
        } else if (type === "report") {
            reportProfiles?.map((item) => {
                if (item?.name === e.dataItem.name) {
                    return (item.allowAccess = e.value);
                }
            });
            setReportProfiles([...reportProfiles]);
        } else if (type === "account") {
            accountProfiles.map((item) => {
                if (item.name === e.dataItem.name) {
                    return (item.allowAccess = e.value);
                }
            });
            setAccountProfiles([...accountProfiles]);
        }
    };

    const fetchGridsData = async () => {
        try {
            const data = await api.getSecurityProfilesItems();
            setTimeout(() => {
                setCaseProfiles(data?.data?.data?.case);
                setAccountProfiles(data?.data?.data?.accounts);
                setReportProfiles(data?.data?.data?.reports);
            }, 100);
        } catch (error) {
            handleApiError(error);
        }
    };

    const dataFetcher = async (e) => {
        try {
            const data = await api.getSecurityProfiles();
            let dataToPass = data?.data?.data;
            setProfIlesData(dataToPass);
            // onChangeProfile(data?.data?.data[0])
        } catch (error) {
            handleApiError(error);
        }
    };

    React.useEffect(() => {
        dataFetcher();
        fetchGridsData();
    }, []);

    const updateProfiles = (e) => {
        let array = [];
        caseProfiles.map((item) => {
            if ((e.caseAccessCode & item.value) === item.value) {
                item.allowAccess = true;
            } else {
                item.allowAccess = false;
            }
            array.push(item);
        });
        setCaseProfiles([...array]);
        accountProfiles.map((item) => {
            if ((e.accountAccessCode & item.value) === item.value) {
                return (item.allowAccess = true);
            } else {
                return (item.allowAccess = false);
            }
        });
        setAccountProfiles([...accountProfiles]);
        reportProfiles.map((item) => {
            if ((e.reportAccessCode & item.value) === item.value) {
                return (item.allowAccess = true);
            } else {
                return (item.allowAccess = false);
            }
        });
        setReportProfiles([...reportProfiles]);
    };

    const onChangeProfile = async (e) => {
        try {
            if (e.value !== undefined && e.value !== null) {
                try {
                    setprofileValue(e.value);
                    const data = await api.getSpecificProfiles(e.value.profileId);
                    updateProfiles(data?.data.data);
                    setProfileInfo(data?.data.data);
                } catch (error) {
                    handleApiError(error);
                }
            } else if (e.value === null) {
                setprofileValue({
                    profileName: "",
                    value: null,
                });
                updateProfiles({
                    caseAccessCode: 0,
                    accountAccessCode: 0,
                    reportAccessCode: 0,
                });
            }
        } catch (error) {
            handleApiError(error);
        }
    };

    const handleCancelEdit = () => {
        dispatch(clearProfilePhoto());
        setOpenForm(false);
    };

    const title = () => {
        if (type === "add") {
            return "Add Profile";
        } else if (type === "edit") {
            return "Edit Profile";
        } else {
            return "Copy Profile";
        }
    };

    const handleSelect = (e) => {
        setSelected(e.selected);
    };

    const checkInactive = () => {
        if (profileValue?.active === false) {
            setIsInactive(true);
        } else {
            setIsInactive(false);
        }
    };

    React.useEffect(() => {
        checkInactive();
    }, [profileValue]);


    return (
        <>
            {!addnewform ? (
                <div className="w-75 mx-auto">
                    <div className="d-flex justify-content-between align-items-center mt-5">
                        <div className="d-flex align-items-center">
                            <div className="d-flex flex-column">
                                <Label className="label mb-2">Select Profile</Label>
                                <ComboBox
                                    style={{
                                        width: "350px",
                                    }}
                                    data={profileData}
                                    textField={"profileName"}
                                    iconClassName="wa"
                                    value={profileValue}
                                    onChange={onChangeProfile}
                                    placeholder={"Search Team"}
                                />
                            </div>
                            <div>
                                <Label className="label ms-3 mt-4 pt-1 text-danger">
                                    {isInActive && "Inactive"}
                                </Label>
                            </div>
                        </div>
                        <div className="pe-4 mt-3">
                            <Button
                                className={`common-btn me-3`}
                                disabled={
                                    profileValue === undefined ||
                                    (profileValue !== undefined && profileValue.value === null)
                                }
                                onClick={(e) => {
                                    setEditItem(profileValue);
                                    setType("edit");
                                    setOpenForm(true);
                                }}
                            >
                                Edit Profile
                            </Button>
                            <Button
                                className={`common-btn me-3`}
                                disabled={
                                    profileValue === undefined ||
                                    (profileValue !== undefined && profileValue.value === null)
                                }
                                onClick={(e) => {
                                    setType("copy");
                                    setOpenForm(true);
                                }}
                            >
                                Copy Profile
                            </Button>
                            <Button
                                className={`common-btn`}
                                onClick={() => {
                                    setType("add");
                                    setOpenForm(true);
                                }}
                            >
                                Add Profile
                            </Button>
                        </div>
                    </div>
                    <div className={`row mt-2 w-100`}>
                        <TabStrip
                            selected={selected}
                            onSelect={handleSelect}
                            keepTabsMounted={true}
                            className="mt-3 pagetab companytab"
                        >
                            {tabs.map((item, index) => {
                                return (
                                    item && (
                                        <TabStripTab
                                            title={
                                                <PageTabItemTitle
                                                    content={item}
                                                    selected={selected}
                                                    //   className={`mb-sm-2 mb-xl-0`}
                                                    title={item}
                                                    index={tabs.indexOf(item)}
                                                />
                                            }
                                            key={index}
                                        >
                                            <div style={{ marginTop: "2%" }}>
                                                {item === "Case Managements" && (
                                                    <CaseMangementGrid
                                                        value={profileValue}
                                                        data={caseProfiles}
                                                        onChange={handleChange}
                                                    />
                                                )}
                                                {item === "Accounts" && (
                                                    <AccountsGrid
                                                        data={accountProfiles}
                                                        onChange={handleChange}
                                                    />
                                                )}
                                                {item === "Reports" && (
                                                    <ReportsGrid
                                                        data={reportProfiles}
                                                        onChange={handleChange}
                                                    />
                                                )}
                                            </div>
                                        </TabStripTab>
                                    )
                                );
                            })}
                        </TabStrip>
                    </div>

                    <div className="pe-4 float-end mt-3"></div>
                    <div
                        className={`${styles["buttons-container"]} d-flex align-items-center justify-content-between col-12 p-3`}
                    >
                        <div></div>
                        <div className="d-flex align-items-center gap-2 me-2">
                            <Button
                                className={`common-btn bgBtnSecondary me-2`}
                                onClick={(e) => {
                                    navigate("/settings");
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                className={`common-btn`}
                                onClick={() => {
                                    saveData();
                                }}
                                disabled={
                                    profileValue === undefined ||
                                    (profileValue !== undefined && profileValue.value === null)
                                }
                            >
                                Save
                            </Button>
                        </div>
                    </div>
                </div>
            ) : (
                <></>
            )}
            {modalvalue && (
                <SuccessModal
                    onClose={() => {
                        dataFetcher();
                        setmodalvalue(false);
                    }}
                    message={type === "edit" ? "Profile Updated" : "Profile Added"}
                />
            )}
            {openForm && (
                <Dialog
                    onClose={handleCancelEdit}
                    // className="mattersDialog"
                    width={600}
                    title={title()}
                >
                    <AddEditProfile
                        cancelEdit={handleCancelEdit}
                        onSubmit={handleSubmit}
                        item={editItem}
                        type={type}
                    />
                </Dialog>
            )}
        </>
    );
};

export default SecurityProfiles;
