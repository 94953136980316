import * as React from "react";
import {
  Chart,
  ChartSeries,
  ChartSeriesItem,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartValueAxis,
  ChartValueAxisItem,
  ChartLegend,
  ChartYAxis,
  ChartYAxisItem,
  ChartXAxis,
  ChartXAxisItem,
} from "@progress/kendo-react-charts";

export const chartDefaultV4Colors = ["#F1F0FF", "#707070"];

const ChartComponent = (props) => {
  return (
    <Chart
      style={{
        height: "200px",
        width: "100%",
      }}
      transitions={false}
      seriesColors={chartDefaultV4Colors}
      // transitions={true}
      onSeriesClick={(e) => {
        props.onSeriesClick(e);
      }}
    >
      <ChartCategoryAxis>
        <ChartCategoryAxisItem
          categories={props.categories}
          startAngle={45}
          maxDivisions={5}
          majorGridLines={{ visible: false }}
        />
      </ChartCategoryAxis>
      <ChartValueAxis>
        <ChartValueAxisItem majorGridLines={{ visible: false }} />
      </ChartValueAxis>
      <ChartYAxis>
        <ChartYAxisItem max={5} />
      </ChartYAxis>
      <ChartSeries>
        {props?.series.map((item, idx) => (
          <ChartSeriesItem
            key={idx}
            type="column"
            spacing={0}
            opacity={0.9}
            style="smooth"
            tooltip={{
              visible: true,
            }}
            data={item.data}
            name={item.name}
          />
        ))}
      </ChartSeries>
      <ChartLegend position="bottom" orientation="horizontal" visible={false} />
    </Chart>
  );
};
export default ChartComponent;
