import {
  START_LOADING,
  STOP_LOADING,
  ERROR,
  SUCCESS,
  GET_CONTACTS_LIST,
  GET_CONTACTS_INFO,
  CLEAR_CONTACT_INFO,
  CONTACT_ERROR,
  RESET_ERROR,
  CONTACT_CREATED,
  GET_CONTACTS_BY_CATEGORY,
  GET_CONTACTS_ON_MATTER,
  GET_ALL_CONTACTS
} from "../types/index.js";

const contactsReducer = (
  state = {
    IsError: false,
    isSuccess: false,
    isLoading: undefined,
    contactsInfo: {},
    contactError:null
  },
  action
) => {
  switch (action.type) {
    case START_LOADING:
      return { ...state, isLoading: true };
    case STOP_LOADING:
      return { ...state, isLoading: false };
    case GET_CONTACTS_LIST:
      return {
        ...state,
        contactsList: action.payload,
      };
    case GET_CONTACTS_INFO:
      return {
        ...state,
        contactsInfo: action.payload,
      };
    case GET_CONTACTS_BY_CATEGORY:
      return {
        ...state,
        contactsByCategory: action.payload,
      }; 
    case GET_CONTACTS_ON_MATTER:
      return {
        ...state,
        contactsOnMatter: action.payload,
      };
      case GET_ALL_CONTACTS:
      return {
        ...state,
        allContacts: action.payload,
      }; 
    case CLEAR_CONTACT_INFO:
      return {
        ...state,
        contactsInfo: {},
      };
    case CONTACT_ERROR:
      return {
        ...state,
        contactError: true,
      };
    case CONTACT_CREATED:
      return {
        ...state,
        contactError: false,
      };
    case RESET_ERROR:
      return {
        ...state,
        contactError: null,
      };
    case ERROR:
      return { ...state, IsError: true, isSuccess: false };
    case SUCCESS:
      return { ...state, IsError: false, isSuccess: true };
    default:
      return state;
  }
};
export default contactsReducer;
