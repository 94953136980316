import * as React from "react";
import styles from "./DashboardPage.module.scss";
import { MattersChart, MattersStatsCard } from "./MattersChart";
import ChartContainer from "./PieChart";
import { useState } from "react";
import TasksGrid from "./TasksGrid";
import { TimeTagetChart, TimeRecordedStatsCard } from "./TimeTargetChart";
import {
  BillingTargetChart,
  BillingTargetStatsCard,
  UnBilledTargetStatsCard,
} from "./BillingTargetChart";
import { DebtorsChart } from "./DebtorsChart";
import { CreditorsChart } from "./CreditorsChart";
import { DashboardCard } from "components/Dashboard";
import MyMattersGrid from "./MyMattersList";
import { FilterComponent } from "components/common/FiltersComponent";
import { getAccessRightsData } from "api";
import { useEffect } from "react";
import { Skeleton } from "@progress/kendo-react-indicators";

const Dashboard = () => {
  const [filters, setFilters] = useState({});
  const [allowAccessData, setAllowAccessData] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const analyzeFilterParamters = (params) => {
    const paramsToFilter = {
      branch: params?.branch,
      appCode: params?.appCode,
      feeEarnerRef: params?.feeearnerRef,
      teamId: params?.teamId,
      FromDate: params?.FromDate,
      ToDate: params?.ToDate,
      duration: params?.duration,
    };
    setFilters(paramsToFilter);
  };
  // const handleReposition = (e) => {
  //   setData(e.value);
  // };
  const getAccessRights = async () => {
    try {
      const data = await getAccessRightsData();
      setAllowAccessData(data?.data?.data);
      setIsLoading(false);
      // setLoadedContent(false);
    } catch (error) {}
  };

  useEffect(() => {
    getAccessRights();
  }, [localStorage.getItem("url")]);

  const dateDifference =
    (new Date(filters.ToDate) - new Date(filters.FromDate)) /
    (1000 * 60 * 60 * 24);

  return (
    <div
      className={`container-fluid overflow-auto px-5 mb-5 ${styles.dashboardContainer} `}
    >
      <div className="row">
        <div className="ms-1 mt-4">
          {/* <Filters
                        updateFilters={(e) => {
                          setFilters(e);
                        }}
                        component={"Graphs"}
                      /> */}

          {isLoading ? (
            <Skeleton
              shape="rectangle"
              className="mb-3"
              style={{
                width: "100%",
                height: "70px",
              }}
            />
          ) : (
            <FilterComponent
              allowAccessData={allowAccessData}
              updateFilters={(data) => analyzeFilterParamters(data)}
              // isRefreshed={isRefreshed}
              component={"Dashboard"}
            />
          )}
        </div>
      </div>

      <div className="row gy-2 gx-3 justify-content-center ">
        <div className={`col-lg-3 col-6`}>
          <DashboardCard className={` ${styles.tiles} ${styles.statsTile} `}>
            <TimeRecordedStatsCard filters={filters} />
          </DashboardCard>
        </div>

        <div className={`col-lg-3 col-6`}>
          <DashboardCard className={` ${styles.tiles} ${styles.statsTile} `}>
            <BillingTargetStatsCard filters={filters} />
          </DashboardCard>
        </div>

        <div className={`col-lg-3 col-6`}>
          <DashboardCard className={` ${styles.tiles} ${styles.statsTile} `}>
            <UnBilledTargetStatsCard filters={filters} />
          </DashboardCard>
        </div>

        <div className={`col-lg-3 col-6`}>
          <DashboardCard className={` ${styles.tiles} ${styles.statsTile} `}>
            <MattersStatsCard filters={filters} />
          </DashboardCard>
        </div>
      </div>

      <div className="row gy-2 gx-3 mt-2">
        <div className={`col-xl-${dateDifference > 7 ? 12 : 4}`}>
          <DashboardCard className={` ${styles.tiles} ${styles.chartsTile} `}>
            <TimeTagetChart filters={filters} />
          </DashboardCard>
        </div>

        <div className={`col-xl-${dateDifference > 7 ? 12 : 4}`}>
          <DashboardCard className={` ${styles.tiles} ${styles.chartsTile} `}>
            <BillingTargetChart filters={filters} />
          </DashboardCard>
        </div>

        <div className={`col-xl-${dateDifference > 7 ? 12 : 4}`}>
          <DashboardCard className={` ${styles.tiles} ${styles.chartsTile} `}>
            <MattersChart filters={filters} />
          </DashboardCard>
        </div>
      </div>

      {/*Show Debtors and Creditors if user have accounts license*/}
      <div className="row gy-2 gx-3 mt-2">
        <div className="col-xl-6 col-12">
          <DashboardCard className={` ${styles.tiles} ${styles.chartsTile} `}>
            <DebtorsChart filters={filters} />
          </DashboardCard>
        </div>

        <div className="col-xl-6 col-12">
          <DashboardCard className={` ${styles.tiles} ${styles.chartsTile} `}>
            <CreditorsChart filters={filters} />
          </DashboardCard>
        </div>
      </div>

      <div className="row gy-2 gx-3 mt-2">
        <div className="col-xl-8 col-12">
          <DashboardCard className={` ${styles.tiles} `}>
            <MyMattersGrid />
          </DashboardCard>
        </div>

        <div className="col-xl-4 col-12">
          <DashboardCard className={` ${styles.tiles} h-100  `}>
            <TasksGrid className="h-100" />
          </DashboardCard>
        </div>
      </div>

      {/*<div class="row">*/}

      {/*    <div class="col-md-6 d-flex">*/}
      {/*        <div class="p-3 bg-primary text-white w-100">*/}
      {/*            <h3>Column 1</h3>*/}
      {/*            <p>This column has dynamic content, so its height changes based on how much content is inside it.*/}
      {/*                <br />aa*/}
      {/*                <br />aa*/}
      {/*                <br />aa*/}
      {/*            </p>*/}
      {/*        </div>*/}
      {/*    </div>*/}

      {/*    <div class="col-md-6 d-flex">*/}
      {/*        <div class="p-3 bg-secondary text-white w-100">*/}
      {/*            <h3>Column 2</h3>*/}
      {/*            <p>The height of this column will match the height of the first column, regardless of the content.</p>*/}
      {/*        </div>*/}
      {/*    </div>*/}
      {/*</div>*/}
    </div>
  );
};
export default Dashboard;
