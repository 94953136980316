import React from "react";
import { Field } from "@progress/kendo-react-form";
import CustomInput from "components/common/Input";
import AddressInputComponent from "components/common/AddressInputComponent";
import { Tile } from "components/UIComponents/Tile";

const CompanyDetails = ({ initialValues, readOnly }) => {
  return (
    <div className="row ">
      <div className="col-12">
        <Tile
          heading="Company Details"
          height={"100%"}
          className="px-4 py-2  overflow-auto"
        >
          <div
            className="container pb-1"
            style={{
              height: "calc(-23rem + 100vh)",
              maxHeight: "500px",
              minHeight: "360px",
            }}
          >
            <div className={`row`}>
              <Field
                disabled={readOnly}
                name="companyRegNo"
                mr="20px"
                label="Registration Number"
                ph="Enter Registration Number"
                width="255px"
                component={CustomInput}
              />
              <Field
                disabled={readOnly}
                name="vatNo"
                mr="20px"
                label="VAT Number"
                ph="Enter VAT Number"
                className="mt-3"
                width="255px"
                component={CustomInput}
              />
              <Field
                disabled={readOnly}
                name="sicCode"
                mr="20px"
                className="mt-3"
                label="SIC Code"
                ph="Enter SIC Code"
                width="255px"
                component={CustomInput}
              />
              <AddressInputComponent
                name="Registered Address"
                readOnly={readOnly}
                type="registeredAddress"
                width="255px"
                style={{
                  marginTop: "20px",
                  width: "640px",
                }}
              />
            </div>
          </div>
        </Tile>
      </div>
    </div>
  );
};

export default CompanyDetails;
