import React from "react";
import { AutoComplete } from "@progress/kendo-react-dropdowns";
import { searchCompanies } from "api";
import { useState } from "react";
import { Button } from "@progress/kendo-react-buttons";

const AutoCompleteInput = ({ value: initialValue, ...props }) => {
  const [state, setState] = useState({
    value: initialValue || "",
    data: [],
    opened: false,
  });
  const [valueSelected, setValueSelected] = useState(false);

  const fetchData = async (value) => {
    try {
      const response = await searchCompanies(value);

      setState((prevState) => ({
        ...prevState,
        data: response?.data?.data,
      }));
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  const onChange = (event) => {
    const value = event.target.value;
    const eventType = event.nativeEvent.type;
    const nativeEvent = event.nativeEvent;
    const valueSelected =
      eventType === "click" ||
      (eventType === "keydown" && nativeEvent.keyCode === 13);

    setValueSelected(valueSelected);
    if (value.length >= 3) {
      fetchData(value);
      setState({
        value: value,
        opened: true,
      });
    } else {
      setState({
        value: value,
        data: [],
        opened: false,
      });
    }

    if (valueSelected && state.data.some((item) => item.title === value)) {
      setState((prevState) => ({
        ...prevState,
        opened: false,
      }));
    }
    if (props.setClientName) {
      props.setClientName(value);
    }
    props.onChange(event);
  };

  const onBlur = () => {
    setState((prevState) => ({
      ...prevState,
      opened: false,
    }));
    if (valueSelected && props?.blurRef) {
      props?.blurRef();
    }
  };

  return (
    <AutoComplete
      //   required
      onBlur={onBlur}
      data={state.data}
      value={state.value}
      textField="title"
      name={props.name}
      onChange={onChange}
      opened={state.opened}
      itemRender={props.itemRender}
      placeholder={props.ph}
      style={{
        width: "260px",
        height: "32px",
      }}
      className={props.className}
    />
  );
};

export default AutoCompleteInput;
