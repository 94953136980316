import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "@progress/kendo-react-intl";
import "@progress/kendo-react-dropdowns";
import "react-router-dom";
import MattersCombobox from "../Postings/MattersComboBox";
import * as api from "../../api/index";
import { Button } from "@progress/kendo-react-buttons";
import {
  clearMattersBalances,
  getMattersBalances,
  getMattersInfo,
} from "actions/matters.action";
import { useState } from "react";
import ViewPostingDetails from "../Postings/PostingDetails";
import { getPostingDetails, resetFields } from "actions/postingDetails.action";
import MatterAccount from "./MatterAccount";
import { changeLedgerLink, clearLedgerLink } from "actions/ledgerLinks.action";
import MatterLedgerGrid from "components/LedgerGrid";
import { handleApiError } from "Helper";

const MatterLedger = ({ component }) => {
  const dispatch = useDispatch();

  const [transNo, settransNo] = React.useState("");
  const [showForm, setshowForm] = React.useState(false);
  const [searchValue, setSearchValue] = React.useState();
  const [matterId, setMatterId] = React.useState(null);
  const [currentmattersInfo, setMattersInfo] = useState({});

  const [showDDA, setShowDDA] = useState(true);
  const [list, setList] = React.useState([]);
  const [total, setTotal] = useState();
  const [fixedScroll, setFixedScroll] = useState(true);

  const mattersInfo = useSelector((state) => state.mattersList.mattersDetails);
  const mattersBalances = useSelector(
    (state) => state.mattersList.mattersBalances
  );
  const redirectedMatterLedger = useSelector(
    (state) => state.ledgerLinks.currentMatterLedger
  );

  const apiDatFetcher = (page) => {
    api
      .getMattersPostings(searchValue, page, 200)
      .then((data) => {
        setList((prevList) => [...prevList, ...data.data.data]);
        setTotal(data.data.total);
      })
      .catch((error) => {
        handleApiError(error);
      });
  };

  const clearData = () => {
    setList([]);
    setMatterId(null);
    dispatch(clearLedgerLink("Matters", ""));
    setMattersInfo({});
    dispatch(clearMattersBalances("Matter Ledger"));
  };

  const onChangematter = async (event) => {
    if (event === null) {
      clearData();
    }
    if (event !== null) {
      if (!event?.value?.matterId) return;
      setFixedScroll(false);
      try {
        const { data } = await api.getMattersPostings(
          event.value.matterId,
          1,
          200
        );
        setTotal(data.total);
        setList(data.data);
        setSearchValue(event.value.matterId);
        setMatterId(event.value.matterId);
        dispatch(getMattersInfo(event.value.matterId));
        dispatch(getMattersBalances(event.value.matterId, "Matter Ledger"));
      } catch (error) {
        handleApiError(error);
      }
    }
  };
  const fetchMoreData = () => {
    let page = 1;
    let totalRecords = list.length;
    page = Math.ceil(totalRecords / 200) + 1;
    if (totalRecords >= total) return;
    setFixedScroll(true);
    apiDatFetcher(page);
  };

  useEffect(() => {
    if (
      mattersInfo !== undefined &&
      matterId !== null &&
      matterId !== undefined
    ) {
      setMattersInfo(mattersInfo.data);
    }
  }, [mattersBalances, mattersInfo]);

  useEffect(() => {
    if (redirectedMatterLedger !== null) {
      onChangematter({
        value: redirectedMatterLedger,
      });
      dispatch(clearLedgerLink("MatterLedger", redirectedMatterLedger));
    }
  }, [redirectedMatterLedger]);

  const refreshData = () => {
    setList([]);
    apiDatFetcher(1);
    dispatch(getMattersInfo(matterId));
    dispatch(getMattersBalances(matterId, "Matter Ledger"));
    setFixedScroll(false);
  };

  const grid = (
    <div>
      <MatterLedgerGrid
        gridData={list}
        showDDA={showDDA}
        setShowDDA={setShowDDA}
        getMoreData={fetchMoreData}
        fixedScroll={fixedScroll}
        handleActionRef={(e, transNo) => {
          settransNo(transNo);
          setshowForm(true);
        }}
      />
    </div>
  );

  return (
    <div className={`${component === "Matter" && "px-5"} page`}>
      <div className="row">
        <div className="col mt-3">
          {component !== "Matter" && (
            <div className="mb-3">
              <label className="me-3">Matter Ref</label>
              <MattersCombobox
                onChangematter={onChangematter}
                onFilterChange={() => clearData()}
                value={redirectedMatterLedger}
                component={"MatterLedger"}
                width={280}
              />
              <Button
                className="common-btn ms-2"
                icon="refresh"
                onClick={refreshData}
              >
                Refresh
              </Button>
            </div>
          )}

          <div className="row fs-md">
            <div className="col-1" style={{ minWidth: "80px" }}>
              Details:{" "}
            </div>
            <div className="col">{currentmattersInfo.details}</div>
          </div>
          <div className="row fs- fs-md">
            <div className="col-1" style={{ minWidth: "80px" }}>
              Branch:{" "}
            </div>
            <div className="col">{currentmattersInfo.branch}</div>
          </div>
          <div className="row fs-md">
            <div className="col-1" style={{ minWidth: "80px" }}>
              App:{" "}
            </div>
            <div className="col">{currentmattersInfo.application}</div>
          </div>
        </div>

        <div
          className={`${
            component === "Matter" ? "col-5" : "col-xxl-4 "
          } d-flex justify-content-end`}
        >
          <MatterAccount component={"Matter Ledger"} matterId={matterId} />
        </div>

        {showForm && (
          <ViewPostingDetails
            transNo={transNo}
            cancelEdit={() => {
              setshowForm(false);
              dispatch(resetFields());
            }}
          />
        )}
      </div>

      <div
        className="grid-layout-container font-14 d-flex justify-space-between"
        style={{ marginBottom: 25 }}
      ></div>
      {grid}
    </div>
  );
};

export default MatterLedger;
