// customHooks.js
import { useState, useEffect } from 'react';
import * as api from "../../api/index";
import { handleApiError } from "Helper";
import { prepareFeeearnerData } from "components/common/ComboRepresentationFunctions";

export const useFetchCurrency = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchCurrency = async () => {
      try {
        const { data } = await api.getActiveCurrency();
        data.data.map((item) => {
          item.representation =
            item.currencyCode + "  -  " + item.name + "  -  " + item.symbol;
          return item;
        });
        setData(data.data);
      } catch (error) {
        handleApiError(error);
      }
    };

    fetchCurrency();
  }, []);

  return data;
};

export const useFetchFeeEarner = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchFeeEarner = async () => {
      try {
        const { data } = await api.getfeeearner();
        const preparedData = prepareFeeearnerData(data.data);
        setData(preparedData);
      } catch (error) {
        handleApiError(error);
      }
    };

    fetchFeeEarner();
  }, []);

  return data;
};

export const useFetchVatCode = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchVatCode = async () => {
      try {
        const { data } = await api.getactiveVats();
        data?.data?.map((item) => {
          item.representation =
            item.vatCodeId + "  -  " + item.details + "  -  " + item.rate + "%";
          return item;
        });
        setData(data.data);
      } catch (error) {
        handleApiError(error);
      }
    };

    fetchVatCode();
  }, []);

  return data;
};