import * as React from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import FixedHeaderItems from "./FixedHeaderItems";
import styles from '../Settings.module.scss'

const ViewFixedHeader = (props) => {
  return (
    <Dialog
      title={`Fixed Fee Items ${props.item.name}`}
      onClose={props.cancelEdit}
      width={800}
      height={600}
      className={'settings-dialog'}
    >
      <FixedHeaderItems item={props.item} />
    </Dialog>
  );
};

export default ViewFixedHeader;
