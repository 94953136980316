import * as React from "react";
import { Dialog } from "@progress/kendo-react-dialogs";

import { AiOutlineCheckCircle } from "react-icons/ai";
import { BiErrorCircle } from "react-icons/bi";

const MatterModal = ({ showModal, message, success, onClose }) => {
  if (!showModal) return null;
  return (
    <Dialog width={400} height={230}>
      <div className="d-flex flex-column justify-content-around align-items-center h-100 px-5 text-center">
        {success ? (
          <AiOutlineCheckCircle size={"50px"} color="green" />
        ) : (
          <BiErrorCircle color="red" size={"50px"} />
        )}
        <p style={{ fontWeight: "500", fontSize: "18px" }}> {message}</p>
        <button className={`common-btn border-0  `} onClick={onClose}>
          {success ? "Done" : "Close"}
        </button>
      </div>
    </Dialog>
  );
};

export default MatterModal;
