import {
  START_LOADING,
  STOP_LOADING,
  ERROR,
  SUCCESS,
  GET_BRANCHES_NAMES,
} from "../types/index.js";

const BranchesNames = (
  state = {
    IsError: false,
    isSuccess: false,
    isLoading: undefined,
    branchesList: [{}],
  },
  action
) => {
  switch (action.type) {
    case START_LOADING:
      return { ...state, isLoading: true };
    case STOP_LOADING:
      return { ...state, isLoading: false };
    case GET_BRANCHES_NAMES:
      sessionStorage.setItem(
        "activebranches",
        JSON.stringify(action.payload.data)
      );
      return {
        ...state,
        branchesList: action.payload.data,
      };
    // case UPDATE_BRANCH:
    //   return {
    //     ...state,
    //     branchesList: action.payload,
    //   };
    case ERROR:
      return { ...state, IsError: true, isSuccess: false };
    case SUCCESS:
      return { ...state, IsError: false, isSuccess: true };
    default:
      return state;
  }
};
export default BranchesNames;
