import * as React from "react";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { Button } from "@progress/kendo-react-buttons";
import { CellRender, RowRender } from "./Renderers";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { updateTransactionperiods } from "actions/transactionperiod.action";
import { DateFormatCell } from "components/common/formatter";
import styles from "./TransactionPeriod.module.scss";
import settingStyles from "../Settings/Settings.module.scss";

const EDIT_FIELD = "inEdit";

const TransactionPeriodList = () => {
    const [data, setData] = React.useState([
        { startDate: "", endDate: "", closedOn: "" },
    ]);
    const dispatch = useDispatch();
    const [tempState, settempstate] = React.useState();
    const transactionalPeriods = useSelector(
        (state) => state.transactionperiods?.transactionList
    );
    const periods = useSelector((state) => {
        let list = state.transactionperiods?.transactionList?.map((item) => {
            const templist = item;
            templist.inEdit = true;

            return templist;
        });
        return list;
    });
    useEffect(() => {
        if (periods !== null && periods !== undefined) {
            setData(periods);
        }
    }, [transactionalPeriods]);

    const [changes, setChanges] = React.useState(false);

    const enterEdit = (dataItem, field) => {
        const newData = data.map((item) => ({
            ...item,
            [EDIT_FIELD]: item.period === dataItem.period ? field : undefined,
        }));
        // setData(newData);
    };

    const exitEdit = () => {
        const newData = data.map((item) => ({ ...item, [EDIT_FIELD]: undefined }));
        setData(newData);
    };

    const TransactionClosedCell = (props) => {
        return (
            <td>
                <input
                    onClick={() => {
                        setData((prevData) => {
                            const prevTransactionData = prevData.filter(
                                (item) => item.period !== props.dataItem.period
                            );
                            const data = [
                                ...prevTransactionData,
                                {
                                    ...props.dataItem,
                                    closed: !props.dataItem.closed,
                                },
                            ];
                            return [...data].sort((a, b) => a.period - b.period);
                        });
                        if (!changes) setChanges(true);
                    }}
                    type={"checkbox"}
                    style={{ marginLeft: "30px" }}
                    className={`cursor-pointer ${styles["checkbox"]}`}
                    name="closed"
                    readOnly={true}
                    checked={props.dataItem.closed === true ? true : false}
                />
            </td>
        );
    };

    const TransactionCleanCell = (props) => {
        return (
            <td className="">
                <input
                    type={"checkbox"}
                    className={`${styles["checkbox"]}`}
                    style={{ marginLeft: "30px" }}
                    name="clean"
                    readOnly={true}
                    checked={props.dataItem.clean === true ? true : false}
                />
            </td>
        );
    };

    const saveChanges = () => {
        dispatch(updateTransactionperiods(data));
        setChanges(false);
    };

    const cancelChanges = () => {
        setData(data);
        setChanges(false);
    };

    const itemChange = (event) => {
        settempstate(event.dataItem);
        let field = event.field || "";
        event.dataItem[field] = event.value;
        let newData = data.map((item) => {
            if (item.period === event.dataItem.period) {
                item[field] = event.value;
            }

            return item;
        });
        setData(newData);
        setChanges(true);
    };

    const customCellRender = (td, props) => (
        <CellRender
            originalProps={props}
            td={td}
            enterEdit={enterEdit}
            editField={EDIT_FIELD}
        />
    );

    const customRowRender = (tr, props) => (
        <RowRender
            originalProps={props}
            tr={tr}
            exitEdit={exitEdit}
            editField={EDIT_FIELD}
            component="Transaction"
        />
    );

    class CenterTextHeaderCell extends React.Component {
        render() {
            return (
                <span className="d-flex pb-2 justify-content-center">
                    {this.props.title}
                </span>
            );
        }
    }

    return (
        <React.Fragment>
            <Grid
                data={data}
                dataItemKey={"period"}
                // onItemChange={itemChange}
                editable={false}
                // cellRender={customCellRender}
                rowRender={customRowRender}
                // editField={EDIT_FIELD}
                className={`mt-3 mb-3 fs-md ${styles.period_gridheight}`}
            >
                <Column field="period" title="Period" editable={false} width="150px" />
                <Column
                    field="startDate"
                    title="Start Date"
                    width="120px"
                    editable={false}
                    cell={DateFormatCell}
                />
                <Column
                    field="endDate"
                    title="End Date"
                    editable={false}
                    cell={DateFormatCell}
                    width="120px"
                />
                <Column
                    field="closed"
                    title="Closed"
                    editor="boolean"
                    width="60px"
                    headerCell={CenterTextHeaderCell}
                    cell={TransactionClosedCell}
                    className="text-center"
                />
                <Column
                    field="clean"
                    editable={false}
                    title="Clean"
                    editor="boolean"
                    width="60px"
                    cell={TransactionCleanCell}
                    headerCell={CenterTextHeaderCell}
                    className="d-flex align-items-center justify-content-center"
                />
                <Column
                    field="closedOn"
                    title="Closed On"
                    editable={false}
                    cell={DateFormatCell}
                    width="120px"
                />
                <Column
                    field="closedBy"
                    title="Closed By"
                    editable={false}
                    width="100px"
                />
            </Grid>
            <div
                className={`d-flex gap-3 mb-4 p-2 justify-content-between align-items-center ${settingStyles["buttons-container"]}`}
            >
                <div style={{ fontSize: "14px", color: "red" }} className="ms-3">
                    <i>{changes && `You have unsaved changes`}</i>
                </div>
                <div>
                    <div>
                        <Button
                            title="Cancel Changes"
                            className="common-btn bgBtnSecondary me-3"
                            onClick={cancelChanges}
                            disabled={!changes}
                        >
                            Cancel
                        </Button>
                        <Button
                            title="Save Changes"
                            className="common-btn me-2"
                            onClick={saveChanges}
                            disabled={!changes}
                        >
                            Save
                        </Button>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};
export default TransactionPeriodList;
