import * as React from "react";
import * as ReactDOM from "react-dom";
import { toODataString } from "@progress/kendo-data-query";
import { useDispatch, useSelector } from "react-redux";
import { getUsersList, getUsersListSearch } from "actions/users.action";

export const ProductsLoader = (props) => {
  const dispatch = useDispatch();
  const { usersList } = useSelector((state) => state.usersList);
  const lastSuccess = React.useRef("");
  const pending = React.useRef("");
  const requestDataIfNeeded = () => {
    if (
      pending.current ||
      toODataString(props.dataState) === lastSuccess.current
    ) {
      return;
    }
    pending.current = toODataString(props.dataState);
    if (props.searchValue === null || props.searchValue === undefined || props.searchValue === '') {
      dispatch(
        getUsersList(
          props.dataState.skip / props.dataState.take + 1,
          props.dataState.take
        )
      );
    } else {
      dispatch(
        getUsersListSearch(
          props.dataState.skip / props.dataState.take + 1,
          props.dataState.take, props.searchValue
        )
      );
    }
  };

  React.useEffect(() => {
    if (usersList) {
      lastSuccess.current = pending.current;
      pending.current = "";
      if (toODataString(props.dataState) === lastSuccess.current) {
        props.onDataReceived.call(undefined, {
          data: usersList.data,
          total: usersList.total,
        });
      } else {
        requestDataIfNeeded();
      }
    }
    requestDataIfNeeded();
  }, [usersList, pending, props.dataState, lastSuccess, props.searchValue]);
  return pending.current ? <LoadingPanel /> : null;
};

const LoadingPanel = () => {
  const loadingPanel = (
    <div className="k-loading-mask">
      <span className="k-loading-text">Loading</span>
      <div className="k-loading-image" />
      <div className="k-loading-color" />
    </div>
  );
  const gridContent = document && document.querySelector(".k-grid-content");
  return gridContent
    ? ReactDOM.createPortal(loadingPanel, gridContent)
    : loadingPanel;
};
