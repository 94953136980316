import styles from "./pageTabItemTitle.module.scss";

export const PageTabItemTitle = ({ title, index, selected, className }) => (
  <div
    className={`${className} 
                    ${styles.tabItem} 
                    ${
                      selected === index
                        ? styles.selectedItem
                        : styles.unselectedItem
                    }                      
                    d-flex align-items-center justify-content-center`}
  >
    <span
      className={` ${
        selected === index
          ? styles["selectedItem-text"]
          : styles["unselectedItem-text"]
      }`}
    >
      {title}
    </span>
  </div>
);

export default PageTabItemTitle;
