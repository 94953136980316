import React from "react";
import { Label } from "@progress/kendo-react-labels";
import { NumericTextBox } from "@progress/kendo-react-inputs";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { getClientRefExample, getMatterRefExample } from "api";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import styles from "../Settings.module.scss";
import Icon from "components/Global/icon";
import { useNavigate } from "react-router";
import { Button } from "@progress/kendo-react-buttons";
import { updateCompany } from "actions/company.action";

const selectionData = [
  { name: "None", value: "None" },
  { name: "Client Ref", value: "ClientRef" },
];
const separatorData = [
  { name: "None", value: "N" },
  { name: "/", value: "/" },
  { name: "-", value: "-" },
];

function SectionHeading({ sectionName, example }) {
  return (
    <div className="d-flex align-items-center">
      <div className={styles["setup-div"]}>
        <span className={styles["setup-text"]}> {sectionName}</span>
      </div>
      <div className="w-100  border-bottom border-opacity-50"></div>
      <div className={styles["setup-div"]}>
        <span className={styles["setup-text"]}>
          {" "}
          <span className={styles["gray-text"]}>Ref eg : </span>
          {example}
        </span>
      </div>
    </div>
  );
}

const MatterSetup = ({ updateAddress }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [clientRef, setClientRef] = useState("");
  const [matterRef, setMatterRef] = useState("");
  const [clientRefParameters, setClientRefParameters] = useState({
    lettersLength: 0,
    numbersLength: 2,
  });

  const [matterRefParameters, setMatterRefParameters] = useState({
    selection: { value: "None", name: "None" },
    separator: { value: "None", name: "N" },
    numbersLength: 0,
    initialCheck: false,
  });

  const companyData = useSelector((state) => state.company.company);

  useEffect(() => {
    if (companyData) {
      setClientRefParameters({
        lettersLength: companyData?.clientRefLetters,
        numbersLength: companyData?.clientRefNumbers,
      });
      setMatterRefParameters({
        selection: selectionData.filter(
          (item) => item.value === companyData?.matterRefSelection
        )[0],
        separator: separatorData.filter(
          (item) => item.value === companyData?.matterRefSeparator
        )[0],
        numbersLength: companyData?.matterRefNumbers,
        initialCheck: true,
      });
    }
  }, [companyData]);

  useEffect(() => {
    if (matterRefParameters.initialCheck) {
      getClientRef();
      getMatterRef();
      updateAddress({
        matterRefNumbers: matterRefParameters?.numbersLength,
        matterRefSelection: matterRefParameters?.selection?.value,
        matterRefSeparator: matterRefParameters?.separator?.value,
        clientRefLetters: clientRefParameters?.lettersLength,
        clientRefNumbers: clientRefParameters?.numbersLength,
      });
    }
  }, [matterRefParameters, clientRefParameters]);

  const submitHandler = () => {
    const data = {
      matterRefNumbers: matterRefParameters?.numbersLength,
      matterRefSelection: matterRefParameters?.selection?.value,
      matterRefSeparator: matterRefParameters?.separator?.value,
      clientRefLetters: clientRefParameters?.lettersLength,
      clientRefNumbers: clientRefParameters?.numbersLength,
    };
    if (Object.keys(data).length === 0) {
      toast.success("Company Updated");
    } else {
      dispatch(updateCompany(companyData.id, data));
    }
  };

  const getClientRef = async () => {
    try {
      const response = await getClientRefExample(
        "John Smith",
        clientRefParameters?.lettersLength,
        clientRefParameters?.numbersLength
      );
      setClientRef(response?.data);
    } catch (error) {}
  };

  const getMatterRef = async () => {
    try {
      const response = await getMatterRefExample(
        "JOH004",
        matterRefParameters?.selection?.value,
        matterRefParameters?.separator?.value,
        matterRefParameters?.numbersLength
      );
      setMatterRef(response?.data);
    } catch (error) {}
  };

  return (
    <div className={`${styles["setup-container"]} mt-5`}>
      <div className="mt-3 mb-3">
        <SectionHeading sectionName={"Client Reference"} example={clientRef} />
        <div className="mt-4 ms-5 d-flex align-items-center">
          <div className="ms-5 me-4 d-flex flex-column">
            <Label className="label mb-2">Letters length</Label>
            <NumericTextBox
              width={100}
              min={0}
              max={8}
              value={clientRefParameters.lettersLength}
              onChange={(e) => {
                if (clientRefParameters.numbersLength + e.value <= 10) {
                  setClientRefParameters({
                    ...clientRefParameters,
                    lettersLength: e.value,
                  });
                } else {
                  toast.error(
                    "Sum of letters and numbers shouldnt be greater than 10"
                  );
                }
              }}
            />
          </div>
          <div className="ms-3 d-flex flex-column">
            <Label className="label mb-2">Numbers length</Label>
            <NumericTextBox
              width={100}
              min={2}
              max={8}
              value={clientRefParameters.numbersLength}
              onChange={(e) => {
                if (clientRefParameters.lettersLength + e.value <= 10) {
                  setClientRefParameters({
                    ...clientRefParameters,
                    numbersLength: e.value,
                  });
                } else {
                  toast.error(
                    "Sum of letters and numbers shouldnt be greater than 10"
                  );
                }
              }}
            />
          </div>
        </div>
      </div>
      <div className="mt-5">
        <SectionHeading sectionName={"Matter Reference"} example={matterRef} />
        <div className="mt-4 ms-5 d-flex align-items-center">
          <div className="ms-5 d-flex flex-column">
            <Label className="label mb-2">Selection</Label>
            <ComboBox
              iconClassName="wa"
              icon={<Icon icon="arrow-down" />}
              data={selectionData}
              clearButton={false}
              style={{ width: 200 }}
              textField={"name"}
              dataItemKey={"value"}
              value={matterRefParameters.selection}
              onChange={(e) => {
                if (e.value?.value === "None") {
                  setMatterRefParameters({
                    ...matterRefParameters,
                    selection: e.value,
                    separator: { name: "None", value: "N" },
                  });
                  return;
                }
                setMatterRefParameters({
                  ...matterRefParameters,
                  selection: e.value,
                });
              }}
            />
          </div>
          <div className="ms-4 d-flex flex-column">
            <Label className="label mb-2">Separator</Label>
            <ComboBox
              iconClassName="wa"
              icon={<Icon icon="arrow-down" />}
              data={separatorData}
              clearButton={false}
              style={{ width: 100 }}
              textField={"name"}
              dataItemKey={"value"}
              value={matterRefParameters.separator}
              onChange={(e) => {
                if (matterRefParameters.selection?.value === "None") return;
                setMatterRefParameters({
                  ...matterRefParameters,
                  separator: e.value,
                });
              }}
            />
          </div>
          <div className="ms-4 d-flex flex-column">
            <Label className="label mb-2">Number length</Label>
            <NumericTextBox
              width={100}
              min={2}
              max={8}
              value={matterRefParameters.numbersLength}
              onChange={(e) =>
                setMatterRefParameters({
                  ...matterRefParameters,
                  numbersLength: e.value,
                })
              }
            />
          </div>
        </div>
      </div>
      <div
        className={`${styles["buttons-container"]} d-flex align-items-center justify-content-between col-12 p-3`}
        style={{ width: "100%", marginTop: "250px" }}
      >
        <div></div>
        <div className="d-flex align-items-center gap-2 me-2">
          <Button
            className={`common-btn bgBtnSecondary me-3`}
            type="button"
            onClick={() => {
              navigate(`/settings`);
            }}
          >
            Cancel
          </Button>
          <Button
            className={`common-btn`}
            type="button"
            onClick={() => {
              submitHandler();
            }}
          >
            Update
          </Button>
        </div>
      </div>
    </div>
  );
};

export default MatterSetup;
//
