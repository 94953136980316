import * as React from "react";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { AmountFormat } from "components/common/formatter";
import { DateFormat } from "components/common/formatter";
import * as api from "api/index";
import styles from "./Matter.module.scss";
import DataList from "components/MatterDetails/DataList";
import EstimateFeeImage from "../../assets/estimate-fee.png";
import ContactCard, {
  ContactsListItem,
  NoContacts,
} from "components/MatterDetails/ContactCard";
import { formatDate } from "@progress/kendo-intl";
import { handleApiError } from "Helper";
import MatterClientAccount, { ClientList } from "./MatterAccounts";
import EstimateFee from "./EstimateFee";
import {
  ContainerCard,
  PrimaryBadgeComponent,
} from "components/MatterDetails/ContactCard";
import { Tile } from "components/UIComponents/Tile";

const MattersDetailsPage = (props) => {
  const matterDetails = useSelector((state) => state.mattersList);
  const [balances, setBalances] = useState({});
  const [showEstimatedFee, setShowEstimatedFee] = useState(null);
  let locale = useSelector((state) => state.company.company.locale);
  const [currentMatterDetails, setcurrentMatterDetails] = useState();

  useEffect(() => {
    if (matterDetails !== null) {
      setcurrentMatterDetails(
        matterDetails?.matterViewDetails[props.reference]?.data
      );
    }
  });

  const dataGetter = async () => {
    try {
      const data = await api.getMattersBalances(currentMatterDetails.matterId);
      if (data?.data?.data !== undefined) {
        setBalances(data.data.data);
      }
    } catch (error) {
      handleApiError(error);
    }
  };

  useEffect(() => {
    if (currentMatterDetails !== undefined) {
      dataGetter();
    }
  }, [currentMatterDetails]);

  return (
    <div className={`container-fluid`}>
      <div className="row mt-3 g-2">
        <div className="col-12 col-xl-7">
          <Tile
            heading={"Matter Details"}
            headingItems={
              <div>
                <PrimaryBadgeComponent
                  text={" Status :  " + currentMatterDetails?.status}
                  className="mt-3 ps-3 pe-3"
                />
              </div>
            }
            height={"270px"}
            className="mt-2 mb-2  pt-1 pb-0 overflow-auto"
            style={{
              paddingInline: "32px",
            }}
          >
            <div>
              <div>
                <DataList
                  text="Reference:"
                  value={
                    <b style={{ color: "black" }}>
                      {currentMatterDetails?.matterRef}
                    </b>
                  }
                  keyStyles={{
                    minWidth: "80px",
                  }}
                  width={"w-100"}
                />
              </div>
              <div>
                <DataList
                  text="Description:"
                  value={currentMatterDetails?.details}
                  width={"w-100"}
                  keyStyles={{
                    minWidth: "80px",
                  }}
                />
              </div>
              <div className="mt-3 d-flex w-100 mx-auto justify-content-between">
                <div className="w-50">
                  <DataList
                    text="Fee Earner:"
                    value={currentMatterDetails?.feeEarnerName}
                    width={"w-100"}
                    keyStyles={{
                      minWidth: "90px",
                    }}
                  />
                  <DataList
                    text="Supervisor:"
                    value={currentMatterDetails?.supervisorName}
                    width={"w-100"}
                    keyStyles={{
                      minWidth: "90px",
                    }}
                  />
                  <DataList
                    text="Application:"
                    value={currentMatterDetails?.application}
                    width={"w-100"}
                    keyStyles={{
                      minWidth: "90px",
                    }}
                  />
                  {/* <DataList
                    text="Start Date"
                    value={DateFormat(currentMatterDetails?.startDate)}
                    width={"w-100"}
                  /> */}
                </div>
                <div className="me-4">
                  <DataList
                    text="Branch:"
                    value={currentMatterDetails?.branch}
                    width={"w-100"}
                    keyStyles={{
                      minWidth: "100px",
                    }}
                  />
                  <DataList
                    text="Department:"
                    value={currentMatterDetails?.department}
                    width={"w-100"}
                    keyStyles={{
                      minWidth: "100px",
                    }}
                  />
                  <DataList
                    text="Work Type:"
                    value={currentMatterDetails?.workType}
                    width={"w-100"}
                    keyStyles={{
                      minWidth: "100px",
                    }}
                  />
                  {/* <DataList
                    text="Source:"
                    value={currentMatterDetails?.source}
                    width={"w-100"}
                  />
                  <DataList
                    text="Referrer:"
                    value={currentMatterDetails?.referrer}
                    width={"w-100"}
                  /> */}
                </div>
              </div>
            </div>
          </Tile>
        </div>

        <div className="col-12 col-xl-5">
          <Tile
            height={"270px"}
            heading={"Key Dates"}
            className="mt-2 mb-2 pt-1 pb-0 overflow-auto"
            style={{
              paddingInline: "32px",
            }}
          >
            <div className="mt-3 d-flex gap-5 w-100 w-80 mx-auto justify-content-between overflow-hidden">
              <div className="w-50">
                <DataList
                  text="Opened:"
                  statusChart={true}
                  // className="mt-3"
                  value={DateFormat(currentMatterDetails?.startDate)}
                  keyStyles={{
                    minWidth: "110px",
                  }}
                  width={"w-100"}
                />
                <DataList
                  text="Last Action:"
                  statusChart={true}
                  // className="mt-3"
                  value={DateFormat(currentMatterDetails?.lastAction)}
                  keyStyles={{
                    minWidth: "110px",
                  }}
                  width={"w-100"}
                />
                <DataList
                  text="Last Review:"
                  statusChart={true}
                  className="mt-1"
                  keyStyles={{
                    minWidth: "110px",
                  }}
                  value={
                    currentMatterDetails?.lastReviewDate !==
                      "0001-01-01T00:00:00" &&
                    formatDate(
                      new Date(currentMatterDetails?.lastReviewDate),
                      "d",
                      locale
                    )
                  }
                  width={"w-100"}
                />
                <DataList
                  text="Next Review:"
                  statusChart={true}
                  className="mt-1"
                  value={DateFormat(currentMatterDetails?.nextReviewDate)}
                  valueClass="text-danger"
                  width={"w-100"}
                  keyStyles={{
                    minWidth: "110px",
                  }}
                />
                <DataList
                  text="Date Closed:"
                  statusChart={true}
                  keyStyles={{
                    minWidth: "110px",
                  }}
                  className="mt-1"
                  value={DateFormat(currentMatterDetails?.dateClosed)}
                  width={"w-100"}
                />
              </div>
              <div className="me-5">
                <DataList
                  text="By:"
                  keyStyles={{
                    minWidth: "110px",
                  }}
                  statusChart={true}
                  // className="mt-3"
                  value={""}
                  width={"w-80"}
                />
                <DataList
                  text="By:"
                  statusChart={true}
                  keyStyles={{
                    minWidth: "110px",
                  }}
                  className="mt-1"
                  value={""}
                  width={"w-80"}
                />
                <DataList
                  text="By:"
                  statusChart={true}
                  keyStyles={{
                    minWidth: "110px",
                  }}
                  className="mt-1"
                  value={""}
                  valueClass="text-danger"
                  width={"w-80"}
                />{" "}
              </div>
            </div>
          </Tile>
        </div>
      </div>

      <div className="row g-2 ">
        <div className="col-12 col-xl-4 ">
          <Tile
            heading="Client"
            height={"278px"}
            className="mb-2 pt-1 pb-0 overflow-auto"
            style={{
              paddingInline: "32px",
            }}
            headingItems={
              <div>
                <PrimaryBadgeComponent
                  text={currentMatterDetails?.clientRef}
                  className="mt-3 ps-3 pe-3"
                />
              </div>
            }
          >
            <div className="">
              <ContactsListItem
                type={"person"}
                value={currentMatterDetails?.client?.clientName}
              />
              <hr className="mt-0 mb-0" />
              <ContactsListItem
                type={"email"}
                value={currentMatterDetails?.client?.email}
              />
              <hr className="mt-0 mb-0" />
              <ContactsListItem
                type={"phone"}
                value={currentMatterDetails?.client?.phoneNo}
              />
            </div>
          </Tile>
        </div>

        <div className="col-12 col-xl-3">
          <Tile
            heading="Rates"
            height={"278px"}
            style={{
              paddingInline: "32px",
            }}
            className="pt-1 pb-0 "
          >
            <div className="w-100 ">
              <div className="d-flex align-items-start">
                <DataList
                  text="Estimate Fee:"
                  statusChart={true}
                  commercial={true}
                  className="mt-1 me-auto"
                  value={AmountFormat(currentMatterDetails?.estimate)}
                  width={"w-75"}
                />
                <img
                  onClick={() => setShowEstimatedFee(true)}
                  className="cursor-pointer"
                  src={EstimateFeeImage}
                  alt="estimatefeeIcon"
                ></img>
              </div>
              <DataList
                text="Rate Type:"
                value={
                  currentMatterDetails?.rateType === "MatterFlatRate"
                    ? "Matter Flat Rate"
                    : currentMatterDetails?.rateType === "FeeEarnerDefault"
                    ? "Fee Earner Default"
                    : currentMatterDetails?.rateType === "RateCategory"
                    ? "Rate Category"
                    : ""
                }
                commercial={true}
                width={"w-100 text"}
                // valueClass={`text-end`}
              />
              {currentMatterDetails?.rateType === "MatterFlatRate" && (
                <DataList
                  text="Fix Rate:"
                  value={AmountFormat(currentMatterDetails?.rateValue)}
                  commercial={true}
                  width={"w-100 text-right"}
                  // valueClass={`text-end`}
                />
              )}
            </div>
          </Tile>
        </div>

        <div className="col-12 col-xl-5 mt-2">
          <div className="d-flex flex-column ">
            <Tile height={"160px"} className="">
              <MatterClientAccount currentmattersBal={balances} />
            </Tile>

            <Tile
              heading="Marketing"
              height={"110px"}
              className="mt-2 pb-0 overflow-hidden"
              style={{
                paddingInline: "32px",
              }}
            >
              <div className="d-flex gap-5 w-100 justify-content-between">
                <div className="w-50 ">
                  <DataList
                    text="Source:"
                    value={currentMatterDetails?.source}
                    commercial={true}
                    width={"w-100"}
                    keyStyles={{
                      minWidth: "90px",
                    }}
                  />
                </div>
                <div className="w-50">
                  <DataList
                    text="Referrer:"
                    value={currentMatterDetails?.referrer}
                    commercial={true}
                    width={"w-100"}
                    keyStyles={{
                      minWidth: "90px",
                    }}
                  />
                </div>
              </div>
            </Tile>
          </div>
        </div>
      </div>
      {showEstimatedFee && (
        <EstimateFee
          cancelEdit={() => setShowEstimatedFee(false)}
          matter={currentMatterDetails?.matterId}
        />
      )}
    </div>
  );
};

export default MattersDetailsPage;
