import React, { useEffect } from "react";
import { Tile } from "components/UIComponents/Tile";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import {
    getClientDocs,
    getClientVerifications,
    getDocIntelligenceData,
    getMatterDocs,
    getDocument,
} from "api";
import { DateFormat1, DateFormatCell } from "components/common/formatter";
import { Button } from "@progress/kendo-react-buttons";
import { process } from "@progress/kendo-data-query";
import styles from "./index.module.scss";
import { Label } from "@progress/kendo-react-labels";
import { useState } from "react";
import DataList from "components/MatterDetails/DataList";
import { PrimaryBadgeComponent } from "components/UIComponents/Badges";
import ImageIcon from "../../assets/image-icon.png";
import EditIcon from "../../assets/common/editBoxIcon.png";
import AddIcon from "../../assets/add-user.png";
import Verified from "../../assets/checked 1.png";
import Document from "../../assets/document.png";
import IntelligentDoc from "../../assets/add-doc-intelligence.png";
import AddEditProof from "./AddEditProof";
import { FormComboBox } from "components/Matters";
import AddDirectors from "./AddDirector";
import { useSelector } from "react-redux";
import AddOwners from "./AddEditOwner";
import { VerificationProofType, getDocType } from "components/Enums/IDCheck.ts";
import DocumentViewer from "components/common/DocumentViewer";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { DocumentsUpload } from "pages/Postings/DcoumentsUpload";
import { useUploadForm } from "components/fileUploadHook";
import { onFileUpload } from "Services/fileDataService";
import { FileDocumentTypes } from "components/Enums/FileDocumentTypes.ts";
import LoadingScreen from "components/common/Loading";
import { toast } from "react-toastify";
import DoccIntelligenceModal from "./DocIntelligenceLoaderModal";
// import DocumentRegisterModal from "./DocumentRegisterModal";

const stringPurifier = (data) => (data ? data : "");

export const IdCheck = ({ client }) => {
    const [verificationData, setVerifcationData] = useState({});
    const [editProof, setEditProof] = useState(false);
    const [currentActiveTab, setCurrentActiveTab] = useState("Company");
    const [addNew, setAddNew] = useState(false);
    const [addDirector, setAddDirector] = useState(false);
    const [addOwner, setAddOwner] = useState(false);
    const [documentViewrData, setDocumentViewerData] = useState([]);
    const [docIntelligentData, setDocIntelligentData] = useState(null);
    const [showPdf, setShowPdf] = useState(false);
    const [proofsData, setProofsData] = useState({});
    const [comboBoxState, setComboBoxState] = useState({
        director: null,
    });
    let locale = useSelector((state) => state?.company?.company?.locale);
    const getClientVerificationsData = async () => {
        try {
            const data = await getClientVerifications(client?.clientId);
            setProofsData({
                addressProof: data?.data?.data?.addressProof,
                proofOfID: data?.data?.data?.proofOfID,
                companyProof1: data?.data?.data?.companyProof1,
                companyProof2: data?.data?.data?.companyProof2,
                otherProofs: data?.data?.data?.otherProofs || [],
                directors: data?.data?.data?.directors || [],
                owner1: data?.data?.data?.owner1 || {},
                owner2: data?.data?.data?.owner2 || {},
                owner3: data?.data?.data?.owner3 || {},
                owner4: data?.data?.data?.owner4 || {},
            });
            if (data?.data?.data?.directors?.length) {
                setComboBoxState({
                    director: {
                        ...data?.data?.data?.directors[0],
                        representation: `${data?.data?.data?.directors[0]?.firstName} ${data?.data?.data?.directors[0]?.lastName}`,
                    },
                });
            }
            setVerifcationData(data?.data?.data);
        } catch (error) { }
    };

    const showPDFFile = async (id) => {
        try {
            const { data } = await getDocument(id);
            setDocumentViewerData([data.data]);
            setShowPdf(true);
        } catch (error) { }
    };

    useEffect(() => {
        if (client) {
            getClientVerificationsData();
        }
    }, []);

    return (
        <div className="container mt-4">
            {client?.type === "Company" && currentActiveTab === "Beneficial Owner" ? (
                <>
                    <div className="row g-2">
                        <div className="col-12 col-xl-6">
                            <Tile
                                heading={`Beneficial Owner 1`}
                                className="mb-2 px-4 py-2 overflow-auto"
                                headingClass={"px-2"}
                                height={"calc(100vh -  630px)"}
                                style={{
                                    minHeight: "250px",
                                }}
                            >
                                <div className="container-fluid">
                                    <OwnerData data={proofsData?.owner1} index={0} />
                                </div>
                            </Tile>
                        </div>
                        <div className="col-12 col-xl-6">
                            <Tile
                                heading={`Beneficial Owner 2`}
                                headingClass={"px-2"}
                                className="mb-2 ps-4 pe-4 overflow-auto"
                                height={"calc(100vh -  630px)"}
                                style={{
                                    minHeight: "250px",
                                }}
                            >
                                <div className="container-fluid">
                                    <OwnerData data={proofsData?.owner2} />
                                </div>
                            </Tile>
                        </div>
                    </div>

                    <div className="row g-2">
                        <div className="col-12 col-xl-6">
                            <Tile
                                headingClass={"px-2"}
                                heading={`Beneficial Owner 3`}
                                className="mb-2 ps-4 pe-4 overflow-auto"
                                height={"calc(100vh -  630px)"}
                                style={{
                                    minHeight: "250px",
                                }}
                            >
                                <div className="container-fluid">
                                    <OwnerData data={proofsData?.owner2} index={0} />
                                </div>
                            </Tile>
                        </div>
                        <div className="col-12 col-xl-6">
                            <Tile
                                heading={`Beneficial Owner 4`}
                                className="mb-2 ps-4 pe-4 overflow-auto"
                                headingClass={"px-2"}
                                height={"calc(100vh -  630px)"}
                                style={{
                                    minHeight: "250px",
                                }}
                            >
                                <div className="container-fluid">
                                    <OwnerData data={proofsData?.owner2} />
                                </div>
                            </Tile>
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <div className="row g-2">
                        {client.type === "Company" && currentActiveTab === "Director ID" ? (
                            <div className="col-12 col-xl-6">
                                <Tile
                                    heading={`Director Details`}
                                    headingClass={"px-2"}
                                    className="mb-2 ps-4 pe-4 overflow-auto"
                                    height={"190px"}
                                    headingItems={
                                        <>
                                            <img
                                                className="cursor-pointer"
                                                src={AddIcon}
                                                onClick={() => setAddDirector(true)}
                                            ></img>
                                        </>
                                    }
                                >
                                    <div className="container-fluid">
                                        <div className="row">
                                            <div className="col-12">
                                                <DataList
                                                    text="Director:"
                                                    // statusChart={true}
                                                    // className="col-4"
                                                    value={
                                                        <>
                                                            <DropDownList
                                                                wf={true}
                                                                isStyled={true}
                                                                width={"260px"}
                                                                iconClassName="wa"
                                                                style={{
                                                                    width: "260px",
                                                                    height: "35px",
                                                                    boxShadow: "0px 1px 9px rgba(0, 0, 0, 0.12)",
                                                                }}
                                                                onChange={(e, value) =>
                                                                    setComboBoxState({
                                                                        director: e?.value,
                                                                    })
                                                                }
                                                                fiterable={false}
                                                                data={proofsData?.directors?.map((director) => {
                                                                    director.representation = `${director.firstName} ${director.lastName}`;
                                                                    return director;
                                                                })}
                                                                value={comboBoxState["director"]}
                                                                state={comboBoxState}
                                                                textField={"representation"}
                                                                placeHolder="Select Director"
                                                                boxShadow={true}
                                                                name={"director"}
                                                            // label={"Directors"}
                                                            />
                                                        </>
                                                    }
                                                    // valueClass="text-danger"
                                                    keyStyles={{ minWidth: "135px" }}
                                                    width={"w-100"}
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-3">
                                                <DataList
                                                    text="Date of Birth:"
                                                    // statusChart={true}
                                                    // className="col-4"
                                                    value={
                                                        comboBoxState["director"]?.dateOfBirth &&
                                                        DateFormat1(
                                                            comboBoxState["director"]?.dateOfBirth,
                                                            locale
                                                        )
                                                    }
                                                    keyStyles={{ minWidth: "135px" }}
                                                    width={"w-100"}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </Tile>
                            </div>
                        ) : (
                            <div className="col-12 col-xl-6">
                                <Tile
                                    headingClass={"px-2"}
                                    heading={`${client.type === "Company" ? "Company" : "Person"
                                        } Details`}
                                    className="mb-2 ps-4 pe-4 overflow-auto"
                                    height={"190px"}
                                >
                                    {client.type === "Person" && (
                                        <div className="container-fluid">
                                            <div className="row">
                                                <div className="col-3">
                                                    <DataList
                                                        text="First Name:"
                                                        // statusChart={true}
                                                        // className="col-4"
                                                        value={client?.contact?.person?.firstName}
                                                        // valueClass="text-danger"
                                                        keyStyles={{ minWidth: "120px" }}
                                                        width={"w-100"}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-3">
                                                    <DataList
                                                        text="Last Name:"
                                                        // statusChart={true}
                                                        // className="col-4"
                                                        value={client?.contact?.person?.lastName}
                                                        keyStyles={{ minWidth: "120px" }}
                                                        width={"w-100"}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-3">
                                                    <DataList
                                                        text="Date of Birth:"
                                                        // statusChart={true}
                                                        // className="col-4"
                                                        value={DateFormat1(
                                                            client?.contact?.person?.dateOfBirth,
                                                            locale
                                                        )}
                                                        // valueClass="text-danger"
                                                        keyStyles={{ minWidth: "120px" }}
                                                        width={"w-100"}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {client.type === "Company" &&
                                        currentActiveTab === "Company" && (
                                            <>
                                                <div className="container-fluid">
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <DataList
                                                                text="Company Name:"
                                                                // statusChart={true}
                                                                keyStyles={{ minWidth: "150px" }}
                                                                // className="col-4"
                                                                value={client?.clientName}
                                                                // valueClass="text-danger"
                                                                width={"w-100"}
                                                            />
                                                        </div>{" "}
                                                        <div className="col-6">
                                                            <DataList
                                                                text="Registration No:"
                                                                // statusChart={true}
                                                                // className="col-4"
                                                                keyStyles={{ minWidth: "120px" }}
                                                                value={client?.regNo}
                                                                // valueClass="text-danger"
                                                                width={"w-100"}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <DataList
                                                                text="VAT Reg No:"
                                                                // statusChart={true}
                                                                // className="col-4"
                                                                keyStyles={{ minWidth: "150px" }}
                                                                value={client?.contact?.company?.vatNo}
                                                                // valueClass="text-danger"
                                                                width={"w-100"}
                                                            />
                                                        </div>{" "}
                                                        <div className="col-6">
                                                            <DataList
                                                                text="SIC Code:"
                                                                // statusChart={true}
                                                                // className="col-4"
                                                                keyStyles={{ minWidth: "120px" }}
                                                                value={client?.contact?.company?.sicCode}
                                                                // valueClass="text-danger"
                                                                width={"w-100"}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <DataList
                                                                text="Registered Address:"
                                                                keyStyles={{ minWidth: "150px" }}
                                                                value={`${stringPurifier(
                                                                    client?.contact?.company?.registeredAddress
                                                                        ?.line1
                                                                )} ${stringPurifier(
                                                                    client?.contact?.company?.registeredAddress
                                                                        ?.line2
                                                                )} ${stringPurifier(
                                                                    client?.contact?.company?.registeredAddress
                                                                        ?.state
                                                                )} ${stringPurifier(
                                                                    client?.contact?.company?.registeredAddress
                                                                        ?.postCode
                                                                )}  ${stringPurifier(
                                                                    client?.contact?.company?.registeredAddress
                                                                        ?.country
                                                                )}`}
                                                                // valueClass="text-danger"
                                                                width={"w-100"}
                                                            />
                                                        </div>{" "}
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                </Tile>
                            </div>
                        )}
                        {client.type === "Company" && currentActiveTab === "Director ID" ? (
                            <div className="col-12 col-xl-6">
                                <Tile
                                    heading={`Summary`}
                                    className="mb-2 ps-4 pe-4"
                                    headingClass={"px-2"}
                                    style={{
                                        overFlowX: "hidden",
                                    }}
                                    height={"190px"}
                                >
                                    <div className="container-fluid">
                                        <div className="row">
                                            <div className="col-3">
                                                <DataList
                                                    text="Passport No:"
                                                    // statusChart={true}
                                                    // className="col-4"
                                                    value={
                                                        comboBoxState["director"]?.passportDetails
                                                            ?.passportNo
                                                    }
                                                    keyStyles={{ minWidth: "100px" }}
                                                    // valueStyles={{ minWidth: "120px" }}
                                                    valueClass="truncate"
                                                    width={"w-100"}
                                                />
                                            </div>
                                            <div className="col-3">
                                                <DataList
                                                    text="Issue On:"
                                                    statusChart={true}
                                                    //   className="col-4"
                                                    keyStyles={{ minWidth: "80px" }}
                                                    value={DateFormat1(
                                                        comboBoxState["director"]?.passportDetails
                                                            ?.passportIssueDate,
                                                        locale
                                                    )}
                                                    // valueClass="text-danger"
                                                    width={"w-100"}
                                                />
                                            </div>
                                            <div className="col-3">
                                                <DataList
                                                    text="Expiry:"
                                                    statusChart={true}
                                                    keyStyles={{ minWidth: "70px" }}
                                                    value={DateFormat1(
                                                        comboBoxState["director"]?.passportDetails
                                                            ?.passportExpiryDate,
                                                        locale
                                                    )}
                                                    // valueClass="text-danger"
                                                    width={"w-100"}
                                                />
                                            </div>
                                            <div className="col-3">
                                                <DataList
                                                    text="Country:"
                                                    keyStyles={{ minWidth: "70px" }}
                                                    statusChart={true}
                                                    value={
                                                        comboBoxState["director"]?.passportDetails
                                                            ?.passportIssuedBy
                                                    }
                                                    // valueClass="text-danger"
                                                    width={"w-100"}
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-3">
                                                <DataList
                                                    text="Driving License:"
                                                    keyStyles={{ minWidth: "110px" }}
                                                    // statusChart={true}
                                                    // className="col-4"
                                                    value={
                                                        comboBoxState["director"]?.licenseDetails?.licenseNo
                                                    }
                                                    // valueClass="text-danger"
                                                    width={"w-100"}
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-3">
                                                <DataList
                                                    text="Address:"
                                                    // statusChart={true}
                                                    // className="col-4"
                                                    value={comboBoxState["director"]?.fullAddressDetails}
                                                    // valueClass="text-danger"
                                                    width={"w-100"}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </Tile>
                            </div>
                        ) : (
                            <div className="col-12 col-xl-6">
                                <Tile
                                    heading={`Summary`}
                                    className="mb-2 ps-4 pe-4"
                                    headingClass={"px-2"}
                                    style={{
                                        overFlowX: "hidden",
                                    }}
                                    height={"190px"}
                                >
                                    {client.type === "Person" && (
                                        <div className="container-fluid">
                                            <div className="row">
                                                <div className="col-3">
                                                    <DataList
                                                        text="Passport No:"
                                                        // statusChart={true}
                                                        // className="col-4"
                                                        value={
                                                            verificationData?.passportDetails?.passportNo
                                                        }
                                                        keyStyles={{ minWidth: "100px" }}
                                                        // valueStyles={{ minWidth: "120px" }}
                                                        valueClass="truncate"
                                                        width={"w-100"}
                                                    />
                                                </div>
                                                <div className="col-3">
                                                    <DataList
                                                        text="Issue On:"
                                                        statusChart={true}
                                                        //   className="col-4"
                                                        keyStyles={{ minWidth: "80px" }}
                                                        value={DateFormat1(
                                                            verificationData?.passportDetails
                                                                ?.passportIssueDate,
                                                            locale
                                                        )}
                                                        // valueClass="text-danger"
                                                        width={"w-100"}
                                                    />
                                                </div>
                                                <div className="col-3">
                                                    <DataList
                                                        text="Expiry:"
                                                        statusChart={true}
                                                        keyStyles={{ minWidth: "70px" }}
                                                        value={DateFormat1(
                                                            verificationData?.passportDetails
                                                                ?.passportExpiryDate,
                                                            locale
                                                        )}
                                                        // valueClass="text-danger"
                                                        width={"w-100"}
                                                    />
                                                </div>
                                                <div className="col-3">
                                                    <DataList
                                                        text="Country:"
                                                        keyStyles={{ minWidth: "70px" }}
                                                        statusChart={true}
                                                        value={
                                                            verificationData?.passportDetails
                                                                ?.passportIssuedBy
                                                        }
                                                        // valueClass="text-danger"
                                                        width={"w-100"}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-3">
                                                    <DataList
                                                        text="Driving License:"
                                                        keyStyles={{ minWidth: "110px" }}
                                                        // statusChart={true}
                                                        // className="col-4"
                                                        value={verificationData?.licenseDetails?.licenseNo}
                                                        // valueClass="text-danger"
                                                        width={"w-100"}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-3">
                                                    <DataList
                                                        text="Address:"
                                                        // statusChart={true}
                                                        // className="col-4"
                                                        value={verificationData?.fullAddressDetails}
                                                        // valueClass="text-danger"
                                                        width={"w-100"}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {client.type === "Company" &&
                                        currentActiveTab === "Company" && (
                                            <>
                                                <div className="container-fluid">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <DataList
                                                                text="Directors:"
                                                                // statusChart={true}
                                                                keyStyles={{ minWidth: "150px" }}
                                                                // className="col-4"
                                                                value={
                                                                    <div className="d-flex align-items-center gap-4">
                                                                        <div className="me-3">
                                                                            {proofsData?.directors &&
                                                                                `${stringPurifier(
                                                                                    proofsData.directors[0]?.firstName
                                                                                )} ${stringPurifier(
                                                                                    proofsData.directors[0]?.lastName
                                                                                )}`}
                                                                        </div>
                                                                        <div className="me-3">
                                                                            {proofsData?.directors &&
                                                                                `${stringPurifier(
                                                                                    proofsData.directors[1]?.firstName
                                                                                )} ${stringPurifier(
                                                                                    proofsData.directors[1]?.lastName
                                                                                )}`}{" "}
                                                                        </div>
                                                                        <div>
                                                                            {proofsData?.directors &&
                                                                                `${stringPurifier(
                                                                                    proofsData.directors[2]?.firstName
                                                                                )} ${stringPurifier(
                                                                                    proofsData.directors[2]?.lastName
                                                                                )}`}
                                                                        </div>
                                                                    </div>
                                                                }
                                                                // valueClass="text-danger"
                                                                width={"w-100"}
                                                            />
                                                        </div>{" "}
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <DataList
                                                                text="Significant Control:"
                                                                // statusChart={true}
                                                                // className="col-4"
                                                                keyStyles={{ minWidth: "150px" }}
                                                                value={
                                                                    proofsData?.directors &&
                                                                    proofsData?.directors[0]?.significantControl
                                                                }
                                                                // valueClass="text-danger"
                                                                width={"w-100"}
                                                            />
                                                        </div>{" "}
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <DataList
                                                                text="Company Type"
                                                                // statusChart={true}
                                                                // className="col-4"
                                                                keyStyles={{ minWidth: "120px" }}
                                                                value={client?.companyType}
                                                                // valueClass="text-danger"
                                                                width={"w-100"}
                                                            />
                                                        </div>{" "}
                                                        <div className="col-6">
                                                            <DataList
                                                                text="Status:"
                                                                // statusChart={true}
                                                                // className="col-4"
                                                                keyStyles={{ minWidth: "120px" }}
                                                                value={client?.status}
                                                                // valueClass="text-danger"
                                                                width={"w-100"}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                </Tile>
                            </div>
                        )}
                    </div>
                    <Tile
                        heading={`Identity Requirements`}
                        className="mb-2 ps-4 pe-4 overflow-auto"
                        headingClass={"px-2"}
                        height={"330px"}
                    >
                        {client.type === "Company" &&
                            currentActiveTab === "Beneficial Owner" ? (
                            <></>
                        ) : (
                            <div className={``}>
                                {client.type === "Person" && (
                                    <>
                                        <ProofTile
                                            clientId={client?.clientId}
                                            proofType={VerificationProofType.ProofOfID}
                                            onClick={(e) =>
                                                setEditProof({
                                                    ...e,
                                                    proofType: VerificationProofType.ProofOfID,
                                                })
                                            }
                                            setDocIntelligentData={setDocIntelligentData}
                                            type="Proof of Id"
                                            showPDFFile={showPDFFile}
                                            data={proofsData.proofOfID}
                                        />
                                        <ProofTile
                                            clientId={client?.clientId}
                                            setDocIntelligentData={setDocIntelligentData}
                                            proofType={VerificationProofType.AddressProof}
                                            onClick={(e) =>
                                                setEditProof({
                                                    ...e,
                                                    proofType: VerificationProofType.AddressProof,
                                                })
                                            }
                                            type="Address Proof"
                                            showPDFFile={showPDFFile}
                                            data={proofsData.addressProof}
                                        />
                                    </>
                                )}
                                {client.type === "Company" &&
                                    currentActiveTab === "Company" && (
                                        <>
                                            <ProofTile
                                                clientId={client?.clientId}
                                                setDocIntelligentData={setDocIntelligentData}
                                                proofType={VerificationProofType.CompanyProof1}
                                                onClick={(e) =>
                                                    setEditProof({
                                                        ...e,
                                                        proofType: VerificationProofType.CompanyProof1,
                                                    })
                                                }
                                                type="Company Proof 1"
                                                showPDFFile={showPDFFile}
                                                data={proofsData.companyProof1}
                                            />
                                            <ProofTile
                                                clientId={client?.clientId}
                                                setDocIntelligentData={setDocIntelligentData}
                                                proofType={VerificationProofType.CompanyProof2}
                                                onClick={(e) =>
                                                    setEditProof({
                                                        ...e,
                                                        proofType: VerificationProofType.CompanyProof2,
                                                    })
                                                }
                                                showPDFFile={showPDFFile}
                                                type="Company Proof 2"
                                                data={proofsData.companyProof2}
                                            />
                                        </>
                                    )}
                                {client.type === "Company" &&
                                    currentActiveTab === "Director ID" && (
                                        <>
                                            <ProofTile
                                                clientId={client?.clientId}
                                                setDocIntelligentData={setDocIntelligentData}
                                                proofType={VerificationProofType.ProofOfID}
                                                onClick={(e) =>
                                                    setEditProof({
                                                        ...e,
                                                        companyPersonId:
                                                            comboBoxState["director"]?.companyPersonId,
                                                        proofType: VerificationProofType.ProofOfID,
                                                    })
                                                }
                                                companyPersonId={
                                                    comboBoxState["director"]?.companyPersonId
                                                }
                                                showPDFFile={showPDFFile}
                                                type="Proof of Id"
                                                data={comboBoxState["director"]?.proofs?.proofOfID}
                                                directors={comboBoxState["director"] === null}
                                            />
                                            <ProofTile
                                                clientId={client?.clientId}
                                                setDocIntelligentData={setDocIntelligentData}
                                                proofType={VerificationProofType.AddressProof}
                                                companyPersonId={
                                                    comboBoxState["director"]?.companyPersonId
                                                }
                                                onClick={(e) =>
                                                    setEditProof({
                                                        ...e,
                                                        companyPersonId:
                                                            comboBoxState["director"]?.companyPersonId,
                                                        proofType: VerificationProofType.AddressProof,
                                                    })
                                                }
                                                type="Address Proof"
                                                showPDFFile={showPDFFile}
                                                data={comboBoxState["director"]?.proofs?.addressProof}
                                                directors={comboBoxState["director"] === null}
                                            />
                                        </>
                                    )}
                                {client.type === "Company" &&
                                    currentActiveTab === "Director ID" &&
                                    comboBoxState["director"]?.proofs?.otherProofs?.map(
                                        (proof, index) => (
                                            <ProofTile
                                                clientId={client?.clientId}
                                                setDocIntelligentData={setDocIntelligentData}
                                                key={index}
                                                showPDFFile={showPDFFile}
                                                proofType={VerificationProofType.Other}
                                                onClick={(e) =>
                                                    setEditProof({
                                                        ...e,
                                                        companyPersonId:
                                                            comboBoxState["director"]?.companyPersonId,
                                                        proofType: VerificationProofType.Other,
                                                    })
                                                }
                                                type={proof.proofTypeName}
                                                companyPersonId={
                                                    comboBoxState["director"]?.companyPersonId
                                                }
                                                data={proof}
                                                directors={comboBoxState["director"] === null}
                                            />
                                        )
                                    )}
                                {(client.type === "Person" || currentActiveTab === "Company") &&
                                    proofsData?.otherProofs?.map((proof, index) => (
                                        <ProofTile
                                            key={index}
                                            clientId={client?.clientId}
                                            showPDFFile={showPDFFile}
                                            setDocIntelligentData={setDocIntelligentData}
                                            proofType={VerificationProofType.Other}
                                            onClick={(e) => {
                                                setEditProof({
                                                    ...e,
                                                    proofType: VerificationProofType.Other,
                                                });
                                            }}
                                            companyPersonId={
                                                comboBoxState["director"]?.companyPersonId
                                            }
                                            type={"Other"}
                                            data={proof}
                                        />
                                    ))}

                                {!(
                                    comboBoxState["director"] === null &&
                                    currentActiveTab === "Director ID"
                                ) && (
                                        <div className="d-flex align-items-center justify-content-end mt-3 me-3 pe-1 pb-3">
                                            <Button
                                                className="button-small px-3"
                                                onClick={() => setAddNew(true)}
                                            >
                                                Add Other Proof
                                            </Button>
                                        </div>
                                    )}
                            </div>
                        )}
                        {currentActiveTab === "Beneficial Owner" && (
                            <>
                                <OwnerData data={proofsData?.owner1} index={0} />
                                <OwnerData data={proofsData?.owner2} />
                                <OwnerData data={proofsData?.owner3} />
                                <OwnerData data={proofsData?.owner4} />
                            </>
                        )}
                    </Tile>
                </>
            )}
            {client.type === "Company" && (
                <div
                    className={`${styles["comapany-proof-tabs"]} mt-3 px-4 d-flex align-items-center gap-2`}
                >
                    <Menutem
                        tabName="Company"
                        active={currentActiveTab}
                        onClick={(e) => setCurrentActiveTab(e)}
                    />
                    <div className={`${styles["horizontal-bar"]}`}></div>
                    <Menutem
                        tabName="Director ID"
                        active={currentActiveTab}
                        onClick={(e) => setCurrentActiveTab(e)}
                    />
                    <div className={`${styles["horizontal-bar"]}`}></div>
                    <Menutem
                        tabName="Beneficial Owner"
                        active={currentActiveTab}
                        onClick={(e) => setCurrentActiveTab(e)}
                    />
                </div>
            )}
            {(editProof !== false || addNew || docIntelligentData) && (
                <AddEditProof
                    cancelEdit={() => {
                        setEditProof(false);
                        setAddNew(false);
                        setDocIntelligentData(null);
                    }}
                    proof={editProof || docIntelligentData}
                    docIntelligentData={docIntelligentData}
                    type={
                        client?.type === "Person" ||
                            (client?.type === "Company" && currentActiveTab === "Director ID")
                            ? "Person"
                            : "Company"
                    }
                    addNew={addNew}
                    clientId={client?.clientId}
                    companyPersonId={
                        client?.type === "Company" && currentActiveTab === "Director ID"
                            ? comboBoxState["director"]?.companyPersonId
                            : undefined
                    }
                    updateSuccess={() => {
                        setEditProof(false);
                        setAddNew(false);
                        setDocIntelligentData(null);
                        getClientVerificationsData();
                    }}
                />
            )}
            {addDirector && (
                <AddDirectors
                    cancelEdit={() => {
                        setAddDirector(false);
                    }}
                    clientId={client?.clientId}
                    onUpdateData={(e) => {
                        getClientVerificationsData();
                        setComboBoxState({
                            director: {
                                ...e,
                                representation: `${e.firstName} ${e.lastName}`,
                            },
                        });
                    }}
                />
            )}
            {addOwner && (
                <AddOwners
                    cancelEdit={() => {
                        setAddOwner(false);
                    }}
                    clientId={client?.clientId}
                    data={{
                        owner1: proofsData?.owner1,
                        owner2: proofsData?.owner2,
                        owner3: proofsData?.owner3,
                        owner4: proofsData?.owner4,
                    }}
                    onUpdateData={() => getClientVerificationsData()}
                />
            )}
            {showPdf && (
                <DocumentViewer
                    data={documentViewrData}
                    onClose={() => {
                        setShowPdf(false);
                        setDocumentViewerData([]);
                    }}
                />
            )}
        </div>
    );
};

export const ProofTile = (props) => {
    const { isSuccess, uploadForm, progress, reset } = useUploadForm();
    const [filesData, setFilesData] = React.useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const filePickerRef = React.useRef();
    const handleUpload = async (e) => {
        let data = onFileUpload(e);
        setFilesData([...filesData, data]);
        const dataChoosen = await uploadForm(data, e.target.files[0], {
            documentType: FileDocumentTypes.ClientDocs,
            clientId: props.clientId,
        });
        data.fileID = dataChoosen.data.data.fileID;
        const dataRecieved = await getDocIntelligenceData(data.fileID);
        const extreactedData = dataRecieved?.data?.data;
        props.setDocIntelligentData({
            documentType: getDocType(extreactedData?.documentType),
            dateOfBirth: extreactedData?.fields?.["DateOfBirth"],
            downloadURL: dataChoosen.data.data?.downloadURL,
            expiryDate: extreactedData?.fields?.["DateOfExpiration"],
            issuedDate: extreactedData?.fields?.["DateOfIssue"],
            documentNumber: extreactedData?.fields?.["DocumentNumber"],
            issuedBy: extreactedData?.fields?.["IssuingAuthority"],
            address:
                extreactedData?.fields?.Address?.streetAddress +
                extreactedData?.fields?.Address?.city +
                extreactedData?.fields?.Address?.countryRegion,
            documentFile: data?.fileID,
            proofType: props.proofType,
            countryCode: extreactedData?.fields?.["CountryRegion"] || "GBR",
            country: extreactedData?.fields?.["PlaceOfBirth"],
            companyPersonId: props?.companyPersonId,
        });
        setFilesData([]);
    };

    const changeHandler = async (e) => {
        try {
            setIsLoading(true);
            await handleUpload(e);
            setIsLoading(false);
            e.target.value = null;
        } catch (error) {
            setIsLoading(false);
            toast.error("Error Occured while uploading file");
        }
    };
    return (
        <div
            className={`${styles["proof-tile"]} pt-2 pb-2 container-fluid mt-1 px-3`}
        >
            <div className="row">
                <div
                    className="col-2"
                    style={{
                        marginBottom: "-15px",
                        maxWidth: "180px",
                    }}
                ></div>
                <div className="col-3">
                    <DataList
                        text="Document Type:"
                        value={props?.data?.documentType}
                        width={"w-100"}
                    // keyStyles={{ minWidth: "120px" }}
                    />
                </div>
                <div className="col-3">
                    <DataList
                        text="Document No:"
                        value={props?.data?.documentNumber}
                        width={"w-100"}
                        keyStyles={{ minWidth: "120px" }}
                    />
                </div>
                <div className="col-2">
                    <DataList
                        text="Country:"
                        value={props?.data?.country}
                        valueClass={"text-truncate"}
                        width={"w-100"}
                        keyStyles={{ minWidth: "80px" }}
                    />
                </div>
                <div className="col d-flex align-items-center justify-content-end"></div>
            </div>
            <div className="row">
                <div
                    className="col-2"
                    style={{
                        marginTop: "-15px",
                        maxWidth: "180px",
                    }}
                >
                    <PrimaryBadgeComponent
                        text={props.type || "Proof of Id"}
                        className="text-center me-3"
                        style={{
                            width: "110px",
                        }}
                    />
                    {props.data?.verified && <img src={Verified}></img>}
                </div>
                <div className="col-8">
                    <DataList
                        text="Details:"
                        // statusChart={true}
                        // className="col-4"
                        value={props.data?.details}
                        // valueClass="text-danger"
                        width={"w-100"}
                    />
                </div>
                <div
                    className="col d-flex align-items-center justify-content-end"
                    style={{
                        marginTop: "-45px",
                        // maxWidth: "180px",
                    }}
                >
                    {props?.data === null && (
                        <div
                            className={`px-3 py-1 gap-2 d-flex align-items-center cursor-pointer`}
                        >
                            {/* <DocumentsUpload
                position="static"
                icon={IntelligentDoc}
                type="ID-CHECK"
                // open={true}
                // value={"Matter Activity"}
                progress={isSuccess ? 100 : progress}
                // type={"Matter Activity"}
                onError={() => {
                  const newFiles = filesData.splice(filesData.length, 1);
                  setFilesData([...newFiles]);
                }}
                reset={false}
                // progress={progress}
                filesData={filesData}
                // onFileRemove={onFileRemove}
                onUpload={handleUpload}
              /> */}
                            <img
                                className="text-center cursor-pointer"
                                src={IntelligentDoc}
                                role="button"
                                onClick={() => filePickerRef?.current?.click()}
                                alt="Doc Int Icon"
                            ></img>
                        </div>
                    )}
                    {/* <button id="doc-clicker" style={{ visibility: "hidden" }}></button> */}
                    {props?.data && props.data?.documentId && (
                        <div
                            className={`px-3 py-1 gap-2 d-flex align-items-center cursor-pointer`}
                            onClick={() => props.showPDFFile(props?.data?.documentId)}
                        >
                            <img
                                src={ImageIcon}
                                style={{
                                    width: "32px",
                                    height: "32px",
                                }}
                                alt="Images"
                            ></img>
                        </div>
                    )}
                    {!props.directors && (
                        <img
                            className="px-3 cursor-pointer"
                            // style={{ width: "140px" }}
                            onClick={async () => {
                                if (props.data?.documentId) {
                                    const { data } = await getDocument(props.data?.documentId);

                                    props.onClick({
                                        ...props.data,
                                        downloadURL: data?.data?.documentFile?.downloadURL,
                                    });
                                    return;
                                }
                                props.onClick(props.data);
                            }}
                            src={EditIcon}
                            alt="Edit Icon"
                        ></img>
                    )}
                    <input
                        name="file"
                        type="file"
                        onChange={changeHandler}
                        ref={filePickerRef}
                        style={{
                            visibility: "hidden",
                        }}
                    />
                </div>
            </div>
            <hr className="mb-0 mt-1" />
            {isLoading && <DoccIntelligenceModal />}
        </div>
    );
};

const Menutem = (props) => {
    return (
        <div
            className={`cursor-pointer ms-3 ${props.active === props.tabName
                    ? styles["active-tab"]
                    : styles["in-active-tab"]
                }`}
            onClick={(e) => props.onClick(props.tabName)}
        >
            {props.tabName}
        </div>
    );
};

export const OwnerData = (props) => {
    return (
        <div className={`pb-1`}>
            {/* <div className="row"> */}
            <div className="row">
                <DataList
                    text="First Name:"
                    value={props?.data?.firstName}
                    width={"w-100"}
                    keyStyles={{ minWidth: "140px" }}
                />
            </div>
            <div className="row">
                <DataList
                    text="Last Name:"
                    value={props?.data?.lastName}
                    width={"w-100"}
                    keyStyles={{ minWidth: "140px" }}
                />
            </div>
            <div className="row">
                <DataList
                    text="Date of Birth:"
                    value={DateFormat1(props.data?.dateOfBirth, props.locale)}
                    width={"w-100"}
                    keyStyles={{ minWidth: "140px" }}
                />
                {/* </div> */}
            </div>
            {/* <div className="row"> */}
            <div className="row">
                <DataList
                    text="Percentage Held:"
                    value={
                        props?.data?.heldPercentage ? `${props?.data?.heldPercentage}%` : ""
                    }
                    width={"w-100"}
                    keyStyles={{ minWidth: "140px" }}
                />
                {/* </div> */}
            </div>
            {/* <hr></hr> */}
        </div>
    );
};
