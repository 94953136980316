import React, { useState, useRef } from 'react'
import { ComboBox } from '@progress/kendo-react-dropdowns';
import * as api from "../../api/index";
import { useEffect } from 'react';
import { Label } from "@progress/kendo-react-labels";
import { Grid , GridColumn as Column } from "@progress/kendo-react-grid";
import {
    AmountFormat,
    DateFormat,
} from "components/common/formatter";
import { getter } from '@progress/kendo-react-common';
import {
    filterBy,
} from '@progress/kendo-data-query';
import { Button } from '@progress/kendo-react-buttons';
import InfoBox from 'components/Global/InfoBox';
import { NominalTypeFormat } from 'components/common/nominalTypeFormatter';
const DATA_ITEM_KEY = 'postingId';
const SELECTED_FIELD = 'selected';
const idGetter = getter(DATA_ITEM_KEY);


export default function PaymentProcessing() {
    const [nominalData, setNominalData] = useState()
    const [nominalFilterData, setNominalFilterData] = useState()
    const [nominalValue, setNominalvalue] = useState(null)
    const [dataState, setDataState] = React.useState({});
    const [loading, setLoading] = useState(false)
    const [selectedState, setSelectedState] = React.useState({});
    const [dataResult, setDataResult] = React.useState([]);

    const fetchData = async () => {
        const { data } = await api.getBankRecNominals()
        setNominalData(data.data)
        setNominalFilterData(data.data)
    }
    const filterNominalData = (filter) => {
        const data = nominalData.slice();
        return filterBy(data, filter);
    };

    const filterNominal = (event) => {
        if (!event.value) {
            return
        }
        setNominalFilterData(filterNominalData(event.filter));
    };



    useEffect(() => {
        fetchData()
    }, [])



    const PaymentCell = (props) => {
        const dateField = props.dataItem[props.field];
        if (dateField >= 0) {
            return (<td style={{ textAlign: "right" }}>{AmountFormat(dateField)}</td>, props)
        } else {
            return <td></td>;
        }
    };

    const DateCell = (props) => {
        const dateField = props.dataItem[props.field];
        if (dateField) {
            return (<td>{DateFormat(dateField)}</td>, props);
        } else {
            return <td></td>;
        }
    };


    const RecieptCell = (props) => {
        const dateField = props.dataItem[props.field];
        if (dateField < 0) {
            return (<td style={{ textAlign: "right" }}>{AmountFormat(dateField)}</td>, props);
        } else {
            return <td></td>;
        }
    };

    const BooleanHeaderCell = (props) => (
        <span className="d-flex pb-2 "
            style={{ color: "#000000", fontSize: "15px", fontWeight: "700" }} >
            Select
        </span>
    );

    return (
        <div className='page'>

            <div className="row">
                <div className="col-6">
                    <div className='d-flex align-items-start'>
                        <Label className='me-4 label mt-2 pt-1'>Bank</Label>
                        <div>
                            <ComboBox
                                style={{ width: '300px' }}
                                data={nominalFilterData}
                                textField={"nominalName"}
                                filterable={true}
                                onFilterChange={filterNominal}
                                value={nominalValue}
                                onChange={(e) => {
                                    if (e.value === null) {;
                                        setNominalvalue(null)
                                        return;
                                    }
                                    setNominalvalue(e.value)
                                }}
                                placeholder={"Search Nominals"}
                            />
                            <InfoBox className="mt-2 w-100" text={nominalValue !== null && `${NominalTypeFormat(nominalValue?.type)} - ${NominalTypeFormat(nominalValue?.subType)}`} title="Type" />
                        </div>
                        <Button className="common-btn ms-3" icon="refresh"
                            disabled={nominalValue === null}
                            onClick={() => { }}>Refresh</Button>
                    </div>
                </div>
            </div>

            <div className="row" style={{ marginTop: '10%' }}>
                <div className="col bankRec">
                    <Grid
                        sortable={true}
                        data={dataResult.data?.map((item) => ({
                            ...item,
                            [SELECTED_FIELD]: selectedState[idGetter(item)],
                        }))}
                        {...dataState}
                        dataItemKey={DATA_ITEM_KEY}
                        selectedField={SELECTED_FIELD}
                        selectable={{
                            enabled: true,
                            drag: false,
                            cell: false,
                            row: true,
                            mode: 'multiple',
                        }}
                        editField="inEdit"
                        className="pb-1"
                        style={{ height: "calc(100vh - 480px)" }}
                    >
                        <Column
                            field="date"
                            title="Date"
                            editable={false}
                            cell={DateCell}
                            width="120px"
                        />
                        <Column field="postingType" title="Type" editable={false} width="80px" />
                        <Column field="matterRef" title="Matter Ref" editable={false} width="120px" />
                        <Column field="details" title="Details" editable={false} />
                        <Column field="transactionPeriod" title="Period" editable={false} width="80px" />
                        <Column field="netAmount" title="Receipt" cell={(RecieptCell)} editable={false} width="120px" />
                        <Column field="netAmount" title="Payment" cell={PaymentCell} editable={false} width="120px" />
                        {/*<Column field="saved" title="Doc" editable={false} width="80px" />*/}
                        <Column field={SELECTED_FIELD} headerCell={BooleanHeaderCell} title="Select" editor="boolean" width="80px" />
                    </Grid>

                    <div className="d-flex justify-content-end mt-2 pb-2">
                        <Button className="common-btn bgBtnSecondary" >{loading ? "Saving..." : "Save"}</Button>
                        <Button className="common-btn ms-3">{"Post"}</Button>
                    </div>
                </div>
            </div>
        </div >
    )
}


