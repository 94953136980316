import React, { useEffect } from "react";
import { useState } from "react";
import { Label } from "@progress/kendo-react-labels";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { Input, TextArea } from "@progress/kendo-react-inputs";
import styles from "./AddressInputComponent.module.scss";
import { Field } from "@progress/kendo-react-form";
import useMediaQuery from "hooks/useMediaQuery";

const AddressInputComponent = (props) => {
  const matches = useMediaQuery("(min-width: 900px)");
  const [address, setAddress] = useState({});

  const onChange = (e) => {
    const name = e.target.name;
    let data = { ...address };
    data[name] = e.value;
    setAddress(data);
    if (props.setAddress) props.setAddress(data);
  };

  useEffect(() => {
    if (props.address) {
      setAddress(props.address);
    }
  }, [props.address]);

  return (
    <div className={`row `} style={props.style}>
      {props?.name && <Label className="label mb-1">{props.name}</Label>}
      <div
        className={
          !matches
            ? "col-12 mb-3"
            : props.contactComponent
            ? props.contactComponent
            : "col-5 pe-0 me-auto"
        }
      >
        <div>
          <Field
            disabled={props.readOnly}
            placeholder={`Address`}
            type="text"
            required={false}
            maxLength={60}
            name={`${props.type ? props.type : ""}line1`}
            className={`${styles[`input-address-component mt-0`]}`}
            autoSize={false}
            style={{
              width: props.width || "100%",
              maxWidth: "400px",
              height: "114px",
            }}
            component={TextArea}
            onChange={onChange}
          />
        </div>
      </div>

      <div className={!matches ? "col-8" : "col-6 "}>
        <div className="col-12 ">
          <Field
            disabled={props.readOnly}
            style={{ maxWidth: props.width || "300px", height: "32px" }}
            placeholder="Enter Town"
            name={`${props.type ? props.type : ""}town`}
            className="h-sm"
            component={Input}
            onChange={onChange}
          />
        </div>

        <div
          className={`no-gutters pe-0 mt-2 `}
          style={{ maxWidth: props.width || "300px" }}
        >
          <Field
            disabled={props.readOnly}
            style={{ width: "64%", height: "32px" }}
            className="h-sm"
            placeholder="Enter State"
            name={`${props.type ? props.type : ""}state`}
            component={Input}
            onChange={onChange}
          />

          <Field
            disabled={props.readOnly}
            placeholder="Post Code"
            style={{ width: "35%", marginLeft: "1%", height: "32px" }}
            className="h-sm"
            name={`${props.type ? props.type : ""}postcode` || "postcode"}
            maxLength={10}
            component={Input}
            onChange={onChange}
          />
        </div>

        <div className="mt-2">
          <Field
            disabled={props.readOnly}
            placeholder="Select Country"
            data={["United Kingdom"]}
            style={{ maxWidth: props.width || "300px", height: "32px" }}
            className={`${styles[`input-address-component`]} h-sm`}
            iconClassName="wa"
            clearButton={true}
            onKeyDown={props.onKeyDown}
            name={`${props.type ? props.type : ""}country` || "country"}
            component={ComboBox}
            onChange={onChange}
          />
        </div>
      </div>
    </div>
  );
};

export default AddressInputComponent;
