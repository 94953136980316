export const enum VerificationProofType {
  ProofOfID = "ProofOfID",
  AddressProof = "AddressProof",
  Other = "Other",
  CompanyProof1 = "CompanyProof1",
  CompanyProof2 = "CompanyProof2",
}

export const enum VerificationDocumentType {
  Passport = "Passport",
  DrivingLicense = "DrivingLicense",
  UtilityBill = "UtilityBill",
  CouncilTax = "CouncilTax",
  Other = "Other",
  NationalIdentityCard = "NationalIdentityCard",
  ResidencePermit = "ResidencePermit",
}

export const getDocType = (docType) => {
  switch (docType) {
    case "idDocument.driverLicense":
      return VerificationDocumentType.DrivingLicense;
    case "idDocument.passport":
      return VerificationDocumentType.Passport;
    case "idDocument.nationalIdentityCard":
      return VerificationDocumentType.NationalIdentityCard;
    case "idDocument.usSocialSecurityCard":
      return VerificationDocumentType.NationalIdentityCard;
    case "idDocument.residencePermit":
      return VerificationDocumentType.ResidencePermit;
    case "idDocument":
      return VerificationDocumentType.Other;
    default:
      break;
  }
};
