import * as React from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import { useSelector, useDispatch } from "react-redux";
import { clearFiles } from "actions/files.action.js";

const Modal = (props) => {
  const [visible, setVisible] = React.useState(false);
  const currencyresult = useSelector((state) => state.currency);
  const userresult = useSelector((state) => state.usersList);
  const branchresult = useSelector((state) => state.branches);
  const vatresult = useSelector((state) => state.vatcodes);
  const supplierResult = useSelector((state) => state.suppliersList);
  const nominalResult = useSelector((state) => state.nominalList);
  const profitCostTemplate = useSelector((state) => state.profitCostTemplate);
  const applications = useSelector((state) => state.applications);

  const dispatch = useDispatch();

  const toggleDialog = () => {
    setVisible(false);
    props?.reset();
  };
  React.useEffect(() => {
    setVisible(props.value);
  }, [props.value]);
  return (
    <div>
      {visible && (
        <Dialog
          title={"Please confirm"}
          onClose={toggleDialog}
          height={300}
          width={500}
          //   className="mattersDialog"
        >
          {/* <h3
            style={{
              fontWeight: 500,
            }}
          >
            {props.component === "Currency"
              ? currencyresult.IsError === false
                ? "Success"
                : "Error!"
              : ""}
            {props.component === "User"
              ? userresult.IsError === false
                ? "Success"
                : "Error"
              : ""}
            {props.component === "Branch"
              ? branchresult.IsError === false
                ? "Success"
                : "Error"
              : ""}
            {props.component === "VAT" || props.component === "Vat"
              ? vatresult.IsError === false
                ? "Success"
                : "Error"
              : ""}
            {props.component === "Supplier"
              ? supplierResult.IsError === false
                ? "Successfully saved data. "
                : "Failed to save data."
              : ""}
            {props.component === "Nominal"
              ? nominalResult.IsError === false
                ? "Successfully saved data. "
                : "Failed to save data."
              : ""}
            {props.component === "ProfitCostTemplate"
              ? profitCostTemplate.IsError === false
                ? "Successfully saved data. "
                : "Failed to save data."
              : ""}
            {props.component === "Applications"
              ? applications.IsError === false
                ? "Successfully saved data. "
                : "Failed to save data."
              : ""}

            {props.component === "Posting" ? "Warning" : ""}
          </h3> */}
          {/* <div className="mattersDialogField">
            <div className="label">
              {currencyresult.IsError === false ? props.display : ""}
            </div>
          </div> */}
          <div
            className="p-4 d-flex align-items-center justify-center"
            style={{
              height: "180px",
            }}
          >
            <div className="">
              {props.component === "Posting" ? (
                <p className="text-center" style={{ color: "red" }}>{props.display}</p>
              ) : (
                ""
              )}
            </div>
          </div>

          <div className="dialog-footer">
            <button
              className="common-btn bgBtnSecondary me-3"
              onClick={() => {
                if ("onCancel" in props) {
                  props?.onCancel();
                }
                setVisible(false);
              }}
            >
              {props.component === "Posting" ? "Cancel" : "Ok"}
            </button>
            {props.component === "Posting" ? (
              <button
                className="k-button editBtn common-btn me-3"
                onClick={() => {
                  dispatch(clearFiles());
                  props?.clearFile();
                  toggleDialog();
                }}
              >
                Clear Files
              </button>
            ) : (
              ""
            )}
          </div>
        </Dialog>
      )}
    </div>
  );
};

export default Modal;
