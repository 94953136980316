import React, { useState } from "react";
import styles from './VatReturn.module.scss';
import pic from "../../assets/vaticon.png"
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { Button } from "@progress/kendo-react-buttons";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Input, Checkbox, NumericTextBox } from "@progress/kendo-react-inputs";
import { Label } from "@progress/kendo-react-labels";
import { AmountFormat } from "components/common/formatter";


export const VatContainer = (props) => {
    return <div className={`${styles['vat-container']} d-flex flex-column align-items-center justify-content-center`}
        style={props.style}>
        {props.children}
    </div>
}

export const Heading = (props) => {
    return <div className={`d-flex align-items-center`}>
        <img src={pic} className='me-3' />
        <p className="mt-3">{props.name}</p>
    </div>
}

export const DetailsCell = (props) => {
    return <td className="mb-3">
        <div className={`${styles['grid-section-height']} d-flex h-100 align-items-center`}>
            <p className={`${styles['date-text']} me-4`}>{props.dataItem.period}</p>
            <BadgeComponent text={props.dataItem.badge} />
        </div>
        <span className={styles['text-light-color']}>{props?.dataItem?.submissionDetails}</span>
    </td>
}

export const VatReturnCell = (props) => {
    return <td>
        <span className={`${styles['text-light-color']} ${styles['text-center']} ms-2`}>VAT amount refundable</span>
        <p className={`${styles['date-text']} ${styles['text-center']} mt-1 me-4`}>{props.dataItem.amount}</p>
    </td>
}

export const GridComponent = (props) => {

    const ReviewButtonCell = (e) => {
        return <td className="mb-3">
            <Button className={`ms-4 common-btn ${styles[`grid-btn`]}`} onClick={() => { props.showDetails(e.dataItem) }}>Review</Button>
        </td>
    }

    return <div className="settings-grid vat-return-grid">
        <Grid
            data={props.data}
        >
            <Column field="vatCodeId" cell={DetailsCell} />
            <Column field="details" className="mt-2" cell={VatReturnCell} width={200} />
            <Column field="rate" cell={ReviewButtonCell} width={130} />
        </Grid>
    </div>
}

export const CardContainer = (props) => {
    return <div className={styles[`vat-details-container`]}>
        {props.children}
    </div>
}

export const BarComponent = () => {
    return <div className={styles[`line-bar`]}>
    </div >
}

export const BadgeComponent = (props) => {
    return <p className="badge rounded-pill bg-dark mt-auto">{props.text}</p>
}

export const VatReturnAmountComponent = (props) => {
    const [isAdjust, setAdjust] = useState(false)
    return <div className="row">
        <div className="col-1">
            <Button className={`${styles['box-button']}`}>Box {props.box}</Button>
        </div>
        <div className="col-8 mt-2">
            <p className={styles['calculation-text-color']}>{props.details}</p>
        </div>
        <div className="col-1 cursor-pointer fs-md mt-1">
            <p onClick={() => { setAdjust(!isAdjust) }}><u>{'Adjust'}</u></p>
        </div>
        <div className="col-1"></div>
        <div className="col-1 mt-2 text-align-right">
            {
                <p className={`${styles['calculation-text-color']} text-right`}>{AmountFormat(props.amount)}</p>
            }
        </div>
        {isAdjust && <AdjustAmount cancelEdit={() => { setAdjust(false) }} item={{ amount: props.amount, reason: '' }} onSubmit={(e) => {
            props.updateData(props.box, e.amount)
            setAdjust(false)
        }} />}
    </div>
}


const AdjustAmount = (props) => {
    return (
        <Dialog
            title={'Adjust Amount'}
            onClose={props.cancelEdit}
            width={600}
        >
            <div className="p-3 ps-4">
                <Form
                    onSubmit={props.onSubmit}
                    initialValues={props.item}
                    render={(formRenderProps) => (
                        <FormElement>
                            <fieldset className={"k-form-fieldset"}>
                                {<div className="mb-2 right-align">
                                    <Label className="label mt-1 mb-2  w-100">Amount&nbsp;</Label>
                                    <Field
                                        name={"amount"}
                                        component={NumericTextBox}
                                        format='n2'
                                        spinners={false}
                                    />
                                </div>}
                                <div className="mb-2">
                                    <Label className="label mt-1 mb-2 w-100">Reason&nbsp;</Label>
                                    <Field
                                        name={"reason"}
                                        component={Input}
                                    />
                                </div>
                            </fieldset>
                            <div className="d-flex float-end pb-4">
                                <button
                                    className="common-btn me-2"
                                    disabled={!formRenderProps.allowSubmit}
                                    type={"submit"}
                                >
                                    {'Update'}
                                </button>
                                <button
                                    className="common-btn me-1"
                                    onClick={props.cancelEdit}
                                >
                                    Cancel
                                </button>
                            </div>
                        </FormElement>
                    )}
                />
            </div>
        </Dialog >
    );
};

