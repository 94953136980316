import { SET_MAIN_LOADING,SET_CLIENT_DROPDONW_DATA,ACCESS_DENIED } from "../types/index.js";

const CommonReducer = (
  state = {
    mainLoading: false,
    branchesName: [],
    acctiveCurrencies: [],
    feeEarner: [],
    activeVats: [],
    accessDenied: {status: false, message: ""},
  },
  action
) => {
  switch (action.type) {
    case SET_MAIN_LOADING:
      return {
        ...state,
        mainLoading: action.payLoad };
      case SET_CLIENT_DROPDONW_DATA:
        return{
          ...state,
          branchesName: action.payLoad.branchesName,
          acctiveCurrencies: action.payLoad.acctiveCurrencies,
          feeEarner: action.payLoad.feeEarner,
          activeVats: action.payLoad.activeVats
        } 
        case ACCESS_DENIED:
          return{
            ...state,
            accessDenied: {status: action.payLoad.status, message: action.payLoad.message}
          }
    default:
      return state;
  }
};
export default CommonReducer;
