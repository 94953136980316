import React from "react";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Label } from "@progress/kendo-react-labels";

const titles = ["Mr", "Mrs"];

const Title = (fieldRenderProps) => {
  return (
    <div className="flex flex-column" style={fieldRenderProps.style}>
      <Label className="label">Title</Label>
      {fieldRenderProps.controlled ? (
        <DropDownList
          {...fieldRenderProps}
          data={titles}
          iconClassName="wa"
          value={fieldRenderProps.value}
          onChange={(e) => fieldRenderProps.onChange(e.target.value)}
          style={{ width: "110px" }}
        />
      ) : (
        <DropDownList
          {...fieldRenderProps}
          data={titles}
          iconClassName="wa"
          style={{ width: "110px" }}
          defaultValue={"Mr"}
        />
      )}
    </div>
  );
};

export default Title;
