import PersonNameComponent from "components/common/Contact/PersonNameComponent/PersonNameComponent";
import Title from "components/common/Title";
import ContactInformation from "components/common/ContactInformation";
import { Field } from "@progress/kendo-react-form";
import AddressInputComponent from "components/common/AddressInputComponent";
import { Input } from "@progress/kendo-react-inputs";
import { FormComboBox, FormDate } from "components/Matters";
import ContactInformationComponent from "components/Contact/ContactInformation";
import { DocRegisterListTile } from "components/DocumentRegister/DocRegisterListTile";
import { NotesListTile } from "components/Notes/NotesListTile";
import { IdCheck } from "components/IdCheck";
import { RiskAssessment } from "components/RiskAssessments";
import AutoCompleteInput from "components/AutoCompleteCompanies/AutoCompleteCompany";
import { CompanyItemRender } from "components/AutoCompleteCompanies/CompanyItemRender";
import { DocumentList } from "components/Documents/DocumentList";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { Label } from "@progress/kendo-react-labels";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { Form } from "@progress/kendo-react-form";
import styles from "./Clients.module.scss";
import * as api from "../../api/index";
import { PageTabItemTitle } from "components/tabs/TabItem/PageTabItemTitle";
import { setMainLoading } from "actions/common.action";
import {
  combineAddressLines,
  handleApiError,
  setClinetFormInitialValues,
} from "Helper";
import {
  changeClientEditPermission,
  getClientsAllInfo,
  updateClientViewTab,
} from "actions/clients.action";
import useMediaQuery from "hooks/useMediaQuery";
import { Button } from "@progress/kendo-react-buttons";
import {
  CompanyInformationView,
  ContactInformationView,
  CreditsInformationView,
  StatementInformationView,
} from "components/Clients";
import { Tile } from "components/UIComponents/Tile";
import { toast } from "react-toastify";
import CustomInput from "components/common/Input";
import ClientMatters from "../../components/Clients/ClientMatters";
import { emailValidator, phoneValidator } from "components/common/validator";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import CompanyDetails from "./CompanyDetails";
import CorrespondenceContact from "./CorrespondenceContact";
import PersonalInformation from "./PersonalInformation";
import StatementBilling from "./StatementBilling";
import CreditsDefaults from "./CreditsDefaults";
import { breakArrayIntoChunks } from "../../components/Clients/UtilityFunction";
import { getCompanyProfile } from "../../api/index";
import {
  ethnicity,
  genders,
  disability,
  typesList,
  companyViewTab,
  companyEditTab,
  personViewTab,
  personEditTab,
} from "./constants";
import {
  useFetchCurrency,
  useFetchFeeEarner,
  useFetchVatCode,
} from "./FetchData";

const AddClients = (props) => {
  const matches = useMediaQuery("(min-width: 992px)");
  const personTab = props.type === "VIEW" ? personViewTab : personEditTab;
  const companyTab = props.type === "VIEW" ? companyViewTab : companyEditTab;
  const isFromView = useSelector((state) => state.clients.clinetEditPermission);
  const clinetTabEditData = useSelector(
    (state) => state.clients.clinetTabEditData
  );
  const clinetTabData = useSelector((state) => state.clients.clientViewTabs);

  const [initialValues, setInitialValues] = useState({
    title: "Mr",
    gender: "Male",
    ethnicity: "White British",
    disability: "Physical",
  });
  const formRef = useRef(null);
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({});
  const [type, setType] = useState({ typeId: 0, typeName: "Company" });
  const [showLoader, setShowLoader] = useState(false);
  const [selected, setSelected] = useState(0);
  const [clientName, setClientName] = useState(null);
  const [reference, setReference] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const { userlist: user } = useSelector((state) => state.currentuser);
  const [branch, setBranch] = useState(null);
  const [modalMessage, setModalMessage] = useState("");
  const [modalSuccess, setModalSuccess] = useState(undefined);
  const [comboBoxState, setComboBoxState] = useState({
    ethnicity: null,
    gender: null,
    disability: null,
    currency: null,
    feeEarner: user?.isFeeEarner
      ? {
          userRef: user?.userRef,
          representation: `${user?.userRef} - ${user.displayname} `,
        }
      : null,
    vatCodeId: null,
  });
  const [dateState, setDateState] = useState({
    dateOfBirth: null,
    dateOfDeath: null,
    dateStageSet: null,
  });
  const [tabs, setTabs] = useState(
    props.type === "VIEW" || (isFromView && formData?.type === "Person")
      ? personTab
      : companyTab
  );
  const [companyProfile, setCompanyProfile] = useState();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const currencyData = useFetchCurrency();
  const feeEarnerData = useFetchFeeEarner();
  const vatData = useFetchVatCode();

  const handleSelect = (e) => {
    setSelected(e.selected);
  };

  const updatInitialValues = (
    vatCodeName = "",
    feeEarnerName = "",
    ccname = "",
    bname = ""
  ) => {
    let personStateCopy = clinetTabEditData || props.data;
    setInitialValues({
      ...initialValues,
      ...setClinetFormInitialValues(
        personStateCopy,
        vatCodeName,
        feeEarnerName,
        ccname,
        bname
      ),
    });
    setDateState({
      dateOfBirth: personStateCopy?.contact?.person?.dateOfBirth
        ? new Date(personStateCopy?.contact?.person?.dateOfBirth)
        : null,
      dateOfDeath: personStateCopy?.contact?.person?.dateOfDeath
        ? new Date(personStateCopy?.contact?.person?.dateOfDeath)
        : null,
      dateStageSet: personStateCopy?.dateStageSet
        ? new Date(personStateCopy?.dateStageSet)
        : null,
    });
    setFirstName(personStateCopy?.contact?.person?.firstName);
    setLastName(personStateCopy?.contact?.person?.lastName);
    setClientName(personStateCopy.clientName);
    setReference(personStateCopy.clientRef);
    setComboBoxState({
      ethnicity: ethnicity[personStateCopy?.contact?.person?.ethnicity],
      gender:
        personStateCopy?.contact?.person?.gender === "M" ? "Male" : "Female",
      disability: personStateCopy?.contact?.person?.disability,
      vatCodeId: personStateCopy?.vatCodeId
        ? {
            vatCodeId: personStateCopy?.vatCodeId,
            representation: `${vatCodeName?.vatCodeId} - ${vatCodeName?.details} - ${vatCodeName.rate}%`,
          }
        : null,
      feeEarner: personStateCopy?.feeEarnerRef
        ? {
            userRef: personStateCopy?.feeEarnerRef,
            representation: `${feeEarnerName?.userRef} -${feeEarnerName?.displayname} `,
          }
        : null,
      currency: personStateCopy?.currencyCode
        ? {
            currencyCode: personStateCopy?.currencyCode,
            representation: `${ccname?.currencyCode} - ${ccname?.name}`,
          }
        : null,
    });
  };

  const { branchesName, acctiveCurrencies, feeEarner, activeVats } =
    useSelector((state) => state.common);

  const getDispalyNamesForDD = () => {
    const data = clinetTabEditData || props.data;
    let vatCodeName;
    let feeEarnerName;
    let ccname;
    let bname;
    vatCodeName = activeVats?.find((item) => item.vatCodeId === data.vatCodeId);
    feeEarnerName = feeEarner?.find(
      (item) => item.userRef === data.feeEarnerRef
    );
    ccname = acctiveCurrencies.find(
      (item) => item.currencyCode === data.currencyCode
    );
    bname = branchesName?.find((item) => item.branchId === data.branchId);
    setBranch({
      branchName: bname?.branchName,
      branchId: bname?.branchId,
    });

    updatInitialValues(vatCodeName, feeEarnerName, ccname, bname?.branchName);
  };

  useEffect(() => {
    if (props.formId == "add" && companyProfile) {
      let selectedAddress =
        companyProfile.service_address ||
        companyProfile.registered_office_address ||
        {};

      let address = {
        // line1: selectedAddress.address_line_1,
        // line2: selectedAddress.address_line_2,
        line1: combineAddressLines(
          selectedAddress.address_line_1,
          selectedAddress.address_line_2
        ),
        town: selectedAddress?.locality,
        state: selectedAddress.region,
        postcode: selectedAddress?.postal_code,
        country: selectedAddress.country || "",
      };

      let sicCode = breakArrayIntoChunks(companyProfile?.sic_codes, 30);
      setInitialValues({
        ...initialValues,
        clientName: companyProfile?.company_name,
        companyRegNo: companyProfile?.company_number,
        sicCode: sicCode,
        ...setClinetFormInitialValues({
          contact: {
            address: address,
            company: {
              registeredAddress: {
                line1: combineAddressLines(
                  companyProfile.registered_office_address?.address_line_1,
                  companyProfile.registered_office_address?.address_line_2
                ),
                // line1: companyProfile.registered_office_address.address_line_1,
                // line2: companyProfile.registered_office_address.address_line_2,
                town: companyProfile.registered_office_address?.locality,
                state: companyProfile.registered_office_address.region,
                postcode:
                  companyProfile.registered_office_address?.postal_code || "",
                country: companyProfile.registered_office_address.country || "",
              },
            },
          },
        }),
      });
      getClientReference();
    }
  }, [companyProfile?.company_number]);

  useEffect(() => {
    if (props.type === "VIEW" || isFromView) {
      const data = clinetTabEditData || props.data;
      if (data) {
        const { type } = data || {};
        setType({ typeId: type === "Person" ? 1 : 0, typeName: type });
        if (type === "Person") {
          setTabs(personTab);
        } else {
          setTabs(companyTab);
        }
        getDispalyNamesForDD();
      } else {
        updateModalState(true, "No data found to show.", false);
      }
    }
  }, [JSON.stringify(props.data), JSON.stringify(clinetTabEditData)]);

  useEffect(() => {
    setFormData(clinetTabEditData || props?.data || {});
  }, [props.data, clinetTabEditData]);

  const updateModalState = (modalShow, message, success) => {
    setShowModal(modalShow);
    setModalMessage(message);
    setModalSuccess(success);
  };

  const handleSubmit = async (res) => {
    if (res.preventDefault) res.preventDefault();
    const isFormValid = formRef.current.checkValidity();
    if (!res.values) return;
    if (isFormValid) {
      let dataItem = res.values;
      dispatch(setMainLoading(true));
      let data = {
        clientRef: reference,
        clientName: clientName || "",
        branchId: branch?.branchId,
        currencyCode: comboBoxState["currency"]?.currencyCode,
        feeEarnerRef: comboBoxState["feeEarner"]?.userRef,
        vatCodeId: comboBoxState["vatCodeId"]?.vatCodeId,
        creditLimit: dataItem?.creditLimit,
        rate: dataItem?.rate,
        billingName: dataItem.billingName,
        billingPhone: dataItem.billingPhone,
        billingEmail: dataItem.billingEmail,
        billingAddress: {
          line1: dataItem.siline1?.split("\n")?.[0],
          line2: dataItem.siline1?.split("\n")?.[1],
          town: dataItem.sitown,
          state: dataItem.sistate,
          postcode: dataItem.sipostcode,
          country: dataItem.sicountry,
        },
        statementName: dataItem.statementName,
        statementPhone: dataItem.statementPhone,
        statementEmail: dataItem.statementEmail,
        statementAddress: {
          line1: dataItem.biline1?.split("\n")?.[0],
          line2: dataItem.biline1?.split("\n")?.[1],
          town: dataItem.bitown,
          state: dataItem.bistate,
          postcode: dataItem.bipostcode,
          country: dataItem.bicountry,
        },
        active: true,
        type: type?.typeName,
      };
      data.contact = {
        email1: dataItem.email,
        phone1: dataItem.phone1,
        phone2: dataItem.phone2,
        mobileNo: dataItem.mobileNo,
        website: dataItem.website,

        notes: "",
        other1: "",
        active: true,
        type: type?.typeName,
        company: null,
        address: {
          line1: dataItem?.line1?.split("\n")?.[0],
          line2: dataItem?.line1?.split("\n")?.[1],
          town: dataItem?.town,
          state: dataItem?.state,
          postcode: dataItem?.postcode,
          country: dataItem?.country,
        },
        bankAcccount: {
          bankName: "",
          accountType: "",
          accountTitle: dataItem.accountTitle || "",
          accountNo: dataItem.accountNo || "",
          sortCode: dataItem.sortCode || "",
          currencyCode: "",
          iban: "",
        },
      };
      if (type.typeName === "Company") {
        data.contact.company = {
          companyName: "",
          companyRegNo: dataItem.companyRegNo,
          vatNo: dataItem.vatNo,
          sicCode: dataItem.sicCode,
          corrEmail: dataItem?.corrEmail,
          corrPhone: dataItem?.corrPhone,
          corrJobTitle: dataItem?.corrJobTitle,
          corrFirstName: dataItem?.corrFirstName,
          other1: "",
          registeredAddress: {
            line1: dataItem.registeredAddressline1?.split("\n")?.[0],
            line2: dataItem.registeredAddressline1?.split("\n")?.[1],
            town: dataItem.registeredAddresstown,
            state: dataItem.registeredAddressstate,
            postcode: dataItem.registeredAddresspostcode,
            country: dataItem.registeredAddresscountry,
          },
        };
        data.contact.company.correspondenceContact = {
          email1: dataItem.correspondenceContactemail,
          email2: "",
          phone1: dataItem.correspondenceContactphone1,
          phone2: dataItem.correspondenceContactphone2,
          phone3: "",
          mobileNo: dataItem.correspondenceContactmobileNo,
          website: dataItem.correspondenceContactwebsite,
          notes: "",
          other1: "",
          active: true,
          type: "Person",
          person: {
            title: dataItem.title,
            firstName: firstName,
            middleName: dataItem.middleName,
            lastName: lastName,
            letterTitle: "",
            jobTitle: "",
            nameAtBirth: "",
            placeOfBirth: "",
            dateOfBirth: dateState["dateOfBirth"],
            dateOfDeath: dateState["dateOfDeath"],
            ethnicity: comboBoxState["ethnicity"],
            gender: comboBoxState["gender"],
            disability: comboBoxState["disability"],
            niNumber: dataItem?.niNumber,
            other1: "",
          },
          address: {
            line1: dataItem?.correspondenceContactline1?.split("\n")?.[0],
            line2: dataItem?.correspondenceContactline1?.split("\n")?.[1],
            town: dataItem?.correspondenceContacttown,
            state: dataItem?.correspondenceContactstate,
            postcode: dataItem?.correspondenceContactpostcode,
            country: dataItem?.correspondenceContactcountry,
          },
        };
      } else if (type.typeName === "Person") {
        data.contact.address = {
          line1: dataItem?.line1?.split("\n")?.[0],
          line2: dataItem?.line1?.split("\n")?.[1],
          town: dataItem?.town,
          state: dataItem?.state,
          postcode: dataItem?.postcode,
          country: dataItem?.country,
        };
        data.contact.bankAcccount = {
          bankName: "",
          accountType: "",
          accountTitle: dataItem.accountTitle || "",
          accountNo: dataItem.accountNo || "",
          sortCode: dataItem.sortCode || "",
          currencyCode: "",
          iban: "",
        };
        data.contact.person = {
          title: dataItem.title,
          firstName: firstName,
          middleName: dataItem.middleName,
          lastName: lastName,
          letterTitle: "",
          jobTitle: "",
          nameAtBirth: dataItem.nameAtBirth,
          placeOfBirth: dataItem.placeOfBirth,
          dateOfBirth: dateState["dateOfBirth"],
          dateOfDeath: dateState["dateOfDeath"],
          ethnicity: ethnicity.findIndex(
            (item) => item === comboBoxState["ethnicity"]
          ),
          gender: comboBoxState["gender"] === "Male" ? "M" : "F",
          disability: comboBoxState["disability"] === "Yes" ? 1 : 0,
          niNumber: dataItem.niNumber,
          other1: "",
        };
      }
      try {
        if (props.type === "VIEW" || isFromView) {
          try {
            const updateRes = await api.editClient(formData.clientId, data);
            dispatch(setMainLoading(false));
            if (updateRes?.status === 200) {
              toast.success("Client Updated");
              updateModalState(true, "Client Updated Successfully", true);
              dispatch(updateClientViewTab(updateRes.data.data));
              props.onClose();
            } else {
              updateModalState(true, "Client Failed to Update", false);
            }
          } catch (error) {
            dispatch(setMainLoading(false));
            toast.error("Error Occured on  updating client");
            updateModalState(true, "Error!", false);
            handleApiError(error);
          }
        } else {
          try {
            const res = await api.addClient(data);
            dispatch(setMainLoading(false));
            if (res?.status === 200) {
              toast.success("Client Added");
              if (props.component === "Add Matters") {
                props.onChangeClient({
                  name: res?.data?.data.clientName,
                  id: res?.data?.data.clientId,
                  ref: res?.data?.data.clientRef,
                });
              }
              dispatch(
                getClientsAllInfo(res?.data?.data.clientRef, res?.data?.data)
              );
              updateModalState(true, "Client Added Successfully", true);
              props.onClose();
            } else {
              updateModalState(true, "Client Failed to add", false);
            }
          } catch (err) {
            dispatch(setMainLoading(false));
            updateModalState(true, "Error!", false);
            handleApiError(err);
            toast.error("Error Occured on adding");
            const errorM = err?.response?.data?.errorMessage;
            if (
              errorM.includes("duplicate") &&
              errorM.includes("IX_Clients_ClientRef")
            ) {
              updateModalState(true, "Reference already added", false);
            }
          }
        }
      } catch (error) {
        dispatch(setMainLoading(false));
        console.error(error);
      }
    }
  };

  const handleTypeNameChange = (e) => {
    if (e.value.typeName === "Person") {
      setTabs(personTab);
      setType(e.value);
    } else {
      setTabs(companyTab);
      setType(e.value);
    }
  };

  const reuiredValidator = (value) => (value ? "" : "Field is required");

  const handleBranchChange = (e) => {
    setBranch(e.value);
  };

  const getClientReference = async () => {
    const Name =
      props.formId === "add" && type?.typeName === "Person"
        ? firstName + " " + lastName
        : clientName;
    if (!Name) {
      return;
    }
    setShowLoader(true);
    try {
      const response = await api.getClientRefExample(Name);
      setReference(response?.data);
    } catch (error) {
    } finally {
      setShowLoader(false);
    }
  };

  const handleDateChange = (e, label) => {
    setDateState({
      ...dateState,
      [label]: e.value,
    });
  };

  const handleSelection = async (event) => {
    try {
      const response = await getCompanyProfile(event);
      setCompanyProfile(response?.data);
    } catch (error) {
      console.error("Failed to get company profile:", error);
    }
  };

  useEffect(() => {
    if (branchesName?.length && user?.branchId && props.formId === "add") {
      const defaultBranch = branchesName.filter(
        (branch) => branch.branchId === user.branchId
      )[0];
      setBranch(defaultBranch);
    }
  }, [branchesName]);

  return (
    <div className={styles["matter-form-dialog1"]}>
      {props.type === "VIEW" ? (
        <Dialog
          className="dialog-xxl add-dialog"
          title={"View Client"}
          onClose={props.onClose}
        >
          <div className="pt-3 px-4  ">
            <div className="row">
              <div className={`styles["view-tittle"] `}>
                <TabStrip
                  selected={selected}
                  onSelect={handleSelect}
                  keepTabsMounted={true}
                  className="mt-3 pagetab pagetab-client "
                >
                  {tabs.map((item, index) => (
                    <TabStripTab
                      title={
                        <PageTabItemTitle
                          client={true}
                          title={item}
                          index={index}
                          selected={selected}
                        />
                      }
                      key={index}
                    >
                      <div>
                        {item === "Contact Information" &&
                          type.typeName === "Company" && (
                            <>
                              <CompanyInformationView data={initialValues} />
                            </>
                          )}

                        {item === "Company Details" &&
                          type.typeName === "Company" && (
                            <>
                              <div className={`row`}>
                                <Field
                                  disabled={props.type === "VIEW"}
                                  name="companyRegNo"
                                  mr="20px"
                                  label="Registration Number"
                                  ph="e.g 273743383"
                                  width="255px"
                                  component={CustomInput}
                                />
                                <Field
                                  disabled={props.type === "VIEW"}
                                  name="vatNo"
                                  mr="20px"
                                  label="VAT Number"
                                  ph="e.g 273743383"
                                  width="255px"
                                  component={CustomInput}
                                />
                                <Field
                                  disabled={props.type === "VIEW"}
                                  name="sicCode"
                                  mr="20px"
                                  label="SIC Code"
                                  ph="e.g 273743383"
                                  width="255px"
                                  component={CustomInput}
                                />
                                <AddressInputComponent
                                  name="Registered Address"
                                  // address={initialValues?.address}
                                  readOnly={props.type === "VIEW"}
                                  type="registeredAddress"
                                  style={{
                                    marginTop: "20px",
                                    width: "640px",
                                  }}
                                />
                              </div>
                            </>
                          )}

                        {item === "Wills & Deeds" && (
                          <DocRegisterListTile clientId={props?.data} />
                        )}

                        {item === "Documents" && (
                          <DocumentList clientId={props?.data} />
                        )}

                        {item === "Notes" && (
                          <NotesListTile clientId={props?.data?.clientId} />
                        )}

                        {item === "ID Check" && (
                          <IdCheck client={props?.data} />
                        )}

                        {item === "Correspondence Contact" &&
                          type.typeName === "Company" && (
                            <>
                              <div>
                                <div className="flex mt-4">
                                  <Field
                                    disabled={props.type === "VIEW"}
                                    name="title"
                                    style={{ marginRight: "13px" }}
                                    component={Title}
                                  />

                                  <PersonNameComponent
                                    readOnly={props.type === "VIEW"}
                                    required={[false, false, false]}
                                  />
                                </div>
                                <div className={`row gy-4 mt-1`}>
                                  <ContactInformation
                                    readOnly={props.type === "VIEW"}
                                    addType={"correspondenceContact"}
                                    type={type.typeName}
                                    tab="cc"
                                  />
                                </div>
                              </div>
                            </>
                          )}

                        {item === "Contact Information" &&
                          type.typeName === "Person" && (
                            <ContactInformationView data={initialValues} />
                          )}

                        {item === "Personal Information" && (
                          <ContactInformationView />
                        )}

                        {item === "Statement & Billing" && (
                          <StatementInformationView data={initialValues} />
                        )}

                        {item === "Credits & Defaults" && (
                          <CreditsInformationView data={initialValues} />
                        )}

                        {item === "Risk Assessment" && (
                          <RiskAssessment clientId={props?.data?.clientId} />
                        )}

                        {item === "Matters" && (
                          <ClientMatters list={props.data?.matters} />
                        )}
                      </div>
                    </TabStripTab>
                  ))}
                </TabStrip>
              </div>
            </div>
          </div>
          <DialogActionsBar>
            <div className="dialog-footer">
              <Button
                type="button"
                onClick={() => {
                  dispatch(changeClientEditPermission(props.data));
                }}
                className="common-btn  border-0 me-5"
              >
                Edit
              </Button>
            </div>
          </DialogActionsBar>
        </Dialog>
      ) : (
        <div className="pt-3 px-5">
          <div className={`styles["view-tittle"]`}>
            <Form
              initialValues={initialValues}
              onSubmitClick={handleSubmit}
              key={JSON.stringify(initialValues)}
              render={(formRenderProps) => (
                <>
                  <form ref={formRef} onSubmit={handleSubmit}>
                    <TabStrip
                      selected={selected}
                      onSelect={handleSelect}
                      scrollable={true}
                      keepTabsMounted={true}
                      className="mt-3 pagetab"
                      style={{
                        userSelect: "none",
                      }}
                      animation="fade"
                    >
                      {tabs.map((item, index) => (
                        <TabStripTab
                          title={
                            <PageTabItemTitle
                              className="mb-sm-42 mb-xl-0"
                              client={true}
                              title={item}
                              index={index}
                              selected={selected}
                            />
                          }
                          key={index}
                        >
                          <div className="container  mt-4  ">
                            {item === "Contact Information" && (
                              <div className="row g-2">
                                <div className="col-12 col-xl-7 ">
                                  <Tile
                                    heading="Contact"
                                    className="px-4 py-2  overflow-auto"
                                    height={"100%"}
                                  >
                                    <div className="container pb-2 ">
                                      <div className="row">
                                        <div className={`col-3`}>
                                          <Label className="label">Type</Label>
                                          <ComboBox
                                            disabled={
                                              props.type === "VIEW" ||
                                              isFromView
                                            }
                                            data={typesList}
                                            required={true}
                                            textField="typeName"
                                            style={{
                                              width: "260px",
                                              height: "32px",
                                              boxShadow:
                                                "0px 1px 9px rgba(0, 0, 0, 0.12)",
                                            }}
                                            placeholder="Select Type"
                                            dataItemKey="typeId"
                                            clearButton={false}
                                            iconClassName="wa"
                                            value={type}
                                            onChange={handleTypeNameChange}
                                          />
                                        </div>
                                      </div>
                                      <div className={`row`}></div>
                                      <div className="row mt-2">
                                        {type.typeName === "Company" && (
                                          <div className="col-5 me-4">
                                            <div className="">
                                              <Label>Company Name</Label>
                                              {props?.formId == "add" ? (
                                                <Field
                                                  // blurRef={getClientReference}
                                                  disabled={
                                                    props.type === "VIEW"
                                                  }
                                                  name="clientName"
                                                  label="Company Name"
                                                  ph="Enter Company Name"
                                                  component={AutoCompleteInput}
                                                  itemRender={CompanyItemRender(
                                                    handleSelection
                                                  )}
                                                  setClientName={setClientName}
                                                />
                                              ) : (
                                                <Input
                                                  name="clientName"
                                                  placeholder="Enter Client Name"
                                                  value={clientName}
                                                  required
                                                  onChange={(e) =>
                                                    setClientName(
                                                      e.target.value
                                                    )
                                                  }
                                                  onBlur={getClientReference}
                                                  style={{
                                                    width: "260px",
                                                    height: "32px",
                                                  }}
                                                  disabled={
                                                    props.type === "VIEW"
                                                  }
                                                />
                                              )}
                                            </div>
                                          </div>
                                        )}
                                      </div>

                                      {type.typeName === "Person" && (
                                        <>
                                          <div className="row mt-2">
                                            <div
                                              className={`col-5  d-flex flex-column justify-content-end`}
                                            >
                                              <Label className="label">
                                                First Name
                                              </Label>
                                              <Input
                                                onBlur={getClientReference}
                                                disabled={props.type === "VIEW"}
                                                name="firstName"
                                                required={true}
                                                placeholder="Enter First Name"
                                                style={{
                                                  width: "260px",
                                                  height: "32px",
                                                }}
                                                value={firstName}
                                                onChange={(e) => {
                                                  setFirstName(e.target.value);
                                                }}
                                              />
                                            </div>
                                            <div className="col-1"></div>
                                            <div className="col-4">
                                              <Label className="label">
                                                Last Name
                                              </Label>

                                              <Input
                                                onBlur={getClientReference}
                                                disabled={props.type === "VIEW"}
                                                name="lastName"
                                                required={true}
                                                placeholder="Enter Last Name"
                                                style={{
                                                  width: "260px",
                                                  height: "32px",
                                                }}
                                                value={lastName}
                                                onChange={(e) => {
                                                  setLastName(e.target.value);
                                                }}
                                              />
                                            </div>
                                          </div>
                                        </>
                                      )}
                                      <div className="row mt-2">
                                        <div
                                          className={`col-5  d-flex flex-column justify-content-end`}
                                        >
                                          <Label className="label">
                                            Branch
                                          </Label>
                                          <ComboBox
                                            data={branchesName}
                                            required={true}
                                            textField="branchName"
                                            style={{
                                              width: "260px",
                                              height: "32px",
                                            }}
                                            placeholder="Select Branch"
                                            dataItemKey="branchId"
                                            iconClassName="wa"
                                            value={branch}
                                            onChange={handleBranchChange}
                                          />
                                        </div>
                                        <div className="col-1"></div>
                                        <div className={`col-4`}>
                                          <Label className="label">
                                            Client Ref
                                          </Label>
                                          <Input
                                            disabled={props.type === "VIEW"}
                                            maxLength={10}
                                            name="clientRef"
                                            required={true}
                                            placeholder="Enter Client Ref"
                                            style={{
                                              width: "260px",
                                              height: "32px",
                                              boxShadow:
                                                "0px 1px 9px rgba(0, 0, 0, 0.12)",
                                            }}
                                            value={reference}
                                            onChange={(e) => {
                                              setReference(e.target.value);
                                            }}
                                          />
                                        </div>
                                      </div>

                                      <div className="row mt-2">
                                        <Field
                                          name={`phone1`}
                                          label="Phone 1"
                                          type="number"
                                          ph="Phone Number"
                                          width={"260px"}
                                          component={CustomInput}
                                          className={`col-5 me-4`}
                                          validator={phoneValidator}
                                        />
                                      </div>

                                      <div className="row mt-2">
                                        <Field
                                          className={`col-5 `}
                                          name={`phone2`}
                                          label="Phone 2"
                                          type="number"
                                          ph="Secondary Phone"
                                          width={"260px"}
                                          component={CustomInput}
                                          validator={phoneValidator}
                                        />
                                        <div className="col-1"></div>
                                        <Field
                                          className={`col-4 `}
                                          label="Email"
                                          name={`email`}
                                          ph="Email Address"
                                          width={"260px"}
                                          validator={emailValidator}
                                          component={CustomInput}
                                        />
                                      </div>
                                      <div className="row mt-2">
                                        <Field
                                          className={`col-5`}
                                          label="Mobile No"
                                          name={`mobileNo`}
                                          type="number"
                                          ph="Enter Mobile Number"
                                          width={"260px"}
                                          validator={phoneValidator}
                                          component={CustomInput}
                                        />
                                        <div className="col-1"></div>
                                        <Field
                                          label="Website"
                                          name={`website`}
                                          ph="Enter Website URL"
                                          width={"260px"}
                                          className={"col-4"}
                                          component={CustomInput}
                                        />
                                      </div>
                                      <div className="mt-2 ">
                                        <AddressInputComponent
                                          name="Address"
                                          contactComponent={`col-6 `}
                                          width={"260px"}
                                          type={""}
                                          style={{
                                            width: "103%",
                                          }}
                                          readOnly={false}
                                        />
                                      </div>
                                    </div>
                                  </Tile>
                                </div>
                                {type.typeName === "Company" ? (
                                  <div className="col-12 col-xl-5">
                                    <Tile
                                      heading="Correspondence Person"
                                      className="px-4  py-2 overflow-auto"
                                      height={"100%"}
                                    >
                                      <div className="px-2 pb-2">
                                        <div className="container">
                                          <div className="col-3">
                                            <Field
                                              name={`corrFirstName`}
                                              label="Name"
                                              width={"260px"}
                                              ph="Enter Name"
                                              component={CustomInput}
                                            />
                                          </div>
                                          <div className="col-3 mt-3">
                                            <Field
                                              name={`corrPhone`}
                                              label="Phone Number"
                                              type="number"
                                              width={"260px"}
                                              ph="Enter Phone Number"
                                              component={CustomInput}
                                            />
                                          </div>
                                          <div className="col-3 mt-3">
                                            <Field
                                              name={`corrEmail`}
                                              label="Email"
                                              width={"260px"}
                                              ph="Enter Email Address"
                                              validator={emailValidator}
                                              required={false}
                                              component={CustomInput}
                                            />
                                          </div>
                                          <div className="col-3 mt-3">
                                            <Field
                                              name={`corrJobTitle`}
                                              label="Job Title"
                                              width={"260px"}
                                              ph="Enter Job Title"
                                              component={CustomInput}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </Tile>
                                  </div>
                                ) : (
                                  <div className="col-12 col-xl-5">
                                    <Tile
                                      heading="Personal Details"
                                      className="px-4 py-2 overflow-auto"
                                    >
                                      <div className="px-2">
                                        <div className="container">
                                          <div className="col-3">
                                            <FormComboBox
                                              name="gender"
                                              label="Gender"
                                              placeholder="Select Gender"
                                              wf={true}
                                              width={"260px"}
                                              mr={"5px"}
                                              data={genders}
                                              isStyled={true}
                                              state={comboBoxState}
                                              onChangeComboBox={(e) => {
                                                setComboBoxState({
                                                  ...comboBoxState,
                                                  gender: e?.value,
                                                });
                                              }}
                                            />
                                          </div>
                                          <div className="col-3 mt-3 form-date-picker">
                                            <FormDate
                                              className={
                                                "form-date-picker note-date-picker "
                                              }
                                              wf={true}
                                              isStyled={true}
                                              width={"260px"}
                                              customLabel={"Date Of Birth"}
                                              label={"dateOfBirth"}
                                              onChangeDate={handleDateChange}
                                              state={dateState}
                                            />
                                          </div>
                                          <div className="col-3 mt-3">
                                            <Field
                                              name="niNumber"
                                              label="NI Number"
                                              mr={"5px"}
                                              ph="Enter National Insurance Number"
                                              width={"260px"}
                                              component={CustomInput}
                                            />
                                          </div>
                                          <div className="col-3 mt-3">
                                            <Field
                                              name="nameAtBirth"
                                              label="Name At Birth"
                                              mr={"5px"}
                                              ph="Enter Name at Birth"
                                              width="260px"
                                              component={CustomInput}
                                            />
                                          </div>{" "}
                                          <div className="col-3 mt-3">
                                            <Field
                                              name="placeOfBirth"
                                              label="Place Of Birth"
                                              mr={"5px"}
                                              ph="Enter Place Of Birth"
                                              width="260px"
                                              component={CustomInput}
                                            />
                                          </div>{" "}
                                          <div className="col-3 mt-3">
                                            <FormComboBox
                                              name="ethnicity"
                                              label="Ethnicity"
                                              placeholder="Select Ethnicity"
                                              wf={true}
                                              width={"260px"}
                                              mr={"5px"}
                                              data={ethnicity}
                                              isStyled={true}
                                              state={comboBoxState}
                                              onChangeComboBox={(e) => {
                                                setComboBoxState({
                                                  ...comboBoxState,
                                                  ethnicity: e?.value,
                                                });
                                              }}
                                            />
                                          </div>
                                          <div className="col-3 mt-3">
                                            <FormComboBox
                                              name="disability"
                                              label="Disability"
                                              placeholder="Select Disability"
                                              wf={true}
                                              width={"260px"}
                                              mr={"5px"}
                                              data={disability}
                                              isStyled={true}
                                              state={comboBoxState}
                                              onChangeComboBox={(e) => {
                                                setComboBoxState({
                                                  ...comboBoxState,
                                                  disability: e?.value,
                                                });
                                              }}
                                            />
                                          </div>{" "}
                                          <div className="col-3 mt-3 pb-3 form-date-picker">
                                            <FormDate
                                              className={
                                                "form-date-picker note-date-picker"
                                              }
                                              wf={true}
                                              isStyled={true}
                                              width={"260px"}
                                              customLabel={"Date Of Death"}
                                              label={"dateOfDeath"}
                                              onChangeDate={handleDateChange}
                                              state={dateState}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </Tile>
                                  </div>
                                )}
                              </div>
                            )}

                            {item === "Company Details" &&
                              type.typeName === "Company" && (
                                <CompanyDetails
                                  initialValues={initialValues}
                                  readOnly={false}
                                />
                              )}

                            {item === "Correspondence Contact" &&
                              type.typeName === "Company" && (
                                <CorrespondenceContact
                                  type={type}
                                  readOnly={false}
                                  handleSelection={handleSelection}
                                />
                              )}

                            {item === "Personal Information" && (
                              <PersonalInformation
                                readOnly={false}
                                comboBoxState={comboBoxState}
                                setComboBoxState={setComboBoxState}
                                dateState={dateState}
                                handleDateChange={handleDateChange}
                              />
                            )}

                            {item === "Statement & Billing" && (
                              <StatementBilling
                                readOnly={false}
                                initialValues={initialValues}
                              />
                            )}

                            {item === "Credit & Defaults" && (
                              <CreditsDefaults
                                readOnly={false}
                                comboBoxState={comboBoxState}
                                setComboBoxState={setComboBoxState}
                                initialValues={initialValues}
                              />
                            )}

                            {item === "Matters" && (
                              <ClientMatters list={props.data?.matters} />
                            )}

                            <button
                              id="client-submitter"
                              type="submit"
                              style={{ visibility: "hidden" }}
                              onClick={() => formRenderProps.onSubmit()}
                            ></button>
                          </div>
                        </TabStripTab>
                      ))}
                    </TabStrip>{" "}
                  </form>
                </>
              )}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default AddClients;
