import { useState } from "react";
import axios from "axios";
import * as api from "../api/index";
import { TOKEN } from "../api/axios.config";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { License, Tabs } from "components/Enums/TabEnums.ts";
import { getCompany } from "../actions/company.action";

const ACCOUNTS_LICENSE = [
  Tabs.ACCOUNTS_DASHBOARD,
  Tabs.CLIENTS,
  Tabs.MATTERS,
  Tabs.ACCOUNTS,
  Tabs.NOMINAL,
  Tabs.PURCHASE,
  Tabs.ADMIN,
  Tabs.REPORTS,
];

const CASE_MANAGEMENT_LICENSE = [
  Tabs.FEE_EARNER_DASHBOARD,
  Tabs.CLIENTS,
  Tabs.MATTERS,
  Tabs.CONTACTS,
  Tabs.FEE_EARNER,
  Tabs.CASE_FILE,
  Tabs.REPORTS,
];

export const useAllowedLiscence = (component) => {
  const [allowed, setAllowed] = useState(true);
  const [allowedList, setAllowedList] = useState([]);
  const subData = useSelector((state) => state.currentuser.userSub);
  const modules = useSelector((state) => state?.company?.company?.modules);

  const accessTabs = () => {
    try {
      const data = subData?.AllowedProducts?.split(",");

      let allowedTabs = [];
      data?.forEach((license) => {
        if (license.trim() === License.ACCOUNTS) {
          let accountsLicense = [...ACCOUNTS_LICENSE];
          if (modules?.includes("Consultants")) {
            accountsLicense.push(Tabs.CONSULTANT);
          }
          allowedTabs = [...allowedTabs, ...accountsLicense];
        } else if (license.trim() === License.CASE_MANAGEMENT) {
          allowedTabs = [...allowedTabs, ...CASE_MANAGEMENT_LICENSE];
        }
      });
      setAllowedList(allowedTabs);
    } catch (error) {}
  };

  const checkTab = () => {
    if (!allowedList.filter((tab) => tab === component)?.length) {
      setAllowed(false);
    }
  };

  useEffect(() => {
    accessTabs();
  }, [subData, modules]);

  useEffect(() => {
    if (allowedList.length) {
      checkTab();
    }
  }, [allowedList]);

  const subDataCheck = () =>
    subData?.AllowedProducts?.split(",")?.map((item) => item.trim()) || [];

  return { subData, allowed, checkTab, allowedList, subDataCheck };
};
