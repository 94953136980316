import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { AmountFormat } from "components/common/formatter";

const SupplierBalances = (props) => {
  const [yearToDate, setYearToDate] = React.useState(undefined);
  const [selectedPeriod, setSelectedPeriod] = React.useState(undefined);
  const [total, setTotal] = React.useState(undefined);
  const { supplierRefBalances } = useSelector(
    (state) => state.suppliersList
  );

  useEffect(() => {
    if (supplierRefBalances && props.type === "SupplierLedger") {
      let yearToDate = AmountFormat(supplierRefBalances.data.ytdBalance);
      setYearToDate(yearToDate);
      let selectedPeriod = AmountFormat(
        supplierRefBalances.data.periodBalance
      );
      setSelectedPeriod(selectedPeriod);
      let total = AmountFormat(supplierRefBalances.data.balance);
      setTotal(total);
    }
  }, [supplierRefBalances]);

  return (
    <div className="grid-layout-container" style={{ marginBottom: 25, width: '400px' }}>
      <table className="balance-table pb-2"  >
        <thead className="width-100">
          <tr>
            <th
              className="pb-2"
              // style={{
              //   fontFamily: "Montserrat",
              //   fontWeight: "700",
              //   fontSize: "15px",
              //   lineHeight: "18px",
              //   color: "black",
              // }}
              colSpan={2}
            >
              Balances
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="bt-text">Total</td>
            <td className="bt-value text-end">{props.supplier !== null && total}</td>
          </tr>
          <tr>
            <td className="bt-text">Year to date</td>
            <td c className="bt-value text-end">
              {props.supplier !== null && yearToDate}
            </td>
          </tr>
          <tr>
            <td className="bt-text">Selected period</td>
            <td className="bt-value text-end">{props.supplier !== null && selectedPeriod}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
export default SupplierBalances;
