import React, { useEffect, useState } from "react";
import DocumentViewer from "components/common/DocumentViewer";
import { Tile } from "components/UIComponents/Tile";
import styles from "./EngagementLetters.module.scss";
import { Button } from "@progress/kendo-react-buttons";
import {
  getWorkTypeEngagementLetters,
  updateWorkTypeEngagementLetters,
  runTemplateForEngagementLetter,
  saveActivity,
  updateOnboarding,
} from "api";
import { FaTrashAlt } from "react-icons/fa";
import Loader from "components/common/Loading";

const EngagementLetters = ({ moveNext, formData }) => {
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [showDocViewer, setShowDocViewer] = useState(false);
  const [data, setData] = useState([]);
  const [activeDocumentId, setActiveDocumentId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [documentData, setDocumentData] = useState([]);

  const workTypeId = formData?.matterDetails?.Work_Type?.workTypeID;

  useEffect(() => {
    if (workTypeId) {
      getEngagementLetters(workTypeId);
    }
  }, [workTypeId]);

  const getEngagementLetters = async (workTypeId) => {
    try {
      const response = await getWorkTypeEngagementLetters(workTypeId);
      const fetchedData = response.data.data;

      if (fetchedData.length === 0) {
        setLoading(false);
      }

      setData(fetchedData);
      if (fetchedData.length > 0) {
        // Fetch document data for each engagement letter
        const documentPromises = fetchedData.map((document) =>
          runDocumentTemplate(
            document?.documentTemplateId,
            formData?.matterDetails?.matterId,
            document?.templateDetails
          )
        );
        const documentResults = await Promise.all(documentPromises);
        setDocumentData(documentResults);
        // set selected document as the first document from the documentResults
        setSelectedDocument(documentResults[0]);
        setShowDocViewer(true);
        setActiveDocumentId(documentResults[0]?.documentTemplateId);
      }
    } catch (error) {
      console.error("Failed to fetch engagement letters:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDocumentClick = (document) => {
    setActiveDocumentId(document.documentTemplateId);
    const selectedDoc = documentData.find(
      (doc) => doc.documentTemplateId === document.documentTemplateId
    );
    setSelectedDocument(selectedDoc);
    setShowDocViewer(true);
  };

  const runDocumentTemplate = async (templateId, matterId, details) => {
    try {
      const response = await runTemplateForEngagementLetter(
        templateId,
        matterId
      );
      const res = response.data.data;
      return { ...res, details, documentTemplateId: templateId };
    } catch (error) {
      console.error("Failed to run document template:", error);
      return null;
    }
  };

  const handleRemoveDocument = async (documentId) => {
    const updatedData = data.map((doc) =>
      doc.documentTemplateId === documentId ? { ...doc, active: false } : doc
    );
    try {
      await updateWorkTypeEngagementLetters(workTypeId, updatedData);
      getEngagementLetters(workTypeId);
    } catch (error) {
      console.error("Failed to update engagement letters:", error);
    }
  };

  const handleSubmit = async () => {
    if (documentData.length === 0) {
      moveNext({}, { EngagementLettersData: {} });
      return;
    }
    setLoading(true);
    const updatedLetters = [];

    for (const document of documentData) {
      try {
        const dataObject = {
          typeId: 2,
          date: new Date(),
          details: document?.details,
          feeEarnerRef: formData?.matterDetails?.Fee_Earner?.userRef,
          units: 0,
          fileId: document.fileID,
          subType: "None",
        };

        const response = await saveActivity(
          formData?.matterDetails?.matterId,
          dataObject
        );

        updatedLetters.push({
          ...document,
          documentId: response.data.data.documentId,
        });
      } catch (error) {
        console.error("Failed to save activity:", error);
      }
    }
    updateOnboarding(
      {
        letters: updatedLetters,
      },
      formData.onboardingId
    );
    setLoading(false);

    moveNext({}, { EngagementLettersData: documentData });
  };

  const handleRefresh = async () => {
    try {
      const refreshedDocument = await runDocumentTemplate(
        activeDocumentId,
        formData?.matterDetails?.matterId
      );
      setSelectedDocument(refreshedDocument);
    } catch (error) {
      console.error("Failed to refresh document:", error);
    }
  };

  return (
    <Tile heading="Engagement Letters" className="px-4 overflow-auto h-100">
      <div style={{ height: "calc(100% - 70px)" }}>
        {loading && (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: "100%" }}
          >
            <Loader />
          </div>
        )}
        {!loading && (
          <>
            <div
              className="overflow-auto"
              style={{ height: "calc(100% - 55px)" }}
            >
              <div className={styles.container}>
                <div className={styles.documentList}>
                  {data && data.length > 0 ? (
                    data.map((document) => (
                      <div
                        key={document.documentTemplateId}
                        className={`${styles.documentItem} ${
                          activeDocumentId === document.documentTemplateId
                            ? styles.activeDocumentItem
                            : ""
                        }`}
                      >
                        <div
                          onClick={() => handleDocumentClick(document)}
                          className={styles.documentName}
                        >
                          <p className="fw-bold mb-1">
                            {document.templateName}
                          </p>
                          <span>{document.templateDetails}</span>
                        </div>
                        <div>
                          <FaTrashAlt
                            className={styles.removeIcon}
                            onClick={() =>
                              handleRemoveDocument(document.documentTemplateId)
                            }
                            title="Remove Document"
                            color="red"
                          />
                        </div>
                      </div>
                    ))
                  ) : (
                    <span>No engagement letters available.</span>
                  )}
                </div>
                <div className={styles.documentViewer}>
                  {showDocViewer && selectedDocument && (
                    <DocumentViewer
                      data={[selectedDocument]}
                      onClose={() => setShowDocViewer(false)}
                      onScreenComponent={true}
                      handleRefresh={handleRefresh}
                      OnlyRefreshEditIcon={true}
                    />
                  )}
                </div>
              </div>
            </div>
            <div style={{ height: "55px" }}>
              <hr className="m-0" />
              <Button
                onClick={handleSubmit}
                className="mt-3 common-btn"
                style={{ float: "right" }}
              >
                Next
              </Button>
            </div>
          </>
        )}
      </div>
    </Tile>
  );
};

export default EngagementLetters;
