import React, { useEffect } from "react";
import { PDFViewer } from "@progress/kendo-react-pdf-viewer";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import { toast } from "react-toastify";
import { IoCaretBack, IoCaretForward } from "react-icons/io5";
import { FaExpandAlt } from "react-icons/fa";
import excelIcon from "../../assets/fileIcons/Excel.svg";
import emailIcon from "../../assets/fileIcons/Email.svg";
import wordIcon from "../../assets/fileIcons/Word.svg";
import pptIcon from "../../assets/fileIcons/PowerPoint.svg";
import downloadIcon from "../../assets/download.png";
import otherFormat from "../../assets/fileIcons/Document.svg";
import { BiCollapse, BiExpand, BiRefresh } from "react-icons/bi";
import editIcon from "../../assets/common/editBoxIcon.png";
import { CrossIcon } from "components/tabs/TabTitle";
import { Loader } from "@progress/kendo-react-indicators";
import { Editor } from "@progress/kendo-react-editor";
import { IoMdDownload } from "react-icons/io";
import pdfIcon from "../../assets/CaseFileIcons/pdf-icon.png";
import { FaShare } from "react-icons/fa";
import ShareDocumentModal from "pages/CaseFile/ShareDocumentModal";
import {
    cancelDocumentEditor,
    getDocument,
    getDocumentEditor,
    saveDocumentEditor,
} from "api";
import { Button } from "@progress/kendo-react-buttons";

const powerPointExt = ["ppt", "pptx"];
const wordExt = ["doc", "docx"];
const excelExt = ["xls", "xlsx"];
const emailExt = ["msg", "eml"];

const truncateFilename = (filename = "", maxLength = 10) => {

    if (filename.length <= maxLength) return filename;
    const parts = filename.split(".");
    if (parts.length < 2) return filename; // No extension found
    const extension = parts.pop();
    const truncatedPart = filename.slice(0, maxLength - extension.length - 1);
    return `${truncatedPart}...${extension}`;
};

export default function DocumentEditor({ cancelEdit, matterId, documentId }) {
    const [loadingScreen, setLoadingScreen] = useState(false);
    const [editorUrl, setEditorUrl] = useState();
    const [dialogSize, setDialogSize] = useState({
        width: "700px",
        height: "600px",
    });

    const getDocuemntEditUrl = async (id) => {
        try {
            setLoadingScreen(true);
            const { data } = await getDocument(documentId);
            setEditorUrl(data?.data?.documentFile?.editViewURL);
        } catch (error) {
            toast.error("Error occured while editing");
        } finally {
            setLoadingScreen(false);
        }
    };

    const saveDocuemntEdit = async (id) => {
        try {
            setLoadingScreen(true);
            await saveDocumentEditor(documentId, matterId);
            cancelEdit(true);
            toast.success("Document Saved");
        } catch (error) {
            toast.error("Error occured while editing");
        } finally {
            setLoadingScreen(false);
        }
    };

    const cancelDocuemntEdit = async (id) => {
        try {
            setLoadingScreen(true);
            await cancelDocumentEditor(documentId, matterId);
            cancelEdit(true);
            toast.success("Editing Cancelled");
        } catch (error) {
            toast.error("Error occured while editing");
        } finally {
            setLoadingScreen(false);
        }
    };

    const iframe = `<iframe className = "border-0" style="width: 100%; height:460px" scrolling="no" title="fx." src='${editorUrl}?action=embedview' allowtransparency="true" allowfullscreen="true"></iframe>`;
    function Iframe(props) {
        return <div dangerouslySetInnerHTML={{ __html: iframe }} />;
    }
    useEffect(() => {
        getDocuemntEditUrl();
    }, []);

    return (
        <div className="pdfwindow">
            <Dialog
                className="pdfwindow fs-md"
                height={dialogSize.height}
                width={dialogSize.width}
                onClose={cancelEdit}
                title={"Edit Document"}
            >
                <div style={{ height: `475px` }}>
                    {loadingScreen ? (
                        <Loader
                            themeColor={"warning"}
                            size={"large"}
                            type={"pulsing"}
                            style={{
                                position: "absolute",
                                top: "40%",
                                left: "45%",
                                zIndex: 100,
                            }}
                        />
                    ) : (
                        <Iframe iframe={iframe} />
                    )}
                </div>

                <div className="dialog-footer">
                    <Button
                        className="me-2 common-btn bgBtnSecondary"
                        icon="refresh"
                        style={{ height: "35px", minWidth: "40px", zIndex: "100" }}
                        onClick={() => {
                            getDocuemntEditUrl();
                        }}
                    ></Button>
                    <Button
                        className="common-btn bgBtnSecondary border-0 me-2"
                        onClick={() => cancelDocuemntEdit(true)}
                    >
                        Cancel Changes
                    </Button>
                    <Button
                        className="common-btn me-4"
                        type="button"
                        form="matterForm"
                        onClick={(e) => {
                            saveDocuemntEdit();
                        }}
                    >
                        Save Changes
                    </Button>
                </div>
            </Dialog>
        </div>
    );
}
