import { TABS_CONFIG, TABS_CONFIG_REMOVE } from "../types/index";

const INITIAL_STATE = {
  nominalTabs: [],
  accountTabs: [],
  purchaseTabs: [],
  consultantTabs: [],
  feeearnerTabs: [],
  adminTabs: [],
  reportTabs: [],
};

const tabsReducer = (state = INITIAL_STATE, action) => {
  switch (action?.type) {
    case TABS_CONFIG:
      if (action.payload.type === "nominals") {
        return {
          ...state,
          nominalTabs: [...state.nominalTabs, action.payload.data],
        };
      } else if (action.payload.type === "Accounts") {
        return {
          ...state,
          accountTabs: [...state.accountTabs, action.payload.data],
        };
      } else if (action.payload.type === "admin") {
        return {
          ...state,
          adminTabs: [...state.adminTabs, action.payload.data],
        };
      } else if (action.payload.type === "feeearner") {
        return {
          ...state,
          feeearnerTabs: [...state.feeearnerTabs, action.payload.data],
        };
      } else if (action.payload.type === "purchase") {
        return {
          ...state,
          purchaseTabs: [...state.purchaseTabs, action.payload.data],
        };
      } else if (action.payload.type === "consultant") {
        return {
          ...state,
          consultantTabs: [...state.consultantTabs, action.payload.data],
        };
      } else if (action.payload.type === "reports") {
        return {
          ...state,
          reportTabs: [...state.reportTabs, action.payload.data],
        };
      }
    case TABS_CONFIG_REMOVE:
      if (action.payload.type === "nominals") {
        let index = state.nominalTabs.indexOf(
          action.payload.data?.toLowerCase()?.replace(" ", "")
        );
        state.nominalTabs.splice(index, 1);
        return {
          ...state,
          nominalTabs: [...state.nominalTabs],
        };
      } else if (action.payload.type === "Accounts") {
        let index = state.accountTabs.indexOf(
          action.payload.data?.toLowerCase()?.replace(" ", "")
        );
        state.accountTabs.splice(index, 1);
        return {
          ...state,
          accountTabs: [...state.accountTabs],
        };
      } else if (action.payload.type === "admin") {
        let index = state.adminTabs.indexOf(
          action.payload.data?.toLowerCase()?.replace(" ", "")
        );
        state.adminTabs.splice(index, 1);
        return {
          ...state,
          adminTabs: [...state.adminTabs],
        };
      } else if (action.payload.type === "feeearner") {
        let index = state.feeearnerTabs.indexOf(
          action.payload.data?.toLowerCase()?.replace(" ", "")
        );
        state.feeearnerTabs.splice(index, 1);
        return {
          ...state,
          feeearnerTabs: [...state.feeearnerTabs],
        };
      } else if (action.payload.type === "purchase") {
        let index = state.purchaseTabs.indexOf(
          action.payload.data?.toLowerCase()?.replace(" ", "")
        );
        state.purchaseTabs.splice(index, 1);
        return {
          ...state,
          purchaseTabs: [...state.purchaseTabs],
        };
      } else if (action.payload.type === "consultant") {
        let index = state.consultantTabs.indexOf(
          action.payload.data?.toLowerCase()?.replace(" ", "")
        );
        state.consultantTabs.splice(index, 1);
        return {
          ...state,
          consultantTabs: [...state.consultantTabs],
        };
      } else if (action.payload.type === "reports") {
        let index = state.reportTabs.indexOf(
          action.payload.data?.toLowerCase()?.replace(" ", "")
        );
        state.reportTabs.splice(index, 1);
        return {
          ...state,
          reportTabs: [...state.reportTabs],
        };
      }
    default:
      return state;
  }
};

export default tabsReducer;
