import React from "react";
import { useState, useEffect } from "react";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import {
  createClientSetvicesTask,
  getClientServicesTasks,
  getClientTasksTemplate,
  updateOnboarding,
} from "api";
import { UserScreens } from "components/CaseFiles/AddUserScreens";
import { useSelector } from "react-redux";
import { Checkbox } from "@progress/kendo-react-inputs";
import { DateFormatCell } from "components/common/formatter";
import styles from "../../CaseFile/ClientServices/index.module.scss";
import EditIcon from "../../../assets/common/editBoxIcon.png";
import { Button } from "@progress/kendo-react-buttons";
import { MdArrowDropDown } from "react-icons/md";
import { Tile } from "components/UIComponents/Tile";

const clientTypes = {
  DataCollection: 1,
  IDVerification: 2,
};

const Tasks = ({ moveNext, formData, matter, showScreens, setShowScreens }) => {
  const [data, setData] = useState([]);
  const [selectedTasks, setSelectedTasks] = useState([]);
  const user = useSelector((state) => state.currentuser.userlist);
  const [showTaskScreen, setShowTaskScreen] = useState(false);

  const getMatterTasks = async () => {
    try {
      const data = await getClientServicesTasks(
        matter?.matterId || formData?.matterDetails?.matterId
      );
      setData(data?.data?.data);
    } catch (error) {}
  };

  const getClientTasks = async () => {
    try {
      const response = await getClientTasksTemplate(
        formData.matterDetails?.Work_Type?.workTypeID
      );
      const tasks = response.data.data;
      setSelectedTasks(tasks.map((task) => ({ ...task, selected: true })));
    } catch (error) {
      console.error("Failed to fetch client tasks template:", error);
    }
  };

  useEffect(() => {
    if (matter) getMatterTasks();
    if (formData) getClientTasks();
  }, [formData, matter]);

  const createUserTask = async (item) => {
    if (formData) {
      setSelectedTasks((prevTasks) => [
        ...prevTasks,
        {
          active: item[0]?.active,
          selected: true,
          // type: item[0]?.itemType,
          type: "DataCollection",
          details: item[0]?.details,
          userScreenId: item[0]?.userScreenId,
        },
      ]);
      setShowTaskScreen(false);
      return;
    }

    try {
      await createClientSetvicesTask(
        matter?.matterId || formData?.matterDetails?.matterId,
        {
          feeEarnerRef: user.userRef,
          details: "",
          notes: "",
          dateDueBy: "",
          userScreenId: item[0]?.userScreenId,
          active: true,
          type: "DataCollection",
          status: "",
        }
      );

      setShowScreens(false);
      getMatterTasks();
    } catch (error) {
    } finally {
    }
  };
  console.log("selectedTasks====", selectedTasks);
  const handleNext = async () => {
    const updatedTasks = [];
    for (const task of selectedTasks.filter((task) => task.selected)) {
      try {
        const response = await createClientSetvicesTask(
          formData?.matterDetails?.matterId,
          {
            feeEarnerRef: user.userRef,
            details: task.details || "",
            notes: "",
            dateDueBy:
              new Date(
                new Date().getTime() + task.daysDueIn * 24 * 60 * 60 * 1000
              ) || "",
            userScreenId: task.userScreenId || "",
            active: task.active,
            type: task.type,
            status: "",
          }
        );
        updatedTasks.push({
          ...task,
          clientTaskId: response.data.data.clientTaskID,
        });
      } catch (error) {
        console.error("Failed to create client services task:", error);
      }
    }
    updateOnboarding(
      {
        tasks: updatedTasks,
      },
      formData.onboardingId
    );

    moveNext({}, { TasksData: selectedTasks.filter((task) => task.selected) });
  };

  const toggleTaskSelection = (taskId) => {
    setSelectedTasks((prevTasks) =>
      prevTasks.map((task) =>
        task?.clientTaskTemplateId === taskId
          ? { ...task, selected: !task.selected }
          : task
      )
    );
  };

  const CheckBoxCell = (props) => {
    return (
      <td>
        <Checkbox
          checked={props.dataItem.selected}
          defaultChecked={props.dataItem.active}
          onChange={() =>
            toggleTaskSelection(props.dataItem.clientTaskTemplateId)
          }
          className="ms-3"
        />
      </td>
    );
  };

  const content = (
    <>
      {!formData && (
        <div className="client-services-grid">
          <Grid data={data} className={`fs-md ${styles.gridHeight}`}>
            <GridColumn field="date" cell={CheckFormatCell} width="60px" />
            <GridColumn field="date" cell={DateFormatCell} width="100px" />
            <GridColumn field="details" title="details" />
            <GridColumn field="type" title="type" width="200px" />
            {/*<GridColumn field="notes" title="notes" />*/}
            <GridColumn
              field="clientName"
              title="Client Name"
              cell={ActionsCell}
              width="160px"
            />
          </Grid>
        </div>
      )}
      {(showScreens || showTaskScreen) && (
        <UserScreens
          matterId={matter?.matterId || formData?.matterDetails?.matterId}
          addScreens={(data) => createUserTask(data)}
          cancelEdit={() => {
            if (formData) {
              setShowTaskScreen(false);
            } else {
              setShowScreens(false);
            }
          }}
          screenType="ClientScreen"
          title={"Data Collection Form"}
        />
      )}
    </>
  );

  return formData ? (
    <Tile heading="Client Tasks" className="px-4 h-100 overflow-auto">
      <div style={{ height: "calc(100% - 70px)" }}>
        <div className="overflow-auto" style={{ height: "calc(100% - 55px)" }}>
          <div className={`${styles.container} `}>
            <div className="d-flex my-3 justify-content-end">
              <Button
                style={{
                  width: "100px",
                  minWidth: "100px",
                  height: "35px",
                  zIndex: "100",
                }}
                className="common-btn pe-2"
                onClick={() => setShowTaskScreen(true)}
              >
                <span>Add Task </span>
                <MdArrowDropDown size={22} style={{ marginBottom: "2px" }} />
              </Button>
            </div>
            <div className="client-tasks-list">
              <Grid
                data={selectedTasks}
                className={`fs-lg fw-semibold ms-5 ${styles.gridHeight}`}
              >
                <GridColumn
                  className="ps-5"
                  field="active"
                  title="Select"
                  cell={CheckBoxCell}
                  width="80px"
                />
                <GridColumn field="details" title="Details" width="100px" />
                <GridColumn field="type" title="Type" width="100px" />
              </Grid>
            </div>
            {content}
          </div>
        </div>
        <div className="d-flex flex-column" style={{ height: "55px" }}>
          <hr className="m-0 w-100" />
          <div className="d-flex justify-content-end mt-2">
            <Button className="common-btn" onClick={handleNext}>
              Next
            </Button>
          </div>
        </div>
      </div>
    </Tile>
  ) : (
    <div>{content}</div>
  );
};

export default Tasks;

export const CheckFormatCell = (props) => {
  const { dataItem, field, className, onClick } = props;
  const status = dataItem.status; // Assuming status is a field in the dataItem

  const isCompleted = status === "Completed";

  return (
    <td onClick={(e) => onClick && onClick(e)}>
      <Checkbox
        type="checkbox"
        checked={isCompleted}
        className="ms-3"
        style={{
          borderRadius: "50%",
          height: "22px",
          width: "22px",
        }}
        readOnly
      />
    </td>
  );
};

export const ActionsCell = (props) => {
  const { dataItem, field, className, onClick } = props;
  const status = dataItem.status; // Assuming status is a field in the dataItem

  const isCompleted = status === "Completed";

  return (
    <td
      onClick={(e) => onClick && onClick(e)}
      className="d-flex align-items-center justify-content-center h-100 gap-4 me-2"
    >
      <div style={{ marginBottom: "-10px" }} className="me-2">
        <PrimaryBadgeComponent
          text={dataItem?.status}
          isCompleted={isCompleted}
        />
      </div>
      <div>
        <img
          className="cursor-pointer"
          alt="Edit Icon"
          onClick={() => {}}
          src={EditIcon}
        ></img>
      </div>
    </td>
  );
};

export const PrimaryBadgeComponent = (props) => {
  return (
    <p
      className={`badge rounded-pill ${
        styles[props?.isCompleted ? "green-pill" : "primary-pill"]
      } ${props.className} p-2 py1-1`}
      style={{ width: "90px", height: "26px" }}
    >
      {props.text}
    </p>
  );
};
