import { Button } from "@progress/kendo-react-buttons";
import { Checkbox } from "@progress/kendo-react-inputs";
import SearchTextBox from "components/SearchComponent";
import React, { useState, useRef, useEffect } from "react";
import styles from "./CaseFiles.module.scss";
import EditIcon from "../../assets/common/editBoxIcon.png";
import { Dialog } from "@progress/kendo-react-dialogs";
import { getAllUserScreens } from "api";
import { Loader } from "@progress/kendo-react-indicators";
import ScreenDesigner from "pages/CaseFile/CaseWorkFlow/ScreenDesigner/ScreenDesigner";

export const UserScreens = (props) => {
  const searchRef = useRef();
  const [userScreens, setUserScreens] = useState([]);
  const [filteredScreens, setFilteredScreens] = useState([]);
  const [editItem, setEditItem] = useState(null);
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [addNewQuestion, setAddNewQuestion] = useState(false);
  const [showScreenDesigner, setShowScreenDesigner] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const getUserScreens = async () => {
    try {
      setIsLoading(true);
      const data = await getAllUserScreens(props.screenType);
      const allowedScreens = allowedScreenIds(data?.data?.data);
      setUserScreens(allowedScreens);
      setFilteredScreens(allowedScreens); // Initialize filteredScreens to display all initially
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const allowedScreenIds = (screens) => {
    const ALREADY_ADDED_SCREENS = [];
    props.addedScreens?.forEach((screenItem) => {
      if (!ALREADY_ADDED_SCREENS.includes(screenItem.userScreenId)) {
        ALREADY_ADDED_SCREENS.push(screenItem.userScreenId);
      }
    });
    return screens?.filter(
      (item) => !ALREADY_ADDED_SCREENS.includes(item.userScreenId)
    );
  };

  const selectQuestion = (type, question) => {
    if (type) {
      setSelectedQuestions([...selectedQuestions, question]);
    } else {
      setSelectedQuestions(
        selectedQuestions.filter(
          (prevQuestion) => prevQuestion.userScreenId !== question?.userScreenId
        )
      );
    }
  };

  const addQuestions = () => {
    props.addScreens(selectedQuestions);
  };

  const handleSearchChange = (e) => {
    const searchText = e;
    setSearchTerm(searchText);

    // Filter userScreens based on the search term
    if (searchText?.trim()) {
      const filtered = userScreens.filter(
        (question) =>
          question.name.toLowerCase().includes(searchText) ||
          question.details.toLowerCase().includes(searchText)
      );
      setFilteredScreens(filtered);
    } else {
      setFilteredScreens(userScreens); // Reset if search is cleared
    }
  };

  useEffect(() => {
    getUserScreens();
  }, []);

  return (
    <>
      <Dialog
        onClose={() => props.cancelEdit()}
        width={700}
        title={`Select ${props?.title ? props?.title : "Data Collection Form"}`}
      >
        <div className="p-3 px-4 ms-3 me-3">
          <div className="d-flex align-items-center justify-content-between">
            <SearchTextBox
              width={"400px"}
              ref={searchRef}
              changeFilterValue={handleSearchChange} // Attach the search handler
            />
            <Button
              className="common-btn"
              onClick={() => setShowScreenDesigner(true)}
            >
              Add New Screen
            </Button>
          </div>
          <div
            className={`container-fluid mt-4 ${
              isLoading && "d-flex align-items-center justify-content-center"
            }`}
            style={{
              height: "400px",
              overflow: "auto",
            }}
          >
            {isLoading ? (
              <Loader themeColor={"warning"} size={"large"} type={"pulsing"} />
            ) : (
              filteredScreens?.map((question, index) =>
                props.questionIds?.includes(question?.userScreenId) ? null : (
                  <UserScreen
                    key={index}
                    question={question}
                    selectedQuestions={selectedQuestions}
                    selectQuestion={selectQuestion}
                    onSelectEdit={() => {
                      setEditItem(question);
                      setShowScreenDesigner(true);
                    }}
                  />
                )
              )
            )}
          </div>
        </div>
        <div className="dialog-footer">
          <button
            className="common-btn me-5"
            type={"submit"}
            onClick={addQuestions}
          >
            Select
          </button>
        </div>
        {showScreenDesigner && (
          <ScreenDesigner
            matterId={props.matterId}
            screenId={editItem?.userScreenId}
            screenType={props.screenType}
            onClose={(isSuccess) => {
              if (isSuccess) {
                getUserScreens();
              }
              if (editItem) {
                setEditItem(null);
              }
              setShowScreenDesigner(false);
            }}
          />
        )}
      </Dialog>
    </>
  );
};

const UserScreen = ({
  question,
  onSelectEdit,
  selectQuestion,
  selectedQuestions,
}) => {
  return (
    <div
      className={`row ${styles["box-styles"]} p-2 d-flex align-items-center mt-2`}
    >
      <div className="col-1" style={{ maxWidth: "40px" }}>
        <Checkbox
          defaultValue={false}
          value={selectedQuestions.some(
            (item) => item.userScreenId === question?.userScreenId
          )}
          onChange={(e) => {
            selectQuestion(e.value, { ...question, itemType: "UserScreen" });
          }}
        />
      </div>
      <div className={`col ${styles["question-text"]}`}>{question?.name}</div>

      <div className={`col ${styles["question-text"]}`}>
        {question?.details}
      </div>

      <div className="col-1" style={{ maxWidth: "50px" }}>
        <img
          className="cursor-pointer"
          onClick={() => onSelectEdit()}
          src={EditIcon}
          alt="Edit Icon"
          style={{ width: "20px", height: "20px" }}
        ></img>
      </div>
    </div>
  );
};
