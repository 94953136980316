import {
  START_LOADING,
  STOP_LOADING,
  SUCCESS,
  GET_CURRENCY_LIST,
  ADDEDNEWCURRENCY,
  UPDATE_CURRENCY,
  GET_ACTIVE_CURRENCY,
} from "../types/index";
import * as api from "../api/index.js";
import { handleApiError } from "Helper";
import { toast } from "react-toastify";

export const getCurrency = (page, limit) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const datas = await api.getCurrency(page, limit);
    dispatch({ type: GET_CURRENCY_LIST, payload: datas.data });
    dispatch({ type: STOP_LOADING });
    dispatch({ type: SUCCESS, payload: datas.message });
  } catch (error) {
    handleApiError(error);
  }
};

export const editCurrencyinfo = (id, data) => async (dispatch) => {
  try {
    const datas = await api.editCurrencyinfo(id, data);
    toast.success(`Currency '${data?.name}' has been updated`);
    dispatch({ type: UPDATE_CURRENCY, payload: datas });
  } catch (error) {
    toast.error(`Error occured while updaing currency`);
    handleApiError(error);
  }
};

export const addnewCurrency = (data) => async (dispatch) => {
  try {
    const datas = await api.addnewCurrency(data);
    dispatch({ type: ADDEDNEWCURRENCY, payload: datas });
  } catch (error) {
    handleApiError(error);
  }
};

export const getActiveCurrency = () => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const datas = await api.getActiveCurrency();
    dispatch({ type: GET_ACTIVE_CURRENCY, payload: datas.data });
  } catch (error) {
    handleApiError(error);
  }
};
