import {
  START_LOADING,
  STOP_LOADING,
  ERROR,
  SUCCESS,
  GET_VATCODES_LIST,
  UPDATE_VATCODE,
  RESET,
  GET_ACTIVEVATS
} from "../types/index.js";

const VatCodesReducer = (
  state = {
    IsError: false,
    isSuccess: false,
    isLoading: undefined,
    mattersList: [],
    activevats: { data: [] },
  },
  action
) => {
  switch (action.type) {
    case START_LOADING:
      return { ...state, isLoading: true };
    case STOP_LOADING:
      return { ...state, isLoading: false };
    case GET_VATCODES_LIST:
      return {
        ...state,
        vatcodesList: action.payload,
      };
    case UPDATE_VATCODE:
      return {
        ...state,
        vatcodesList: action.payload,
      };
    case GET_ACTIVEVATS:
      return {
        ...state,
        activevats: action.payload,
      };
    case RESET:
      const data = state;
      data.vatcodesList = undefined;
      return data;
    case ERROR:
      return { ...state, IsError: true, isSuccess: false };
    case SUCCESS:
      return { ...state, IsError: false, isSuccess: true };
    default:
      return state;
  }
};
export default VatCodesReducer;
