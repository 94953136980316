import React, { useEffect, useState } from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import { ContainerCard } from "components/MatterDetails/ContactCard";
import {
  getApplicationUserSetting,
  getSuggestedEmails,
  postESigning,
} from "api";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { Loader } from "@progress/kendo-react-indicators"; // Import your loader component
import { toast } from "react-toastify";

const ESigning = ({ title, onClose, matterId, activityId }) => {
  const SELECTED_FIELD = "selected";
  const [suggestedEmails, setSuggestedEmails] = useState([]);
  const [loading, setLoading] = useState(false); // State for loading

  const generateRandomId = () => Math.floor(Math.random() * 1000000); // Function to generate a random integer ID

  useEffect(() => {
    setLoading(true);
    const fetchSuggestedEmails = async () => {
      try {
        setLoading(true);
        const emails = await getSuggestedEmails(matterId);
        const emailsWithId = emails.data?.data.map((email) => ({
          ...email,
          id: generateRandomId(),
          inEdit: false,
        }));

        setSuggestedEmails([
          ...emailsWithId,
          {
            email: "",
            id: generateRandomId(),
            inEdit: true,
          },
        ]);
      } catch (error) {
        console.error("Failed to fetch suggested emails", error);
      } finally {
        setLoading(false);
      }
    };

    fetchSuggestedEmails();
  }, [matterId]);

  const openNewMail = async () => {
    setLoading(true); // Show loader
    try {
      const toList = suggestedEmails
        .filter((email) => email.selected) // Get active emails
        .map((email, index) => ({
          emailAddress: email?.email,
          name: email?.name || email?.email,
          order: index,
        }));
      await postESigning(matterId, activityId, toList);
      onClose();
      toast.success("Document has been sent for E-Signing");
    } catch (error) {
      toast.error("Failed to send Signing");
    } finally {
      setLoading(false); // Hide loader after operation
    }
  };

  const CheckboxCell = (props) => {
    const handleChange = (event) => {
      const updatedItem = {
        ...props.dataItem,
        [props.field]: event.target.checked,
      };

      handleCellChange({
        dataItem: updatedItem,
        field: props.field,
        value: event.target.checked,
      });
    };
    return (
      <td className="">
        <input
          type="checkbox"
          style={{ width: "18px", height: "18px" }}
          className="cursor-pointer mt-1"
          checked={props.dataItem[props.field] || false}
          onChange={handleChange}
        />
      </td>
    );
  };

  const handleCellChange = (event) => {
    const updatedEmails = suggestedEmails.map((email) =>
      email.id === event.dataItem.id
        ? { ...email, [event.field]: event.value }
        : email
    );
    setSuggestedEmails(updatedEmails);
  };

  const onItemChange = (event) => {
    const editedItemID = event.dataItem.id;
    let selected = false;
    if (event.field === "email" && event.value?.trim() !== "") {
      selected = true;
    }
    const data = suggestedEmails.map((item) =>
      item.id === editedItemID
        ? { ...item, [event.field]: event.value, selected: selected }
        : item
    );
    setSuggestedEmails(data);
  };

  return (
    <Dialog title={title} onClose={onClose} width={"800px"}>
      <div className="dialog-content p-3 pb-1">
        <ContainerCard className="p-3 ps-2 pe-2 pt-2 pb-0 overflow-auto">
          <Grid
            data={suggestedEmails}
            style={{ height: "400px" }}
            editField="inEdit"
            className="pb-1"
            onItemChange={onItemChange}
          >
            <GridColumn
              field={SELECTED_FIELD}
              width="50px"
              editable={false}
              cell={CheckboxCell}
              onChange={handleCellChange}
              title=" "
            />
            <GridColumn
              field="name"
              title="Name"
              className="fs-sm fw-bold"
              width="180px"
            />
            <GridColumn
              field="email"
              title="Email"
              className="fs-sm"
              editor="text"
            />
            <GridColumn
              field="contactCategory"
              title="Category"
              className="fs-sm"
              editable={false}
              width="130px"
            />
          </Grid>
        </ContainerCard>
      </div>

      {loading && (
        <div
          className="loader-container d-flex justify-content-center align-items-center"
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Loader themeColor={"warning"} size={"large"} type={"pulsing"} />
        </div>
      )}

      <div className="dialog-footer">
        <Button className="common-btn bgBtnSecondary me-2" onClick={onClose}>
          Close
        </Button>
        <Button
          className="common-btn me-3"
          onClick={openNewMail}
          disabled={loading}
        >
          Send
        </Button>
      </div>
    </Dialog>
  );
};

export default ESigning;
