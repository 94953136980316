export const onFileUpload = (e) => {
  const fileName = e?.target?.files[0]?.name;
  const form = new FormData();
  form.append("File", e.target.files[0]);
  const data = {
    name: fileName,
  };
  const appendData = {
    filename: fileName,
    fileID: "",
  };

  return appendData;
};
