import SideBar from "../menu/SideBarMenu";
import React, { useRef, useState } from "react";
import Tabs from "./Tabs";
import { HeaderProfile } from "./HeaderProfile";
import closeSideBar from "../../assets/collapse.png";
import openSideBar from "../../assets/expand.png";

const Layout = ({ viewmode }) => {
  const [tabsopen, settabopen] = useState(["Dashboard"]);
  const [showSettings, setShowSettings] = useState(false);
  const [backToSettings, setBackToSettings] = useState(false);
  const [collapsed, setCollapse] = useState(false);
  const ref = useRef();
  const showMenu = viewmode !== "outlook";

  let toggleLeftside = () => {
    setCollapse(!collapsed);
    ref.current.toggleSidebar();
  };

  return (
    <div className="container-fluid p-0 bg-light">
      {showMenu && (
        <img
          className={
            "leftside-collapse-button " +
            (collapsed
              ? "collapsed-button-location"
              : "expanded-button-location")
          }
          src={collapsed ? openSideBar : closeSideBar}
          onClick={() => {
            toggleLeftside();
          }}
          alt="Collapse Icon"
        ></img>
      )}

      <div className="row vh-100 vw-100 flex-nowrap">
        {showMenu && (
          <div
            className={
              "col p-0 pe-2 border-topright " +
              (collapsed ? "leftside-collapsed" : "leftside-expanded")
            }
          >
            <SideBar
              ref={ref}
              collapsedhaha={collapsed}
              showSettings={() => {
                setShowSettings(true);
                setBackToSettings(!backToSettings);
              }}
              hideSettings={() => {
                setShowSettings(false);
              }}
            ></SideBar>
          </div>
        )}
        <div className="col h-100 w-100 p-0 overflow-hidden">
          {/* {showSettings && (
                        <div className="h-100">
                          <Options
                            close={() => {
                              setShowSettings(false);
                            }}
                            onBack={backToSettings}
                          />
                        </div>
                    )} */}

          {/* {!showSettings && (
                        <Tabs className="h-100 w-100" showSettings={showSettings} />
                    )} */}

          <Tabs
            className="h-100 w-100"
            showSettings={showSettings}
            viewmode={viewmode}
          />

          {!showSettings && <HeaderProfile tabsopen={tabsopen} />}
        </div>
      </div>
    </div>
  );
};

export default Layout;
