import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { addMatterTask, getfeeearner, updateMatterTask } from "api/index";
import styles from "./Casefile.module.scss";
import { Dialog } from "@progress/kendo-react-dialogs";
import { prepareFeeearnerData } from "components/common/ComboRepresentationFunctions";
import { FormComboBox, FormInput, FormDate } from "components/Matters/index";
import { CONSTANT } from "CONSTANT/Matter/MatterActivityForm";
import { TextArea } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import { filterBy } from "@progress/kendo-data-query";
import { Tile } from "components/UIComponents/Tile";

const CaseTaskForm = (props) => {
  const [dateState, setDateState] = useState(new Date());
  const [dueDateState, setDueDateState] = useState(() => {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 7);
    return currentDate;
  });
  const [isDueDateChecked, setIsDueDateChecked] = useState(false);
  const [details, setDetails] = useState("");

  const { feeearner } = useSelector((state) => state.postingsdocs);
  const [comboBoxState, setComboBoxState] = useState({
    [CONSTANT.COMBOBOX.TYPE]: "",
    [CONSTANT.COMBOBOX.FEE_EARNER]: "",
  });
  const [feeEarner, setFeeEarner] = useState(feeearner.data);

  useEffect(() => {
    getFeeEarnerData();
  }, []);

  useEffect(() => {
    if (props.isView) {
      getEditData();
    }
  }, [props.isView]);

  const filterFeeEarner = (filter) => {
    const data = feeearner.data.slice();
    return filterBy(data, filter);
  };
  const feeEarnerfilterChange = (event) => {
    setFeeEarner(filterFeeEarner(event.filter));
  };

  const getFeeEarnerData = async () => {
    try {
      const { data } = await getfeeearner();
      const preparedData = prepareFeeearnerData(data.data);
      setFeeEarner(preparedData);
      if (!props?.isView) {
        const selectedFeeEarner = preparedData.find(
          (item) => item.userRef === props?.feeEarnerRef
        );
        setComboBoxState((prevState) => ({
          ...prevState,
          [CONSTANT.COMBOBOX.FEE_EARNER]: {
            displayname: selectedFeeEarner?.displayname,
            representation: selectedFeeEarner?.representation,
            userRef: props?.feeEarnerRef,
          },
        }));
      }
    } catch (error) {}
  };

  const handleComboBoxChange = (e, label) =>
    setComboBoxState((prevState) => ({
      ...prevState,
      [label]: e.value,
    }));

  const handleDateChange = (e) => setDateState(e.value);

  const handleDueDateChange = (e) => setDueDateState(e.value);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const parseDate = (newDate) => {
      if (!newDate) return null;
      var os = newDate?.getTimezoneOffset();
      return (newDate = new Date(newDate.getTime() - os * 60 * 1000));
    };

    const dataObject = {
      date: parseDate(dateState) || null,
      ...(isDueDateChecked && { dateDueBy: parseDate(dueDateState) }),
      feeEarnerRef:
        comboBoxState[CONSTANT.COMBOBOX.FEE_EARNER]?.userRef || null,
      details: details,
      type: "FeeEarnerTask",
    };
    try {
      const res = props.isView
        ? await updateMatterTask(props?.viewTask?.taskId, dataObject)
        : await addMatterTask(props?.matterId, dataObject);

      if (res.status === 200) {
        props?.setIsRefreshed(!props?.isRefreshed);
        props?.setCloseModal();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getEditData = async () => {
    setDetails(props.viewTask?.details);
    setComboBoxState({
      ...comboBoxState,
      [CONSTANT.COMBOBOX.FEE_EARNER]: {
        displayname: feeEarner?.find(
          (item) => item.userRef === props.viewTask?.feeEarnerRef
        )?.displayname,
        representation: feeEarner?.find(
          (item) => item.userRef === props.viewTask?.feeEarnerRef
        )?.representation,
        userRef: props.viewTask?.feeEarnerRef,
      },
    });
    setDateState(new Date(props.viewTask?.date));
    setIsDueDateChecked(props.viewTask?.dateDueBy ? true : false);
    props.viewTask?.dateDueBy &&
      setDueDateState(new Date(props.viewTask?.dateDueBy));
  };

  const handleCheckboxChange = (e) => {
    setIsDueDateChecked(e.value);
  };

  return (
    <div>
      <Dialog
        title={props.isView ? "Update Task" : "Add Task"}
        onClose={props?.setCloseModal}
      >
        <div
          className="overflow-hidden"
          style={{ width: "45vw", height: "55vh" }}
        >
          <form id="taskForm" onSubmit={handleSubmit}>
            <div className="row">
              <div
                className=" bgForm py-4 px-5 col-5"
                style={{
                  height: "55vh",
                }}
              >
                <div className="d-flex flex-column ">
                  <div className="">
                    <span className={`${styles["add-activity-form-text"]}`}>
                      {props.addFormText}
                    </span>
                  </div>
                  {/* <div className="d-flex flex-column mt-2">
                          <Label className="label mb-1"> Type</Label>
                          <ComboBox
                            style={{
                              textAlign: "end",
                              width: "280px",
                            }}
                            // data={types}
                            textField={`name`}
                            iconClassName="wa"
                            filterable={true}
                            placeholder={"Add Type"}
                            // value={type}
                          />
                        </div> */}
                  <FormDate
                    isStyled={true}
                    className="col-12 mt-3"
                    label={"Date"}
                    onChangeDate={handleDateChange}
                    state={{ Date: dateState }}
                  />
                  <FormComboBox
                    required={true}
                    isStyled={true}
                    className="col-12 mt-4"
                    //   width={"260px"}
                    wf={true}
                    onChangeComboBox={handleComboBoxChange}
                    onFilterChange={feeEarnerfilterChange}
                    data={feeEarner}
                    textField="representation"
                    state={comboBoxState}
                    name={CONSTANT.COMBOBOX.FEE_EARNER}
                  />
                  <FormDate
                    disabled={!isDueDateChecked}
                    isStyled={true}
                    className="col-12 mt-3"
                    label={"Due Date"}
                    onChangeDate={handleDueDateChange}
                    state={{
                      "Due Date": isDueDateChecked ? dueDateState : null,
                    }}
                    checkbox
                    checked={isDueDateChecked}
                    handleCheckboxChange={handleCheckboxChange}
                  />
                </div>
              </div>
              <div className="col-6 ms-auto me-5">
                <Tile
                  heading="Details"
                  className="px-4 pb-3 my-3 overflow-auto"
                  height={"42vh"}
                >
                  <TextArea
                    rows={10}
                    required
                    size="large"
                    placeholder="Enter Details"
                    autoSize={true}
                    value={details}
                    style={{
                      height: "32vh",
                      maxWidth: "420px",
                    }}
                    onChange={(e) => setDetails(e.value)}
                  />
                </Tile>
              </div>
            </div>
          </form>
        </div>

        <div className="dialog-footer">
          <Button
            className="common-btn bgBtnSecondary border-0 me-4"
            onClick={props?.setCloseModal}
          >
            Cancel
          </Button>
          <Button className="common-btn me-4" type="submit" form="taskForm">
            {props.isView ? "Update" : "Save"}
          </Button>
        </div>
      </Dialog>
    </div>
  );
};

export default CaseTaskForm;
