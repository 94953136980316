import React from "react";
import { Field } from "@progress/kendo-react-form";
import CustomInput from "components/common/Input";
import AddressInputComponent from "components/common/AddressInputComponent";
import { Tile } from "components/UIComponents/Tile";
import { emailValidator } from "components/common/validator";

const StatementBilling = ({ readOnly, initialValues }) => {
  return (
    <div className="row g-2">
      <div className="col-12 col-xl-6">
        <Tile
          heading="Statement Contact"
          height={"100%"}
          className="px-4  py-2 overflow-auto"
        >
          <div
            style={{
              height: "calc(-23rem + 100vh)",
              maxHeight: "500px",
              minHeight: "360px",
            }}
          >
            <div className="row">
              <div className="col-4 me-3">
                <Field
                  disabled={readOnly}
                  name="statementName"
                  mr="20px"
                  label="Name"
                  ph="Enter Full Name"
                  width="260px"
                  component={CustomInput}
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-4 me-3">
                <Field
                  disabled={readOnly}
                  name="statementEmail"
                  mr="20px"
                  label="Email"
                  ph="Enter Email Address"
                  width="260px"
                  component={CustomInput}
                  validator={emailValidator}
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-4">
                <Field
                  disabled={readOnly}
                  name="statementPhone"
                  mr="20px"
                  label="Phone Number"
                  type="number"
                  ph="Enter Phone Number"
                  width="260px"
                  component={CustomInput}
                />
              </div>
            </div>
            <div className=" mt-3 ">
              <AddressInputComponent
                name="Statement Address"
                readOnly={readOnly}
                type="si"
                width="260px"
              />
            </div>
          </div>
        </Tile>
      </div>
      <div className="col-12 col-xl-6">
        <Tile
          heading="Billing Contact"
          height={"100%"}
          className="px-4  py-2 overflow-auto"
        >
          <div
            style={{
              height: "calc(-23rem + 100vh)",
              maxHeight: "500px",
              minHeight: "360px",
            }}
          >
            <div className="row">
              <div className="col-4 me-3">
                <Field
                  disabled={readOnly}
                  name="billingName"
                  mr="20px"
                  label="Name"
                  ph="Enter Full Name"
                  width="260px"
                  component={CustomInput}
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-4 me-3">
                <Field
                  disabled={readOnly}
                  name="billingEmail"
                  mr="20px"
                  label="Email"
                  ph="Enter Email Address"
                  width="260px"
                  component={CustomInput}
                  required={false}
                  validator={emailValidator}
                />
              </div>
            </div>
            <div className="row mt-3 ">
              <div className="col-4 ">
                <Field
                  disabled={readOnly}
                  name="billingPhone"
                  mr="20px"
                  label="Phone Number"
                  type="number"
                  ph="Enter Phone Number"
                  width="260px"
                  component={CustomInput}
                />
              </div>
            </div>
            <div className="mt-3">
              <AddressInputComponent
                name="Billing Address"
                readOnly={readOnly}
                type="bi"
                width="260px"
              />
            </div>
          </div>
        </Tile>
      </div>
    </div>
  );
};

export default StatementBilling;
