import * as React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import Title from "./TabTitle";
import Clients from "../../pages/Clients/ClientsList";
import AddClients from "../../pages/Clients/AddViewClients";
import {
  removeClientsViewTab,
  changeClientEditPermission,
  getClientsAllInfo,
} from "../../actions/clients.action";
import { useNavigate, useLocation, useMatch } from "react-router-dom";
import LoadingScreen from "components/common/Loading";
import { getClientData } from "api";
import { Button } from "@progress/kendo-react-buttons";
import { useAllowedLiscence } from "hooks/useAllowedLiscence";
import { Tabs } from "components/Enums/TabEnums.ts";

export const getTabName = (item) => {
  if (item.clientName !== null && item.clientName !== "")
    return item.clientName;
  return item.clientRef;
};

const ClientTabs = React.memo((props) => {
  const dispatch = useDispatch();
  const [selected, setSelected] = React.useState(0);
  const [tabs, setTabs] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const { allowed } = useAllowedLiscence(Tabs.CLIENTS);
  const [editData, setEditData] = React.useState({});
  const location = useLocation();
  const navigate = useNavigate();
  const [count, setCount] = React.useState(0);

  const tabsList = useSelector((state) => state.clients);

  const tabsListCurrentOpened = useSelector((state) => state.clients.openTab);

  const handleSelect = (e) => {
    // urlDefiner(e.selected);
    setSelected(e.selected);
  };

  const clientsDataGetter = async () => {
    try {
      setIsLoading(true);
      const data = await getClientData(params?.params?.id);
      dispatch(getClientsAllInfo(data?.data?.clientRef, data?.data?.data));
      // setEditData(data?.data?.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      alert("No client exists against this Id. Try again with different Id");
      navigate("/clients");
    }
  };

  // const urlDefiner = (selected) => {
  //   let index = tabs[selected]?.clientId;
  //   if (selected !== 0) {
  //     window.history.replaceState(null, "", `/clients/${index}`);
  //   } else {
  //     navigate("/clients");
  //   }
  // };

  const params = useMatch("/clients/:id");
  useEffect(() => {
    if (params?.params?.id !== null && params?.params?.id !== undefined) {
      clientsDataGetter();
    } else {
      setSelected(0);
    }
  }, [params]);

  // useEffect(() => {
  //   if (tabsListCurrentOpened) {
  //     let item_1 = {};
  //     tabs.map((item) => {
  //       if (item.clientRef === tabsListCurrentOpened) {
  //         item_1 = item;
  //       }
  //     });
  //     setSelected(tabs.indexOf(item_1));
  //   }
  // }, [tabsListCurrentOpened]);

  useEffect(() => {
    let tabsTemp = tabsList.clientViewTabs;
    if (tabsList.clientViewTabs.length > count) {
      setTabs([...tabsTemp]);
      setCount(tabsTemp.length);
      if (location.pathname.toLowerCase() !== "/clients") {
        setSelected(tabsTemp.length - 1);
      }
    } else {
      setTabs([...tabsTemp]);
    }
  }, [tabsList]);

  // const removeTab = (tab) => {
  //   if (tab === "Clients List" && tabs.length !== 1) {
  //     return;
  //   }
  //   if (tabs.length === 1) {
  //     props.closeTab();
  //     return;
  //   }
  //   let newTabs = [];
  //   tabs.map((item) => {
  //     if (getTabName(item) !== tab) {
  //       newTabs.push(item);
  //     }
  //   });
  //   let itemCount = count - 1;
  //   setTabs([...newTabs]);
  //   dispatch(removeClientsViewTab(tab));
  //   // urlDefiner(selected - 1);
  //   setSelected(tabs.length - 2);
  //   setCount(itemCount);
  // };

  useEffect(() => {
    if (selected > tabsList.clientViewTabs.length - 1) {
      setSelected(0);
      // urlDefiner(0);
    }
  }, [selected]);
  return allowed ? (
    <TabStrip
      selected={selected}
      onSelect={handleSelect}
      keepTabsMounted={true}
      className="navigationTab"
    >
      <TabStripTab
        title={
          <Title
            content={"Clients List"}
            style={{
              fontSize: "14px",
            }}
            maxWidth={"220px"}
            onTabRemove={() => {
              props.closeTab();
            }}
            selected={"Clients List"}
            count={tabs.length}
          />
        }
      >
        <>
          <div>
            <Clients />
          </div>
          {isLoading && <LoadingScreen />}
        </>
      </TabStripTab>
    </TabStrip>
  ) : (
    <></>
  );
});

export default ClientTabs;
