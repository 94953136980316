import CustomInput from "components/common/Input";
import React, { forwardRef, useImperativeHandle, useState } from "react";
import { Field } from "@progress/kendo-react-form";

const PersonNameComponent = ({ type = "", required, readOnly }) => {
  return (
    <div className="d-flex flex-wrap">
      <Field
        disabled={readOnly}
        required={required[0]}
        name={`${type}firstName`}
        label="First Name"
        ph="e.g Jhon"
        component={CustomInput}
      />

      <Field
        disabled={readOnly}
        required={required[1]}
        name={`${type}middleName`}
        label="Middle Name"
        className="mt-3 mt-lg-0"
        ph="e.g Jhon"
        component={CustomInput}
      />

      <Field
        disabled={readOnly}
        required={required[2]}
        name={`${type}lastName`}
        label="Last Name"
        className="mt-3 mt-xl-0"
        ph="e.g Jhon"
        component={CustomInput}
      />
    </div>
  );
};

export default PersonNameComponent;
