import * as React from "react";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import styles from "../Settings.module.scss";
import { Checkbox } from "@progress/kendo-react-inputs";
import { useState } from "react";

const ActiveCell = (props) => {
    return (
        <td className={`${styles[`checkbox`]}`}>
            <Checkbox
                {...props}
                className="ms-4"
                checked={props.dataItem.allowAccess}
                onChange={(e) => {
                    props.onChange({
                        dataIndex: 0,
                        dataItem: props.dataItem,
                        field: props.field,
                        syntheticEvent: e.syntheticEvent,
                        value: e.target.value,
                    });
                }}
            />
        </td>
    );
};

export const CaseMangementGrid = (props) => {
    const [data, setData] = useState(props.data);
    React.useEffect(() => {
        if (props.data.length > 1) {
            setData(props.data);
        }
    }, [props.value, props.data]);

    return (
        <>
            <div className="w-100 mx-auto">
                <div>
                    <div className="settings-grid users-grid">
                        <Grid
                            data={data}
                            style={{ height: "50vh" }}
                            sortable={true}
                            onItemChange={(e) => {
                                props.onChange("case", e);
                            }}
                        >
                            <Column
                                field="name"
                                title="Name"
                                editable={false}
                                className="gridBoldField"
                                width="220px"
                            />
                            <Column field="details" title="Details" editable={false} />
                            <Column
                                field="allowAccess"
                                title="Allow Access"
                                cell={ActiveCell}
                                width="140px"
                            />
                        </Grid>
                    </div>
                </div>
            </div>
        </>
    );
};

export const ReportsGrid = (props) => {
    const [data, setData] = useState(props.data);
    React.useEffect(() => {
        setData(props.data);
    }, [props.value, props.data]);
    return (
        <>
            <div className="w-100 mx-auto">
                <div>
                    <div className="settings-grid users-grid">
                        <Grid
                            data={data}
                            style={{ height: "50vh" }}
                            sortable={true}
                            onItemChange={(e) => {
                                props.onChange("report", e);
                            }}
                        >
                            <Column field="name" title="Name" className="gridBoldField" width="220px" />
                            <Column field="details" title="Details" />
                            <Column
                                field="allowAccess"
                                title="Allow Access"
                                cell={ActiveCell}
                                width="140px"
                            />
                        </Grid>
                    </div>
                </div>
            </div>
        </>
    );
};

export const AccountsGrid = (props) => {
    const [data, setData] = useState(props.data);
    React.useEffect(() => {
        setData(props.data);
    }, [props.value, props.data]);
    return (
        <>
            <div className="w-100 mx-auto">
                <div>
                    <div className="settings-grid users-grid">
                        <Grid
                            data={data}
                            style={{ height: "50vh" }}
                            sortable={true}
                            onItemChange={(e) => {
                                props.onChange("account", e);
                            }}
                        >
                            <Column field="name" title="Name" className="gridBoldField" width="220px" />
                            <Column field="details" title="Details" />
                            <Column
                                field="allowAccess"
                                title="Allow Access"
                                cell={ActiveCell}
                                width="140px"
                            />
                        </Grid>
                    </div>
                </div>
            </div>
        </>
    );
};
