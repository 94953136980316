import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@progress/kendo-react-buttons";
import { Label } from "@progress/kendo-react-labels";
import { TextArea } from "@progress/kendo-react-inputs";
import { Tile } from "components/UIComponents/Tile";
import styles from "./MatterDetails.module.scss";
import { FormComboBox, FormInput } from "components/Matters/index";
import { getfeeearner } from "actions/postings.action";
import { getBranchesnames } from "actions/branchnames.action";
import { CONSTANT } from "CONSTANT/Matter/MatterForm";
import { filterBy } from "@progress/kendo-data-query";
import {
  prepareApplicationData,
  prepareFeeearnerData,
} from "components/common/ComboRepresentationFunctions";
import {
  addMatter,
  getActiveApplications,
  getCategoryList,
  getDepartmentList,
  getMatterRefExample,
  getWorkType,
  updateOnboarding,
} from "api/index";

const MatterDetails = ({ moveNext, formData }) => {
  const { branchesList } = useSelector((state) => state.branchesnames);
  const { feeearner } = useSelector((state) => state.postingsdocs);
  const { userlist: user } = useSelector((state) => state.currentuser);

  const [details, setDetails] = useState(formData.matterDetails?.details || "");
  const dispatch = useDispatch();
  const [departmentData, setDepartmentData] = useState([]);
  const [departmentFilter, setDepartmentfilter] = useState([]);
  const [categoryData, setCaegoryData] = useState([]);
  const [categoryFilter, setCaegoryFilter] = useState([]);
  const [superVisor, setSuperVisor] = useState(feeearner.data);
  const [application, setApplication] = useState([]);
  const [applicationFilter, setApplicationFilter] = useState([]);
  const [validationState, setValidationState] = useState(false);

  const [inputState, setInputState] = useState({
    [CONSTANT.INPUT.MATTER_REF]: formData.matterDetails?.matterRef || "",
  });

  const [comboBoxState, setComboBoxState] = useState({
    [CONSTANT.COMBOBOX.SUPERVISIOR]: formData.matterDetails?.Supervisior || "",
    [CONSTANT.COMBOBOX.FEE_EARNER]:
      formData.matterDetails?.Fee_Earner ||
      (user?.isFeeEarner
        ? {
            userRef: user?.userRef,
            representation: `${user?.userRef} - ${user.displayname} `,
          }
        : null),
    [CONSTANT.COMBOBOX.APPLICATION]: formData.matterDetails?.Application || "",
    [CONSTANT.COMBOBOX.DEPARTMENT]: formData.matterDetails?.Department || "",
    [CONSTANT.COMBOBOX.CATEGORY]: formData.matterDetails?.Work_Type || "",
    [CONSTANT.COMBOBOX.BRANCH]:
      formData.matterDetails?.Branch ||
      branchesList.find(
        (branch) =>
          branch.branchId ===
            formData.clientData?.individual?.branch?.branchId ||
          branch.branchId === formData.clientData?.company?.branch?.branchId
      ),
    [CONSTANT.COMBOBOX.RATE_TYPE]: formData.matterDetails?.Rate_Type || "",
  });

  const [feeEarner, setFeeEarner] = useState(feeearner.data);

  useEffect(() => {
    dispatch(getfeeearner());
    dispatch(getBranchesnames());
    getDepartmentData();
    getCategoryData();
    getApplication();
    fectchMatterRef(formData.clientData?.clientRef);
  }, []);

  const handleComboBoxChange = (e) => {
    setComboBoxState({
      ...comboBoxState,
      [e.target.name]: e.target.value,
    });
  };

  const handleInputChange = (value, label) => {
    setInputState({
      ...inputState,
      [label]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let hasError = false;

    if (
      !inputState[CONSTANT.INPUT.MATTER_REF] ||
      !details ||
      !comboBoxState[CONSTANT.COMBOBOX.DEPARTMENT] ||
      !comboBoxState[CONSTANT.COMBOBOX.CATEGORY]
    ) {
      hasError = true;
    }

    setValidationState(hasError);

    if (!hasError) {
      const dataObject = {
        matterRef: inputState[CONSTANT.INPUT.MATTER_REF] || null,
        clientId: formData.clientData?.clientId,
        details: details,
        feeEarnerRef:
          comboBoxState[CONSTANT.COMBOBOX.FEE_EARNER].userRef || null,
        supervisorRef:
          comboBoxState[CONSTANT.COMBOBOX.SUPERVISIOR]?.userRef || null,
        appCode: comboBoxState[CONSTANT.COMBOBOX.APPLICATION].appCode || null,
        branchId: comboBoxState[CONSTANT.COMBOBOX.BRANCH]?.branchId || null,
        application:
          comboBoxState[CONSTANT.COMBOBOX.APPLICATION].appName || null,
        departmentId:
          comboBoxState[CONSTANT.COMBOBOX.DEPARTMENT]?.departmentID || -1,
        workTypeId: comboBoxState[CONSTANT.COMBOBOX.CATEGORY]?.workTypeID || -1,
        rateType: comboBoxState[CONSTANT.COMBOBOX.RATE_TYPE]?.rateType || null,
        status: "Onboarding",
      };

      try {
        const res = await addMatter({ data: dataObject });
        if (res?.status === 200) {
          console.log("Matter Added Successfully");
          moveNext(
            {},
            {
              matterDetails: {
                ...comboBoxState,
                matterId: res.data.data?.matterId,
                details: details,
                matterRef: inputState[CONSTANT.INPUT.MATTER_REF],
                appCode: comboBoxState[CONSTANT.COMBOBOX.APPLICATION].appCode,
                client: {
                  clientId: formData.clientData?.clientId,
                  clientRef: formData.clientData?.clientRef,
                  clientName: formData.clientData?.clientName,
                },
              },
            }
          );
          updateOnboarding(
            { matterId: res.data.data?.matterId },
            formData.onboardingId
          );
        }
      } catch (err) {
        if (
          err?.response?.data !== "" &&
          err?.response?.data?.errorMessage?.includes(
            "The duplicate key value is"
          )
        ) {
          console.log("Matter Ref Already Added");
        } else {
          console.log("Error Occured");
        }
      }
    }
  };

  const filterFeeEarner = (filter) => {
    const data = feeearner.data.slice();
    return filterBy(data, filter);
  };
  const feeEarnerfilterChange = (event) => {
    setFeeEarner(filterFeeEarner(event.filter));
  };

  const filterSuperVisor = (filter) => {
    const data = feeearner.data.slice();
    return filterBy(data, filter);
  };
  const superVisorfilterChange = (event) => {
    setSuperVisor(filterSuperVisor(event.filter));
  };

  const filterrApplication = (filter) => {
    const data = application.slice();
    return filterBy(data, filter);
  };
  const applicationfilterChange = (event) => {
    setApplicationFilter(filterrApplication(event.filter));
  };

  const filterDepartment = (filter) => {
    const data = departmentData.slice();
    return filterBy(data, filter);
  };
  const departmentfilterChange = (event) => {
    setDepartmentfilter(filterDepartment(event.filter));
  };

  const filterCaegory = (filter) => {
    const data = categoryData.slice();
    return filterBy(data, filter);
  };
  const cateforyFilterChange = (event) => {
    setCaegoryFilter(filterCaegory(event.filter));
  };

  const getDepartmentData = async () => {
    const { data } = await getDepartmentList();
    setDepartmentData(data.data.slice());
    setDepartmentfilter(data.data.slice());
  };

  const getCategoryData = async () => {
    const { data } = await getCategoryList();
    setCaegoryData(data.data.slice());
    setCaegoryFilter(data.data.slice());
  };

  const getApplication = async () => {
    const datas = await getActiveApplications();
    const preparedData = prepareApplicationData(datas?.data?.data);
    setApplication(preparedData);
    setApplicationFilter(preparedData);
  };

  const fectchMatterRef = async (value) => {
    if (value !== null) {
      try {
        const response = await getMatterRefExample(
          formData.clientData?.clientRef
        );
        setInputState({
          ...inputState,
          [CONSTANT.INPUT.MATTER_REF]: response.data,
        });
      } catch (error) {}
    }
  };

  useEffect(() => {
    if (comboBoxState[CONSTANT.COMBOBOX.CATEGORY]?.workTypeID) {
      getWorkTypeData();
    }
  }, [comboBoxState[CONSTANT.COMBOBOX.CATEGORY]?.workTypeID]);

  const getWorkTypeData = async () => {
    const { data } = await getWorkType(
      comboBoxState[CONSTANT.COMBOBOX.CATEGORY]?.workTypeID
    );
    const response = data.data;

    const appItem = application.find(
      (item) => item.appCode === response.appCode
    );
    if (!formData.matterDetails?.Application) {
      if (!appItem || !response.appCode) {
        setComboBoxState((prevState) => ({
          ...prevState,
          [CONSTANT.COMBOBOX.APPLICATION]: "",
        }));
        return;
      }
      setComboBoxState((prevState) => ({
        ...prevState,
        [CONSTANT.COMBOBOX.APPLICATION]: {
          appCode: response?.appCode,
          appName: appItem?.appName,
          representation: response.appCode + "-" + appItem?.appName,
        },
      }));
    }
  };

  return (
    <Tile heading="Matter Details" className="overflow-auto px-4 h-100">
      <div style={{ height: "calc(100% - 70px)" }}>
        <form onSubmit={handleSubmit} className="h-100" noValidate>
          <div
            className="overflow-auto"
            style={{ height: "calc(100% - 55px)" }}
          >
            <div className={`${styles.container}`}>
              <div className="row mt-3 ">
                <div className="col-xl-10 col-12 me-2">
                  <Label className={`label mb-1`}>Matter Details</Label>
                  <TextArea
                    placeholder="Enter Matter Details"
                    autoSize={true}
                    value={details}
                    className={`${
                      validationState && !details ? styles.error : ""
                    }`}
                    style={{
                      height: "62px",
                      minWidth: "100%",
                    }}
                    onChange={(e) => setDetails(e.value)}
                  />
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-4">
                  <FormComboBox
                    wf={true}
                    isStyled={true}
                    width={"260px"}
                    onChangeComboBox={handleComboBoxChange}
                    onFilterChange={feeEarnerfilterChange}
                    data={prepareFeeearnerData(feeEarner)}
                    textField="representation"
                    state={comboBoxState}
                    name={CONSTANT.COMBOBOX.FEE_EARNER}
                    val={comboBoxState[CONSTANT.COMBOBOX.FEE_EARNER]}
                  />
                </div>
                <div className="col-1"></div>
                <div className="col-6">
                  <FormComboBox
                    wf={true}
                    isStyled={true}
                    width={"260px"}
                    onChangeComboBox={handleComboBoxChange}
                    textField="name"
                    onFilterChange={departmentfilterChange}
                    data={departmentFilter}
                    state={comboBoxState}
                    name={CONSTANT.COMBOBOX.DEPARTMENT}
                    val={comboBoxState[CONSTANT.COMBOBOX.DEPARTMENT]}
                    inputClassName={` ${
                      validationState &&
                      !comboBoxState[CONSTANT.COMBOBOX.DEPARTMENT]
                        ? styles.error
                        : ""
                    }`}
                  />
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-4">
                  <FormComboBox
                    wf={true}
                    isStyled={true}
                    width={"260px"}
                    onChangeComboBox={handleComboBoxChange}
                    onFilterChange={superVisorfilterChange}
                    data={superVisor}
                    textField="displayname"
                    state={comboBoxState}
                    name={CONSTANT.COMBOBOX.SUPERVISIOR}
                    val={comboBoxState[CONSTANT.COMBOBOX.SUPERVISIOR]}
                  />
                </div>
                <div className="col-1"></div>
                <div className="col-6">
                  <div className="d-flex align-items-center gap-2">
                    <FormComboBox
                      wf={true}
                      isStyled={true}
                      width={"260px"}
                      onChangeComboBox={handleComboBoxChange}
                      onFilterChange={cateforyFilterChange}
                      data={categoryFilter.filter(
                        (item) =>
                          item?.departmentId ===
                          comboBoxState[CONSTANT.COMBOBOX.DEPARTMENT]
                            ?.departmentID
                      )}
                      state={comboBoxState}
                      textField="name"
                      name={CONSTANT.COMBOBOX.CATEGORY}
                      inputClassName={`${
                        validationState &&
                        !comboBoxState[CONSTANT.COMBOBOX.CATEGORY]
                          ? styles.error
                          : ""
                      }`}
                    />
                  </div>
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-4">
                  <div className="d-flex flex-column col-5">
                    <FormInput
                      labelWidth
                      wf={true}
                      labelForComboBox={"Matter Ref"}
                      isStyled={true}
                      width={"260px"}
                      state={inputState}
                      label={CONSTANT.INPUT.MATTER_REF}
                      onChangeInput={handleInputChange}
                      inputClassName={` ${
                        validationState &&
                        !inputState[CONSTANT.INPUT.MATTER_REF]
                          ? styles.error
                          : ""
                      }`}
                    />
                  </div>
                </div>
                <div className="col-1"></div>
                <div className="col-6">
                  <FormComboBox
                    onChangeComboBox={handleComboBoxChange}
                    wf={true}
                    isStyled={true}
                    width={"260px"}
                    onFilterChange={applicationfilterChange}
                    textField="representation"
                    data={applicationFilter}
                    state={comboBoxState}
                    name={CONSTANT.COMBOBOX.APPLICATION}
                    val={comboBoxState[CONSTANT.COMBOBOX.APPLICATION]}
                  />
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-4">
                  <FormComboBox
                    isStyled={true}
                    wf={true}
                    width={"260px"}
                    placeholder={"Rate Type"}
                    iconClassName="wa"
                    data={[
                      {
                        value: "Fee Earner Default",
                        rateType: "FeeEarnerDefault",
                      },
                      {
                        value: "Matter Flat Rate",
                        rateType: "MatterFlatRate",
                      },
                      {
                        value: "Rate Category",
                        rateType: "RateCategory",
                      },
                    ]}
                    textField="value"
                    state={comboBoxState}
                    name={CONSTANT.COMBOBOX.RATE_TYPE}
                    onChangeComboBox={handleComboBoxChange}
                    val={comboBoxState[CONSTANT.COMBOBOX.RATE_TYPE]}
                  />
                </div>
                <div className="col-1"></div>
                <div className="col-6 d-flex align-items-center gap-2">
                  <FormComboBox
                    isStyled={true}
                    wf={true}
                    data={branchesList}
                    textField="branchName"
                    width={"260px"}
                    boxShadow={true}
                    state={comboBoxState}
                    name={CONSTANT.COMBOBOX.BRANCH}
                    onChangeComboBox={handleComboBoxChange}
                  />
                </div>
              </div>
            </div>
          </div>

          <div
            className={`${styles.container} me-3 `}
            style={{ height: "55px" }}
          >
            <hr className="m-0" />
            <Button
              className="my-2 common-btn"
              style={{ float: "right" }}
              type="submit"
            >
              Next
            </Button>
          </div>
        </form>
      </div>
    </Tile>
  );
};

export default MatterDetails;
