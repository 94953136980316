import * as React from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import { useDispatch, useSelector } from "react-redux";
import { Checkbox, NumericTextBox } from "@progress/kendo-react-inputs";
import { Label } from "@progress/kendo-react-labels";
import { useState } from "react";
import { TextArea } from "@progress/kendo-react-inputs";
import { useEffect } from "react";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import Icon from "components/Global/icon";
import styles from "./index.module.scss";
import SectionDivider from "components/Contact/sectionDivider";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import AddClients from "pages/Clients/AddViewClients";
import { Button } from "@progress/kendo-react-buttons";
import { IoIosAddCircle } from "react-icons/io";
import { getfeeearner } from "actions/postings.action";
import { PageTabItemTitle } from "components/tabs/TabItem/PageTabItemTitle";
import { getBranchesnames } from "actions/branchnames.action";
import { getContactLinks } from "api/settingApi";
import {
    addClientDocumentResgisters,
    addMatter,
    addMatterDocumentResgisters,
    addProof,
    getActiveApplications,
    getCategoryList,
    getClientsNameAndRef,
    getContactsListAll,
    getCountries,
    getDepartmentList,
    getDocIntelligenceData,
    getDocumentRegisters,
    getFileById,
    getMatterAllInfo,
    getMatterRefExample,
    updateDocumentResgister,
    updateMatter,
    updateProof,
} from "api/index";
import {
    getDDABankNominals,
    getIncomeNominals,
} from "actions/nominals.action";
import {
    FormComboBox,
    TabTitle,
    FormInput,
    FormDate,
} from "components/Matters/index";
import { toast } from "react-toastify";
import { Badge, Loader } from "@progress/kendo-react-indicators";
import { Tile } from "components/UIComponents/Tile";
import {
    VerificationDocumentType,
    VerificationProofType,
    getDocType,
} from "components/Enums/IDCheck.ts";
import { DocumentsUpload } from "pages/Postings/DcoumentsUpload";
import { useUploadForm } from "components/fileUploadHook";
import { onFileUpload } from "Services/fileDataService";
import { FileDocumentTypes } from "components/Enums/FileDocumentTypes.ts";
import DocumentViewer from "components/common/DocumentViewer";
import { RiUpload2Fill } from "react-icons/ri";
import DoccIntelligenceModal from "./DocIntelligenceLoaderModal";
const personProofTypes = [
    {
        label: "Proof of ID",
        value: VerificationProofType.ProofOfID,
    },
    {
        label: "Address  Proof",
        value: VerificationProofType.AddressProof,
    },
    {
        label: "Other",
        value: VerificationProofType.Other,
    },
];
const companyProofTypes = [
    {
        label: "Company Proof 1",
        value: VerificationProofType.CompanyProof1,
    },
    {
        label: "Company Proof 2",
        value: VerificationProofType.CompanyProof2,
    },
    {
        label: "Other",
        value: VerificationProofType.Other,
    },
];

const documentTypes = [
    {
        label: "Passport",
        value: VerificationDocumentType.Passport,
    },
    {
        label: "Driving License",
        value: VerificationDocumentType.DrivingLicense,
    },
    {
        label: "Utility Bill",
        value: VerificationDocumentType.UtilityBill,
    },
    {
        label: "Council Tax",
        value: VerificationDocumentType.CouncilTax,
    },
    {
        label: "Residence Permit",
        value: VerificationDocumentType.ResidencePermit,
    },
    {
        label: "National ID Card",
        value: VerificationDocumentType.NationalIdentityCard,
    },
    {
        label: "Other",
        value: VerificationDocumentType.Other,
    },
];

const dateDefiner = (date) => (date ? new Date(date) : null);

const AddEditProof = ({
    props = {},
    type,
    proof = {},
    addNew,
    cancelEdit,
    clientId,
    updateSuccess,
    companyPersonId,
    docIntelligentData,
}) => {
    const [isView, setIsView] = useState(false);
    let locale = useSelector((state) => state.company.company.locale);
    const [isLoading, setIsLoading] = useState(props.docId ? true : false);
    const [details, setDetails] = useState(proof?.details || "");
    const [address, setAddress] = useState(proof?.address || "");
    const filePickerRef = React.useRef();
    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const [showDocViewer, setShowDocViewer] = useState(false);
    const [countryData, setCountryData] = useState([]);
    const [inputState, setInputState] = useState({
        Issued_By: proof?.issuedBy,
        Document_Number: proof?.documentNumber,
    });

    const [comboBoxState, setComboBoxState] = useState({
        proofType: addNew
            ? personProofTypes.filter((proofItem) => proofItem.value === "Other")[0]
            : type === "Person"
                ? personProofTypes.filter(
                    (proofItem) => proofItem.value === proof?.proofType
                )[0]
                : companyProofTypes.filter(
                    (proofItem) => proofItem.value === proof?.proofType
                )[0],
        documentType: documentTypes.filter(
            (doc) => doc.value === proof?.documentType
        )[0],
        country: {
            countryCode: proof?.countryCode,
            countryName: countryData.filter(
                (country) => country.countryCode === proof?.countryCode
            )[0]?.countryName,
        },
    });

    const [dateState, setDateState] = useState({
        Issue_Date: proof?.issuedDate ? new Date(proof?.issuedDate) : null,
        Expiry_Date: proof?.expiryDate ? new Date(proof?.expiryDate) : null,
    });
    const { isSuccess, uploadForm, progress } = useUploadForm();

    const [filesData, setFilesData] = React.useState([]);

    const handleUpload = async (e) => {
        try {
            setIsLoading(true);
            if (e.target?.files?.length === 0) return;
            let data = onFileUpload(e);
            setFilesData([...filesData, data]);
            const dataChoosen = await uploadForm(data, e.target.files[0], {
                documentType: FileDocumentTypes.ClientDocs,
                clientId: clientId,
            });
            data.fileID = dataChoosen?.data?.data?.fileID;
            data.downloadURL = dataChoosen?.data?.data?.downloadURL;
            // if (!proof?.documentType) {
            const dataRecieved = await getDocIntelligenceData(data.fileID);
            const extreactedData = dataRecieved?.data?.data;
            setComboBoxState({
                ...comboBoxState,
                documentType: documentTypes.filter(
                    (doc) => doc.value === getDocType(extreactedData?.documentType)
                )[0],
                country: {
                    countryCode: extreactedData?.fields?.["CountryRegion"] || "GBR",
                    countryName:
                        countryData.filter(
                            (country) =>
                                country.countryCode ===
                                extreactedData?.fields?.["CountryRegion"]
                        )[0]?.countryName || "United Kingdom",
                },
            });
            setDateState({
                Issue_Date: dateDefiner(extreactedData?.fields?.["DateOfIssue"]),
                Expiry_Date: dateDefiner(extreactedData?.fields?.["DateOfExpiration"]),
            });

            setInputState({
                Document_Number: extreactedData?.fields?.["DocumentNumber"],
                Issued_By: extreactedData?.fields?.["IssuingAuthority"],
            });

            setAddress(
                extreactedData?.fields?.Address?.streetAddress +
                extreactedData?.fields?.Address?.city +
                extreactedData?.fields?.Address?.countryRegion
            );
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            toast.error("Unexpected Error Occured");
        }
        // }
    };

    const onFileRemove = (fileId) => {
        setFilesData((prev) => prev.filter((file) => file.fileID !== fileId));
    };
    const getCountriesList = async () => {
        try {
            const data = await getCountries();
            setCountryData(data.data?.data);
            setComboBoxState({
                ...comboBoxState,
                country: {
                    countryCode: proof?.countryCode,
                    countryName: data?.data?.data?.filter(
                        (country) => country.countryCode === proof?.countryCode
                    )[0]?.countryName,
                },
            });
        } catch (error) { }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const data = {
                ...proof,
                documentType: comboBoxState["documentType"].value,
                details,
                proofTypeName: "proof",
                countryCode: comboBoxState["country"].countryCode,
                proofType: comboBoxState["proofType"].value,
                issuedDate: dateState["Issue_Date"],
                expiryDate: dateState["Expiry_Date"],
                documentNumber: inputState["Document_Number"],
                issuedBy: inputState["Issued_By"],
                documentFile: filesData[0]?.fileID,
            };
            if (addNew && companyPersonId) {
                data.companyPersonId = companyPersonId;
            }
            if (docIntelligentData) {
                data.documentFile = proof?.documentFile;
            }
            if (addNew || !proof?.verificationProofId) {
                await addProof({ data: data }, clientId);
                updateSuccess();
                toast.success("Proof Added");
            } else {
                await updateProof({ data: data }, clientId, proof?.verificationProofId);
                updateSuccess();
                toast.success("Proof Updated");
            }
        } catch (error) {
            toast.error("Unexpected Error Occured");
        }
    };

    const renderPdfView = async (fileID) => {
        if (!fileID) return toast("File id is rquired");
        try {
            const { data } = await getFileById(fileID);
            setData([data.data]);
            setShowDocViewer(true);
        } catch (error) { }
    };

    const handleInputChange = (value, label) => {
        setInputState({
            ...inputState,
            [label]: value,
        });
    };

    const handleComboBoxChange = (e, label) => {
        setComboBoxState({
            ...comboBoxState,
            [label]: e.value,
        });
    };

    const handleDateChange = (e, label) => {
        setDateState({
            ...dateState,
            [label]: e.value,
        });
    };

    React.useEffect(() => {
        dispatch(getfeeearner());
        getCountriesList();
    }, []);

    return (
        <div>
            <Dialog
                className={styles["matter-form-dialog"]}
                title={addNew ? "Add Proof" : "Edit Proof"}
                onClose={cancelEdit}
            >
                <div
                    className="container pb-3 pt-3 px-5 mt-3"
                    style={{
                        width: "1050px",
                        height:
                            comboBoxState["proofType"]?.value ===
                                VerificationProofType.AddressProof
                                ? "63vh"
                                : "55vh",
                    }}
                >
                    <form id="matterForm" onSubmit={handleSubmit}>
                        <Tile
                            heading="Proof Details"
                            className="mb-1 ps-4 pb-4 pe-4 overflow-auto"
                        // height={"calc(100vh - 280px)"}
                        >
                            <div
                                className="container pb-2"
                                style={{
                                    width: "900px",
                                    display: isView && "none",
                                }}
                            >
                                <div className="row g-2 mt-3">
                                    <div className="col">
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-3 me-3">
                                                    <FormComboBox
                                                        wf={true}
                                                        isStyled={true}
                                                        disabled={true}
                                                        width={"260px"}
                                                        onChangeComboBox={handleComboBoxChange}
                                                        fiterable={false}
                                                        data={personProofTypes}
                                                        state={comboBoxState}
                                                        boxShadow={true}
                                                        label={"Proof"}
                                                        textField={"label"}
                                                        name={"proofType"}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mt-3">
                                                <div className="col-4">
                                                    <FormComboBox
                                                        wf={true}
                                                        isStyled={true}
                                                        width={"260px"}
                                                        onChangeComboBox={handleComboBoxChange}
                                                        fiterable={false}
                                                        required
                                                        data={documentTypes}
                                                        textField={"label"}
                                                        state={comboBoxState}
                                                        label={"Document Type"}
                                                        name={"documentType"}
                                                    />
                                                </div>
                                                <div className="col-3"></div>
                                                {(filesData[0]?.downloadURL || proof?.downloadURL) && (
                                                    <div
                                                        className="col-5 d-flex align-items-center justify-content-end"
                                                        style={{
                                                            marginTop: "-70px",
                                                        }}
                                                    >
                                                        <img
                                                            src={
                                                                filesData[0]?.downloadURL || proof?.downloadURL
                                                            }
                                                            onClick={() => {
                                                                if (filesData[0]?.downloadURL) {
                                                                    renderPdfView(filesData[0]?.fileID);
                                                                } else {
                                                                    renderPdfView(proof?.documentFile);
                                                                }
                                                            }}
                                                            className="cursor-pointer"
                                                            style={{
                                                                height: "120px",
                                                                border: "none",
                                                                width: "200px",
                                                            }}
                                                        ></img>
                                                    </div>
                                                )}
                                            </div>
                                            <div className="row mt-3">
                                                <div className="col-4 me-2">
                                                    <FormInput
                                                        className={"form-date-picker note-date-picker "}
                                                        wf={true}
                                                        // required
                                                        isStyled={true}
                                                        state={inputState}
                                                        width={"260px"}
                                                        label={"Document_Number"}
                                                        onChangeInput={handleInputChange}
                                                    />
                                                </div>
                                                <div className="d-flex flex-column col-4 me-5">
                                                    <FormComboBox
                                                        wf={true}
                                                        isStyled={true}
                                                        width={"260px"}
                                                        onChangeComboBox={handleComboBoxChange}
                                                        fiterable={false}
                                                        data={countryData}
                                                        // required
                                                        state={comboBoxState}
                                                        textField={"countryName"}
                                                        name={"country"}
                                                        label={"Country"}
                                                    />
                                                </div>
                                                <div className="col-3 ms-3">
                                                    {/* {!proof?.documentId && !proof?.documentFile && ( */}
                                                    <div className="d-flex justify-content-end">
                                                        <div
                                                            onClick={(e) => filePickerRef?.current?.click()}
                                                            className={`d-flex align-items-center cursor-pointer`}
                                                        // ref={anchor}
                                                        >
                                                            <div className="mt-3">
                                                                <p className={styles["doc-upload-text"]}>
                                                                    {filesData[0]?.downloadURL ||
                                                                        proof?.downloadURL
                                                                        ? "Update"
                                                                        : "Attach"}
                                                                    <br /> Documents
                                                                </p>
                                                            </div>
                                                            <div className={styles["vertical-rule"]}></div>
                                                            <div
                                                                className={`${styles["file-upload-icon"]} d-flex align-items-center justify-content-center position-relative`}
                                                            >
                                                                <RiUpload2Fill color="#DCDAFF" size={20} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* )} */}
                                                </div>
                                            </div>
                                            <div className="row mt-3">
                                                <div className="col-4 me-2">
                                                    <FormDate
                                                        className={"form-date-picker note-date-picker "}
                                                        wf={true}
                                                        isStyled={true}
                                                        width={"260px"}
                                                        label={"Issue_Date"}
                                                        onChangeDate={handleDateChange}
                                                        state={dateState}
                                                    />
                                                </div>
                                                <div className="col-4 me-2">
                                                    <FormDate
                                                        className={"form-date-picker note-date-picker "}
                                                        wf={true}
                                                        isStyled={true}
                                                        width={"260px"}
                                                        label={"Expiry_Date"}
                                                        onChangeDate={handleDateChange}
                                                        state={dateState}
                                                    />
                                                </div>
                                                <div className="col-3 me-3">
                                                    <FormInput
                                                        className={"form-date-picker note-date-picker "}
                                                        wf={true}
                                                        isStyled={true}
                                                        width={"253px"}
                                                        label={"Issued_By"}
                                                        onChangeInput={handleInputChange}
                                                        state={inputState}
                                                    />
                                                </div>
                                            </div>
                                            {comboBoxState["proofType"]?.value ===
                                                VerificationProofType.AddressProof && (
                                                    <div className="row mt-3">
                                                        <div className="d-flex flex-column col-12">
                                                            <Label className={`label mb-1`}>Address</Label>
                                                            <TextArea
                                                                // required
                                                                placeholder="Enter Details"
                                                                autoSize={true}
                                                                value={address}
                                                                style={{
                                                                    height: "32px",
                                                                    minWidth: "100%",
                                                                }}
                                                                onChange={(e) => setAddress(e.value)}
                                                            />
                                                        </div>
                                                    </div>
                                                )}

                                            <div className="row mt-3">
                                                <div className="d-flex flex-column col-12">
                                                    <Label className={`label mb-1`}>Notes</Label>
                                                    <TextArea
                                                        // required
                                                        placeholder="Enter Details"
                                                        autoSize={true}
                                                        value={details}
                                                        style={{
                                                            height: "32px",
                                                            minWidth: "100%",
                                                        }}
                                                        onChange={(e) => setDetails(e.value)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Tile>
                        <button
                            id="doc-form"
                            style={{
                                visibility: "hidden",
                            }}
                            type="submit"
                        ></button>
                        <input
                            name="file"
                            type="file"
                            onChange={handleUpload}
                            ref={filePickerRef}
                            style={{
                                visibility: "hidden",
                            }}
                        />
                    </form>
                </div>

                {showDocViewer && (
                    <DocumentViewer
                        upload={true}
                        data={data}
                        onClose={() => setShowDocViewer(false)}
                    />
                )}

                {isLoading && <DoccIntelligenceModal />}

                <div className="dialog-footer">
                    <Button
                        className="common-btn bgBtnSecondary border-0 me-3"
                        onClick={cancelEdit}
                    >
                        Cancel
                    </Button>
                    <Button
                        className="common-btn me-4"
                        type="button"
                        form="matterForm"
                        onClick={(e) => {
                            document.getElementById("doc-form").click();
                        }}
                    >
                        {isView ? "Edit" : "Save"}
                    </Button>
                </div>
            </Dialog>
        </div>
    );
};

export default AddEditProof;
