import { useEffect, useRef, useCallback } from 'react';

const useWindowEventHandlers = () => {
  const handlersRef = useRef({});

  const registerHandler = useCallback((eventName, handler) => {
    // Register the handler on the window immediately
    window[eventName] = handler;

    // Also store it in the ref to keep track of registered handlers
    handlersRef.current[eventName] = handler;
  }, []);

  useEffect(() => {
    // Cleanup when the component unmounts
    return () => {
      Object.keys(handlersRef.current).forEach((eventName) => {
        delete window[eventName]; // Remove handlers from the window object
      });
    };
  }, []);

  return { registerHandler };
};

export default useWindowEventHandlers;
