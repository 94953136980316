import * as React from "react";
//import styles from "components/Dashboard/index.module.scss";
import styles from "./DashboardPage.module.scss";
import { useState } from "react";
import { DashboardMatterItem } from "components/Dashboard";
import { useEffect } from "react";
import { getClientData, getMatterAllInfo, getMyMattersList } from "api";
import { getMattersAllURLInfo } from "actions/matters.action";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import MatterForm from "pages/Matters/MatterForm";
import AddClients from "pages/Clients/AddViewClients";

const MyMattersGrid = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [myMatters, setMyMatters] = useState([]);
    const [reference, setReference] = useState();
    const [showMatterForm, setshowMatterForm] = useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [editData, setEditData] = useState({});
    const [showClientView, setshowClientView] = React.useState(false);
    const [clientReference, setClientReference] = useState();

    const clientsDataGetter = async (id) => {
        try {
            setIsLoading(true);
            const data = await getClientData(id);
            setEditData(data?.data?.data);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            alert("No client exists against this Id. Try again with different Id");
            navigate("/clients");
        }
    };

    const mattersDataGetter = async (id) => {
        try {
            const datas = await getMatterAllInfo(id);
            dispatch(getMattersAllURLInfo(datas, false));
        } catch (error) {
            alert("No matter exists against this Id. Try again with different Id");
            navigate("/matters");
        }
    };

    const onClientRefClickActions = (matter) => {

        setClientReference(matter?.clientRef);
        clientsDataGetter(matter?.clientId);
        setshowClientView(true);
    };

    const onMatterRefClickActions = (matter) => {
        // navigate(`/matters/${props.dataItem.matterId}`);
        setReference(matter?.matterRef || matter.matterRef);
        mattersDataGetter(matter?.matterId || matter.matterId);
        setshowMatterForm(true);
    };

    const getMyMatters = async () => {
        try {
            const data = await getMyMattersList(1, 10, "lastActionDate desc", {});
            setMyMatters(data?.data?.data);
        } catch (error) { }
    };

    useEffect(() => {
        getMyMatters();
    }, []);

    return (
        <div className="h-100 overflow-auto w-100 ">
            <div className={`${styles["combo-label-font"]} p-3 ps-4 pb-3`}>
                Recent Matters
            </div>

            <div className="m-2">
                {myMatters.map((item, index) => (
                    <DashboardMatterItem
                        key={index}
                        item={item}
                        onMatterRefClickActions={onMatterRefClickActions}
                        onClientRefClickActions={onClientRefClickActions}
                    />
                ))}
            </div>

            {showMatterForm && (
                <MatterForm
                    isView={reference !== undefined}
                    reference={reference}
                    onMatterRefClickActions={onMatterRefClickActions}
                    setshowMatterForm={() => {
                        setshowMatterForm(false);
                        setReference(undefined);
                    }}
                />
            )}

            {showClientView && !isLoading && (
                <AddClients
                    data={editData}
                    formId={"view"}
                    onClose={() => {
                        setshowClientView(false);
                    }}
                    open={() => {
                        setshowClientView(true);
                    }}
                    reference={reference}
                    type={"VIEW"}
                />
            )}
        </div>
    );
};
export default MyMattersGrid;
