import * as React from "react";
import Branch from "components/common/Branch";
import { Form, FormElement } from "@progress/kendo-react-form";
import styles from "./index.module.scss";
import Nominals from "./Nominal";
import { useState } from "react";
import { Button } from "@progress/kendo-react-buttons";
import { handleApiError } from "Helper";
import { useEffect } from "react";
import {
    getBranchesnames,
    getDefaultNominals,
    getNewDefaultNominals,
    getactivenominals,
    updateDefaultNominals,
} from "api";
import { toast } from "react-toastify";
import LoadingScreen from "components/common/Loading";

const DefaultNominals = (props) => {
    const [branch, setBranch] = useState(null);
    const [nominalList, setNominalList] = useState([]);
    const [nominalValues, setNominalValues] = useState({});
    const [initialSet, setInitialSet] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const prepareNominalData = (data) =>
        data?.map((item) => {
            item.representation = item.nominalRef + "  -  " + item.nominalName;
            return item;
        });
    const fetchNominals = async () => {
        try {
            const { data } = await getactivenominals();
            const formattedData = prepareNominalData(data?.data);
            setNominalList(formattedData);
            const branchesData = await getBranchesnames();
            const result = branchesData.data.data.map((branch) => {
                branch.representation = `${branch.branchCode} - ${branch.branchName}`;
                return branch;
            });
            onChangeBranch(result[0], formattedData);
        } catch (error) {
            handleApiError(error);
        }
    };

    const onChangeValue = (name, e) => {
        const prevValues = { ...nominalValues };
        prevValues[name] = e;
        setNominalValues({
            ...prevValues,
        });
    };

    const onChangeBranch = async (e, formattedData) => {
        let nominals = formattedData?.length ? formattedData : nominalList;
        if (e === null) {
            setNominalValues({});
            return;
        }
        try {
            setIsLoading(true);
            const res = await getDefaultNominals(e.branchId);
            setBranch(e);
            const branchNominals = {};
            res?.data?.data?.map((item) => {
                branchNominals[item.arrayNo] = nominals.filter(
                    (nominal) => nominal.nominalRef === item.nominalRef
                )[0];
            });
            setNominalValues(branchNominals);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    };

    const getNewDefaultBranch = async (branchId) => {
        try {
            const res = await getNewDefaultNominals(branchId);
            const branchNominals = {};
            res?.data?.data?.map((item) => {
                branchNominals[item.arrayNo] = {
                    representation: item.nominalRef,
                    nominalRef: item.nominalRef,
                };
            });
            setNominalValues(branchNominals);
        } catch (error) { }
    };
    //   useEffect(() => {
    //     if (branch?.length && nominalList?.length && !initialSet) {
    //       console.log(branch, nominalList);
    //       onChangeBranch(branch[0]);
    //       setInitialSet(true);
    //     }
    //   }, [branch, nominalList]);

    const onSubmitChanges = async () => {
        try {
            const preparedData = Object.keys(nominalValues).map((nominalValue) => {
                let item = {};
                item["arrayNo"] = nominalValue;
                item["nominalRef"] = nominalValues[nominalValue].nominalRef;
                return item;
            });
            const data = { data: preparedData };
            const res = await updateDefaultNominals(branch.branchId, data);
            toast.success(`Default Nominals for ' ${branch.branchName} ' updated`);
        }
        catch (error) {
            toast.error("Error occured while updating data");
            console.log(error);
        }
    };

    useEffect(() => {
        fetchNominals();
    }, []);

    return (
        <div className={`${styles["container"]} mx-auto`}>
            <div className={`container mt-4`}>
                <Form
                    nominalValues={nominalValues}
                    onSubmitClick={() => onSubmitChanges()}
                    key={JSON.stringify({})}
                    render={(formRenderProps) => {
                        return (
                            <FormElement id={props.formId}>
                                <div className="row">
                                    <div className="col-4">
                                        <Branch
                                            value={branch}
                                            onChange={onChangeBranch}
                                            controlled={true}
                                            name="branchId"
                                            component={"DefaultNominals"}
                                            width="360px"
                                            labelClass="mb-2"
                                            bacgroundColor="#F8F8F8"
                                        />
                                    </div>
                                </div>
                                <hr />
                                <div className="row mt-5">
                                    <div className="col-4">
                                        <Nominals
                                            label="Debtors Control"
                                            width="360px"
                                            nominalList={nominalList.filter((item) => item.control)}
                                            controlled={true}
                                            value={nominalValues["Debtors"]}
                                            onChange={(e) => {
                                                onChangeValue("Debtors", e);
                                            }}
                                        />
                                    </div>
                                    <div className="col-4">
                                        <Nominals
                                            label="Office Bank"
                                            width="360px"
                                            nominalList={nominalList.filter(
                                                (item) => item.subTypeName === "BalanceSheet_ClientBank"
                                            )}
                                            controlled={true}
                                            value={nominalValues["OfficeBank"]}
                                            onChange={(e) => {
                                                onChangeValue("OfficeBank", e);
                                            }}
                                        />
                                    </div>
                                    <div className="col-4">
                                        <Nominals
                                            label="Unpaid Disbs Control"
                                            width="360px"
                                            nominalList={nominalList.filter((item) => item.control)}
                                            controlled={true}
                                            value={nominalValues["UnpaidDisbursement"]}
                                            onChange={(e) => {
                                                onChangeValue("UnpaidDisbursement", e);
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-4">
                                        <Nominals
                                            label="Creditors Control"
                                            width="360px"
                                            nominalList={nominalList.filter((item) => item.control)}
                                            controlled={true}
                                            value={nominalValues["Creditors"]}
                                            onChange={(e) => {
                                                onChangeValue("Creditors", e);
                                            }}
                                        />
                                    </div>
                                    <div className="col-4">
                                        <Nominals
                                            label="Client Bank"
                                            width="360px"
                                            nominalList={nominalList.filter(
                                                (item) => item.subTypeName === "BalanceSheet_ClientBank"
                                            )}
                                            controlled={true}
                                            value={nominalValues["ClientBank"]}
                                            onChange={(e) => {
                                                onChangeValue("ClientBank", e);
                                            }}
                                        />
                                    </div>
                                    <div className="col-4">
                                        <Nominals
                                            label="Unbilled Disbs Control"
                                            width="360px"
                                            nominalList={nominalList.filter((item) => item.control)}
                                            controlled={true}
                                            value={nominalValues["UnbilledDisbursement"]}
                                            onChange={(e) => {
                                                onChangeValue("UnbilledDisbursement", e);
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-4">
                                        <Nominals
                                            label="VAT on Sale"
                                            width="360px"
                                            nominalList={nominalList.filter(
                                                (item) => item.subTypeName === "ProfitAndLoss_Income"
                                            )}
                                            controlled={true}
                                            value={nominalValues["VatOnSales"]}
                                            onChange={(e) => {
                                                onChangeValue("VatOnSales", e);
                                            }}
                                        />
                                    </div>
                                    <div className="col-4">
                                        <Nominals
                                            label="Client Control"
                                            width="360px"
                                            nominalList={nominalList.filter((item) => item.control)}
                                            controlled={true}
                                            value={nominalValues["ClientControl"]}
                                            onChange={(e) => {
                                                onChangeValue("ClientControl", e);
                                            }}
                                        />
                                    </div>
                                    <div className="col-4">
                                        <Nominals
                                            label="Disbursements WriteOff"
                                            width="360px"
                                            nominalList={nominalList.filter((item) => item.control)}
                                            controlled={true}
                                            value={nominalValues["DisbursementWriteOff"]}
                                            onChange={(e) => {
                                                onChangeValue("DisbursementWriteOff", e);
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-4">
                                        <Nominals
                                            label="VAT on Purchase"
                                            width="360px"
                                            nominalList={nominalList.filter(
                                                (item) => item.subTypeName === "ProfitAndLoss_Expense"
                                            )}
                                            controlled={true}
                                            value={nominalValues["VatOnPurchase"]}
                                            onChange={(e) => {
                                                onChangeValue("VatOnPurchase", e);
                                            }}
                                        />
                                    </div>
                                    <div className="col-4">
                                        <Nominals
                                            label="DDA Bank"
                                            width="360px"
                                            nominalList={nominalList.filter(
                                                (item) => item.subTypeName === "BalanceSheet_DDABank"
                                            )}
                                            controlled={true}
                                            value={nominalValues["DDABank"]}
                                            onChange={(e) => {
                                                onChangeValue("DDABank", e);
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="row mt-3 mb-3">
                                    <div className="col-4">
                                        <Nominals
                                            label="Retained Profit"
                                            width="360px"
                                            nominalList={nominalList.filter((item) => item.control)}
                                            controlled={true}
                                            value={nominalValues["RetainedProfit"]}
                                            onChange={(e) => {
                                                onChangeValue("RetainedProfit", e);
                                            }}
                                        />
                                    </div>
                                    <div className="col-4">
                                        <Nominals
                                            label="DDA Control"
                                            width="360px"
                                            nominalList={nominalList.filter((item) => item.control)}
                                            controlled={true}
                                            value={nominalValues["DDAControl"]}
                                            onChange={(e) => {
                                                onChangeValue("DDAControl", e);
                                            }}
                                        />
                                    </div>
                                </div>
                                <div
                                    className={`${styles["buttons-container"]} d-flex align-items-center justify-content-between col-12 p-3`}
                                >
                                    <div>
                                        {Object.keys(nominalValues).length === 0 && (
                                            <Button
                                                className="common-btn bgBtnSecondary"
                                                type="button"
                                                onClick={() => getNewDefaultBranch(branch.branchId)}
                                            >
                                                Use Defaults
                                            </Button>
                                        )}
                                    </div>
                                    <div className="d-flex align-items-center gap-2 me-2">
                                        <Button className="common-btn bgBtnSecondary" type="button">
                                            Cancel
                                        </Button>
                                        <Button
                                            className="common-btn"
                                            type="button"
                                            onClick={() => onSubmitChanges()}
                                        >
                                            Save
                                        </Button>
                                    </div>
                                </div>
                            </FormElement>
                        );
                    }}
                />
                {isLoading && <LoadingScreen />}
            </div>
        </div>
    );
};
export default DefaultNominals;
