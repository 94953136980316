import React from "react";
import { Label } from "@progress/kendo-react-labels";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { Field } from "@progress/kendo-react-form";

export default function Nominals({
  component,
  label,
  disabled = false,
  nominalList,
  width = "255px",
  type,
  valueSetter,
  controlled = false,
  name,
  className,
  onChange,
  value,
  style,
}) {
  return (
    <div className={`${className} d-flex flex-column`} style={style}>
      <Label className="label mb-2">{label}</Label>
      {controlled ? (
        <ComboBox
          style={{ width: width, height: "44px" }}
          data={nominalList}
          textField="representation"
          placeholder="Select Nominal"
          dataItemKey="nominalRef"
          iconClassName="wa"
          className="ps-2"
          value={value}
          onChange={(e) => onChange(e.value)}
        />
      ) : (
        <Field
          disabled={disabled}
          style={{ width: width, height: "44px" }}
          data={nominalList}
          name={name}
          textField="representation"
          placeholder="Select Nominal"
          className="ps-2"
          dataItemKey="nominalRef"
          iconClassName="wa"
          component={ComboBox}
        />
      )}
    </div>
  );
}
