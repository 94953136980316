import React, { useEffect, useState } from "react";
import { Tile } from "components/UIComponents/Tile";
import styles from "./RiskAssessments.module.scss";
import {
  getClientRiskAssessmentQuestions,
  getMatterRiskAssessmentQuestions,
  saveClientRiskAssessmentQuestions,
  saveMatterRiskAssessmentQuestions,
  updateOnboarding,
} from "api";

import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
import { Checkbox } from "@progress/kendo-react-inputs";
import { toast } from "react-toastify";
import LoadingScreen from "components/common/Loading";
import { DateFormat } from "components/common/formatter";

export const RiskAssessment = ({ moveNext, formData, matterId, clientId }) => {
  const [data, setData] = useState({});
  const [savedAnswers, setSavedAnswers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [view, setView] = useState(formData ? "matter" : "");

  const getMatterAssessmentData = async () => {
    try {
      setLoading(true);
      const data = await getMatterRiskAssessmentQuestions(
        matterId || formData.matterDetails?.matterId
      );
      setData(data?.data?.data);
    } catch (error) {
      toast.error("Failed to fetch matter risk assessment data");
    } finally {
      setLoading(false);
    }
  };

  const saveAnswer = (id, data) => {
    const answers = savedAnswers.filter((answer) => answer?.questionId !== id);
    setSavedAnswers([...answers, data]);
  };

  const getClientAssessmentData = async () => {
    try {
      setLoading(true);
      const data = await getClientRiskAssessmentQuestions(
        clientId || formData.clientData?.clientId
      );
      setData(data?.data?.data);
    } catch (error) {
      toast.error("Failed to fetch client risk assessment data");
    } finally {
      setLoading(false);
    }
  };

  const submitAnswers = async () => {
    try {
      if (clientId || view === "client") {
        await saveClientRiskAssessmentQuestions(
          clientId || formData.clientData?.clientId,
          {
            data: {
              riskAssessementId: data.riskAssessementId,
              answers: savedAnswers,
            },
          }
        );
        getClientAssessmentData();
      } else if (matterId || view === "matter") {
        await saveMatterRiskAssessmentQuestions(
          matterId || formData.matterDetails?.matterId,
          {
            data: {
              riskAssessementId: data.riskAssessementId,
              answers: savedAnswers,
            },
          }
        );
        getMatterAssessmentData();
      }
      toast.success("Questionnaire Updated");
    } catch (error) {
      toast.error("Unexpected Error Occurred");
    }
  };

  const handleNext = async () => {
    try {
      setLoading(true);
      const [matterResponse, clientResponse] = await Promise.all([
        getMatterRiskAssessmentQuestions(formData.matterDetails?.matterId),
        getClientRiskAssessmentQuestions(formData.clientData?.clientId),
      ]);
      const matterData = matterResponse?.data?.data;
      const clientData = clientResponse?.data?.data;

      if (matterData?.submissionId || clientData?.submissionId) {
        await updateOnboarding(
          {
            matterRiskSubmissionId: matterData?.submissionId,
            clientRiskSubmissionId: clientData?.submissionId,
          },
          formData.onboardingId
        );
      }
      moveNext(
        {},
        {
          riskAssessment: {
            matterData,
            clientData,
          },
        }
      );
    } catch (error) {
      toast.error("Unexpected Error Occurred");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (clientId || view === "client") {
      getClientAssessmentData();
    } else if (matterId || view === "matter") {
      getMatterAssessmentData();
    }
  }, [view]);

  const formattedDate = DateFormat(data?.submittedOn);
  return (
    <div className={`${!formData && "container mt-4"} h-100`}>
      <Tile
        heading="Risk Assessment"
        className="mb-2 px-4 py-2 overflow-auto h-100"
        height={!formData ? "580px" : ""}
        headingItems={
          <div className="d-flex align-items-center gap-2">
            {formData && (
              <div className={`${styles["button-group"]}`}>
                <ButtonGroup width="100%">
                  <Button
                    className={`rounded-pill border-0 mx-1`}
                    style={{
                      color: view === "matter" && "white",
                      backgroundColor:
                        view === "matter" ? "#241CCA" : "#FFFFFF",
                    }}
                    onClick={() => setView("matter")}
                  >
                    Matter
                  </Button>
                  <Button
                    className="rounded-pill border-0 mx-1"
                    style={{
                      color: view === "client" && "white",
                      backgroundColor:
                        view === "client" ? "#241CCA" : "#FFFFFF",
                    }}
                    onClick={() => setView("client")}
                  >
                    Client
                  </Button>
                </ButtonGroup>
              </div>
            )}

            <div className={`d-flex gap-2 ${styles["header-text"]}`}>
              {data?.submissionId !== null &&
                data?.submissionId !== undefined && (
                  <>
                    <div className="">
                      <span
                        style={{
                          color: "black",
                          fontWeight: 600,
                        }}
                        className="me-2"
                      >
                        Submitted By:
                      </span>
                      <span className={`${styles["detail-text"]}`}>
                        {data?.submittedBy}
                      </span>
                    </div>
                    <div
                      style={{
                        color: "black",
                        fontWeight: 600,
                      }}
                    >
                      |
                    </div>
                    <div className="me-3">
                      <span
                        style={{
                          color: "black",
                          fontWeight: 600,
                        }}
                        className="me-2"
                      >
                        Submitted On:
                      </span>
                      <span className={`${styles["detail-text"]}`}>
                        {formattedDate}
                      </span>
                    </div>
                  </>
                )}
              <div>
                <span
                  style={{
                    color: "black",
                    fontWeight: 600,
                  }}
                  className="me-2"
                >
                  Status:
                </span>
                <span
                  style={{
                    color:
                      data?.status === "Pass"
                        ? "green"
                        : data?.status === "Failed"
                        ? "red"
                        : data.status === "Not Submitted"
                        ? "black"
                        : "red",
                  }}
                >
                  {data?.status}
                </span>
              </div>
            </div>
          </div>
        }
      >
        <div
          style={{
            height: formData ? "calc(100% - 70px)" : "auto",
          }}
          className="container-fluid"
        >
          <div
            className="d-flex flex-column  justify-content-between "
            style={{
              height: !formData ? "495px" : "100%",
            }}
          >
            <div
              className="overflow-auto"
              style={{ height: "calc(100% - 55px)" }}
            >
              <div>
                {data?.questions?.map((question, index) => (
                  <QuestionTile
                    data={question}
                    key={index}
                    disabled={data?.submissionId !== null}
                    saveAnswer={saveAnswer}
                    index={index}
                    answer={question?.answer?.answer}
                    view={view}
                  />
                ))}
              </div>
            </div>
            {
              <div className="row p-2 align-self-end ">
                <Button
                  className="common-btn"
                  style={{
                    maxWidth: "80px",
                  }}
                  disabled={
                    data.submissionId !== null ||
                    data.riskAssessementId === null
                  }
                  onClick={submitAnswers}
                >
                  Submit
                </Button>
              </div>
            }

            {formData && (
              <div className="me-3" style={{ height: "55px" }}>
                <hr className="m-0" />
                <div className="d-flex justify-content-end mt-2">
                  <Button className="common-btn" onClick={handleNext}>
                    Next
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      </Tile>
      {loading && <LoadingScreen />}
    </div>
  );
};

const QuestionTile = ({ data, index, answer, saveAnswer, disabled, view }) => {
  const [checkBoxesValues, setCheckBoxesValues] = useState({
    firstValue: answer === "Yes" ? true : false,
    secondValue: answer === "No" ? true : false,
    thirdValue: answer === "Unknown" ? true : false,
  });

  const QUESTIONS_LEGEND = {
    firstValue: "Yes",
    secondValue: "No",
    thirdValue: "Unknown",
  };
  useEffect(() => {
    setCheckBoxesValues({
      firstValue: answer === "Yes" ? true : false,
      secondValue: answer === "No" ? true : false,
      thirdValue: answer === "Unknown" ? true : false,
    });
  }, [answer, view]);

  const handleChange = (e) => {
    if (disabled) return;
    const label = e?.target?.name;
    const currentValues = checkBoxesValues;
    if (e.value) {
      Object.keys(checkBoxesValues).forEach((name) => {
        if (name !== label) {
          currentValues[name] = false;
        }
      });
      currentValues[label] = e.value;
    } else if (!e.value) {
      currentValues[label] = false;
    }
    setCheckBoxesValues({ ...currentValues });
    saveAnswer(data.questionId, {
      questionId: data?.questionId,
      commentsFor: data?.commentsFor,
      answer: QUESTIONS_LEGEND[label],
    });
  };

  return (
    <div
      className={` ${styles["question-box"]} p-2 d-flex justify-content-between align-items-center`}
      style={{
        backgroundColor: index % 2 === 0 && "#F9F9FF",
      }}
    >
      <div
        className={` ${styles["question-text"]} truncate `}
        style={{
          maxWidth: "60%",
        }}
      >
        {data?.question}
      </div>
      <div
        className={`w-25 d-block d-xl-flex align-items-center justify-content-start  `}
      >
        <div className=" me-4 ">
          <Checkbox
            onChange={handleChange}
            name="firstValue"
            label={"Yes"}
            labelClassName={styles["checkboxes"]}
            style={{
              height: "17px",
              fontWeight: 600,
              width: "17px",
            }}
            value={checkBoxesValues["firstValue"]}
          />
        </div>
        <div className="me-4">
          <Checkbox
            onChange={handleChange}
            name="secondValue"
            label={"No"}
            style={{
              height: "17px",
              fontWeight: "600 !important",
              fontFamily: "Montserrat",
              width: "17px",
            }}
            labelClassName={styles["checkboxes"]}
            value={checkBoxesValues["secondValue"]}
          />
        </div>
        {data.showUnknown && (
          <div className="">
            <Checkbox
              label={data.unknownText}
              onChange={handleChange}
              name="thirdValue"
              style={{
                height: "17px",
                width: "17px",
                fontWeight: 600,
              }}
              labelClassName={`${styles["checkboxes"]} text-wrap`}
              value={checkBoxesValues["thirdValue"]}
            />
          </div>
        )}
      </div>
    </div>
  );
};
