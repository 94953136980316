import {
    START_LOADING,
    STOP_LOADING,
    SUCCESS,
    GET_TIME_TYPE_LIST,
    EDIT_TIME_TYPE_LIST,
    ADD_TIME_TYPE,
  } from "../types/index";
  import * as api from "../api/index.js";
  import { handleApiError } from "Helper";
  import { toast } from "react-toastify";
  
  export const getTimeType = (page, limit) => async (dispatch) => {
    try {
      dispatch({ type: START_LOADING });
      const datas = await api.getTimeTypesList(page, limit);
      dispatch({ type: GET_TIME_TYPE_LIST, payload: datas.data });
      dispatch({ type: STOP_LOADING });
      dispatch({ type: SUCCESS, payload: datas.message });
    } catch (error) {
      handleApiError(error);
    }
  };
  
  export const editTimeType = (id, data) => async (dispatch) => {
    try {
      const datas = await api.editTimeType(id, data);
      toast.success(`Time Type '${data?.name}' has been updated.`);
      dispatch({ type: EDIT_TIME_TYPE_LIST, payload: datas });
    } catch (error) {
      toast.error(`Error occcured while updating`);
      handleApiError(error);
    }
  };
  
  export const addTime = (data) => async (dispatch) => {
    try {
      const datas = await api.addTimeType(data);
      dispatch({ type: ADD_TIME_TYPE, payload: datas });
    } catch (error) {
      handleApiError(error);
    }
  };
  