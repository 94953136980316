import {
  START_LOADING,
  STOP_LOADING,
  ERROR,
  SUCCESS,
  GET_BRANCHES_LIST,
  ADDEDNEWBRANCH,
  UPDATE_BRANCH,
  RESET,
} from "../types/index";
import * as api from "../api/index.js";
import { handleApiError } from "Helper";
import { toast } from "react-toastify";

export const getBranchesList = (page, limit) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const data = await api.getBranches(page, limit);
    dispatch({ type: GET_BRANCHES_LIST, payload: data.data });
    dispatch({ type: STOP_LOADING });
    dispatch({ type: SUCCESS, payload: data.message });
  } catch (error) {
    handleApiError(error);
  }
};

export const editBranch = (id, data) => async (dispatch) => {
  try {
    const datas = await api.editbranchinfo(id, data);
    toast.success(`Branch '${data?.branchName}' has been updated`);
    dispatch({ type: UPDATE_BRANCH, payload: datas });
  } catch (error) {
    toast.error(error?.response?.data?.errorMessage);
    handleApiError(error);
  }
};

export const addnewBranch = (data) => async (dispatch) => {
  try {
    const datas = await api.addnewbranch(data);
    dispatch({ type: ADDEDNEWBRANCH, payload: datas });
  } catch (error) {
    toast.error(error?.response?.data?.errorMessage);
    handleApiError(error);
  }
};

export const reset = (data) => async (dispatch) => {
  try {
    dispatch({ type: RESET });
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};
